export default function reducer(
    state = {
        filters: {
            distance: 100
        },
        create: {
            selectedUsers: [],
            selectedDogs: [],
            selectedPark: {},
            selectedDate: null,
            selectedTime: null,
            selectedEndDate: null,
            selectedEndTime: null,
            invitationOnly: false,
            comment: '',
            type: 'playdate',
            title: '',
            additionalinfo: {},
            images: []
        },
        edit: {
            selectedUsers: [],
            selectedDogs: [],
            selectedPark: {},
            selectedDate: null,
            selectedTime: null,
            selectedEndDate: null,
            selectedEndTime: null,
            invitationOnly: false,
            comment: '',
            editing: null,
            type: 'playdate',
            title: '',
            additionalinfo: {},
            images: []
        }
    },
    action
) {
    switch (action.type) {
        case 'persist/REHYDRATE': {
            if (action.payload.playdates) {
                return {
                    ...state,
                    filters: { ...action.payload.playdates.filters } || {
                        distance: 100
                    },
                    create: { ...action.payload.playdates.create } || {
                        selectedUsers: [],
                        selectedDogs: [],
                        selectedPark: {},
                        selectedDate: null,
                        selectedTime: null,
                        selectedEndDate: null,
                        selectedEndTime: null,
                        invitationOnly: false,
                        comment: '',
                        type: 'playdate',
                        title: '',
                        additionalinfo: {},
                        images: []
                    },
                    edit: { ...action.payload.playdates.edit } || {
                        selectedUsers: [],
                        selectedDogs: [],
                        selectedPark: {},
                        selectedDate: null,
                        selectedTime: null,
                        selectedEndDate: null,
                        selectedEndTime: null,
                        invitationOnly: false,
                        comment: '',
                        editing: null,
                        type: 'playdate',
                        title: '',
                        additionalinfo: {},
                        images: []
                    }
                };
            }
            return { ...state };
        }

        case 'PLAYDATE_FILTER_DISTANCE': {
            return { ...state, filters: { ...state.filters, distance: action.payload } };
        }

        case 'PLAYDATECREATE_CHANGE_DATE': {
            return { ...state, create: { ...state.create, selectedDate: action.payload } };
        }

        case 'PLAYDATEEDIT_CHANGE_DATE': {
            return { ...state, edit: { ...state.edit, selectedDate: action.payload } };
        }

        case 'PLAYDATECREATE_CHANGE_ENDDATE': {
            return { ...state, create: { ...state.create, selectedEndDate: action.payload } };
        }

        case 'PLAYDATEEDIT_CHANGE_ENDDATE': {
            return { ...state, edit: { ...state.edit, selectedEndDate: action.payload } };
        }

        case 'PLAYDATECREATE_TOGGLE_DOG': {
            let selectedDogs;
            if (!state.create || !state.create.selectedDogs || !state.create.selectedDogs.length) {
                selectedDogs = [];
                selectedDogs.push(action.payload);
            } else if (!!state.create && !!state.create.selectedDogs && !!state.create.selectedDogs.length && state.create.selectedDogs.length > 0 && state.create.selectedDogs.indexOf(action.payload) !== -1) {
                selectedDogs = state.create.selectedDogs.filter(item => item !== action.payload);
            } else {
                selectedDogs = [...state.create.selectedDogs];
                selectedDogs.push(action.payload);
            }

            return { ...state, create: { ...state.create, selectedDogs } };
        }

        case 'PLAYDATEEDIT_TOGGLE_DOG': {
            let selectedDogs2;
            if (!state.edit || !state.edit.selectedDogs || !state.edit.selectedDogs.length) {
                selectedDogs2 = [];
                selectedDogs2.push(action.payload);
            } else if (!!state.edit && !!state.edit.selectedDogs && !!state.edit.selectedDogs.length && state.edit.selectedDogs.length > 0 && state.edit.selectedDogs.indexOf(action.payload) !== -1) {
                selectedDogs2 = state.edit.selectedDogs.filter(item => item !== action.payload);
            } else {
                selectedDogs2 = [...state.edit.selectedDogs];
                selectedDogs2.push(action.payload);
            }

            return { ...state, edit: { ...state.edit, selectedDogs: selectedDogs2 } };
        }

        case 'PLAYDATECREATE_TOGGLE_USER': {
            if (!!state.create.selectedUsers && !!state.create.selectedUsers.length) {
                let index2 = -1;
                for (let j = 0; j < state.create.selectedUsers.length; j++) {
                    if (String(state.create.selectedUsers[j].id) === String(action.payload.id)) {
                        index2 = j;
                        break;
                    }
                }

                if (index2 !== -1) {
                    const filterAry = [...state.create.selectedUsers];
                    filterAry.splice(index2, 1);
                    return { ...state, create: { ...state.create, selectedUsers: filterAry } };
                }

                return { ...state, create: { ...state.create, selectedUsers: [...state.create.selectedUsers, action.payload] } };
            }
            return { ...state, create: { ...state.create, selectedUsers: [action.payload] } };
        }

        case 'PLAYDATECREATE_TOGGLE_PARK': {
            return { ...state, create: { ...state.create, selectedPark: action.payload } };
        }

        case 'PLAYDATEEDIT_TOGGLE_PARK': {
            return { ...state, edit: { ...state.edit, selectedPark: action.payload } };
        }

        case 'PLAYDATECREATE_CHANGE_TIME': {
            return { ...state, create: { ...state.create, selectedTime: action.payload } };
        }

        case 'PLAYDATEEDIT_CHANGE_TIME': {
            return { ...state, edit: { ...state.edit, selectedTime: action.payload } };
        }

        case 'PLAYDATECREATE_CHANGE_ENDTIME': {
            return { ...state, create: { ...state.create, selectedEndTime: action.payload } };
        }

        case 'PLAYDATEEDIT_CHANGE_ENDTIME': {
            return { ...state, edit: { ...state.edit, selectedEndTime: action.payload } };
        }

        case 'PLAYDATECREATE_COMMENT': {
            return { ...state, create: { ...state.create, comment: action.payload } };
        }

        case 'PLAYDATEEDIT_COMMENT': {
            return { ...state, edit: { ...state.edit, comment: action.payload } };
        }

        case 'PLAYDATECREATE_TYPE': {
            return { ...state, create: { ...state.create, type: action.payload } };
        }

        case 'PLAYDATEEDIT_TYPE': {
            return { ...state, edit: { ...state.edit, type: action.payload } };
        }

        case 'PLAYDATECREATE_TITLE': {
            return { ...state, create: { ...state.create, title: action.payload } };
        }

        case 'PLAYDATEEDIT_TITLE': {
            return { ...state, edit: { ...state.edit, title: action.payload } };
        }

        case 'PLAYDATECREATE_ADDITIONALINFO': {
            return { ...state, create: { ...state.create, additionalinfo: action.payload } };
        }

        case 'PLAYDATEEDIT_ADDITIONALINFO': {
            return { ...state, edit: { ...state.edit, additionalinfo: action.payload } };
        }

        case 'PLAYDATECREATE_IMAGES': {
            return { ...state, create: { ...state.create, images: action.payload } };
        }

        case 'PLAYDATECREATE_IMAGES_FILES': {
            return { ...state, create: { ...state.create, images_files: action.payload } };
        }

        case 'PLAYDATEEDIT_IMAGES': {
            return { ...state, edit: { ...state.edit, images: action.payload } };
        }

        case 'PLAYDATEEDIT_IMAGES_FILES': {
            return { ...state, edit: { ...state.edit, images_files: action.payload } };
        }

        case 'PLAYDATECREATE_TOGGLE_INVITATION': {
            return { ...state, create: { ...state.create, invitationOnly: !state.create.invitationOnly } };
        }

        case 'PLAYDATEEDIT_TOGGLE_INVITATION': {
            return { ...state, edit: { ...state.edit, invitationOnly: action.payload } };
        }

        case 'ADD_PLAYDATE_DOGS_FULFILLED': {
            return {
                ...state,
                create: {
                    selectedUsers: [],
                    selectedDogs: [],
                    selectedPark: {},
                    selectedDate: null,
                    selectedTime: null,
                    selectedEndDate: null,
                    selectedEndTime: null,
                    invitationOnly: false,
                    comment: '',
                    type: 'playdate',
                    title: '',
                    additionalinfo: {},
                    images: []
                }
            };
        }

        case 'UPDATE_PLAYDATE_FULFILLED': {
            return {
                ...state,
                edit: {
                    selectedUsers: [],
                    selectedDogs: [],
                    selectedPark: {},
                    selectedDate: null,
                    selectedTime: null,
                    selectedEndDate: null,
                    selectedEndTime: null,
                    invitationOnly: false,
                    comment: '',
                    editing: null,
                    type: 'playdate',
                    title: '',
                    additionalinfo: {},
                    images: []
                }
            };
        }

        case 'EDITING': {
            return { ...state, edit: { ...state.edit, editing: action.payload } };
        }

        case 'USER_LOGOUT': {
            return {
                ...state,
                filters: {
                    distance: 100
                },
                create: {
                    selectedUsers: [],
                    selectedDogs: [],
                    selectedPark: {},
                    selectedDate: null,
                    selectedTime: null,
                    selectedEndDate: null,
                    selectedEndTime: null,
                    invitationOnly: false,
                    comment: '',
                    type: 'playdate',
                    title: '',
                    additionalinfo: {},
                    images: []
                },
                edit: {
                    selectedUsers: [],
                    selectedDogs: [],
                    selectedPark: {},
                    selectedDate: null,
                    selectedTime: null,
                    selectedEndDate: null,
                    selectedEndTime: null,
                    invitationOnly: false,
                    comment: '',
                    editing: null,
                    type: 'playdate',
                    title: '',
                    additionalinfo: {},
                    images: []
                }
            };
        }
        default: {
            return { ...state };
        }
    }
}
