import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import { setMissingDogSelected } from '../../redux/actions/dogs';
import { getUserProfile } from '../../redux/actions/users';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import PetList from '../../layout/PetList';
import ApiError from '../../helpers/ApiError';

export class ReportMissingDogsSelectDogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dogProfiles: null
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(getUserProfile(this.props.credentials.token, this.props.credentials.user_id))
            .then(response => {
                this.setState({
                    dogProfiles: response.value.data.dogs.filter(item => (!!item && !item.dogaddress) || (!!item && !!item.dogaddress && !!item.dogaddress.dogaddress_found))
                });
            });
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    selectDog = (e, dog) => {
        e.preventDefault();
        this.props.dispatch(setMissingDogSelected(dog.id));
    }

    validate(e) {
        e.preventDefault();
        if (!!this.props.missingDogs && !!this.props.missingDogs.selectedDogs && !!this.props.missingDogs.selectedDogs.length) {
            this.props.history.push('/reportmissing/details');
        } else {
            ApiError('Please select a dog or create one first');
        }
    }

    render() {
        return (
            <div className="mainAppContainer">
                <MainNavigation {...this.props} title="Select the Lost Dog" leftButton="back" />
                <div className="bodyContent backgroundRed wrapper">
                    <div className="maxWidth missing-dog-list ">
                        <PetList
                            dogs={this.state.dogProfiles}
                            selectedDogs={this.props.missingDogs.selectedDogs}
                            markSelected={true}
                            onClick={this.selectDog}
                            color="fontWhite"
                        />
                    </div>
                    <div className="paddingTop maxWidth create-missing-dog">
                        <Link className="rightRoundButton whiteBorder whiteOpaque fontWhite" to="/user/dogadd?redirect=/reportmissing/selectdogs">
                            Add a Dog &gt;
                        </Link>
                        {/* this.state.selected && */
                            <Link to="#" onClick={e => this.validate(e)} className="nextButton rightRoundButton whiteBorder whiteOpaque fontWhite">
                                Next &gt;
                            </Link>
                        }
                    </div>
                </div>
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        missingDogs: state.dogs.reportMissing
    };
}

export default connect(mapStateToProps)(ReportMissingDogsSelectDogs);
