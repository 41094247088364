import React from 'react';
import { connect } from 'react-redux';

import FoundDogsList from '../../layout/FoundDogsList';
import { FoundDogsNavigation } from '../../layout/navigation/FoundDogsNavigation';
import Columns from '../../hoc/Columns';
import WhiteTitle from '../../hoc/WhiteTitle';

export class FoundDogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const c1 = (
            <React.Fragment>
                <div className="simple-navigation">
                    <FoundDogsNavigation
                        showTitle
                        selected="found-dogs"
                    />
                </div>
            </React.Fragment>
        );

        const c2 = (
            <React.Fragment>
                <WhiteTitle>These dogs have been found</WhiteTitle>
                <FoundDogsList dogs={this.props.dogs} />
            </React.Fragment>
        );

        const c3 = (<React.Fragment />);

        return (
            <div className="mainAppContainer">
                <div className="bodyContent">
                    <div className="three-column-layout two-column-list">
                        <Columns
                            c1={c1}
                            c2={c2}
                            c3={c3}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        dogs: state.dogs.dog
    };
}

export default connect(mapStateToProps)(FoundDogs);
