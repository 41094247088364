import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';

import { getActivities } from '../../redux/actions/activities';
import { deletePicture, setPicture } from '../../redux/actions/all';
import { setUsersMainImage } from '../../redux/actions/users';
import PetList from '../../layout/PetList';
import GalleryLightbox from '../../layout/gallery/GalleryLightbox';
import { ActivityListItem } from '../../layout/ActivityListItem';
import Spinner from '../../layout/UI/Spinner';
import UserProfileHeader from '../../layout/UI/UserProfileHeader';
import UserProfileButtons from '../../layout/UI/UserProfileButtons';
import { InviteFriends } from '../../layout/InviteFriends';
import WhiteTitle from '../../hoc/WhiteTitle';
import Columns from '../../hoc/Columns';
import { infiniteScroll } from '../../helpers/Pull2Refresh';
import ApiError from '../../helpers/ApiError';
import FindFriendsComponent from '../Friends/FindFriendsComponent';
import HelmetTags from '../../helmetTags';

/**
 * @param {function} dispatch function used to hit end-points
 * @param {Object} credentials a JSON Object with the credentials of the logged user
 * @param {Object} userData a JSON Object with the information of the logged user
 * @param {Object} user a JSON Object with the information of the user to show
 * @param {array} dogs array of Objects with the dogs that the user displayed has added
 * @param {Object} history react-router Object used to get information from the browser history and/or to have access to some functions
 * @param {bool} ownProfile true if it's the profile of the logged user
 */
export class OtherUserProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activities: [],
            loadingMore: true,
            currentPage: 1,
            totalPages: 1,
            image: null,
            gallery: []
            // socialMedia: {},
            // bio: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.userData && this.props.userData.user_id) {
            this.getActivities();
        } else {
            this.setState({ loadingMore: false });
        }
        this.setState({
            image: this.props.user && this.props.user.images && this.props.user.images[0] && this.props.user.images[0].image_mediumurl ? this.props.user.images[0].image_mediumurl : null,
            gallery: this.props.user && this.props.user.images
            // socialMedia: this.props.user.user_socialmedia,
            // bio: this.props.user.user_aboutme
        });
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    LoginModal = () => {
        ApiError('You need to log in or sign up.');
    }

    getActivities = () => {
        this.props.dispatch(getActivities(this.props.credentials.token, this.props.user.user_id, this.state.currentPage))
            .then(response => {
                this.activityList(response.value.data);
                this.setState({
                    loadingMore: false,
                    totalPages: response.value.headers['x-pagination-page-count']
                }, () => {
                    if (this.state.currentPage < this.state.totalPages) {
                        infiniteScroll(this, this.getActivities);
                    }
                });
            })
            .catch(err => {
                console.log(err);
                this.setState({ loadingMore: false });
            });
    }

    // Removes image from GalleryLightBox
    handleClickRemoveImage = id => {
        this.props.dispatch(deletePicture(this.props.credentials.token, id))
            .then(() => {
                this.setState(prevState => {
                    const newGallery = prevState.gallery.filter(image => image.image_id !== id);
                    return {
                        gallery: newGallery
                    };
                }, () => {
                    this.props.dispatch(setUsersMainImage(this.state.gallery));
                    this.setState({ image: this.state.gallery && this.state.gallery[0] && this.state.gallery[0].image_largeurl ? this.state.gallery[0].image_largeurl : null });
                });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    handleClickSetImage = id => {
        this.props.dispatch(setPicture(this.props.credentials.token, id))
            .then(res => {
                let newFeaturedImage = {};
                // we push the new featured image to the top of the list, on the first position
                this.setState(prevState => {
                    let newGallery = prevState.gallery.filter(image => {
                        if (image.image_id === id) newFeaturedImage = image;
                        return image.image_id !== id;
                    });
                    newGallery.sort((a, b) => b.image_id - a.image_id);
                    newGallery = [newFeaturedImage, ...newGallery];
                    this.props.dispatch(setUsersMainImage(newGallery));
                    return {
                        gallery: newGallery,
                        image: res.value.data.image_largeurl
                    };
                });
                toast.success('Profile Image Set Successfully!');
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    activityList(data) {
        const activities = data.map((activity, index) => (<ActivityListItem dispatch={this.props.dispatch} key={index} activity={activity} credentials={this.props.credentials} />));
        this.setState({ activities: [...this.state.activities, activities] });
    }

    render() {
        let addDogButton = null;
        if (this.props.ownProfile && this.props.credentials.token) {
            addDogButton = (<Link to="/user/dogadd" className="square-button greenBG fontWhite h6">Add Dog</Link>);
        } else if (!this.props.ownProfile && !this.props.credentials.token) {
            addDogButton = (<div onClick={this.LoginModal} className="square-button greenBG fontWhite h6">Add Dog</div>);
        }

        const userProfileButtons = (
            <UserProfileButtons
                user={this.props.user}
                userData={this.props.userData}
                credentials={this.props.credentials}
                dispatch={this.props.dispatch}
                ownProfile={this.props.ownProfile}
            />
        );

        const column1 = (
            <div className="user-dog-list-desktop">
                {this.props.dogs.length > 0 && (
                    <div className="other-user-dog-list">
                        <WhiteTitle>Dog(s)</WhiteTitle>
                        <PetList dogs={this.props.dogs} />
                        {addDogButton}
                    </div>
                )}
            </div>
        );

        const column2 = (
            <React.Fragment>
                {this.props.user.user_aboutme && (
                    <div className="other-user-bio">
                        <WhiteTitle>About Me</WhiteTitle>
                        <div className="maxWidth fontDarkGray profile-description">
                            {this.props.user && this.props.user.user_aboutme && (
                                <div className="profile-bio">
                                    {this.props.user && this.props.user.user_aboutme && this.props.user.user_aboutme.split('\n').map((item, key) => (<p className="paragraph-bio" key={key}>{item}<br /></p>))}
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {this.state.gallery && this.state.gallery.length > 0 && (
                    <React.Fragment>
                        <div className="gallerySection">
                            <WhiteTitle>Pictures
                                <div className="viewAll">
                                    <Link to={'/user/profile/' + this.props.user.user_id + '/gallery'} className="fontGreen">View All</Link>
                                </div>
                            </WhiteTitle>
                        </div>
                        <div className="galleryHolder">
                            <GalleryLightbox
                                images={this.state.gallery}
                                userData={this.props.userData}
                                count={4}
                                size="normal"
                                handleClickRemoveImage={this.handleClickRemoveImage}
                                handleClickSetImage={this.handleClickSetImage}
                            />
                            {this.props.user.images.length > 4 && (
                                <div className="holderEnclosed"><Link to={'/user/profile/' + this.props.user.user_id + '/gallery'}>+ {this.state.gallery.length - 4} More</Link></div>
                            )}
                        </div>
                    </React.Fragment>
                )}
                {this.state.activities.length > 1 && (
                    <React.Fragment>
                        <WhiteTitle className="activities-section">Activities</WhiteTitle>
                        <ul className="activitiesListSearch readAll">
                            {this.state.activities}
                        </ul>
                    </React.Fragment>
                )}
                {this.state.loadingMore && (<Spinner />)}
            </React.Fragment>
        );

        const column3 = (
            <React.Fragment>
                <InviteFriends wrapperClass="noWrapper" />
                <WhiteTitle>Suggested Friends</WhiteTitle>
                <FindFriendsComponent perPage={5} infiniteScroll={false} />
                <Link to="/findfriends" className="square-button greenBG fontWhite h6">View all</Link>
            </React.Fragment>
        );

        const titleFirstName = this.props.user.user_firstname;
        const titleLastName = this.props.user.user_lastname;
        const pageDescription = this.props.user.user_aboutme || titleFirstName + ' ' + titleLastName + '\'s profile';

        return (
            <div>
                <HelmetTags
                    title={titleFirstName + titleLastName || ''}
                    description={pageDescription || ''}
                />
                {this.props.user && (
                    <UserProfileHeader
                        user={this.props.user}
                        image={this.state.image}
                        right={userProfileButtons}
                    />
                )}
                {this.props.user && (
                    <div className="three-column-layout">
                        <Columns
                            c1={column1}
                            c2={column2}
                            c3={column3}
                        />
                    </div>
                )}
            </div>
        );
    }
}

OtherUserProfile.propTypes = {
    dogs: PropTypes.instanceOf(Array),
    user: PropTypes.instanceOf(Object).isRequired,
    credentials: PropTypes.instanceOf(Object).isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.instanceOf(Object).isRequired,
    userData: PropTypes.instanceOf(Object).isRequired,
    ownProfile: PropTypes.bool.isRequired
};
