import React from 'react';

const Avatar = props => {
    let image = (
        <i
            className={'icn icon-no-image ' + (props.className ? props.className : '')}
            onClick={props.onClick ? props.onClick : null}
        />
    );

    if (props.src) {
        image = (
            <img
                className={props.className ? props.className : ''}
                src={props.src}
                alt={props.alt}
                onClick={props.onClick ? props.onClick : null}
            />
        );
    } else if (props.type === 'blank') {
        image = null;
    } else if (props.type === 'location') {
        image = (
            <i
                className={'icn icon-location-pin-white ' + (props.className ? props.className : '')}
                onClick={props.onClick ? props.onClick : null}
            />
        );
    } else if (props.type === 'user') {
        image = (
            <i
                className={'icn icon-user ' + (props.className ? props.className : '')}
                onClick={props.onClick ? props.onClick : null}
            />
        );
    } else if (props.type === 'dog') {
        image = (
            <i
                className={'icn icon-dog-default-white ' + (props.className ? props.className : '')}
                onClick={props.onClick ? props.onClick : null}
            />
        );
    }

    return (
        <React.Fragment>
            {image}
        </React.Fragment>
    );
};

export default Avatar;
