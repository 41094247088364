import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { resetPassword, login } from '../../redux/actions/users';
import sb from '../../helpers/SendBird';
import { guard } from '../../helpers/Functions';
import ApiError from '../../helpers/ApiError';

class ModalPasswordReset extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: null,
            confirmPassword: null,
            pin: '',
            pause: false
        };
    }

    componentDidMount() {
        const params = new URLSearchParams(window.location.search);
        const pin = params.get('pin'); // bar
        this.setState({ pin });
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    verifyData = e => {
        e.preventDefault();
        if (this.state.pause) return;

        if (!this.state.password || !this.state.confirmPassword || (this.state.password !== this.state.confirmPassword)) {
            toast('Please verify your passwords!');
        } else {
            this.setState({ pause: true });
            const that = this;
            const data = {
                user_email: this.props.userData.user_email,
                user_password: this.state.password,
                user_pin: this.state.pin
            };

            this.props.dispatch(resetPassword(data))
                .then(() => {
                    that.props.dispatch(login(that.props.userData.user_email, that.state.password))
                        .then(response => {
                            console.log(response);
                            toast.success('Welcome Back!');

                            sb.connect(
                                that.props.credentials.user_id,
                                that.props.credentials.token,
                                () => {
                                    sb.updateCurrentUserInfo(
                                        that.props.userData.user_firstname + ' ' + that.props.userData.user_lastname,
                                        guard(that.props.userData, '.images.0.image_smallurl') || '',
                                        () => { }
                                    );
                                }
                            );

                            that.setState({ pause: false });
                            that.props.handleClose();
                        });
                })
                .catch(err => {
                    ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                    that.setState({ pause: false });
                });
        }
    }

    render() {
        return (
            <div className="modal display-block">
                <section className="modalConfirmPasswordReset">
                    <div className="confirmPasswordModalContainer">
                        <section className="modalTopSection">
                            <h1>Confirm Password</h1>
                            <i className="icn icon-left-arrow" onClick={this.props.showModalForgotPassword} />
                        </section>
                        <form onSubmit={this.verifyData}>
                            <div className="innerLabelInput">
                                <span className="blocking-span">
                                    <input className="inputText" id="pin" name="pin" value={this.state.pin || ''} onChange={this.handleChange} required />
                                    <span className="floating-label">PIN (emailed to you)</span>
                                </span>
                            </div>
                            <div className="innerLabelInput">
                                <span className="blocking-span">
                                    <input className="inputText" id="password" name="password" type="password" value={this.state.password || ''} onChange={this.handleChange} required />
                                    <span className="floating-label">New Password</span>
                                    {this.state.password && this.state.confirmPassword && this.state.password === this.state.confirmPassword
                                        && <i className="icn icon-check-mark fontWhite" />}
                                </span>
                            </div>
                            <div className="innerLabelInput">
                                <span className="blocking-span">
                                    <input className="inputText" id="confirmPassword" name="confirmPassword" type="password" value={this.state.confirmPassword || ''} onChange={this.handleChange} required />
                                    <span className="floating-label">Repeat New Password</span>
                                    {this.state.password && this.state.confirmPassword && this.state.password === this.state.confirmPassword
                                        && <i className="icn icon-check-mark fontWhite" />}
                                </span>
                            </div>
                            <input type="submit" value="Reset My Password &gt;" className="rightRoundButton whiteBorder whiteOpaque fontWhite" />
                        </form>
                    </div>
                </section>
                <i className="icn icon-cross fontWhite" onClick={this.props.handleClose} />
                <div className="wholeScreen" onClick={this.props.handleClose} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(ModalPasswordReset);
