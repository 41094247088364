import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { setFirstName, setLastName } from '../../redux/actions/users';
import ApiError from '../../helpers/ApiError';
import TopNav from './TopNav';

class RegisterUser extends React.Component {
    componentDidMount() {
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    verifyData = e => {
        e.preventDefault();
        if (!this.props.userData.user_firstname || !this.props.userData.user_lastname) {
            ApiError('Please write your First Name and Last Name');
        } else {
            this.props.history.push('/registeremail');
        }
    }

    render() {
        return (
            <div className="mainAppContainer LoginScreens Register">
                <TopNav backLink="/register" />
                <form onSubmit={this.verifyData}>
                    <div className="LoginWrapper wrapper">
                        <h1>What's your name?</h1>
                    </div>
                    <div className="LoginWrapper wrapper maxWidth">
                        <div className="innerLabelInput">
                            <span className="blocking-span">
                                <input
                                    className="inputText"
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    onChange={e => this.props.dispatch(setFirstName(e.target.value))}
                                    required
                                    value={this.props.userData.user_firstname}
                                />
                                <span className="floating-label">First Name</span>
                            </span>
                        </div>
                        <div className="innerLabelInput">
                            <span className="blocking-span">
                                <input
                                    className="inputText"
                                    id="lastName"
                                    name="lastName"
                                    type="text"
                                    onChange={e => this.props.dispatch(setLastName(e.target.value))}
                                    required
                                    value={this.props.userData.user_lastname}
                                />
                                <span className="floating-label">Last Name</span>
                            </span>
                        </div>
                        <input value="Next &gt;" type="submit" className="rightRoundButton whiteBorder whiteOpaque fontWhite" />
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(RegisterUser);
