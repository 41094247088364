import React from 'react';
import { Link } from 'react-router-dom';

export const PlaydateNavigation = ({ showTitle, selected }) => (
    <React.Fragment>
        {showTitle && (
            <div className="simple-title">
                <i className="icn icon-tennis-ball fontBlue" /> <b className="fontBlack">Events</b>
            </div>
        )}
        <div className="tabSubmenu">
            <div className="row maxWidth">
                <Link to="/events" className={(selected === 'events' ? 'active' : '')}>
                    Events
                </Link>
                <Link to="/events/manage/event" className={(selected === 'manage' ? 'active' : '')}>
                    Hosted
                </Link>
                <Link to="/events/create/event" className={(selected === 'create' ? 'active' : '')}>
                    Create
                </Link>
            </div>
        </div>
    </React.Fragment>
);
