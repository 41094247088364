import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import Config from '../helpers/Config';
import ApiError from '../helpers/ApiError';
import { urlFriendly, formatShortDate } from '../helpers/Functions';
import Avatar from '../layout/UI/Avatar';
import ActionButtons from '../layout/UI/actions/ActionButtons';
import GalleryLightbox from '../layout/gallery/GalleryLightbox';
import { Stars } from '../layout/UI/Stars';
import BasicConfirm from '../layout/UI/BasicConfirm';
import { deleteActivity } from '../redux/actions/activities';

/*
README:

On this new version of activities we will always display a picture of the user or the dog depending on the action.

User has uploaded a new picture of the dog?
Show the main picture of the dog and Link to the dogs profile.

User has uploaded a new picture of a Location?
Show the main picture of the User and then add a Big Pic of the new Location Picture

User has lost or found a dog?
Show the picture of the user in the main avatar and show a BIG picture of the dog in the activity.

A user has checked into, favorited or reviewed a location?
Show the avatar of the user and add a picture of the location.
*/
export class ActivityListItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleted: false,
            winScroll: 0
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll);
        if (this.props.winScroll > 0) {
            this.setState({ winScroll: this.props.winScroll }, () => {
                window.scrollTo(0, this.state.winScroll);
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll);
    }

    gallery = (images, count = 2, size = 'normal') => {
        let classGalleryHolder = 'galleryHolder';
        let isMobile = false;
        if (this.isMobileDevice()) {
            isMobile = true;
        }
        if (count === 1 && !isMobile) {
            classGalleryHolder = 'galleryHolder bigImage';
        }
        return (
            <div className={classGalleryHolder}>
                <GalleryLightbox
                    images={images}
                    count={count}
                    size={size}
                />
                {count === 2 && (
                    <div className="holderEnclosed">
                        <div>View Gallery</div>
                    </div>
                )}
            </div>
        );
    }

    deleteActivity = id => {
        BasicConfirm(
            'Are you sure?',
            'Do you want to delete this Activity?',
            'Yes, delete',
            () => {
                this.props.dispatch(deleteActivity(this.props.credentials.token, id))
                    .then(() => {
                        this.setState({ deleted: true });
                        toast.success('Activity removed successfully.');
                    })
                    .catch(err => {
                        console.log(err);
                        ApiError('Something went wrong... Please refresh the page.');
                    });
            }
        );
    }

    listenToScroll = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        this.setState({
            winScroll
        });
    }

    isMobileDevice() {
        return (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
    }

    render() {
        const activity = this.props.activity;
        if (activity) {
            let entityId = activity.activity_id;
            let deepActivity = null;
            let link = ''; // this is the link that when pressed it will take the user to
            let details; // this is the text that will show on the activity
            let additionalDetails = null;
            let bigImage;
            let bigImageX1;
            let bigImageX2;
            let bigImageX3 = null; // this is a set of images, if a user uploaded an image it will show an image set, small, medium and large, for retina and bigger screens
            const action = (activity.entity && activity.entity.entity_type) || false; // this is what we are using to properly SWITCH/CASE and show relevant data
            let iconType = null; // the avatars are usually linked to a small icon, some instances we need to change the icon type, specifically for lost and found dogs
            let showEntry = false; // this is to programatically know if we are going to show the activity in the feed
            const date = formatShortDate(activity.activity_createdat);
            const address = activity?.useraddress?.address?.address_city && activity?.useraddress?.address?.address_state ? `${activity.useraddress.address.address_city}, ${activity.useraddress.address.address_state}` : '';
            const addressDate = address ? `${date} - ${address}` : date;
            let linkToUserId = (activity.user && activity.user.user_id) || this.props.credentials.user_id; // sometimes the user id does change depending of the activity type
            let imageset = false;
            let avatar = null;
            let charCount;
            let maxChar;
            let size;
            let fontSize;
            let yourActivity;
            const commentCounter = activity.CommentCounter;

            if (activity.post_id) {
                entityId = activity.activity.activity_id;
                showEntry = true;
                avatar = (activity.user.images && activity.user.images[0] && activity.user.images[0].image_smallurl); // this is the small avatar of the person that created the activity
                link = '/activity/' + activity.activity.activity_id;
                if (activity.user.user_id === this.props.credentials.user_id) {
                    details = 'You';
                    yourActivity = true;
                } else {
                    details = activity.user.user_firstname + ' ' + activity.user.user_lastname;
                }

                if (activity.images.length === 1) {
                    bigImageX1 = activity.images[0].image_smallurl;
                    bigImageX2 = activity.images[0].image_mediumurl;
                    bigImageX3 = activity.images[0].image_largeurl;
                    details = (
                        <React.Fragment>
                            {details} <span className="fontGreen">uploaded 1 picture</span>
                        </React.Fragment>
                    );
                } else if (activity.images.length > 1) {
                    imageset = this.gallery(activity.images);
                    details = (
                        <React.Fragment>
                            {details} <span className="fontGreen">uploaded {activity.images.length} pictures</span>
                        </React.Fragment>
                    );
                }

                if (activity.post_body) {
                    charCount = activity.post_body.length;
                    maxChar = 80;
                    size = maxChar / charCount;
                    fontSize = size.toFixed(1) * 10;
                    if (fontSize > 20) {
                        fontSize = 20;
                    } else if (fontSize < 10) {
                        fontSize = 10;
                    }

                    additionalDetails = (
                        <div className="post">
                            <div className={'additionalDetails x' + fontSize}>
                                {activity.post_body.split('\n').map((item, index) => <div key={index}>{item}</div>)}
                            </div>
                        </div>
                    );
                }
            } else if (activity?.entity && activity?.user?.user_id && activity?.user?.user_firstname && action !== false) {
                avatar = (activity.user.images && activity.user.images[0] && activity.user.images[0].image_smallurl); // this is the small avatar of the person that created the activity
                switch (action) {
                    // for when a user checked in to a location
                    case 'post':
                        deepActivity = activity.entity.post;
                        if (deepActivity?.post_id) {
                            showEntry = true;
                            avatar = (activity.user.images && activity.user.images[0] && activity.user.images[0].image_smallurl); // this is the small avatar of the person that created the activity
                            link = '/activity/' + activity.activity_id;
                            if (activity.user.user_id === this.props.credentials.user_id) {
                                details = 'You';
                                yourActivity = true;
                            } else {
                                details = activity.user.user_firstname + ' ' + activity.user.user_lastname;
                            }

                            if (deepActivity.images.length === 1) {
                                bigImageX1 = deepActivity.images[0].image_smallurl;
                                bigImageX2 = deepActivity.images[0].image_mediumurl;
                                bigImageX3 = deepActivity.images[0].image_largeurl;
                                details = (
                                    <React.Fragment>
                                        {details} <span className="fontGreen">uploaded 1 picture</span>
                                    </React.Fragment>
                                );
                            } else if (deepActivity.images.length > 1) {
                                imageset = this.gallery(deepActivity.images);
                                details = (
                                    <React.Fragment>
                                        {details} <span className="fontGreen">uploaded {deepActivity.images.length} pictures</span>
                                    </React.Fragment>
                                );
                            }

                            if (deepActivity.post_body) {
                                charCount = deepActivity.post_body.length;
                                maxChar = 80;
                                size = maxChar / charCount;
                                fontSize = size.toFixed(1) * 10;
                                if (fontSize > 20) {
                                    fontSize = 20;
                                } else if (fontSize < 10) {
                                    fontSize = 10;
                                }
                                additionalDetails = (
                                    <div className="post">
                                        <div className={'additionalDetails x' + fontSize}>
                                            {deepActivity.post_body.split('\n').map((item, index) => <div key={index}>{item}</div>)}
                                        </div>
                                    </div>
                                );
                            }
                        }
                        break;

                    case 'checkin':
                        deepActivity = activity.entity.checkin;
                        if (deepActivity?.location?.[0]?.location_name) {
                            showEntry = true;
                            // show location image if it has an image
                            // else show user avatar
                            bigImage = (deepActivity.location[0].images && deepActivity.location[0].images[0] && deepActivity.location[0].images[0]);
                            if (bigImage) {
                                bigImageX1 = bigImage.image_smallurl;
                                bigImageX2 = bigImage.image_mediumurl;
                                bigImageX3 = bigImage.image_largeurl;
                            }
                            link = '/location/profile/' + deepActivity.location_id + '/' + urlFriendly(deepActivity.location[0].location_name);
                            if (this.props.credentials.user_id === activity.user.user_id) {
                                details = 'You checked in to ' + deepActivity.location[0].location_name;
                                yourActivity = true;
                            } else {
                                details = activity.user.user_firstname + ' checked in to ' + deepActivity.location[0].location_name;
                            }
                        }
                        break;

                    // for when a user added a location
                    case 'location':
                        // show location image if it has an image
                        // else show user avatar
                        deepActivity = activity.entity.location;
                        if (deepActivity?.location_name) {
                            showEntry = true;
                            bigImage = (deepActivity.images && deepActivity.images[0] && deepActivity.images[0]);
                            if (bigImage) {
                                bigImageX1 = bigImage.image_smallurl;
                                bigImageX2 = bigImage.image_mediumurl;
                                bigImageX3 = bigImage.image_largeurl;
                            }
                            link = '/location/profile/' + deepActivity.location_id + '/' + urlFriendly(deepActivity.location_name);
                            if (this.props.credentials.user_id === activity.user.user_id) {
                                details = 'You added a Location: ' + deepActivity.location_name;
                                yourActivity = true;
                            } else {
                                details = activity.user.user_firstname + ' added a Location: ' + deepActivity.location_name;
                            }
                        }
                        break;

                    // for when a user reviewed a location
                    case 'review':
                        deepActivity = activity.entity.review;
                        if (deepActivity?.location?.location_id) {
                            showEntry = true;
                            if (deepActivity?.images?.length > 0) {
                                if (deepActivity.images.length === 1) {
                                    bigImageX1 = deepActivity.images[0].image_smallurl;
                                    bigImageX2 = deepActivity.images[0].image_mediumurl;
                                    bigImageX3 = deepActivity.images[0].image_largeurl;
                                } else {
                                    imageset = this.gallery(deepActivity.images);
                                }
                            } else if (deepActivity?.location?.images?.length > 0) {
                                bigImageX1 = deepActivity.location.images[0].image_smallurl;
                                bigImageX2 = deepActivity.location.images[0].image_mediumurl;
                                bigImageX3 = deepActivity.location.images[0].image_largeurl;
                            }
                            link = '/location/profile/' + deepActivity.location.location_id + '/' + urlFriendly(deepActivity.location.location_name);
                            if (this.props.credentials.user_id === activity.user.user_id) {
                                details = 'You reviewed: ' + deepActivity.location.location_name;
                                yourActivity = true;
                            } else {
                                details = (activity.user.user_firstname) + ' reviewed: ' + deepActivity.location.location_name;
                            }

                            additionalDetails = (
                                <div className="review">
                                    <Stars stars={deepActivity.review_rating} />
                                    {deepActivity.review_comment && <div className="additionalDetails">{deepActivity.review_comment}</div>}
                                </div>
                            );
                        }
                        break;

                    // for when a user adds a dog
                    case 'userdog':
                        // show dog avatar
                        // else show user avatar
                        deepActivity = activity.entity.userdog;
                        if (deepActivity?.dog?.dog_id) {
                            showEntry = true;
                            /*
                            bigImage = (deepActivity.dog && deepActivity.dog.images && deepActivity.dog.images[0] && deepActivity.dog.images[0])
                            if(bigImage) {
                                bigImageX1 = bigImage.image_smallurl
                                bigImageX2 = bigImage.image_mediumurl
                                bigImageX3 = bigImage.image_largeurl
                            } */
                            link = '/dog/' + deepActivity.dog.dog_id;
                            if (this.props.credentials.user_id === activity.user.user_id) {
                                details = 'You added a new Dog: ' + deepActivity.dog.dog_name;
                                yourActivity = true;
                            } else {
                                details = activity.user.user_firstname + ' added a new Dog: ' + deepActivity.dog.dog_name;
                            }
                        }
                        break;

                    // -----> WHEN DOG IS LOST <-----
                    case 'dogaddress':
                        // show dog avatar
                        // else show user avatar
                        deepActivity = activity.entity.dogaddress;
                        if (deepActivity?.dog?.dog_id) {
                            showEntry = true;
                            bigImage = (deepActivity.dog.images && deepActivity.dog.images[0] && deepActivity.dog.images[0]);
                            if (bigImage) {
                                bigImageX1 = bigImage.image_smallurl;
                                bigImageX2 = bigImage.image_mediumurl;
                                bigImageX3 = bigImage.image_largeurl;
                            }
                            // if the activity row table has the same datetime as dog reported date time means that the dog is reported as lost
                            if (activity.activity_createdat === deepActivity.dogaddress_createdat) {
                                if (this.props.credentials.user_id === activity.user.user_id) {
                                    details = 'You have lost ' + deepActivity.dog.dog_name + '!';
                                    yourActivity = true;
                                } else {
                                    details = activity.user.user_firstname + ' lost ' + deepActivity.dog.dog_name + '!';
                                }
                                iconType = 'lost';
                            } else if (deepActivity.dogaddress_found) {
                                // else if the datetime is different and the dog is marked as found then display it as found
                                if (this.props.credentials.user_id === activity.user.user_id) {
                                    details = deepActivity.dog.dog_name + ' has been found!';
                                    yourActivity = true;
                                } else {
                                    details = activity.user.user_firstname + ' found ' + deepActivity.dog.dog_name + '!';
                                }
                                iconType = 'found';
                            } else {
                                showEntry = false;
                            }
                            link = '/dog/' + deepActivity.dog.dog_id;
                        }
                        break;

                    case 'friendship':
                        deepActivity = activity.entity.friendship;
                        if (deepActivity?.sender?.user_id && deepActivity?.receiver?.user_id) {
                            showEntry = true;
                            // if user made a friend but it is not you (friend A befriended user B)
                            if (activity.user_id !== deepActivity.receiver.user_id && this.props.credentials.user_id !== deepActivity.receiver.user_id) {
                                bigImage = (deepActivity.receiver.images && deepActivity.receiver.images[0] && deepActivity.receiver.images[0]);
                                if (bigImage) {
                                    avatar = bigImage.image_smallurl;
                                    bigImageX1 = bigImage.image_smallurl;
                                    bigImageX2 = bigImage.image_mediumurl;
                                    bigImageX3 = bigImage.image_largeurl;
                                }
                                details = '' + activity.user.user_firstname + ' is now friends with ' + deepActivity.receiver.user_firstname + '!';
                                link = '/user/profile/' + deepActivity.receiver.user_id;
                            } else if (activity.user_id !== deepActivity.sender.user_id && this.props.credentials.user_id !== deepActivity.sender.user_id) {
                                // if user made a friend but it is not you (user B befriended friend A)
                                avatar = (deepActivity.sender.images && deepActivity.sender.images[0] && deepActivity.sender.images[0].image_smallurl);
                                details = activity.user.user_firstname + ' is now friends with ' + deepActivity.sender.user_firstname + '!';
                                link = '/user/profile/' + deepActivity.sender.user_id;
                            } else if (deepActivity.friendship_status === 'unfriended') {
                                // if you unfriended user
                                details = 'You have unfriended ' + activity.user.user_firstname;
                                bigImage = (deepActivity.receiver && deepActivity.receiver.images && deepActivity.receiver.images[0] && deepActivity.receiver.images[0]);
                                if (bigImage) {
                                    avatar = bigImage.image_smallurl;
                                    bigImageX1 = bigImage.image_smallurl;
                                    bigImageX2 = bigImage.image_mediumurl;
                                    bigImageX3 = bigImage.image_largeurl;
                                }
                                link = '/user/profile/' + deepActivity.sender.user_id;
                            } else {
                                // if user made a friend but it IS you
                                if (deepActivity.friendship_status === 'pending') {
                                    details = activity.user.user_firstname + ' sent you a friend request...';
                                } else {
                                    details = 'You are now friends with ' + activity.user.user_firstname;
                                }
                                bigImage = activity.user.images && activity.user.images[0] && activity.user.images[0];
                                if (bigImage) {
                                    bigImageX1 = bigImage.image_smallurl;
                                    bigImageX2 = bigImage.image_mediumurl;
                                    bigImageX3 = bigImage.image_largeurl;
                                }
                                link = '/user/profile/' + activity.user.user_id;
                            }
                        }
                        break;

                    case 'group':
                    case 'image':
                        let showGallery = false;
                        if (activity.entity.entity_type === 'group') {
                            deepActivity = activity.groupentity;
                            showGallery = true;
                        } else {
                            deepActivity = activity.entity.image;
                            bigImageX1 = deepActivity.image_smallurl;
                            bigImageX2 = deepActivity.image_mediumurl;
                            bigImageX3 = deepActivity.image_largeurl;
                        }
                        let image_count = 'a picture';

                        if (deepActivity?.entity?.entity_type) {
                            showEntry = true;
                            avatar = (activity.user.images && activity.user.images[0] && activity.user.images[0].image_smallurl);

                            if (showGallery && activity?.activity_additionalinfo?.images) {
                                if (activity.activity_additionalinfo.images.length > 1) {
                                    image_count = activity.activity_additionalinfo.images.length + ' pictures';
                                    imageset = this.gallery(activity.activity_additionalinfo.images);
                                } else {
                                    bigImageX1 = activity.activity_additionalinfo.images[0].image_smallurl;
                                    bigImageX2 = activity.activity_additionalinfo.images[0].image_mediumurl;
                                    bigImageX3 = activity.activity_additionalinfo.images[0].image_largeurl;
                                }
                            }

                            switch (deepActivity.entity.entity_type) {
                                case 'location':
                                    if (this.props.credentials.user_id === activity.user.user_id) {
                                        details = (
                                            <React.Fragment>
                                                You <span className="fontGreen">uploaded {image_count}</span> to {deepActivity.entity.location.location_name}
                                            </React.Fragment>
                                        );
                                        yourActivity = true;
                                    } else {
                                        details = (
                                            <React.Fragment>
                                                {activity.user.user_firstname} <span className="fontGreen">uploaded {image_count}</span> to {deepActivity.entity.location.location_name}
                                            </React.Fragment>
                                        );
                                    }
                                    link = '/location/profile/' + deepActivity.entity.entity_id + '/' + urlFriendly(deepActivity.entity.location.location_name);
                                    break;

                                case 'dog':
                                    if (this.props.credentials.user_id === activity.user.user_id) {
                                        details = 'You uploaded ' + image_count + ' for ' + deepActivity.entity.dog.dog_name;
                                        yourActivity = true;
                                    } else {
                                        details = activity.user.user_firstname + ' uploaded ' + image_count + ' for ' + deepActivity.entity.dog.dog_name;
                                    }
                                    link = '/dog/' + deepActivity.entity.entity_id;
                                    break;

                                case 'user':
                                    if (this.props.credentials.user_id === activity.user.user_id) {
                                        details = 'You uploaded ' + image_count;
                                        yourActivity = true;
                                    } else {
                                        details = activity.user.user_firstname + ' uploaded ' + image_count;
                                    }
                                    link = '/user/profile/' + deepActivity.entity.entity_id;
                                    break;
                                default:
                                    showEntry = false;
                                    break;
                            }
                        }
                        break;

                    case 'playdate':
                        deepActivity = activity.entity.playdate;
                        if (deepActivity?.playdate_id) {
                            showEntry = true;
                            bigImage = (deepActivity.images && deepActivity.images[0] && deepActivity.images[0]) || (deepActivity.location && deepActivity.location.images && deepActivity.location.images[0] && deepActivity.location.images[0]);
                            if (bigImage) {
                                bigImageX1 = bigImage.image_smallurl;
                                bigImageX2 = bigImage.image_mediumurl;
                                bigImageX3 = bigImage.image_largeurl;
                            }
                            if (this.props.credentials.user_id === activity.user.user_id) {
                                details = 'You created a Playdate at ' + (deepActivity.location && deepActivity.location.location_name);
                                yourActivity = true;
                            } else {
                                details = activity.user.user_firstname + ' created a Playdate at ' + (deepActivity.location && deepActivity.location.location_name);
                            }
                            link = '/events/' + deepActivity.playdate_id;
                        }
                        break;

                    case 'playdateuser':
                        deepActivity = activity.entity.playdateuser;
                        if (deepActivity?.playdate) {
                            showEntry = true;
                            // [if] you have been invited to a playdate by deepActivity.playdate.owner.user_firstname
                            // [else] someone STATUS (deepActivity.playdateuser_status) your playdate: deepActivity.user.user_firstname
                            bigImage = (deepActivity.playdate && deepActivity.playdate.images && deepActivity.playdate.images[0] && deepActivity.playdate.images[0]) || (deepActivity.playdate && deepActivity.playdate.location && deepActivity.playdate.location.images && deepActivity.playdate.location.images[0] && deepActivity.playdate.location.images[0]);
                            if (bigImage) {
                                bigImageX1 = bigImage.image_smallurl;
                                bigImageX2 = bigImage.image_mediumurl;
                                bigImageX3 = bigImage.image_largeurl;
                            }
                            if (deepActivity.user_id === this.props.credentials.user_id) {
                                avatar = (deepActivity.playdate.owner && deepActivity.playdate.owner.images && deepActivity.playdate.owner.images[0] && deepActivity.playdate.owner.images[0].image_smallurl);
                                details = (deepActivity.playdate.owner && deepActivity.playdate.owner.user_firstname) + ' invited you to a Playdate at ' + (deepActivity.playdate.location && deepActivity.playdate.location.location_name);
                                linkToUserId = deepActivity.playdate.owner.user_id;
                            } else {
                                switch (deepActivity.playdateuser_status) {
                                    case 'joined':
                                        details = activity.user.user_firstname + ' joined ';
                                        break;
                                    case 'maybe':
                                        details = activity.user.user_firstname + ' is interested in ';
                                        break;
                                    default:
                                        details = activity.user.user_firstname + ' cannot attend ';
                                        break;
                                }
                                if ((deepActivity.playdate.owner && deepActivity.playdate.owner_id) === this.props.credentials.user_id) {
                                    details += 'your Playdate';
                                } else {
                                    details = details + (deepActivity.playdate.owner && deepActivity.playdate.owner.user_firstname) + "'s Playdate";
                                }
                            }
                            link = '/events/' + deepActivity.playdate_id;
                        }
                        break;

                    case 'favoritelocation':
                        deepActivity = activity.entity.favoritelocation;
                        if (deepActivity?.location?.location_id) {
                            showEntry = true;
                            bigImage = deepActivity.location.images && deepActivity.location.images[0] && deepActivity.location.images[0];
                            if (bigImage) {
                                bigImageX1 = (bigImage.image_smallurl);
                                bigImageX2 = (bigImage.image_mediumurl);
                                bigImageX3 = (bigImage.image_largeurl);
                            }
                            if (this.props.credentials.user_id === activity.user.user_id) {
                                yourActivity = true;
                                details = 'You favorited a location: ' + (deepActivity.location.location_name);
                            } else {
                                details = activity.user.user_firstname + ' favorited a location: ' + (deepActivity.location.location_name);
                            }
                            link = '/location/profile/' + deepActivity.location.location_id + '/' + urlFriendly(deepActivity.location.location_name);
                        }
                        break;

                    default:
                        showEntry = false;
                        break;
                }
            } else {
                showEntry = false;
            }

            let icon = null;
            if ((action === 'dogaddress') && (iconType === 'found')) {
                icon = (
                    <img
                        className="icon-type"
                        alt="Dog Found"
                        src={Config.fileDirectory + 'assets/images/check-in-fill.png'}
                        srcSet={Config.fileDirectory + 'assets/images/check-in-fill.png 1x,' + Config.fileDirectory + 'assets/images/check-in-fill@2x.png 2x'}
                    />
                );
            }

            if ((action === 'dogaddress') && (iconType === 'lost')) {
                icon = (
                    <img
                        className="icon-type"
                        alt="Dog Lost"
                        src={Config.fileDirectory + 'assets/images/sign-out.png'}
                        srcSet={Config.fileDirectory + 'assets/images/sign-out.png 1x,' + Config.fileDirectory + 'assets/images/sign-out@2x.png 2x'}
                    />
                );
            }

            if (action === 'checkin' || action === 'review') {
                icon = (
                    <img
                        className="icon-type"
                        alt="CheckIn Icon"
                        src={Config.fileDirectory + 'assets/images/check-in-fill.png'}
                        srcSet={Config.fileDirectory + 'assets/images/check-in-fill.png 1x,' + Config.fileDirectory + 'assets/images/check-in-fill@2x.png 2x'}
                    />
                );
            }

            if (action === 'playdate' || action === 'playdateuser') {
                icon = (
                    <img
                        className="icon-type"
                        alt="Playdate Icon"
                        src={Config.fileDirectory + 'assets/images/playdate-icon.png'}
                        srcSet={Config.fileDirectory + 'assets/images/playdate-icon.png 1x,' + Config.fileDirectory + 'assets/images/playdate-icon@2x.png 2x'}
                    />
                );
            }

            if (action === 'dog' || action === 'userdog' || action === 'friendship' || action === 'FriendAccepted' || action === 'image' || action === 'group' || action === 'location') {
                icon = (
                    <img
                        className="icon-type"
                        alt="Add Icon"
                        src={Config.fileDirectory + 'assets/images/more-add.png'}
                        srcSet={Config.fileDirectory + 'assets/images/more-add.png 1x,' + Config.fileDirectory + 'assets/images/more-add@2x.png 2x'}
                    />
                );
            }

            if (action === 'CancelledPlaydate') {
                icon = (
                    <img
                        className="icon-type"
                        alt="Cancel Icon"
                        src={Config.fileDirectory + 'assets/images/sign-out.png'}
                        srcSet={Config.fileDirectory + 'assets/images/sign-out.png 1x,' + Config.fileDirectory + 'assets/images/sign-out@2x.png 2x'}
                    />
                );
            }

            if (action === 'favoritelocation') {
                icon = (
                    <img
                        className="icon-type"
                        alt="Favorite Icon"
                        src={Config.fileDirectory + 'assets/images/heart-fill.png'}
                        srcSet={Config.fileDirectory + 'assets/images/heart-fill.png 1x,' + Config.fileDirectory + 'assets/images/heart-fill@2x.png 2x'}
                    />
                );
            }

            let className = 'activityItem';
            if (this.state.deleted) className += ' deleted';

            return showEntry && (
                <div key={this.props.activity.activity_id} className={className}>
                    {!!imageset && imageset}
                    {bigImageX1 && !this.props.disableImages && !imageset && activity.activity_additionalinfo
                        && this.gallery(activity.activity_additionalinfo.images, 1, 'large')}
                    {bigImageX1 && !this.props.disableImages && !imageset && !activity.activity_additionalinfo && (
                        <Link to={link} className="bigImage">
                            <picture>
                                {bigImageX3 && (<source media="(min-width:650px)" srcSet={bigImageX3} />)}
                                {bigImageX2 && (<source media="(min-width:465px)" srcSet={bigImageX2} />)}
                                <img src={bigImageX1} alt={details} />
                            </picture>
                        </Link>
                    )}
                    <div className={'detailsHolder row ' + (!activity.SeenOn ? 'unseen' : '')}>
                        <Link to={{ pathname: '/user/profile/' + linkToUserId }} className="round-avatar user colored-icon">
                            <Avatar
                                src={avatar}
                                alt={details}
                                type="user"
                            />
                            {icon}
                        </Link>
                        <Link to={{ pathname: link, winScroll: this.state.winScroll }} className="info">
                            {details}
                            <div className="h7 fontDarkGray">{addressDate}</div>
                            {additionalDetails}
                        </Link>
                        {yourActivity && (
                            <div className="delete-activity-icon" onClick={() => this.deleteActivity(entityId)}>
                                <i className="icn icon-sign-out fontGray" />
                            </div>
                        )}
                    </div>
                    {!this.props.disableActions && (
                        <ActionButtons
                            dispatch={this.props.dispatch}
                            credentials={this.props.credentials}
                            actionBySelf={activity.ActionBySelf}
                            entityId={entityId}
                            EntityActionCounters={activity.EntityActionCounters}
                            commentCounter={commentCounter}
                        />
                    )}
                </div>
            );
        }
    }
}

ActivityListItem.propTypes = {
    dispatch: PropTypes.func,
    credentials: PropTypes.instanceOf(Object).isRequired,
    activity: PropTypes.instanceOf(Object).isRequired,
    disableImages: PropTypes.bool,
    disableActions: PropTypes.bool
};
