import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { getUsers } from '../../redux/actions/users';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import AdministratorsUsersList from '../../layout/AdministratorsUsersList';
import Spinner from '../../layout/UI/Spinner';
import NotFound from '../../layout/NotFound';
import { Pull2Refresh, infiniteScroll } from '../../helpers/Pull2Refresh';
import ApiError from '../../helpers/ApiError';

let delayTimer;

class AdminUsersList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            reloading: false,
            searchText: '',
            loadingMore: false,
            currentPage: 1,
            totalPages: 1, // This it's being used by infiniteScroll->handleScroll->loadMore
            users: []
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.start();
        Pull2Refresh(this.start);
    }

    start = () => {
        this.setState({ reloading: true });
        this.search();
    }

    search = () => {
        this.props.dispatch(getUsers(this.props.credentials.token, this.state.searchText, this.state.currentPage))
            .then(response => {
                this.setState(
                    prevState => ({
                        loading: false,
                        reloading: false,
                        loadingMore: false,
                        currentPage: prevState.currentPage + 1,
                        totalPages: response.value.headers['x-pagination-page-count'],
                        users: prevState.users.concat(response.value.data)
                    }), () => {
                        if (this.state.currentPage < this.state.totalPages) {
                            infiniteScroll(this, this.search);
                        }
                    }
                );
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loading: false, reloading: false, loadingMore: false });
            });
    }

    handleSearchInput = e => {
        clearTimeout(delayTimer);
        this.setState({ searchText: e.target.value, loading: true, users: [], currentPage: 1 });
        const that = this;
        delayTimer = setTimeout(() => {
            that.search();
            // Do the ajax stuff
        }, 1500);
    }

    onClear = () => {
        clearTimeout(delayTimer);
        this.setState({ searchText: '', loading: true, users: [], currentPage: 1 });
        const that = this;
        delayTimer = setTimeout(() => {
            that.search();
            // Do the ajax stuff
        }, 1500);
    }

    render() {
        return (
            <div className="mainAppContainer">
                <MainNavigation title="Manage Users" />
                {this.props.userData?.user_role !== 'superadmin' && (
                    <Redirect to="/404" />
                )}
                <div className="simpleSearchBar row">
                    <i className="icn icon-search fontDarkGray" />
                    <input onChange={this.handleSearchInput} value={this.state.searchText} placeholder="Search..." />
                    {!!this.state.searchText && (
                        <i onClick={this.onClear} className="icn icon-sign-out fontDarkGray" />
                    )}
                </div>
                {(!!this.state.loading || !!this.state.reloading) && (
                    <div className="bodyContent wrapper noPaddingTop  pull2refresh">
                        <Spinner />
                    </div>
                )}
                {!this.state.loading && !this.state.users.length && (
                    <NotFound type="searchlocations" onClear={this.onClear} />
                )}
                {!this.state.loading && !!this.state.users && !!this.state.users.length && (
                    <div className="bodyContent wrapper noPaddingTop  pull2refresh">
                        <div className="maxWidth">
                            <AdministratorsUsersList list={this.state.users} />
                        </div>
                    </div>
                )}
                {!!this.state.loadingMore && (
                    <div className="bodyContent wrapper noPaddingTop  pull2refresh">
                        <Spinner />
                    </div>
                )}
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(AdminUsersList);
