import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import {
    getLocations,
    setSearchLocation,
    setSearchedLocationsList,
    setSearchedLocationsPosition,
    setMapLastLocationPosition
} from '../../redux/actions/locations';
import { setSearchFilters, getSearchFilters } from '../../redux/actions/searchFilters';
import { LocationListItem } from '../../layout/locations/LocationListItem';
import GoogleMap from '../../layout/locations/GoogleMap';
import Spinner from '../../layout/UI/Spinner';
import SearchBar from '../../layout/UI/SearchBar';
import { getGeoLocation } from '../../helpers/GeoLocation';
import { orderLocationsByDistance } from '../../helpers/Functions';
import HelmetTags from '../../helmetTags';

let delayTimer;

export class MainSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // locations: [],
            locationListItems: [],
            newCenter: null,
            recenter: false,
            loading: true,
            loadingText: 'Loading...',
            // firstLoad: true,
            filterList: [],
            // movingCenter: { lat: 42, lng: -100 },
            // center: { lat: 42, lng: -100 },
            templocations: [],
            // locationBanners: [],
            hasNewCenter: false
        };
    }

    getGeoLocation = getGeoLocation.bind(this)

    componentWillMount() {
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        this.props.dispatch(getSearchFilters(this.props.credentials.token)).then(() => this.sortFilter());
        this.start();
    }

    start = (getFreshLocation = 120000) => getGeoLocation(this.props, this.getNearByLocations, this.props.credentials.token, true, 6000, getFreshLocation)

    recenter = () => {
        this.props.dispatch(setMapLastLocationPosition(null));
        this.setState({ recenter: true }, () => {
            this.setState({ recenter: false });
            this.start(0);
        });
    }

    getNearByLocations = geopoint => {
        let geoLocation = null;
        if (geopoint) {
            geoLocation = this.props.mapLastLocation || geopoint;
            this.props.dispatch(setSearchedLocationsPosition(geopoint));
        }
        if (geoLocation === null) {
            geoLocation = this.props.searchedLocationsPosition || this.props.userData.user_location || this.props.userData.address || null;
        }
        this.setState({ loading: true, loadingText: 'Loading...', recenter: true }, () => {
            this.setState({ recenter: false });
        });
        this.props.dispatch(getLocations(this.props.credentials.token, geoLocation, this.props.selectedSearchFilter, this.props.searchLocation, 1, 100))
            .then(response => {
                this.setState(prevState => ({
                    hasNewCenter: false,
                    templocations: [...prevState.templocations, ...response.value.data]
                }), () => {
                    this.removeDuplicatedLocations(geoLocation);
                    // this.toggleSlider()
                });
            })
            .catch(() => {
                this.setState({ loading: false, loadingText: 'There was an Error...' });
                // this.toggleSlider()
            });
    }

    /**
     * Provide a list for render in Map
     * remove Duplicate ID from list
     * and sum float number to longitud when map find location with the same address
     *
     * doesn't return anything, only modified the list received
     */
    removeDuplicatedLocations = center => {
        const filtered = this.state.templocations.filter((loc, index, self) => index === self.findIndex(l => (
            l.location_id === loc.location_id
        )));

        const orderedByDistance = orderLocationsByDistance(filtered, center);
        const limited = orderedByDistance.slice(0, 100);

        const normalLocations = limited.filter(location => location.location_featured === 0);
        const featuredLocations = limited.filter(location => location.location_featured === 1);

        const list = [...featuredLocations, ...normalLocations];
        this.props.dispatch(setSearchedLocationsList(list));
        const delta = 0.00015;
        const indexedAddresses = {};
        list.map(address => {
            const latitude = parseFloat(address.address.address_latitude);
            let longitude = parseFloat(address.address.address_longitude);
            // Increment the longitude slightly if the pair has been
            // encountered before.
            while (indexedAddresses[longitude + ':' + latitude] !== undefined) {
                longitude += delta;
            }
            indexedAddresses[longitude + ':' + latitude] = true;
            // Add to map.
            address.address = {
                address_latitude: latitude,
                address_longitude: longitude
            };
            return null;
        });
        this.setState({ templocations: list }, this.mapLocationBanners);
    }

    mapLocationBanners = () => {
        this.setState({ loading: false, loadingText: false });
        if (!!this.props.searchedLocationsList && this.props.searchedLocationsList.length > 0) {
            const locationBanners = this.props.searchedLocationsList.map((location, index) => (
                <LocationListItem key={index} {...this.props} location={location} url="location/profile" />
            ));
            this.setState({ locationListItems: locationBanners }/* , this.toggleSlider */);
        } else {
            this.setState({ locationListItems: [], loadingText: 'Nothing Found' });
        }
    }

    centerMoved = geopoint => {
        this.props.dispatch(setMapLastLocationPosition(geopoint));
        this.setState({ hasNewCenter: true, newCenter: geopoint });
    }

    searchNearNewCenter = () => {
        this.setState({ hasNewCenter: false });
        this.getNearByLocations(this.state.newCenter);
    }

    handleSearchInput = e => {
        clearTimeout(delayTimer);
        const value = e.target.value;
        this.props.dispatch(setSearchedLocationsList([]));
        this.props.dispatch(setSearchLocation(value));
        const that = this;
        delayTimer = setTimeout(() => {
            that.setState({ templocations: [] }, () => {
                that.getNearByLocations();
            });
        }, 1500);
    }

    onClear = () => {
        clearTimeout(delayTimer);
        const value = '';
        this.props.dispatch(setSearchedLocationsList([]));
        this.props.dispatch(setSearchLocation(value));
        const that = this;
        this.setState({ loadingText: 'Loading...' });
        delayTimer = setTimeout(() => {
            that.setState({ templocations: [] }, () => {
                that.getNearByLocations();
            });
        }, 1500);
    }

    toggleSlider = () => {
        // this.setState({ firstLoad: false });
        const sidebar = document.getElementsByClassName('sidebar');
        if (sidebar && sidebar[0] && sidebar[0].classList) sidebar[0].classList.toggle('open');
    }

    sortFilter = () => {
        let other = false;
        const filters = this.props.filterList.filter(item => {
            if (item.locationtype_name === 'Other') {
                other = item;
                return false;
            }
            return true;
        });
        if (other) filters.push(other);
        filters.unshift({ locationtype_id: 0, locationtype_name: 'All' });
        this.filterDropdown(filters);
    }

    filterDropdown = filters => {
        const options = filters.map(item => <option key={item.locationtype_id} value={item.locationtype_name.toLowerCase()}>{item.locationtype_name}</option>);
        this.setState({ filterList: options });
    }

    filterChanged = event => {
        const value = event.target.value;
        const that = this;
        this.props.dispatch(setSearchFilters(value));
        this.props.dispatch(setSearchedLocationsList([]));
        this.setState({ templocations: [] }, () => {
            that.getNearByLocations(null, value);
        });
    }

    render() {
        const pageTitle = 'Search Locations';
        const pageDescription = 'Search local dog businesses in your area';
        return (
            <React.Fragment>
                <HelmetTags title={pageTitle} description={pageDescription} />
                <div className="mainMapContainer sliding">
                    <div className="bodyContent">
                        <SearchBar
                            placeholder="Search Locations"
                            handleSearchInput={this.handleSearchInput}
                            searchValue={this.props.searchLocation}
                            onClear={this.onClear}
                        />
                        <div className="sidebar open">
                            <div className="locationBannerHolder">
                                <div className="wrapper">
                                    <h6 className="fontGreen">CATEGORY</h6>
                                    <div className="categories">
                                        <select value={this.props.selectedSearchFilter} onChange={this.filterChanged}>
                                            {this.state.filterList}
                                        </select>
                                    </div>
                                </div>
                                <div className="locationListSearch">
                                    {!this.state.loading && this.state.locationListItems}
                                    {this.state.loading && (<Spinner />)}
                                    {this.props.searchedLocationsList.length === 0 && !this.state.loading && (
                                        <div className="add-location-banner row">
                                            <div className="location-banner-caption">
                                                <i className="icn icon-location-pin-white" />
                                                <Link to="/location/add">Missing location?<br />Click to add Location</Link>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="tab-toggle" onClick={this.toggleSlider}>
                                <i className="icn icon-right-arrow" />
                                <i className="icn icon-left-arrow" />
                            </div>
                            <div className="gps-locate" onClick={this.recenter}>
                                <i className="icn icon-report-lost-dog" />
                            </div>
                        </div>
                        <div className="bigMapHolder">
                            {this.state.loadingText && (
                                <div className="searchNearHere">
                                    <div className="searchAreaButton h6">{this.state.loadingText}</div>
                                </div>
                            )}
                            {this.state.hasNewCenter === true && (
                                <div className="searchNearHere">
                                    <div onClick={this.searchNearNewCenter} className="searchAreaButton h6">Search this area</div>
                                </div>
                            )}
                            <GoogleMap
                                locations={this.props.searchedLocationsList}
                                userData={this.props.userData}
                                centerMoved={this.centerMoved}
                                recenter={this.state.recenter}
                                center={this.props.searchedLocationsPosition}
                                lastPosition={this.props.mapLastLocation}
                                history={this.props.history}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        searchLocation: state.locations.searchLocation,
        filterList: state.searchFilters.filterList,
        searchedLocationsList: state.locations.searchedLocationsList,
        searchedLocationsPosition: state.locations.searchedLocationsPosition,
        selectedSearchFilter: state.searchFilters.selectedFilter,
        mapLastLocation: state.locations.mapLastLocation
    };
}

export default connect(mapStateToProps)(MainSearch);
