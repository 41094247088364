import React from 'react';
import PropTypes from 'prop-types';

import { getPosts } from '../../redux/actions/all';
import LeaveComment from '../../layout/Comments/LeaveComment';
import Spinner from '../../layout/UI/Spinner';
import { infiniteScroll } from '../../helpers/Pull2Refresh';
import ApiError from '../../helpers/ApiError';
import CommentBox from './CommentBox';

class CommentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: [], // this one is being used on child components
            replies: [],
            currentPage: 1,
            totalPages: 1, // This it's being used by infiniteScroll->handleScroll->loadMore
            loadingMore: false,
            loading: true
        };
    }

    componentDidMount() {
        if (this.props.mode === 'post') {
            this.start();
        } else {
            this.setState({ loading: false });
        }
    }

    stateSetter = newState => this.setState(newState);

    replySetter = reply => {
        if (reply?.entity_id) {
            this.setState(prevState => {
                const newReply = [...prevState.replies];
                if (newReply[reply.entity_id]) {
                    newReply[reply.entity_id] = [...newReply[reply.entity_id], reply];
                } else {
                    newReply[reply.entity_id] = [reply];
                }
                return {
                    replies: newReply
                };
            });
        }
    }

    start = () => {
        this.props.dispatch(getPosts(this.props.credentials.token, this.props.entityId, this.state.currentPage, 10))
            .then(res => {
                const replies = [];
                for (let i = 0; i < res.value.data.length; i++) {
                    replies.push(res.value.data[i].replies);
                }
                this.setState(prevState => ({
                    replies: [...prevState.replies, ...replies],
                    loadingMore: false,
                    loading: false,
                    totalPages: parseInt(res.value.headers['x-pagination-page-count'], 10)
                }), () => {
                    if (this.props.infiniteScroll && this.state.currentPage < this.state.totalPages) {
                        infiniteScroll(this, this.start);
                    }
                });
                this.props.stateSetter(prevState => ({
                    comments: [...prevState.comments, ...res.value.data]
                }));
            })
            .catch(err => {
                this.setState({ loadingMore: false, loading: false });
                ApiError((err?.response?.data?.[0]?.message || err?.response?.data?.message) || 'There was an error');
            });
    }

    removeDuplicatePosts = (comments = []) => {
        const listOfIds = comments.map(comment => comment.post_id);
        const listOfRepeats = [];
        for (let i = 0; i < comments.length; i++) {
            const value = listOfIds.indexOf(comments[i].post_id, i + 1);
            if (value > -1) {
                listOfRepeats.push(value);
            }
        }
        for (let j = listOfRepeats.length - 1; j >= 0; j--) {
            comments.splice(listOfRepeats[j], 1);
        }
        return comments;
    }

    getThisReplies = id => {
        const replies = [];
        for (let i = 0; i < this.state.replies.length; i++) {
            if (this.state.replies[i] && this.state.replies[i].length) {
                for (let j = 0; j < this.state.replies[i].length; j++) {
                    if (this.state?.replies?.[i]?.[j]?.entity_id === id) {
                        replies.push(this.state.replies[i][j]);
                    }
                }
            }
        }
        return replies;
    }

    loadMore = () => {
        if (!this.state.loadingMore) {
            this.setState(prevState => ({ currentPage: prevState.currentPage + 1 }),
                () => {
                    if (this.state.currentPage <= this.state.totalPages) {
                        this.setState({ loadingMore: true }, this.start);
                    }
                });
        }
    }

    render() {
        let list = null;

        if (this.props.state.comments) {
            let comments = [];

            if (this.props.mode === 'post') {
                comments = this.removeDuplicatePosts(this.props.state.comments);
            }

            if (this.props.mode === 'reply' && this.props.replies?.length) {
                comments = this.props.replies;
            }

            list = comments.map(comment => (
                <div key={comment.post_id} className="thread">
                    <CommentBox
                        user={comment.user}
                        comment={comment.post_body}
                        date={comment.post_createdat}
                    />
                    {this.props.acceptReplies && (
                        <div className="replies">
                            <CommentList
                                stateSetter={this.stateSetter}
                                state={this.state}
                                dispatch={this.props.dispatch}
                                credentials={this.props.credentials}
                                entityId={comment.post_id}
                                mode="reply"
                                infiniteScroll={false}
                                acceptReplies={false}
                                replies={this.getThisReplies(comment.post_id)}
                            />
                            <LeaveComment
                                dispatch={this.props.dispatch}
                                entityId={comment.post_id}
                                credentials={this.props.credentials}
                                commentAs={this.props.commentAs}
                                callback={this.replySetter}
                                placeholder="Write a reply"
                            />
                        </div>
                    )}
                </div>
            ));
        }

        return (
            <React.Fragment>
                {this.state.loading && (
                    <Spinner />
                )}
                <div className="comments">
                    {list}
                </div>
                {this.state.loadingMore && (
                    <Spinner />
                )}
                {this.props.infiniteScroll === false && !this.state.loadingMore && this.state.currentPage < this.state.totalPages && (
                    <div className="wrapper fontGreen alignRight" onClick={this.loadMore}>Load More</div>
                )}
            </React.Fragment>
        );
    }
}

CommentList.propTypes = {
    infiniteScroll: PropTypes.bool.isRequired,
    commentAs: PropTypes.instanceOf(Object),
    state: PropTypes.instanceOf(Object).isRequired,
    credentials: PropTypes.instanceOf(Object).isRequired,
    dispatch: PropTypes.func.isRequired,
    stateSetter: PropTypes.func.isRequired,
    entityId: PropTypes.number.isRequired,
    mode: PropTypes.oneOf(['post', 'reply']).isRequired
};

export default CommentList;
