import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { getUserProfile } from '../../redux/actions/users';
import PetList from '../../layout/PetList';
import { InviteFriends } from '../../layout/InviteFriends';
import Avatar from '../../layout/UI/Avatar';
import Spinner from '../../layout/UI/Spinner';
import { guard } from '../../helpers/Functions';
import ApiError from '../../helpers/ApiError';
import Columns from '../../hoc/Columns';
import WhiteTitle from '../../hoc/WhiteTitle';
import FindFriendsComponent from '../Friends/FindFriendsComponent';
import FriendRequestsComponent from '../Friends/FriendRequestsComponent';

class UserDogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            dogs: []
        };
    }

    componentDidMount() {
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        this.start();
    }

    start = () => {
        this.props.dispatch(getUserProfile(this.props.credentials.token, this.props.credentials.user_id))
            .then(response => this.setState({ loading: false, dogs: response.value.data.dogs }))
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loading: false });
            });
    }

    listOwners(owners) {
        return owners.map(owner => {
            const image = (
                <div className="round-avatar owner-list">
                    <Avatar
                        src={guard(owner, '.images.0.image_mediumurl')}
                        alt={this.props.dogProfile.name}
                        type="user"
                    />
                </div>
            );
            const lastSeen = (<div className="lastSeen">Last Seen: {owner.lastaddress.address_city}, {owner.lastaddress.address_country}</div>);
            let friendShipStatus = '';
            if (owner.myfriendship && owner.myfriendship.friendship_status === 'accepted') {
                friendShipStatus = <div className="flexSmallestButtons borderGreen greenBG fontWhite"><p>Friends</p></div>;
            } else if (owner.myfriendship && owner.myfriendship.friendship_status === 'pending') {
                friendShipStatus = <div className="flexSmallestButtons borderBlue blueBG fontWhite"><p>Pending</p></div>;
            } else {
                friendShipStatus = null;
            }
            return (
                <React.Fragment key={owner.user_firstname}>
                    {image}
                    <div className="name">
                        <div className="fontGreen">{owner.user_firstname + ' ' + owner.user_lastname}</div>
                        <div>{lastSeen}</div>
                    </div>
                    {friendShipStatus}
                </React.Fragment>
            );
        });
    }

    render() {
        const c1 = (
            <React.Fragment>
                {/*
                this is showing incorrect list owner information,
                this specific component is not meant to be on this page, whe you are viewing the dogs then you are the owner.
                    {this.props.dogProfile.users && this.props.dogProfile.users.length && (
                        <React.Fragment>
                            <WhiteTitle>Owner(s):</WhiteTitle>
                            <div className="participantList">
                                <div className="participantListItem">
                                    <Link className="user row " to={"/user/profile/"+this.props.userData.user_id}>
                                        {this.listOwners(this.props.dogProfile.users)}
                                    </Link>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                */}
            </React.Fragment>
        );

        const c2 = (
            <React.Fragment>
                <Link className="wideButtons greenBorder greenBG fontWhite spacerVertical" to="/user/dogadd"><i className="icn icon-more-add" /> Add a Dog!</Link>
                <div className="boxContainer">
                    <div className="boxTitle row">
                        <div className="title">Dog(s)</div>
                    </div>
                </div>
                {this.state.loading && (<Spinner />)}
                {!!this.state.dogs.length && !this.state.loading && <PetList dogs={this.state.dogs} />}
            </React.Fragment>
        );

        const c3 = (
            <React.Fragment>
                <InviteFriends wrapperClass="noWrapper" />
                <FriendRequestsComponent limit={3} infiniteScroll={false} viewMoreButton={true} />
                <WhiteTitle>Suggested Friends</WhiteTitle>
                <FindFriendsComponent perPage={3} infiniteScroll={false} />
                <Link to="/findfriends" className="square-button greenBG fontWhite h6">View all</Link>
            </React.Fragment>
        );

        return (
            <div className="mainAppContainer">
                <div className="bodyContent">
                    <div className="three-column-layout two-column-list">
                        <Columns
                            c1={c1}
                            c2={c2}
                            c3={c3}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        dogProfile: state.dogs.dogProfile

    };
}

export default connect(mapStateToProps)(UserDogs);
