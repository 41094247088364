import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import ActivityNavigation from '../../layout/navigation/ActivityNavigation';
import DownloadAppButton from '../../layout/DownloadAppButton';
import ActivityDispatcher from '../../layout/ActivityDispatcher';

export class Activities extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentId: this.props.match.params.id
        };
    }

    componentDidMount() {
        this.start();
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.match.params.id !== this.state.currentId) {
            this.setState({
                currentId: nextProps.match.params.id
            }, () => {
                this.start();
            });
        }
        return true;
    }

    start = () => {
        window.scrollTo(0, 0);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    render() {
        return (
            <div className="mainAppContainer">
                <MainNavigation title="Activities" />
                <ActivityNavigation.ActivityNavigationMobile selected="activities" />
                <div className="bodyContent pull2refresh maxWidth">
                    <DownloadAppButton />
                    {this.state.currentId && (
                        <ActivityDispatcher
                            credentials={this.props.credentials}
                            dispatch={this.props.dispatch}
                            type="activity"
                            disableImages={false}
                            userData={this.props.userData}
                            activityId={this.state.currentId}
                        />
                    )}
                    {!this.state.currentId && (
                        <ActivityDispatcher
                            credentials={this.props.credentials}
                            dispatch={this.props.dispatch}
                            type="activity"
                            userData={this.props.userData}
                            disableImages={false}
                            allowPosts={true}
                        />
                    )}
                </div>
                <SecondaryNavigation selected="activities" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        credentials: state.users.credentials,
        userData: state.users.user
    };
}

export default connect(mapStateToProps)(Activities);
