import React from 'react';
import PropTypes from 'prop-types';

import Avatar from './Avatar';
import { guard, listDogBreeds } from '../../helpers/Functions';

class DogProfileHeader extends React.Component {
    render() {
        const dog_is_lost = !!this.props.dogProfile && !!this.props.dogProfile.dogaddress && !this.props.dogProfile.dogaddress.dogaddress_found;
        const dog_passed_away = !!this.props.dogProfile.dog_deceased;

        return (
            <React.Fragment>
                <div className={'profilePictureWrapper dog' + (dog_is_lost ? ' lost' : '') + (dog_passed_away ? ' passed' : '')}>
                    <div className="row maxWidth wrapper">
                        <div className="round-avatar dog huge">
                            <Avatar
                                src={guard(this.props.dogProfile, '.images.0.image_mediumurl')}
                                alt={this.props.dogProfile.name}
                                type="dog"
                            />
                        </div>
                        <div className="details dog">
                            <div className="dogData">
                                <div className="title-section">
                                    <div className="dogTitle">
                                        {this.props.dogProfile.dog_name}
                                    </div>
                                    <div className="dogStatus">
                                        {dog_passed_away ? ' (Passed away)' : ''}
                                        {(dog_is_lost && !dog_passed_away) ? <div className="fontWhite">(MISSING)</div> : ''}
                                        <span>{(!dog_is_lost && !dog_passed_away && this.props.dogProfile.dog_playdates) ? '(Available for events)' : ''}</span>
                                    </div>
                                </div>
                                <div className="dog-profile-two-columns">
                                    <div className="column-1">
                                        <div className="dogBreed">
                                            <strong>Breed(s): </strong> <span>{this.props.dogProfile && this.props.dogProfile.breeds && this.props.dogProfile.breeds.length && listDogBreeds(this.props.dogProfile.breeds)}</span>
                                        </div>
                                        <div className="dateOfBirth">
                                            {this.props.dogProfile.dog_birthdate
                                                && (
                                                    <div>
                                                        <strong>D.O.B: </strong><span>{this.props.dogProfile.dog_birthdate}</span>
                                                    </div>
                                                )}
                                        </div>
                                        <div className="dogGender">
                                            {this.props.dogProfile.dog_sex
                                                && (
                                                    <div>
                                                        <strong>Sex: </strong><span>{this.props.dogProfile.dog_sex.charAt(0).toUpperCase() + this.props.dogProfile.dog_sex.slice(1)}</span>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                    <div className="column-2">
                                        <div className="dogWeight">
                                            {this.props.dogProfile.dog_weight
                                                && (
                                                    <div>
                                                        <strong>Weight: </strong><span>{parseFloat(this.props.dogProfile.dog_weight).toFixed(2)} lbs</span>
                                                    </div>
                                                )}
                                        </div>
                                        <div className="dogFixed">
                                            {(this.props.dogProfile.dog_fixed === 0 || this.props.dogProfile.dog_fixed === 1)
                                                && (
                                                    <div>
                                                        <strong>Fixed: </strong><span>{this.props.dogProfile.dog_fixed ? 'Fixed' : 'Not Fixed'}</span>
                                                    </div>
                                                )}
                                        </div>
                                        {this.props.dogProfile.dog_socialmedia
                                            && (
                                                <div className="social-media">
                                                    {this.props.dogProfile.dog_socialmedia.facebook
                                                        && (
                                                            <a className="fontWhite" href={this.props.dogProfile.dog_socialmedia.facebook.indexOf('http') === -1 ? 'http://' + this.props.dogProfile.dog_socialmedia.facebook : this.props.dogProfile.dog_socialmedia.facebook} target="_blank" rel="noopener noreferrer">
                                                                <i className="icn icon-facebook" />
                                                            </a>
                                                        )}
                                                    {this.props.dogProfile.dog_socialmedia.twitter
                                                        && (
                                                            <a className="fontWhite" href={this.props.dogProfile.dog_socialmedia.twitter.indexOf('http') === -1 ? 'http://' + this.props.dogProfile.dog_socialmedia.twitter : this.props.dogProfile.dog_socialmedia.twitter} target="_blank" rel="noopener noreferrer">
                                                                <i className="icn icon-twitter" />
                                                            </a>
                                                        )}
                                                    {this.props.dogProfile.dog_socialmedia.instagram
                                                        && (
                                                            <a className="fontWhite" href={this.props.dogProfile.dog_socialmedia.instagram.indexOf('http') === -1 ? 'http://' + this.props.dogProfile.dog_socialmedia.instagram : this.props.dogProfile.dog_socialmedia.instagram} target="_blank" rel="noopener noreferrer">
                                                                <i className="icn icon-instagram" />
                                                            </a>
                                                        )}
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>
                            {!!this.props.right && this.props.right}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

DogProfileHeader.propTypes = {
    dogProfile: PropTypes.instanceOf(Object).isRequired,
    right: PropTypes.instanceOf(Object)
};

export default DogProfileHeader;
