import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import { InviteFriends } from '../../layout/InviteFriends';
import FriendsNavigation from '../../layout/navigation/FriendsNavigation';
import { getGeoLocation } from '../../helpers/GeoLocation';
import Columns from '../../hoc/Columns';
import WhiteTitle from '../../hoc/WhiteTitle';
import FindFriendsComponent from './FindFriendsComponent';
import FriendRequestsComponent from './FriendRequestsComponent';

export class Friends extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // searchText: '',
        };
    }

    getGeoLocation = getGeoLocation.bind(this)

    componentDidMount() {
        window.scrollTo(0, 0);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    render() {
        const c1 = (
            <div className="simple-navigation">
                <FriendsNavigation
                    showFindFriends
                    selected="friendrequest"
                />
            </div>
        );

        const c2 = (<FriendRequestsComponent limit={30} infiniteScroll={true} />);

        const c3 = (
            <React.Fragment>
                <InviteFriends wrapperClass="noWrapper" />
                <WhiteTitle>Suggested Friends</WhiteTitle>
                <FindFriendsComponent perPage={3} infiniteScroll={false} />
                <Link to="/findfriends" className="square-button greenBG fontWhite h6">View all</Link>
            </React.Fragment>
        );

        return (
            <div className="mainAppContainer">
                <div className="bodyContent">
                    {/*
                        <div className="simpleSearchBar row">
                            <i className="icn icon-search fontGreen"/>
                            <input onChange={(e)=>this.handleSearchInput(e)} value={this.state.searchText} placeholder="SEARCH Friends"/>
                            {!!this.state.searchText &&
                                <i onClick={this.onClear} className="icn icon-sign-out fontDarkGray" />
                            }
                        </div>
                    */}
                    <div className="three-column-layout">
                        <Columns
                            c1={c1}
                            c2={c2}
                            c3={c3}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(Friends);
