import React from 'react';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import {
    FacebookShareButton,
    TwitterShareButton,
    RedditShareButton,
    EmailShareButton
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { Stars } from '../../layout/UI/Stars';
import { urlFriendly } from '../../helpers/Functions';
import Config from '../../helpers/Config';
import ApiError from '../../helpers/ApiError';

class LocationProfileHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shareModal: false
        };
    }

    copiedUrl = () => {
        this.toggleModal();
        toast.success('Saved to Clipboard');
    }

    toggleModal = () => {
        this.setState(prevState => ({ shareModal: !prevState.shareModal }));
    }

    // checks if address 1 matches address 2
    claimLocation = e => {
        if (!this.props.userData?.user_id) {
            e.preventDefault();
            ApiError('Please Login to Claim this Location');
        }
    }

    render() {
        let shareTitle = null;
        let url = null;
        if (this.props.location?.location_name) {
            shareTitle = 'Check out this great location: ' + this.props.location.location_name;
        }
        if (this.props.location?.location_id && this.props.location?.location_name) {
            url = Config.webUrl + '/location/profile/' + (this.props.location && this.props.location.location_id) + '/' + urlFriendly(this.props.location && this.props.location.location_name);
        }
        let claimButton = null;
        if (((this.props.userData?.user_role === 'superadmin')
            || (
                this.props.userData?.user_role === 'admin'
                && this.state.myLocationsToAdmin
                && this.state.myLocationsToAdmin.includes(String(this.props.location.location_id))
            ))
            && this.props.location?.location_id && this.props.location?.location_name
        ) {
            claimButton = (
                <Link to={'/managelocation/' + this.props.location.location_id + '/' + urlFriendly(this.props.location.location_name)} className="inlineButtons greenBorder greenBG fontWhite h5">
                    Manage Location
                </Link>
            );
        } else if (this.props.location?.adminlocations?.length === 0 && this.props.location?.location_id && this.props.location?.location_name) {
            claimButton = (
                <Link onClick={this.claimLocation} to={'/claimlocation/' + this.props.location.location_id + '/' + urlFriendly(this.props.location.location_name)} className="inlineButtons blueBorder blueBG fontWhite">
                    Claim this location
                </Link>
            );
        }

        return (
            <div className="main-title-holder" itemScope itemType="http://schema.org/LocalBusiness">
                <div className="maxWidth side-padding location-profile">
                    <div className="two-column-layout">
                        <div className="column-1">
                            <div className="floating-profile-box empty" />
                        </div>
                        <div className="column-2">
                            <div className="business-overview full-flex">
                                <div>
                                    <h1 className="location-title" itemProp="name">{this.props.location?.location_name}</h1>
                                    <div className="location-details fontDarkGray" itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
                                        {this.props.location?.address?.address_street && (
                                            <span itemProp="streetAddress">{this.props.location.address.address_street}, </span>
                                        )}
                                        {this.props.location?.address?.address_street2 && (this.props.location.address.address_street !== this.props.location.address.address_street2) && (
                                            <span itemProp="streetAddress"> {this.props.location.address.address_street2}, </span>
                                        )}
                                        {this.props.location?.address?.address_city && (
                                            <span itemProp="addressLocality">{this.props.location.address.address_city}, </span>
                                        )}
                                        {this.props.location?.address?.address_state && (
                                            <span itemProp="addressRegion"> {this.props.location.address.address_state}</span>
                                        )}
                                    </div>
                                    <div className="row">
                                        <div className="h1" itemProp="aggregateRating" itemScope itemType="http://schema.org/AggregateRating">
                                            <Stars itemProp="ratingValue" stars={this.props.location?.Reviewaverage} />
                                        </div>
                                        <div className="fontDarkGray h3" itemProp="reviewCount">
                                            ({this.props.location?.Reviewcounter})
                                        </div>
                                    </div>
                                </div>
                                <div className="ui-buttons">
                                    <div className="top-buttons">
                                        <div>
                                            {this.props.location?.location_id && this.props.location?.location_name && (
                                                <Link
                                                    to={'/location/review/' + this.props.location.location_id + '/' + urlFriendly(this.props.location.location_name)}
                                                    className="inlineButtons greenBorder greenBG fontWhite h5"
                                                >
                                                    Write a Review
                                                </Link>
                                            )}
                                        </div>
                                        <div>
                                            {claimButton}
                                        </div>
                                    </div>
                                    <div className="inlineMultiButtons">
                                        <div className="greenBG fontWhite" onClick={this.props.changeImage}>
                                            <input
                                                className="hidden"
                                                id="file"
                                                type="file"
                                                onChange={e => this.props.filesChanged(e.target.files)}
                                                accept="image/*"
                                                required
                                                multiple
                                            />
                                            <i className="icn icon-upload-photo h4" /> Add Photo
                                        </div>
                                        {this.props.location?.location_id && this.props.location?.location_name && (
                                            <React.Fragment>
                                                <Link to="#" onClick={e => this.props.removeFavorite(e)} className={'greenBG fontWhite removefavorite ' + (!this.props.location.IsFavorite && 'hidden')}>
                                                    <i className="icn icon-heart-fill h4" /> Favorite
                                                </Link>
                                                <Link to="#" onClick={e => this.props.checkFavorite(e)} className={'greenBG fontWhite checkfavorite ' + (!!this.props.location.IsFavorite && 'hidden')}>
                                                    <i className="icn icon-heart-outline h4" /> Favorite
                                                </Link>
                                                <Link
                                                    className="greenBG fontWhite"
                                                    to={'/location/checkin/' + this.props.location.location_id + '/' + urlFriendly(this.props.location.location_name)}
                                                >
                                                    <i className="icn icon-check-in h4" /> Check In
                                                </Link>
                                            </React.Fragment>
                                        )}
                                        <div onClick={this.toggleModal} className="greenBG fontWhite">
                                            <i className="icn icon-share h4" /> Share
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.shareModal && (
                    <ReactModal
                        isOpen={true}
                        contentLabel="onRequestClose Example"
                        onRequestClose={this.toggleModal}
                        className="Modal"
                        overlayClassName="Overlay"
                        ariaHideApp={false}
                    >
                        <h2>Share to:</h2>
                        <CopyToClipboard text={url} onCopy={this.copiedUrl}>
                            <div className="SocialMediaShareButton SocialMediaShareButton--copy">
                                Copy Link
                            </div>
                        </CopyToClipboard>
                        <FacebookShareButton
                            url={url}
                            quote={shareTitle}
                        >
                            Facebook
                        </FacebookShareButton>
                        <TwitterShareButton
                            url={url}
                            title={shareTitle}
                        >
                            Twitter
                        </TwitterShareButton>
                        <RedditShareButton
                            url={url}
                            title={shareTitle}
                            windowWidth={660}
                            windowHeight={460}
                        >
                            Reddit
                        </RedditShareButton>
                        <EmailShareButton
                            url={url}
                            subject={shareTitle}
                            body={shareTitle + ' ' + url}
                        >
                            Email
                        </EmailShareButton>
                        <Link to="#" onClick={this.toggleModal}>Cancel</Link>
                    </ReactModal>
                )}
            </div>
        );
    }
}

LocationProfileHeader.propTypes = {
    location: PropTypes.instanceOf(Object).isRequired,
    changeImage: PropTypes.func,
    removeFavorite: PropTypes.func,
    checkFavorite: PropTypes.func
};

export default LocationProfileHeader;
