import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import SocialMediaIcons from '../../layout/UI/SocialMediaIcons';
import UserSummary from '../../layout/UI/UserSummary';
import { urlFriendly } from '../../helpers/Functions';
import Avatar from './Avatar';

const UserProfileHeader = ({ image, user, right, bottom }) => (
    <React.Fragment>
        <div className="profilePictureWrapper user">
            <div className="row maxWidth wrapper">
                <div className="round-avatar huge user">
                    <Avatar
                        src={image}
                        alt={user.user_firstname + ' ' + user.user_lastname}
                        type="user"
                    />
                </div>
                <div className="details user">
                    <div className="userData">
                        {user && (
                            <div className="user-name">
                                {user.user_firstname} {user.user_lastname}
                            </div>
                        )}
                        {user && user.user_primaryhours && (
                            <div className="available-playdates">
                                <span><strong>Available Hours for Events:</strong> {user.user_primaryhours}</span>
                            </div>
                        )}
                        {user && user.primarylocation && user.primarylocation.location_name && (
                            <div className="primary-location">
                                <Link
                                    className="fontWhite"
                                    to={'/location/profile/' + user.primarylocation.location_id + '/' + urlFriendly(user.primarylocation.location_name)}
                                >
                                    <span><strong>Favorite Park:</strong> {user.primarylocation.location_name}</span>
                                </Link>
                            </div>
                        )}
                        {user.lastaddress && (
                            <div className="last-address">
                                <span><strong>Last Seen:</strong>{user.lastaddress.address_city}, {user.lastaddress.address_country}</span>
                            </div>
                        )}
                        {user.user_socialmedia && (
                            <div className="social-media">
                                <SocialMediaIcons
                                    socialmedia={user.user_socialmedia}
                                />
                            </div>
                        )}
                        {!!user && !!user.UserSummary && (
                            <UserSummary
                                summary={user.UserSummary}
                                color="fontWhite"
                            />
                        )}
                    </div>
                    {!!right && right}
                </div>
            </div>
        </div>
        {!!bottom && bottom}
    </React.Fragment>
);

UserProfileHeader.propTypes = {
    image: PropTypes.string,
    user: PropTypes.instanceOf(Object).isRequired,
    right: PropTypes.instanceOf(Object)
};

export default UserProfileHeader;
