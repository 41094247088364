import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';

import { setPicture, deletePicture } from '../../redux/actions/all';
import { getDogProfile } from '../../redux/actions/dogs';
import GalleryLightbox from '../../layout/gallery/GalleryLightbox';
import Avatar from '../../layout/UI/Avatar';
import { guard, listDogBreeds } from '../../helpers/Functions';
import ApiError from '../../helpers/ApiError';
import Spinner from '../../layout/UI/Spinner';
import NotFound from '../../layout/NotFound';
import Columns from '../../hoc/Columns';
import WhiteTitle from '../../hoc/WhiteTitle';

class DogGallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            loading: true,
            gallery: []
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        this.props.dispatch(getDogProfile(this.props.credentials.token, this.props.match.params.id))
            .then(response => {
                this.setState({
                    loading: false,
                    gallery: response.value.data.images
                });
            })
            .catch(err => {
                this.setState({
                    loading: false
                });
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    /* Removes image from GalleryLightBox */
    handleClickRemoveImage = id => {
        this.props.dispatch(deletePicture(this.props.credentials.token, id))
            .then(() => {
                this.setState(prevState => {
                    const newGallery = prevState.gallery.filter(image => image.image_id !== id);
                    return {
                        gallery: newGallery
                    };
                });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    handleClickSetImage = id => {
        this.props.dispatch(setPicture(this.props.credentials.token, id))
            .then(() => {
                let newFeaturedImage = {};
                // we push the new featured image to the top of the list, on the first position
                this.setState(prevState => {
                    const newGallery = prevState.gallery.filter(image => {
                        if (image.image_id === id) newFeaturedImage = image;
                        return image.image_id !== id;
                    });
                    newGallery.sort((a, b) => b.image_id - a.image_id);
                    return {
                        gallery: [newFeaturedImage, ...newGallery]
                    };
                });
                toast.success('Profile Image Set Successfully!');
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    listOwners(owners) {
        return owners.map(owner => {
            const image = (
                <div className="round-avatar owner-list">
                    <Avatar
                        src={guard(owner, '.images.0.image_mediumurl')}
                        alt={this.props.dogProfile.name}
                        type="user"
                    />
                </div>
            );
            let lastSeen = null;

            if (owner.lastaddress && owner.lastaddress.address_city) {
                lastSeen = (
                    <div className="lastSeen">
                        Last Seen: {owner.lastaddress.address_city}, {owner.lastaddress.address_country}
                    </div>
                );
            }
            let friendShipStatus = '';

            if (owner.myfriendship && owner.myfriendship.friendship_status === 'accepted') {
                friendShipStatus = (
                    <div className="flexSmallestButtons borderGreen greenBG fontWhite">
                        <p>Friends</p>
                    </div>
                );
            } else if (owner.myfriendship && owner.myfriendship.friendship_status === 'pending') {
                friendShipStatus = (
                    <div className="flexSmallestButtons borderBlue blueBG fontWhite">
                        <p>Pending</p>
                    </div>
                );
            } else {
                friendShipStatus = null;
            }

            return (
                <Link className="user row " to={'/user/profile/' + owner.user_id} key={owner.user_firstname}>
                    {image}
                    <div className="name">
                        <div className="fontGreen">{owner.user_firstname + ' ' + owner.user_lastname}</div>
                        <div>{lastSeen}</div>
                    </div>
                    {friendShipStatus}
                </Link>
            );
        });
    }

    render() {
        let ownDogImages = null;

        if (this.state.gallery && this.state.gallery.length > 0 && !this.state.loading) {
            ownDogImages = (
                <div className="dog-profile dog-gallery maxWidth">
                    <GalleryLightbox
                        images={this.state.gallery}
                        dogProfile={this.props.dogProfile}
                        userData={this.props.userData}
                        count="all"
                        size="normal"
                        handleClickSetImage={this.handleClickSetImage}
                        handleClickRemoveImage={this.handleClickRemoveImage}
                    />
                </div>
            );
        }
        if (this.state.gallery && this.state.gallery.length === 0 && !this.state.loading) {
            ownDogImages = (<NotFound dog_id={this.props.match.params.id} type="dogimages" class="backgroundRed" />);
        }
        const sex = [];
        const c1 = (
            <React.Fragment>
                {this.props.dogProfile.users && this.props.dogProfile.users.length && (
                    <React.Fragment>
                        <WhiteTitle>Profile</WhiteTitle>
                        <div className="participantList">
                            <div className="participantListItem">
                                <Link className="user row " to={'/dog/' + this.props.dogProfile.dog_id} key={this.props.dogProfile.dog_id}>
                                    <div className="round-avatar owner-list">
                                        <Avatar
                                            src={guard(this.props.dogProfile, '.images.0.image_mediumurl')}
                                            alt={this.props.dogProfile.name}
                                            type="dog"
                                        />
                                    </div>
                                    <div className="info">
                                        <div className="name">
                                            <div className="fontGreen" to={'/dog/' + this.props.dogProfile.dog_id}>{this.props.dogProfile.dog_name}</div>
                                        </div>
                                        <div className="race">{this.props.dogProfile && this.props.dogProfile.breeds && this.props.dogProfile.breeds.length && listDogBreeds(this.props.dogProfile.breeds)}</div>
                                        <div className="sex">
                                            {!!this.props.dogProfile.dog_sex && sex.push(this.props.dogProfile.dog_sex.charAt(0).toUpperCase() + this.props.dogProfile.dog_sex.slice(1)) && ''}
                                            {!!this.props.dogProfile.dog_weight && sex.push(parseFloat(this.props.dogProfile.dog_weight).toFixed(2) + ' lbs') && ''}
                                            {!!this.props.dogProfile.dog_fixed && sex.push('Fixed') && ''}
                                            {!this.props.dogProfile.dog_fixed && sex.push('Not Fixed') && ''}
                                            {sex.join(', ')}
                                            {sex.splice(0, sex.length) && ''}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="spacerSmall" />
                        <WhiteTitle>Owner(s)</WhiteTitle>
                        <div className="participantList">
                            <div className="participantListItem">
                                {this.listOwners(this.props.dogProfile.users)}
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );

        const c2 = (
            <React.Fragment>
                {this.state.loading
                    && <Spinner />}
                {ownDogImages}
            </React.Fragment>
        );

        return (
            <div className="mainAppContainer">
                {this.state.redirect && (
                    <Redirect to={'/dog/' + this.props.match.params.id + '/edit'} />
                )}
                <div className="bodyContent">
                    <div className="two-column-layout">
                        <Columns
                            c1={c1}
                            c2={c2}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

DogGallery.propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.instanceOf(Object).isRequired
};

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        dogProfile: state.dogs.dogProfile
    };
}

export default connect(mapStateToProps)(DogGallery);
