import React from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class NotFound extends React.Component {
    render() {
        const type = this.props.type || '404';
        let className = this.props.class || '';
        if (!this.props.type) {
            className = 'backgroundRed';
        }
        if (type === '404') {
            // console.log(Error('404: Server error'));
            if (this.props.location.pathname !== '/404') {
                return (<Redirect to="/404" />);
            }
        }

        return (
            <div className={'errorPage ' + className}>
                <div className="emptyHolder fontWhite">
                    {type === '404' && (
                        <React.Fragment>
                            <div className="hugeFont">404</div>
                            Page Not Found<br /><br />
                            <Link to="/" className="smallWideButtons whiteBorder fontWhite">Go Back Home!</Link>
                        </React.Fragment>
                    )}
                    {type === 'favorites' && (
                        <React.Fragment>
                            <i className="icn icon-heart-outline hugeFont fontRed" /> <i className="icn icon-heart-fill hugeFont fontRed" />
                            <br /><br />
                            You don't have any favorite locations
                            <br /><br />
                            <Link to="/search" className="smallWideButtons whiteBorder fontWhite">Add a Favorite</Link>
                        </React.Fragment>
                    )}
                    {type === 'chat' && (
                        <React.Fragment>
                            <i className="icn icon-no-messages hugeFont fontWhite" />
                            <br /><br />
                            <Link to="/chatcreate" className="smallWideButtons whiteBorder fontWhite">Find Friends to Chat With</Link>
                        </React.Fragment>
                    )}
                    {type === 'friends' && (
                        <React.Fragment>
                            <i className="icn icon-couple hugeFont fontWhite" />
                            <br /><br />
                            Let's start adding friends!
                            <br /><br />
                            <Link to="/findfriends" className="smallWideButtons whiteBorder fontWhite">Find Friends</Link>
                        </React.Fragment>
                    )}
                    {type === 'locations' && (
                        <React.Fragment>
                            <i className="icn icon-map-search hugeFont fontWhite" />
                            <h2>Location Not Found</h2>
                            <Link to="#" onClick={e => { e.preventDefault(); this.props.history.goBack(); }} className="smallWideButtons whiteBorder fontWhite">Go Back</Link>
                        </React.Fragment>
                    )}
                    {type === 'emptyGallery' && (
                        <React.Fragment>
                            <i className="icn icon-user hugeFont fontWhite" />
                            <h2>Location does not have pictures</h2>
                            <Link to="#" onClick={e => { e.preventDefault(); this.props.history.goBack(); }} className="smallWideButtons whiteBorder fontWhite">Go Back</Link>
                        </React.Fragment>
                    )}
                    {type === 'playdates' && (
                        <React.Fragment>
                            <i className="icn icon-dog hugeFont fontWhite" />
                            <br /><br />
                            Dogs need socializing too!
                            <br /><br />
                            <Link to="/events/create/event" className="smallWideButtons whiteBorder fontWhite">Start an Event</Link>
                        </React.Fragment>
                    )}
                    {type === 'lostdogs' && (
                        <React.Fragment>
                            <i className="icn icon-dog hugeFont fontWhite" />
                            <br /><br />
                            Good news! No missing dogs reported.
                            <br /><br />
                            <Link to="/reportmissing" className="smallWideButtons whiteBorder fontWhite">Need to report a missing dog?</Link>
                        </React.Fragment>
                    )}
                    {type === 'playdate' && (
                        <React.Fragment>
                            <i className="icn icon-tennis-ball hugeFont fontWhite" />
                            <h2>Event Not Found</h2>
                            <Link to="#" onClick={e => { e.preventDefault(); this.props.history.goBack(); }} className="smallWideButtons whiteBorder fontWhite">Go Back</Link>
                        </React.Fragment>
                    )}
                    {type === 'emptyPlaydateRating' && (
                        <React.Fragment>
                            <i className="icn icon-tennis-ball hugeFont fontWhite" />
                            <br /><br />
                            Nothing to rate right now
                            <br /><br />
                            <Link to="/events/manage/event" className="smallWideButtons whiteBorder fontWhite">Manage Other Events</Link>
                        </React.Fragment>
                    )}
                    {type === 'dog' && (
                        <React.Fragment>
                            <i className="icn icon-dog hugeFont fontWhite" />
                            <h2>Pooch Not Found</h2>
                            <Link to="#" onClick={e => { e.preventDefault(); this.props.history.goBack(); }} className="smallWideButtons whiteBorder fontWhite">Go Back</Link>
                        </React.Fragment>
                    )}
                    {type === 'user' && (
                        <React.Fragment>
                            <i className="icn icon-user hugeFont fontWhite" />
                            <h2>User Not Found</h2>
                            <Link to="/friends" onClick={e => { e.preventDefault(); this.props.history.goBack(); }} className="smallWideButtons whiteBorder fontWhite">Go Back</Link>
                        </React.Fragment>
                    )}
                    {type === 'searchlocations' && (
                        <React.Fragment>
                            <div className="emptyHolder fontWhite">
                                <i className="icn icon-map-search hugeFont fontWhite" />
                                <h2>Location Not Found</h2>
                                <Link to="#" onClick={e => { e.preventDefault(); this.props.onClear(); }} className="smallWideButtons whiteBorder fontWhite">Clear Search</Link>
                            </div>
                        </React.Fragment>
                    )}
                    {type === 'filteredlocations' && (
                        <React.Fragment>
                            <i className="icn icon-map-search hugeFont fontWhite" />
                            <h2>Location Not Found</h2>
                            <Link to="/categories" className="smallWideButtons whiteBorder fontWhite">Change the filters</Link>
                        </React.Fragment>
                    )}
                    {type === 'userimages' && (
                        <React.Fragment>
                            <i className="icn icon-user hugeFont fontWhite" />
                            <br /><br />
                            No images Found!
                            <br /><br />
                            <Link to={'/user/profile/' + this.props.user_id} className="smallWideButtons whiteBorder fontWhite">Go back</Link>
                        </React.Fragment>
                    )}
                    {type === 'dogimages' && (
                        <React.Fragment>
                            <i className="icn icon-dog hugeFont fontWhite" />
                            <br /><br />
                            No images Found!
                            <br /><br />
                            <Link to={'/dog/' + this.props.dog_id + '/edit'} className="smallWideButtons whiteBorder fontWhite">Upload your dogs images here</Link>
                        </React.Fragment>
                    )}
                    {type === 'form' && (
                        <React.Fragment>
                            <i className="icn icon-activity hugeFont fontWhite" />
                            <br />
                            Form not found
                            <br />
                            <Link to="#" onClick={e => { e.preventDefault(); this.props.history.goBack(); }} className="smallWideButtons whiteBorder fontWhite">Go Back</Link>
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }
}

NotFound.propTypes = {
    class: PropTypes.string,
    type: PropTypes.string,
    onClear: PropTypes.func
};

export default withRouter(NotFound);
