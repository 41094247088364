import React from 'react';
import { Link } from 'react-router-dom';

import Avatar from '../../layout/UI/Avatar';
import GalleryLightbox from '../../layout/gallery/GalleryLightbox';
import { guard } from '../../helpers/Functions';

export class UserReview extends React.Component {
    render() {
        const userName = (this.props.user.user.user_firstname || '') + ' ' + (this.props.user.user.user_lastname || '');
        return (
            <div className="userReviewWrapper">
                <li className="userReview">
                    <Link to={'/user/profile/' + this.props.user.user_id} className="round-avatar user">
                        <Avatar
                            src={guard(this.props.user, '.user.images.0.image_smallurl')}
                            alt={userName}
                            type="user"
                        />
                    </Link>
                    <Link to={'/user/profile/' + this.props.user.user_id} className="info">
                        {this.props.user.user && (<h4 className="fontGreen">{userName}</h4>)}
                        <span className="fontDarkGray">
                            {!!this.props.user && !!this.props.user.review_comment && this.props.user.review_comment.split('\n').map((item, key) => <span key={key}>{item}<br /></span>)}
                        </span>
                        {this.props.user.reviews && (
                            <div className="userActivity row">
                                <div>{this.props.user.reviews || 0} Reviews</div>
                                <div>{this.props.user.pictures || 0} Photos</div>
                                <div>{this.props.user.checkins || 0} Check Ins</div>
                            </div>
                        )}
                    </Link>
                    <div className="additionalInfo">
                        <div className="fontDarkGray label">Rated:</div> <i className="icn icon-star-fill fontGold" /><span className="rated fontWhite">{this.props.user.review_rating}</span>
                    </div>
                </li>
                {!!this.props.user.images && !!this.props.user.images.length && (
                    <div className="galleryHolder">
                        <GalleryLightbox images={this.props.user.images} count={2} size="normal" />
                        {this.props.user.images.length > 2 && (
                            <div className="holderEnclosed">
                                <div>View Gallery </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}
