import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

export class SecondaryNavigation extends React.Component {
    render() {
        let menuButton;

        if (this.props.location.pathname !== '/more') {
            menuButton = (
                <Link to="/more" className={(this.props.selected === 'more' ? 'active' : '')}><i className="icn icon-settings" />More</Link>
            );
        } else {
            menuButton = (
                <Link to="#" onClick={this.props.history.goBack} className="active"><i className="icn icon-settings" />More</Link>
            );
        }

        return (
            <div className="secondaryNavigation row" id="secondaryNav">
                <Link to="/dogs" className={(this.props.selected === 'activities' ? 'active' : '')}><i className="icn icon-activity" />Feed</Link>
                <Link to="/search" className={(this.props.selected === 'search' ? 'active' : '')}><i className="icn icon-search" />Search</Link>
                <Link to="/events" className={(this.props.selected === 'events' ? 'active' : '')}><i className="icn icon-tennis-ball" />Events</Link>
                <Link to="/friends" className={(this.props.selected === 'friends' ? 'active' : '')}><i className="icn icon-couple" />Friends
                    {!!this.props.counter && ((!!this.props.counter.NewActivities && this.props.counter.NewActivities !== '0') || (!!this.props.counter.NewNotifications && this.props.counter.NewNotifications !== '0')) && (
                        <span className="badge">{parseInt(this.props.counter.NewActivities, 10) + parseInt(this.props.counter.NewNotifications, 10)}</span>
                    )}
                </Link>
                {menuButton}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        counter: state.activities.counter
    };
}

export default connect(mapStateToProps)(withRouter(SecondaryNavigation));
