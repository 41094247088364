import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { getLocations } from '../redux/actions/locations';
import { Stars } from '../layout/UI/Stars';
import { urlFriendly } from '../helpers/Functions';
import Avatar from './UI/Avatar';

class LocationsNearBy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // loading: true,
            locations: [],
            templocation: []
        };
    }

    componentDidMount() {
        /* if (this.props.geoLocation) {
            this.setState({ loading: true });
        } */

        let extraFilter;

        if (this.props.featured) {
            extraFilter = '&onlyfeatured=true';
        }

        this.props.dispatch(getLocations(this.props.credentials.token, this.props.geoLocation, this.props.selectedSearchFilter, '', 1, 4, extraFilter))
            .then(res => {
                this.setState({
                    // loading: false,
                    templocation: [...res.value.data]
                }, () => this.removeDuplicate());
            });
        // .catch(() => { this.setState({ loading: false }); });
    }

    removeDuplicate = () => {
        const newlocations = this.state.templocation.filter(location => location.location_id !== parseInt(this.props.match.params.id, 10));
        this.setState({ locations: newlocations });
    }

    showBusinesses = () => {
        if (this.state.locations && this.state.locations.length > 0) {
            const locations = this.state.locations.map(location => {
                const link = '/location/profile/' + location.location_id + '/' + urlFriendly(location.location_name);
                let refresh = null;
                if (this.props.match.path.indexOf('/location/profile') > -1) {
                    refresh = '_self';
                }
                return (
                    <li key={location.location_id} className="locationItemBasic row">
                        <Link to={link} target="_self" className="round-avatar location center">
                            <Avatar
                                src={false}
                                alt={location.location_name}
                                type="location"
                            />
                        </Link>
                        <Link to={link} target={refresh} className="info">
                            <h4 className="fontGreen">{location.location_name}</h4>
                            <Stars stars={location.Reviewaverage} /> <span className="fontGray">{location.Reviewcounter} reviews</span>
                        </Link>
                    </li>
                );
            });

            return (
                <div className="related-locations">
                    {!this.props.featured && (
                        <h4>Nearby Locations</h4>
                    )}
                    {!!this.props.featured && (
                        <h4>Featured Business</h4>
                    )}
                    <div className="locationListSearch">
                        {locations}
                    </div>
                </div>
            );
        }
        return null;
    }

    render() {
        return (
            <React.Fragment>
                {this.showBusinesses()}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        credentials: state.users.credentials,
        selectedSearchFilter: state.searchFilters.selectedFilter
    };
}

export default connect(mapStateToProps)(withRouter(LocationsNearBy));
