import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { getStats } from '../../redux/actions/all';
import Spinner from '../../layout/UI/Spinner';
import Columns from '../../hoc/Columns';

class AdminStats extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stats: null,
            loading: true
        };
    }

    componentDidMount() {
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        this.props.dispatch(getStats(this.props.credentials.token))
            .then(res => {
                this.setState({ stats: res.value, loading: false });
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        let message = null;
        if (this.state.stats && this.state.stats.data) {
            message = JSON.stringify(this.state.stats.data, null, 2);
            message = message.split('\n').map((item, index) => <div key={index}>{item}</div>);
        }

        const c1 = (<div />);

        const c2 = (
            <React.Fragment>
                <h2>Stats</h2>
                {this.state.loading && (<Spinner />)}
                <pre>{message}</pre>
            </React.Fragment>
        );

        const c3 = (<React.Fragment />);

        return (
            <div className="mainAppContainer">
                <div className="bodyContent">
                    <div className="three-column-layout">
                        <Columns
                            c1={c1}
                            c2={c2}
                            c3={c3}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(AdminStats);
