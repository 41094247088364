import React from 'react';
import { connect } from 'react-redux';
import Textarea from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import qs from 'qs';
import ReactGA from 'react-ga';

import {
    setCreateLocationContactInfo,
    setCreateLocationSocialMedia,
    setCreateLocationAdditionalInfo,
    setCreateLocationWeb,
    setCreateTimes,
    setCreateCloseAllDay,
    setCreateOpenAllDay,
    addLocation,
    setCreateLocationDescription
} from '../../redux/actions/locations';
import DynamicForm from '../../layout/UI/DynamicForm';
import Spinner from '../../layout/UI/Spinner';
import { guard, urlFriendly } from '../../helpers/Functions';
import ApiError from '../../helpers/ApiError';
import Columns from '../../hoc/Columns';

class AddLocationDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contactInfo: {},
            socialMedia: {},
            additionalInfo: {},
            loadingSubmitForm: false
        };
    }

    componentDidMount() {
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        window.scrollTo(0, 0);
        this.setState({
            contactInfo: (this.props.createLocation && this.props.createLocation.location_contactinfo) || {},
            socialMedia: (this.props.createLocation && this.props.createLocation.location_socialmedia) || {},
            additionalInfo: (this.props.createLocation && this.props.createLocation.location_additionalinfo) || {}
        });
    }

    verifyData = e => {
        e.preventDefault();
        this.submit.setAttribute('disabled', 'disabled');
        if (!(!!this.props.createLocation
            && !!this.props.createLocation.address
            && !!this.props.createLocation.address.address_city
            && !!this.props.createLocation.address.address_street
            && !!this.props.createLocation.location_name
            && !!this.props.createLocation.locationtypes
            && !!this.props.createLocation.locationtypes.length
        )) {
            ApiError('There is some missing data, please try again.');
            this.submit.removeAttribute('disabled');
        } else {
            this.setState({ loadingSubmitForm: true });
            const that = this;
            let data = { ...this.props.createLocation };
            if (this.props.createLocation.location_schedule) {
                data = { ...this.props.createLocation, location_schedule: this.props.createLocation.location_schedule };
            }
            this.props.dispatch(addLocation(this.props.credentials.token, data))
                .then(response => {
                    toast.success('The location has beed added correctly.');
                    // TODO: Review this logic later
                    let params = false;
                    if (that.props.history.location.search) {
                        params = qs.parse(that.props.history.location.search.slice(1));
                    }
                    if (params && params.redirect) {
                        that.props.history.push(params.redirect);
                    } else {
                        that.props.history.push('/location/profile/' + response.value.data.location_id + '/' + urlFriendly(response.value.data.location_name));
                    }
                })
                .catch(err => {
                    this.setState({ loadingSubmitForm: false }, () => {
                        this.submit.removeAttribute('disabled');
                    });
                    ApiError((err.response && err.response.data && err.response.data && err.response.data.message) || err.message);
                });
        }
    }

    contactInfoHandler = event => {
        this.setState({
            contactInfo: { ...this.state.contactInfo, [event.target.name]: event.target.value }
        }, () => {
            this.props.dispatch(setCreateLocationContactInfo(this.state.contactInfo));
        });
    }

    socialMediaHandler = event => {
        this.setState({
            socialMedia: { ...this.state.socialMedia, [event.target.name]: event.target.value }
        }, () => {
            this.props.dispatch(setCreateLocationSocialMedia(this.state.socialMedia));
        });
    }

    additionalInfoCheckmarkHandler = e => {
        const target = e.target.id;
        this.setState(prevState => ({
            additionalInfo: { ...this.state.additionalInfo, [target]: !prevState.additionalInfo[target] }
        }), () => {
            this.props.dispatch(setCreateLocationAdditionalInfo(this.state.additionalInfo));
        });
    }

    additionalInfoHandler = event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;
        this.setState({
            additionalInfo: { ...this.state.additionalInfo, [targetName]: targetValue }
        }, () => {
            this.props.dispatch(setCreateLocationAdditionalInfo(this.state.additionalInfo));
        });
    }

    render() {
        // gets the selected categories for this specific location
        // it then passes the data to the DynamicForm, with data handlers, this way it will return all the necessary fields specified by each category
        const selected = (!!this.props.createLocation && !!this.props.createLocation.locationtypes && this.props.createLocation.locationtypes.length > 0) ? this.props.createLocation.locationtypes.map(cat => cat.locationtype_name) : [];
        const additionalInfo = DynamicForm(selected, this.additionalInfoCheckmarkHandler, this.additionalInfoHandler, this.state.additionalInfo);

        const c1 = (<React.Fragment />);
        const c3 = (<React.Fragment />);

        const c2 = (
            <React.Fragment>
                <h1 className="section fontWhite">Almost there...</h1>
                <form onSubmit={this.verifyData} className="wideForm">
                    <div className="">
                        <div className="fontWhite h5">Brief description of this location</div>
                        <Textarea onChange={e => (this.props.dispatch(setCreateLocationDescription(e.target.value)))} className="niceTextarea smallmargin" placeholder="Write something about this Location" value={guard(this.props.createLocation, '.location_description') || ''} />
                    </div>
                    <div className="innerLabelInput">
                        <span className="blocking-span">
                            <input className="inputText" name="phone" type="text" onChange={this.contactInfoHandler} value={(this.state.contactInfo && this.state.contactInfo.phone) || ''} />
                            <span className="floating-label">Phone Number</span>
                        </span>
                    </div>
                    <div className="innerLabelInput">
                        <span className="blocking-span">
                            <input className="inputText" name="tollFree" type="text" onChange={this.contactInfoHandler} value={(this.state.contactInfo && this.state.contactInfo.tollFree) || ''} />
                            <span className="floating-label">Toll-free Number</span>
                        </span>
                    </div>
                    <div className="innerLabelInput">
                        <span className="blocking-span">
                            <input className="inputText" name="fax" type="text" onChange={this.contactInfoHandler} value={(this.state.contactInfo && this.state.contactInfo.fax) || ''} />
                            <span className="floating-label">Fax Number</span>
                        </span>
                    </div>
                    <div className="innerLabelInput">
                        <span className="blocking-span">
                            <input className="inputText" name="email" type="text" onChange={this.contactInfoHandler} value={(this.state.contactInfo && this.state.contactInfo.email) || ''} />
                            <span className="floating-label">Email</span>
                        </span>
                    </div>
                    <h4 className="fontWhite">Online Presence</h4>
                    <div className="innerLabelInput">
                        <span className="blocking-span">
                            <input className="inputText" name="web" type="text" onChange={e => this.props.dispatch(setCreateLocationWeb(e.target.value))} value={guard(this.props.createLocation, '.location_website') || ''} />
                            <span className="floating-label">Website</span>
                        </span>
                    </div>
                    <div className="innerLabelInput">
                        <span className="blocking-span">
                            <input className="inputText" name="facebook" type="text" onChange={this.socialMediaHandler} value={(this.state.socialMedia && this.state.socialMedia.facebook) || ''} />
                            <span className="floating-label">Facebook</span>
                        </span>
                    </div>
                    <div className="innerLabelInput">
                        <span className="blocking-span">
                            <input className="inputText" name="instagram" type="text" onChange={this.socialMediaHandler} value={(this.state.socialMedia && this.state.socialMedia.instagram) || ''} />
                            <span className="floating-label">Instagram</span>
                        </span>
                    </div>
                    <div className="innerLabelInput">
                        <span className="blocking-span">
                            <input className="inputText" name="twitter" type="text" onChange={this.socialMediaHandler} value={(this.state.socialMedia && this.state.socialMedia.twitter) || ''} />
                            <span className="floating-label">Twitter</span>
                        </span>
                    </div>
                    {additionalInfo && additionalInfo.length > 0 && (
                        <div className="fontWhite">
                            <h4>Other Services</h4>
                            {additionalInfo}
                        </div>
                    )}
                    <div className="spacerSmall" />
                    <h4 className="fontWhite">Hours of Operation</h4>
                    <div className="section timeTableHolder">
                        <div className="timeTable fontWhite">
                            <div className="row">
                                <div>Monday:</div>
                                <div><input name="mondayHoursOpen" placeholder="8am" onChange={e => this.props.dispatch(setCreateTimes('monday', 'open', e.target.value))} value={guard(this.props.createLocation, '.location_schedule.monday.open') || ''} /></div>
                                <div>-</div>
                                <div><input name="mondayHoursClose" placeholder="8pm" onChange={e => this.props.dispatch(setCreateTimes('monday', 'close', e.target.value))} value={guard(this.props.createLocation, '.location_schedule.monday.close') || ''} /></div>
                                <div className="checkmarks">
                                    <div className="row">
                                        <div>Closed:</div>
                                        <label className="checkmarkContainer inline h6">
                                            <input type="checkbox" name="mondayClosed" onChange={() => { }} onClick={() => this.props.dispatch(setCreateCloseAllDay('monday'))} checked={!!guard(this.props.createLocation, '.location_schedule.monday.closed')} />
                                            <span className="checkmark small" />
                                        </label>
                                    </div>
                                    <div className="row">
                                        <div>24h:</div>
                                        <label className="checkmarkContainer inline h6">
                                            <input type="checkbox" name="monday24h" onChange={() => { }} onClick={() => this.props.dispatch(setCreateOpenAllDay('monday'))} checked={!!guard(this.props.createLocation, '.location_schedule.monday.allday')} />
                                            <span className="checkmark small" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div>Tuesday:</div>
                                <div><input name="tuesdayHoursOpen" placeholder="8am" onChange={e => this.props.dispatch(setCreateTimes('tuesday', 'open', e.target.value))} value={guard(this.props.createLocation, '.location_schedule.tuesday.open') || ''} /></div>
                                <div>-</div>
                                <div><input name="tuesdayHoursClose" placeholder="8pm" onChange={e => this.props.dispatch(setCreateTimes('tuesday', 'close', e.target.value))} value={guard(this.props.createLocation, '.location_schedule.tuesday.close') || ''} /></div>
                                <div className="checkmarks">
                                    <div className="row">
                                        <div>Closed:</div>
                                        <label className="checkmarkContainer inline h6">
                                            <input type="checkbox" name="tuesdayClosed" onChange={() => { }} onClick={() => this.props.dispatch(setCreateCloseAllDay('tuesday'))} checked={!!guard(this.props.createLocation, '.location_schedule.tuesday.closed')} />
                                            <span className="checkmark small" />
                                        </label>
                                    </div>
                                    <div className="row">
                                        <div>24h:</div>
                                        <label className="checkmarkContainer inline h6">
                                            <input type="checkbox" name="tuesday24h" onChange={() => { }} onClick={() => this.props.dispatch(setCreateOpenAllDay('tuesday'))} checked={!!guard(this.props.createLocation, '.location_schedule.tuesday.allday')} />
                                            <span className="checkmark small" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div>Wednesday:</div>
                                <div><input name="wednesdayHoursOpen" placeholder="8am" onChange={e => this.props.dispatch(setCreateTimes('wednesday', 'open', e.target.value))} value={guard(this.props.createLocation, '.location_schedule.wednesday.open') || ''} /></div>
                                <div>-</div>
                                <div><input name="wednesdayHoursClose" placeholder="8pm" onChange={e => this.props.dispatch(setCreateTimes('wednesday', 'close', e.target.value))} value={guard(this.props.createLocation, '.location_schedule.wednesday.close') || ''} /></div>
                                <div className="checkmarks">
                                    <div className="row">
                                        <div>Closed:</div>
                                        <label className="checkmarkContainer inline h6">
                                            <input type="checkbox" name="wednesdayClosed" onChange={() => { }} onClick={() => this.props.dispatch(setCreateCloseAllDay('wednesday'))} checked={!!guard(this.props.createLocation, '.location_schedule.wednesday.closed')} />
                                            <span className="checkmark small" />
                                        </label>
                                    </div>
                                    <div className="row">
                                        <div>24h:</div>
                                        <label className="checkmarkContainer inline h6">
                                            <input type="checkbox" name="wednesday24h" onChange={() => { }} onClick={() => this.props.dispatch(setCreateOpenAllDay('wednesday'))} checked={!!guard(this.props.createLocation, '.location_schedule.wednesday.allday')} />
                                            <span className="checkmark small" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div>Thursday:</div>
                                <div><input name="thursdayHoursOpen" placeholder="8am" onChange={e => this.props.dispatch(setCreateTimes('thursday', 'open', e.target.value))} value={guard(this.props.createLocation, '.location_schedule.thursday.open') || ''} /></div>
                                <div>-</div>
                                <div><input name="thursdayHoursClose" placeholder="8pm" onChange={e => this.props.dispatch(setCreateTimes('thursday', 'close', e.target.value))} value={guard(this.props.createLocation, '.location_schedule.thursday.close') || ''} /></div>
                                <div className="checkmarks">
                                    <div className="row">
                                        <div>Closed:</div>
                                        <label className="checkmarkContainer inline h6">
                                            <input type="checkbox" name="thursdayClosed" onChange={() => { }} onClick={() => this.props.dispatch(setCreateCloseAllDay('thursday'))} checked={!!guard(this.props.createLocation, '.location_schedule.thursday.closed')} />
                                            <span className="checkmark small" />
                                        </label>
                                    </div>
                                    <div className="row">
                                        <div>24h:</div>
                                        <label className="checkmarkContainer inline h6">
                                            <input type="checkbox" name="thursday24h" onChange={() => { }} onClick={() => this.props.dispatch(setCreateOpenAllDay('thursday'))} checked={!!guard(this.props.createLocation, '.location_schedule.thursday.allday')} />
                                            <span className="checkmark small" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div>Friday:</div>
                                <div><input name="fridayHoursOpen" placeholder="8am" onChange={e => this.props.dispatch(setCreateTimes('friday', 'open', e.target.value))} value={guard(this.props.createLocation, '.location_schedule.friday.open') || ''} /></div>
                                <div>-</div>
                                <div><input name="fridayHoursClose" placeholder="8pm" onChange={e => this.props.dispatch(setCreateTimes('friday', 'close', e.target.value))} value={guard(this.props.createLocation, '.location_schedule.friday.close') || ''} /></div>
                                <div className="checkmarks">
                                    <div className="row">
                                        <div>Closed:</div>
                                        <label className="checkmarkContainer inline h6">
                                            <input type="checkbox" name="fridayClosed" onChange={() => { }} onClick={() => this.props.dispatch(setCreateCloseAllDay('friday'))} checked={!!guard(this.props.createLocation, '.location_schedule.friday.closed')} />
                                            <span className="checkmark small" />
                                        </label>
                                    </div>
                                    <div className="row">
                                        <div>24h:</div>
                                        <label className="checkmarkContainer inline h6">
                                            <input type="checkbox" name="friday24h" onChange={() => { }} onClick={() => this.props.dispatch(setCreateOpenAllDay('friday'))} checked={!!guard(this.props.createLocation, '.location_schedule.friday.allday')} />
                                            <span className="checkmark small" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {// this.props.createLocation.hourSet !== 'weekdays' &&
                                <div className="row">
                                    <div>Saturday:</div>
                                    <div><input name="saturdayHoursOpen" placeholder="8am" onChange={e => this.props.dispatch(setCreateTimes('saturday', 'open', e.target.value))} value={guard(this.props.createLocation, '.location_schedule.saturday.open') || ''} /></div>
                                    <div>-</div>
                                    <div><input name="saturdayHoursClose" placeholder="8pm" onChange={e => this.props.dispatch(setCreateTimes('saturday', 'close', e.target.value))} value={guard(this.props.createLocation, '.location_schedule.saturday.close') || ''} /></div>
                                    <div className="checkmarks">
                                        <div className="row">
                                            <div>Closed:</div>
                                            <label className="checkmarkContainer inline h6">
                                                <input type="checkbox" name="saturdayClosed" onChange={() => { }} onClick={() => this.props.dispatch(setCreateCloseAllDay('saturday'))} checked={!!guard(this.props.createLocation, '.location_schedule.saturday.closed')} />
                                                <span className="checkmark small" />
                                            </label>
                                        </div>
                                        <div className="row">
                                            <div>24h:</div>
                                            <label className="checkmarkContainer inline h6">
                                                <input type="checkbox" name="saturday24h" onChange={() => { }} onClick={() => this.props.dispatch(setCreateOpenAllDay('saturday'))} checked={!!guard(this.props.createLocation, '.location_schedule.saturday.allday')} />
                                                <span className="checkmark small" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }
                            {// this.props.createLocation.hourSet !== 'weekdays' &&
                                <div className="row">
                                    <div>Sunday:</div>
                                    <div><input name="sundayHoursOpen" placeholder="8am" onChange={e => this.props.dispatch(setCreateTimes('sunday', 'open', e.target.value))} value={guard(this.props.createLocation, '.location_schedule.sunday.open') || ''} /></div>
                                    <div>-</div>
                                    <div><input name="sundayHoursClose" placeholder="8pm" onChange={e => this.props.dispatch(setCreateTimes('sunday', 'close', e.target.value))} value={guard(this.props.createLocation, '.location_schedule.sunday.close') || ''} /></div>
                                    <div className="checkmarks">
                                        <div className="row">
                                            <div>Closed:</div>
                                            <label className="checkmarkContainer inline h6">
                                                <input type="checkbox" name="sundayClosed" onChange={() => { }} onClick={() => this.props.dispatch(setCreateCloseAllDay('sunday'))} checked={!!guard(this.props.createLocation, '.location_schedule.sunday.closed')} />
                                                <span className="checkmark small" />
                                            </label>
                                        </div>
                                        <div className="row">
                                            <div>24h:</div>
                                            <label className="checkmarkContainer inline h6">
                                                <input type="checkbox" name="sunday24h" onChange={() => { }} onClick={() => this.props.dispatch(setCreateOpenAllDay('sunday'))} checked={!!guard(this.props.createLocation, '.location_schedule.sunday.allday')} />
                                                <span className="checkmark small" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="spacerSmall" />
                        {this.state.loadingSubmitForm
                            ? (<Spinner />)
                            : (
                                <input
                                    type="submit"
                                    ref={submit => { this.submit = submit; }}
                                    value="Add new Location &gt;"
                                    className="rightRoundButton whiteBorder whiteOpaque fontWhite"
                                />
                            )}
                    </div>
                </form>
            </React.Fragment>
        );

        return (
            <div className="mainAppContainer LoginScreens">
                <div className="bodyContent backgroundGreen">
                    <div className="three-column-layout">
                        {this.props.createLocation
                            && (
                                <Columns
                                    c1={c1}
                                    c2={c2}
                                    c3={c3}
                                />
                            )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        createLocation: state.locations.createLocation
    };
}

export default connect(mapStateToProps)(AddLocationDetails);
