import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { setEmail } from '../../redux/actions/users';
import ApiError from '../../helpers/ApiError';
import TopNav from './TopNav';

class RegisterEmail extends React.Component {
    componentDidMount() {
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    verifyData = e => {
        e.preventDefault();
        if (!this.props.userData.user_email) {
            ApiError('Please write your Email!');
        } else if (!/\S+@\S+\.\S+/.test(this.props.userData.user_email)) {
            ApiError('Please verify your Email!');
        } else {
            this.props.history.push('/registerpassword');
        }
    }

    render() {
        return (
            <div className="mainAppContainer LoginScreens Register">
                <TopNav backLink="/registeruser" />
                <form onSubmit={this.verifyData}>
                    <div className="LoginWrapper wrapper">
                        <h1>What's your email?</h1>
                    </div>
                    <div className="LoginWrapper wrapper maxWidth">
                        <div className="innerLabelInput">
                            <span className="blocking-span">
                                <input className="inputText" id="email" name="email" type="email" onChange={e => this.props.dispatch(setEmail(e.target.value))} required value={this.props.userData.user_email} />
                                <span className="floating-label">Email</span>
                            </span>
                        </div>
                        <input
                            type="submit"
                            value="Next &gt;"
                            className="rightRoundButton whiteBorder whiteOpaque fontWhite"
                        />
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(RegisterEmail);
