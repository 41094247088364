import React from 'react';
import { confirmAlert } from 'react-confirm-alert';

import 'react-confirm-alert/src/react-confirm-alert.css';

const BasicAlert = msg => {
    confirmAlert({
        customUI: ({ onClose }) => (
            <div className="custom-confirm-ui">
                <h1>{msg}</h1>
                <div className="row">
                    <button
                        className="flexButtons greenBorder whiteBG fontGreen h6"
                        type="button"
                        onClick={onClose}
                    >
                        OK
                    </button>
                </div>
            </div>
        )
    });
};

export default BasicAlert;
