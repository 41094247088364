import React from 'react';
import { connect } from 'react-redux';
import DatePicker from 'rmc-date-picker';
import moment from 'moment';
import { toast } from 'react-toastify';
import qs from 'qs';
import ReactGA from 'react-ga';
import Textarea from 'react-textarea-autosize';

import 'rmc-picker/assets/index.css';
import 'rmc-date-picker/assets/index.css';

import {
    setCreateDogName,
    setCreateDogPicture,
    setCreateDogPictureFile,
    clearDogPictureFile,
    setCreateDogSex,
    setCreateDogDOB,
    setCreateDogFixed,
    setCreateDogPlaydates,
    setCreateDogWeight,
    setCreateDogSocialMedia,
    setCreateDogDescription,
    createDog,
    clearDogCreate,
    setCreateBreedsSelected, getDogBreeds
} from '../../redux/actions/dogs';
import { uploadPicture } from '../../redux/actions/all';
import ApiError from '../../helpers/ApiError';
import { formatMonth, guard } from '../../helpers/Functions';
import { camera, cameraRoll, cameraBlob } from '../../helpers/Camera';
import Avatar from '../../layout/UI/Avatar';
import Spinner from '../../layout/UI/Spinner';

class UserDogAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            breeds: [],
            breedSearch: '',
            list_selected_breeds: [],
            pictureModal: false,
            socialMedia: {},
            showBreeds: false,
            loadingSubmitForm: false
        };
    }

    componentDidMount() {
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        this.props.dispatch(getDogBreeds(this.props.credentials.token)).then(() => this.mapBreeds());
        if (!this.props.dogCreate.sex) {
            this.props.dispatch(setCreateDogSex('male'));
        }
        if (this.props.dogCreate.fixed !== true && this.props.dogCreate.fixed !== false) {
            this.props.dispatch(setCreateDogFixed(true));
        }
        if (!this.props.dogCreate.dob) {
            this.props.dispatch(setCreateDogDOB(moment()._d));
        }
        if (this.props.dogCreate.playdates !== true && this.props.dogCreate.playdates !== false) {
            this.props.dispatch(setCreateDogPlaydates(true));
        }
        if (!this.props.dogCreate.weight) {
            this.props.dispatch(setCreateDogWeight(50));
        }
        this.setState({ // or social_media
            socialMedia: (this.props.dogCreate && this.props.dogCreate.socialmedia) || {}
        });
    }

    filesChanged = files => {
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            const file = files[i];
            reader.onloadend = () => {
                this.props.dispatch(setCreateDogPictureFile(file));
                this.props.dispatch(setCreateDogPicture(reader.result));
            };
            reader.readAsDataURL(file);
        }
    }

    toggleModal = () => {
        this.setState({ pictureModal: !this.state.pictureModal });
    }

    changeImage = () => {
        const selectfile = document.getElementById('file');
        selectfile.click();
    }

    removeImage = () => {
        this.props.dispatch(clearDogPictureFile());
    }

    phoneCamera = () => {
        camera(this.phoneSuccess);
    }

    phoneUpload = () => {
        cameraRoll(this.phoneSuccess);
    }

    phoneSuccess = file => {
        this.props.dispatch(setCreateDogPicture(file));
        cameraBlob(file, this.uploadToProfile);
    }

    uploadToProfile = imgBlob => {
        this.props.dispatch(setCreateDogPictureFile(imgBlob));
    }

    uploadPicture = () => {
        let params = false;
        if (this.props.history.location.search) {
            params = qs.parse(this.props.history.location.search.slice(1));
        }
        if (this.props.dogCreate.success && this.props.dogCreate.new_dog_id) {
            const dogId = this.props.dogCreate.new_dog_id;
            if (this.props.dogCreate.pictureFile) {
                toast.success('Uploading Dog Profile Image');
                this.props.dispatch(uploadPicture(this.props.credentials.token, this.props.dogCreate.new_dog_id, this.props.dogCreate.pictureFile, true))
                    .then(() => {
                        this.props.dispatch(clearDogCreate());
                        this.setState({
                            loadingSubmitForm: false
                            // loadingPhotoSubmitForm: false
                        }, () => {
                            this.submit.removeAttribute('disabled');
                        });
                        if (params && params.redirect) {
                            this.props.history.push(params.redirect);
                        } else {
                            this.props.history.push('/dog/' + dogId);
                        }
                    });
            } else {
                this.props.dispatch(clearDogCreate());
                if (params && params.redirect) {
                    this.props.history.push(params.redirect);
                } else {
                    this.props.history.push('/dog/' + dogId);
                }
            }
        } else {
            ApiError('Make sure all data is correct');
        }
    }

    onDateChange = date => {
        this.props.dispatch(setCreateDogDOB(date));
    }

    socialMediaHandler = event => {
        this.setState({
            socialMedia: { ...this.state.socialMedia, [event.target.name]: event.target.value }
        }, () => {
            this.props.dispatch(setCreateDogSocialMedia(this.state.socialMedia));
        });
    }

    showBreeds = () => {
        this.setState(prevState => ({
            showBreeds: !prevState.showBreeds
        }));
    }

    toggleBreeds = breed_id => {
        let listBreeds = [...this.state.list_selected_breeds];
        if (listBreeds.indexOf(breed_id) !== -1) {
            listBreeds = listBreeds.filter(item => item !== breed_id);
        } else {
            listBreeds.push(breed_id);
        }
        this.setState({ list_selected_breeds: listBreeds }, () => this.mapBreeds(this.state.breedSearch));
        this.props.dispatch(setCreateBreedsSelected(breed_id));
    }

    mapBreeds = (search = '') => {
        const selectedBreeds = [];
        this.setState({ breedSearch: search });
        if (this.props.breeds.length) {
            const breedlist = this.props.breeds.map(breed => {
                let showBreedBox = null;
                if (breed.breed_name.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                    let selectedClass = '';
                    if (this.props.dogCreate && this.props.dogCreate.breeds && this.props.dogCreate.breeds.indexOf(breed.breed_id) !== -1) {
                        selectedClass = 'selected';
                    }
                    // set the showBreedBox variable to equal this new DOM element
                    showBreedBox = <div className={selectedClass} key={breed.breed_id} id={breed.breed_id} onClick={e => { e.target.classList.toggle('selected'); this.toggleBreeds(breed.breed_id); }}>{breed.breed_name}</div>;
                }
                if (this.props.dogCreate && this.props.dogCreate.breeds && this.props.dogCreate.breeds.indexOf(breed.breed_id) !== -1) {
                    selectedBreeds.push(<li key={breed.breed_id} className="dogBreed" id={breed.breed_id}>{breed.breed_name}<div className="removeIcon" onClick={() => this.toggleBreeds(breed.breed_id)}><i className="icn icon-cross" /> </div></li>);
                }
                return showBreedBox;
            });
            this.setState({ breeds: breedlist });
        }
        this.setState({
            list_selected_breeds: selectedBreeds
        });
    }

    removeBreeds = () => {
        const grid = document.getElementById('grid');
        grid.childNodes.forEach(item => {
            if (item.className === 'selected') {
                item.classList.toggle('selected');
            }
        });
        this.props.dispatch(setCreateBreedsSelected(this.state.list_selected_breeds));
        const breedArray = this.state.list_selected_breeds;
        const propDispatch = this.props.dispatch;
        breedArray.forEach(breed => {
            const prop = breed.props.id;
            propDispatch(setCreateBreedsSelected(prop));
        });
        this.setState({
            list_selected_breeds: []
        });
    }

    verifyData = e => {
        e.preventDefault();
        this.submit.setAttribute('disabled', 'disabled');
        if (!this.props.dogCreate.name) {
            ApiError('Please write your dogs name!');
            this.submit.removeAttribute('disabled');
            return;
        }
        if (!this.props.dogCreate.sex) {
            ApiError('Please select the sex of the dog');
            this.submit.removeAttribute('disabled');
            return;
        }
        if (!this.state.list_selected_breeds.length || !this.props.dogCreate.breeds || !this.props.dogCreate.breeds.length) {
            ApiError('Please select at least one breed!');
            this.submit.removeAttribute('disabled');
            this.showBreeds();
            return;
        }
        this.setState({ loadingSubmitForm: true });
        const dataSet = {};
        if (this.props.dogCreate.name) {
            dataSet.dog_name = this.props.dogCreate.name;
        }
        if (this.props.dogCreate.description) {
            dataSet.dog_description = this.props.dogCreate.description;
        }
        if (!!this.props.dogCreate.breeds && !!this.props.dogCreate.breeds.length) {
            dataSet.breeds = [];
            for (const i in this.props.dogCreate.breeds) {
                dataSet.breeds.push({ breed_id: this.props.dogCreate.breeds[i] });
            }
        }
        if (this.props.dogCreate.dob) {
            dataSet.dog_birthdate = moment(this.props.dogCreate.dob).format('YYYY-MM-DD');
        }
        if (this.props.dogCreate.fixed === true || this.props.dogCreate.fixed === false) {
            dataSet.dog_fixed = this.props.dogCreate.fixed ? 1 : 0;
        }
        if (this.props.dogCreate.playdates === true || this.props.dogCreate.playdates === false) {
            dataSet.dog_playdates = this.props.dogCreate.playdates ? 1 : 0;
        }
        if (this.props.dogCreate.sex === 'male' || this.props.dogCreate.sex === 'female') {
            dataSet.dog_sex = this.props.dogCreate.sex;
        }
        if (this.props.dogCreate.weight) {
            dataSet.dog_weight = this.props.dogCreate.weight;
        }
        if (this.props.dogCreate.socialmedia) {
            dataSet.dog_socialmedia = this.props.dogCreate.socialmedia;
        }
        this.props.dispatch(createDog(this.props.credentials.token, dataSet))
            .then(() => {
                if (!this.props.dogCreate.pictureFile) {
                    this.setState({ loadingSubmitForm: false }, () => {
                        this.submit.removeAttribute('disabled');
                    });
                }
                toast.success('Dog Profile Created');
                this.uploadPicture();
            })
            .catch(err => {
                this.setState({ loadingSubmitForm: false }, () => {
                    this.submit.removeAttribute('disabled');
                });
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    toggleSex(e) {
        this.props.dispatch(setCreateDogSex(e));
    }

    toggleFixed(val) {
        this.props.dispatch(setCreateDogFixed(val));
    }

    togglePlaydates(val) {
        this.props.dispatch(setCreateDogPlaydates(val));
    }

    changeWeight(e) {
        this.props.dispatch(setCreateDogWeight(e.target.value));
    }

    addWeight(action, value) {
        let v = 50;
        if (value) {
            v = value;
        }
        if (action === 'add' && v < 250) {
            this.props.dispatch(setCreateDogWeight(parseInt(v, 10) + 1));
        } else if (action === 'sub' && v > 1) {
            this.props.dispatch(setCreateDogWeight(v - 1));
        }
    }

    render() {
        return (
            <div className="mainAppContainer">
                <div className="bodyContent backgroundRed">
                    <form onSubmit={this.verifyData}>
                        <div className="create-dog wrapper maxWidth">
                            <h1 className="create-dog-title">Confirm your dog's info</h1>
                            {this.state.loadingSubmitForm
                                ? (<Spinner />)
                                : (
                                    <div className="create-dog-info">
                                        <div className="profile-avatar">
                                            <input className="hidden" id="file" type="file" onChange={e => this.filesChanged(e.target.files)} accept="image/*" required />
                                            <div className="fontWhite" id="profileImage">
                                                <div className="round-avatar huge">
                                                    <Avatar
                                                        src={guard(this.props.dogCreate, '.picture')}
                                                        onClick={this.changeImage}
                                                        alt={this.props.dogCreate.name}
                                                        className="changeImage"
                                                    />
                                                </div>
                                                <div className="alignCenter marginTopSmall">
                                                    {this.props.dogCreate.picture && this.props.dogCreate.pictureFile && (
                                                        <div className="add-dog-image" onClick={this.removeImage}>Remove Image</div>
                                                    )}
                                                    {(!this.props.dogCreate.picture || !this.props.dogCreate.pictureFile) && (
                                                        <div className="add-dog-image" onClick={this.changeImage}>Add Image</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="create-dog-info">
                                            <div className="top-section">
                                                <div className="row">
                                                    <div className="create-dog-name">
                                                        <label>Name</label>
                                                        <input className="inputText border__input" name="dogName" type="text" required value={guard(this.props.dogCreate, '.name') || ''} onChange={e => this.props.dispatch(setCreateDogName(e.target.value))} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="create-dog-gender">
                                                        <label>Gender</label>
                                                        <div className="options">
                                                            <label onClick={() => this.toggleSex('male')} className="checkmarkContainer h5">Male
                                                                <span className="checkmark">
                                                                    {this.props.dogCreate.sex === 'male'
                                                                        ? (<i className="icn icon-check-mark fontRed" />)
                                                                        : (<i className="icn" />)}
                                                                </span>
                                                            </label>
                                                            <label onClick={() => this.toggleSex('female')} className="checkmarkContainer h5">Female
                                                                <span className="checkmark">
                                                                    {this.props.dogCreate.sex === 'female'
                                                                        ? (<i className="icn icon-check-mark fontRed" />)
                                                                        : (<i className="icn" />)}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="create-dog-fixed">
                                                        <label>Fixed</label>
                                                        <div className="options">
                                                            <label onClick={() => this.toggleFixed(true)} className="checkmarkContainer h5">Fixed
                                                                <span className="checkmark">
                                                                    {this.props.dogCreate.fixed === true
                                                                        ? (<i className="icn icon-check-mark fontRed" />)
                                                                        : (<i className="icn" />)}
                                                                </span>
                                                            </label>
                                                            <label onClick={() => this.toggleFixed(false)} className="checkmarkContainer h5">Not Fixed
                                                                <span className="checkmark">
                                                                    {this.props.dogCreate.fixed === false
                                                                        ? (<i className="icn icon-check-mark fontRed" />)
                                                                        : (<i className="icn" />)}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="middle-section">
                                                <div className="row">
                                                    <div className="create-dog-dob">
                                                        <label>When was your pooch born?</label>
                                                        <div className="selectDate fontWhite">
                                                            <DatePicker
                                                                mode="date"
                                                                date={this.props.dogCreate.dob ? moment(this.props.dogCreate.dob)._d : moment()._d}
                                                                formatMonth={formatMonth}
                                                                onDateChange={this.onDateChange}
                                                                maxDate={moment()._d}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="create-dog-playdates">
                                                        <label>Is your pooch available for Events?</label>
                                                        <div className="options">
                                                            <label onClick={() => this.togglePlaydates(true)} className="checkmarkContainer h5">Yes
                                                                <span className="checkmark">
                                                                    {this.props.dogCreate.playdates === true
                                                                        ? (<i className="icn icon-check-mark fontRed" />)
                                                                        : (<i className="icn" />)}
                                                                </span>
                                                            </label>
                                                            <label onClick={() => this.togglePlaydates(false)} className="checkmarkContainer h5">No
                                                                <span className="checkmark">
                                                                    {this.props.dogCreate.playdates === false
                                                                        ? (<i className="icn icon-check-mark fontRed" />)
                                                                        : (<i className="icn" />)}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="create-dog-description">
                                                        <label>How would you describe your pooch? </label>
                                                        <Textarea value={this.props.dogCreate.description || ''} onChange={e => this.props.dispatch(setCreateDogDescription(e.target.value))} className="niceTextarea" placeholder="e.g. My pooch is the fun and playful kind..." />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="create-dog-weight">
                                                        <label>How much does your pooch weigh?</label>
                                                        <input className="slider white" id="slider" type="range" min="1" max="250" step="1" value={this.props.dogCreate.weight || ''} onChange={e => this.changeWeight(e)} />
                                                        <div className="sliderValue">
                                                            <span className="sub fontWhite" onClick={() => this.addWeight('sub', this.props.dogCreate.weight)}> - </span> <span className="fontWhite">{parseFloat(this.props.dogCreate.weight).toFixed(2)}lbs</span> <span className="sub fontWhite" onClick={() => this.addWeight('add', this.props.dogCreate.weight)}> + </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="create-dog-social-media">
                                                    <label>Add Social Media for your pooch</label>
                                                    <span className="blocking-span">
                                                        <label>Facebook</label>
                                                        <input className="inputText border__input" name="facebook" type="text" onChange={this.socialMediaHandler} value={(this.state.socialMedia && this.state.socialMedia.facebook) || ''} />
                                                    </span>
                                                    <span className="blocking-span">
                                                        <label>Twitter</label>
                                                        <input className="inputText border__input" name="twitter" type="text" onChange={this.socialMediaHandler} value={(this.state.socialMedia && this.state.socialMedia.twitter) || ''} />
                                                    </span>
                                                    <span className="blocking-span">
                                                        <label>Instagram</label>
                                                        <input className="inputText border__input" name="instagram" type="text" onChange={this.socialMediaHandler} value={(this.state.socialMedia && this.state.socialMedia.instagram) || ''} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="spacerSmall" />
                                            <div className="bottom-section">
                                                <div className="create-dog-select-breeds">
                                                    <label className="select-breed-label" onClick={this.showBreeds}> Select your dogs breed or breeds</label>
                                                    {!this.state.showBreeds && (
                                                        <i className="icn icon-right-arrow fontWhite" />
                                                    )}
                                                    {this.state.showBreeds && (
                                                        <i className="icn icon-arrow_drop_down fontWhite" />
                                                    )}
                                                    {!!this.props.dogCreate.breeds && (
                                                        <div className="breedSelected">
                                                            <ul className="breeds" id="list">
                                                                {this.state.list_selected_breeds}
                                                            </ul>
                                                            {this.state.list_selected_breeds.length > 1 && (
                                                                <div className="removeBreeds" onClick={e => this.removeBreeds(e)}><p>Remove All</p></div>
                                                            )}
                                                        </div>
                                                    )}
                                                    {this.state.showBreeds && (
                                                        <div className="dog-breeds">
                                                            <div className="simpleSearchBar row breedSearch">
                                                                <i className="icn icon-search fontDarkGray" />
                                                                <input value={this.state.breedSearch} onChange={e => this.mapBreeds(e.target.value)} placeholder="Poodle, Pug, etc..." />
                                                                <span className={'hover cancel_search_btn ' + (this.state.breedSearch ? 'fontRed' : 'fontGray')} onClick={() => this.mapBreeds()}>Cancel</span>
                                                            </div>
                                                            <div className="tableGrid" id="grid">
                                                                {this.state.breeds}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="spacerSmall" />
                                            <div className="create-dog-button">
                                                <button type="submit" ref={submit => { this.submit = submit; }} onClick={e => this.verifyData(e)} className="flexButtons whiteBG fontGreen">
                                                    Create Dog
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        dogCreate: state.dogs.dogCreate,
        breeds: state.dogs.breeds
    };
}

export default connect(mapStateToProps)(UserDogAdd);
