import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactGA from 'react-ga';

import { getLostDogs, setSearchLostDogs } from '../../redux/actions/dogs';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import NotFound from '../../layout/NotFound';
import NavigationSearchBar from '../../layout/UI/NavigationSearchBar';
import Spinner from '../../layout/UI/Spinner';
import Avatar from '../../layout/UI/Avatar';
import ApiError from '../../helpers/ApiError';
import { guard } from '../../helpers/Functions';
import { Pull2Refresh, infiniteScroll } from '../../helpers/Pull2Refresh';
import { getGeoLocation } from '../../helpers/GeoLocation';

class MissingDogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            missingDogs: [],
            loading: true,
            reloading: false,
            currentPage: 1,
            // loadingMore: false,
            totalPages: 1, // This it's being used by infiniteScroll->handleScroll->loadMore
            missingDogList: []
        };
    }

    getGeoLocation = getGeoLocation.bind(this)

    componentDidMount() {
        window.scrollTo(0, 0);
        this.start();
        Pull2Refresh(this.start);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    init = () => {
        this.setState({
            missingDogs: [],
            loading: true,
            reloading: false,
            // loadingMore: false,
            currentPage: 1,
            totalPages: 1,
            missingDogList: []
        }, this.start());
    }

    start = () => {
        this.setState({ reloading: true });
        this.getGeoLocation(this.props, this.showLostDogs, this.props.credentials.token, true);
    }

    showLostDogs = () => {
        if (!this.props.userData.user_location && this.state.currentPage === 1) {
            ApiError('Please turn on your Location to see missing dogs near you.');
        }

        this.props.dispatch(getLostDogs(this.props.credentials.token, (this.props.userData.user_location || null), this.state.currentPage, this.props.searchValue))
            .then(response => {
                this.setState(prevState => ({
                    loading: false,
                    reloading: false,
                    totalPages: response.value.headers['x-pagination-page-count'],
                    // loadingMore: false,
                    missingDogList: prevState.missingDogList.concat(response.value.data)
                }), () => {
                    if (this.state.currentPage < this.state.totalPages) {
                        infiniteScroll(this, this.showLostDogs);
                    }
                    this.missingMap();
                });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loading: false, reloading: false });
            });
    }

    listBreeds(breeds) {
        const listedBreeds = breeds.map(breed => breed.breed_name);
        return listedBreeds.join(', ') + (listedBreeds.length > 1 ? ' mix' : '');
    }

    missingMap() {
        const count = this.state.missingDogs.length;

        const missingDogs = this.state.missingDogList.map((dog, index) => (
            <div className="participantListItem" key={count + index}>
                <Link to={'/dog/' + dog.dog_id} className="user row">
                    <div className="round-avatar dog">
                        <Avatar
                            src={guard(dog, '.dog.images.0.image_smallurl')}
                            alt={dog.dog.dog_name}
                            type="dog"
                        />
                    </div>
                    <div className="info">
                        <div className="name missingDogTitle fontRed">{dog.dog.dog_name}</div>
                        {!!dog.dogaddress_reward && (
                            <div>Reward: $<strong className="">{dog.dogaddress_reward}</strong> </div>
                        )}
                        <div><strong>{!!dog && !!dog.dog && !!dog.dog.breeds && !!dog.dog.breeds.length && this.listBreeds(dog.dog.breeds)}</strong></div>
                        <div>
                            {guard(dog, '.dog.dog_sex') ? dog.dog.dog_sex.charAt(0).toUpperCase() + dog.dog.dog_sex.slice(1) + ' ' : null}
                            {guard(dog, '.dog.dog_weight') ? parseFloat(dog.dog.dog_weight).toFixed(2) + ' lbs ' : null}
                            {guard(dog, '.dog.dog_fixed') ? 'Fixed ' : 'Not Fixed '}
                        </div>
                        {!!dog.dogaddress_datetime && (
                            <div><strong>Date Lost: </strong><br />{moment(dog.dogaddress_datetime).format('MMMM Do YYYY, h:mm a')}</div>
                        )}
                        {!!dog && !!dog.address && (
                            <div>
                                <strong>Last Seen: </strong><br />
                                {guard(dog, '.address.address_street') ? dog.address.address_street + ' ' : null}
                                {guard(dog, '.address.address_street2') ? dog.address.address_street2 + ' ' : null}
                                {guard(dog, '.address.address_city') ? dog.address.address_city + ' ' : null}
                                {guard(dog, '.address.address_state') ? dog.address.address_state + ' ' : null}
                                {guard(dog, '.address.address_country') ? dog.address.address_country + ' ' : null}
                                {guard(dog, '.address.address_zipcode') ? dog.address.address_zipcode + ' ' : null}
                            </div>
                        )}
                    </div>
                    <div className="distance">
                        {(!!dog && !!dog.Distance) ? parseFloat(dog.Distance).toFixed(2) + ' km' : null}
                    </div>
                </Link>
            </div>
        ));
        this.setState(prevState => ({ missingDogs: prevState.missingDogs.concat(missingDogs) }));
    }

    render() {
        return (
            <div className="mainAppContainer">
                <MainNavigation title="Missing Dogs" />
                <NavigationSearchBar
                    callback={this.init}
                    disp={this.props.dispatch}
                    searchInputHandler={setSearchLostDogs}
                    searchValue={this.props.searchValue}
                />
                <div className="bodyContent maxWidth noPadding">
                    {!this.state.loading && !this.state.missingDogs.length && (
                        <NotFound type="lostdogs" />
                    )}
                    {(!!this.state.loading || !!this.state.reloading) && (
                        <Spinner />
                    )}
                    {!this.state.loading && this.state.missingDogs.length > 0 && (
                        <div className=" pull2refresh">
                            <div className="participantList lostDogsList">
                                {this.state.missingDogs}
                            </div>
                        </div>
                    )}
                </div>
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        searchValue: state.dogs.searchLostDogs
    };
}

export default connect(mapStateToProps)(MissingDogs);
