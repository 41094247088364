import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { getSearchFilters } from '../../redux/actions/searchFilters';
import { setCreateLocationTypes, setCreateLocationName } from '../../redux/actions/locations';
import Columns from '../../hoc/Columns';
import { guard } from '../../helpers/Functions';
import ApiError from '../../helpers/ApiError';

class AddLocationType extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: []
        };
    }

    componentDidMount() {
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        window.scrollTo(0, 0);
        this.props.dispatch(getSearchFilters(this.props.credentials.token)).then(() => this.sortList());
    }

    sortList() {
        let otherOption = null;
        const list = this.props.filterList.map((item, index) => {
            let classes = 'wideButtons transparentBorder fontRed ';
            if (!!this.props.createLocation && !!this.props.createLocation.locationtypes && this.props.createLocation.locationtypes.some(o => o['locationtype_id'] === item.locationtype_id)) {
                classes += 'whiteBG';
            } else {
                classes += 'whiteOpaque';
            }
            if (item.locationtype_name === 'Other') {
                otherOption = (
                    <Link key={index} to="#" onClick={e => { e.preventDefault(); this.selectType(e, { id: item.locationtype_id, name: item.locationtype_name }); }} className={classes}>
                        {item.locationtype_name}
                    </Link>
                );
                return null;
            }
            return (
                <Link key={index} to="#" onClick={e => { e.preventDefault(); this.selectType(e, { id: item.locationtype_id, name: item.locationtype_name }); }} className={classes}>
                    {item.locationtype_name}
                </Link>
            );
        });
        list.push(otherOption);
        this.setState({ list });
    }

    selectType(e, data) {
        e.target.classList.toggle('whiteOpaque');
        e.target.classList.toggle('whiteBG');
        this.props.dispatch(setCreateLocationTypes(data));
    }

    verifyData(e) {
        if (!this.props.createLocation.location_name) {
            e.preventDefault();
            ApiError('Please write the name of the Park or Business');
        }
        if (!(!!this.props.createLocation && !!this.props.createLocation.locationtypes && !!this.props.createLocation.locationtypes.length)) {
            e.preventDefault();
            ApiError('Please select one of the options');
        }
    }

    render() {
        const c1 = (<React.Fragment />);
        const c3 = (<React.Fragment />);

        const c2 = (
            <React.Fragment>
                <div className="LoginWrapper">
                    <h1 className="section">What is the Name of this Park or Business?</h1>
                </div>
                <div className="LoginWrapper wideForm">
                    <div className="innerLabelInput">
                        <span className="blocking-span">
                            <input className="inputText" name="locationName" type="text" onChange={e => this.props.dispatch(setCreateLocationName(e.target.value))} required value={guard(this.props.createLocation, '.location_name') || ''} />
                            <span className="floating-label">Name</span>
                        </span>
                    </div>
                    <h1 className="section">What kind of place is this?</h1>
                </div>
                <div className="LoginWrapper flex-grid">
                    {this.state.list}
                </div>
                <div className="LoginWrapper">
                    <div className="spacerSmall" />
                    <Link to={'/location/add/address' + this.props.history.location.search} className="rightRoundButton whiteBorder whiteOpaque fontWhite" onClick={event => this.verifyData(event)}>
                        Next &gt;
                    </Link>
                </div>
            </React.Fragment>
        );

        return (
            <div className="mainAppContainer LoginScreens">
                <div className="bodyContent backgroundRed">
                    <div className="three-column-layout">
                        <Columns
                            c1={c1}
                            c2={c2}
                            c3={c3}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        filterList: state.searchFilters.filterList,
        createLocation: state.locations.createLocation
    };
}

export default connect(mapStateToProps)(AddLocationType);
