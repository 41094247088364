import React from 'react';
import ReactModal from 'react-modal';

const CameraModal = ({ toggleModal, callbackCamera, callbackUpload }) => (
    <ReactModal
        isOpen={true}
        contentLabel="onRequestClose Example"
        onRequestClose={toggleModal}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
    >
        <h2>
            Upload or Select Image
        </h2>
        <div className="SocialMediaShareButton" onClick={() => callbackCamera()}>
            Take Picture
        </div>
        <div className="SocialMediaShareButton" onClick={() => callbackUpload()}>
            Select From Library
        </div>
    </ReactModal>
);

export default CameraModal;
