import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { findFriends, chat_toggleUser } from '../../redux/actions/users';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import UserList from '../../layout/UserList';
import Spinner from '../../layout/UI/Spinner';
import BasicAlert from '../../layout/UI/BasicAlert';
import { getGeoLocation } from '../../helpers/GeoLocation';
import ApiError from '../../helpers/ApiError';

let delayTimer;

export class ChatCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            friends: [],
            notFriends: [],
            loading1: true,
            loading2: true,
            // createdPlaydate: null,
            friendsPage: 1,
            friendsPageTotal: 1,
            loadingMoreFriends: false,
            notFriendsPage: 1,
            notFriendsPageTotal: 1,
            loadingMoreNotFriends: false
        };
    }

    getGeoLocation = getGeoLocation.bind(this)

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getGeoLocation(this.props, this.search, this.props.credentials.token);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    start = () => {
        // this.setState({ reloading: true });
        this.search();
    }

    search = () => {
        this.setState({
            friends: [],
            notFriends: [],
            loading1: true,
            loading2: true,
            // createdPlaydate: null,
            friendsPage: 1,
            friendsPageTotal: 1,
            loadingMoreFriends: false,
            notFriendsPage: 1,
            notFriendsPageTotal: 1,
            loadingMoreNotFriends: false
        }, () => {
            this.loadFriends();
            this.loadNotFriends();
        });
    }

    loadMoreFriends = () => {
        this.setState(prevState => ({
            friendsPage: prevState.friendsPage + 1,
            loadingMoreFriends: true
        }), this.loadFriends);
    }

    loadMoreNotFriends = () => {
        this.setState(prevState => ({
            notFriendsPage: prevState.notFriendsPage + 1,
            loadingMoreNotFriends: true
        }), this.loadNotFriends);
    }

    loadFriends = () => {
        const filter = '&filter[friendship_status][eq]=accepted';
        this.props.dispatch(findFriends(this.props.credentials.token, this.props.userData.user_location, filter, this.state.searchText, this.state.friendsPage))
            .then(response => {
                this.setState(prevState => ({
                    friends: [...prevState.friends, ...response.value.data],
                    loading1: false,
                    friendsPageTotal: response.value.headers['x-pagination-page-count'],
                    loadingMoreFriends: false
                }));
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loading1: false, loadingMoreFriends: false });
            });
    }

    loadNotFriends = () => {
        const filter = '&filter[friendship_status][neq]=accepted';
        this.props.dispatch(findFriends(this.props.credentials.token, this.props.userData.user_location, filter, this.state.searchText, this.state.notFriendsPage))
            .then(response => {
                this.setState(prevState => ({
                    notFriends: [...prevState.notFriends, ...response.value.data],
                    loading2: false,
                    notFriendsPageTotal: response.value.headers['x-pagination-page-count'],
                    loadingMoreNotFriends: false
                }));
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loading2: false, loadingMoreNotFriends: false });
            });
    }

    selectUser = (e, user) => {
        e.preventDefault();
        this.props.dispatch(chat_toggleUser(user));
    }

    handleSearchInput = e => {
        clearTimeout(delayTimer);
        const that = this;
        this.setState({ searchText: e.target.value });
        delayTimer = setTimeout(() => {
            that.search();
            // Do the ajax stuff
        }, 1500);
    }

    onClear = () => {
        clearTimeout(delayTimer);
        const that = this;
        this.setState({ searchText: '' });
        delayTimer = setTimeout(() => {
            that.search();
            // Do the ajax stuff
        }, 1500);
    }

    rightButtonOnClick = e => {
        e.preventDefault();
        if (!!this.props.selectedUsers && !!this.props.selectedUsers.length) {
            this.props.history.push('/chatconfirm');
        } else {
            BasicAlert('Please select at least one user');
        }
    }

    render() {
        let loadMoreFriendsButton = null;
        if (this.state.friendsPage < this.state.friendsPageTotal) {
            loadMoreFriendsButton = (
                <div className="fontGreen alignRight" onClick={this.loadMoreFriends}>Load More Friends</div>
            );
        }
        let loadMoreNotFriendsButton = null;
        if (this.state.notFriendsPage < this.state.notFriendsPageTotal) {
            loadMoreNotFriendsButton = (
                <div className="fontGreen alignRight spacer" onClick={this.loadMoreNotFriends}>Load More Users</div>
            );
        }

        return (
            <div className="mainAppContainer locationListSearch">
                <MainNavigation title="Select Users" leftButton="back" rightButton="next" rightButtonOnClick={this.rightButtonOnClick} />
                <div className="simpleSearchBar row">
                    <i className="icn icon-search fontDarkGray" />
                    <input onChange={this.handleSearchInput} value={this.state.searchText} placeholder="Search..." />
                    {!!this.state.searchText && (
                        <i onClick={this.onClear} className="icn icon-sign-out fontDarkGray" />
                    )}
                </div>
                <div className="bodyContent noPaddingTop">
                    <div className="maxWidth">
                        <div className="boxContainer"><div className="boxTitle row"><div className="title">Invite Friends to Chat</div></div></div>
                        {this.state.loading1 && (
                            <div><Spinner /><div className="spacer" /></div>
                        )}
                        <UserList users={this.state.friends} markSelected={true} selectedUsers={this.props.selectedUsers} onClick={this.selectUser} />
                        {!!this.state.loadingMoreFriends && (
                            <div><Spinner /><div className="spacer" /></div>
                        )}
                        <div className="spacerSmall" />
                        {loadMoreFriendsButton}
                        <div className="spacerSmall" />
                        <div className="boxContainer"><div className="boxTitle row"><div className="title">Invite Other Users to Chat</div></div></div>
                        {this.state.loading2 && (
                            <div>
                                <Spinner />
                                <div className="spacer" />
                            </div>
                        )}
                        <UserList
                            users={this.state.notFriends}
                            markSelected={true}
                            selectedUsers={this.props.selectedUsers}
                            onClick={this.selectUser}
                        />
                        {!!this.state.loadingMoreNotFriends && (
                            <div>
                                <Spinner />
                                <div className="spacer" />
                            </div>
                        )}
                        <div className="spacerSmall" />
                        {loadMoreNotFriendsButton}
                    </div>
                </div>
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        selectedUsers: state.users.chat.selectedUsers
    };
}

export default connect(mapStateToProps)(ChatCreate);
