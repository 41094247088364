import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

class ChatList extends React.Component {
    membersList(members) {
        const newMembers = members.filter(member => !(member.userId === String(this.props.credentials.user_id)));
        const membersNames = [];
        for (const i in newMembers) {
            if (newMembers.length > 1) {
                membersNames.push(newMembers[i].nickname.split(' ')[0]);
            } else {
                membersNames.push(newMembers[i].nickname);
            }
        }
        return membersNames.join(', ');
    }

    formatDate(timestamp) {
        return moment(timestamp).fromNow();
    }

    chatMapper(chatList) {
        return chatList.map((chat, index) => (
            <Link onClick={e => (this.props.openChatBox ? this.props.openChatBox(e, chat.url) : '')} key={index} className="fontGreen chatItemLink" to={'/chat/channel/' + chat.url}>
                <li className="chatItem row">
                    <div className="info">
                        <div className={'' + (chat && chat.unreadMessageCount ? 'bold' : '')}>
                            {chat && chat.unreadMessageCount ? '(' + chat.unreadMessageCount + ') ' : ''}
                            {chat.channelType === 'open'
                                ? chat.roomName
                                : this.membersList(chat.members)}
                        </div>
                        <span className={'h5 ' + (chat && chat.unreadMessageCount ? 'fontBlack bold' : 'fontDarkGray')}>
                            {/* THIS WILL SHOW THAT YOU SENT THE LAST MESSAGE */}
                            {!!chat
                                && !!chat.lastMessage
                                && !!chat.lastMessage._sender
                                && (chat.lastMessage._sender.userId === String(this.props.credentials.user_id))
                                && 'You: '}
                            {/* THIS WILL SHOW THE NAME OF THE SENDER IF THE ROOM HAS MORE THAN 2 PEOPLE (EXCEPT FOR YOURSELF) */}
                            {!!chat
                                && !!chat.lastMessage
                                && !!chat.lastMessage._sender
                                && (chat.lastMessage._sender.userId !== String(this.props.credentials.user_id))
                                && !!chat.members
                                && (chat.members.length > 2)
                                && chat.lastMessage._sender.nickname.split(' ')[0] + ': '}
                            {!!chat
                                && !!chat.lastMessage
                                && !!chat.lastMessage.message
                                && !!(chat.lastMessage.message.length > 20)
                                && chat.lastMessage.message.substring(0, 20 - 3) + '...'}
                            {!!chat
                                && !!chat.lastMessage
                                && !!chat.lastMessage.message
                                && !!(chat.lastMessage.message.length <= 20)
                                && chat.lastMessage.message}
                            {!!chat
                                && (!chat.lastMessage
                                    || !chat.lastMessage.message)
                                && chat.channelType !== 'open'
                                && 'Start the chat by Saying Hello'}
                            {!!chat
                                && (!chat.lastMessage
                                    || !chat.lastMessage.message)
                                && chat.channelType === 'open'
                                && 'Talk about the Event'}
                        </span>
                    </div>
                    <div className="additionalInfo">
                        <div className={(chat && chat.unreadMessageCount ? 'fontBlack bold' : 'fontDarkGray')}>
                            {!!chat
                                && !!chat.lastMessage
                                && !!chat.lastMessage.createdAt
                                && this.formatDate(chat.lastMessage.createdAt)}
                        </div>
                    </div>
                </li>
            </Link>
        ));
    }

    render() {
        return (
            <ul className="chatList">
                {this.props.chatList && this.props.chatList !== 'loading' && this.chatMapper(this.props.chatList)}
            </ul>
        );
    }
}

export default ChatList;
