import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { getLocation, checkFavorite, removeFavorite } from '../../redux/actions/locations';
import { uploadPicture, getReviews } from '../../redux/actions/all';
import OtherDetails from '../../layout/UI/OtherDetails';
import LocationMap from '../../layout/locations/LocationMap';
import { Stars } from '../../layout/UI/Stars';
import GalleryLightbox from '../../layout/gallery/GalleryLightbox';
import Spinner from '../../layout/UI/Spinner';
import NotFound from '../../layout/NotFound';
import { UserReview } from './UserReview';
import WhiteTitle from '../../hoc/WhiteTitle';
import ApiError from '../../helpers/ApiError';
import { timetable, urlFriendly, urlUnfriendly } from '../../helpers/Functions';
import Config from '../../helpers/Config';
import { camera, cameraMultiRoll, cameraBlob } from '../../helpers/Camera';
import LocationProfileHeader from './LocationProfileHeader';
import LocationProfileSidebar from './LocationProfileSidebar';
import HelmetTags from '../../helmetTags';

class LocationProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            location: (this.props.locationData && this.props.locationData.location) || null,
            gallery: (this.props.locationData && this.props.locationData.location && this.props.locationData.location.images) || [],
            loading: this.props.loading !== false,
            reviews: null,
            totalReviews: [],
            pictureModal: false,
            myLocationsToAdmin: [],
            pageTitle: (this.props.locationData && this.props.locationData.location && this.props.locationData.location.location_name) || (this.props.match && this.props.match.params && this.props.match.params.name && urlUnfriendly(this.props.match.params.name)) || '',
            pageDescription: (this.props.locationData && this.props.locationData.location && this.props.locationData.location.location_description) || 'Hey, Check out this great location on Pooch.pet',
            // pageImageSmall: Config.fileDirectory + 'assets/images/' + Config.logos.twoLines + '@1x.png',
            pageImageLarge: Config.fileDirectory + 'assets/images/' + Config.logos.twoLines + '@2x.png',
            locationAddress: (this.props.locationData && this.props.locationData.location && this.props.locationData.location.address) || '',
            locationCategories: (this.props.locationData && this.props.locationData.location && this.props.locationData.location.locationtypes) || '',
            locationSocialMedia: (this.props.locationData && this.props.locationData.location && this.props.locationData.location.location_socialmedia) || '',
            locationWebsite: (this.props.locationData && this.props.locationData.location && this.props.locationData.location.location_website) || '',
            currentId: (this.props.currentId) || false
        };
    }

    componentDidMount() {
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        if (!this.state.currentId) {
            this.start();
        } else {
            this.getReview(this.props.credentials.token, this.props.currentId);
        }
        this.adminLocations();
    }

    adminLocations = () => {
        if (this.props.userData && this.props.userData.adminlocations) {
            const adminLocations = this.props.userData.adminlocations.map(location => String(location.location_id));
            this.setState({ myLocationsToAdmin: adminLocations });
        }
    }

    start = () => {
        this.props.dispatch(getLocation(this.props.credentials.token, this.props.match.params.id))
            .then(response => {
                this.setState({ location: response.value.data, loading: false, gallery: response.value.data.images });
                this.getReview(this.props.credentials.token, this.props.match.params.id);
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loading: false });
            });
    }

    getReview = (token, id) => {
        this.props.dispatch(getReviews(token, id))
            .then(res => {
                this.setState({
                    reviews: res.value.data,
                    totalReviews: res.value.data
                });
            });
    }

    mapReviews = reviews => reviews.map((review, index) => (<UserReview key={index} user={review} />))

    checkFavorite = e => {
        e.preventDefault();
        const favorite = document.getElementsByClassName('checkfavorite');
        favorite[0].classList.toggle('hidden');
        const remove = document.getElementsByClassName('removefavorite');
        remove[0].classList.toggle('hidden');
        this.props.dispatch(checkFavorite(this.props.credentials.token, this.state.location.location_id));
    }

    removeFavorite = e => {
        e.preventDefault();
        const favorite = document.getElementsByClassName('checkfavorite');
        favorite[0].classList.toggle('hidden');
        const remove = document.getElementsByClassName('removefavorite');
        remove[0].classList.toggle('hidden');
        this.props.dispatch(removeFavorite(this.props.credentials.token, this.state.location.location_id));
    }

    filesChanged = files => {
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            const file = files[i];
            reader.onloadend = () => {
                this.props.dispatch(uploadPicture(this.props.credentials.token, this.state.location.location_id, file, false))
                    .then(response => this.updateThumbs(response));
            };
            reader.readAsDataURL(file);
        }
    }

    toggleModal = () => {
        this.setState({ pictureModal: !this.state.pictureModal });
    }

    changeImage = () => {
        if (Config.isMobileApp) {
            this.toggleModal();
        } else {
            const selectfile = document.getElementById('file');
            selectfile.click();
        }
    }

    phoneCamera = () => {
        camera(this.phoneSuccess);
    }

    phoneUpload = () => {
        cameraMultiRoll(this.phoneSuccess);
    }

    phoneSuccess = file => {
        cameraBlob(file, this.uploadToProfile);
    }

    uploadToProfile = imgBlob => {
        this.props.dispatch(uploadPicture(this.props.credentials.token, this.state.location.location_id, imgBlob, false))
            .then(response => this.updateThumbs(response));
    }

    updateThumbs = response => {
        this.setState(prevState => ({
            pictureModal: false,
            gallery: [response.value.data, ...prevState.gallery]
        }));
        if (!!navigator && !!navigator.camera && !!navigator.camera.cleanup) {
            navigator.camera.cleanup();
        }
    }

    render() {
        let additionalInfo = null;
        if (!!this.state.location && !!this.state.location.location_additionalinfo) {
            additionalInfo = (<OtherDetails location={this.state.location} />);
        }
        const pageTitle = (this.state.location && this.state.location.location_name) || this.state.pageTitle;
        const pageDescription = (this.state.location && this.state.location.location_description) || this.state.pageDescription;
        // let pageImageSmall = (this.state.location && this.state.location.images[0] && this.state.location.images[0].image_smallurl) || this.state.pageImageSmall
        const pageImageLarge = (this.state.location && this.state.location.images[0] && this.state.location.images[0].image_largeurl) || this.state.pageImageLarge;
        const locationAddress = (this.state.location && this.state.location.address.address_street + ', ' + this.state.location.address.address_city + ', ' + this.state.location.address.address_state) || this.state.locationAddress;
        const locationCategories = (this.state.location && this.state.location.locationtypes[0].locationtype_name) || this.state.locationCategories;
        const locationSocialMedia = (this.state.location && this.state.location.location_socialmedia && this.state.location.location_socialmedia.twitter) || this.state.locationSocialMedia;
        const locationWebsite = (this.state.location && this.state.location.location_website) || this.state.locationWebsite;
        let pageTitleSubPhotos = '';
        let pageTitleSubReviews = '';

        if (this.state.gallery.length === 1) {
            pageTitleSubPhotos = ' - ' + this.state.gallery.length + ' Photo';
        } else if (this.state.gallery.length > 1) {
            pageTitleSubPhotos = ' - ' + this.state.gallery.length + ' Photos';
        }

        if (this.state.totalReviews.length === 0) {
            pageTitleSubReviews = ' - ' + this.state.totalReviews.length + ' Reviews';
        }
        if (this.state.totalReviews.length === 1) {
            pageTitleSubReviews = ' - ' + this.state.totalReviews.length + ' Review';
        } else if (this.state.totalReviews.length > 1) {
            pageTitleSubReviews = ' - ' + this.state.totalReviews.length + ' Reviews';
        }

        return (
            <React.Fragment>
                <HelmetTags
                    title={pageTitle + pageTitleSubPhotos + pageTitleSubReviews + ' - ' + locationCategories + ' - ' + locationAddress + ' | Pooch - Dog Friendly Network'}
                    description={pageDescription || locationAddress || ''}
                    image={pageImageLarge}
                    site={locationSocialMedia}
                    twitterCard={pageImageLarge}
                    type={locationWebsite}
                />
                <div className="mainAppContainer">
                    <div className="bodyContent">
                        {!!this.state.loading && (<Spinner />)}
                        {!this.state.location && !this.state.loading && (
                            <NotFound type="locations" class="backgroundRed" />
                        )}
                        {!!this.state.location && (
                            <React.Fragment>
                                {this.state.location.address && this.state.location.address.address_latitude && this.state.location.address.address_longitude && (
                                    <div className="profileMap">
                                        <LocationMap geoPoint={{ lat: this.state.location.address.address_latitude, lng: this.state.location.address.address_longitude }} />
                                    </div>
                                )}
                                <LocationProfileHeader
                                    userData={this.props.userData}
                                    location={this.state.location}
                                    filesChanged={this.filesChanged}
                                    changeImage={this.changeImage}
                                    removeFavorite={this.removeFavorite}
                                    checkFavorite={this.checkFavorite}
                                />
                                <div className="maxWidth side-padding location-profile">
                                    <div className="two-column-layout">
                                        <LocationProfileSidebar
                                            myLocationsToAdmin={this.state.myLocationsToAdmin}
                                            userData={this.props.userData || false}
                                            location={this.state.location}
                                            match={this.props.match}
                                            dispatch={this.props.dispatch}
                                            credentials={this.props.credentials}
                                        />
                                        <div className="column-2">
                                            <div className="locationInfo">
                                                <div className="row space-between">
                                                    {this.state.location.location_description && (
                                                        <React.Fragment>
                                                            <div>
                                                                <h2>About {this.state.location.location_name}</h2>
                                                                <div className="description" itemProp="description">
                                                                    {this.state.location.location_description.split('\n').map((item, index) => <div key={index}>{item}</div>)}
                                                                </div>
                                                            </div>
                                                            {/* <AdBox /> */}
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                                <div className="row space-between">
                                                    {this.state.location.location_schedule && timetable(this.state.location.location_schedule)}
                                                    <div className="additional-info" itemProp="description">
                                                        {additionalInfo}
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.gallery.length > 1 && (
                                                <div className="location-profile-view-gallery">
                                                    <WhiteTitle>
                                                        <div className="view-gallery">
                                                            <Link className="fontGreen" to={'/location/gallery/' + this.state.location.location_id + '/' + urlFriendly(this.state.location.location_name)}>View Gallery</Link>
                                                        </div>
                                                    </WhiteTitle>
                                                </div>
                                            )}
                                            {this.state.gallery.length > 0 && (
                                                <div className="galleryHolderLocations">
                                                    <GalleryLightbox images={this.state.gallery} count={4} size="normal" />
                                                    {this.state.gallery.length > 4 && (
                                                        <div className="holderEnclosed">
                                                            <Link to={'/location/gallery/' + this.state.location.location_id + '/' + urlFriendly(this.state.location.location_name)}>
                                                                See all {this.state.gallery.length} photos
                                                            </Link>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            <div className="addReview">
                                                <div className="row space-between">
                                                    <div>
                                                        <div className="fontGreen h1">Reviews</div>
                                                        <div className="row alingItems">
                                                            <div className="big-rating-score" itemProp="aggregateRating" itemScope itemType="http://schema.org/AggregateRating">{this.state.location.Reviewaverage}</div>
                                                            <div itemProp="ratingValue">
                                                                <div itemProp="reviewCount">Out of 5</div>
                                                                <Stars stars={this.state.location.Reviewaverage} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="h4">
                                                            Write a Review
                                                        </div>
                                                        <Link to={'/location/review/' + this.state.location.location_id + '/' + urlFriendly(this.state.location.location_name)}>
                                                            <Stars stars={0} big={true} />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="reviewList">
                                                <div className="maxWidth">
                                                    <ul>
                                                        {this.state.reviews && this.mapReviews(this.state.reviews)}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(LocationProfile);
