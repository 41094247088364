import React from 'react';
import { Link } from 'react-router-dom';

import { findFriends, sendRequest, updateRequest } from '../../redux/actions/users';
import Avatar from '../../layout/UI/Avatar';
import Spinner from '../../layout/UI/Spinner';
import Config from '../../helpers/Config';
import { getGeoLocation } from '../../helpers/GeoLocation';
import { guard } from '../../helpers/Functions';

export default class Cards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            friends: [],
            sentRequests: [],
            acceptedRequests: []
        };
    }

    getGeoLocation = getGeoLocation.bind(this)

    componentDidMount() {
        if (!Config.isMobileDevice) { window.dragscroll.reset(); }
        this.getGeoLocation(this.props, this.search, this.props.credentials.token);
    }

    search = () => {
        const filter = '&filter[friendship_status][neq]=accepted';
        this.props.dispatch(findFriends(this.props.credentials.token, this.props.userData.user_location, filter, null, 1, 50))
            .then(response => {
                this.setState({
                    loading: false,
                    friends: response.value.data
                });
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    friendRequest = (e, id) => {
        e.preventDefault();
        this.props.dispatch(sendRequest(this.props.credentials.token, id))
            .then(() => this.setState(
                prevState => ({
                    sentRequests: prevState.sentRequests.concat(id)
                })
            ));
    }

    acceptRequest = (e, id) => {
        e.preventDefault();
        this.props.dispatch(updateRequest(this.props.credentials.token, id, { friendship_status: 'accepted' }))
            .then(() => this.setState(
                prevState => ({
                    acceptedRequests: prevState.acceptedRequests.concat(id)
                })
            ));
    }

    render() {
        let cards = this.state.friends.map(user => {
            if (guard(user, '.images.0.image_smallurl')) {
                let button = null;

                if ((!!user && !!user.myfriendship && user.myfriendship.friendship_status === 'pending' && user.myfriendship.sender_id === this.props.credentials.user_id) || !!this.state.sentRequests.includes(user.user_id)) {
                    button = (
                        <div className="flexSmallestButtons blueBorder blueBG fontWhite">
                            Request Sent
                        </div>
                    );
                } else if (!!user && !!user.myfriendship && !!user.myfriendship.friendship_id && !!this.state.acceptedRequests.includes(user.myfriendship.friendship_id)) {
                    button = (
                        <div className="flexSmallestButtons greenBorder greenBG fontWhite">
                            Accepted
                        </div>
                    );
                } else if (!!user && !!user.myfriendship && user.myfriendship.friendship_status === 'pending' && user.myfriendship.sender_id !== this.props.credentials.user_id) {
                    button = (
                        <div className="flexSmallestButtons blueBorder blueBG fontWhite" onClick={e => this.acceptRequest(e, user.myfriendship.friendship_id)}>
                            Accept
                        </div>
                    );
                } else {
                    button = (
                        <div onClick={e => this.friendRequest(e, user.user_id)} className="flexSmallestButtons greenBorder whiteBG fontGreen">
                            Add Friend
                        </div>
                    );
                }

                return (
                    <React.Fragment key={user.user_id}>
                        <div className="cards">
                            <Link to={'/user/profile/' + user.user_id}>
                                <div className="square-avatar alignCenter">
                                    <Avatar src={guard(user, '.images.0.image_smallurl')} alt={user.user_firstname + ' ' + user.user_lastname} />
                                </div>
                                <div className="userName">{user.user_firstname} {user.user_lastname}</div>
                            </Link>
                            {button}
                        </div>
                    </React.Fragment>
                );
            }
            return false;
        });

        cards = cards.filter(user => !!user);

        return (
            <React.Fragment>
                {!!this.state.loading && <Spinner />}
                {!this.state.loading && cards.length > 0 && (
                    <div className="maxWidth wrapper noPadding">
                        <div className="scrollableBannersHolder">
                            <div className="sliderTitle">
                                <h5 className="fontBlack">People you may know</h5>
                            </div>
                            <div className="scrollableBanners dragscroll">
                                {cards}
                                <div className="viewMore">
                                    <Link to="/findfriends">
                                        <p className="viewmore flexSmallestButtons greenBorder greenBG fontWhite">View More</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}
