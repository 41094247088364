import React from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { fbregister } from '../../redux/actions/users';
import BasicAlert from '../../layout/UI/BasicAlert';
import sb from '../../helpers/SendBird';
import Config from '../../helpers/Config';
import { guard } from '../../helpers/Functions';
import { getDeviceToken } from '../../helpers/PushNotifications';
import ApiError from '../../helpers/ApiError';
import TopNav from './TopNav';
import FacebookLoginButton from '../../FacebookLoginButton';

export class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loggingIn: false
        };
    }

    componentDidMount() {
        if (window?.location?.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    fbLogin = e => {
        e.preventDefault();
        this.setState({ loggingIn: true });
        const that = this;
        const fbLoginSuccess = () => {
            window.facebookConnectPlugin.getAccessToken(token => {
                that.responseFacebook({ accessToken: token });
            });
        };
        window.facebookConnectPlugin.login(['public_profile', 'email'], fbLoginSuccess,
            error => {
                console.log(error);
                this.setState({ loggingIn: false });
                BasicAlert('Sorry there is an issue with the credentials provided');
            }, { auth_type: 'reauthenticate' });
    }

    responseFacebook = response => {
        this.setState({ loggingIn: true });
        if ((response && response.email && response.id) || (response.accessToken)) {
            const that = this;
            this.props.dispatch(fbregister(response.accessToken))
                .then(response => {
                    console.log(response);
                    toast.success('Welcome to Pooch!');
                    sb.connect(
                        that.props.credentials.user_id,
                        that.props.credentials.token,
                        () => {
                            sb.updateCurrentUserInfo(
                                that.props.userData.user_firstname + ' ' + that.props.userData.user_lastname,
                                guard(that.props.userData, '.images.0.image_smallurl') || '',
                                () => { }
                            );
                        }
                    );
                    getDeviceToken(that.props.dispatch, that.props.credentials);
                    that.props.history.push('/welcome');
                })
                .catch(err => {
                    ApiError(err?.response?.data?.[0]?.essage || err.message);
                });
        } else {
            this.setState({ loggingIn: false });
        }
    }

    render() {
        return (
            <div className="mainAppContainer LoginScreens Register">
                <TopNav backLink="/" />
                <div className="LoginWrapper wrapper">
                    <h1>Welcome to Pooch.</h1>
                </div>
                <div className="LoginWrapper wrapper">
                    {this.state.loggingIn && (
                        <div className="wideButtons fb-button">
                            <i className="icn icon-facebook1" /> Logging In...
                        </div>
                    )}
                    {!this.state.loggingIn && Config.isMobileApp && (
                        <Link to="/loginemail" onClick={e => this.fbLogin(e)} className="wideButtons fb-button">
                            <i className="icn icon-facebook1" /> Continue with Facebook
                        </Link>
                    )}
                    {!this.state.loggingIn && !Config.isMobileApp && (
                        <FacebookLoginButton
                            callback={this.responseFacebook}
                            textButton="Continue with Facebook"
                        />
                    )}
                    <Link to="/registeruser" className="wideButtons whiteBorder transparent fontWhite">
                        <i className="icn icon-email" /> Create an Account
                    </Link>
                    <Link to="/login" className="wideButtons whiteBorder transparent fontWhite">
                        Already have an account?
                    </Link>
                </div>
                <div className="termsConditions">
                    <div className="LoginWrapper wrapper">
                        By signing up, I agree to <Link to="/terms">Pooch's Terms and Conditions,</Link> and <Link to="/privacy">Privacy Policy</Link>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(Register);
