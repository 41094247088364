import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import { findFriends } from '../../redux/actions/users';
import { uploadPicture } from '../../redux/actions/all';
import { pc_toggleUser, createPlaydate, addPlaydateUsers, addPlaydateDogs, updatePlaydate } from '../../redux/actions/playdates';
import UserListNew from '../../layout/UserListNew';
import Spinner from '../../layout/UI/Spinner';
import { PlaydateNavigation } from '../../layout/navigation/PlaydateNavigation';
import ApiError from '../../helpers/ApiError';
import { getGeoLocation } from '../../helpers/GeoLocation';
import sb from '../../helpers/SendBird';
import WhiteTitle from '../../hoc/WhiteTitle';
import Columns from '../../hoc/Columns';

let delayTimer;

export class PlaydatesCreateFriendSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            friends: [],
            notFriends: [],
            loading1: true,
            loading2: true,
            createdPlaydate: null,
            friendsPage: 1,
            // friendsPageTotal: 1,
            loadingMoreFriends: false,
            notFriendsPage: 1,
            // notFriendsPageTotal: 1,
            loadingMoreNotFriends: false,
            loadingSubmitForm: false
        };
    }

    getGeoLocation = getGeoLocation.bind(this)

    componentDidMount() {
        window.scrollTo(0, 0);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        this.getGeoLocation(this.props, this.search, this.props.credentials.token);
    }

    search = () => {
        this.setState({
            friends: [],
            notFriends: [],
            loading1: true,
            loading2: true,
            createdPlaydate: null,
            friendsPage: 1,
            // friendsPageTotal: 1,
            loadingMoreFriends: false,
            notFriendsPage: 1,
            // notFriendsPageTotal: 1,
            loadingMoreNotFriends: false
        }, () => {
            this.loadFriends();
            this.loadNotFriends();
        });
    }

    // loadMoreFriends = () => {
    //     this.setState((prevState, props) => {
    //         return {
    //             friendsPage: prevState.friendsPage+1,
    //             loadingMoreFriends: true
    //         }
    //     }, this.loadFriends)
    // }

    // loadMoreNotFriends = () => {
    //     this.setState((prevState, props) => {
    //         return {
    //             notFriendsPage: prevState.notFriendsPage+1,
    //             loadingMoreNotFriends: true
    //         }
    //     }, this.loadNotFriends)
    // }

    loadFriends = () => {
        const filter = '&filter[friendship_status][eq]=accepted';
        this.props.dispatch(findFriends(this.props.credentials.token, this.props.userData.user_location, filter, this.state.searchText, this.state.friendsPage))
            .then(response => {
                this.setState(prevState => ({
                    friends: [...prevState.friends, ...response.value.data],
                    loading1: false,
                    // friendsPageTotal: response.value.headers['x-pagination-page-count'],
                    loadingMoreFriends: false
                }));
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loading1: false, loadingMoreFriends: false });
            });
    }

    loadNotFriends = () => {
        const filter = '&filter[friendship_status][neq]=accepted';
        this.props.dispatch(findFriends(this.props.credentials.token, this.props.userData.user_location, filter, this.state.searchText, this.state.notFriendsPage))
            .then(response => {
                this.setState(prevState => ({
                    notFriends: [...prevState.notFriends, ...response.value.data],
                    loading2: false,
                    // notFriendsPageTotal: response.value.headers['x-pagination-page-count'],
                    loadingMoreNotFriends: false
                }));
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loading2: false, loadingMoreNotFriends: false });
            });
    }

    selectUser = (e, user) => {
        e.preventDefault();
        this.props.dispatch(pc_toggleUser(user));
    }

    finishPlaydate = e => {
        e.preventDefault();
        if (!this.props.pc.selectedPark || !(this.props.pc.selectedPark.id)) {
            ApiError('You didn\'t select a Location!');
            return;
        }
        if ((!this.props.pc.selectedDogs || !(this.props.pc.selectedDogs.length > 0)) && this.props.pc.type === 'playdate') {
            ApiError('You didn\'t select one of your Dogs!');
            return;
        }
        this.setState({ loadingSubmitForm: true });
        let date;
        let time;
        if (!this.props.pc.selectedDate) {
            date = moment().format('YYYY-MM-DD');
        } else {
            date = moment(this.props.pc.selectedDate).format('YYYY-MM-DD');
        }
        if (!this.props.pc.selectedTime) {
            time = moment().format('H:mm:ss');
        } else {
            time = moment(this.props.pc.selectedTime).format('H:mm:ss');
        }

        const data = {
            location_id: this.props.pc.selectedPark.id,
            owner_id: this.props.credentials.user_id,
            playdate_datetime: date + ' ' + time,
            playdate_isactive: 1,
            playdate_comment: this.props.pc.comment,
            playdate_title: this.props.pc.title,
            playdate_additionalinfo: this.props.pc.additionalinfo,
            playdate_type: this.props.pc.type,
            playdate_isprivate: this.props.pc.invitationOnly ? 1 : 0
        };

        this.props.dispatch(createPlaydate(this.props.credentials.token, data))
            .then(response => {
                this.setState({
                    createdPlaydate: response.value.data
                }, () => {
                    this.uploadCover();
                    // this.createChatRoom()
                    this.addUsers();
                    this.setState({ loading1: true });
                    toast.success('Event Created');
                    this.props.history.push('/events/manage/event');
                });
            })
            .catch(err => {
                this.setState({ loadingSubmitForm: false }, () => {
                    this.submit.removeAttribute('disabled');
                });
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    uploadCover = () => {
        if (this.props.pc && this.props.pc.images_files && this.props.pc.images_files.size) {
            this.props.dispatch(uploadPicture(this.props.credentials.token, this.state.createdPlaydate.playdate_id, this.props.pc.images_files, true));
        }
    }

    createChatRoom = () => {
        const that = this;
        sb.connect(
            this.props.credentials.user_id,
            this.props.credentials.token,
            () => {
                sb.OpenChannel.createChannel(
                    'Playdate @ ' + that.props.pc.selectedPark.name,
                    null,
                    null,
                    createdChannel => {
                        that.props.dispatch(updatePlaydate(that.props.credentials.token, that.state.createdPlaydate.playdate_id, { playdate_chaturl: createdChannel.url }))
                            .then(() => that.addUsers());
                    }
                );
            }
        );
    }

    addUsers = () => {
        const id = this.state.createdPlaydate.playdate_id;
        const users = [];
        for (const i in this.props.pc.selectedUsers) {
            users.push({ user_id: this.props.pc.selectedUsers[i].id });
        }
        this.props.dispatch(addPlaydateUsers(this.props.credentials.token, id, users))
            .then(() => this.addOwnerDogs(id))
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    addOwnerDogs = id => {
        const dogs = [];
        for (const i in this.props.pc.selectedDogs) {
            dogs.push({ dog_id: this.props.pc.selectedDogs[i] });
        }
        this.props.dispatch(addPlaydateDogs(this.props.credentials.token, id, dogs))
            .then(() => {
                toast.success('Playdate Created');
                this.props.history.push('/events/' + id);
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    handleSearchInput = e => {
        clearTimeout(delayTimer);
        const that = this;
        this.setState({ searchText: e.target.value }, () => {
            delayTimer = setTimeout(() => {
                that.search();
                // Do the ajax stuff
            }, 1500);
        });
    }

    onClear = () => {
        clearTimeout(delayTimer);
        const that = this;
        this.setState({ searchText: '' }, () => {
            delayTimer = setTimeout(() => {
                that.search();
                // Do the ajax stuff
            }, 1500);
        });
    }

    render() {
        // let loadMoreFriendsButton = null
        // if(this.state.friendsPage < this.state.friendsPageTotal)
        //     loadMoreFriendsButton = <div className="fontGreen wrapper alignRight" onClick={this.loadMoreFriends}>Load More Friends</div>

        // let loadMoreNotFriendsButton = null
        // if(this.state.notFriendsPage < this.state.notFriendsPageTotal)
        //     loadMoreNotFriendsButton = <div className="fontGreen wrapper alignRight" onClick={this.loadMoreNotFriends}>Load More Users</div>
        const c1 = (
            <div className="simple-navigation">
                <PlaydateNavigation
                    selected="create"
                />
            </div>
        );

        const c2 = (
            <React.Fragment>
                {this.state.loadingSubmitForm
                    ? (<Spinner />)
                    : (
                        <div className="create-playdate">
                            <Link to="/events" onClick={this.finishPlaydate} className="wideButtons greenBorder greenBG fontWhite">Create Playdate</Link>
                        </div>
                    )}
                <div className="bodyContent noPaddingTop">
                    {!this.state.loadingSubmitForm && (
                        <div className="maxWidth">
                            <div className="invite-friends">
                                <WhiteTitle>Invite Friends</WhiteTitle>
                                {this.state.loading1 && (
                                    <div>
                                        <Spinner />
                                        <div className="spacer" />
                                    </div>
                                )}
                                <UserListNew users={this.state.friends} markSelected={true} selectedUsers={this.props.pc.selectedUsers} onClick={this.selectUser} />
                                {!!this.state.loadingMoreFriends && (
                                    <div>
                                        <Spinner />
                                        <div className="spacer" />
                                    </div>
                                )}
                            </div>
                            <div className="spacerSmall" />
                            <div className="invite-others">
                                <WhiteTitle>Invite Other Friends</WhiteTitle>
                                {this.state.loading2 && (
                                    <div>
                                        <Spinner />
                                        <div className="spacer" />
                                    </div>
                                )}
                                <UserListNew users={this.state.notFriends} markSelected={true} selectedUsers={this.props.pc.selectedUsers} onClick={this.selectUser} />
                                {!!this.state.loadingMoreNotFriends && (
                                    <div>
                                        <Spinner />
                                        <div className="spacer" />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment>
        );

        const c3 = (<React.Fragment />);

        return (
            <div className="mainAppContainer playdates">
                <div className="bodyContent playdates-invite-friends">
                    <div className="simpleSearchBar row">
                        <i className="icn icon-search fontDarkGray" />
                        <input onChange={e => this.handleSearchInput(e)} value={this.state.searchText} placeholder="Search..." />
                        {!!this.state.searchText && (
                            <i onClick={this.onClear} className="icn icon-sign-out fontDarkGray" />
                        )}
                    </div>
                    <div className="three-column-layout">
                        <Columns
                            c1={c1}
                            c2={c2}
                            c3={c3}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        pc: state.playdates.create
    };
}

export default connect(mapStateToProps)(PlaydatesCreateFriendSelection);
