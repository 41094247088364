import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const FriendsNavigation = ({ showTitle, credentials, showFindFriends, selected }) => (
    <React.Fragment>
        {showTitle && (
            <div className="simple-title">
                <i className="icn icon-couple fontBlue" /> <b className="fontBlack">Pack</b>
            </div>
        )}
        {(credentials?.token || showFindFriends) && (
            <div className="tabSubmenu">
                <div className="navigationTabs">
                    <div className="maxWidth row">
                        {credentials?.token && (
                            <Link to="/friends" className={(selected === 'friends' ? 'active' : '')}>
                                Your Friends
                            </Link>
                        )}
                        {showFindFriends && (
                            <Link to="/findfriends" className={(selected === 'findfriends' ? 'active' : '')}>
                                Find Friends
                            </Link>
                        )}
                        {credentials?.token && (
                            <Link to="/friendrequest" className={(selected === 'friendrequest' ? 'active' : '')}>
                                Requests
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        )}
    </React.Fragment>
);

FriendsNavigation.propTypes = {
    selected: PropTypes.string,
    showFindFriends: PropTypes.bool,
    showTitle: PropTypes.bool
};

function mapStateToProps(state) {
    return {
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(FriendsNavigation);
