import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';

import { sendRequest } from '../../redux/actions/users';
import { updateMissingDog } from '../../redux/actions/dogs';
import sb from '../../helpers/SendBird';
import ApiError from '../../helpers/ApiError';
import BasicConfirm from './BasicConfirm';

class DogProfileButtons extends React.Component {
    friendRequest = (e, users) => {
        e.preventDefault();
        for (const i in users) {
            this.props.dispatch(sendRequest(this.props.credentials.token, users[i].user_id));
        }
    }

    chatWithUser = (e, users, lostDog) => {
        e.preventDefault();
        const userIds = [];
        let roomName = this.props.userData.user_firstname;

        for (const i in users) {
            userIds.push(users[i].user_id);
            roomName += ', ' + users[i].user_firstname;
        }

        if (lostDog) {
            roomName = 'Dog Missing: ' + this.props.dogProfile.name;
        }

        const that = this;
        sb.connect(
            this.props.credentials.user_id,
            this.props.credentials.token,
            () => {
                sb.GroupChannel.createChannelWithUserIds(userIds, true, roomName, null, null, (createdChannel, error) => {
                    if (error) {
                        toast.error('Sorry, there was an issue creating this chat, please try again.');
                        return;
                    }
                    that.props.history.push('/chat/channel/' + createdChannel.url);
                });
            }
        );
    }

    foundDog = e => {
        e.preventDefault();
        BasicConfirm(
            'Did you find ' + this.props.dogProfile.dog_name + '?',
            false,
            'Yes, Mark as Found.',
            this.handleClickFound
        );
    }

    handleClickFound = () => {
        const data = { dogaddress_found: moment().format('YYYY-MM-DD') };
        this.props.dispatch(updateMissingDog(this.props.credentials.token, this.props.dogProfile.dogaddress.dogaddress_id, data))
            .then(() => {
                toast.success('Profile Updated! Glad to hear you found your pup!');
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    render() {
        let friendshipButton = null;
        const follow = null;
        let messageButton = null;
        let editButton = null;
        let reportMissing = null;
        const dog_is_lost = !!this.props.dogProfile && !!this.props.dogProfile.dogaddress && !this.props.dogProfile.dogaddress.dogaddress_found;

        if (this.props.isMyDog) {
            editButton = (
                <Link className="fontBlue" to={'/dog/' + this.props.dogProfile.dog_id + '/edit'}>Edit My Dog</Link>
            );
            // follow = <Link className="fontBlue" to="#">Follow Dog</Link>
            if (dog_is_lost) {
                reportMissing = (
                    <Link onClick={this.foundDog} className="fontRed" to="/reportmissing">Mark As Found</Link>
                );
            } else {
                reportMissing = (
                    <Link className="fontBlue" to="/reportmissing">Report Missing Dog</Link>
                );
            }
        } else {
            friendshipButton = (
                <Link to="#" className="fontBlue" onClick={e => this.friendRequest(e, this.props.dogProfile.users)}><i className="icn icon-couple" />Befriend Owner(s)</Link>
            );
            // follow = <Link className="fontBlue" to="#">Follow Me</Link>
            messageButton = (
                <Link to="#" className="fontBlue" onClick={e => this.chatWithUser(e, this.props.dogProfile.users, false)}><i className="icn icon-send-message-plane" />Message Owner(s)</Link>
            );
        }

        return (
            <div className="tabSubmenu">
                <div className="maxWidth noPadding row">
                    <div className="inlineMultiButtons row maxWidth">
                        {editButton}
                        {follow}
                        {reportMissing}
                        {friendshipButton}
                        {messageButton}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(DogProfileButtons);
