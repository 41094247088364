import React from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { register } from '../../redux/actions/users';
import { guard } from '../../helpers/Functions';
import sb from '../../helpers/SendBird';
import { getDeviceToken } from '../../helpers/PushNotifications';
import ApiError from '../../helpers/ApiError';
import TopNav from './TopNav';

class RegisterPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            passwordConfirm: null
        };
    }

    componentDidMount() {
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    verifyData = e => {
        e.preventDefault();
        if (this.state.password && this.state.password.length >= 8 && this.state.password === this.state.passwordConfirm) {
            const that = this;
            this.props.dispatch(register(this.props.userData.user_firstname, this.props.userData.user_lastname, this.props.userData.user_email, this.state.password))
                .then(response => {
                    console.log(response);
                    toast.success('Welcome to Pooch!');
                    sb.connect(
                        that.props.credentials.user_id,
                        that.props.credentials.token,
                        () => {
                            sb.updateCurrentUserInfo(
                                that.props.userData.user_firstname + ' ' + that.props.userData.user_lastname,
                                guard(that.props.userData, '.images.0.image_smallurl') || '',
                                () => {

                                }
                            );
                        }
                    );
                    getDeviceToken(that.props.dispatch, that.props.credentials);
                    that.props.history.push('/welcome');
                })
                .catch(err => {
                    let errorMsg = 'There was an unexpected error';
                    if (!!err && !!err.response && !!err.response.data && !!err.response.data.message) {
                        const messages = JSON.parse(err.response.data.message);
                        if (messages && messages.user_email && messages.user_email[0]) {
                            errorMsg = messages.user_email[0];
                        }
                    }
                    ApiError(errorMsg);
                });
        } else if (this.state.password !== this.state.passwordConfirm) {
            ApiError('Passwords do not match!');
        } else {
            ApiError('Please write your password, it must be at least 8 characters long');
        }
    }

    render() {
        return (
            <div className="mainAppContainer LoginScreens Register">
                <TopNav backLink="/registeremail" />
                <form onSubmit={this.verifyData}>
                    <div className="LoginWrapper wrapper">
                        <h1>Create a password.</h1>
                        <div className="fontWhite">Your password must contain at least eight characters and one symbol.</div>
                    </div>
                    <div className="LoginWrapper wrapper maxWidth">
                        <div className="innerLabelInput">
                            <span className="blocking-span">
                                <input className="inputText" id="password" name="password" type="password" minLength={8} onChange={e => this.handleChange(e)} required />
                                <span className="floating-label">Password</span>
                                {this.state.password && this.state.passwordConfirm && this.state.password === this.state.passwordConfirm && (
                                    <i className="icn icon-check-mark fontWhite" />
                                )}
                            </span>
                        </div>
                        <div className="innerLabelInput">
                            <span className="blocking-span">
                                <input className="inputText" id="passwordConfirm" name="passwordConfirm" type="password" minLength={8} onChange={e => this.handleChange(e)} required />
                                <span className="floating-label">Confirm Password</span>
                                {this.state.password && this.state.passwordConfirm && this.state.password === this.state.passwordConfirm && (
                                    <i className="icn icon-check-mark fontWhite" />
                                )}
                            </span>
                        </div>
                        <input type="submit" value="Create Account &gt;" className="rightRoundButton whiteBorder whiteOpaque fontWhite" />
                        <Link to="/login" className="rightRoundButton transparentBorder transparent fontWhite">
                            Already have an account?
                        </Link>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(RegisterPassword);
