import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import { getLocation } from '../../redux/actions/locations';
import { deletePicture, setPicture } from '../../redux/actions/all';
import GalleryLightbox from '../../layout/gallery/GalleryLightbox';
import NotFound from '../../layout/NotFound';
import LocationMap from '../../layout/locations/LocationMap';
import ApiError from '../../helpers/ApiError';
import { urlFriendly } from '../../helpers/Functions';
import WhiteTitle from '../../hoc/WhiteTitle';
import LocationProfileHeader from './LocationProfileHeader';
import LocationProfileSidebar from './LocationProfileSidebar';

class LocationGallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            location: {},
            loading: true,
            // image: null,
            gallery: [],
            myLocationsToAdmin: []
        };
    }

    componentDidMount() {
        if (window?.location?.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        this.start();
        this.props.dispatch(getLocation(this.props.credentials.token, this.props.match.params.id))
            .then(response => {
                this.setState({ location: response.value.data, loading: false, gallery: response.value.data.images });
            })
            .catch(err => {
                ApiError(err.response?.data?.[0]?.message || err.message);
                this.setState({ loading: false });
            });
    }

    start = () => {
        if (this.props.userData?.adminlocations) {
            const adminLocations = this.props.userData.adminlocations.map(location => String(location.location_id));
            this.setState({ myLocationsToAdmin: adminLocations });
        }
        this.props.dispatch(getLocation(this.props.credentials.token, this.props.match.params.id))
            .then(response => {
                this.setState({ location: response.value.data, loading: false, gallery: response.value.data.images });
            })
            .catch(err => {
                ApiError(err.response?.data?.[0]?.message || err.message);
                this.setState({ loading: false });
            });
    }

    handleClickRemoveImage = id => {
        this.props.dispatch(deletePicture(this.props.credentials.token, id))
            .then(() => {
                this.setState(prevState => {
                    const newGallery = prevState.gallery.filter(image => image.image_id !== id);
                    return {
                        gallery: newGallery
                    };
                });
                toast.success('Deleted Image Successfully');
            })
            .catch(err => {
                ApiError(err.response?.data?.[0]?.message || err.message);
            });
    }

    handleClickSetImage = id => {
        this.props.dispatch(setPicture(this.props.credentials.token, id))
            .then(() => {
                // this.setState({ redirect: true });
                toast.success('Updated Profile Successfully');
            })
            .catch(err => {
                ApiError(err.response?.data?.[0]?.message || err.message);
            });
    }

    goBack(e) {
        e.preventDefault();
        this.props.history.goBack();
    }

    render() {
        let gallery = null;
        let notFound = null;
        console.log('this.state?.location:', this.state?.location);
        const link = (this.state?.location?.location_name && this.state?.location?.location_id) ? ('/location/profile/' + this.state.location.location_id + '/' + urlFriendly(this.state.location.location_name)) : '#';

        if (this.state?.location?.images?.length > 0 && !this.state.loading) {
            gallery = (
                <GalleryLightbox
                    images={this.state.gallery}
                    userData={this.props.userData}
                    count="all"
                    size="normal"
                    handleClickSetImage={this.handleClickSetImage}
                    handleClickRemoveImage={this.handleClickRemoveImage}
                />
            );
        } else if (!this.state.loading) {
            notFound = (<NotFound type="emptyGallery" />);
        }

        return (
            <div className="mainAppContainer">
                <div className="bodyContent locations-gallery">
                    {this.state?.location?.address?.address_latitude && this.state?.location?.address?.address_longitude && (
                        <div className="profileMap">
                            <LocationMap
                                geoPoint={{
                                    lat: this.state.location.address.address_latitude,
                                    lng: this.state.location.address.address_longitude
                                }}
                            />
                        </div>
                    )}
                    <LocationProfileHeader
                        userData={this.props.userData}
                        location={this.state.location}
                        filesChanged={null}
                        changeImage={null}
                        removeFavorite={null}
                        checkFavorite={null}
                    />
                    <div className="maxWidth side-padding">
                        <div className="two-column-layout">
                            <LocationProfileSidebar
                                myLocationsToAdmin={this.state.myLocationsToAdmin}
                                userData={this.props.userData || false}
                                location={this.state.location}
                                match={this.props.match}
                            />
                            <div className="column-2">
                                <div className="locationInfo">
                                    <WhiteTitle>
                                        <Link className="fontGreen" to={link}>View Full Profile</Link>
                                    </WhiteTitle>
                                    {gallery && (
                                        <div className="galleryHolderLocations">
                                            {gallery}
                                        </div>
                                    )}
                                    {notFound}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(LocationGallery);
