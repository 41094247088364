import React from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { setPicture, deletePicture } from '../../redux/actions/all';
import { getDogProfile } from '../../redux/actions/dogs';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import GalleryLightbox from '../../layout/gallery/GalleryLightbox';
import Spinner from '../../layout/UI/Spinner';
import NotFound from '../../layout/NotFound';
import ApiError from '../../helpers/ApiError';

class DogGallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            loading: true,
            gallery: []
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(getDogProfile(this.props.credentials.token, this.props.match.params.id))
            .then(response => {
                this.setState({
                    loading: false,
                    gallery: response.value.data.images
                });
            })
            .catch(err => {
                this.setState({
                    loading: false
                });
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    // Removes image from GalleryLightBox
    handleClickRemoveImage = id => {
        this.props.dispatch(deletePicture(this.props.credentials.token, id))
            .then(res => {
                console.log(res);
                this.setState(prevState => {
                    const newGallery = prevState.gallery.filter(image => image.image_id !== id);
                    return {
                        gallery: newGallery
                    };
                });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    handleClickSetImage = id => {
        this.props.dispatch(setPicture(this.props.credentials.token, id))
            .then(() => {
                let newFeaturedImage = {};
                // we push the new featured image to the top of the list, on the first position
                this.setState(prevState => {
                    const newGallery = prevState.gallery.filter(image => {
                        if (image.image_id === id) newFeaturedImage = image;
                        return image.image_id !== id;
                    });
                    newGallery.sort((a, b) => b.image_id - a.image_id);
                    return {
                        gallery: [newFeaturedImage, ...newGallery]
                    };
                });
                toast.success('Profile Image Set Successfully!');
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    render() {
        let ownDogImages = null;
        if (this.state.gallery && this.state.gallery.length > 0 && !this.state.loading) {
            ownDogImages = (
                <div className="locationProfile locationGallery maxWidth">
                    <GalleryLightbox
                        images={this.state.gallery}
                        dogProfile={this.props.dogProfile}
                        userData={this.props.userData}
                        count="all"
                        size="normal"
                        handleClickSetImage={this.handleClickSetImage}
                        handleClickRemoveImage={this.handleClickRemoveImage}
                    />
                </div>
            );
        }
        if (this.state.gallery && this.state.gallery.length === 0 && !this.state.loading) {
            ownDogImages = (<NotFound dog_id={this.props.match.params.id} type="dogimages" class="backgroundRed" />);
        }
        return (
            <div className="mainAppContainer">
                <MainNavigation {...this.props} title={this.props.dogProfile && this.props.dogProfile.dog_name} leftButton="back-gallery" leftLink="/dogs" />
                {this.state.redirect && (
                    <Redirect to={'/dog/' + this.props.match.params.id + '/edit'} />
                )}
                <div className="bodyContent">
                    {this.state.loading && (<Spinner />)}
                    {ownDogImages}
                </div>
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        dogProfile: state.dogs.dogProfile
    };
}

export default connect(mapStateToProps)(DogGallery);
