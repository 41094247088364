import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';

import { getPlaydate, reviewPlaydateUser } from '../../redux/actions/playdates';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import Avatar from '../../layout/UI/Avatar';
import Spinner from '../../layout/UI/Spinner';
import NotFound from '../../layout/NotFound';
import { Stars } from '../../layout/UI/Stars';
import { guard } from '../../helpers/Functions';
import ApiError from '../../helpers/ApiError';

export class PlaydatesRateUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            playdateParticipants: [],
            ratingIndex: 0,
            stars: 0,
            showUp: null,
            onTime: null,
            meetAgain: null,
            comment: '',
            loading: true
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(getPlaydate(this.props.credentials.token, this.props.match.params.id))
            .then(response => {
                const participants = [];
                const ratedParticipants = [];
                const data = response.value.data;
                // get all the reviewed users, we will use this to compare and make sure they havent been rated yet
                for (const j in data.reviews) {
                    if (data.reviews[j].user_id === this.props.credentials.user_id && !!data.reviews[j].playdateuser && !!data.reviews[j].playdateuser.user_id) {
                        ratedParticipants.push(data.reviews[j].playdateuser.user_id);
                    }
                }
                // check who is the organizer and make sure I am not the organizer
                if (data.owner_id !== this.props.credentials.user_id && !ratedParticipants.indexOf(data.owner_id) !== -1) {
                    const ownerdata = {
                        id: data.playdate_id,
                        name: data.owner.user_firstname + ' ' + data.owner.user_lastname,
                        image: guard(data, '.owner.images.0.image_smallurl') || null
                    };
                    participants.push(ownerdata);
                }
                for (const i in data.users) {
                    if (data.users[i].user_id !== this.props.credentials.user_id && !ratedParticipants.indexOf(data.users[i].user_id) !== -1) {
                        const userdata = {
                            id: data.users[i].playdateuser_id,
                            name: data.users[i].user.user_firstname + ' ' + data.users[i].user.user_lastname,
                            image: guard(data, '.users.' + i + '.user.images.0.image_smallurl') || null
                        };
                        participants.push(userdata);
                    }
                }
                this.setState({ playdateParticipants: participants, loading: false });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loading: false });
            });
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    changeStars = stars => {
        this.setState({ stars });
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    toggleStatus(e, state, val) {
        e.preventDefault();
        this.setState({
            [state]: val
        });
    }

    skip(e) {
        e.preventDefault();
        window.scrollTo(0, 0);

        if (this.state.ratingIndex < this.state.playdateParticipants.length - 1) {
            this.setState(prevState => ({
                ratingIndex: prevState.ratingIndex + 1,
                stars: 0,
                showUp: null,
                onTime: null,
                meetAgain: null,
                comment: '',
                loading: false
            }));
        } else {
            toast.success('Success!');
            this.props.history.push('/events/manage/event');
        }
    }

    submitFeedback(e) {
        e.preventDefault();
        window.scrollTo(0, 0);

        if (
            this.state.showUp === null
            || this.state.onTime === null
            || this.state.meetAgain === null
        ) {
            ApiError('Please answer all the questions');
            return;
        }
        if (this.state.stars === 0) {
            ApiError('Please Rate the user using the 5 dog system');
            return;
        }

        this.setState({ loading: true });

        const id = this.state.playdateParticipants[this.state.ratingIndex].id;
        const data = {
            review_rating: this.state.stars,
            review_comment: this.state.comment,
            review_morefields: {
                didShowUp: this.state.showUp,
                wasOnTime: this.state.onTime,
                wouldYouMeetAgain: this.state.meetAgain
            }
        };

        this.props.dispatch(reviewPlaydateUser(this.props.credentials.token, id, data));

        if (this.state.ratingIndex < this.state.playdateParticipants.length - 1) {
            this.setState(prevState => ({
                ratingIndex: prevState.ratingIndex + 1,
                stars: 0,
                showUp: null,
                onTime: null,
                meetAgain: null,
                comment: '',
                loading: false
            }));
        } else {
            toast.success('Success!');
            this.props.history.push('/events/manage/event');
        }
    }

    render() {
        return (
            <div className="mainAppContainer">
                <MainNavigation {...this.props} title="Event Feedback" leftButton="back" />
                <div className="bodyContent">
                    {this.state.loading === false && this.state.playdateParticipants.length === 0 && (
                        <NotFound type="emptyPlaydateRating" />
                    )}
                    {!!this.state.loading && (
                        <Spinner />
                    )}
                    {this.state.playdateParticipants && this.state.playdateParticipants[this.state.ratingIndex] && !this.state.loading && (
                        <div className="locationProfile maxWidth">
                            <div className="wrapper">
                                <div className="row checkInTitle">
                                    <div className="round-avatar user">
                                        <Avatar
                                            src={guard(this.state.playdateParticipants, '.' + this.state.ratingIndex + '.image')}
                                            alt={this.state.playdateParticipants[this.state.ratingIndex].name}
                                            type="user"
                                        />
                                    </div>
                                    <div>How was your playdate with {this.state.playdateParticipants[this.state.ratingIndex].name}?</div>
                                </div>
                                <div className="question">
                                    Did {this.state.playdateParticipants[this.state.ratingIndex].name} show up?
                                    <div className="row">
                                        <Link to="#chat" onClick={e => this.toggleStatus(e, 'showUp', false)} className={'flexSmallButtons ' + (this.state.showUp === false ? 'redBorder redBG fontWhite' : 'blueBorder whiteBG fontBlue')}>
                                            No
                                        </Link>
                                        <Link to="#chat" onClick={e => this.toggleStatus(e, 'showUp', true)} className={'flexSmallButtons ' + (this.state.showUp === true ? 'greenBorder greenBG fontWhite' : 'blueBorder whiteBG fontBlue')}>
                                            Yes
                                        </Link>
                                    </div>
                                </div>
                                <div className="question">
                                    Was {this.state.playdateParticipants[this.state.ratingIndex].name} on time?
                                    <div className="row">
                                        <Link to="#chat" onClick={e => this.toggleStatus(e, 'onTime', false)} className={'flexSmallButtons ' + (this.state.onTime === false ? 'redBorder redBG fontWhite' : 'blueBorder whiteBG fontBlue')}>
                                            No
                                        </Link>
                                        <Link to="#chat" onClick={e => this.toggleStatus(e, 'onTime', true)} className={'flexSmallButtons ' + (this.state.onTime === true ? 'greenBorder greenBG fontWhite' : 'blueBorder whiteBG fontBlue')}>
                                            Yes
                                        </Link>
                                    </div>
                                </div>
                                <div className="question">
                                    Would you meet with {this.state.playdateParticipants[this.state.ratingIndex].name} again?
                                    <div className="row">
                                        <Link to="#chat" onClick={e => this.toggleStatus(e, 'meetAgain', false)} className={'flexSmallButtons ' + (this.state.meetAgain === false ? 'redBorder redBG fontWhite' : 'blueBorder whiteBG fontBlue')}>
                                            No
                                        </Link>
                                        <Link to="#chat" onClick={e => this.toggleStatus(e, 'meetAgain', true)} className={'flexSmallButtons ' + (this.state.meetAgain === true ? 'greenBorder greenBG fontWhite' : 'blueBorder whiteBG fontBlue')}>
                                            Yes
                                        </Link>
                                    </div>
                                </div>
                                <div className="addRating">
                                    Rating
                                    <Stars stars={this.state.stars} type="dogs" big={true} onClick={this.changeStars} />
                                </div>
                                <textarea name="comment" value={this.state.comment} onChange={this.handleChange} className="niceTextarea" placeholder="Add a comment (e.g. This persons dogs are very well trained and well behaved)" />
                                <div className="row alignItems">
                                    <Link to="#" onClick={e => this.skip(e)} className="flexButtons blueBorder transparentBG fontBlue">Skip</Link>
                                    <Link to="#" onClick={e => this.submitFeedback(e)} className="flexButtons greenBorder greenBG fontWhite">Submit Feedback</Link>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <SecondaryNavigation selected="playdates" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(PlaydatesRateUser);
