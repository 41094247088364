import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactGA from 'react-ga';

import {
    setMissingDogContactName,
    setMissingDogContactPhone,
    setMissingDogContactEmail,
    setMissingDogContactByEmail,
    setMissingDogContactByPhone,
    setMissingDogContactByText,
    reportMissingDog,
    clearMissingDogs
} from '../../redux/actions/dogs';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import ApiError from '../../helpers/ApiError';
import { guard } from '../../helpers/Functions';

class ReportMissingDogsConfirm extends React.Component {
    constructor(props) {
        super(props);
        if (!!this.props.missingDogs && !this.props.missingDogs.contactName) {
            this.props.dispatch(setMissingDogContactName(this.props.userData.user_firstname + ' ' + this.props.userData.user_lastname));
        }
        if (!!this.props.missingDogs && !this.props.missingDogs.contactPhone) {
            this.props.dispatch(setMissingDogContactPhone(this.props.userData.user_phone || ''));
        }
        if (!!this.props.missingDogs && !this.props.missingDogs.contactEmail) {
            this.props.dispatch(setMissingDogContactEmail(this.props.userData.user_email));
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    verifyData = e => {
        e.preventDefault();
        if (!this.validateCheckbox()) {
            ApiError('Please select at least one method of contact.');
            return;
        }
        if (!this.props.missingDogs.contactName) {
            ApiError('Please write your name to contact you');
            return;
        }
        if (!this.props.missingDogs.contactPhone) {
            ApiError('Please provide your phone number to contact you');
            return;
        }
        if (!this.props.missingDogs.city || !this.props.missingDogs.street1 || !this.props.missingDogs.country) {
            ApiError('There is an issue with your address, please verify');
            return;
        }
        if (!this.props.missingDogs.selectedDogs || !this.props.missingDogs.selectedDogs.length) {
            ApiError('There is an issue with your selected dogs, please verify');
            return;
        }
        const missingInformation = {};
        if (this.props.missingDogs.reward) {
            missingInformation.dogaddress_reward = this.props.missingDogs.reward;
        }
        if (this.props.missingDogs.additional) {
            missingInformation.dogaddress_description = this.props.missingDogs.additional;
        }
        let date;
        let time;
        if (!this.props.missingDogs.date) {
            date = moment().format('YYYY-MM-DD');
        } else {
            date = moment(this.props.missingDogs.date).format('YYYY-MM-DD');
        }
        if (!this.props.missingDogs.time) {
            time = moment().format('H:mm:ss');
        } else {
            time = moment(this.props.missingDogs.time).format('H:mm:ss');
        }
        missingInformation.dogaddress_datetime = date + ' ' + time;
        if (this.props.missingDogs.street1) {
            missingInformation.address = {};
            missingInformation.address.address_street = this.props.missingDogs.street1;
        }
        if (this.props.missingDogs.street2) {
            missingInformation.address.address_street2 = this.props.missingDogs.street2;
        }
        if (this.props.missingDogs.city) {
            missingInformation.address.address_city = this.props.missingDogs.city;
        }
        if (this.props.missingDogs.state) {
            missingInformation.address.address_state = this.props.missingDogs.state;
        }
        if (this.props.missingDogs.country) {
            missingInformation.address.address_country = this.props.missingDogs.country;
        }
        if (this.props.missingDogs.postal) {
            missingInformation.address.address_zipcode = this.props.missingDogs.postal;
        }
        missingInformation.dogaddress_contactinfo = {};
        if (this.props.missingDogs.contactName) {
            missingInformation.dogaddress_contactinfo.person = this.props.missingDogs.contactName;
        }
        if (this.props.missingDogs.contactPhone) {
            missingInformation.dogaddress_contactinfo.phone = this.props.missingDogs.contactPhone;
        }
        if (this.props.missingDogs.contactEmail) {
            missingInformation.dogaddress_contactinfo.email = this.props.missingDogs.contactEmail;
        }
        if (this.props.missingDogs.contactByText) {
            missingInformation.dogaddress_contactinfo.contact_by_text = this.props.missingDogs.contactByText;
        }
        if (this.props.missingDogs.contactByPhone) {
            missingInformation.dogaddress_contactinfo.contact_by_phone = this.props.missingDogs.contactByPhone;
        }
        if (this.props.missingDogs.contactByEmail) {
            missingInformation.dogaddress_contactinfo.contact_by_email = this.props.missingDogs.contactByEmail;
        }
        missingInformation.dogaddress_contactinfo = missingInformation.dogaddress_contactinfo;
        this.dispatchMissing(0, this.props.missingDogs.selectedDogs, missingInformation);
    }

    validateCheckbox = () => {
        const checkboxes = document.getElementsByName('selectMethod');
        let selected = false;
        for (let i = 0, l = checkboxes.length; i < l; i++) {
            if (checkboxes[i].checked) {
                selected = true;
                break;
            }
        }
        if (!selected) {
            return false;
        }
        return true;
    }

    // This is a recursive function it creates a report for each selected dogs
    dispatchMissing(i, selectedDogs, missingInformation) {
        this.props.dispatch(reportMissingDog(this.props.credentials.token, selectedDogs[i], missingInformation))
            .then(() => {
                i++;
                if (i === selectedDogs.length) {
                    this.props.dispatch(clearMissingDogs());
                    ApiError('The report has been created successfully');
                    this.props.history.push('/missingdogs');
                } else {
                    this.dispatchMissing(i, this.props.missingDogs.selectedDogs, missingInformation);
                }
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    render() {
        return (
            <div className="mainAppContainer">
                <MainNavigation {...this.props} title="Confirm" leftButton="back" />
                <div className="bodyContent backgroundRed wrapper">
                    <form onSubmit={this.verifyData}>
                        <div className="maxWidth">
                            <h1 className="section fontWhite">Please verify the following data.</h1>
                        </div>
                        <div className="maxWidth">
                            <p className="fontWhite">Contact Information</p>
                            <div className="innerLabelInput">
                                <span className="blocking-span">
                                    <input className="inputText" name="contactName" type="text" onChange={e => this.props.dispatch(setMissingDogContactName(e.target.value))} value={guard(this.props.missingDogs, '.contactName') || ''} />
                                    <span className="floating-label">Contact Name</span>
                                </span>
                            </div>
                            <div className="innerLabelInput">
                                <span className="blocking-span">
                                    <input className="inputText" name="contactPhone" type="text" onChange={e => this.props.dispatch(setMissingDogContactPhone(e.target.value))} value={guard(this.props.missingDogs, '.contactPhone') || ''} />
                                    <span className="floating-label">Contact Phone Number</span>
                                </span>
                            </div>
                            <div className="innerLabelInput">
                                <span className="blocking-span">
                                    <input className="inputText" name="contactEmail" type="text" onChange={e => this.props.dispatch(setMissingDogContactEmail(e.target.value))} value={guard(this.props.missingDogs, '.contactEmail') || ''} />
                                    <span className="floating-label">Contact Email</span>
                                </span>
                            </div>
                            <div className="fontWhite">Please contact me by: </div>
                            <div className="checkmarks">
                                <p className="row middle">
                                    <label className="checkmarkContainer inlineright h6">
                                        <input type="checkbox" name="selectMethod" checked={!!guard(this.props.missingDogs, '.contactByPhone')} onChange={() => this.props.dispatch(setMissingDogContactByPhone())} />
                                        <span className="checkmark small" />
                                    </label>
                                    <span onClick={() => this.props.dispatch(setMissingDogContactByPhone())} className="fontWhite h5">Phone Call</span>
                                </p>
                                <p className="row middle">
                                    <label className="checkmarkContainer inlineright h6">
                                        <input type="checkbox" name="selectMethod" checked={!!guard(this.props.missingDogs, '.contactByText')} onChange={() => this.props.dispatch(setMissingDogContactByText())} />
                                        <span className="checkmark small" />
                                    </label>
                                    <span onClick={() => this.props.dispatch(setMissingDogContactByText())} className="fontWhite h5">Text Message</span>
                                </p>
                                <p className="row">
                                    <label className="checkmarkContainer inlineright h6">
                                        {this.props.missingDogs && this.props.missingDogs.contactByEmail}
                                        <input type="checkbox" name="selectMethod" checked={!!guard(this.props.missingDogs, '.contactByEmail')} onChange={() => this.props.dispatch(setMissingDogContactByEmail())} />
                                        <span className="checkmark small" />
                                    </label>
                                    <span onClick={() => this.props.dispatch(setMissingDogContactByEmail())} className="fontWhite h5">Email</span>
                                </p>
                            </div>
                            <div className="spacerSmall" />
                            <input type="submit" value="Report Lost Dog &gt;" className="rightRoundButton whiteBorder whiteOpaque fontWhite" />
                        </div>
                    </form>
                </div>
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        missingDogs: state.dogs.reportMissing
    };
}

export default connect(mapStateToProps)(ReportMissingDogsConfirm);
