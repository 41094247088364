import React from 'react';
import moment from 'moment';
import momentTz from 'moment-timezone';

export function listDogBreeds(breeds) {
    const breedList = breeds.map(breed => breed.breed_name);
    if (breedList.length > 1) {
        return breedList.join(', ') + ' mix';
    }
    return breedList.join(', ');
}

// this one converts the db timestamp into a real timezone time, then formats it using eventDateTime function below
// sometimes the user saves the dateTime correctly in their timezone, thats why this formateDateTime is not always used
export function formatDateTime(date) {
    date = momentTz.tz(date, 'Etc/GMT').format();
    return eventDateTime(date);
}

/**
 * Return the date with a short format
 *
 * @param {*} date
 * @returns {string} ate with a short format, example "Aug 30, 2021 "
 */
export function formatShortDate(date) {
    date = momentTz.tz(date, 'Etc/GMT').format();
    return moment(date).format('MMM D[,] YYYY');
}

// this function is used for whenever a user saves the dateTime in their own timezone,
// like playdates, the user will insert the time in LOCAL time, not GMT time.
// For this reason we are not converting it again to a timezone.
export function eventDateTime(date) {
    return moment(date).isSame(moment(), 'day')
        ? moment(date).format('[Today] dddd [@] h:mm a')
        : moment(date).isSame(moment(new Date()).add(1, 'days'), 'day')
            ? moment(date).format('[Tomorrow] dddd [@] h:mm a')
            : moment(date).isSame(moment(new Date()).add(-11, 'days'), 'day')
                ? moment(date).format('[Yesterday] dddd [@] h:mm a')
                : moment(date).format('dddd MMMM Do YYYY [@] h:mm a');
}

export function formatMonth(index) {
    if (index === 0) return 'Jan';
    if (index === 1) return 'Feb';
    if (index === 2) return 'Mar';
    if (index === 3) return 'Apr';
    if (index === 4) return 'May';
    if (index === 5) return 'Jun';
    if (index === 6) return 'Jul';
    if (index === 7) return 'Aug';
    if (index === 8) return 'Sep';
    if (index === 9) return 'Oct';
    if (index === 10) return 'Nov';
    if (index === 11) return 'Dec';
}

export function formatDay(index, day) {
    if (day) {
        const formatDate = moment(day).format('YYYY/MM') + '/' + index;
        const currentDay = moment(formatDate, 'YYYY/MM/D')._d;
        return moment(currentDay).format('ddd D');
    }
}

export function timetable(time) {
    let table = null;
    let row = [];
    if (time.hasOwnProperty('monday')) row.push(timetablerow(time['monday'], 'Monday'));
    if (time.hasOwnProperty('tuesday')) row.push(timetablerow(time['tuesday'], 'Tuesday'));
    if (time.hasOwnProperty('wednesday')) row.push(timetablerow(time['wednesday'], 'Wednesday'));
    if (time.hasOwnProperty('thursday')) row.push(timetablerow(time['thursday'], 'Thursday'));
    if (time.hasOwnProperty('friday')) row.push(timetablerow(time['friday'], 'Friday'));
    if (time.hasOwnProperty('saturday')) row.push(timetablerow(time['saturday'], 'Saturday'));
    if (time.hasOwnProperty('sunday')) row.push(timetablerow(time['sunday'], 'Sunday'));

    row = row.filter(r => r !== null);

    if (row.length > 0) {
        table = (
            <div className="location-schedule">
                <div className="sub-title">Hours</div>
                <table className="hoursGrid">
                    <tbody>
                        {row}
                    </tbody>
                </table>
            </div>
        );
    }

    return table;
}

export function timetablerow(day, dayname) {
    let row = null;
    if (day.closed || day.open || day.close) {
        if (day.closed === true) {
            row = <tr key={dayname}><td>{dayname}:</td><td>Closed</td></tr>;
        } else {
            row = (
                <tr key={dayname}>
                    <td>{dayname}:</td>
                    <td>
                        {!!day.open && !!day.close
                            ? day.open + ' - ' + day.close
                            : day.open
                                ? day.open
                                : day.close}
                    </td>
                </tr>
            );
        }
    }
    return row;
}

export function cleanJson(obj) {
    for (const prop in obj) {
        const value = obj[prop]; const
            type = typeof value;
        if (value !== null && (type === 'string' || type === 'object') && obj.hasOwnProperty(prop)) {
            if (type === 'object') {
                cleanJson(obj[prop]);
            } else {
                obj[prop] = obj[prop].trim();
            }
        }
    }
    return obj;
}

/**
 * This function will built an url string to be used which will look for the `path` in the `object` passed
 *
 * @param {Object} object a json object to look for the `path` passed to build the url to return
 * @param {string} path a route sepparated by `.` which will indicate a path in the `object` passed to build the url to return
 * @param {string} value
 * @returns a string as a url to be used for example to print an image
 */
export function guard(object, path, value) {
    const pathParts = path.split('.');
    let result = false;
    // Check if required parameters are set; if not, return false
    if (!object || typeof object === 'undefined' || !path || typeof path !== 'string') return false;
    /*
    * Loop through object keys to find a way to the path or check for value
    * If the property does not exist, set result to false
    * If the property is an object, update @pobject
    * Otherwise, update result
    */
    for (let i = 0; i < pathParts.length; i++) {
        const currentPathPart = pathParts[i];
        if (!object.hasOwnProperty(currentPathPart)) {
            result = false;
        } else if (object[currentPathPart] && path === pathParts[i]) {
            result = pathParts[i];
            break;
        } else if (typeof object[currentPathPart] === 'object') {
            object = object[currentPathPart];
        } else {
            result = object[currentPathPart];
        }
    }
    // If we're looking for a specific value and it matches our query (param @value) Otherwise, return the result
    if (typeof value !== 'undefined' && value === result) return true;
    return result;
}

export function calculateDistance(lat1, lon1, lat2, lon2) {
    const p = 0.017453292519943295; // Math.PI / 180
    const c = Math.cos;
    // eslint-disable-next-line no-mixed-operators
    const a = 0.5 - c((lat2 - lat1) * p) / 2 + c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p)) / 2;
    return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}

export function orderLocationsByDistance(list, center) {
    if (center && center.address_latitude && center.address_longitude) {
        const lat1 = center.address_latitude;
        const lon1 = center.address_longitude;
        for (let i = 0; i < list.length; i++) {
            let calculatedDistance = 0;
            if (list[i] && list[i].address && list[i].address.address_latitude && list[i].address.address_longitude) {
                const lat2 = list[i].address.address_latitude;
                const lon2 = list[i].address.address_longitude;
                calculatedDistance = calculateDistance(lat1, lon1, lat2, lon2);
            }
            list[i]['_calculatedDistance'] = calculatedDistance;
        }
        list.sort((a, b) => a._calculatedDistance - b._calculatedDistance);
    }
    return list;
}

export function urlFriendly(str) {
    return str.replace(/[^A-Za-z0-9\s]/g, '').replace(/\s+/g, '-').toLowerCase();
}

export function urlUnfriendly(str) {
    return str.split('-').map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
}

export function setBadgeNumber(total = 0) {
    if (window.FirebasePlugin) window.FirebasePlugin.setBadgeNumber(total);
}

export function isJSON(str) {
    try {
        const o = JSON.parse(str);
        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object",
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === 'object') {
            return o;
        }
    } catch (e) {
        console.log('e:', e);
    }
    return false;
}
