import React from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import PropTypes from 'prop-types';

import { Stars } from '../../layout/UI/Stars';
import Config from '../../helpers/Config';
import { calculateDistance, urlFriendly } from '../../helpers/Functions';

/**
* Component that render Map provided by GoogleMap with a marker with the user position plus others of the locations around the user
*
* @param {Array} locations
* @param {Object} userData
* @param {function} centerMoved
* @param {boolean} recenter
* @param {Object} center
* @param {Object} lastPosition
* @param {Object} history Object with functions provided by react-router-dom
*/
class GoogleMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            zoom: 3,
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            initialCenter: { lat: 42, lng: -100 },
            movingCenter: { lat: 42, lng: -100 },
            center: { lat: 42, lng: -100 },
            hasGeoPos: false
        };
    }

    componentDidMount() {
        if (this.props.center && this.props.center.address_latitude) {
            this.setState({
                zoom: 14,
                initialCenter: {
                    lat: this.props.center.address_latitude,
                    lng: this.props.center.address_longitude
                },
                movingCenter: {
                    lat: this.props.center.address_latitude,
                    lng: this.props.center.address_longitude
                }
            }, () => this.userMarker());
        } else {
            this.userMarker();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.state.activeMarker && this.state.showingInfoWindow && this.state.selectedPlace?.id) && (prevState.selectedPlace !== this.state.selectedPlace)) {
            // Adding a redirect function to the Marker through the DOM
            // this method it's necessary due to `google-maps-react` does not allow `Link` from `react-router-dom`
            // neither pass a function in the `onClick` inside of the `<a />`
            // so this it's using the DOM to add a function to an element by it's `id` and that function will redirect using `react-router-dom`
            setTimeout(() => {
                const a = document.getElementById('info_window_marker');
                if (a) a.addEventListener('click', this.redirectFromMarkerLocation.bind(this));
            }, 300); // There it's a small delay between the mao showing the marker and the DOM finding the id, that's the reason of this
        }
    }

    /**
     * Redirect to location page using `history` from `react-router-dom`
     */
    redirectFromMarkerLocation = () => {
        this.props.history.push(((Config.isMobileDevice && Config.isMobileApp) ? '#' : '') + '/location/profile/' + this.state.selectedPlace.id + '/' + urlFriendly(this.state.selectedPlace.name));
    }

    userMarker = () => {
        if (!!this.props.userData && !!this.props.userData.user_location && !!this.props.userData.user_location.address_latitude) {
            this.setState({
                hasGeoPos: true,
                center: {
                    lat: this.props.userData.user_location.address_latitude,
                    lng: this.props.userData.user_location.address_longitude
                },
                movingCenter: {
                    lat: this.props.lastPosition?.address_latitude || this.props.center?.address_latitude || 42,
                    lng: this.props.lastPosition?.address_longitude || this.props.center?.address_longitude || -100
                },
                zoom: 12
            });
        }
    }

    onMarkerClick = (props, marker) => {
        this.setState(prevState => ({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: !((prevState.selectedPlace.id === props.id && prevState.showingInfoWindow === true))
        }));
    }

    centerMoved = (mapProps, map) => {
        this.props.centerMoved({ address_latitude: map.center.lat(), address_longitude: map.center.lng() });
    }

    render() {
        const userLocationText = 'Starting Point';
        if (this.props.recenter) {
            this.userMarker();
        }

        const markers = this.props.locations.map(location => {
            if (!!location.address && !!location.address.address_latitude && !!location.address.address_longitude) {
                let calculatedDistance = '';

                if (this.props.userData && this.props.userData.user_location && this.props.userData.user_location.address_latitude && this.props.userData.user_location.address_longitude) {
                    calculatedDistance = calculateDistance(this.props.userData.user_location.address_latitude, this.props.userData.user_location.address_longitude, location.address.address_latitude, location.address.address_longitude);
                    calculatedDistance = parseFloat(calculatedDistance).toFixed(2) + ' km';
                }
                return (
                    <Marker
                        key={location.location_id}
                        id={location.location_id}
                        Reviewaverage={location.Reviewaverage}
                        Reviewcounter={location.Reviewcounter}
                        distance={calculatedDistance}
                        name={location.location_name}
                        position={{ lat: location.address.address_latitude, lng: location.address.address_longitude }}
                        onClick={this.onMarkerClick}
                        icon={{
                            url: Config.fileDirectory + 'assets/images/green-map-pin@2x.png'
                        }}
                    />
                );
            }
            return null;
        });

        return (
            <Map
                google={this.props.google}
                className="bigMap"
                mapTypeControl={false}
                zoom={this.state.zoom}
                onDragend={this.centerMoved}
                zoomControl={!Config.isMobileDevice}
                streetViewControl={true}
                fullscreenControl={true}
                initialCenter={this.state.initialCenter}
                center={this.state.movingCenter}
            >
                {this.state.hasGeoPos && (
                    <Marker
                        name={userLocationText}
                        onClick={this.onMarkerClick}
                        position={this.state.center}
                        icon={{
                            url: Config.fileDirectory + 'assets/images/gold-map-pin@2x.png'
                        }}
                    />
                )}
                {markers}
                <InfoWindow marker={this.state.activeMarker} visible={this.state.showingInfoWindow}>
                    <div className="tooltip">
                        {this.state.selectedPlace.id && (
                            <a
                                to="#"
                                className="row"
                                id="info_window_marker"
                            >
                                <div className="basicInfo">
                                    <div className="name">{this.state.selectedPlace.name}</div>
                                    <div className="row">
                                        <div className="stars">
                                            <Stars stars={this.state.selectedPlace.Reviewaverage} />
                                            <span className="fontGold">({this.state.selectedPlace.Reviewcounter})</span>
                                        </div>
                                        <div className="distance">{this.state.selectedPlace.distance}</div>
                                    </div>
                                </div>
                                <div className="icon">
                                    <i className="icn icon-info-header" />
                                </div>
                            </a>
                        )}
                        {!this.state.selectedPlace.id && (
                            <div id="LinkTo" className="name">{userLocationText}</div>
                        )}
                    </div>
                </InfoWindow>
            </Map>
        );
    }
}

GoogleMap.propTypes = {
    userData: PropTypes.instanceOf(Object),
    locations: PropTypes.instanceOf(Array),
    centerMoved: PropTypes.func,
    recenter: PropTypes.bool,
    history: PropTypes.instanceOf(Object).isRequired
};

export default GoogleApiWrapper({ apiKey: Config.GoogleApi })(GoogleMap);
