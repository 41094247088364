import React from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { updateUserResponse, postJoinPlaydate } from '../../redux/actions/playdates';
import CommentBox from '../../layout/Comments/CommentBox';
import PlaydateJoinButtons from '../../layout/UI/PlaydateJoinButtons';
import LeaveComment from '../../layout/Comments/LeaveComment';
import CommentList from '../../layout/Comments/CommentList';
import { urlFriendly } from '../../helpers/Functions';
import ApiError from '../../helpers/ApiError';
import WhiteTitle from '../../hoc/WhiteTitle';

class PlaydatesProfileDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            joinedStatus: '',
            playdateuser_id: null,
            joined: 0,
            maybe: 0,
            declined: 0,
            comments: [] // this one is being used on child components
        };
    }

    componentDidMount() {
        const myPlaydateProfile = this.props.playdate.users.filter(user => user.user_id === this.props.credentials.user_id);
        const joined = this.guests(this.props.playdate.users, 'joined') + 1; // The "+1" it's the event owner
        const maybed = this.guests(this.props.playdate.users, 'maybe');
        const declined = this.guests(this.props.playdate.users, 'declined');
        if (myPlaydateProfile && myPlaydateProfile[0]) {
            this.setState({
                joinedStatus: myPlaydateProfile[0].playdateuser_status,
                playdateuser_id: myPlaydateProfile[0].playdateuser_id
            });
        }
        this.setState({
            joined,
            maybe: maybed,
            declined
        });
    }

    guests = (list, type = 'joined') => {
        const filtered = list.filter(p => p.playdateuser_status === type);
        return filtered.length;
    }

    changeStatus = (e, response) => {
        e.preventDefault();
        const currentState = this.state.joinedStatus;

        let joined = (currentState === 'joined') ? this.state.joined - 1 : this.state.joined;
        let maybe = (currentState === 'maybe') ? this.state.maybe - 1 : this.state.maybe;
        let declined = (currentState === 'declined') ? this.state.declined - 1 : this.state.declined;

        joined = (response === 'joined') ? joined + 1 : joined;
        maybe = (response === 'maybe') ? maybe + 1 : maybe;
        declined = (response === 'declined') ? declined + 1 : declined;

        if (response) {
            if (this.state.playdateuser_id) {
                this.props.dispatch(updateUserResponse(this.props.credentials.token, this.state.playdateuser_id, { playdateuser_status: response }))
                    .then(() => {
                        toast.success('Updated Successfully');
                        this.setState({
                            joinedStatus: response,
                            joined,
                            maybe,
                            declined
                        });
                    })
                    .catch(() => ApiError('Sorry, there was an error'));
            } else {
                const data = {
                    playdate_id: this.props.playdate.playdate_id,
                    user_id: this.props.credentials.user_id,
                    playdateuser_status: response
                };
                this.props.dispatch(postJoinPlaydate(this.props.credentials.token, data))
                    .then(res => {
                        toast.success('Updated Successfully');
                        this.setState({
                            playdateuser_id: res.value.data.playdateuser_id,
                            joinedStatus: response,
                            joined,
                            maybe,
                            declined
                        });
                    })
                    .catch(() => ApiError('Sorry, there was an error'));
            }
        }
    }

    stateSetter = newState => this.setState(newState);

    render() {
        const pd = this.props.playdate;
        let isLivePD = true;
        let image = false;
        if (pd.images && pd.images[0] && pd.images[0].image_largeurl) {
            image = pd.images[0].image_largeurl;
        } else if (pd && pd.location && pd.location.images && pd.location.images[0] && pd.location.images[0].image_largeurl) {
            image = pd.location.images[0].image_largeurl;
        }

        let title = pd.location.location_name;
        if (pd.playdate_title) {
            title = pd.playdate_title;
        }

        let type = 'playdate';
        if (pd.playdate_type) {
            type = pd.playdate_type;
        }

        if (moment.tz(pd.playdate_datetime, 'Etc/GMT').isBefore(moment(), 'day')) {
            isLivePD = false;
        }

        let inviteMore = null;
        if (pd.playdate_isprivate === 0 || pd.owner_id === this.props.credentials.user_id) {
            inviteMore = (<Link className="fontGreen" to={'/events/invite/' + pd.playdate_id}>Invite Friends &gt;</Link>);
        }

        return (
            <div className="maxWidth playdateProfile">
                {image && (
                    <div className="playdateProfileHeader">
                        <div className="square-avatar">
                            <img src={image} alt={pd.location.location_name} />
                        </div>
                    </div>
                )}
                <div className="wrapper backgroundWhite main-details">
                    <h2 className="fontDarkGray">{title}</h2>
                    {!pd.playdate_isactive && (
                        <div className="fontRed">
                            Cancelled
                        </div>
                    )}
                    <div className="fontGray">
                        {pd.playdate_isprivate === 1 ? 'Private ' + type + ' - ' : 'Public ' + type + ' - '}
                        {pd.owner.user_firstname} {pd.owner.user_lastname}
                    </div>
                    <div className="row">
                        <i className="icn icon-clock fontGreen" />
                        <span className="fontDarkGray">
                            {moment.tz(pd.playdate_datetime, 'Etc/GMT').format('MMMM Do YYYY, h:mm a')}
                        </span>
                    </div>
                    <div className="row">
                        <i className="icn icon-distance fontGreen" />
                        <Link to={'/location/profile/' + pd.location.location_id + '/' + urlFriendly(pd.location.location_name)} className="fontGreen">
                            {pd.location.location_name} - {pd.location.address.address_street}, {pd.location.address.address_city}, {pd.location.address.address_state}
                        </Link>
                    </div>
                    {!!pd.playdate_additionalinfo && !!pd.playdate_additionalinfo.website && (
                        <a target="_blank" href={pd.playdate_additionalinfo.website.indexOf('http') === -1 ? 'http://' + pd.playdate_additionalinfo.website : pd.playdate_additionalinfo.website} className="row" rel="noopener noreferrer">
                            <i className="icn icon-internet fontGreen" />
                            <span className="fontGreen">
                                {pd.playdate_additionalinfo.website}
                            </span>
                        </a>
                    )}
                </div>
                {isLivePD && !!pd.playdate_isactive && (pd.owner.user_id !== this.props.credentials.user_id) && (
                    <div className="backgroundWhite">
                        <PlaydateJoinButtons joinedStatus={this.state.joinedStatus} changeStatus={this.changeStatus} />
                    </div>
                )}
                {(!isLivePD || !pd.playdate_isactive) && (
                    <div className="backgroundWhite" />
                )}
                {isLivePD && !!pd.playdate_isactive && (pd.owner.user_id === this.props.credentials.user_id) && (
                    <div className="row alignItems backgroundWhite">
                        <Link to={'/events/manage/event/' + pd.playdate_id} className="flexButtons fontRed alignCenter">
                            <i className="icn icon-edit-profile noMargin" /><br />
                            Edit
                        </Link>
                    </div>
                )}
                <div className="wrapper backgroundWhite joined-users">
                    <div className="row space-between">
                        <h4 className="fontDarkGray">
                            Guest List
                        </h4>
                        {inviteMore}
                    </div>
                    <div className="row space-between alignCenter">
                        <div>
                            <div className="fontGray">
                                Joined
                            </div>
                            <div className="fontGreen h2">
                                {this.state.joined}
                            </div>
                        </div>
                        <div>
                            <div className="fontGray">
                                Interested
                            </div>
                            <div className="fontGreen h2">
                                {this.state.maybe}
                            </div>
                        </div>
                        <div>
                            <div className="fontGray">
                                Invited
                            </div>
                            <div className="fontGreen h2">
                                {pd.users.length}
                            </div>
                        </div>
                    </div>
                </div>
                <CommentBox user={pd.owner} creator comment={pd.playdate_cancelledreason || pd.playdate_comment || ''} />
                <WhiteTitle className="fontGreen">
                    Comments
                </WhiteTitle>
                <LeaveComment
                    dispatch={this.props.dispatch}
                    stateSetter={this.stateSetter}
                    entityId={pd.playdate_id}
                    credentials={this.props.credentials}
                    commentAs={this.props.userData}
                />
                {this.state.comments && (
                    <CommentList
                        commentAs={this.props.userData}
                        stateSetter={this.stateSetter}
                        state={this.state}
                        dispatch={this.props.dispatch}
                        credentials={this.props.credentials}
                        entityId={pd.playdate_id}
                        mode="post"
                        infiniteScroll={true}
                        acceptReplies={true}
                    />
                )}
            </div>
        );
    }
}

PlaydatesProfileDetails.propTypes = {
    userData: PropTypes.instanceOf(Object).isRequired,
    dispatch: PropTypes.func.isRequired,
    credentials: PropTypes.instanceOf(Object).isRequired,
    playdate: PropTypes.instanceOf(Object).isRequired,
    userLocation: PropTypes.instanceOf(Object)
};

export default PlaydatesProfileDetails;
