import apiConnection from '../../helpers/Api';
import { cleanJson } from '../../helpers/Functions';

export function updateUserResponse(token, id, data) {
    return {
        type: 'UPDATE_USER_RESPONSE',
        payload: apiConnection.put('playdateusers/' + id, cleanJson(data), { headers: { Authorization: token } })
    };
}

export function cancelPlaydate(token, id, data) {
    return {
        type: 'CANCEL_PLAYDATE',
        payload: apiConnection.put('playdates/' + id, cleanJson(data), { headers: { Authorization: token } })
    };
}

export function getPlaydates(token, geopoint, filters, sortByDistance) {
    let query = '?' + filters;
    query += '&expand=images,location,owner,owner.images,dogs,dogs.breeds,dogs.images,users,users.user,users.user.dogs,users.user.images,users.user.dogs.images,users.user.dogs.breeds,location.address, location.images';
    // sortby distance will only work when there is infact a geopoint, otherwise IT WILL BREAK
    if (geopoint) {
        query += '&location=' + geopoint.address_latitude + ',' + geopoint.address_longitude;
        if (sortByDistance) query += '&sort=Distance';
        else { query += '&sort=playdate_datetime'; }
    } else {
        query += '&sort=playdate_datetime';
    }
    return {
        type: 'GET_PLAYDATES',
        payload: apiConnection.get('playdates' + query, { headers: { Authorization: token } })
    };
}

export function getPlaydate(token, id, geopoint) {
    let query = '?expand=images, location.images, owner, owner.images, dogs, dogs.breeds, dogs.images, users, users.user, users.user.dogs, users.user.images, users.user.dogs.images, users.user.dogs.breeds, location.address, reviews.user, reviews.playdateuser.user';
    if (geopoint) {
        query += '&location=' + geopoint.address_latitude + ',' + geopoint.address_longitude;
    }
    return {
        type: 'GET_PLAYDATE',
        payload: apiConnection.get('playdates/' + id + query, { headers: { Authorization: token } })
    };
}

export function reviewPlaydateUser(token, id, data) {
    return {
        type: 'REVIEW_PLAYDATE_USER',
        payload: apiConnection.post('playdateusers/review/' + id, cleanJson(data), { headers: { Authorization: token } })
    };
}

export function createPlaydate(token, data) {
    return {
        type: 'CREATE_PLAYDATE',
        payload: apiConnection.post('playdates', cleanJson(data), { headers: { Authorization: token } })
    };
}

export function addPlaydateUsers(token, id, data) {
    return {
        type: 'ADD_PLAYDATE_USERS',
        payload: apiConnection.put('playdates/users/' + id, cleanJson(data), { headers: { Authorization: token } })
    };
}

export function addPlaydateDogs(token, id, data) {
    return {
        type: 'ADD_PLAYDATE_DOGS',
        payload: apiConnection.put('playdates/dogs/' + id, cleanJson(data), { headers: { Authorization: token } })
    };
}

export function updatePlaydate(token, id, data) {
    return {
        type: 'UPDATE_PLAYDATE',
        payload: apiConnection.put('playdates/' + id, cleanJson(data), { headers: { Authorization: token } })
    };
}

export function postJoinPlaydate(token, data) {
    return {
        type: 'POST_JOIN_PLAYDATE',
        payload: apiConnection.post('playdateusers', cleanJson(data), { headers: { Authorization: token } })
    };
}

export function invitePlaydateUsers(token, id, data) {
    return {
        type: 'INVITE_PLAYDATE_USERS',
        payload: apiConnection.post('playdates/users/' + id, data, { headers: { Authorization: token } })
    };
}

export function pc_comment(payload) {
    return {
        type: 'PLAYDATECREATE_COMMENT',
        payload
    };
}

export function pc_type(payload) {
    return {
        type: 'PLAYDATECREATE_TYPE',
        payload
    };
}

export function pc_title(payload) {
    return {
        type: 'PLAYDATECREATE_TITLE',
        payload
    };
}

export function pc_changeEndDate(payload) {
    return {
        type: 'PLAYDATECREATE_CHANGE_ENDDATE',
        payload
    };
}

export function pc_changeEndTime(payload) {
    return {
        type: 'PLAYDATECREATE_CHANGE_ENDTIME',
        payload
    };
}

export function pc_additionalinfo(payload) {
    return {
        type: 'PLAYDATECREATE_ADDITIONALINFO',
        payload
    };
}

export function pc_images(payload) {
    return {
        type: 'PLAYDATECREATE_IMAGES',
        payload
    };
}

export function pc_images_files(payload) {
    return {
        type: 'PLAYDATECREATE_IMAGES_FILES',
        payload
    };
}

export function pe_comment(payload) {
    return {
        type: 'PLAYDATEEDIT_COMMENT',
        payload
    };
}

export function pe_type(payload) {
    return {
        type: 'PLAYDATEEDIT_TYPE',
        payload
    };
}

export function pe_title(payload) {
    return {
        type: 'PLAYDATEEDIT_TITLE',
        payload
    };
}

export function pe_changeEndDate(payload) {
    return {
        type: 'PLAYDATEEDIT_CHANGE_ENDDATE',
        payload
    };
}

export function pe_changeEndTime(payload) {
    return {
        type: 'PLAYDATEEDIT_CHANGE_ENDTIME',
        payload
    };
}

export function pe_additionalinfo(payload) {
    return {
        type: 'PLAYDATEEDIT_ADDITIONALINFO',
        payload
    };
}

export function pe_images(payload) {
    return {
        type: 'PLAYDATEEDIT_IMAGES',
        payload
    };
}

export function pe_images_files(payload) {
    return {
        type: 'PLAYDATEEDIT_IMAGES_FILES',
        payload
    };
}

export function pc_changeDate(payload) {
    return {
        type: 'PLAYDATECREATE_CHANGE_DATE',
        payload
    };
}

export function pc_changeTime(payload) {
    return {
        type: 'PLAYDATECREATE_CHANGE_TIME',
        payload
    };
}

export function pc_toggleInvitation() {
    return {
        type: 'PLAYDATECREATE_TOGGLE_INVITATION'
    };
}

export function pc_toggleDog(payload) {
    return {
        type: 'PLAYDATECREATE_TOGGLE_DOG',
        payload
    };
}

export function pc_toggleUser(payload) {
    return {
        type: 'PLAYDATECREATE_TOGGLE_USER',
        payload
    };
}

export function pc_togglePark(payload) {
    return {
        type: 'PLAYDATECREATE_TOGGLE_PARK',
        payload
    };
}

export function pe_changeDate(payload) {
    return {
        type: 'PLAYDATEEDIT_CHANGE_DATE',
        payload
    };
}

export function pe_changeTime(payload) {
    return {
        type: 'PLAYDATEEDIT_CHANGE_TIME',
        payload
    };
}

export function pe_toggleInvitation(payload) {
    return {
        type: 'PLAYDATEEDIT_TOGGLE_INVITATION',
        payload
    };
}

export function pe_toggleDog(payload) {
    return {
        type: 'PLAYDATEEDIT_TOGGLE_DOG',
        payload
    };
}

export function pe_toggleUser(payload) {
    return {
        type: 'PLAYDATEEDIT_TOGGLE_USER',
        payload
    };
}

export function pe_togglePark(payload) {
    return {
        type: 'PLAYDATEEDIT_TOGGLE_PARK',
        payload
    };
}

export function pe_editing(payload) {
    return {
        type: 'EDITING',
        payload
    };
}

export function playdateFilter_distance(payload) {
    return {
        type: 'PLAYDATE_FILTER_DISTANCE',
        payload
    };
}
