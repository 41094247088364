import React from 'react';
import { confirmAlert } from 'react-confirm-alert';

import 'react-confirm-alert/src/react-confirm-alert.css';

const showAlert = (title = 'Are you sure?', subtitle, confirmation = 'Yes', clicked) => {
    confirmAlert({
        customUI: ({ onClose }) => (
            <div className="custom-confirm-ui">
                <h1>{title}</h1>
                {!!subtitle && <p className="basic-confirm-subtitle">{subtitle}</p>}
                <div className="row">
                    <button
                        className="flexButtons greenBorder whiteBG fontGreen h6"
                        type="button"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        className="flexButtons whiteBorder whiteBG fontRed h6"
                        type="button"
                        onClick={() => {
                            if (clicked) clicked();
                            onClose();
                        }}
                    >
                        {confirmation}
                    </button>
                </div>
            </div>
        )
    });
};

export default showAlert;
