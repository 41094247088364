import React from 'react';
import { Link } from 'react-router-dom';

export const InviteFriends = ({ wrapperClass }) => (
    <div className={wrapperClass || 'wrapper'}>
        <Link className="wideButtons greenBorder greenBG fontWhite" to="/findfriends">
            <i className="icn icon-couple" /> Find Friends
        </Link>
        <a
            className="wideButtons greenBorder greenBG fontWhite"
            href="mailto:?body=Check this out,%0D%0A%0D%0APooch is the first social network for dogs and their humans. You can arrange events, get notified when other dogs check into your favourite parks, add photos and rate your social experiences. Pooch has also mapped over 40,000 dog parks and dog-friendly businesses that all have user submitted ratings, photos and content.%0D%0A%0D%0AYou will discover new parks, find new pet services, and connect with other dogs along the way. Best of all, it is free to use.%0D%0A%0D%0AJoin our pack on Pooch!%0D%0A%0D%0APooch App%0D%0A%0D%0ADownload on iOS:%0D%0Ahttps://itunes.apple.com/app/id1049134590  %0D%0A%0D%0ADownload on Android: %0D%0Ahttps://play.google.com/store/apps/details?id=com.hottomali.OffLeashZone &subject=Join me on Pooch Network so our dogs can play together at events!"
        >
            <i className="icn icon-contact-us" /> Invite Friends by Email
        </a>
    </div>
);
