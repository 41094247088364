import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import {
    getNotifications,
    getActivity,
    getActivities,
    getActivityCounter,
    markAllAsSeen,
    saveActivityScreenDetails,
    saveNotificationScreenDetails,
    saveDogScreenDetails
} from '../redux/actions/activities';
import Spinner from '../layout/UI/Spinner';
import LeaveComment from '../layout/Comments/LeaveComment';
import { Pull2Refresh, infiniteScroll } from '../helpers/Pull2Refresh';
import { setBadgeNumber } from '../helpers/Functions';
import ApiError from '../helpers/ApiError';
import Config from '../helpers/Config';
import { ActivityListItem } from './ActivityListItem';

class ActivityDispatcher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activities: [],
            loading: true,
            currentPage: 1,
            totalPages: 1, // This it's being used by infiniteScroll->handleScroll->loadMore
            loadingMore: false,
            hideRefresh: true,
            comments: [] // this one is being used on child components
        };
    }

    componentDidMount() {
        if (this.props.activityId) {
            this.getActivity(this.props.activityId);
        } else {
            setTimeout(() => this.setState({ hideRefresh: false }), 5000);
            if (this.props.type === 'activity' && this.props.saveActivityScreenDetails?.activities?.length) {
                this.setState({ currentPage: this.props.saveActivityScreenDetails.currentPage });
                this.getter(this.props.saveActivityScreenDetails.activities, this.props.saveActivityScreenDetails.totalPages);
            } else if (this.props.type === 'dogs' && this.props.saveDogScreenDetails?.activities?.length) {
                this.setState({ currentPage: this.props.saveDogScreenDetails.currentPage });
                this.getter(this.props.saveDogScreenDetails.activities, this.props.saveDogScreenDetails.totalPages);
            } else if (this.props.type === 'notification' && this.props.saveNotificationScreenDetails?.activities?.length) {
                this.setState({ currentPage: this.props.saveNotificationScreenDetails.currentPage });
                this.getter(this.props.saveNotificationScreenDetails.activities, this.props.saveNotificationScreenDetails.totalPages);
            } else {
                this.init();
            }
        }
        Pull2Refresh(this.init);
    }

    init = () => {
        window.scrollTo(0, 0);
        this.setState({
            activities: [],
            loading: true,
            currentPage: 1,
            totalPages: 1,
            loadingMore: false
        }, this.start);
    }

    start = () => {
        if (this.props.activityId) {
            this.getActivity(this.props.activityId);
        } else {
            this.getActivities();
        }
    }

    getActivity = id => {
        this.props.dispatch(getActivity(this.props.credentials.token, id))
            .then(this.gotActivity)
            .catch(this.catcher);
    }

    gotActivity = response => {
        const data = response.value.data;
        if (Array.isArray(data)) {
            this.activityList(data);
        } else {
            this.activityList([data]);
        }
        this.setState({
            loading: false,
            loadingMore: false,
            totalPages: response.value.headers['x-pagination-page-count']
        });
    }

    getActivities = () => {
        if (this.props.type === 'activity') {
            const filter = '&withfriends=true';
            this.props.dispatch(getActivities(this.props.credentials.token, this.props.credentials.user_id, this.state.currentPage, filter))
                .then(response => {
                    this.getter(response.value.data, response.value.headers['x-pagination-page-count']);
                    const data = {};
                    if (this.props.saveActivityScreenDetails?.activities?.length > 0 && this.state.currentPage > 1) {
                        data['activities'] = [...this.props.saveActivityScreenDetails.activities, ...response.value.data];
                    } else {
                        data['activities'] = [...response.value.data];
                    }
                    data['totalPages'] = response.value.headers['x-pagination-page-count'];
                    data['currentPage'] = this.state.currentPage;
                    data['createdAt'] = moment()._d;
                    this.props.dispatch(saveActivityScreenDetails(data));
                })
                .catch(this.catcher);
        } else if (this.props.type === 'dogs') {
            const filter = '&onlydogimages=true';
            this.props.dispatch(getActivities(this.props.credentials.token, this.props.credentials.user_id, this.state.currentPage, filter))
                .then(response => {
                    this.getter(response.value.data, response.value.headers['x-pagination-page-count']);
                    const data = {};
                    if (this.props.saveDogScreenDetails?.activities?.length > 0 && this.state.currentPage > 1) {
                        data['activities'] = [...this.props.saveDogScreenDetails.activities, ...response.value.data];
                    } else {
                        data['activities'] = [...response.value.data];
                    }
                    data['totalPages'] = response.value.headers['x-pagination-page-count'];
                    data['currentPage'] = this.state.currentPage;
                    data['createdAt'] = moment()._d;
                    this.props.dispatch(saveDogScreenDetails(data));
                })
                .catch(this.catcher);
        } else if (this.props.credentials?.token) {
            this.props.dispatch(getNotifications(this.props.credentials.token, this.state.currentPage))
                .then(response => {
                    this.getter(response.value.data, response.value.headers['x-pagination-page-count']);
                    const data = {};
                    if (this.props.saveNotificationScreenDetails?.activities?.length > 0 && this.state.currentPage > 1) {
                        data['activities'] = [...this.props.saveNotificationScreenDetails.activities, ...response.value.data];
                    } else {
                        data['activities'] = [...response.value.data];
                    }
                    data['totalPages'] = response.value.headers['x-pagination-page-count'];
                    data['currentPage'] = this.state.currentPage;
                    data['createdAt'] = moment()._d;
                    this.props.dispatch(saveNotificationScreenDetails(data));
                })
                .catch(this.catcher);
        }
    }

    getter = (data, totalPages) => {
        this.markAll();
        this.activityList(data);
        this.setState({
            loading: false,
            loadingMore: false,
            totalPages
        }, () => {
            if (this.state.currentPage < this.state.totalPages) {
                infiniteScroll(this, this.getActivities);
            }
        });
    }

    catcher = err => {
        ApiError(err?.response?.data?.[0]?.message || err.message);
        this.setState({ loading: false, loadingMore: false });
    }

    stateSetter = newState => {
        this.setState(newState, () => {
            const activity = this.state.comments.map(activity => (
                <ActivityListItem
                    winScroll={this.props.winScroll}
                    dispatch={this.props.dispatch}
                    key={activity.post_id}
                    activity={activity}
                    credentials={this.props.credentials}
                    disableImages={this.props.disableImages}
                    disableActions={this.props.disableActions}
                />
            ));
            this.setState({
                activities: [...activity, ...this.state.activities],
                comments: []
            });
        });
    }

    activityList(data) {
        const activities = data.map(activity => (
            <ActivityListItem
                winScroll={this.props.winScroll}
                dispatch={this.props.dispatch}
                key={activity.activity_id}
                activity={activity}
                credentials={this.props.credentials}
                disableImages={this.props.disableImages}
                disableActions={this.props.disableActions}
            />
        ));
        this.setState({ activities: [...this.state.activities, ...activities] });
    }

    markAll() {
        setBadgeNumber(0);
        if (this.props.credentials?.token) {
            this.props.dispatch(markAllAsSeen(this.props.credentials.token, this.props.type))
                .then(() => {
                    this.props.dispatch(getActivityCounter(this.props.credentials.token));
                });
        }
    }

    render() {
        return (
            <React.Fragment>
                {Config.isMobileDevice && (
                    <div className="reload-activities-holder">
                        <div
                            onClick={this.init}
                            className={(!this.state.hideRefresh ? 'reload-activities show' : 'reload-activities')}
                        >
                            <i className="icn icon-reload fontGreen" />
                        </div>
                    </div>
                )}
                {this.props.allowPosts && (
                    <LeaveComment
                        dispatch={this.props.dispatch}
                        stateSetter={this.stateSetter}
                        entityId={this.props.credentials.user_id}
                        credentials={this.props.credentials}
                        commentAs={this.props.userData}
                        placeholder="What are you up to?"
                        actionButtons={true}
                    />
                )}
                {!!this.state.loading && (
                    <Spinner />
                )}
                <ul className="activitiesListSearch">
                    {this.state.activities}
                </ul>
                {!!this.state.loadingMore && (
                    <Spinner />
                )}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        credentials: state.users.credentials,
        saveActivityScreenDetails: state.activities.saveActivityScreenDetails,
        saveNotificationScreenDetails: state.activities.saveNotificationScreenDetails,
        saveDogScreenDetails: state.activities.saveDogScreenDetails
    };
}

export default connect(mapStateToProps)(ActivityDispatcher);
