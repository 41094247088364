import {
    isChrome,
    isFirefox,
    isSafari,
    isOpera,
    isChromium,
    isMobile,
    isAndroid,
    isIOS,
    isEdge
} from 'react-device-detect';

function DeviceType() {
    return isAndroid ? 'Android'
        : isIOS ? 'Ios' : 'Other';
}

function isMobileApp() {
    return isMobile && !isChrome && !isFirefox && !isSafari && !isOpera && !isEdge && !isChromium;
}

function isMobileDevice() {
    return isMobile;
}

const Device = {
    DeviceType: DeviceType(),
    isMobileApp: isMobileApp(),
    isMobileDevice: isMobileDevice()
};

export default Device;
