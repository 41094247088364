import React from 'react';
import { Link } from 'react-router-dom';

import { setSearchFilters } from '../../redux/actions/searchFilters';
import { setSearchedLocationsList } from '../../redux/actions/locations';
import Avatar from '../../layout/UI/Avatar';
import SocialMediaIcons from '../../layout/UI/SocialMediaIcons';
import ContactInfo from '../../layout/UI/ContactInfo';
import LocationsNearBy from '../../layout/LocationsNearBy';
import UserList from '../../layout/UserList';
import { urlFriendly } from '../../helpers/Functions';

const LocationProfileSidebar = props => {
    const filterSelected = (e, value = 'all') => {
        value = value && value.toLowerCase();
        props.dispatch(setSearchFilters(value));
        props.dispatch(setSearchedLocationsList([]));
    };

    const listCategories = types => {
        const categories = [];
        for (let i = 0; i < types.length; i++) {
            categories.push(
                <div key={types[i].locationtype_name}>
                    <Link to="/" onClick={e => filterSelected(e, types[i].locationtype_name)} className="fontRed">
                        {types[i].locationtype_name}
                    </Link>
                </div>
            );
        }
        return categories;
    };

    let socialmedia = null;
    if (!!props.location && !!props.location.location_socialmedia) {
        socialmedia = (<SocialMediaIcons socialmedia={props.location.location_socialmedia} />);
    }

    let contactInfo = null;
    if (!!props.location && !!props.location.location_contactinfo) {
        contactInfo = (<ContactInfo contactInfo={props.location.location_contactinfo} />);
    }

    let leader;

    if (props.location && props.location.topusers && props.location.topusers.length > 0) {
        leader = (
            <div className="">
                <h4 className="fontGreen">Pack Leader</h4>
                <UserList
                    users={[props.location.topusers[0]]}
                    dispatch={props.dispatch}
                    credentials={props.credentials}
                    addFriend
                />
            </div>
        );
    } else if (props.location && props.location.topusers && props.location.topusers.length === 0) {
        leader = (
            <Link className="wideButtons greenBorder fontGreen h5" to={'/location/checkin/' + props.location.location_id + '/' + urlFriendly(props.location.location_name)}>
                Check in to become Pack Leader
            </Link>
        );
    }

    const counters = [<React.Fragment key="empty" />];

    if (props.location && props.location.Favoritecounter) {
        counters.push(
            <div key="favorites" title="Favorites" className="fontWhite">
                <i className="icn icon-heart-fill" /> {props.location.Favoritecounter}
            </div>
        );
    }

    if (props.location && props.location.Checkincounter) {
        counters.push(
            <div key="favorites" title="Check Ins" className="fontWhite">
                <i className="icn icon-check-in" /> {props.location.Checkincounter}
            </div>
        );
    }

    return (
        <div className="column-1 location-sidebar">
            <div className="floating-profile-box">
                <div className="round-avatar huge fontWhite">
                    <Avatar
                        src={(props.location && props.location.images && props.location.images[0] && props.location.images[0].image_mediumurl) || null}
                        alt="location profile"
                        type="location"
                    />
                </div>
                <div className="detail-info h6">
                    <div className="row location-social-media h3">
                        {counters}
                    </div>
                    {contactInfo}
                    {props.location && props.location.location_website && (
                        <div className="location-website">
                            <a itemProp="url" href={(props.location.location_website.indexOf('://') === -1) ? 'http://' + props.location.location_website : props.location.location_website} target="_blank" rel="noopener noreferrer">Website</a>
                        </div>
                    )}
                    {props.location && props.location.address && (
                        <div className="directions">
                            <a target="_blank" rel="noopener noreferrer" href={'https://www.google.com/maps/dir/Current+Location/' + (props.location.address.address_street || '') + ' ' + (props.location.address.address_street2 || '') + ' ' + (props.location.address.address_city || '') + ' ' + (props.location.address.address_state || '') + ' ' + (props.location.address.address_country || '') + ' ' + (props.location.address.address_zipcode || '')}>Directions</a>
                        </div>
                    )}
                    {(props.location && props.location.location_name)
                        && ((props.userData && props.userData.user_role && props.userData.user_role === 'superadmin')
                            || (props.userData && props.userData.user_role && props.userData.user_role === 'admin' && props.myLocationsToAdmin && props.myLocationsToAdmin.includes(String(props.location.location_id))))
                        && (
                            <div className="edit-location">
                                <Link to={'/managelocation/' + props.location.location_id + '/' + urlFriendly(props.location.location_name)}>Edit</Link>
                            </div>
                        )}
                    {socialmedia !== null && (
                        <div className="row location-social-media">
                            {socialmedia}
                        </div>
                    )}
                </div>
            </div>
            {props.location && props.location.locationtypes && (
                <div className="location-categories">
                    <h4 className="fontGreen">Categories</h4>
                    {listCategories(props.location.locationtypes)}
                </div>
            )}
            <div className="spacerSmall" />
            {leader}
            {props && props.location && props.location.address && props.location.address.address_latitude && !props.location.location_featured && (
                <LocationsNearBy
                    geoLocation={props.location.address}
                    dispatch={props.dispatch}
                    featured
                />
            )}
        </div>
    );
};

export default LocationProfileSidebar;
