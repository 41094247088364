import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';

import { updateDog, getDogProfile } from '../../redux/actions/dogs';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import ApiError from '../../helpers/ApiError';

class UserDogEditName extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // dog: null
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(getDogProfile(this.props.credentials.token, this.props.match.params.id)).then(() => this.myDog());
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    myDog() {
        this.setState({
            dog_name: this.props.dogProfile.dog_name
        });
    }

    verifyData(e) {
        e.preventDefault();
        if (!this.state.dog_name) {
            ApiError('Please write your dogs name!');
        } else {
            this.props.dispatch(updateDog(this.props.credentials.token, this.props.match.params.id, { dog_name: this.state.dog_name }))
                .then(() => {
                    toast.success('Updated name correctly');
                    this.props.history.goBack();
                })
                .catch(err => {
                    ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                });
        }
    }

    render() {
        return (
            <div className="mainAppContainer LoginScreens">
                <MainNavigation {...this.props} title="Add your Dog" leftButton="back" />
                <div className="bodyContent backgroundGreen">
                    <div className="LoginWrapper wrapper maxWidth">
                        <h1 className="section">Change your dog's name</h1>
                    </div>
                    <div className="LoginWrapper wrapper maxWidth">
                        <h4 className="fontWhite">What is your dogs name?</h4>
                        <div className="spacerSmall" />
                        <div className="innerLabelInput">
                            <span className="blocking-span">
                                <input className="inputText" name="dogName" type="text" required value={this.state.dog_name || ''} onChange={e => this.setState({ dog_name: e.target.value })} />
                                <span className="floating-label">Name</span>
                            </span>
                        </div>
                    </div>
                    <div className="LoginWrapper wrapper maxWidth">
                        <Link to={'/dog/' + this.props.match.params.id} className="rightRoundButton whiteBorder whiteOpaque fontWhite" onClick={e => this.verifyData(e)}>
                            Save Name &gt;
                        </Link>
                    </div>
                </div>
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        dogProfile: state.dogs.dogProfile
    };
}

export default connect(mapStateToProps)(UserDogEditName);
