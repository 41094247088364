import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Config from '../helpers/Config';
import { checkPermissions, getDeviceToken } from '../helpers/PushNotifications';
import ApiError from '../helpers/ApiError';
import { updateUser } from '../redux/actions/users';

class SettingsNotifications extends React.Component {
    componentDidMount() {
        if (Config.isMobileApp) {
            checkPermissions(this.props.dispatch);
        }
    }

    toggleNotificationPermission = val => {
        if (val === 1) {
            getDeviceToken(this.props.dispatch, this.props.credentials);
        }

        this.props.dispatch(updateUser(this.props.credentials.token, this.props.userData.user_id, { user_pushnotifications: val }))
            .then(() => { toast.success('Saved'); })
            .catch(err => ApiError(err));
    }

    toggleEmailPermission = val => {
        this.props.dispatch(updateUser(this.props.credentials.token, this.props.userData.user_id, { user_emailnotifications: val }))
            .then(() => { toast.success('Saved'); })
            .catch(err => ApiError(err));
    }

    render() {
        return (
            <React.Fragment>
                {Config.isMobileApp && this.props.deviceStatus === false && (
                    <div className="wrapper backgroundRed">
                        <div>You have notifications turned off for this device
                            <ul>
                                <li>1 - Open up your phone settings</li>
                                <li>2 - Search for the Pooch App</li>
                                <li>3 - Enable Push Notifications</li>
                            </ul>
                        </div>
                    </div>
                )}
                <h2 className="section">Receive push notifications</h2>
                <h6>We only send push notifications when you receive friend requests, event invitations, received likes or comments!</h6>
                <div className="row multiChoiceHolder">
                    <div className={'multiChoice ' + (this.props.userData.user_pushnotifications ? 'selected' : '')} onClick={() => this.toggleNotificationPermission(1)}>
                        Yes
                    </div>
                    <div className={'multiChoice ' + (!this.props.userData.user_pushnotifications ? 'selected' : '')} onClick={() => this.toggleNotificationPermission(0)}>
                        No
                    </div>
                </div>
                <h2 className="section">Receive emails</h2>
                <h6>We only send emails once in a while or when you receive friend requests, event invitations, received likes or comments!</h6>
                <div className="row multiChoiceHolder">
                    <div className={'multiChoice ' + (this.props.userData.user_emailnotifications ? 'selected' : '')} onClick={() => this.toggleEmailPermission(1)}>
                        Yes
                    </div>
                    <div className={'multiChoice ' + (!this.props.userData.user_emailnotifications ? 'selected' : '')} onClick={() => this.toggleEmailPermission(0)}>
                        No
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        deviceStatus: state.users.deviceStatus,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(SettingsNotifications);
