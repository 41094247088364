import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { setCreateDogName } from '../../redux/actions/dogs';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import { guard } from '../../helpers/Functions';
import ApiError from '../../helpers/ApiError';

class UserDogAdd extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    verifyData = e => {
        if (!this.props.dogCreate.name) {
            e.preventDefault();
            ApiError('Please write your dogs name!');
        } else {
            this.props.history.push('/user/dogadd/breed' + this.props.history.location.search);
        }
    }

    render() {
        return (
            <div className="mainAppContainer LoginScreens">
                <MainNavigation {...this.props} title="Add your Dog" leftButton="back" />
                <div className="bodyContent backgroundGreen">
                    <form onSubmit={this.verifyData}>
                        <div className="LoginWrapper wrapper maxWidth">
                            <h1 className="section">Create your Pooch's Profile to Join and Create Events, also to report your Dog as lost!</h1>
                        </div>
                        <div className="LoginWrapper wrapper maxWidth">
                            <h4 className="fontWhite">What is your dogs name?</h4>
                            <div className="spacerSmall" />
                            <div className="innerLabelInput">
                                <span className="blocking-span">
                                    <input className="inputText" name="dogName" type="text" required value={guard(this.props.dogCreate, '.name') || ''} onChange={e => this.props.dispatch(setCreateDogName(e.target.value))} />
                                    <span className="floating-label">Name</span>
                                </span>
                            </div>
                        </div>
                        <div className="LoginWrapper wrapper maxWidth">
                            <input type="submit" value="Next &gt;" className="rightRoundButton whiteBorder whiteOpaque fontWhite" />
                        </div>
                    </form>
                </div>
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        dogCreate: state.dogs.dogCreate
    };
}

export default connect(mapStateToProps)(UserDogAdd);
