import React from 'react';

import DynamicInputs from '../../layout/UI/DynamicInputs';

/*
we want to show all the information available for this particular location
however we only want to show the information available and permited for each input

for example, the category "hotels" has specific inputs
if this location is exclusively "hotels" then it will only show "hotels" information
in other words, if this "hotel" has more information irrelevant to "hotels" then it will exclude this information
*/

const OtherDetails = props => {
    const additionalInfo = [];
    const preselectedCategories = props.location && props.location.locationtypes.map(loc => loc.locationtype_name);

    additionalInfo.push(
        <div key="additional">
            <div className="sub-title">Additional Info</div>
        </div>
    );

    for (const key in DynamicInputs) {
        let showInput = false;
        for (const cat in preselectedCategories) {
            if (DynamicInputs[key].categories.includes(preselectedCategories[cat])) {
                showInput = true;
                break;
            }
        }

        if (showInput && !!props.location && !!props.location.location_additionalinfo && !!props.location.location_additionalinfo[key]) {
            let label = '';
            if (DynamicInputs[key].type === 'input') label = ': ' + props.location.location_additionalinfo[key];

            additionalInfo.push(
                <div key={key}>
                    <div>{DynamicInputs[key].label}{label}</div>
                </div>
            );
        }
    }

    return (
        <React.Fragment>
            {additionalInfo}
        </React.Fragment>
    );
};

export default OtherDetails;
