import React from 'react';
import { Link } from 'react-router-dom';

import Avatar from '../../layout/UI/Avatar';
import { eventDateTime, guard } from '../../helpers/Functions';

class PlaydateCards extends React.Component {
    render() {
        const playdate = this.props.playdate;
        const image = (playdate && playdate.images && playdate.images[0] && playdate.images[0].image_smallurl) || (playdate.location && !!playdate.location.images && playdate.location.images.length > 0 && playdate.location.images[0].image_smallurl);
        const title = playdate && (playdate.playdate_title || (playdate.location && playdate.location.location_name));

        return (
            <div className="playdateCards">
                <Link to={'/events/' + playdate.playdate_id}>
                    <div className="square-avatar">
                        {!!image && (<img src={image} alt={title} />)}
                    </div>
                    <div className="description-holder">
                        <div className="description">
                            <div className="datetime">
                                {eventDateTime(playdate.playdate_datetime)}
                            </div>
                            <div className="title">
                                {title}
                            </div>
                            {playdate.location && playdate.location.address && playdate.location.address.address_city && playdate.location.address.address_state
                                && (
                                    <div className="h7 fontWhite">
                                        {playdate.location.address.address_city}, {playdate.location.address.address_state}
                                    </div>
                                )}
                            <div className="owner-info row fontWhite">
                                <div className="round-avatar user verysmall">
                                    <Avatar
                                        src={guard(playdate.owner, '.images.0.image_smallurl')}
                                        alt={playdate.owner.user_firstname + ' ' + playdate.owner.user_lastname}
                                        type="user"
                                    />
                                </div>
                                {playdate.owner.user_firstname + ' ' + playdate.owner.user_lastname}
                                {!!playdate.users && playdate.users.length > 0 && (
                                    <React.Fragment>
                                        {' & ' + playdate.users.length}
                                        {playdate.users.length === 1 ? ' other user' : ' other users'}
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        );
    }
}

export default PlaydateCards;
