import React from 'react';
import { Link } from 'react-router-dom';
import DateTime from 'react-datetime';
import moment from 'moment';
import Textarea from 'react-textarea-autosize';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { getUserProfile } from '../../redux/actions/users';
import { setSearchLocation, getLocations } from '../../redux/actions/locations';
import {
    pc_comment,
    pc_toggleInvitation,
    pc_changeDate,
    pc_changeTime,
    pc_type,
    pc_title,
    pc_images_files,
    pc_toggleDog,
    pc_togglePark,
    pc_additionalinfo,
    pc_images
} from '../../redux/actions/playdates';
import { PlaydateNavigation } from '../../layout/navigation/PlaydateNavigation';
import { LocationListItem } from '../../layout/locations/LocationListItem';
import Spinner from '../../layout/UI/Spinner';
import NotFound from '../../layout/NotFound';
import PetList from '../../layout/PetList';
import Avatar from '../../layout/UI/Avatar';
import NavigationSearchBar from '../../layout/UI/NavigationSearchBar';
import BasicAlert from '../../layout/UI/BasicAlert';
import { getGeoLocation } from '../../helpers/GeoLocation';
import ApiError from '../../helpers/ApiError';
import { infiniteScroll } from '../../helpers/Pull2Refresh';
import Columns from '../../hoc/Columns';
import { guard } from '../../helpers/Functions';

class PlaydateCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDogs: false,
            showLocations: false,
            dogs: [],
            pictureModal: false,
            locationList: [],
            loading: true,
            checkPrivate: false,
            currentPage: 1,
            totalPages: 1 // This it's being used by infiniteScroll->handleScroll->loadMore
        };
    }

    getGeoLocation = getGeoLocation.bind(this)

    componentDidMount() {
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        this.props.dispatch(getUserProfile(this.props.credentials.token, this.props.credentials.user_id))
            .then(response => this.setState({ dogs: response.value.data.dogs }))
            .catch(err => {
                ApiError(err);
            });
        this.start();
    }

    init = () => {
        this.props.dispatch(pc_togglePark());
        this.setState({
            locationList: [],
            loading: true,
            currentPage: 1,
            totalPages: 1
        }, this.start());
    }

    start = () => {
        this.setState({ loading: true });
        getGeoLocation(this.props, this.getNearByLocations, this.props.credentials.token, true);
    }

    getNearByLocations = () => {
        const filter = 'all';
        this.props.dispatch(getLocations(this.props.credentials.token, this.props.userData.user_location, filter, this.props.searchValue, this.state.currentPage))
            .then(response => {
                this.locationList(response.value.data);
                this.setState({
                    totalPages: response.value.headers['x-pagination-page-count']
                }, () => {
                    if (this.state.currentPage < this.state.totalPages) {
                        infiniteScroll(this, this.getNearByLocations);
                    }
                });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.locationList();
            });
    }

    filesChanged = files => {
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            const file = files[i];
            reader.onloadend = () => {
                this.props.dispatch(pc_images_files(file));
                this.props.dispatch(pc_images(reader.result));
            };
            reader.readAsDataURL(file);
        }
    }

    changeImage = () => {
        const selectfile = document.getElementById('file');
        selectfile.click();
    }

    toggleModal = () => {
        this.setState({ pictureModal: !this.state.pictureModal });
    }

    onDateChange = date => {
        this.props.dispatch(pc_changeDate(date));
    }

    onTimeChange = hour => {
        this.props.dispatch(pc_changeTime(hour));
    }

    verify = e => {
        if ((!this.props.pc.selectedDogs || !this.props.pc.selectedDogs.length) && this.props.pc.type === 'playdate') {
            e.preventDefault();
            BasicAlert('Please choose a Dog for your event.');
            return;
        }
        if (!this.props.pc.selectedPark || !this.props.pc.selectedPark.name) {
            e.preventDefault();
            BasicAlert('Please choose a Location for your event.');
        }
    }

    addDogAlert = e => {
        e.preventDefault();
        BasicAlert('You need add a dog first!');
    }

    selectDog = (e, dog) => {
        e.preventDefault();
        this.props.dispatch(pc_toggleDog(dog.id));
    }

    clear = () => {
        document.getElementById('file').value = '';
        this.props.dispatch(pc_images());
        this.props.dispatch(pc_images_files());
    }

    setAdditionalInfo = e => {
        const additionalinfo = { ...this.props.pc.additionalinfo, [e.target.name]: e.target.value };
        this.props.dispatch(pc_additionalinfo(additionalinfo));
    }

    showDogs = () => {
        this.setState(prevState => ({
            showDogs: !prevState.showDogs
        }));
    }

    showLocations = () => {
        this.setState(prevState => ({
            showLocations: !prevState.showLocations
        }));
    }

    locationList = moreLocations => {
        this.setState(prevState => ({
            locationList: prevState.locationList.concat(moreLocations || null),
            loading: false
        }));
    }

    selectPark = (e, park) => {
        e.preventDefault();
        this.props.dispatch(pc_togglePark(park));
    }

    toggleType = type => {
        this.props.dispatch(pc_type(type));
    }

    selectPrivate = () => {
        this.setState(prevState => ({
            checkPrivate: !prevState.checkPrivate
        }));
    }

    render() {
        const today = this.props.pc.selectedDate ? moment(this.props.pc.selectedDate)._d : moment()._d;
        const defaultTime = this.props.selectedTime ? moment(this.props.pc.selectedTime)._d : moment()._d;
        const valid = current => {
            const day = moment().subtract(1, 'days');
            return current.isAfter(day);
        };

        let newlocations;

        if (this.state.loading) {
            newlocations = (<Spinner />);
        } else if (this.state.locationList.length > 0) {
            newlocations = this.state.locationList.map(location => (
                <LocationListItem
                    dispatch={this.props.dispatch}
                    credentials={this.props.credentials}
                    key={location && location.location_id}
                    location={location}
                    url="location/profile"
                    onClick={this.selectPark}
                />
            ));
        } else {
            newlocations = (
                <NotFound
                    type="searchlocations"
                    onClear={() => {
                        this.props.dispatch(setSearchLocation(''));
                        this.init();
                    }}
                    className="backgroundRed"
                />
            );
        }
        const minuteIncrements = {
            minutes: {
                step: 5
            }
        };
        const c1 = (
            <React.Fragment>
                <div className="simple-navigation">
                    <PlaydateNavigation
                        // showTitle
                        selected="create"
                    />
                </div>
            </React.Fragment>
        );

        const c2 = (
            <React.Fragment>
                <div className="playdate-create-playdate">
                    {/* <WhiteTitle>Create an Event!</WhiteTitle> */}
                    <div className="playdate-data">
                        <div className="spacerSmall" />
                        <h3 className="fontGreen">What do you want to create?</h3>
                        <div className="playdate-type">
                            <label onClick={() => this.toggleType('event')} className="checkmarkContainer h5">This is an Event <br /><span className="h7">Invite other Users and Friends!</span>
                                <span className="checkmark">
                                    {this.props.pc.type === 'event'
                                        ? (<i className="icn icon-check-mark fontBlue" />)
                                        : (<i className="icn" />)}
                                </span>
                            </label>
                            <label onClick={() => this.toggleType('playdate')} className="checkmarkContainer h5">This is a Playdate <br /><span className="h7">Invite a small number of Users and Friends to a local park!</span>
                                <span className="checkmark">
                                    {this.props.pc.type === 'playdate'
                                        ? (<i className="icn icon-check-mark fontBlue" />)
                                        : (<i className="icn" />)}
                                </span>
                            </label>
                        </div>
                        <div className="add-playdate-image">
                            <div className="image-box" onClick={this.changeImage}>
                                <Avatar
                                    src={guard(this.props.pc, '.images')}
                                    onClick={this.changeImage}
                                    alt={this.props.pc.title}
                                    type="blank"
                                />
                                {!guard(this.props.pc, '.images') && (
                                    <div className="select-playdate-image">
                                        <i className="icn icon-upload-photo" />
                                        <p>Click to upload cover picture</p>
                                    </div>
                                )}
                            </div>
                            {guard(this.props.pc, '.images') && (
                                <div className="clear-image">
                                    <i onClick={this.clear} className="icn icon-cross fontRed changeImage" />
                                </div>
                            )}
                            <input
                                className="hidden"
                                id="file"
                                type="file"
                                onChange={e => this.filesChanged(e.target.files)}
                                accept="image/*"
                                required
                            />
                        </div>
                        <div className="playdate-data-top-section">
                            <div className="playdate-title fontDarkGray">
                                <label>Event Name</label>
                                <input
                                    className="inputText"
                                    name="title"
                                    type="text"
                                    onChange={e => this.props.dispatch(pc_title(e.target.value))}
                                    value={(this.props.pc && this.props.pc.title) || ''}
                                />
                            </div>
                            <div className="playdate-time-date">
                                <div className="playdate-date fontDarkGray">
                                    <label>Date</label>
                                    <div className="select-date selectBox">
                                        <DateTime
                                            dateFormat="YYYY-MM-DD"
                                            className="dropdown-calendar"
                                            timeFormat={false}
                                            isValidDate={valid}
                                            defaultValue={today}
                                            onChange={this.onDateChange}
                                        />
                                        <i className="icn icon-calendar fontGreen" />
                                    </div>
                                </div>
                                <div className="playdate-time fontDarkGray">
                                    <label>Time</label>
                                    <div className="select-time selectBox">
                                        <DateTime
                                            className="dropdown-time-select"
                                            dateFormat={false}
                                            timeConstraints={minuteIncrements}
                                            defaultValue={defaultTime}
                                            onChange={this.onTimeChange}
                                        />
                                        <i className="icn icon-time fontGreen" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.props.pc.type === 'event' && (
                            <React.Fragment>
                                <div className="spacerSmall" />
                                <div className="playdate-website">
                                    <label>Does the event have an official website?</label>
                                    <input className="inputText" name="website" type="text" onChange={this.setAdditionalInfo} value={(this.props.pc && this.props.pc.additionalinfo && this.props.pc.additionalinfo.website) || ''} />
                                    <div className="spacerSmall" />
                                </div>
                            </React.Fragment>
                        )}
                        <div className="spacerSmall" />
                        <div className="playdates-select-dog-location">
                            <div className="playdates-select-dog">
                                <div className="wideButtons greenBorder whiteBG fontGreen" onClick={this.showDogs}>
                                    {(!this.props.pc.selectedDogs || !this.props.pc.selectedDogs.length) && (
                                        <span className="select-dogs">Select Your Dog(s) <i className="icn icon-right-arrow" /></span>
                                    )}
                                    {!!this.props.pc.selectedDogs && !!this.props.pc.selectedDogs.length && (
                                        <span className="select-dogs">
                                            {this.props.pc.selectedDogs.length} Dog(s) Selected
                                        </span>
                                    )}
                                </div>
                                {this.state.showDogs && this.state.dogs.length > 0 && (
                                    <div className="playdates-dog-list">
                                        <PetList dogs={this.state.dogs} onClick={this.selectDog} markSelected={true} selectedDogs={this.props.selectedDogs} />
                                    </div>
                                )}
                                {this.state.showDogs && this.state.dogs.length === 0 && (
                                    <div className="playdates-dog-list">
                                        <a className="square-button greenBG fontWhite h6" href="/user/dogadd">Add Dog</a>
                                    </div>
                                )}
                            </div>
                            <div className="spacerSmall" />
                            <div className="playdates-select-location">
                                <div className="wideButtons greenBorder whiteBG fontGreen">
                                    <span className="select-location" onClick={this.showLocations}>
                                        <NavigationSearchBar
                                            callback={this.init}
                                            disp={this.props.dispatch}
                                            searchInputHandler={setSearchLocation}
                                            searchValue={(this.props.pc && this.props.pc.selectedPark && this.props.pc.selectedPark.name) || this.props.searchValue}
                                            placeHolder="Select Location"
                                        />
                                        {!(this.props.pc && this.props.pc.selectedPark && this.props.pc.selectedPark.name) && !this.props.searchValue && (
                                            <i className="icn icon-right-arrow" />
                                        )}
                                    </span>
                                </div>
                                {this.state.showLocations && (
                                    <div className="playdates-location-list" onClick={this.showLocations}>
                                        <div className="add-location-banner row">
                                            <div className="location-banner-caption">
                                                <i className="icn icon-location-pin-white" />
                                                <Link to="/location/add?redirect=/events/create/event">Add a Location</Link>
                                            </div>
                                        </div>
                                        {newlocations}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="spacerSmall" />
                        <div className="playdates-desciption">
                            <div className="h5">Description</div>
                            <Textarea onChange={e => (this.props.dispatch(pc_comment(e.target.value)))} className="niceTextarea" value={this.props.pc.comment} />
                            <div className="playdate-private-event">
                                <label onClick={() => this.props.dispatch(pc_toggleInvitation())} className="checkmarkContainer h5">This is a private {this.props.pc.type} <br /> <p className="sub-text">Select this if you only want invited users to attend</p>
                                    <span className="checkmark">
                                        {this.props.pc.invitationOnly
                                            ? (<i className="icn icon-check-mark fontBlue" />)
                                            : (<i className="icn" />)}
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div className="link-to-next-page">
                            <Link to="/events/create/event/friends" onClick={e => this.verify(e)} className="wideButtons greenBorder greenBG fontWhite">Proceed to Invite Guests</Link>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );

        const c3 = (<React.Fragment />);

        return (
            <div className="mainAppContainer playdates">
                <div className="bodyContent playdates-create">
                    <div className="three-column-layout">
                        <Columns
                            c1={c1}
                            c2={c2}
                            c3={c3}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        pc: state.playdates.create,
        userData: state.users.user,
        credentials: state.users.credentials,
        searchValue: state.locations.searchLocation,
        selectedDogs: state.playdates.create.selectedDogs,
        selectedPark: state.playdates.create.selectedPark,
        selectedDate: state.playdates.create.selectedDate,
        selectedTime: state.playdates.create.selectedTime,
        invitationOnly: state.playdates.create.invitationOnly
    };
}

export default connect(mapStateToProps)(PlaydateCreate);
