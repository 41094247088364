import React from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import ExploreList from './ExploreList';
import Config from '../../helpers/Config';

class WelcomeExplore extends React.Component {
    UNSAFE_componentWillMount() {
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    render() {
        return (
            <div className="mainAppContainer textList">
                <MainNavigation title="I Would Like To..." />
                <header className="textList">
                    <Link to="/events" className="">
                        <img
                            src={Config.fileDirectory + 'assets/images/i-would-like-to-dogs.png'}
                            alt="pooch-logo"
                            srcSet="assets/images/i-would-like-to-dogs.png 1x, assets/images/i-would-like-to-dogs@2x.png 2x"
                        />
                    </Link>
                    <Link to="/events" className="smallWideButtons greenBG fontWhite">
                        Events
                    </Link>
                </header>
                <ExploreList fullCategories={true} link="/search" {...this.props} />
                <SecondaryNavigation />
            </div>
        );
    }
}

export default WelcomeExplore;
