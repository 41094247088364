import React from 'react';
import { Link } from 'react-router-dom';
import DatePicker from 'rmc-date-picker';
import { connect } from 'react-redux';
import moment from 'moment';
import Textarea from 'react-textarea-autosize';
import ReactGA from 'react-ga';

import 'rmc-picker/assets/index.css';
import 'rmc-date-picker/assets/index.css';

import { sendNotification } from '../../redux/actions/all';
import {
    pe_comment,
    pe_editing,
    pe_toggleInvitation,
    pe_changeDate,
    pe_changeTime,
    pe_togglePark,
    getPlaydate,
    cancelPlaydate,
    pe_type,
    pe_title,
    pe_additionalinfo
} from '../../redux/actions/playdates';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import Spinner from '../../layout/UI/Spinner';
import ApiError from '../../helpers/ApiError';
import { formatMonth, formatDay } from '../../helpers/Functions';

class PlaydateEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            maxDate: moment().add(2, 'years')._d,
            minDate: moment()._d,
            loading: true,
            cancelProcess: false,
            cancelreason: '',
            invitedUsers: []
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(getPlaydate(this.props.credentials.token, this.props.match.params.id))
            .then(response => {
                const pd = response.value.data;
                if (String(this.props.pe.editing) !== String(this.props.match.params.id)) {
                    this.props.dispatch(pe_editing(this.props.match.params.id));
                    this.props.dispatch(pe_changeDate(pd.playdate_datetime));
                    this.props.dispatch(pe_changeTime(pd.playdate_datetime));
                    this.props.dispatch(pe_togglePark({ id: pd.location.location_id, name: pd.location.location_name }));
                    this.props.dispatch(pe_comment(pd.playdate_comment));
                    this.props.dispatch(pe_toggleInvitation(pd.playdate_isprivate));
                    this.props.dispatch(pe_type(pd.playdate_type));
                    this.props.dispatch(pe_title(pd.playdate_title));
                    this.props.dispatch(pe_additionalinfo(pd.playdate_additionalinfo));
                }
                this.setState({ loading: false, invitedUsers: pd.users });
            });
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    onDateChange = date => {
        this.props.dispatch(pe_changeDate(date));
    }

    onTimeChange = hour => {
        this.props.dispatch(pe_changeTime(hour));
    }

    setEventType = e => {
        this.props.dispatch(pe_type(e.target.value));
    }

    setAdditionalInfo = e => {
        const additionalinfo = { ...this.props.pe.additionalinfo, [e.target.name]: e.target.value };
        this.props.dispatch(pe_additionalinfo(additionalinfo));
    }

    cancelPlaydate(e) {
        e.preventDefault();
        const data = {
            playdate_isactive: 0,
            playdate_cancelledreason: this.state.cancelreason
        };
        let receivers = this.state.invitedUsers.filter(user => !(user.user_id === String(this.props.credentials.user_id)));
        receivers = this.state.invitedUsers.map(user => ({ user_id: user.user_id }));
        const notificationData = {
            notification_title: 'Event Cancelled',
            notification_body: this.props.userData.user_firstname + ': ' + (this.state.cancelreason || 'Cancelled the Event'),
            playdate_id: this.props.pe.editing,
            sender_id: this.props.credentials.user_id,
            receivers
        };
        this.props.dispatch(cancelPlaydate(this.props.credentials.token, this.props.match.params.id, data))
            .then(() => {
                ApiError('Cancelled Event');
                this.props.dispatch(sendNotification(this.props.credentials.token, notificationData));
                this.props.history.goBack();
            });
    }

    render() {
        return (
            <div className="mainAppContainer playdates">
                <MainNavigation title="Edit the Event" leftButton="back" />
                {this.state.loading && (<Spinner />)}
                <div className="bodyContent wrapper">
                    {!this.state.loading
                        && (
                            <div className="maxWidth">
                                <h1 className="fontGreen">Edit your Event!</h1>
                                <div className="innerLabelInput fontDarkGray noMargin">
                                    <input placeholder="Event Title..." className="inputText" name="title" type="text" onChange={e => this.props.dispatch(pe_title(e.target.value))} value={(this.props.pe && this.props.pe.title) || ''} />
                                </div>
                                <div className="spacerSmall" />
                                {/* <h3 className="fontGreen">Please select</h3>
                                <label className="checkmarkContainer h5">This is a Playdate
                                    <input name="playdate" value="playdate" type="checkbox" onChange={this.setEventType} checked={this.props.pe.type === 'playdate' ? 'checked':''}/>
                                    <span className="checkmark"></span>
                                </label>
                                <label className="checkmarkContainer h5">This is an Event
                                    <input name="event" value="event" type="checkbox" onChange={this.setEventType} checked={this.props.pe.type === 'event' ? 'checked':''}/>
                                    <span className="checkmark"></span>
                                </label> */}
                                {this.props.pe.type === 'event' && (
                                    <React.Fragment>
                                        <div className="spacerSmall" />
                                        <h4 className="fontGreen">Does the event have an official website?</h4>
                                        <div className="innerLabelInput fontDarkGray noMargin">
                                            <input placeholder="Website URL" className="inputText" name="website" type="text" onChange={this.setAdditionalInfo} value={(this.props.pe && this.props.pe.additionalinfo && this.props.pe.additionalinfo.website) || ''} />
                                        </div>
                                        <div className="spacerSmall" />
                                    </React.Fragment>
                                )}
                                <div className="selectDate">
                                    <h5>Select the date</h5>
                                    <DatePicker
                                        mode="date"
                                        date={this.props.pe.selectedDate ? moment(this.props.pe.selectedDate)._d : moment()._d}
                                        maxDate={this.state.maxDate}
                                        minDate={this.state.minDate}
                                        onDateChange={this.onDateChange}
                                        use12Hours={true}
                                        formatMonth={formatMonth}
                                        formatDay={formatDay}
                                    />
                                </div>
                                <div className="selectTime">
                                    <h5>Select the time</h5>
                                    <DatePicker
                                        mode="time"
                                        date={this.props.pe.selectedTime ? moment(this.props.pe.selectedTime)._d : moment()._d}
                                        minuteStep={5}
                                        onDateChange={this.onTimeChange}
                                        use12Hours={true}
                                        formatMonth={formatMonth}
                                    />
                                </div>
                                <Link to={'/events/manage/event/' + this.props.match.params.id + '/locationselection'} className="wideButtons greenBorder whiteBG fontGreen">
                                    {(!this.props.pe.selectedPark || !this.props.pe.selectedPark.name) && (
                                        <span>Select the Location</span>
                                    )}
                                    {this.props.pe.selectedPark && this.props.pe.selectedPark.name && (
                                        <span>{this.props.pe.selectedPark.name}</span>
                                    )}
                                </Link>
                                <div className="h5">Comments</div>
                                <Textarea onChange={e => (this.props.dispatch(pe_comment(e.target.value)))} className="niceTextarea" placeholder="Write something about the meet up (e.g. We can meet in the big fountain)" value={this.props.pe.comment || ''} />
                                <label className="checkmarkContainer h5">By Invitation Only
                                    <input type="checkbox" onChange={() => this.props.dispatch(pe_toggleInvitation(!this.props.pe.invitationOnly))} checked={this.props.pe.invitationOnly ? 'checked' : ''} />
                                    <span className="checkmark" />
                                </label>
                                {!this.state.cancelProcess
                                    ? (
                                        <div className="row alignItems">
                                            <Link onClick={e => { e.preventDefault(); this.setState({ cancelProcess: true }); }} to="/events/manage/event" className="wideButtons redBorder redBG fontWhite">Cancel Event</Link>
                                            <div className="flexButtons transparentBorder transparentBG fontGreen" />
                                            <Link to={'/events/manage/event/' + this.props.match.params.id + '/image'} className="wideButtons greenBorder greenBG fontWhite">Next</Link>
                                        </div>
                                    )
                                    : (
                                        <div>
                                            <Textarea className="niceTextarea smallmargin" placeholder="I am cancelling because..." id="messageArea" onChange={e => this.setState({ cancelreason: e.target.value })} value={this.state.cancelreason} />
                                            <div className="row alignItems">
                                                <Link to="#" onClick={e => { e.preventDefault(); this.setState({ cancelProcess: false }); }} className="wideButtons blueBorder whiteBG fontBlue">
                                                    Go Back
                                                </Link>
                                                <div className="flexButtons transparentBorder transparentBG fontGreen" />
                                                <Link to="#decline" onClick={e => this.cancelPlaydate(e)} className="wideButtons redBorder redBG fontWhite">
                                                    Yes, Cancel Event
                                                </Link>
                                            </div>
                                        </div>
                                    )}
                            </div>
                        )}
                </div>
                <SecondaryNavigation selected="events" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        pe: state.playdates.edit
    };
}

export default connect(mapStateToProps)(PlaydateEdit);
