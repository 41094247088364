import React from 'react';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { getPlaydates, playdateFilter_distance } from '../../redux/actions/playdates';
import { PlaydateNavigation } from '../../layout/navigation/PlaydateNavigation';
import Spinner from '../../layout/UI/Spinner';
import NotFound from '../../layout/NotFound';
import { InviteFriends } from '../../layout/InviteFriends';
import { getGeoLocation } from '../../helpers/GeoLocation';
import Config from '../../helpers/Config';
import ApiError from '../../helpers/ApiError';
import Columns from '../../hoc/Columns';
import WhiteTitle from '../../hoc/WhiteTitle';
import PlaydayeManageList from './PlaydatesManageList';
import PlaydateCards from './PlaydateCards';
import FindFriendsComponent from '../Friends/FindFriendsComponent';
import FriendRequestsComponent from '../Friends/FriendRequestsComponent';
import HelmetTags from '../../helmetTags';

export class PlaydatesHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            perPage: 5,
            public: false,
            publicArray: [],
            invited: [],
            passed: [],
            loadingPublic: true,
            reloadingPublic: false,
            loadingInvited: true,
            loadingPassed: true,
            pagePublic: 1,
            pageInvited: 1,
            pagePassed: 1,
            totalPagesPublic: 1,
            totalPagesInvited: 1,
            totalPagesPassed: 1,
            selectedCard: 0
        };
    }

    getGeoLocation = getGeoLocation.bind(this)

    componentDidMount() {
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        if (!this.props.userData || !this.props.userData.user_id) {
            this.props.dispatch(playdateFilter_distance('all'));
        }
        this.getGeoLocation(this.props, this.loadPublic, this.props.credentials.token, true);
        this.loadInvited();
        this.loadPassed();
        if (!Config.isMobileDevice) window.dragscroll.reset();
    }

    loadPublic = () => {
        let distanceFilter = '';
        if ((this.props.credentials && this.props.credentials.token) || this.props.playdateFilters.distance) {
            distanceFilter = this.props.playdateFilters.distance === 'all' ? '' : '&filter[distance][lte]=' + (this.props.playdateFilters.distance || 100);
        }
        const filters = 'islive=true&onlymine=false&per-page=' + this.state.perPage + '&page=' + this.state.pagePublic + distanceFilter;
        const mylocation = (this.props.userData && this.props.userData.address && this.props.userData.address.address_latitude && this.props.userData.address) || (this.props.userData && this.props.userData.user_location && this.props.userData.user_location.address_latitude && this.props.userData.user_location) || null;
        this.props.dispatch(getPlaydates(this.props.credentials.token, mylocation, filters, true))
            .then(response => {
                this.setState(prevState => ({
                    publicArray: [...prevState.publicArray, ...response.value.data],
                    loadingPublic: false,
                    reloadingPublic: false,
                    totalPagesPublic: response.value.headers['x-pagination-page-count']
                }), () => this.showPublic(this.state.publicArray));
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loadingPublic: false, reloadingPublic: false });
            });
    }

    loadMorePublic = thumb => {
        this.setState({ selectedCard: thumb });
        if ((thumb === (this.state.pagePublic * this.state.perPage) - 2) && (this.state.pagePublic < this.state.totalPagesPublic)) {
            this.setState(prevState => ({
                pagePublic: prevState.pagePublic + 1,
                loadingPublic: true
            }), this.loadPublic);
        }
    }

    changeDistance = distance => {
        this.props.dispatch(playdateFilter_distance(distance));
        this.setState({
            selectedCard: 0,
            public: null,
            publicArray: [],
            reloadingPublic: true,
            pagePublic: 1,
            totalPagesPublic: 1
        }, this.loadPublic);
    }

    loadInvited = () => {
        if (this.props.credentials?.token) {
            const filters = 'islive=true&onlymine=true&filter[owner_id][neq]=' + this.props.credentials.user_id + '&per-page=5&page=' + this.state.pageInvited;
            this.props.dispatch(getPlaydates(this.props.credentials.token, false, filters))
                .then(response => {
                    this.showInvited(response.value.data);
                    this.setState({
                        loadingInvited: false,
                        totalPagesInvited: response.value.headers['x-pagination-page-count']
                    });
                })
                .catch(() => {
                    // ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message)||err.message)
                    this.setState({ loadingInvited: false });
                });
        }
    }

    loadMoreInvited = () => {
        this.setState(prevState => ({
            pageInvited: prevState.pageInvited + 1,
            loadingInvited: true
        }), this.loadInvited);
    }

    loadPassed = () => {
        if (this.props.credentials?.token) {
            const filters = 'islive=false&onlymine=true&filter[owner_id][neq]=' + this.props.credentials.user_id + '&per-page=5&page=' + this.state.pagePassed;
            this.props.dispatch(getPlaydates(this.props.credentials.token, false, filters))
                .then(response => {
                    this.showPassed(response.value.data);
                    this.setState({
                        loadingPassed: false,
                        totalPagesPassed: response.value.headers['x-pagination-page-count']
                    });
                })
                .catch(() => {
                    // ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message)||err.message)
                    this.setState({ loadingPassed: false });
                });
        }
    }

    loadMorePassed = () => {
        this.setState(prevState => ({
            pagePassed: prevState.pagePassed + 1,
            loadingPassed: true
        }), this.loadPassed);
    }

    showInvited(playdates) {
        if (!!playdates && playdates.length > 0) {
            const myPlaydatesList = playdates.map(playdate => (
                <PlaydayeManageList
                    key={playdate.playdate_id}
                    playdate={playdate}
                    type="myInvitations"
                />
            ));
            this.setState(prevState => ({
                invited: [...prevState.invited, myPlaydatesList]
            }));
        }
    }

    showPublic(playdates) {
        const myPlaydatesList = playdates.map(playdate => (
            <PlaydateCards
                key={playdate.playdate_id}
                playdate={playdate}
            />
        ));
        this.setState({
            public: myPlaydatesList
        });
    }

    showPassed(playdates) {
        if (!!playdates && playdates.length > 0) {
            const myPlaydatesList = playdates.map(playdate => (
                <PlaydayeManageList key={playdate.playdate_id} playdate={playdate} type="pastPlaydates" />
            ));
            this.setState(prevState => ({
                passed: [...prevState.passed, myPlaydatesList]
            }));
        }
    }

    render() {
        /*
        let isLoading = this.state.loadingInvited || this.state.loadingPassed
        let hasPlaydaytes = this.state.invited.length > 0 || this.state.passed.length > 0
        */
        let playdateTitle = 'All Events';
        let playdateButtonText = 'Show Local';
        let playdateButtonValue = 100;
        if (this.props.playdateFilters.distance !== 'all') {
            playdateTitle = 'Nearby Events';
            playdateButtonText = 'Show All';
            playdateButtonValue = 'all';
        }

        const pageDescription = 'Create and Join Events near your location';

        const c1 = (
            <div className="simple-navigation">
                <PlaydateNavigation
                    selected="events"
                />
            </div>
        );

        const c2 = (
            <div>
                <div className="scrollableBannersHolder">
                    <h3 className="fontBlack scrollTitle wrapper row space-between">
                        <div>
                            {playdateTitle}
                        </div>
                        <div>
                            <span className="h6 fontGreen" onClick={() => this.changeDistance(playdateButtonValue)}>{playdateButtonText}</span>
                        </div>
                    </h3>
                    {/* only show the spinner when it is initially loading, or it is reloading a different set, it must not have any pd */}
                    {(this.state.reloadingPublic || this.state.loadingPublic) && (!this.state.public || (this.state.public && this.state.public.length === 0))
                        && (<Spinner />)}
                    {/* show the list once it has pd and it is not reloading */}
                    {this.state.public && this.state.public.length > 0 && (
                        <React.Fragment>
                            <div className={'carousel-holder ' + (this.state.public.length === 1 ? ' one-playdate' : '')}>
                                <Carousel
                                    autoPlay={false}
                                    onChange={this.loadMorePublic}
                                    showIndicators={false}
                                    infiniteLoop={true}
                                    selectedItem={this.state.selectedCard}
                                    centerMode={this.state.public.length > 1}
                                    emulateTouch
                                    showThumbs={false}
                                >
                                    {this.state.public}
                                </Carousel>
                            </div>
                        </React.Fragment>
                    )}
                    {!this.state.reloadingPublic && !this.state.loadingPublic && (!this.state.public || this.state.public.length === 0) && (
                        <div className="wrapper not-found">
                            <NotFound type="playdates" />
                        </div>
                    )}
                </div>
                {this.state.invited.length > 0 && (
                    <React.Fragment>
                        <div className="boxContainer">
                            <div className="boxTitle row">
                                <div className="title">
                                    You are invited to
                                </div>
                            </div>
                        </div>
                        {this.state.invited}
                        {(this.state.pageInvited < this.state.totalPagesInvited) && (
                            <div className="maxWidth wrapper noPaddingTop">
                                <div className="fontGreen alignRight" onClick={this.loadMoreInvited}>View More Invitations</div>
                            </div>
                        )}
                    </React.Fragment>
                )}
                {this.props.credentials?.token && this.state.loadingInvited && (
                    <Spinner />
                )}
                {this.state.invited.length > 0 && this.state.passed.length > 0 && (
                    <div className="spacer" />
                )}
                {this.state.passed.length > 0 && (
                    <React.Fragment>
                        <div className="boxContainer">
                            <div className="boxTitle row">
                                <div className="title">
                                    Past Playdates
                                </div>
                            </div>
                        </div>
                        {this.state.passed}
                        {(this.state.pagePassed < this.state.totalPagesPassed) && (
                            <div className="maxWidth wrapper noPaddingTop">
                                <div className="fontGreen alignRight" onClick={this.loadMorePassed}>View More Past Playdates</div>
                            </div>
                        )}
                    </React.Fragment>
                )}
                {this.props.credentials?.token && this.state.loadingPassed && (
                    <Spinner />
                )}
            </div>
        );

        const c3 = (
            <React.Fragment>
                <InviteFriends wrapperClass="noWrapper" />
                <FriendRequestsComponent limit={3} infiniteScroll={false} viewMoreButton={true} />
                <WhiteTitle>Suggested Friends</WhiteTitle>
                <FindFriendsComponent perPage={3} infiniteScroll={false} />
                <Link to="/findfriends" className="square-button greenBG fontWhite h6">View all</Link>
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <HelmetTags title={playdateTitle || ''} description={pageDescription || ''} />
                <div className="mainAppContainer playdates ">
                    <div className="bodyContent playdateshome">
                        <div className="three-column-layout">
                            <Columns
                                c1={c1}
                                c2={c2}
                                c3={c3}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        playdateFilters: state.playdates.filters
    };
}

export default connect(mapStateToProps)(PlaydatesHome);
