import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Textarea from 'react-textarea-autosize';
import ReactGA from 'react-ga';
import parse from 'html-react-parser';

import { userDelete, logout, updateUser, refreshOwnProfile, setUserSocialMedia, setUserBio } from '../../redux/actions/users';
import { uploadPicture, deletePicture, setPicture } from '../../redux/actions/all';
import sb from '../../helpers/SendBird';
import Config from '../../helpers/Config';
import { camera, cameraRoll, cameraMultiRoll, cameraBlob } from '../../helpers/Camera';
import { guard } from '../../helpers/Functions';
import ApiError from '../../helpers/ApiError';
import Avatar from '../../layout/UI/Avatar';
import CameraModal from '../../layout/CameraModal';
import GalleryLightbox from '../../layout/gallery/GalleryLightbox';
import Columns from '../../hoc/Columns';
import WhiteTitle from '../../hoc/WhiteTitle';
import PetList from '../../layout/PetList';
import AdvancedConfirm from '../../layout/UI/AdvancedConfirm';
import Spinner from '../../layout/UI/Spinner';

/**
 * @param {function} dispatch function used to hit end-points
 * @param {Object} credentials a JSON Object with the credentials of the logged user
 * @param {} images
 * @param {Object} userData a JSON Object with the information of the logged user
 * @param {Object} user a JSON Object with the information of the user to show
 * @param {array} dogs array of Objects with the dogs that the user displayed has added
 * @param {Object} history react-router Object used to get information from the browser history and/or to have access to some functions
 */
export class OwnProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            image: null,
            // dogs: null,
            fname: false,
            lname: false,
            email: false,
            phone: false,
            socialMedia: {},
            bio: false,
            playdateTime: false,
            changed: false,
            gallery: [],
            pictureModal: false,
            pictureModal2: false,
            entity_poochid: '',
            loadingDeleteAccount: false
        };
    }

    componentDidMount() {
        this.props.dispatch(refreshOwnProfile(this.props.credentials.token, this.props.credentials.user_id));
        this.setState({
            user: this.props.user,
            // dogs: this.props.dogs,
            image: guard(this.props.user, '.images.0.image_mediumurl') || null,
            gallery: this.props.user.images,
            socialMedia: this.props.user.user_socialmedia,
            bio: this.props.user.user_aboutme,
            entity_poochid: ((this.props.user && this.props.user.parententity && this.props.user.parententity.entity_poochid) || '')
        });
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    filesChanged = files => {
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            const file = files[i];
            reader.onloadend = () => {
                this.props.dispatch(uploadPicture(this.props.credentials.token, this.props.user.user_id, file, true))
                    .then(response => {
                        this.props.dispatch(refreshOwnProfile(this.props.credentials.token, this.props.credentials.user_id));
                        this.updateThumbs(response, true);
                    });
            };
            reader.readAsDataURL(file);
        }
    }

    galleryfiles = files => {
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            const file = files[i];
            reader.onloadend = () => {
                this.props.dispatch(uploadPicture(this.props.credentials.token, this.props.user.user_id, file, false))
                    .then(response => this.updateThumbs(response));
            };
            reader.readAsDataURL(file);
        }
    }

    getDogNames = dogs => {
        const dogNames = [];
        for (const i in dogs) {
            dogNames.push(dogs[i].dog_name);
        }
        return (<span>{dogNames.join(', ')}</span>);
    }

    handlePoochID = e => {
        const poochId = e.target.value.split('pooch.pet/');
        let text = '';
        if (poochId.length > 1) {
            text = poochId[1].replace(/[^a-z0-9_-]+/gi, '').toLowerCase();
        }
        this.setState({
            changed: true,
            entity_poochid: text
        });
    }

    handleChange = event => {
        this.setState({
            changed: true,
            [event.target.name]: event.target.value
        });
    }

    updateSendBird = () => {
        const that = this;
        sb.connect(
            that.props.credentials.user_id,
            that.props.credentials.token,
            () => {
                sb.updateCurrentUserInfo(
                    that.props.userData.user_firstname + ' ' + that.props.userData.user_lastname,
                    guard(that.props.userData, '.images.0.image_smallurl') || '',
                    () => { }
                );
            }
        );
    }

    saveChanges = e => {
        e.preventDefault();
        const that = this;
        const changes = {};
        if (this.state.fname) {
            changes.user_firstname = this.state.fname;
        }
        if (this.state.lname !== false) {
            changes.user_lastname = this.state.lname;
        }
        if (this.state.phone !== false) {
            changes.user_phone = this.state.phone;
        }
        if (this.state.email !== false) {
            if (!/\S+@\S+\.\S+/.test(this.state.email)) {
                ApiError('Please verify your Email!');
                return;
            }
            changes.user_email = this.state.email;
        }
        if (this.state.socialMedia !== false) {
            changes.user_socialmedia = this.state.socialMedia;
        }
        if (this.state.bio !== false) {
            changes.user_aboutme = this.state.bio;
        }
        if (this.state.playdateTime !== false) {
            changes.user_primaryhours = this.state.playdateTime;
        }
        changes.entity_poochid = this.state.entity_poochid;
        if (Object.keys(changes).length && this.state.changed) {
            this.props.dispatch(updateUser(this.props.credentials.token, this.props.credentials.user_id, changes))
                .then(() => {
                    toast.success('Saved!');
                    that.updateSendBird();
                    that.setState({ changed: false });
                })
                .catch(err => {
                    ApiError(err);
                });
        } else {
            toast.info('No changes to save.');
        }
    }

    toggleModal = () => {
        this.setState({
            pictureModal: !this.state.pictureModal,
            changed: true
        });
    }

    toggleModal2 = () => {
        this.setState({
            pictureModal2: !this.state.pictureModal2,
            changed: true
        });
    }

    changeImage = () => {
        if (Config.isMobileApp) {
            this.toggleModal();
        } else {
            const selectfile = document.getElementById('file');
            selectfile.click();
        }
    }

    // Removes image from GalleryLightBox
    handleClickRemoveImage = id => {
        this.props.dispatch(deletePicture(this.props.credentials.token, id))
            .then(() => {
                this.setState(prevState => {
                    const newGallery = prevState.gallery.filter(image => image.image_id !== id);
                    return {
                        gallery: newGallery
                    };
                }, () => {
                    this.setState({ image: this.state.gallery && this.state.gallery[0] && this.state.gallery[0].image_largeurl ? this.state.gallery[0].image_largeurl : null });
                });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    handleClickSetImage = id => {
        this.props.dispatch(setPicture(this.props.credentials.token, id))
            .then(res => {
                let newFeaturedImage = {};
                // we push the new featured image to the top of the list, on the first position
                this.setState(prevState => {
                    const newGallery = prevState.gallery.filter(image => {
                        if (image.image_id === id) newFeaturedImage = image;
                        return image.image_id !== id;
                    });
                    newGallery.sort((a, b) => b.image_id - a.image_id);
                    return {
                        gallery: [newFeaturedImage, ...newGallery],
                        image: res.value.data.image_largeurl
                    };
                });
                toast.success('Profile Image Set Successfully!');
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    addToGallery = e => {
        e.preventDefault();
        if (Config.isMobileApp) {
            this.toggleModal2();
        } else {
            const selectfile = document.getElementById('galleryfile');
            selectfile.click();
        }
    }

    profileCamera = () => {
        camera(this.profileSuccess, 1);
    }

    profileUpload = () => {
        cameraRoll(this.profileSuccess);
    }

    profileSuccess = file => {
        this.setState({ image: file, pictureModal: false });
        toast('Uploading Picture');
        cameraBlob(file, this.uploadToProfile);
    }

    uploadToProfile = imgBlob => {
        this.props.dispatch(uploadPicture(this.props.credentials.token, this.props.user.user_id, imgBlob, true))
            .then(response => {
                this.updateSendBird();
                this.props.dispatch(refreshOwnProfile(this.props.credentials.token, this.props.credentials.user_id));
                this.updateThumbs(response);
            })
            .catch(err => ApiError(err && err.message ? err.message : 'There was an error uploading'));
    }

    galleryCamera = () => {
        camera(this.gallerySuccess);
    }

    galleryUpload = () => {
        cameraMultiRoll(this.gallerySuccess);
    }

    gallerySuccess = file => {
        cameraBlob(file, this.uploadToGallery);
    }

    uploadToGallery = imgBlob => {
        this.props.dispatch(uploadPicture(this.props.credentials.token, this.props.credentials.user_id, imgBlob, false))
            .then(response => this.updateThumbs(response));
    }

    updateThumbs = (response, featured) => {
        if (featured) {
            this.setState({ image: response.value.data.image_mediumurl });
        }
        this.setState(prevState => ({
            pictureModal: false,
            pictureModal2: false,
            gallery: [response.value.data, ...prevState.gallery]
        }));
        if (!!navigator && !!navigator.camera && !!navigator.camera.cleanup) {
            navigator.camera.cleanup();
        }
    }

    socialMediaHandler = event => {
        this.setState({
            changed: true,
            socialMedia: { ...this.state.socialMedia, [event.target.name]: event.target.value }
        }, () => {
            this.props.dispatch(setUserSocialMedia(this.state.socialMedia));
        });
    }

    userBio = e => {
        this.setState({
            changed: true,
            [e.target.name]: e.target.value
        }, () => {
            this.props.dispatch(setUserBio(this.state.bio));
        });
    }

    deleteAccount = (token, userId) => {
        const headerText = 'Are you sure you want to close your account?';
        const bodyText = 'If there\'s anything we can help you with, please <a href="' + process.env.REACT_APP_EMAIL_SUPPORT + '">contact us</a>. Once it\'s closed, you won\'t be able to use this Pooch account, and all your account data and uploaded pictures will be removed. It can\'t be reopened, and we can\'t restore it. Please type "REMOVE ACCOUNT" below to confirm.';
        AdvancedConfirm(
            headerText,
            parse(bodyText),
            'REMOVE ACCOUNT',
            'Remove it',
            () => {
                console.log('Deleting account:', userId);
                this.setState({ loadingDeleteAccount: true });
                this.props.dispatch(userDelete(token, userId))
                    .then(() => {
                        toast.success('Account deleted successfully.');
                        this.props.dispatch(logout());
                        this.setState({ loadingDeleteAccount: false });
                        this.props.history.push('/');
                    })
                    .catch(err => {
                        console.log('deleteAccount:err:', err);
                        this.setState({ loadingDeleteAccount: false });
                        ApiError('Something went wrong... Please try again later.');
                    });
            }
        );
    };

    render() {
        const column1 = (
            <div className="user-dog-list-desktop">
                <WhiteTitle>Dog(s)</WhiteTitle>
                <PetList dogs={this.props.dogs} />
                <Link to="/user/dogadd" className="square-button greenBG fontWhite h6">Add Dog</Link>
            </div>
        );

        const column2 = (
            <div className="profileDetails maxWidth noPadding">
                <React.Fragment>
                    <WhiteTitle>Pictures
                        <div className="image-options">
                            <input name="galleryfile" className="hidden" id="galleryfile" type="file" onChange={e => this.galleryfiles(e.target.files)} accept="image/*" required multiple />
                            {this.state.gallery.length > 0 && (
                                <Link to={'/user/profile/' + this.props.user.user_id + '/gallery'} className="fontGreen"> <i className="icn icon-gallery" /> View Gallery </Link>
                            )}
                            <Link to="#" onClick={e => { this.addToGallery(e); }} className="fontGreen">
                                <i className="icn icon-upload-photo" /> Add Pictures
                            </Link>
                        </div>
                    </WhiteTitle>
                    {this.state.gallery.length > 0 && (
                        <div className="galleryHolder">
                            <GalleryLightbox
                                dispatch={this.props.dispatch}
                                credentials={this.props.credentials}
                                userData={this.props.userData}
                                images={this.state.gallery}
                                count={4}
                                size="normal"
                                handleClickRemoveImage={this.handleClickRemoveImage}
                                handleClickSetImage={this.handleClickSetImage}
                            />
                            {this.state.gallery.length > 4 && (
                                <div className="holderEnclosed">
                                    <Link to={'/user/profile/' + this.props.userData.user_id + '/gallery'}>+ {this.state.gallery.length - 4} More</Link>
                                </div>
                            )}
                        </div>
                    )}
                </React.Fragment>
                <form onSubmit={this.saveChanges}>
                    <WhiteTitle>Pooch ID</WhiteTitle>
                    <div className="profileDetails maxWidth">
                        <div className="userSocialMedia">
                            <ul>
                                <li className="socialMediaListItem">
                                    <div>
                                        <label className="fontBlack h6">Create your unique Pooch Link</label><br />
                                        <input className="transparentInput" name="entity_poochid" value={'pooch.pet/' + this.state.entity_poochid} onChange={this.handlePoochID} />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <WhiteTitle>About Me</WhiteTitle>
                    <div className="profileDetails maxWidth">
                        <Textarea
                            onChange={e => this.userBio(e)}
                            className="about-me-textarea"
                            placeholder="Write a few sentences about yourself!"
                            name="bio"
                            value={this.state.bio || ''}
                        />
                    </div>
                    <div className="spacerSmall" />
                    <WhiteTitle>Details</WhiteTitle>
                    <div className="profileDetails maxWidth">
                        <ul className="listDivided rightIcon">
                            <li>
                                <div className="input-user-first-name">
                                    <label className="fontBlack h6">First Name</label><br />
                                    <input className="transparentInput" name="fname" value={this.state.fname === false ? this.state.user.user_firstname || '' : this.state.fname} onChange={e => this.handleChange(e)} />
                                </div>
                            </li>
                            <li>
                                <div className="input-user-last-name">
                                    <label className="fontBlack h6">Last Name</label><br />
                                    <input className="transparentInput" name="lname" value={this.state.lname === false ? this.state.user.user_lastname || '' : this.state.lname} onChange={e => this.handleChange(e)} />
                                </div>
                            </li>
                            <li>
                                <div className="input-user-email">
                                    <label className="fontBlack h6">Email</label><br />
                                    <input className="transparentInput" type="email" name="email" value={this.state.email === false ? this.state.user.user_email || '' : this.state.email} onChange={e => this.handleChange(e)} />
                                </div>
                            </li>
                            <li>
                                <div className="input-user-phone">
                                    <label className="fontBlack h6">Phone Number</label><br />
                                    <input className="transparentInput" type="tel" name="phone" value={this.state.phone === false ? this.state.user.user_phone || '' : this.state.phone} onChange={e => this.handleChange(e)} />
                                </div>
                            </li>
                            <li>
                                <Link to="/user/address" className="fontBlack">
                                    <div className="input-user-address">
                                        <label className="fontBlack h6">Address</label><br />
                                        {this.props.userData && this.props.userData.address_id && this.props.userData.address
                                            ? (
                                                <span>
                                                    {this.props.userData && this.props.userData.address && this.props.userData.address && this.props.userData.address.address_city}
                                                </span>
                                            )
                                            : (
                                                <span>
                                                    Please add your location
                                                </span>
                                            )}
                                    </div>
                                    <i className="icn icon-right-arrow fontGreen" />
                                </Link>
                            </li>
                            <li>
                                <Link to="/user/park" className="fontBlack">
                                    <div className="select-favorite-park">
                                        <label className="fontBlack h6">Favorite Park</label><br />
                                        {(!!this.state.user && !!this.state.user.primarylocation && this.state.user.primarylocation.location_name) || 'Select a Location'}
                                    </div>
                                    <i className="icn icon-right-arrow fontGreen" />
                                </Link>
                            </li>
                            <li>
                                <div className="select-playdate-time">
                                    <label className="fontBlack h6">When are you available for Events?</label><br />
                                    <input className="transparentInput" name="playdateTime" value={this.state.playdateTime === false ? this.state.user.user_primaryhours || '' : this.state.playdateTime} onChange={e => this.handleChange(e)} placeholder="e.g. Weekdays after 6pm" />
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="spacerSmall" />
                    <WhiteTitle>Social Media</WhiteTitle>
                    <div className="profileDetails ">
                        <div className="userSocialMedia">
                            <ul>
                                <li className="socialMediaListItem row">
                                    <i className="icn icon-facebook fontGreen" />
                                    <div>
                                        <input
                                            className="transparentInput"
                                            type="text"
                                            name="facebook"
                                            placeholder="Facebook Link"
                                            onChange={this.socialMediaHandler}
                                            value={(this.state.socialMedia && this.state.socialMedia.facebook === undefined) ? (this.props.user && this.props.user.user_socialmedia && this.props.user.user_socialmedia.facebook) || '' : (this.state.socialMedia && this.state.socialMedia.facebook) || ''}
                                        />
                                    </div>
                                </li>
                                <li className="socialMediaListItem row">
                                    <i className="icn icon-twitter fontGreen" />
                                    <div>
                                        <input
                                            className="transparentInput"
                                            type="text"
                                            name="twitter"
                                            placeholder="Twitter Link"
                                            onChange={this.socialMediaHandler}
                                            value={(this.state.socialMedia && this.state.socialMedia.twitter === undefined) ? (this.props.user && this.props.user.user_socialmedia && this.props.user.user_socialmedia.twitter) || '' : (this.state.socialMedia && this.state.socialMedia.twitter) || ''}
                                        />
                                    </div>
                                </li>
                                <li className="socialMediaListItem row">
                                    <i className="icn icon-instagram fontGreen" />
                                    <div>
                                        <input
                                            className="transparentInput"
                                            type="text"
                                            name="instagram"
                                            placeholder="Instagram Link"
                                            onChange={this.socialMediaHandler}
                                            value={(this.state.socialMedia && this.state.socialMedia.instagram === undefined) ? (this.props.user && this.props.user.user_socialmedia && this.props.user.user_socialmedia.instagram) || '' : (this.state.socialMedia && this.state.socialMedia.instagram) || ''}
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="spacerSmall" />
                    {this.state.loadingDeleteAccount
                        ? (
                            <Spinner />
                        )
                        : (
                            <React.Fragment>
                                <input
                                    type="button"
                                    value="Remove Account"
                                    className="leftRoundButton redBorder redBG fontWhite"
                                    onClick={() => this.deleteAccount(this.props.credentials?.token, this.props.userData?.user_id)}
                                />
                                <input
                                    type="submit"
                                    value="Save Changes"
                                    className="rightRoundButton greenBorder greenBG fontWhite"
                                />
                                <Link to={'/user/profile/' + this.props.userData.user_id} className="rightRoundButton greenBorder whiteBG fontGreen">
                                    View Profile
                                </Link>
                            </React.Fragment>
                        )}
                </form>
            </div>
        );

        return (
            <React.Fragment>
                {this.state.pictureModal && (
                    <CameraModal toggleModal={this.toggleModal} callbackCamera={this.profileCamera} callbackUpload={this.profileUpload} />
                )}
                {this.state.pictureModal2 && (
                    <CameraModal toggleModal={this.toggleModal2} callbackCamera={this.galleryCamera} callbackUpload={this.galleryUpload} />
                )}
                <div className="profilePictureWrapper user edit">
                    <input className="hidden" id="file" type="file" onChange={e => this.filesChanged(e.target.files)} accept="image/*" required />
                    <div className="fontWhite wrapper alignCenter">
                        <div className="round-avatar user huge">
                            <Avatar
                                src={this.state.image}
                                alt={this.state.user.user_firstname + ' ' + this.state.user.user_lastname}
                                onClick={this.changeImage}
                                className="changeImage"
                                type="user"
                            />
                        </div>
                        <div onClick={() => { this.changeImage(); }} className="changeImage spacerVertical">Change Profile Picture</div>
                    </div>
                </div>
                {this.props.user && (
                    <div className="two-column-layout">
                        <Columns
                            c1={column1}
                            c2={column2}
                        />
                    </div>
                )}
            </React.Fragment>
        );
    }
}

OwnProfile.propTypes = {
    dogs: PropTypes.instanceOf(Array),
    user: PropTypes.instanceOf(Object).isRequired,
    userData: PropTypes.instanceOf(Object),
    credentials: PropTypes.instanceOf(Object).isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.instanceOf(Object).isRequired
};
