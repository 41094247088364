import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { toast } from 'react-toastify';

import { getDogProfile, getDogBreeds, updateDog } from '../../redux/actions/dogs';
import ApiError from '../../helpers/ApiError';

class UserDogEditBreed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            breeds: [],
            breedSearch: '',
            dog_breeds: [],
            list_selected_breeds: []
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        this.props.dispatch(getDogBreeds(this.props.credentials.token))
            .then(() => this.props.dispatch(getDogProfile(this.props.credentials.token, this.props.match.params.id))
                .then(() => this.setDogBreeds()));
    }

    setDogBreeds() {
        const arrayBreeds = this.props.dogProfile.breeds.map(item => item.breed_id);
        this.setState({
            dog_breeds: arrayBreeds
        }, () => this.mapBreeds());
    }

    save = e => {
        e.preventDefault();
        if (!this.state.dog_breeds || !this.state.dog_breeds.length) {
            ApiError('Please select at least one breed!');
        } else {
            const finalBreeds = [];
            for (const i in this.state.dog_breeds) {
                finalBreeds.push({ breed_id: this.state.dog_breeds[i] });
            }
            this.props.dispatch(updateDog(this.props.credentials.token, this.props.match.params.id, { breeds: finalBreeds }))
                .then(() => {
                    toast.success('Updated breeds correctly');
                    this.props.history.goBack();
                })
                .catch(err => {
                    ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                });
        }
    }

    toggleBreeds(breed_id) {
        let listBreeds = this.state.dog_breeds;
        if (listBreeds.indexOf(breed_id) !== -1) {
            listBreeds = listBreeds.filter(item => item !== breed_id);
        } else {
            listBreeds.push(breed_id);
        }
        this.setState({ dog_breeds: listBreeds }, () => this.mapBreeds(this.state.breedSearch));
    }

    mapBreeds(search = '') {
        const selectedBreeds = [];
        this.setState({ breedSearch: search });
        if (this.props.breeds.length) {
            // list of all the breeds
            const breedlist = this.props.breeds.map(breed => {
                // first don't show the list of all breeds
                let showBreedBox = null;
                // if any characters in the breed name match the characters in the list then proceed
                if (breed.breed_name.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                    let selectedClass = '';
                    // if breeds exist and the breed id exist then set selectedClass to 'selected'
                    if (this.state.dog_breeds && this.state.dog_breeds.indexOf(breed.breed_id) !== -1) {
                        selectedClass = 'selected';
                    }
                    // set the showBreedBox variable to equal this new DOM element
                    showBreedBox = (
                        <div className={selectedClass} key={breed.breed_id} onClick={e => { e.target.classList.toggle('selected'); this.toggleBreeds(breed.breed_id); }}>
                            {breed.breed_name}
                        </div>
                    );
                }
                if (this.state.dog_breeds && this.state.dog_breeds.indexOf(breed.breed_id) !== -1) {
                    selectedBreeds.push(
                        <li key={breed.breed_id} className="dogBreed" id={breed.breed_id}>
                            {breed.breed_name}
                            <div className="removeIcon" onClick={() => this.toggleBreeds(breed.breed_id)}><i className="icn icon-cross" /> </div>
                        </li>
                    );
                }
                return showBreedBox;
            });
            this.setState({ breeds: breedlist });
        }
        this.setState({
            list_selected_breeds: selectedBreeds
        });
    }

    removeBreeds() {
        const grid = document.getElementById('grid');
        // changes childnode previously selected to unselected('')
        grid.childNodes.forEach(item => {
            if (item.className === 'selected') {
                item.classList.toggle('selected');
            }
        });
        this.setState({
            dog_breeds: [],
            list_selected_breeds: []
        });
    }

    render() {
        return (
            <div className="mainAppContainer LoginScreens">
                <div className="bodyContent backgroundGreen">
                    <div className="LoginWrapper wrapper maxWidth">
                        <div className="flexButtons whiteBG fontGreen" onClick={this.save}>Save Changes</div>
                        <h1 className="section">What breed is {this.props.dogProfile.dog_name}?</h1>
                        {!!this.props.dogProfile.breeds && (
                            <div className="breedSelected">
                                <ul className="breeds">
                                    {this.state.list_selected_breeds}
                                    {this.state.list_selected_breeds.length > 1 && (
                                        <div className="removeBreeds" onClick={e => this.removeBreeds(e)}><p>Remove All</p></div>
                                    )}
                                </ul>
                            </div>
                        )}
                        <div className="simpleSearchBar row breedSearch"><i className="icn icon-search fontDarkGray" />
                            <input value={this.state.breedSearch} onChange={e => this.mapBreeds(e.target.value)} placeholder="Poodle, Pug, etc..." />
                            <span className={'hover ' + (this.state.breedSearch ? 'fontRed' : 'fontGray')} onClick={() => this.mapBreeds()}>Cancel</span>
                        </div>
                        <div className="tableGrid" id="grid">
                            {this.state.breeds}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        dogProfile: state.dogs.dogProfile,
        breeds: state.dogs.breeds
    };
}

export default connect(mapStateToProps)(UserDogEditBreed);
