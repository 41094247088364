import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import { PlaydateNavigation } from '../../layout/navigation/PlaydateNavigation';
import PlaydayeManageList from './PlaydatesManageList';
import { getPlaydates, playdateFilter_distance } from '../../redux/actions/playdates';
import Spinner from '../../layout/UI/Spinner';
import PlaydateCards from './PlaydateCards';
import { getGeoLocation } from '../../helpers/GeoLocation';
import Config from '../../helpers/Config';
import ApiError from '../../helpers/ApiError';

export class PlaydatesHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            public: false,
            publicArray: [],
            invited: [],
            passed: [],
            loadingPublic: true,
            reloadingPublic: false,
            loadingInvited: true,
            loadingPassed: true,
            pagePublic: 1,
            pageInvited: 1,
            pagePassed: 1,
            totalPagesPublic: 1,
            totalPagesInvited: 1,
            totalPagesPassed: 1
        };
    }

    getGeoLocation = getGeoLocation.bind(this)

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getGeoLocation(this.props, this.loadPublic, this.props.credentials.token, true);
        this.loadInvited();
        this.loadPassed();
        if (!Config.isMobileDevice) { window.dragscroll.reset(); }
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    loadPublic = () => {
        let distanceFilter = '';
        if ((this.props.credentials && this.props.credentials.token) || this.props.playdateFilters.distance) {
            distanceFilter = this.props.playdateFilters.distance === 'all' ? '' : '&filter[distance][lte]=' + (this.props.playdateFilters.distance || 100);
        }
        const filters = 'islive=true&onlymine=false&per-page=5&page=' + this.state.pagePublic + distanceFilter;
        const mylocation = (this.props.userData && this.props.userData.address && this.props.userData.address.address_latitude && this.props.userData.address) || (this.props.userData && this.props.userData.user_location && this.props.userData.user_location.address_latitude && this.props.userData.user_location) || null;
        this.props.dispatch(getPlaydates(this.props.credentials.token, mylocation, filters, true))
            .then(response => {
                this.setState(prevState => ({
                    publicArray: [...prevState.publicArray, ...response.value.data],
                    loadingPublic: false,
                    reloadingPublic: false,
                    totalPagesPublic: response.value.headers['x-pagination-page-count']
                }), () => this.showPublic(this.state.publicArray));
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loadingPublic: false, reloadingPublic: false });
            });
    }

    loadMorePublic = () => {
        if (this.state.pagePublic < this.state.totalPagesPublic) {
            this.setState(prevState => ({
                pagePublic: prevState.pagePublic + 1,
                loadingPublic: true
            }), this.loadPublic);
        } else {
            ApiError('No more events to show');
        }
    }

    changeDistance = distance => {
        this.props.dispatch(playdateFilter_distance(distance));
        this.setState({
            public: null,
            publicArray: [],
            reloadingPublic: true,
            pagePublic: 1,
            totalPagesPublic: 1
        }, this.loadPublic);
    }

    loadInvited = () => {
        const filters = 'islive=true&onlymine=true&filter[owner_id][neq]=' + this.props.credentials.user_id + '&per-page=5&page=' + this.state.pageInvited;
        this.props.dispatch(getPlaydates(this.props.credentials.token, false, filters))
            .then(response => {
                this.showInvited(response.value.data);
                this.setState({
                    loadingInvited: false,
                    totalPagesInvited: response.value.headers['x-pagination-page-count']
                });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loadingInvited: false });
            });
    }

    loadMoreInvited = () => {
        this.setState(prevState => ({
            pageInvited: prevState.pageInvited + 1,
            loadingInvited: true
        }), this.loadInvited);
    }

    loadPassed = () => {
        const filters = 'islive=false&onlymine=true&filter[owner_id][neq]=' + this.props.credentials.user_id + '&per-page=5&page=' + this.state.pagePassed;
        this.props.dispatch(getPlaydates(this.props.credentials.token, false, filters))
            .then(response => {
                this.showPassed(response.value.data);
                this.setState({
                    loadingPassed: false,
                    totalPagesPassed: response.value.headers['x-pagination-page-count']
                });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loadingPassed: false });
            });
    }

    loadMorePassed = () => {
        this.setState(prevState => ({
            pagePassed: prevState.pagePassed + 1,
            loadingPassed: true
        }), this.loadPassed);
    }

    showPublic(playdates) {
        const myPlaydatesList = playdates.map(playdate => (
            <PlaydateCards
                key={playdate.playdate_id}
                playdate={playdate}
            />
        ));
        this.setState({ public: myPlaydatesList });
    }

    showInvited(playdates) {
        if (!!playdates && playdates.length > 0) {
            const myPlaydatesList = playdates.map(playdate => (<PlaydayeManageList key={playdate.playdate_id} playdate={playdate} type="myInvitations" />));
            this.setState(prevState => ({ invited: [...prevState.invited, myPlaydatesList] }));
        }
    }

    showPassed(playdates) {
        if (!!playdates && playdates.length > 0) {
            const myPlaydatesList = playdates.map(playdate => (<PlaydayeManageList key={playdate.playdate_id} playdate={playdate} type="pastPlaydates" />));
            this.setState(prevState => ({ passed: [...prevState.passed, myPlaydatesList] }));
        }
    }

    render() {
        /*
        let isLoading = this.state.loadingInvited || this.state.loadingPassed
        let hasPlaydaytes = this.state.invited.length > 0 || this.state.passed.length > 0
        */

        let playdateTitle = 'All Events';
        let playdateButtonText = 'Show Local';
        let playdateButtonValue = 100;
        if (this.props.playdateFilters.distance !== 'all') {
            playdateTitle = 'Nearby Events';
            playdateButtonText = 'Show All';
            playdateButtonValue = 'all';
        }

        return (
            <div className="mainAppContainer playdates">
                <MainNavigation title="Events" />
                <PlaydateNavigation selected="events" />
                <div className="bodyContent pull2refresh playdateshome">
                    {/* isLoading === false && hasPlaydaytes === false && (<NotFound type={'playdates'} />) */}
                    <div className="maxWidth">
                        <div className="scrollableBannersHolder">
                            <h3 className="fontBlack scrollTitle wrapper row space-between">
                                <div>
                                    {playdateTitle}
                                </div>
                                <div>
                                    <span className="h6 fontGreen" onClick={() => this.changeDistance(playdateButtonValue)}>{playdateButtonText}</span>
                                </div>
                            </h3>
                            {/* only show the spinner when it is initially loading, or it is reloading a different set, it must not have any pd */}
                            {(this.state.reloadingPublic || this.state.loadingPublic) && (!this.state.public || (this.state.public && this.state.public.length === 0)) && (
                                <Spinner />
                            )}
                            {/* show the list once it has pd and it is not reloading */}
                            {this.state.public && this.state.public.length > 0 && (
                                <div className="scrollableBanners dragscroll">
                                    {this.state.public}
                                    {this.state.pagePublic < this.state.totalPagesPublic && !this.state.loadingPublic && (
                                        <div className="playdateCards viewMore" onClick={this.loadMorePublic}>
                                            <p className="viewmore flexSmallestButtons transparentBG fontWhite">Load More</p>
                                        </div>
                                    )}
                                    {this.state.loadingPublic && (
                                        <div className="playdateCards viewMore">
                                            <Spinner />
                                        </div>
                                    )}
                                </div>
                            )}
                            {!this.state.reloadingPublic && !this.state.loadingPublic && (!this.state.public || this.state.public.length === 0) && (
                                <div className="wrapper">
                                    <h4 className="fontGreen">No events to show</h4>
                                </div>
                            )}
                        </div>
                        {this.state.invited.length > 0 && (
                            <React.Fragment>
                                <div className="boxContainer">
                                    <div className="boxTitle row">
                                        <div className="title">
                                            You are invited to
                                        </div>
                                    </div>
                                </div>
                                {this.state.invited}
                                {(this.state.pageInvited < this.state.totalPagesInvited) && (
                                    <div className="maxWidth wrapper noPaddingTop">
                                        <div className="fontGreen alignRight" onClick={this.loadMoreInvited}>View More Invitations</div>
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                        {this.state.loadingInvited && (
                            <Spinner />
                        )}
                        {this.state.invited.length > 0 && this.state.passed.length > 0 && (
                            <div className="spacer" />
                        )}
                        {this.state.passed.length > 0 && (
                            <React.Fragment>
                                <div className="boxContainer">
                                    <div className="boxTitle row">
                                        <div className="title">
                                            Past Events
                                        </div>
                                    </div>
                                </div>
                                {this.state.passed}
                                {(this.state.pagePassed < this.state.totalPagesPassed) && (
                                    <div className="maxWidth wrapper noPaddingTop">
                                        <div className="fontGreen alignRight" onClick={this.loadMorePassed}>View More Past Events</div>
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                        {this.state.loadingPassed && (
                            <Spinner />
                        )}
                    </div>
                </div>
                <SecondaryNavigation selected="events" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        playdateFilters: state.playdates.filters
    };
}

export default connect(mapStateToProps)(PlaydatesHome);
