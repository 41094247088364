/*
    receiving props:
    title
        STRING
        this one refers to the text that is in the middle of the navigation, it is usually in color $green, and it refers to the current page title
        -> Location Name
        -> User Name

    leftButton
        STRING
        this one will show or hide the left button icon according to the type
        -> back
        -> map

    leftLink
        STRING
        dependant on leftButton, leftLink refers to the page the link will go to, you must provide a link to goback
        -> http://pooch.pet/location

    rightButton
        STRING
        similar to leftButton this one will show or hide the right button icon according to the type
        -> filter
        -> share //when using share you must provide the following props:
            -> shareTitle //this one must have the title of the current page, i.e. "Check out this park: Stanley Park", "Look at this Pooch: Brutus"

    rightLink
        STRING
        dependant on rightButton, rightLink refers to the page the link will go to, you must provide a link to go to, it does not work with "share"
        -> http://pooch.pet/categories
*/
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import {
    FacebookShareButton,
    TwitterShareButton,
    RedditShareButton,
    EmailShareButton
} from 'react-share';
import { toast } from 'react-toastify';

import Config from '../../helpers/Config';
import ModalMobileLogin from '../../hoc/Modal/ModalMobileLogin';

class MainNavigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shareModal: false
        };
    }

    copiedUrl = () => {
        this.toggleModal();
        toast.success('Saved to Clipboard');
    }

    toggleModal = () => {
        this.setState(prevState => ({ shareModal: !prevState.shareModal }));
    }

    goBack(e) {
        e.preventDefault();
        this.props.history.goBack();
    }

    render() {
        return (
            <React.Fragment>
                <div className="titleNavigation row">
                    {!this.props.leftButton && (
                        <Link to="#" className="empty">
                            <i className="icn icon-left-arrow" />
                        </Link>
                    )}
                    {this.props.leftButton && this.props.leftButton === 'back' && this.props.leftLink && (
                        <Link to={{ pathname: this.props.leftLink, winScroll: this.props.location.winScroll }}>
                            <i className="icn icon-left-arrow" />
                        </Link>
                    )}
                    {this.props.leftButton && this.props.leftButton === 'back-gallery' && this.props.leftLink && (
                        <Link to={{ pathname: this.props.leftLink, winScroll: this.props.location.winScroll }}>
                            <i className="icn icon-left-arrow" />
                        </Link>
                    )}
                    {this.props.leftButton && this.props.leftButton === 'back' && !Config.isMobileApp && !this.props.leftLink && (
                        <Link to="#" className="empty">
                            <i className="icn icon-left-arrow" />
                        </Link>
                    )}
                    {this.props.leftButton && this.props.leftButton === 'back' && Config.isMobileApp && !this.props.leftLink && (
                        <Link to="#" onClick={e => this.goBack(e)}>
                            <i className="icn icon-left-arrow" />
                        </Link>
                    )}
                    {this.props.leftButton && this.props.leftButton === 'map' && (
                        <Link to={this.props.leftLink}>
                            <i className="icn icon-map-search" />
                        </Link>
                    )}
                    {this.props.rightButton && this.props.rightButton === 'notifications' && (
                        <Link to={this.props.rightLink}>
                            <i className="icn icon-globe" />
                        </Link>
                    )}
                    <div className={'title ' + (this.props.styleClass || 'logoImgMobile')}>
                        <Link to="/">
                            <img src="https://pooch.pet/assets/images/pooch-logo-letters@1x.png" alt="Pooch Logo" />
                        </Link>
                    </div>
                    {!this.props.rightButton && (
                        <Link to="#" className="empty">
                            <i className="icn icon-left-arrow" />
                        </Link>
                    )}
                    {this.props.rightButton && this.props.rightButton === 'filter' && (
                        <Link to={this.props.rightLink}>
                            <i className="icn icon-filters" />
                        </Link>
                    )}
                    {this.props.rightButton && this.props.rightButton === 'addUser' && (
                        <Link to={this.props.rightLink}>
                            <i className="icn icon-add-friend" />
                        </Link>
                    )}
                    {this.props.rightButton && this.props.rightButton === 'writeMessage' && (
                        <Link to={this.props.rightLink}>
                            <i className="icn icon-compose-msg" />
                        </Link>
                    )}
                    {this.props.rightButton && this.props.rightButton === 'done' && !!this.props.rightButtonOnClick && (
                        <Link to="#" onClick={e => this.props.rightButtonOnClick(e)}>
                            <i className="icn icon-check-mark" />
                        </Link>
                    )}
                    {this.props.rightButton && this.props.rightButton === 'next' && !!this.props.rightButtonOnClick && (
                        <Link to="#" onClick={e => this.props.rightButtonOnClick(e)}>
                            <i className="icn icon-right-arrow" />
                        </Link>
                    )}
                    {this.props.rightButton && this.props.rightButton === 'addLocation' && (
                        <Link to={this.props.rightLink + '?redirect=' + this.props.rightButtonRedirect}>
                            <i className="icn icon-more-add" />
                        </Link>
                    )}
                    {this.props.rightButton && this.props.rightButton === 'share' && (
                        <Link to="#" onClick={this.toggleModal}>
                            <i className="icn icon-share" />
                        </Link>
                    )}
                    {this.props.leftButton && this.props.leftButton === 'chat' && (
                        <Link to={this.props.leftLink}>
                            <i className="icn icon-no-messages" />
                        </Link>
                    )}
                    {this.state.shareModal && (
                        <ReactModal
                            isOpen={this.state.shareModal}
                            contentLabel="onRequestClose Example"
                            onRequestClose={this.toggleModal}
                            className="Modal"
                            overlayClassName="Overlay"
                            ariaHideApp={false}
                        >
                            <h2>Share to:</h2>
                            <CopyToClipboard text={Config.webUrl + this.props.location.pathname} onCopy={this.copiedUrl}>
                                <div className="SocialMediaShareButton SocialMediaShareButton--copy">
                                    Copy Link
                                </div>
                            </CopyToClipboard>
                            <FacebookShareButton
                                url={Config.webUrl + this.props.location.pathname}
                                quote={this.props.shareTitle}
                            >
                                Facebook
                            </FacebookShareButton>
                            <TwitterShareButton
                                url={Config.webUrl + this.props.location.pathname}
                                title={this.props.shareTitle}
                            >
                                Twitter
                            </TwitterShareButton>
                            <RedditShareButton
                                url={Config.webUrl + this.props.location.pathname}
                                title={this.props.shareTitle}
                                windowWidth={660}
                                windowHeight={460}
                            >
                                Reddit
                            </RedditShareButton>
                            <EmailShareButton
                                url={Config.webUrl + this.props.location.pathname}
                                subject={this.props.shareTitle}
                                body={this.props.shareTitle + ' ' + Config.webUrl + this.props.location.pathname}
                            >
                                Email
                            </EmailShareButton>
                            <Link to="#" onClick={this.toggleModal}>
                                Cancel
                            </Link>
                        </ReactModal>
                    )}
                </div>
                {!this.props.credentials?.token && (
                    <ModalMobileLogin
                        dispatch={this.props.dispatch}
                        credentials={this.props.credentials}
                        userData={this.props.userData}
                    />
                )}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default withRouter(connect(mapStateToProps)(MainNavigation));
