import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// import DesktopRouteHolder from './DesktopRouteHolder'
import MainMap from './components/pagesDesktop/Locations/MainMap';
import LocationProfile from './components/pagesDesktop/Locations/LocationProfile';
import Favorites from './components/pagesDesktop/Locations/Favorites';
import LocationCheckIn from './components/pagesDesktop/Locations/LocationCheckIn';
import LocationReview from './components/pagesDesktop/Locations/LocationReview';
import LocationGallery from './components/pagesDesktop/Locations/LocationGallery';

import UserProfile from './components/pagesDesktop/Profiles/UserProfile';
import UserGallery from './components/pagesDesktop/Profiles/UserGallery';
import OwnDogProfile from './components/pagesDesktop/Profiles/OwnDogProfile';
import OwnUserProfile from './components/pagesDesktop/Profiles/OwnUserProfile';
import UserAddress from './components/pagesDesktop/Profiles/UserAddress';
import UserPark from './components/pagesDesktop/Profiles/UserPark';

import DogProfile from './components/pagesDesktop/Profiles/DogProfile';
import UserDogEditBreed from './components/pagesDesktop/Profiles/UserDogEditBreed';
import DogGallery from './components/pagesDesktop/Profiles/DogGallery';

import UserDogs from './components/pagesDesktop/Profiles/UserDogs';
import AddDog from './components/pagesDesktop/Profiles/AddDog';

import MissingDogs from './components/pagesDesktop/missing/MissingDogs';
import ReportMissingDogs from './components/pagesDesktop/missing/ReportMissingDogs';
import ReportMissingDogsSelectDogs from './components/pagesDesktop/missing/ReportMissingDogsSelectDogs';
import ReportMissingDogsDetails from './components/pagesDesktop/missing/ReportMissingDogsDetails';
import ReportMissingDogsConfirm from './components/pagesDesktop/missing/ReportMissingDogsConfirm';

import FoundDogs from './components/pagesDesktop/found/FoundDogs';
import ReportFoundDog from './components/pagesDesktop/found/ReportFoundDog';

import Friends from './components/pagesDesktop/Friends/Friends';
import FriendRequests from './components/pagesDesktop/Friends/FriendRequests';
import FindFriends from './components/pagesDesktop/Friends/FindFriends';

import Logout from './components/pagesDesktop/Logout/Logout';

import ActivitiesDogs from './components/pagesDesktop/activities/Dogs';

import Activities from './components/pagesDesktop/activities/Activities';
import Notifications from './components/pagesDesktop/activities/Notifications';
import ChatCreate from './components/pagesDesktop/chat/ChatCreate';
import ChatConfirm from './components/pagesDesktop/chat/ChatConfirm';
import Chat from './components/pagesDesktop/chat/Chat';

import AdminStats from './components/pagesDesktop/admin/AdminStats';
import AdminContactUsList from './components/pagesDesktop/admin/AdminContactUsList';
import AdminContactUsProfile from './components/pagesDesktop/admin/AdminContactUsProfile';
import AdminUsersList from './components/pagesDesktop/admin/AdminUsersList';
import AdminAdministratorsList from './components/pagesDesktop/admin/AdminAdministratorsList';
import AdminAdministratorsListAdd from './components/pagesDesktop/admin/AdminAdministratorsListAdd';
import AdminAdministratorsListAddUser from './components/pagesDesktop/admin/AdminAdministratorsListAddUser';
import AdminAdministratorsListAddLocation from './components/pagesDesktop/admin/AdminAdministratorsListAddLocation';
import AdminLocationsList from './components/pagesDesktop/admin/AdminLocationsList';
import AdminLocation from './components/pagesDesktop/admin/AdminLocation';
import ClaimLocation from './components/pagesDesktop/admin/ClaimLocation';

import AddLocationType from './components/pagesDesktop/Locations/AddLocationType';
import AddLocationAddress from './components/pagesDesktop/Locations/AddLocationAddress';
import AddLocationDetails from './components/pagesDesktop/Locations/AddLocationDetails';

import PlaydatesEdit from './components/pagesDesktop/playdates/PlaydatesEdit';
import PlaydatesCreate from './components/pagesDesktop/playdates/PlaydatesCreate';
import PlaydatesProfile from './components/pagesDesktop/playdates/PlaydatesProfile';
import PlaydatesHosted from './components/pagesDesktop/playdates/PlaydatesHosted';
import PlaydatesHome from './components/pagesDesktop/playdates/PlaydatesHome';
import PlaydatesSelectFriends from './components/pagesDesktop/playdates/PlaydatesCreateFriendSelection';
import PlaydatesInviteMoreFriends from './components/pagesDesktop/playdates/PlaydatesInviteMoreFriends';

import Terms from './components/pages/about/Terms';
import Privacy from './components/pages/about/Privacy';
import Settings from './components/pagesDesktop/settings/Settings';

import NotFound from './components/layout/NotFound';
import Slug from './components/hoc/Slug';
import Header from './components/hoc/Header';
import Footer from './components/hoc/Footer';

class Desktop extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Header>
                    <Switch>
                        {/* SEARCH PAGE */}
                        <Route exact path="/search" component={MainMap} />
                        <Route exact path="/categories" component={() => <Redirect to="/search" />} />
                        <Route exact path="/searchlist" component={() => <Redirect to="/search" />} />

                        {/* ABOUT US SECTIONS */}
                        <Route exact path="/terms" component={Terms} />
                        <Route exact path="/privacy" component={Privacy} />

                        {/* WELCOME PAGE */}
                        <Route exact path="/welcome" component={() => <Redirect to="/" />} />

                        {/* REGISTER LOGIN BLOCK */}
                        <Route exact path="/register" component={() => <Redirect to="/" />} />
                        <Route exact path="/registeruser" component={() => <Redirect to="/" />} />
                        <Route exact path="/registeremail" component={() => <Redirect to="/" />} />
                        <Route exact path="/registerpassword" component={() => <Redirect to="/" />} />
                        <Route exact path="/login" component={() => <Redirect to="/" />} />
                        <Route exact path="/loginemail" component={() => <Redirect to="/" />} />
                        <Route exact path="/forgot" component={() => <Redirect to="/" />} />
                        <Route exact path="/reset" component={() => <Redirect to="/" />} />

                        {/* ACTIVITY FEED */}
                        <Route exact path="/activity/:id" component={Activities} />
                        <Route exact path="/dogs" component={ActivitiesDogs} />
                        <Route exact path="/activities" component={Activities} />
                        <Route exact path="/notifications" component={Notifications} />

                        <Route exact path="/chat/channel/:id" component={Chat} />
                        <Route exact path="/chat" component={Chat} />
                        <Route exact path="/chatcreate" component={ChatCreate} />
                        <Route exact path="/chatconfirm" component={ChatConfirm} />

                        {/* EVENTS */}
                        <Route exact path="/events/invite/:id" component={PlaydatesInviteMoreFriends} />
                        <Route
                            exact
                            path="/events/manage/event/:id/dogselection"
                            component={props => <Redirect to={'/events/manage/event/' + props.match.params.id} />}
                        />
                        <Route
                            exact
                            path="/events/manage/event/:id/locationselection"
                            component={props => <Redirect to={'/events/manage/event/' + props.match.params.id} />}
                        />
                        <Route
                            exact
                            path="/events/manage/event/:id/image"
                            component={props => <Redirect to={'/events/manage/event/' + props.match.params.id} />}
                        />
                        <Route exact path="/events/manage/event/:id" component={PlaydatesEdit} />
                        <Route exact path="/events/manage/event" component={PlaydatesHosted} />
                        <Route
                            exact
                            path="/events/create/event/dogselection"
                            component={() => <Redirect to="/events/create/event/" />}
                        />
                        <Route
                            exact
                            path="/events/create/event/locationselection"
                            component={() => <Redirect to="/events/create/event/" />}
                        />
                        <Route
                            exact
                            path="/events/create/event/images"
                            component={() => <Redirect to="/events/create/event/" />}
                        />
                        <Route exact path="/events/create/event/friends" component={PlaydatesSelectFriends} />
                        <Route exact path="/events/create/event" component={PlaydatesCreate} />
                        <Route exact path="/events/:id" component={PlaydatesProfile} />
                        <Route exact path="/events" component={PlaydatesHome} />

                        {/* FRIENDS */}
                        <Redirect from="/pack" to="/friends" />
                        <Route exact path="/findfriends" component={FindFriends} />
                        <Route exact path="/friendrequest" component={FriendRequests} />
                        <Route exact path="/friends" component={Friends} />

                        {/* Found Dogs */}
                        <Route exact path="/founddogs" component={FoundDogs} />
                        <Route exact path="/reportfound" component={ReportFoundDog} />

                        {/* MISSING DOGS */}
                        <Route exact path="/missingdogs" component={MissingDogs} />
                        <Route exact path="/reportmissing/confirm" component={ReportMissingDogsConfirm} />
                        <Route exact path="/reportmissing/details" component={ReportMissingDogsDetails} />
                        <Route exact path="/reportmissing/selectdogs" component={ReportMissingDogsSelectDogs} />
                        <Route exact path="/reportmissing" component={ReportMissingDogs} />

                        {/* LOGOUT */}
                        <Route exact path="/logout" component={Logout} />

                        <Route
                            exact
                            path="/user/dogadd/breed"
                            component={() => <Redirect to="/user/dogadd" />}
                        />
                        <Route
                            exact
                            path="/user/dogadd/picture"
                            component={() => <Redirect to="/user/dogadd" />}
                        />
                        <Route
                            exact
                            path="/user/dogadd/details"
                            component={() => <Redirect to="/user/dogadd" />}
                        />
                        <Route
                            exact
                            path="/user/dogadd/description"
                            component={() => <Redirect to="/user/dogadd" />}
                        />
                        <Route exact path="/user/dogadd" component={AddDog} />

                        {/* DOGS */}
                        <Route
                            exact
                            path="/dog/:id/edit/name"
                            component={props => <Redirect to={'/dog/' + props.match.params.id + '/edit'} />}
                        />
                        <Route exact path="/dog/:id/gallery" component={DogGallery} />
                        <Route exact path="/dog/:id/edit/breed" component={UserDogEditBreed} />
                        <Route exact path="/dog/:id/edit" component={OwnDogProfile} />
                        <Route exact path="/dog/:id" component={DogProfile} />

                        {/* USER */}
                        <Route exact path="/user/dogs" component={UserDogs} />

                        <Route exact path="/user/profile/:id/gallery" component={UserGallery} />
                        <Route exact path="/user/profile/:id/edit" component={OwnUserProfile} />
                        <Route exact path="/user/profile/:id" component={UserProfile} />
                        <Route exact path="/user/address/" component={UserAddress} />
                        <Route exact path="/user/park/" component={UserPark} />

                        {/* MORE */}
                        <Route exact path="/claimlocation/:id/:name" component={ClaimLocation} />
                        <Route exact path="/managestats" component={AdminStats} />
                        <Route exact path="/managecontactus/:id" component={AdminContactUsProfile} />
                        <Route exact path="/managecontactus" component={AdminContactUsList} />
                        <Route exact path="/managelocation/:id/:name" component={AdminLocation} />
                        <Route exact path="/managelocation" component={AdminLocationsList} />
                        <Route exact path="/manageadministrators/add/user" component={AdminAdministratorsListAddUser} />
                        <Route exact path="/manageadministrators/add/location" component={AdminAdministratorsListAddLocation} />
                        <Route exact path="/manageadministrators/add" component={AdminAdministratorsListAdd} />
                        <Route exact path="/manageadministrators" component={AdminAdministratorsList} />
                        <Route exact path="/manageusers" component={AdminUsersList} />

                        {/* LOCATIONS */}
                        <Route exact path="/location/add/details" component={AddLocationDetails} />
                        <Route exact path="/location/add/address" component={AddLocationAddress} />
                        <Route exact path="/location/add" component={AddLocationType} />

                        <Route exact path="/favorites" component={Favorites} />
                        <Route exact path="/location/checkin/:id/:name" component={LocationCheckIn} />
                        <Route exact path="/location/review/:id/:name" component={LocationReview} />
                        <Route exact path="/location/profile/:id/:name" component={LocationProfile} />
                        <Route exact path="/location/gallery/:id/:name" component={LocationGallery} />

                        <Route exact path="/more" component={() => <Redirect to="/" />} />

                        <Route exact path="/settings" component={Settings} />

                        <Route exact path="/" component={() => <Redirect to="/dogs" />} />
                        <Route exact path="/404" component={NotFound} status={404} />
                        <Route component={Slug} />
                    </Switch>
                </Header>
                <Footer />
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(Desktop);
