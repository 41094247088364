import React from 'react';
import { connect } from 'react-redux';

import { setCreateBreedsSelected, getDogBreeds } from '../../redux/actions/dogs';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import Spinner from '../../layout/UI/Spinner';
import ApiError from '../../helpers/ApiError';

class UserDogAddBreed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            breeds: [],
            breedSearch: '',
            list_selected_breeds: []
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(getDogBreeds(this.props.credentials.token)).then(() => this.mapBreeds());
    }

    selectedBreeds = e => {
        e.preventDefault();
        if (!this.state.list_selected_breeds.length || !this.props.dogCreate.breeds || !this.props.dogCreate.breeds.length) {
            ApiError('Please select at least one breed!');
        } else {
            this.props.history.push('/user/dogadd/picture' + this.props.history.location.search);
        }
    }

    toggleBreeds = breed_id => {
        let listBreeds = [...this.state.list_selected_breeds];
        if (listBreeds.indexOf(breed_id) !== -1) listBreeds = listBreeds.filter(item => item !== breed_id);
        else listBreeds.push(breed_id);
        this.setState({ list_selected_breeds: listBreeds }, () => this.mapBreeds(this.state.breedSearch));
        this.props.dispatch(setCreateBreedsSelected(breed_id));
    }

    mapBreeds = (search = '') => {
        const selectedBreeds = [];
        this.setState({ breedSearch: search });
        if (this.props.breeds.length) {
            const breedlist = this.props.breeds.map(breed => {
                let showBreedBox = null;
                if (breed.breed_name.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                    let selectedClass = '';
                    if (this.props.dogCreate && this.props.dogCreate.breeds && this.props.dogCreate.breeds.indexOf(breed.breed_id) !== -1) {
                        selectedClass = 'selected';
                    }
                    // set the showBreedBox variable to equal this new DOM element
                    showBreedBox = (
                        <div className={selectedClass} key={breed.breed_id} id={breed.breed_id} onClick={e => { e.target.classList.toggle('selected'); this.toggleBreeds(breed.breed_id); }}>
                            {breed.breed_name}
                        </div>
                    );
                }
                if (this.props.dogCreate && this.props.dogCreate.breeds && this.props.dogCreate.breeds.indexOf(breed.breed_id) !== -1) {
                    selectedBreeds.push(
                        <li key={breed.breed_id} className="dogBreed" id={breed.breed_id}>
                            {breed.breed_name}
                            <div className="removeIcon" onClick={() => this.toggleBreeds(breed.breed_id)}><i className="icn icon-cross" /> </div>
                        </li>
                    );
                }
                return showBreedBox;
            });
            this.setState({ breeds: breedlist });
        }
        this.setState({
            list_selected_breeds: selectedBreeds
        });
    }

    removeBreeds = () => {
        const grid = document.getElementById('grid');
        grid.childNodes.forEach(item => {
            if (item.className === 'selected') {
                item.classList.toggle('selected');
            }
        });
        // this.props.dispatch(setCreateBreedsSelected(this.state.list_selected_breeds))
        const breedArray = this.state.list_selected_breeds;
        const propDispatch = this.props.dispatch;
        breedArray.forEach(breed => {
            const prop = breed.props.id;
            propDispatch(setCreateBreedsSelected(prop));
        });
        this.setState({
            list_selected_breeds: []
        });
    }

    render() {
        return (
            <div className="mainAppContainer LoginScreens">
                <MainNavigation {...this.props} title="Select the Breed" leftButton="back" rightButton="done" rightButtonOnClick={this.selectedBreeds} />
                <div className="bodyContent backgroundGreen">
                    <div className="LoginWrapper wrapper maxWidth">
                        <h1 className="section">What breed is {this.props.dogCreate.name}?</h1>
                        {!!this.props.dogCreate.breeds && (
                            <div className="breedSelected">
                                <ul className="breeds" id="list">
                                    {this.state.list_selected_breeds}
                                </ul>
                                {this.state.list_selected_breeds.length > 1 && (
                                    <div className="removeBreeds" onClick={e => this.removeBreeds(e)}><p>Remove All</p></div>
                                )}
                            </div>
                        )}
                        {this.state.breeds.length === 0
                            ? (<Spinner />)
                            : (
                                <div className="simpleSearchBar row breedSearch">
                                    <i className="icn icon-search fontDarkGray" />
                                    <input value={this.state.breedSearch} onChange={e => this.mapBreeds(e.target.value)} placeholder="Poodle, Pug, etc..." />
                                    <span className={'hover ' + (this.state.breedSearch ? 'fontRed' : 'fontGray')} onClick={() => this.mapBreeds()}>
                                        Cancel
                                    </span>
                                </div>
                            )}
                        <div className="tableGrid" id="grid">
                            {this.state.breeds}
                        </div>
                    </div>
                </div>
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        dogCreate: state.dogs.dogCreate,
        breeds: state.dogs.breeds
    };
}

export default connect(mapStateToProps)(UserDogAddBreed);
