import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from 'rmc-date-picker';
import ReactGA from 'react-ga';
import Textarea from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import { getDogProfile, updateDog, deleteDog, clearDogPictureFile, updateMissingDog } from '../../redux/actions/dogs';
import { setPicture, uploadPicture, deletePicture } from '../../redux/actions/all';
import GalleryLightbox from '../../layout/gallery/GalleryLightbox';
import Avatar from '../../layout/UI/Avatar';
import CameraModal from '../../layout/CameraModal';
import BasicConfirm from '../../layout/UI/BasicConfirm';
import { listDogBreeds, guard, formatMonth } from '../../helpers/Functions';
import ApiError from '../../helpers/ApiError';
import WhiteTitle from '../../hoc/WhiteTitle';
import Columns from '../../hoc/Columns';

class OwnDogProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
            // users: null,
            // socialMedia: {},
            gallery: [],
            pictureModal: false,
            pictureModal2: false,
            dogProfile: null,
            entity_poochid: ''
        };
    }

    componentDidMount() {
        this.props.dispatch(getDogProfile(this.props.credentials.token, this.props.match.params.id))
            .then(() => {
                this.setState({
                    dogProfile: this.props.dogProfile,
                    image: guard(this.props.dogProfile, '.images.0.image_mediumurl') || null,
                    gallery: this.props.dogProfile.images,
                    // socialMedia: this.props.dogProfile.dog_socialmedia,
                    entity_poochid: ((this.props.dogProfile && this.props.dogProfile.parententity && this.props.dogProfile.parententity.entity_poochid) || '')
                });
            });
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    handlePoochID = e => {
        const poochId = e.target.value.split('pooch.pet/');
        let text = '';
        if (poochId.length > 1) {
            text = poochId[1].replace(/[^a-z0-9_-]+/gi, '').toLowerCase();
        }
        this.setState({
            // changed: true,
            entity_poochid: text
        });
    }

    filesChanged = files => {
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            const file = files[i];
            reader.onloadend = () => {
                this.props.dispatch(uploadPicture(this.props.credentials.token, this.props.dogProfile.dog_id, file, true))
                    .then(response => this.updateThumbs(response, true));
            };
            reader.readAsDataURL(file);
        }
    }

    updateThumbs = (response, featured) => {
        if (featured) {
            this.setState({ image: response.value.data.image_mediumurl });
        }
        this.setState(prevState => ({
            pictureModal: false,
            pictureModal2: false,
            gallery: [response.value.data, ...prevState.gallery]
        }));
        if (!!navigator && !!navigator.camera && !!navigator.camera.cleanup) {
            navigator.camera.cleanup();
        }
    }

    galleryfiles = files => {
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            const file = files[i];
            reader.onloadend = () => {
                this.props.dispatch(uploadPicture(this.props.credentials.token, this.props.dogProfile.dog_id, file, false))
                    .then(response => this.updateThumbs(response));
            };
            reader.readAsDataURL(file);
        }
    }

    changeImage = () => {
        const selectfile = document.getElementById('file');
        selectfile.click();
    }

    addToGallery = e => {
        e.preventDefault();
        const selectfile = document.getElementById('galleryfile');
        selectfile.click();
    }

    removeImage = () => {
        this.props.dogProfile.dispatch(clearDogPictureFile());
        this.setState(() => ({
            image: null
        }));
    }

    // Removes image from GalleryLightBox
    handleClickRemoveImage = id => {
        this.props.dispatch(deletePicture(this.props.credentials.token, id))
            .then(() => {
                this.setState(prevState => {
                    const newGallery = prevState.gallery.filter(image => image.image_id !== id);
                    return {
                        gallery: newGallery
                    };
                }, () => {
                    this.setState({ image: this.state.gallery && this.state.gallery[0] && this.state.gallery[0].image_largeurl ? this.state.gallery[0].image_largeurl : null });
                });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    handleClickSetImage = id => {
        this.props.dispatch(setPicture(this.props.credentials.token, id))
            .then(res => {
                let newFeaturedImage = {};
                // we push the new featured image to the top of the list, on the first position
                this.setState(prevState => {
                    const newGallery = prevState.gallery.filter(image => {
                        if (image.image_id === id) newFeaturedImage = image;
                        return image.image_id !== id;
                    });
                    newGallery.sort((a, b) => b.image_id - a.image_id);
                    return {
                        gallery: [newFeaturedImage, ...newGallery],
                        image: res.value.data.image_largeurl
                    };
                });
                toast.success('Profile Image Set Successfully!');
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleClickRemove = () => {
        this.props.dispatch(deleteDog(this.props.credentials.token, this.props.dogProfile.dog_id))
            .then(() => {
                this.props.history.push('/dog/' + this.props.dogProfile.dog_id);
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    verifyData = e => {
        e.preventDefault();

        if (this.state.dogProfile) {
            if (!this.state.dogProfile.dog_sex) {
                ApiError('Please select the sex of the dog');
                return;
            }
            if (this.state.dogProfile.dog_fixed !== 1 && this.state.dogProfile.dog_fixed !== 0) {
                ApiError('Please select if the dog is fixed or not fixed');
                return;
            }
            if (!this.state.dogProfile.dog_birthdate) {
                ApiError('Please select the date of birth of your dog!');
                return;
            }
            const saveObject = {};
            saveObject.dog_sex = this.state.dogProfile.dog_sex;
            saveObject.dog_description = this.state.dogProfile.dog_description;
            saveObject.dog_name = this.state.dogProfile.dog_name;
            saveObject.dog_fixed = this.state.dogProfile.dog_fixed;
            saveObject.dog_birthdate = moment(this.state.dogProfile.dog_birthdate).format('YYYY-MM-DD');
            saveObject.dog_deceased = this.state.dogProfile.dog_deceased ? moment(this.state.dogProfile.dog_deceased).format('YYYY-MM-DD') : null;
            saveObject.dog_playdates = this.state.dogProfile.dog_playdates;
            saveObject.dog_weight = this.state.dogProfile.dog_weight;
            saveObject.dog_socialmedia = this.state.dogProfile.dog_socialmedia;
            saveObject.entity_poochid = this.state.entity_poochid;
            this.props.dispatch(updateDog(this.props.credentials.token, this.props.match.params.id, saveObject))
                .then(() => {
                    toast.success('Updated dog correctly');
                    this.redirect();
                })
                .catch(err => {
                    ApiError(err);
                });
        }
    }

    toggleSex = e => {
        this.setState({ dogProfile: { ...this.state.dogProfile, dog_sex: e.target.innerText.toLowerCase() } });
    }

    toggleFixed = val => {
        this.setState({ dogProfile: { ...this.state.dogProfile, dog_fixed: val } });
    }

    togglePlaydates = val => {
        this.setState({ dogProfile: { ...this.state.dogProfile, dog_playdates: val } });
    }

    changeWeight = e => {
        this.setState({ dogProfile: { ...this.state.dogProfile, dog_weight: e.target.value } });
    }

    onDateChange = date => {
        this.setState({ dogProfile: { ...this.state.dogProfile, dog_birthdate: date } });
    }

    onDeceasedChange = date => {
        this.setState({ dogProfile: { ...this.state.dogProfile, dog_deceased: date } });
    }

    socialMediaTextHandler = (e, type) => {
        const value = e.target.value;
        this.setState({ dogProfile: { ...this.state.dogProfile, dog_socialmedia: { ...this.state.dogProfile.dog_socialmedia, [type]: value } } });
    }

    deleteDog = e => {
        e.preventDefault();
        BasicConfirm(
            'Are you sure?',
            'Do you want to permanently remove ' + this.props.dogProfile.dog_name + ' from the Pooch network?',
            'Yes, Remove.',
            this.handleClickRemove
        );
    }

    handleClickRemove = () => {
        this.props.dispatch(deleteDog(this.props.credentials.token, this.props.dogProfile.dog_id))
            .then(() => {
                this.props.history.push('/user/profile/' + this.props.credentials.user_id);
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    foundDog = e => {
        e.preventDefault();
        BasicConfirm(
            'Did you find ' + this.props.dogProfile.dog_name + '?',
            false,
            'Yes, Mark as Found.',
            this.handleClickFound
        );
    }

    handleClickFound = () => {
        const data = { dogaddress_found: moment().format('YYYY-MM-DD') };
        this.props.dispatch(updateMissingDog(this.props.credentials.token, this.props.dogProfile.dogaddress.dogaddress_id, data))
            .then(() => {
                toast.success('Profile Updated! Glad to hear you found your pup!');
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    listOwners(owners) {
        return owners.map(owner => {
            const image = (
                <div className="round-avatar owner-list">
                    <Avatar
                        src={guard(owner, '.images.0.image_mediumurl')}
                        alt={this.props.dogProfile.name}
                        type="user"
                    />
                </div>
            );
            const lastSeen = (<div className="lastSeen">Last Seen: {owner.lastaddress.address_city}, {owner.lastaddress.address_country}</div>);
            let friendShipStatus = '';
            if (owner.myfriendship && owner.myfriendship.friendship_status === 'accepted') {
                friendShipStatus = <div className="flexSmallestButtons borderGreen greenBG fontWhite"><p>Friends</p></div>;
            } else if (owner.myfriendship && owner.myfriendship.friendship_status === 'pending') {
                friendShipStatus = <div className="flexSmallestButtons borderBlue blueBG fontWhite"><p>Pending</p></div>;
            } else {
                friendShipStatus = null;
            }
            return (
                <React.Fragment key={owner.user_firstname}>
                    {image}
                    <div className="name">
                        <div className="fontGreen">{owner.user_firstname + ' ' + owner.user_lastname}</div>
                        <div>{lastSeen}</div>
                    </div>
                    {friendShipStatus}
                </React.Fragment>
            );
        });
    }

    changeDescription(value) {
        this.setState({ dogProfile: { ...this.state.dogProfile, dog_description: value } });
    }

    changeName(value) {
        this.setState({ dogProfile: { ...this.state.dogProfile, dog_name: value } });
    }

    addWeight(action, value) {
        let v = 50;
        if (value) {
            v = value;
        }
        if (action === 'add' && v < 250) {
            this.setState({ dogProfile: { ...this.state.dogProfile, dog_weight: parseInt(v, 10) + 1 } });
        } else if (action === 'sub' && v > 1) {
            this.setState({ dogProfile: { ...this.state.dogProfile, dog_weight: parseInt(v, 10) - 1 } });
        }
    }

    toggleDeceased(val) {
        if (val === true) {
            this.setState({ dogProfile: { ...this.state.dogProfile, dog_deceased: moment()._d } });
        } else {
            this.setState({ dogProfile: { ...this.state.dogProfile, dog_deceased: null } });
        }
        this.setState({ deceased: val });
    }

    redirect() {
        const params = new URLSearchParams(window.location.search);
        const redirect = params.get('redirect'); // bar
        if (redirect) {
            this.props.history.push(redirect);
        } else {
            this.props.history.goBack();
        }
    }

    render() {
        const dog_is_lost = !!this.props.dogProfile && !!this.props.dogProfile.dogaddress && !this.props.dogProfile.dogaddress.dogaddress_found;

        const column1 = (
            <React.Fragment>
                {this.props.dogProfile.users && this.props.dogProfile.users.length && (
                    <React.Fragment>
                        <WhiteTitle>Owner(s):</WhiteTitle>
                        <div className="participantList">
                            <div className="participantListItem">
                                <Link className="user row " to={'/user/profile/' + this.props.userData.user_id}>
                                    {this.listOwners(this.props.dogProfile.users)}
                                </Link>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );

        const column2 = (
            <React.Fragment>
                {dog_is_lost && (
                    <React.Fragment>
                        <div className="spacerSmall" />
                        <div className="wrapper">
                            <Link onClick={e => this.foundDog(e)} to="#" className="wideButtons redBorder redBG fontWhite">
                                Mark Dog as Found
                            </Link>
                        </div>
                    </React.Fragment>
                )}
                <div className="maxWidth">
                    <React.Fragment>
                        <WhiteTitle>Pictures
                            <div className="image-options">
                                {this.state.gallery.length > 0 && (
                                    <Link to={'/dog/' + this.props.dogProfile.dog_id + '/gallery'} className="fontGreen"> <i className="icn icon-gallery" /> View Gallery </Link>
                                )}
                                <input name="galleryfile" className="hidden" id="galleryfile" type="file" onChange={e => this.galleryfiles(e.target.files)} accept="image/*" required multiple />
                                <Link to="#" onClick={e => { this.addToGallery(e); }} className="fontGreen">
                                    <i className="icn icon-upload-photo" /> Add Pictures
                                </Link>
                            </div>
                        </WhiteTitle>
                        {this.state.gallery.length > 0 && (
                            <div className="galleryHolder">
                                <GalleryLightbox
                                    dispatch={this.props.dispatch}
                                    credentials={this.props.credentials}
                                    dogProfile={this.props.dogProfile}
                                    userData={this.props.userData}
                                    images={this.state.gallery}
                                    count={4}
                                    size="normal"
                                    handleClickSetImage={this.handleClickSetImage}
                                    handleClickRemoveImage={this.handleClickRemoveImage}
                                />
                                {this.state.gallery.length > 4 && (
                                    <div className="holderEnclosed">
                                        <Link to={'/user/profile/' + this.props.userData.user_id + '/gallery'}>+ {this.state.gallery.length - 4} More</Link>
                                    </div>
                                )}
                            </div>
                        )}
                    </React.Fragment>
                    <form onSubmit={this.verifyData}>
                        <WhiteTitle>Pooch ID</WhiteTitle>
                        <div className="profileDetails maxWidth">
                            <div className="userSocialMedia">
                                <ul>
                                    <li className="socialMediaListItem">
                                        <div>
                                            <label className="fontBlack h6">Create your unique Pooch Link</label><br />
                                            <input className="transparentInput" name="entity_poochid" value={'pooch.pet/' + this.state.entity_poochid} onChange={this.handlePoochID} />
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <WhiteTitle>About {(this.state.dogProfile && this.state.dogProfile.dog_name) || ''}</WhiteTitle>
                        <div className="profileDetails maxWidth">
                            <Textarea
                                onChange={e => this.changeDescription(e.target.value)}
                                className="about-me-textarea"
                                placeholder="Write a few sentences about yourself!"
                                name="bio"
                                value={(this.state.dogProfile && this.state.dogProfile.dog_description) || ''}
                            />
                        </div>
                        <div className="spacerSmall" />
                        <WhiteTitle>Details</WhiteTitle>
                        <div className="profileDetails maxWidth">
                            <ul className="listDivided">
                                <li>
                                    <div>
                                        <label className="fontBlack h6">Name</label>
                                        <input className="transparentInput" name="dogName" type="text" required value={(this.state.dogProfile && this.state.dogProfile.dog_name) || ''} onChange={e => this.changeName(e.target.value)} />
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <label className="fontBlack h6">Breeds</label>
                                        <div>
                                            <Link className="edit-breeds fontBlack" to={'/dog/' + this.props.dogProfile.dog_id + '/edit/breed'}>{this.props.dogProfile && this.props.dogProfile.breeds && this.props.dogProfile.breeds.length && listDogBreeds(this.props.dogProfile.breeds)}<i className="icn icon-right-arrow fontGreen" /></Link>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <label className="fontBlack h6">Sex:</label>
                                        <span className="row multiChoiceHolder">
                                            <span className={'multiChoice green ' + (guard(this.state.dogProfile, '.dog_sex') === 'male' ? 'selected' : '')} onClick={e => this.toggleSex(e)}>Male</span>
                                            <span className={'multiChoice green ' + (guard(this.state.dogProfile, '.dog_sex') === 'female' ? 'selected' : '')} onClick={e => this.toggleSex(e)}>Female</span>
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <label className="fontBlack h6">Available for Events?</label>
                                        <span className="row multiChoiceHolder">
                                            <span className={'multiChoice green ' + (this.state.dogProfile && this.state.dogProfile.dog_playdates === 1 ? 'selected' : '')} onClick={() => this.togglePlaydates(1)}>Yes</span>
                                            <span className={'multiChoice green ' + (this.state.dogProfile && this.state.dogProfile.dog_playdates === 0 ? 'selected' : '')} onClick={() => this.togglePlaydates(0)}>No</span>
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <label className="fontBlack h6">Is {this.props.dogProfile.dog_name} fixed?</label>
                                        <span className="row multiChoiceHolder">
                                            <span className={'multiChoice green ' + (this.state.dogProfile && this.state.dogProfile.dog_fixed === 1 ? 'selected' : '')} onClick={() => this.toggleFixed(1)}>Fixed</span>
                                            <span className={'multiChoice green ' + (this.state.dogProfile && this.state.dogProfile.dog_fixed === 0 ? 'selected' : '')} onClick={() => this.toggleFixed(0)}>Not Fixed</span>
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <label className="fontBlack h6">Weight</label>
                                        </div>
                                        <input className="slider white" id="slider" type="range" min="1" max="250" step="1" value={guard(this.state.dogProfile, '.dog_weight') || ''} onChange={e => this.changeWeight(e)} />
                                        <div className="sliderValue">
                                            <span className="sub" onClick={() => this.addWeight('sub', (this.state.dogProfile && this.state.dogProfile.dog_weight))}> - </span> <span>{parseFloat(this.state.dogProfile && this.state.dogProfile.dog_weight).toFixed(2)}lbs</span> <span className="sub" onClick={() => this.addWeight('add', this.state.dogProfile.dog_weight)}> + </span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="spacerSmall" />
                        <WhiteTitle>Important Dates</WhiteTitle>
                        <div className="profileDetails maxWidth">
                            <ul className="listDivided">
                                <li>
                                    <div className="column-two">
                                        <label className="fontBlack h6">Date of Birth</label>
                                        <div className="spacerSmall" />
                                        <DatePicker
                                            mode="date"
                                            date={(!!this.state.dogProfile && !!this.state.dogProfile.dog_birthdate) ? moment(this.state.dogProfile && this.state.dogProfile.dog_birthdate)._d : moment()._d}
                                            formatMonth={formatMonth}
                                            onDateChange={this.onDateChange}
                                            maxDate={moment()._d}
                                        />
                                        <div className="spacerSmall" />
                                    </div>
                                </li>
                                <div className="spacerSmall" />
                                <li>
                                    <div className="column-two">
                                        <label className="fontBlack h6">Create memorial for {(this.state.dogProfile && this.state.dogProfile.dog_name) || ''}?</label>
                                        <div className="row multiChoiceHolder">
                                            <span className={'multiChoice green ' + ((this.state.dogProfile && !!this.state.dogProfile.dog_deceased) || this.state.deceased === true ? 'selected' : '')} onClick={() => this.toggleDeceased(true)}>Yes</span>
                                            <span className={'multiChoice green ' + ((this.state.dogProfile && !this.state.dogProfile.dog_deceased) && this.state.deceased !== true ? 'selected' : '')} onClick={() => this.toggleDeceased(false)}>No</span>
                                        </div>
                                        {((this.state.dogProfile && !!this.state.dogProfile.dog_deceased) || this.state.deceased === true) && (
                                            <div className="section">When did {this.state.dogProfile.dog_name} pass away?</div>
                                        )}
                                        {((this.state.dogProfile && !!this.state.dogProfile.dog_deceased) || this.state.deceased === true) && (
                                            <div className="selectDate ">
                                                <DatePicker
                                                    mode="date"
                                                    date={this.state.dogProfile.dog_deceased ? moment(this.state.dogProfile.dog_deceased)._d : moment()._d}
                                                    formatMonth={formatMonth}
                                                    onDateChange={this.onDeceasedChange}
                                                    maxDate={moment()._d}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="spacerSmall" />
                        <WhiteTitle>Social Media</WhiteTitle>
                        <div className="dogSocialMedia wrapper">
                            <ul>
                                <li className="socialMediaListItem row">
                                    <i className="icn icon-facebook fontGreen" />
                                    <div>
                                        <input
                                            onChange={e => this.socialMediaTextHandler(e, 'facebook')}
                                            placeholder="Facebook Link"
                                            className="transparentInput"
                                            name="facebook"
                                            type="text"
                                            value={(this.state.dogProfile && this.state.dogProfile.dog_socialmedia && this.state.dogProfile.dog_socialmedia.facebook) || ''}
                                        />
                                    </div>
                                </li>
                                <li className="socialMediaListItem row">
                                    <i className="icn icon-twitter fontGreen" />
                                    <div>
                                        <input
                                            placeholder="Twitter Link"
                                            onChange={e => this.socialMediaTextHandler(e, 'twitter')}
                                            className="transparentInput"
                                            name="twitter"
                                            type="text"
                                            value={(this.state.dogProfile && this.state.dogProfile.dog_socialmedia && this.state.dogProfile.dog_socialmedia.twitter) || ''}
                                        />
                                    </div>
                                </li>
                                <li className="socialMediaListItem row">
                                    <i className="icn icon-instagram fontGreen" />
                                    <div>
                                        <input
                                            onChange={e => this.socialMediaTextHandler(e, 'instagram')}
                                            placeholder="Instagram Link"
                                            className="transparentInput"
                                            name="instagram"
                                            type="text"
                                            value={(this.state.dogProfile && this.state.dogProfile.dog_socialmedia && this.state.dogProfile.dog_socialmedia.instagram) || ''}
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="profile-buttons">
                            <input type="button" value="Delete Dog" onClick={e => this.deleteDog(e)} className="rightRoundButton redBorder redBG fontWhite" />
                            <div>
                                <input type="submit" value="Save Changes" className="rightRoundButton greenBorder greenBG fontWhite" />
                                <Link to={'/dog/' + this.props.dogProfile.dog_id} className="rightRoundButton greenBorder whiteBG fontGreen">View Profile</Link>
                                <Link to="/reportmissing" className="rightRoundButton redBorder whiteBG fontRed">Report Lost Dog</Link>
                            </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );

        return (
            <div className="mainAppContainer">
                <div className="bodyContent edit-dog-profiles">
                    {this.state.pictureModal && (
                        <CameraModal toggleModal={this.toggleModal} callbackCamera={this.profileCamera} callbackUpload={this.profileUpload} />
                    )}
                    {this.state.pictureModal2 && (
                        <CameraModal toggleModal={this.toggleModal2} callbackCamera={this.galleryCamera} callbackUpload={this.galleryUpload} />
                    )}
                    <div className={'profilePictureWrapper dog'/* + (dog_is_lost ? ' lost':'') + (dog_passed_away ? ' passed':'' ) */}>
                        <div className="profile-avatar">
                            <input className="hidden" name="file" id="file" type="file" onChange={e => this.filesChanged(e.target.files)} accept="image/*" required />
                            <div className="fontWhite wrapper alignCenter">
                                <div className="round-avatar huge dog">
                                    <Avatar
                                        src={this.state.image}
                                        alt={this.props.dogProfile.dog_name}
                                        onClick={this.changeImage}
                                        type="dog"
                                    />
                                </div>
                                {/* <div className="desktop-options">/*}
                                {/*this.state.image &&
                                    <div onClick={()=>{this.removeImage()}}  className="removeImage">Remove Picture</div>
                                */}
                                <div onClick={() => { this.changeImage(); }} className="changeImage spacerVertical">Change Profile Picture</div>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                    {this.props.dogProfile && (
                        <div className="two-column-layout">
                            <Columns
                                c1={column1}
                                c2={column2}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        dogProfile: state.dogs.dogProfile
    };
}

export default connect(mapStateToProps)(OwnDogProfile);
