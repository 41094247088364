import apiConnection from '../../helpers/Api';

function getActivityQuery() {
    let query = 'expand=entity,';
    query += 'entity.location.images,';
    query += 'entity.post.images,';
    query += 'groupentity.entity.dog,';
    query += 'groupentity.entity.user,';
    query += 'groupentity.entity.location,';
    query += 'groupentity.entity.review.entity.location,';
    query += 'user.images,';
    query += 'useraddress.address,';
    query += 'entity.userdog.dog.images,';
    query += 'entity.userdog.user,';
    query += 'entity.friendship.sender.images,';
    query += 'entity.friendship.receiver.images,';
    query += 'entity.image.entity,';
    query += 'entity.image.entity.review,';
    query += 'entity.image.entity.review.entity,';
    query += 'entity.image.rewiew,';
    query += 'entity.review.images,';
    query += 'entity.image.entity.review.entity.location,';
    query += 'entity.image.entity.location,';
    query += 'entity.image.entity.dog,';
    query += 'entity.checkin.location.images,';
    query += 'entity.review.location.images,';
    query += 'entity.dogaddress.dog.images,';
    query += 'entity.playdate.images,';
    query += 'entity.playdate.location.images,';
    query += 'entity.playdateuser.playdate.images,';
    query += 'entity.playdateuser.user.images,';
    query += 'entity.playdateuser.playdate.owner.images,';
    query += 'entity.playdateuser.playdate.location.images,';
    query += 'entity.favoritelocation.location.images';

    return query;
}

export function getActivities(token, id = 1, page = 1, withfriends = '') {
    let query = '?per-page=20&page=' + page + '&sort=-activity_id&';
    query += getActivityQuery();
    query += withfriends;

    return {
        type: 'GET_ACTIVITIES',
        payload: apiConnection.get('activities/' + id + query, { headers: { Authorization: token } })
    };
}

export function deleteActivity(token, id) {
    return {
        type: 'DELETE_ACTIVITY',
        payload: apiConnection.delete('activities/' + id, { headers: { Authorization: token } })
    };
}

export function getActivity(token, id) {
    let query = '?';
    query += getActivityQuery();

    return {
        type: 'GET_ACTIVITY',
        payload: apiConnection.get('activities/activity/' + id + query, { headers: { Authorization: token } })
    };
}

export function getNotifications(token, page = 1) {
    let query = '?per-page=30&page=' + page + '&sort=-activity_id&';
    query += 'expand=entity,';
    query += 'user.images,';
    query += 'entity.friendship.sender.images,';
    query += 'entity.friendship.receiver.images,';
    query += 'entity.playdateuser.user.images,';
    query += 'entity.playdateuser.playdate.owner.images,';
    query += 'entity.playdateuser.playdate.location';

    return {
        type: 'GET_NOTIFICATIONS',
        payload: apiConnection.get('notifications' + query, { headers: { Authorization: token } })
    };
}

export function getActivityCounter(token) {
    return {
        type: 'GET_ACTIVITY_COUNTER',
        payload: apiConnection.get('activities/countnew', { headers: { Authorization: token } })
    };
}

export function markAllAsSeen(token, type = 'activity') {
    let markType = '';
    if (type === 'activity') {
        markType = type;
    } else {
        markType = 'notification';
    }
    return {
        type: 'MARK_ALL_AS_SEEN',
        payload: apiConnection.post('activities/markasseen/all?type=' + markType, {}, { headers: { Authorization: token } })
    };
}

export function saveActivityScreenDetails(payload) {
    return {
        type: 'SAVE_ACTIVITY_SCREEN_DETAILS',
        payload
    };
}

export function saveNotificationScreenDetails(payload) {
    return {
        type: 'SAVE_NOTIFICATION_SCREEN_DETAILS',
        payload
    };
}

export function saveDogScreenDetails(payload) {
    return {
        type: 'SAVE_DOGS_SCREEN_DETAILS',
        payload
    };
}
