import React from 'react';

import SettingsNotifications from '../../layout/SettingsNotifications';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';

const Settings = () => (
    <div className="mainAppContainer">
        <MainNavigation title="Settings" />
        <div className="bodyContent backgroundGreen">
            <div className="wrapper maxWidth fontWhite">
                <SettingsNotifications />
            </div>
        </div>
        <SecondaryNavigation selected="more" />
    </div>
);

export default Settings;
