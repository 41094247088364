import { Base64 } from 'js-base64';
import { toast } from 'react-toastify';

import { guard } from '../../helpers/Functions';

export default function reducer(
    state = {
        credentials: {},
        user: {
            user_firstname: '',
            user_lastname: '',
            user_email: '',
            user_location: null,
            user_socialmedia: null,
            user_aboutme: ''
        },
        userProfile: {},
        chat: {
            selectedUsers: []
        },
        device: {},
        deviceStatus: null,
        findFriendsSavedStatus: {},
        friendRequestsStatus: []
    },
    action
) {
    switch (action.type) {
        case 'persist/REHYDRATE': {
            if (action.payload.users) {
                return {
                    ...state,
                    credentials: action.payload.users.credentials || {},
                    user: action.payload.users.user || {},
                    userProfile: action.payload.users.userProfile || {},
                    chat: action.payload.users.chat || { selectedUsers: [] },
                    device: action.payload.users.device || {},
                    deviceStatus: action.payload.users.deviceStatus || {},
                    findFriendsSavedStatus: {},
                    friendRequestsStatus: []
                };
            }
            return { ...state };
        }
        case 'SET_FIRST_NAME':
            return { ...state, user: { ...state.user, user_firstname: action.payload } };

        case 'GET_DEVICE_TOKEN_STATUS':
            return { ...state, deviceStatus: action.payload };

        case 'SET_LAST_NAME':
            return { ...state, user: { ...state.user, user_lastname: action.payload } };

        case 'SET_EMAIL':
            return { ...state, user: { ...state.user, user_email: action.payload } };

        case 'SET_SOCIAL_MEDIA':
            return { ...state, user: { ...state.user, user_socialmedia: action.payload } };

        case 'SET_USER_BIO':
            return { ...state, user: { ...state.user, user_aboutme: action.payload } };

        case 'SET_USERS_MAIN_IMAGE':
            return { ...state, user: { ...state.user, images: action.payload } };

        case 'UPDATE_USER_FULFILLED':
            return { ...state, user: { ...state.user, ...action.payload.data } };

        case 'SAVE_DEVICE_TOKEN_FULFILLED':
            return { ...state, device: action.payload.data };

        case 'SET_USER_LOCATION':
            return { ...state, user: { ...state.user, user_location: action.payload } };

        case 'USER_LOGIN_FULFILLED':
            return { ...state, user: { ...action.payload.data }, credentials: { token: 'BASIC ' + Base64.encode(action.payload.data.user_accesstoken), user_id: action.payload.data.user_id } };

        case 'REFRESH_OWN_PROFILE_FULFILLED':
            return { ...state, user: { ...action.payload.data } };

        case 'USER_REGISTER_FULFILLED':
            return { ...state, user: { ...action.payload.data }, credentials: { token: 'BASIC ' + Base64.encode(action.payload.data.user_accesstoken), user_id: action.payload.data.user_id } };

        case 'GET_USER_PENDING':
            return { ...state, userProfile: {} };

        case 'GET_USER_FULFILLED':
            return { ...state, userProfile: { ...action.payload.data } };

        case 'GET_USER_REJECTED':
            return { ...state, userProfile: {} };

        case 'UPDATE_HOME_ADDRESS_FULFILLED':
            return { ...state, user: { ...state.user, address: action.payload.data } };

        case 'SET_LOCATION_ADMINISTRATOR_USER':
            return { ...state, administratorUser: action.payload };

        case 'SET_LOCATION_ADMINISTRATOR_LOCATION':
            return { ...state, administratorLocation: action.payload };

        case 'ADD_LOCATION_ADMIN_FULFILLED':
            return { ...state, administratorLocation: null, administratorUser: null };

        case 'SEND_FRIEND_REQUEST_FULFILLED':
            if (guard(action, '.payload.data.friendship_status') === 'accepted') {
                toast.success('You are now Friends');
                return { ...state, userProfile: { ...state.userProfile, myfriendship: action.payload.data }, friendRequestsStatus: [...state.friendRequestsStatus, { friendship_status: action.payload.data.friendship_status, receiver_id: action.payload.data.receiver_id, sender_id: action.payload.data.sender_id }] };
            } if (guard(action, '.payload.data.friendship_status') === 'pending') {
                toast.success('Request Sent');
                return { ...state, userProfile: { ...state.userProfile, myfriendship: action.payload.data }, friendRequestsStatus: [...state.friendRequestsStatus, { friendship_status: action.payload.data.friendship_status, receiver_id: action.payload.data.receiver_id, sender_id: action.payload.data.sender_id }] };
            }
            toast.success('Request Sent');

            return { ...state };

        case 'SEND_FRIEND_REQUEST_REJECTED':
            if (action.payload && action.payload.response && action.payload.response.data && action.payload.response.data.message) {
                toast.error(action.payload.response.data.message);
            } else {
                toast.error('There was an error, please try again.');
            }

            return { ...state };

        case 'UPDATE_FRIEND_STATE_FULFILLED':
            if (guard(action, '.payload.status') === 200) {
                if (action.payload.data.friendship_status === 'accepted') {
                    toast.success('You have added a friend');
                } else if (action.payload.data.friendship_status === 'unfriended') {
                    toast.success('You unfriended this user');
                } else if (action.payload.data.friendship_status === 'declined') {
                    toast.success('You declined a friendship request');
                } else if (action.payload.data.friendship_status === 'blocked') {
                    toast.success('You have blocked this user');
                } else {
                    toast.success('Changes Saved');
                }

                return { ...state, userProfile: { ...state.userProfile, myfriendship: action.payload.data }, friendRequestsStatus: [...state.friendRequestsStatus, { friendship_status: action.payload.data.friendship_status, receiver_id: action.payload.data.receiver_id, sender_id: action.payload.data.sender_id }] };
            }
            return { ...state };

        case 'CHAT_TOGGLE_USER': {
            if (!!state.chat.selectedUsers && !!state.chat.selectedUsers.length) {
                let index2 = -1;
                for (let j = 0; j < state.chat.selectedUsers.length; j++) {
                    if (String(state.chat.selectedUsers[j].id) === String(action.payload.id)) {
                        index2 = j;
                        break;
                    }
                }

                if (index2 !== -1) {
                    const filterAry = [...state.chat.selectedUsers];
                    filterAry.splice(index2, 1);
                    return { ...state, chat: { ...state.chat, selectedUsers: filterAry } };
                }
                return { ...state, chat: { ...state.chat, selectedUsers: [...state.chat.selectedUsers, action.payload] } };
            }
            return { ...state, chat: { ...state.chat, selectedUsers: [action.payload] } };
        }

        case 'CHAT_CLEAR_USER': {
            return { ...state, chat: { selectedUsers: [] } };
        }

        case 'FIND_FRIENDS_SAVED_STATUS': {
            return { ...state, findFriendsSavedStatus: action.payload };
        }

        case 'USER_DELETE':
            return { ...state };

        case 'UPDATE_USER_ROLE_FULFILLED':
            if (guard(action, '.payload.status') === 200) {
                return {
                    ...state,
                    userProfile: {
                        ...state.userProfile,
                        user_role: action.payload.data.user_role
                    }
                };
            }
            return { ...state };

        case 'USER_LOGOUT':
            return {
                ...state,
                credentials: {},
                user: {
                    user_firstname: '',
                    user_lastname: '',
                    user_email: '',
                    user_location: null,
                    user_socialmedia: null,
                    user_aboutme: ''
                },
                userProfile: { loading: false },
                chat: { selectedUsers: [] },
                device: {},
                deviceStatus: null,
                findFriendsSavedStatus: {},
                friendRequestsStatus: []
            };

        default:
            return { ...state };
    }
}
