import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import { getPlaydate } from '../../redux/actions/playdates';
import { PlaydateNavigation } from '../../layout/navigation/PlaydateNavigation';
import { InviteFriends } from '../../layout/InviteFriends';
import { getGeoLocation } from '../../helpers/GeoLocation';
import ApiError from '../../helpers/ApiError';
import WhiteTitle from '../../hoc/WhiteTitle';
import Columns from '../../hoc/Columns';
import PlaydatesProfileDetails from './PlaydatesProfileDetails';
import FindFriendsComponent from '../Friends/FindFriendsComponent';
import FriendRequestsComponent from '../Friends/FriendRequestsComponent';
import HelmetTags from '../../helmetTags';

export class PlaydatesProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            playdateInfo: null,
            userLocation: {}
        };
    }

    getGeoLocation = getGeoLocation.bind(this)

    componentDidMount() {
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        // Since this is just getting a profile of the playdate, we dont specifically need the users geolocation from the getgo.
        // We display the playdate profile and then we pass on the userLocation, this will allow a faster load on the profile
        // We will later on calculate the distance of the users current location and the geoposition of the playdate
        this.props.dispatch(getPlaydate(this.props.credentials.token, this.props.match.params.id))
            .then(response => this.setState({ playdateInfo: response.value.data }))
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });

        this.getGeoLocation(this.props, this.getNearByLocations, this.props.credentials.token);
    }

    getNearByLocations = () => {
        this.setState({ userLocation: this.props.userData.user_location });
    }

    render() {
        const c1 = (
            <React.Fragment>
                <div className="simple-navigation">
                    <PlaydateNavigation
                        selected="manage"
                    />
                </div>
            </React.Fragment>
        );

        const c2 = this.state.playdateInfo
            ? (
                <PlaydatesProfileDetails
                    dispatch={this.props.dispatch}
                    credentials={this.props.credentials}
                    playdate={this.state.playdateInfo}
                    userLocation={this.state.userLocation}
                    userData={this.props.userData}
                />
            )
            : (<div />);

        const c3 = (
            <React.Fragment>
                <InviteFriends wrapperClass="noWrapper" />
                <FriendRequestsComponent limit={3} infiniteScroll={false} viewMoreButton={true} />
                <WhiteTitle>Suggested Friends</WhiteTitle>
                <FindFriendsComponent perPage={3} infiniteScroll={false} />
                <Link to="/findfriends" className="square-button greenBG fontWhite h6">View all</Link>
            </React.Fragment>
        );

        const playdateTitle = 'Events | Join and Create Events';
        const pageDescription = 'Create and Join Events and Dog Events near my location';
        const pageImageSmall = null;

        return (
            <React.Fragment>
                <HelmetTags title={playdateTitle || ''} description={pageDescription || ''} image={pageImageSmall} />
                <div className="mainAppContainer playdates">
                    <div className="bodyContent playdateshome">
                        <div className="three-column-layout">
                            <Columns
                                c1={c1}
                                c2={c2}
                                c3={c3}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(PlaydatesProfile);
