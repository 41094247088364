import React from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import { deletePicture, setPicture } from '../../redux/actions/all';
import { getUserProfile } from '../../redux/actions/users';
import Avatar from '../../layout/UI/Avatar';
import GalleryLightbox from '../../layout/gallery/GalleryLightbox';
import Spinner from '../../layout/UI/Spinner';
import NotFound from '../../layout/NotFound';
import PetList from '../../layout/PetList';
import { guard } from '../../helpers/Functions';
import ApiError from '../../helpers/ApiError';
import Columns from '../../hoc/Columns';
import WhiteTitle from '../../hoc/WhiteTitle';

class UserGallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            loading: true,
            // image: null,
            gallery: []
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        this.props.dispatch(getUserProfile(this.props.credentials.token, this.props.match.params.id))
            .then(response => {
                this.setState({
                    loading: false,
                    gallery: response.value.data.images
                });
            })
            .catch(err => {
                this.setState({
                    loading: false
                });
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    handleClickRemoveImage = id => {
        this.props.dispatch(deletePicture(this.props.credentials.token, id))
            .then(() => {
                this.setState(prevState => {
                    const newGallery = prevState.gallery.filter(image => image.image_id !== id);
                    return {
                        gallery: newGallery
                    };
                });
                toast.success('Deleted Image Successfully');
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    handleClickSetImage = id => {
        this.props.dispatch(setPicture(this.props.credentials.token, id))
            .then(() => {
                this.setState({
                    redirect: true
                });
                toast.success('Updated Profile Successfully');
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    render() {
        const c1 = (
            <React.Fragment>
                <WhiteTitle>Profile</WhiteTitle>
                <div className="participantList user-list">
                    <div className="participantListItem wrapper noPadding">
                        <div className="user row">
                            <div className="round-avatar owner-list">
                                <Avatar
                                    src={guard(this.props.userProfile, '.images.0.image_mediumurl')}
                                    alt={this.props.userProfile.user_firstname}
                                    type="user"
                                />
                            </div>
                            <div className="info">
                                <div className="name">
                                    <Link className="fontGreen" to={'/user/profile/' + this.props.userProfile.user_id}>{this.props.userProfile.user_firstname} {this.props.userProfile.user_lastname}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="spacerSmall" />
                <WhiteTitle>Dog(s)</WhiteTitle>
                <PetList dogs={this.props.userProfile.dogs} />
            </React.Fragment>
        );

        let ownUserImages = null;

        const c2 = (
            <GalleryLightbox
                userData={this.props.userData}
                credentials={this.props.credentials}
                // image_id={this.props.userProfile.images.image_id}
                images={this.state.gallery}
                count="all"
                size="normal"
                handleClickSetImage={this.handleClickSetImage}
                handleClickRemoveImage={this.handleClickRemoveImage}
            />
        );

        if (this.state.gallery && this.state.gallery.length > 0 && !this.state.loading) {
            ownUserImages = (<div className="locationProfile locationGallery maxWidth" />);
        }

        if (this.state.gallery && this.state.gallery.length === 0 && !this.state.loading && !this.state.removedImage) {
            ownUserImages = (<NotFound user_id={this.props.match.params.id} type="userimages" class="backgroundRed" />);
        }

        return (
            <div className="mainAppContainer">
                {this.state.redirect && (
                    <Redirect to={'/user/profile/' + this.props.match.params.id + '/edit'} />
                )}
                <div className="bodyContent">
                    {this.state.loading && (<Spinner />)}
                    {ownUserImages}
                    <div className="bodyContent">
                        <div className="two-column-layout">
                            <Columns
                                c1={c1}
                                c2={c2}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

UserGallery.propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.instanceOf(Object).isRequired
};

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        userProfile: state.users.userProfile
    };
}

export default connect(mapStateToProps)(withRouter(UserGallery));
