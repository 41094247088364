import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { logout, updateDeviceToken, refreshOwnProfile } from '../../redux/actions/users';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import Avatar from '../../layout/UI/Avatar';
import DownloadAppButton from '../../layout/DownloadAppButton';
import { guard } from '../../helpers/Functions';
import sb from '../../helpers/SendBird';
import { ShopCart } from '../../../utils/SVGicons';

class MoreMenu extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(refreshOwnProfile(this.props.credentials.token, this.props.credentials.user_id));
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    logout = e => {
        e.preventDefault();
        sb.disconnect(() => { });
        const that = this;
        if (window && window.FB && window.FB.logout) window.FB.logout();
        if (!!window && !!window.facebookConnectPlugin) {
            window.facebookConnectPlugin.getLoginStatus(response => {
                if (!!response && !!response.authResponse && !!response.authResponse.userID) {
                    window.facebookConnectPlugin.api(
                        '/' + response.authResponse.userID + '/permissions?method=delete',
                        ['public_profile', 'email'],
                        () => {
                            window.facebookConnectPlugin.logout(
                                () => that.completeLogout(),
                                () => that.completeLogout()
                            );
                        }, () => {
                            that.completeLogout();
                        }
                    );
                } else {
                    that.completeLogout();
                }
            }, () => {
                that.completeLogout();
            });
        } else {
            that.completeLogout();
        }
    }

    completeLogout = () => {
        if (!!this.props.userProfile && !!this.props.userProfile.device && !!this.props.userProfile.device.device_id) {
            const data = { device_status: 'logged out' };
            this.props.dispatch(updateDeviceToken(this.props.credentials.token, this.props.userProfile.device.device_id, data));
        }
        this.props.dispatch(logout());
        this.props.history.push('/');
    }

    render() {
        let userLink;
        if (this.props.userData && this.props.userData.parententity && this.props.userData.parententity.entity_poochid) {
            userLink = '/' + this.props.userData.parententity.entity_poochid;
        } else {
            userLink = '/user/profile/' + this.props.userData.user_id;
        }

        return (
            <div className="mainAppContainer textList">
                <MainNavigation title="More" />
                <div className="bodyContent">
                    <ul className="list leftIcon">
                        {(!this.props.credentials || !this.props.credentials.user_id) && (
                            <li>
                                <Link to="/" className="fontGreen">
                                    <i className="icn icon-user fontGreen" /> Login/Register
                                </Link>
                            </li>
                        )}
                        {this.props.credentials?.user_id && (
                            <li>
                                <Link to={userLink} className="fontGreen">
                                    <div className="round-avatar small fontGreen">
                                        <Avatar
                                            src={guard(this.props.userData, '.images.0.image_smallurl')}
                                            alt={this.props.userData.user_firstname + ' ' + this.props.userData.user_lastname}
                                            type="user"
                                        />
                                    </div>
                                    {this.props.userData.user_firstname + ' ' + this.props.userData.user_lastname}
                                </Link>
                            </li>
                        )}
                        <li>
                            <Link to="/user/dogs" className="fontGreen">
                                <i className="icn icon-dog fontGreen" /> My Dogs
                            </Link>
                        </li>
                        <li>
                            <Link to="/notifications" className="fontGreen">
                                <i className="icn icon-globe fontRed" /> Notifications
                            </Link>
                        </li>
                        <li>
                            <Link to="/chat" className="fontGreen">
                                <i className="icn icon-no-messages fontRed" /> Chat
                            </Link>
                        </li>
                        <li>
                            <Link to="/favorites" className="fontGreen">
                                <i className="icn icon-heart-fill fontRed" /> Favorites
                            </Link>
                        </li>
                        <li>
                            <Link to="/findfriends" className="fontGreen">
                                <i className="icn icon-couple fontGreen" /> Find Friends
                            </Link>
                        </li>
                        <li>
                            <Link to="/location/add" className="fontGreen">
                                <i className="icn icon-more-add" /> Add a Park or Business
                            </Link>
                        </li>
                        <li>
                            <a href={process.env.REACT_APP_SHOP_LINK} target="_blank" rel="noopener noreferrer" className="fontGreen">
                                <ShopCart /> Shop
                            </a>
                        </li>
                        <li>
                            <Link to="/missingdogs" className="fontGreen">
                                <i className="icn icon-report-lost-dog fontRed" /> Missing Dogs
                            </Link>
                        </li>
                        <li>
                            <Link to="/reportmissing" className="fontGreen">
                                <i className="icn icon-mising-dogs fontRed" /> Report a Lost Dog
                            </Link>
                        </li>
                        {(this.props.userData?.user_role === 'admin' || this.props.userData?.adminlocations?.length > 0 || this.props.userData?.user_role === 'superadmin') && (
                            <li>
                                <Link to="/managelocation" className="fontGreen">
                                    <i className="icn icon-map-search fontRed" /> Manage Locations
                                </Link>
                            </li>
                        )}
                        {this.props.userData?.user_role === 'superadmin' && (
                            <React.Fragment>
                                <li>
                                    <Link to="/manageusers" className="fontGreen">
                                        <i className="icn icon-user fontRed" /> Manage Users
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/manageadministrators" className="fontGreen">
                                        <i className="icn icon-user fontRed" /> Manage Administrators
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/managestats" className="fontGreen">
                                        <i className="icn icon-world fontRed" /> Stats
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/managecontactus" className="fontGreen">
                                        <i className="icn icon-contact-us fontRed" /> Manage Contact Forms
                                    </Link>
                                </li>
                            </React.Fragment>
                        )}
                        <li>
                            <a href={`mailto:${process.env.REACT_APP_EMAIL_SUPPORT}?subject=To the team at Pooch, My UID: ${this.props.credentials.user_id}`} className="fontGreen">
                                <i className="icn icon-contact-us" /> Contact Us
                            </a>
                        </li>
                        <li>
                            <Link to="/settings" className="fontGreen">
                                <i className="icn icon-cog fontGreen" /> Settings
                            </Link>
                        </li>
                        {this.props.credentials?.user_id && (
                            <li>
                                <Link to="/" onClick={this.logout} className="fontGreen">
                                    <i className="icn icon-sign-out fontRed" /> Log Out
                                </Link>
                            </li>
                        )}
                    </ul>
                    <div className="spacerSmall" />
                    <DownloadAppButton />
                    {!!window && !!window.poochAppVersion && (
                        <div className="fontGray alignRight wrapper">
                            version: {window.poochAppVersion}
                        </div>
                    )}
                    <div className="alignRight wrapper fontGreen">
                        <Link to="/terms" className="fontGreen">Terms</Link> • <Link to="/privacy" className="fontGreen">Privacy</Link>
                    </div>
                </div>
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        userProfile: state.users
    };
}

export default connect(mapStateToProps)(MoreMenu);
