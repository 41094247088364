import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { getPlaydate } from '../../redux/actions/playdates';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import Spinner from '../../layout/UI/Spinner';
import NotFound from '../../layout/NotFound';
import { PlaydateNavigation } from '../../layout/navigation/PlaydateNavigation';
import { getGeoLocation } from '../../helpers/GeoLocation';
import ApiError from '../../helpers/ApiError';
import PlaydatesProfileDetails from './PlaydatesProfileDetails';

export class PlaydatesProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            playdateInfo: null,
            loading: true,
            userLocation: {}
        };
    }

    getGeoLocation = getGeoLocation.bind(this)

    componentDidMount() {
        window.scrollTo(0, 0);
        // Since this is just getting a profile of the playdate, we dont specifically need the users geolocation from the getgo.
        // We display the playdate profile and then we pass on the userLocation, this will allow a faster load on the profile
        // We will later on calculate the distance of the users current location and the geoposition of the playdate
        this.props.dispatch(getPlaydate(this.props.credentials.token, this.props.match.params.id))
            .then(response => this.setState({ playdateInfo: response.value.data, loading: false }))
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loading: false });
            });
        this.getGeoLocation(this.props, this.getNearByLocations, this.props.credentials.token);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    getNearByLocations = () => {
        this.setState({ userLocation: this.props.userData.user_location });
    }

    render() {
        return (
            <div className="mainAppContainer playdates">
                {(!this.state.playdateInfo || !this.state.playdateInfo.location) && !this.state.loading
                    ? (<MainNavigation {...this.props} styleClass="" title="Not Found" leftButton="back" />)
                    : (<MainNavigation {...this.props} title={(!!this.state.playdateInfo && !!this.state.playdateInfo.location ? '@ ' + this.state.playdateInfo.location.location_name : '')} rightButton="share" shareTitle="Check out this playdate on Pooch!" />)}
                <PlaydateNavigation />
                <div className="bodyContent">
                    {(!this.state.playdateInfo || !this.state.playdateInfo.location) && !this.state.loading && (
                        <NotFound type="playdate" />
                    )}
                    {!!this.state.loading && (
                        <Spinner />
                    )}
                    {this.state.playdateInfo && (
                        <PlaydatesProfileDetails
                            dispatch={this.props.dispatch}
                            credentials={this.props.credentials}
                            playdate={this.state.playdateInfo}
                            userLocation={this.state.userLocation}
                            userData={this.props.userData}
                        />
                    )}
                </div>
                <SecondaryNavigation selected="events" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(PlaydatesProfile);
