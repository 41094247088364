import { toast } from 'react-toastify';

let toastId = null;
let toastIdError = null;
let totalImages = 1;
let totalImagesError = 1;

export default function reducer(
    state = {
    },
    action
) {
    switch (action.type) {
        case 'UPLOAD_PICTURE_FULFILLED': {
            if (!toast.isActive(toastId)) {
                toastId = toast.success('Uploaded the Picture Successfully');
            } else {
                totalImages += 1;
                toast.update(toastId, {
                    render: 'Uploaded ' + totalImages + ' Pictures Successfully'
                });
            }
            return { ...state };
        }
        case 'UPLOAD_PICTURE_REJECTED': {
            if (!toast.isActive(toastIdError)) {
                toastIdError = toast.error("Couldn't upload a picture, please try again with a smaller JPG or PNG picture");
            } else {
                totalImagesError += 1;
                toast.update(toastIdError, {
                    render: "Couldn't upload " + totalImagesError + ' Pictures'
                });
            }
            return { ...state };
        }
        default:
            return { ...state };
    }
}
