const additionalInputs = {
    water: {
        type: 'checkmark',
        label: 'This place has water available for dogs',
        categories: ['Dog Park']
    },
    offLeash: {
        type: 'checkmark',
        label: 'This place has an Off Leash area',
        categories: ['Dog Park']
    },
    fenced: {
        type: 'checkmark',
        label: 'This place has a fenced area',
        categories: ['Dog Park']
    },
    doggyBags: {
        type: 'checkmark',
        label: 'This place provides Doggy Bags for easy cleanup',
        categories: ['Dog Park']
    },
    mobileBusiness: {
        type: 'checkmark',
        label: 'This is a Mobile Business',
        categories: ['Grooming Services']
    },
    dogPool: {
        type: 'checkmark',
        label: 'This place has a dog pool',
        categories: ['Dog Park', 'Dog Shelters', 'Doggy Daycare', 'Pet Sitting', 'Dog Friendly Hotel']
    },
    pickUp: {
        type: 'checkmark',
        label: 'This place can pick up your pet',
        categories: ['Dog Trainer', 'Doggy Daycare', 'Pet Sitting', 'Dog Walkers']
    },
    gpsTracking: {
        type: 'checkmark',
        label: 'This place offers GPS tracking of your pet',
        categories: ['Dog Walkers']
    },
    photoUpdate: {
        type: 'checkmark',
        label: 'This place provides Photo Updates',
        categories: ['Doggy Daycare', 'Pet Sitting', 'Dog Walkers', 'Pet Photography']
    },
    webcam: {
        type: 'checkmark',
        label: 'This place offers WebCam service for your pet',
        categories: ['Doggy Daycare', 'Pet Sitting']
    },
    rawfood: {
        type: 'checkmark',
        label: 'This place has raw food',
        categories: ['Pet Store']
    },
    toys: {
        type: 'checkmark',
        label: 'This place has toys and accessories',
        categories: ['Pet Store']
    },
    maxNumberDogs: {
        type: 'input',
        label: 'Maximum Number of Dogs Allowed',
        categories: ['Dog Friendly Hotel', 'Pet Sitting', 'Dog Walkers']
    },
    perNight: {
        type: 'input',
        label: 'Cost per Dog per Night',
        categories: ['Dog Friendly Hotel', 'Pet Sitting', 'Dog Walkers']
    },
    maxSize: {
        type: 'input',
        label: 'Max Dog Size allowed',
        categories: ['Dog Friendly Hotel', 'Pet Sitting', 'Dog Walkers']
    }
};

export default additionalInputs;
