import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Textarea from 'react-textarea-autosize';
import ReactGA from 'react-ga';

import {
    setEditLocationContactInfo,
    setEditLocationSocialMedia,
    setEditLocationWeb,
    setEditTimes,
    setEditOpenAllDay,
    setEditLocationName,
    setEditLocationCity,
    setEditLocationState,
    setEditLocationCountry,
    setEditLocationPostal,
    setEditLocationStreet1,
    setEditLocationStreet2,
    setEditLocationDescription,
    setEditCloseAllDay,
    getEditLocation,
    saveLocationEdit,
    setEditLocationTypes,
    setEditLocationFeatured,
    setEditLocationAdditionalInfo,
    deleteLocation
} from '../../redux/actions/locations';
import { getSearchFilters } from '../../redux/actions/searchFilters';
import { uploadPicture } from '../../redux/actions/all';
import DynamicForm from '../../layout/UI/DynamicForm';
import Spinner from '../../layout/UI/Spinner';
import Avatar from '../../layout/UI/Avatar';
import CameraModal from '../../layout/CameraModal';
import BasicConfirm from '../../layout/UI/BasicConfirm';
import ApiError from '../../helpers/ApiError';
import { getGeoLocation } from '../../helpers/GeoLocation';
import { guard, urlFriendly } from '../../helpers/Functions';
import Config from '../../helpers/Config';
import { camera, cameraRoll, cameraBlob } from '../../helpers/Camera';
import CountriesStates from '../../helpers/CountriesStates';
import Columns from '../../hoc/Columns';

class AdminLocation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            image: null,
            imagefile: null,
            pictureModal: false,
            listOfCountries: [],
            listOfStates: [],
            countrySelectedID: -1,
            stateSelectedID: -1,
            originalCountry: '',
            originalState: '',
            contactInfo: {},
            socialMedia: {},
            additionalInfo: {},
            addressChanged: false,
            changed: false,
            entity_poochid: '',
            loadingSubmitForm: false
        };
    }

    getGeoLocation = getGeoLocation.bind(this)

    componentDidMount() {
        if (window?.location?.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        window.scrollTo(0, 0);
        this.props.dispatch(getEditLocation(this.props.credentials.token, this.props.match.params.id))
            .then(response => {
                this.setState({
                    image: guard(this.props.editLocation, '.images.0.image_mediumurl') || null,
                    originalCountry: guard(response.value.data, '.address.address_country') || '',
                    originalState: guard(response.value.data, '.address.address_state') || '',
                    contactInfo: response?.value?.data?.location_contactinfo || {},
                    socialMedia: response?.value?.data?.location_socialmedia || {},
                    additionalInfo: response?.value?.data?.location_additionalinfo || {},
                    entity_poochid: response?.value?.data?.parententity?.entity_poochid || ''
                });
                this.props.dispatch(getSearchFilters(this.props.credentials.token)).then(() => this.sortList());
                this.searchDropdownLocations(
                    guard(this.props.editLocation, '.address.address_country'),
                    guard(this.props.editLocation, '.address.address_state')
                );
            });
        this.countriesDropdownList();
    }

    handlePoochID = e => {
        const poochId = e.target.value.split('pooch.pet/');
        let text = '';
        if (poochId.length > 1) {
            text = poochId[1].replace(/[^a-z0-9_-]+/gi, '').toLowerCase();
        }
        this.setState({
            changed: true,
            entity_poochid: text
        });
    }

    filesChanged = files => {
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            const file = files[i];
            reader.onloadend = () => {
                this.setState({ image: reader.result, imagefile: file });
            };
            reader.readAsDataURL(file);
        }
    }

    sortList = () => {
        let otherOption = null;
        const list = this.props.filterList.map((item, index) => {
            let classes = 'wideButtons transparentBorder fontGreen ';
            if (!!this.props.editLocation && !!this.props.editLocation.locationtypes && this.props.editLocation.locationtypes.some(o => o['locationtype_id'] === item.locationtype_id)) {
                classes += 'whiteBG';
            } else {
                classes += 'whiteOpaque';
            }
            if (item.locationtype_name === 'Other') {
                otherOption = (
                    <Link
                        key={index}
                        to="#"
                        onClick={e => {
                            e.preventDefault();
                            this.selectType(e, { id: item.locationtype_id, name: item.locationtype_name });
                        }}
                        className={classes}
                    >
                        {item.locationtype_name}
                    </Link>
                );
                return null;
            }
            return (
                <Link
                    key={index}
                    to="#"
                    onClick={e => {
                        e.preventDefault();
                        this.selectType(e, { id: item.locationtype_id, name: item.locationtype_name });
                    }}
                    className={classes}
                >
                    {item.locationtype_name}
                </Link>
            );
        });
        list.push(otherOption);
        this.setState({ list });
    }

    selectType = (e, data) => {
        e.target.classList.toggle('whiteOpaque');
        e.target.classList.toggle('whiteBG');
        this.props.dispatch(setEditLocationTypes(data));
    }

    verifyData = e => {
        e.preventDefault();
        if (!!this.props.editLocation && !!this.props.editLocation.address && !!this.props.editLocation.address.address_street && !!this.props.editLocation.address.address_city && !!this.props.editLocation.address.address_country && !!this.props.editLocation.address.address_state) {
            this.saveToApi();
        } else {
            ApiError('Please write your Address, Street 1, City, Country and State/Province');
        }
    }

    deleteLocation = e => {
        e.preventDefault();
        BasicConfirm(
            'Are you sure?',
            'Do you want to permanently remove ' + this.props.editLocation.location_name + ' from the Pooch network?',
            'Yes, Delete it!',
            this.handleClickDelete
        );
    }

    handleClickDelete = () => {
        this.submit.setAttribute('disabled', 'disabled');
        this.setState({ loadingSubmitForm: true });
        this.props.dispatch(deleteLocation(this.props.credentials.token, this.props.editLocation.location_id))
            .then(() => {
                this.setState({ loadingSubmitForm: false }, () => {
                    this.submit.removeAttribute('disabled');
                });
                this.props.history.push('/managelocation/');
            })
            .catch(() => {
                this.setState({ loadingSubmitForm: false }, () => {
                    this.submit.removeAttribute('disabled');
                });
            });
    }

    saveToApi = () => {
        if (this.state.imagefile) {
            this.props.dispatch(uploadPicture(this.props.credentials.token, this.props.editLocation.location_id, this.state.imagefile, true));
        }
        let data = { ...this.props.editLocation };
        if (this.props.editLocation.location_schedule) {
            data = { ...this.props.editLocation, location_schedule: this.props.editLocation.location_schedule };
        }
        if (this.state.addressChanged) {
            data = { ...data, address: { ...data.address, address_latitude: null, address_longitude: null } };
        }
        if (this.state.changed) {
            data = { ...data, entity_poochid: this.state.entity_poochid };
        }
        this.props.dispatch(saveLocationEdit(this.props.credentials.token, this.props.editLocation.location_id, data))
            .catch(err => ApiError(err));
    }

    getLocationData = e => {
        e.preventDefault();
        getGeoLocation(this.props, this.showLocation, this.props.credentials.token, true, 1500);
    }

    showLocation = () => {
        if (guard(this.props.userLocation, '.address_street')) {
            this.props.dispatch(setEditLocationStreet1(this.props.userLocation.address_street));
        }
        if (guard(this.props.userLocation, '.address_street2')) {
            this.props.dispatch(setEditLocationStreet2(this.props.userLocation.address_street2));
        }
        if (guard(this.props.userLocation, '.address_city')) {
            this.props.dispatch(setEditLocationCity(this.props.userLocation.address_city));
        }
        if (guard(this.props.userLocation, '.address_zipcode')) {
            this.props.dispatch(setEditLocationPostal(this.props.userLocation.address_zipcode));
        }
        if (guard(this.props.userLocation, '.address_country')) {
            this.props.dispatch(setEditLocationCountry(this.props.userLocation.address_country));
        }
        if (guard(this.props.userLocation, '.address_state')) {
            this.props.dispatch(setEditLocationState(this.props.userLocation.address_state));
        }
        this.searchDropdownLocations(guard(this.props.userLocation, '.address_country'), guard(this.props.userLocation, '.address_state'));
    }

    toggleModal = () => {
        this.setState({ pictureModal: !this.state.pictureModal });
    }

    changeImage = () => {
        if (Config.isMobileApp) {
            this.toggleModal();
        } else {
            const selectfile = document.getElementById('file');
            selectfile.click();
        }
    }

    phoneCamera = () => {
        camera(this.phoneSuccess);
    }

    phoneUpload = () => {
        cameraRoll(this.phoneSuccess);
    }

    phoneSuccess = file => {
        this.setState({ image: file });
        cameraBlob(file, this.uploadToProfile);
    }

    uploadToProfile = imgBlob => {
        this.setState({ imagefile: imgBlob });
    }

    /**
     * Check the `CountriesStates` list and compare against the `country` received to update the state of the application with the country
     * and after that update show the right list of state/provinces according to the country selected updating the state of the application with the state selected
     *
     * @param {string} country
     * @param {string} state
     */
    searchDropdownLocations = (country, state) => {
        for (const i in CountriesStates) {
            if (CountriesStates[i].country === country) {
                this.setState({ countrySelectedID: i });
                this.stateProvince(i);
                for (const j in CountriesStates[i].states) {
                    if (CountriesStates[i].states[j] === state || (CountriesStates[i].short && CountriesStates[i].short[j] === state)) {
                        this.setState({ stateSelectedID: j });
                        break;
                    }
                }
                break;
            }
        }
    }

    contactInfoHandler = event => {
        this.setState({
            contactInfo: { ...this.state.contactInfo, [event.target.name]: event.target.value }
        }, () => {
            this.props.dispatch(setEditLocationContactInfo(this.state.contactInfo));
        });
    }

    socialMediaHandler = event => {
        this.setState({
            socialMedia: { ...this.state.socialMedia, [event.target.name]: event.target.value }
        }, () => {
            this.props.dispatch(setEditLocationSocialMedia(this.state.socialMedia));
        });
    }

    additionalInfoCheckmarkHandler = e => {
        const target = e.target.id;
        this.setState(prevState => ({
            additionalInfo: { ...this.state.additionalInfo, [target]: !prevState.additionalInfo[target] }
        }), () => {
            this.props.dispatch(setEditLocationAdditionalInfo(this.state.additionalInfo));
        });
    }

    additionalInfoHandler = event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;
        this.setState({
            additionalInfo: { ...this.state.additionalInfo, [targetName]: targetValue }
        }, () => {
            this.props.dispatch(setEditLocationAdditionalInfo(this.state.additionalInfo));
        });
    }

    countriesDropdownList() {
        const countries = CountriesStates.map((location, index) => (
            <option key={index} value={index}>
                {location.country}
            </option>
        ));
        this.setState({ listOfCountries: countries });
    }

    stateProvince(selected) {
        if (selected > -1) {
            const states = CountriesStates[selected].states.map((location, index) => (
                <option key={index} value={index}>
                    {location}
                </option>
            ));
            this.setState({ listOfStates: states });
        }
    }

    changeState(selState) {
        if (selState > -1) {
            if (CountriesStates[this.state.countrySelectedID].short && CountriesStates[this.state.countrySelectedID].short[selState]) {
                this.props.dispatch(setEditLocationState(CountriesStates[this.state.countrySelectedID].short[selState]));
            } else {
                this.props.dispatch(setEditLocationState(CountriesStates[this.state.countrySelectedID].states[selState]));
            }
            this.setState({ stateSelectedID: selState });
        } else {
            this.props.dispatch(setEditLocationState(false));
            this.setState({ stateSelectedID: -1 });
        }
    }

    changeCountry(selCountry) {
        if (selCountry > -1) {
            this.props.dispatch(setEditLocationCountry(CountriesStates[selCountry].country));
            this.props.dispatch(setEditLocationState(false));
            this.setState({ countrySelectedID: selCountry, stateSelectedID: -1, listOfStates: [] }, () => {
                this.stateProvince(selCountry);
            });
        } else {
            this.props.dispatch(setEditLocationCountry(false));
            this.props.dispatch(setEditLocationState(false));
            this.setState({ listOfStates: [], countrySelectedID: -1, stateSelectedID: -1 }, () => {
                this.stateProvince(selCountry);
            });
        }
    }

    render() {
        const schedule = this.props.editLocation.location_schedule;
        // gets the selected categories for this specific location
        // it then passes the data to the DynamicForm, with data handlers, this way it will return all the necessary fields specified by each category
        const selected = (!!this.props.editLocation && !!this.props.editLocation.locationtypes && this.props.editLocation.locationtypes.length > 0) ? this.props.editLocation.locationtypes.map(cat => cat.locationtype_name) : [];
        const additionalInfo = DynamicForm(selected, this.additionalInfoCheckmarkHandler, this.additionalInfoHandler, this.state.additionalInfo);

        const allowMajorEdits = (this.props.userData && ((this.props.userData.user_role === 'superadmin') || ((this.props.userData.user_role === 'admin') && !!guard(this.props.editLocation, '.location_featured'))));

        const form = (
            <React.Fragment>
                <form onSubmit={this.verifyData}>
                    <div className="profilePictureWrapper add-location alignCenter">
                        <div className="manage-location maxWidth wrapper">
                            <div className="profile-avatar">
                                <input
                                    className="hidden"
                                    name="file"
                                    id="file"
                                    type="file"
                                    onChange={e => this.filesChanged(e.target.files)}
                                    accept="image/*"
                                />
                                <div className="fontWhite round-avatar location huge">
                                    <Avatar
                                        src={this.state.image}
                                        alt={this.props.editLocation.location_name}
                                        onClick={this.changeImage}
                                        type="location"
                                        className="changeImage"
                                    />
                                </div>
                            </div>
                            <div onClick={this.changeImage} className="changeImage fontWhite spacerVertical">
                                Change Featured Picture
                            </div>
                        </div>
                    </div>
                    <div className="wrapper maxWidth">
                        <h1 className="fontWhite">
                            Please provide more information
                        </h1>
                    </div>
                    {this.state.loadingSubmitForm
                        ? (<Spinner />)
                        : (
                            <React.Fragment>
                                <div className="wrapper maxWidth">
                                    {allowMajorEdits && (
                                        <div className="innerLabelInput">
                                            <span className="blocking-span">
                                                <input
                                                    className="inputText"
                                                    name="entity_poochid"
                                                    type="text"
                                                    value={'pooch.pet/' + this.state.entity_poochid}
                                                    onChange={this.handlePoochID}
                                                />
                                                <span className="floating-label">Pooch ID</span>
                                            </span>
                                        </div>
                                    )}
                                    <div className="innerLabelInput">
                                        <span className="blocking-span">
                                            <input
                                                className="inputText"
                                                name="locationName"
                                                type="text"
                                                onChange={e => this.props.dispatch(setEditLocationName(e.target.value))}
                                                required
                                                value={guard(this.props.editLocation, '.location_name') || ''}
                                            />
                                            <span className="floating-label">Location Name</span>
                                        </span>
                                    </div>
                                    <div className="innerLabelInput">
                                        <span className="blocking-span">
                                            <input
                                                className="inputText"
                                                name="phone"
                                                type="text"
                                                onChange={this.contactInfoHandler}
                                                value={(this.state.contactInfo && this.state.contactInfo.phone) || ''}
                                            />
                                            <span className="floating-label">Phone Number</span>
                                        </span>
                                    </div>
                                    <div className="innerLabelInput">
                                        <span className="blocking-span">
                                            <input
                                                className="inputText"
                                                name="tollFree"
                                                type="text"
                                                onChange={this.contactInfoHandler}
                                                value={(this.state.contactInfo && this.state.contactInfo.tollFree) || ''}
                                            />
                                            <span className="floating-label">Toll-free Number</span>
                                        </span>
                                    </div>
                                    <div className="innerLabelInput">
                                        <span className="blocking-span">
                                            <input
                                                className="inputText"
                                                name="fax"
                                                type="text"
                                                onChange={this.contactInfoHandler}
                                                value={(this.state.contactInfo && this.state.contactInfo.fax) || ''}
                                            />
                                            <span className="floating-label">Fax Number</span>
                                        </span>
                                    </div>
                                    <div className="innerLabelInput">
                                        <span className="blocking-span">
                                            <input
                                                className="inputText"
                                                name="email"
                                                type="text"
                                                onChange={this.contactInfoHandler}
                                                value={(this.state.contactInfo && this.state.contactInfo.email) || ''}
                                            />
                                            <span className="floating-label">Email</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="wrapper maxWidth">
                                    <h1 className="fontWhite">Online Presence</h1>
                                </div>
                                <div className="wrapper maxWidth">
                                    <div className="innerLabelInput">
                                        <span className="blocking-span">
                                            <input
                                                className="inputText"
                                                name="web"
                                                type="text"
                                                onChange={e => this.props.dispatch(setEditLocationWeb(e.target.value))}
                                                value={guard(this.props.editLocation, '.location_website') || ''}
                                            />
                                            <span className="floating-label">Website</span>
                                        </span>
                                    </div>
                                    <div className="innerLabelInput">
                                        <span className="blocking-span">
                                            <input
                                                className="inputText"
                                                name="facebook"
                                                type="text"
                                                onChange={this.socialMediaHandler}
                                                value={(this.state.socialMedia && this.state.socialMedia.facebook) || ''}
                                            />
                                            <span className="floating-label">Facebook</span>
                                        </span>
                                    </div>
                                    <div className="innerLabelInput">
                                        <span className="blocking-span">
                                            <input
                                                className="inputText"
                                                name="instagram"
                                                type="text"
                                                onChange={this.socialMediaHandler}
                                                value={(this.state.socialMedia && this.state.socialMedia.instagram) || ''}
                                            />
                                            <span className="floating-label">Instagram</span>
                                        </span>
                                    </div>
                                    <div className="innerLabelInput">
                                        <span className="blocking-span">
                                            <input
                                                className="inputText"
                                                name="twitter"
                                                type="text"
                                                onChange={this.socialMediaHandler}
                                                value={(this.state.socialMedia && this.state.socialMedia.twitter) || ''}
                                            />
                                            <span className="floating-label">Twitter</span>
                                        </span>
                                    </div>
                                    <Link
                                        to="#"
                                        className="wideButtons whiteBorder whiteOpaque fontWhite"
                                        onClick={event => this.getLocationData(event)}
                                    >
                                        Use Current Location &gt;
                                    </Link>
                                    <div className="wideButtons transparent fontWhite">
                                        Or write your address
                                    </div>
                                    <div className="innerLabelInput">
                                        <span className="blocking-span">
                                            <input
                                                className="inputText"
                                                name="street1"
                                                type="text"
                                                onChange={e => {
                                                    this.setState({ addressChanged: true });
                                                    this.props.dispatch(setEditLocationStreet1(e.target.value));
                                                }}
                                                required
                                                value={guard(this.props.editLocation, '.address.address_street') || ''}
                                            />
                                            <span className="floating-label">Street Address 1</span>
                                        </span>
                                    </div>
                                    <div className="innerLabelInput">
                                        <span className="blocking-span">
                                            <input
                                                className="inputText"
                                                name="street2"
                                                type="text"
                                                onChange={e => {
                                                    this.setState({ addressChanged: true });
                                                    this.props.dispatch(setEditLocationStreet2(e.target.value));
                                                }}
                                                value={guard(this.props.editLocation, '.address.address_street2') || ''}
                                            />
                                            <span className="floating-label">Street Address 2</span>
                                        </span>
                                    </div>
                                    <div className="innerLabelInput">
                                        <span className="blocking-span">
                                            <input
                                                className="inputText"
                                                name="city"
                                                type="text"
                                                onChange={e => {
                                                    this.setState({ addressChanged: true });
                                                    this.props.dispatch(setEditLocationCity(e.target.value));
                                                }}
                                                required
                                                value={guard(this.props.editLocation, '.address.address_city') || ''}
                                            />
                                            <span className="floating-label">City</span>
                                        </span>
                                    </div>
                                    <div className="innerLabelInput">
                                        <span className="h6 fontWhite">
                                            Country {this.state.originalCountry ? '(' + this.state.originalCountry + ')' : ''}
                                        </span>
                                        <select
                                            required
                                            value={this.state.countrySelectedID}
                                            className="blankMultipleChoice fontWhite"
                                            onChange={e => {
                                                this.setState({ addressChanged: true });
                                                this.changeCountry(e.target.value);
                                            }}
                                        >
                                            <option value={-1}>Select a Country</option>
                                            {this.state.listOfCountries.length > 0 && this.state.listOfCountries}
                                        </select>
                                    </div>
                                    {!!this.state.listOfStates && this.state.listOfStates.length > 0 && (
                                        <div className="innerLabelInput">
                                            <span className="h6 fontWhite">
                                                State/Province {this.state.originalState ? '(' + this.state.originalState + ')' : ''}
                                            </span>
                                            <select
                                                required
                                                value={this.state.stateSelectedID}
                                                className="blankMultipleChoice fontWhite"
                                                onChange={e => {
                                                    this.setState({ addressChanged: true });
                                                    this.changeState(e.target.value);
                                                }}
                                            >
                                                <option value={-1}>Select a State/Province</option>
                                                {this.state.listOfStates}
                                            </select>
                                        </div>
                                    )}
                                    <div className="innerLabelInput">
                                        <span className="blocking-span">
                                            <input
                                                className="inputText"
                                                name="postal"
                                                type="text"
                                                onChange={e => {
                                                    this.setState({ addressChanged: true });
                                                    this.props.dispatch(setEditLocationPostal(e.target.value));
                                                }}
                                                value={guard(this.props.editLocation, '.address.address_zipcode') || ''}
                                            />
                                            <span className="floating-label">Postal Code</span>
                                        </span>
                                    </div>
                                    <div className="fontWhite h5">Brief Description</div>
                                    <Textarea
                                        onChange={e => this.props.dispatch(setEditLocationDescription(e.target.value))}
                                        className="niceTextarea smallmargin"
                                        placeholder="Write something about this Location"
                                        value={guard(this.props.editLocation, '.location_description') || ''}
                                    />
                                    <div className="spacerSmall" />
                                    <h4 className="fontWhite">Hours of Operation</h4>
                                    <div className="section timeTableHolder">
                                        <div className="timeTable fontWhite">
                                            <div className="row">
                                                <div>Monday:</div>
                                                <div>
                                                    <input
                                                        name="mondayHoursOpen"
                                                        placeholder="8am"
                                                        onChange={e => this.props.dispatch(setEditTimes('monday', 'open', e.target.value))}
                                                        value={guard(schedule, '.monday.open') || ''}
                                                    />
                                                </div>
                                                <div>-</div>
                                                <div>
                                                    <input
                                                        name="mondayHoursClose"
                                                        placeholder="8pm"
                                                        onChange={e => this.props.dispatch(setEditTimes('monday', 'close', e.target.value))}
                                                        value={guard(schedule, '.monday.close') || ''}
                                                    />
                                                </div>
                                                <div className="checkmarks">
                                                    <div className="row">
                                                        <div>Closed:</div>
                                                        <label className="checkmarkContainer inline h6">
                                                            <input
                                                                type="checkbox"
                                                                name="mondayClosed"
                                                                onClick={() => this.props.dispatch(setEditCloseAllDay('monday'))}
                                                                onChange={() => { }}
                                                                checked={!!guard(schedule, '.monday.closed')}
                                                            />
                                                            <span className="checkmark small" />
                                                        </label>
                                                    </div>
                                                    <div className="row">
                                                        <div>24h:</div>
                                                        <label className="checkmarkContainer inline h6">
                                                            <input
                                                                type="checkbox"
                                                                name="monday24h"
                                                                onClick={() => this.props.dispatch(setEditOpenAllDay('monday'))}
                                                                onChange={() => { }}
                                                                checked={!!guard(schedule, '.monday.allday')}
                                                            />
                                                            <span className="checkmark small" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div>Tuesday:</div>
                                                <div>
                                                    <input
                                                        name="tuesdayHoursOpen"
                                                        placeholder="8am"
                                                        onChange={e => this.props.dispatch(setEditTimes('tuesday', 'open', e.target.value))}
                                                        value={guard(schedule, '.tuesday.open') || ''}
                                                    />
                                                </div>
                                                <div>-</div>
                                                <div>
                                                    <input
                                                        name="tuesdayHoursClose"
                                                        placeholder="8pm"
                                                        onChange={e => this.props.dispatch(setEditTimes('tuesday', 'close', e.target.value))}
                                                        value={guard(schedule, '.tuesday.close') || ''}
                                                    />
                                                </div>
                                                <div className="checkmarks">
                                                    <div className="row">
                                                        <div>Closed:</div>
                                                        <label className="checkmarkContainer inline h6">
                                                            <input
                                                                type="checkbox"
                                                                name="tuesdayClosed"
                                                                onClick={() => this.props.dispatch(setEditCloseAllDay('tuesday'))}
                                                                onChange={() => { }}
                                                                checked={!!guard(schedule, '.tuesday.closed')}
                                                            />
                                                            <span className="checkmark small" />
                                                        </label>
                                                    </div>
                                                    <div className="row">
                                                        <div>24h:</div>
                                                        <label className="checkmarkContainer inline h6">
                                                            <input
                                                                type="checkbox"
                                                                name="tuesday24h"
                                                                onClick={() => this.props.dispatch(setEditOpenAllDay('tuesday'))}
                                                                onChange={() => { }}
                                                                checked={!!guard(schedule, '.tuesday.allday')}
                                                            />
                                                            <span className="checkmark small" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div>Wednesday:</div>
                                                <div>
                                                    <input
                                                        name="wednesdayHoursOpen"
                                                        placeholder="8am"
                                                        onChange={e => this.props.dispatch(setEditTimes('wednesday', 'open', e.target.value))}
                                                        value={guard(schedule, '.wednesday.open') || ''}
                                                    />
                                                </div>
                                                <div>-</div>
                                                <div>
                                                    <input
                                                        name="wednesdayHoursClose"
                                                        placeholder="8pm"
                                                        onChange={e => this.props.dispatch(setEditTimes('wednesday', 'close', e.target.value))}
                                                        value={guard(schedule, '.wednesday.close') || ''}
                                                    />
                                                </div>
                                                <div className="checkmarks">
                                                    <div className="row">
                                                        <div>Closed:</div>
                                                        <label className="checkmarkContainer inline h6">
                                                            <input
                                                                type="checkbox"
                                                                name="wednesdayClosed"
                                                                onClick={() => this.props.dispatch(setEditCloseAllDay('wednesday'))}
                                                                onChange={() => { }}
                                                                checked={!!guard(schedule, '.wednesday.closed')}
                                                            />
                                                            <span className="checkmark small" />
                                                        </label>
                                                    </div>
                                                    <div className="row">
                                                        <div>24h:</div>
                                                        <label className="checkmarkContainer inline h6">
                                                            <input
                                                                type="checkbox"
                                                                name="wednesday24h"
                                                                onClick={() => this.props.dispatch(setEditOpenAllDay('wednesday'))}
                                                                onChange={() => { }}
                                                                checked={!!guard(schedule, '.wednesday.allday')}
                                                            />
                                                            <span className="checkmark small" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div>Thursday:</div>
                                                <div>
                                                    <input
                                                        name="thursdayHoursOpen"
                                                        placeholder="8am"
                                                        onChange={e => this.props.dispatch(setEditTimes('thursday', 'open', e.target.value))}
                                                        value={guard(schedule, '.thursday.open') || ''}
                                                    />
                                                </div>
                                                <div>-</div>
                                                <div>
                                                    <input
                                                        name="thursdayHoursClose"
                                                        placeholder="8pm"
                                                        onChange={e => this.props.dispatch(setEditTimes('thursday', 'close', e.target.value))}
                                                        value={guard(schedule, '.thursday.close') || ''}
                                                    />
                                                </div>
                                                <div className="checkmarks">
                                                    <div className="row">
                                                        <div>Closed:</div>
                                                        <label className="checkmarkContainer inline h6">
                                                            <input
                                                                type="checkbox"
                                                                name="thursdayClosed"
                                                                onClick={() => this.props.dispatch(setEditCloseAllDay('thursday'))}
                                                                onChange={() => { }}
                                                                checked={!!guard(schedule, '.thursday.closed')}
                                                            />
                                                            <span className="checkmark small" />
                                                        </label>
                                                    </div>
                                                    <div className="row">
                                                        <div>24h:</div>
                                                        <label className="checkmarkContainer inline h6">
                                                            <input
                                                                type="checkbox"
                                                                name="thursday24h"
                                                                onClick={() => this.props.dispatch(setEditOpenAllDay('thursday'))}
                                                                onChange={() => { }}
                                                                checked={!!guard(schedule, '.thursday.allday')}
                                                            />
                                                            <span className="checkmark small" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div>Friday:</div>
                                                <div>
                                                    <input
                                                        name="fridayHoursOpen"
                                                        placeholder="8am"
                                                        onChange={e => this.props.dispatch(setEditTimes('friday', 'open', e.target.value))}
                                                        value={guard(schedule, '.friday.open') || ''}
                                                    />
                                                </div>
                                                <div>-</div>
                                                <div>
                                                    <input
                                                        name="fridayHoursClose"
                                                        placeholder="8pm"
                                                        onChange={e => this.props.dispatch(setEditTimes('friday', 'close', e.target.value))}
                                                        value={guard(schedule, '.friday.close') || ''}
                                                    />
                                                </div>
                                                <div className="checkmarks">
                                                    <div className="row">
                                                        <div>Closed:</div>
                                                        <label className="checkmarkContainer inline h6">
                                                            <input
                                                                type="checkbox"
                                                                name="fridayClosed"
                                                                onClick={() => this.props.dispatch(setEditCloseAllDay('friday'))}
                                                                onChange={() => { }}
                                                                checked={!!guard(schedule, '.friday.closed')}
                                                            />
                                                            <span className="checkmark small" />
                                                        </label>
                                                    </div>
                                                    <div className="row">
                                                        <div>24h:</div>
                                                        <label className="checkmarkContainer inline h6">
                                                            <input
                                                                type="checkbox"
                                                                name="friday24h"
                                                                onClick={() => this.props.dispatch(setEditOpenAllDay('friday'))}
                                                                onChange={() => { }}
                                                                checked={!!guard(schedule, '.friday.allday')}
                                                            />
                                                            <span className="checkmark small" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {// this.props.editLocation.hourSet !== 'weekdays' &&
                                                <div className="row">
                                                    <div>Saturday:</div>
                                                    <div>
                                                        <input
                                                            name="saturdayHoursOpen"
                                                            placeholder="8am"
                                                            onChange={e => this.props.dispatch(setEditTimes('saturday', 'open', e.target.value))}
                                                            value={guard(schedule, '.saturday.open') || ''}
                                                        />
                                                    </div>
                                                    <div>-</div>
                                                    <div>
                                                        <input
                                                            name="saturdayHoursClose"
                                                            placeholder="8pm"
                                                            onChange={e => this.props.dispatch(setEditTimes('saturday', 'close', e.target.value))}
                                                            value={guard(schedule, '.saturday.close') || ''}
                                                        />
                                                    </div>
                                                    <div className="checkmarks">
                                                        <div className="row">
                                                            <div>Closed:</div>
                                                            <label className="checkmarkContainer inline h6">
                                                                <input
                                                                    type="checkbox"
                                                                    name="saturdayClosed"
                                                                    onClick={() => this.props.dispatch(setEditCloseAllDay('saturday'))}
                                                                    onChange={() => { }}
                                                                    checked={!!guard(schedule, '.saturday.closed')}
                                                                />
                                                                <span className="checkmark small" />
                                                            </label>
                                                        </div>
                                                        <div className="row">
                                                            <div>24h:</div>
                                                            <label className="checkmarkContainer inline h6">
                                                                <input
                                                                    type="checkbox"
                                                                    name="saturday24h"
                                                                    onClick={() => this.props.dispatch(setEditOpenAllDay('saturday'))}
                                                                    onChange={() => { }}
                                                                    checked={!!guard(schedule, '.saturday.allday')}
                                                                />
                                                                <span className="checkmark small" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {// this.props.editLocation.hourSet !== 'weekdays' &&
                                                <div className="row">
                                                    <div>Sunday:</div>
                                                    <div>
                                                        <input
                                                            name="sundayHoursOpen"
                                                            placeholder="8am"
                                                            onChange={e => this.props.dispatch(setEditTimes('sunday', 'open', e.target.value))}
                                                            value={guard(schedule, '.sunday.open') || ''}
                                                        />
                                                    </div>
                                                    <div>-</div>
                                                    <div>
                                                        <input
                                                            name="sundayHoursClose"
                                                            placeholder="8pm"
                                                            onChange={e => this.props.dispatch(setEditTimes('sunday', 'close', e.target.value))}
                                                            value={guard(schedule, '.sunday.close') || ''}
                                                        />
                                                    </div>
                                                    <div className="checkmarks">
                                                        <div className="row">
                                                            <div>Closed:</div>
                                                            <label className="checkmarkContainer inline h6">
                                                                <input
                                                                    type="checkbox"
                                                                    name="sundayClosed"
                                                                    onClick={() => this.props.dispatch(setEditCloseAllDay('sunday'))}
                                                                    onChange={() => { }}
                                                                    checked={!!guard(schedule, '.sunday.closed')}
                                                                />
                                                                <span className="checkmark small" />
                                                            </label>
                                                        </div>
                                                        <div className="row">
                                                            <div>24h:</div>
                                                            <label className="checkmarkContainer inline h6">
                                                                <input
                                                                    type="checkbox"
                                                                    name="sunday24h"
                                                                    onClick={() => this.props.dispatch(setEditOpenAllDay('sunday'))}
                                                                    onChange={() => { }}
                                                                    checked={!!guard(schedule, '.sunday.allday')}
                                                                />
                                                                <span className="checkmark small" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="spacerSmall" />
                                    <h4 className="fontWhite">Services offered</h4>
                                    <div className="wrapper maxWidth flex-grid">
                                        {this.state.list}
                                    </div>
                                    {additionalInfo?.length > 0 && (
                                        <div className="wrapper maxWidth fontWhite">
                                            <h4>Other Services</h4>
                                            {additionalInfo}
                                        </div>
                                    )}
                                    {this.props?.userData?.user_role === 'superadmin' && (
                                        <div>
                                            <div className="spacerSmall" />
                                            <h4 className="fontWhite">Featured</h4>
                                            <div className="checkmarks">
                                                <div className="row">
                                                    <label className="checkmarkContainer inlineright h6">
                                                        <input
                                                            type="checkbox"
                                                            checked={!!guard(this.props.editLocation, '.location_featured')}
                                                            onChange={() => this.props.dispatch(setEditLocationFeatured())}
                                                        />
                                                        <span className="checkmark small" />
                                                    </label>
                                                    <span className="fontWhite h5">Featured Business</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="spacerSmall" />
                                    {!this.state.loadingSubmitForm && (
                                        <input
                                            type="submit"
                                            value="Save &gt;"
                                            to="#"
                                            className="rightRoundButton whiteBorder whiteOpaque fontWhite"
                                        />
                                    )}
                                    {this.props?.editLocation?.location_name && (
                                        <Link
                                            to={'/location/profile/' + this.props.editLocation.location_id + '/' + urlFriendly(this.props.editLocation.location_name)}
                                            className="rightRoundButton whiteBorder whiteOpaque fontWhite"
                                        >
                                            View Profile
                                        </Link>
                                    )}
                                    {this.props?.userData?.user_role === 'superadmin' && (
                                        <a
                                            href="# "
                                            ref={submit => { this.submit = submit; }}
                                            className="rightRoundButton redBorder redBG fontWhite"
                                            onClick={this.deleteLocation}
                                        >
                                            Delete Location
                                        </a>
                                    )}
                                    {((this.props?.userData?.user_role === 'superadmin') || (this.props?.userData?.user_role === 'admin' && this.props?.userData?.adminlocations?.[0]?.location_id === this.props.match.params.id)) && (
                                        <Link
                                            to={'/location/gallery/' + this.props.match.params.id + '/' + urlFriendly(this.props.match.params.name)}
                                            className="rightRoundButton whiteBorder whiteOpaque fontWhite"
                                        >
                                            View Gallery
                                        </Link>
                                    )}
                                </div>
                            </React.Fragment>
                        )}
                </form>
            </React.Fragment>
        );

        return (
            <div className="mainAppContainer LoginScreens">
                {this.state.pictureModal && (
                    <CameraModal
                        toggleModal={this.toggleModal}
                        callbackCamera={this.phoneCamera}
                        callbackUpload={this.phoneUpload}
                    />
                )}
                <div className="bodyContent backgroundGreen">
                    <div className="three-column-layout">
                        <Columns
                            c1={(<React.Fragment />)}
                            c2={form}
                            c3={(<React.Fragment />)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        editLocation: state.locations.editLocation,
        filterList: state.searchFilters.filterList,
        userLocation: state.users.user.user_location
    };
}

export default connect(mapStateToProps)(AdminLocation);
