import React from 'react';
import PropTypes from 'prop-types';

export class Stars extends React.Component {
    render() {
        let outlineClassName = 'icon-star-outline fontGold';
        let filledClassName = 'icon-star-fill fontGold';
        const totalStars = parseInt(this.props.stars, 10);

        if (this.props.type === 'dogs') {
            outlineClassName = 'icon-dog fontGray';
            filledClassName = 'icon-dog fontBlue';
        }

        return (
            <span className={'stars' + (this.props.big ? ' bigStars' : '')}>
                {totalStars === 0 && (
                    <span>
                        <i className={'icn ' + outlineClassName} onClick={() => this.props.onClick && this.props.onClick(1)} />
                        <i className={'icn ' + outlineClassName} onClick={() => this.props.onClick && this.props.onClick(2)} />
                        <i className={'icn ' + outlineClassName} onClick={() => this.props.onClick && this.props.onClick(3)} />
                        <i className={'icn ' + outlineClassName} onClick={() => this.props.onClick && this.props.onClick(4)} />
                        <i className={'icn ' + outlineClassName} onClick={() => this.props.onClick && this.props.onClick(5)} />
                    </span>
                )}
                {totalStars >= 1 && totalStars < 2 && (
                    <span>
                        <i className={'icn ' + filledClassName} onClick={() => this.props.onClick && this.props.onClick(1)} />
                        <i className={'icn ' + outlineClassName} onClick={() => this.props.onClick && this.props.onClick(2)} />
                        <i className={'icn ' + outlineClassName} onClick={() => this.props.onClick && this.props.onClick(3)} />
                        <i className={'icn ' + outlineClassName} onClick={() => this.props.onClick && this.props.onClick(4)} />
                        <i className={'icn ' + outlineClassName} onClick={() => this.props.onClick && this.props.onClick(5)} />
                    </span>
                )}
                {totalStars >= 2 && totalStars < 3 && (
                    <span>
                        <i className={'icn ' + filledClassName} onClick={() => this.props.onClick && this.props.onClick(1)} />
                        <i className={'icn ' + filledClassName} onClick={() => this.props.onClick && this.props.onClick(2)} />
                        <i className={'icn ' + outlineClassName} onClick={() => this.props.onClick && this.props.onClick(3)} />
                        <i className={'icn ' + outlineClassName} onClick={() => this.props.onClick && this.props.onClick(4)} />
                        <i className={'icn ' + outlineClassName} onClick={() => this.props.onClick && this.props.onClick(5)} />
                    </span>
                )}
                {totalStars >= 3 && totalStars < 4 && (
                    <span>
                        <i className={'icn ' + filledClassName} onClick={() => this.props.onClick && this.props.onClick(1)} />
                        <i className={'icn ' + filledClassName} onClick={() => this.props.onClick && this.props.onClick(2)} />
                        <i className={'icn ' + filledClassName} onClick={() => this.props.onClick && this.props.onClick(3)} />
                        <i className={'icn ' + outlineClassName} onClick={() => this.props.onClick && this.props.onClick(4)} />
                        <i className={'icn ' + outlineClassName} onClick={() => this.props.onClick && this.props.onClick(5)} />
                    </span>
                )}
                {totalStars >= 4 && totalStars < 5 && (
                    <span>
                        <i className={'icn ' + filledClassName} onClick={() => this.props.onClick && this.props.onClick(1)} />
                        <i className={'icn ' + filledClassName} onClick={() => this.props.onClick && this.props.onClick(2)} />
                        <i className={'icn ' + filledClassName} onClick={() => this.props.onClick && this.props.onClick(3)} />
                        <i className={'icn ' + filledClassName} onClick={() => this.props.onClick && this.props.onClick(4)} />
                        <i className={'icn ' + outlineClassName} onClick={() => this.props.onClick && this.props.onClick(5)} />
                    </span>
                )}
                {totalStars === 5 && (
                    <span>
                        <i className={'icn ' + filledClassName} onClick={() => this.props.onClick && this.props.onClick(1)} />
                        <i className={'icn ' + filledClassName} onClick={() => this.props.onClick && this.props.onClick(2)} />
                        <i className={'icn ' + filledClassName} onClick={() => this.props.onClick && this.props.onClick(3)} />
                        <i className={'icn ' + filledClassName} onClick={() => this.props.onClick && this.props.onClick(4)} />
                        <i className={'icn ' + filledClassName} onClick={() => this.props.onClick && this.props.onClick(5)} />
                    </span>
                )}
            </span>
        );
    }
}

Stars.propTypes = {
    type: PropTypes.string,
    big: PropTypes.bool,
    onClick: PropTypes.func,
    stars: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
