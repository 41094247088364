import apiConnection from '../../helpers/Api';
import { cleanJson } from '../../helpers/Functions';

export const getLocations = (token, geopoint, type, search, page = 1, perPage = 30, extraFilter = '') => {
    let query = '?expand=address,images&per-page=' + perPage + '&page=' + page;

    if (geopoint) {
        query += '&location=' + geopoint.address_latitude + ',' + geopoint.address_longitude;
    }

    if (search) {
        query += '&search=' + search.trim();
    }

    if (type !== 'all') {
        query += '&filter[locationtype_name][eq]=' + type.trim();
    }

    if (extraFilter) {
        query += extraFilter;
    }

    return {
        type: 'GET_LOCATIONS',
        payload: apiConnection.get('locations' + query, { headers: { Authorization: token } })
    };
};

export function getManagerLocations(token, geopoint, type, search, page = 1) {
    let query = '?expand=address,images&per-page=30&page=' + page;

    if (geopoint) {
        query += '&location=' + geopoint.address_latitude + ',' + geopoint.address_longitude;
    }

    if (search) {
        query += '&filter[or][][location_name][like]=' + search.trim();
        query += '&filter[or][][address_city][like]=' + search.trim();
    }

    if (type !== 'all') {
        query += '&filter[locationtype_name][eq]=' + type.trim();
    }

    return {
        type: 'GET_LOCATIONS',
        payload: apiConnection.get('locations' + query, { headers: { Authorization: token } })
    };
}

export function addLocation(token, data) {
    return {
        type: 'ADD_LOCATION',
        payload: apiConnection.post('locations', cleanJson(data), { headers: { Authorization: token } })
    };
}

export function setSearchLocation(search) {
    return {
        type: 'SET_SEARCH_LOCATION',
        payload: search
    };
}

export function setSearchedLocationsList(payload) {
    return {
        type: 'SET_SEARCHED_LOCATIONS_LIST',
        payload
    };
}

export function setSearchedLocationsPosition(payload) {
    return {
        type: 'SET_SEARCHED_LOCATIONS_POSITION',
        payload
    };
}

export function getLocation(token, id) {
    return {
        type: 'GET_LOCATION',
        payload: apiConnection.get('locations/' + id + '?expand=address,topusers.images, topusers.myfriendship, topusers.lastaddress, parententity,images,locationtypes,adminlocations.user', { headers: { Authorization: token } })
    };
}

export function setReviewPicture(file) {
    return {
        type: 'SET_REVIEW_PICTURE',
        payload: file
    };
}

export function clearReviewPictureFile() {
    return {
        type: 'CLEAR_REVIEW_PICTURE_FILE'
    };
}

export function setReviewStars(val) {
    return {
        type: 'SET_REVIEW_STARS',
        payload: val
    };
}

export function setReviewDescription(val) {
    return {
        type: 'SET_REVIEW_DESCRIPTION',
        payload: val
    };
}

export function saveReview(token, id, data) {
    return {
        type: 'SAVE_REVIEW',
        payload: apiConnection.post('locations/review/' + id, cleanJson(data), { headers: { Authorization: token } })
    };
}

export function checkIn(token, id) {
    return {
        type: 'CHECKIN',
        payload: apiConnection.post('locations/checkin/' + id, null, { headers: { Authorization: token } })
    };
}

export function clearReview() {
    return {
        type: 'CLEAR_REVIEW'
    };
}

export function checkFavorite(token, id) {
    return {
        type: 'CHECK_FAVORITE',
        payload: apiConnection.post('locations/favorite/' + id, null, { headers: { Authorization: token } })
    };
}

export function removeFavorite(token, id) {
    return {
        type: 'REMOVE_FAVORITE',
        payload: apiConnection.delete('locations/favorite/' + id, { headers: { Authorization: token } })
    };
}

export function getFavorites(token, page = 1) {
    const query = '?expand=images&per-page=30&page=' + page;
    return {
        type: 'GET_FAVORITES',
        payload: apiConnection.get('locations/favorite' + query, { headers: { Authorization: token } })
    };
}

export function setCreateLocationName(val) {
    return {
        type: 'SET_CREATE_LOCATION_NAME',
        payload: val
    };
}

export function setCreateLocationCity(val) {
    return {
        type: 'SET_CREATE_LOCATION_CITY',
        payload: val
    };
}

export function setCreateLocationState(val) {
    return {
        type: 'SET_CREATE_LOCATION_STATE',
        payload: val
    };
}

export function setCreateLocationCountry(val) {
    return {
        type: 'SET_CREATE_LOCATION_COUNTRY',
        payload: val
    };
}

export function setCreateLocationPostal(val) {
    return {
        type: 'SET_CREATE_LOCATION_POSTAL',
        payload: val
    };
}

export function setCreateLocationStreet1(val) {
    return {
        type: 'SET_CREATE_LOCATION_STREET1',
        payload: val
    };
}

export function setCreateLocationStreet2(val) {
    return {
        type: 'SET_CREATE_LOCATION_STREET2',
        payload: val
    };
}

export function setCreateLocationGeopoint(val) {
    return {
        type: 'SET_CREATE_LOCATION_GEOPOINT',
        payload: val
    };
}

export function setCreateLocationDescription(val) {
    return {
        type: 'SET_CREATE_LOCATION_DESCRIPTION',
        payload: val
    };
}

export function setCreateLocationContactInfo(val) {
    return {
        type: 'SET_CREATE_LOCATION_CONTACT_INFO',
        payload: val
    };
}

export function setCreateLocationSocialMedia(val) {
    return {
        type: 'SET_CREATE_LOCATION_SOCIAL_MEDIA',
        payload: val
    };
}

export function setCreateLocationAdditionalInfo(val) {
    return {
        type: 'SET_CREATE_LOCATION_ADDITIONAL_INFO',
        payload: val
    };
}

export function setCreateLocationWeb(val) {
    return {
        type: 'SET_CREATE_LOCATION_WEB',
        payload: val
    };
}

export function setCreateLocationHours(val) {
    return {
        type: 'SET_CREATE_LOCATION_HOURS',
        payload: val
    };
}

export function setCreateLocationTypes(data) {
    return {
        type: 'SET_CREATE_LOCATION_TYPES',
        payload: data
    };
}

export function setCreateHourSet(set) {
    return {
        type: 'SET_CREATE_HOUR_SET',
        payload: set
    };
}

export function setCreateTimes(day, openClose, value) {
    return {
        type: 'SET_CREATE_TIMES',
        payload: { [day]: { [openClose]: value } }
    };
}

export function setCreateCloseAllDay(day) {
    return {
        type: 'SET_CREATE_CLOSE_ALL_DAY',
        payload: day
    };
}

export function setCreateOpenAllDay(day) {
    return {
        type: 'SET_CREATE_OPEN_ALL_DAY',
        payload: day
    };
}

export function deleteLocation(token, id) {
    return {
        type: 'DELETE_LOCATION',
        payload: apiConnection.delete('locations/' + id, { headers: { Authorization: token } })
    };
}

/**
 * When this is sent to the API, if the `address_latitude` and `address_longitude` it's not send,
 * the API use the full address and calls Google API to determine coordinates,
 * so if we try to use (for test for example) a fake address this won't be returned as was sent
 *
 * @param {String} token
 * @param {String} id
 * @param {Object} data
 */
export function saveLocationEdit(token, id, data) {
    return {
        type: 'SAVE_EDIT_LOCATION',
        payload: apiConnection.put('locations/' + id + '?expand=address,parententity,images,locationtypes', cleanJson(data), { headers: { Authorization: token } })
    };
}

export function getEditLocation(token, id) {
    return {
        type: 'GET_EDIT_LOCATION',
        payload: apiConnection.get('locations/' + id + '?expand=address,parententity,images,locationtypes', { headers: { Authorization: token } })
    };
}

export function setEditLocationName(val) {
    return {
        type: 'SET_EDIT_LOCATION_NAME',
        payload: val
    };
}

export function setEditLocationCity(val) {
    return {
        type: 'SET_EDIT_LOCATION_CITY',
        payload: val
    };
}

export function setEditLocationState(val) {
    return {
        type: 'SET_EDIT_LOCATION_STATE',
        payload: val
    };
}

export function setEditLocationCountry(val) {
    return {
        type: 'SET_EDIT_LOCATION_COUNTRY',
        payload: val
    };
}

export function setEditLocationPostal(val) {
    return {
        type: 'SET_EDIT_LOCATION_POSTAL',
        payload: val
    };
}

export function setEditLocationStreet1(val) {
    return {
        type: 'SET_EDIT_LOCATION_STREET1',
        payload: val
    };
}

export function setEditLocationStreet2(val) {
    return {
        type: 'SET_EDIT_LOCATION_STREET2',
        payload: val
    };
}

export function setEditLocationGeopoint(val) {
    return {
        type: 'SET_EDIT_LOCATION_GEOPOINT',
        payload: val
    };
}

export function setEditLocationDescription(val) {
    return {
        type: 'SET_EDIT_LOCATION_DESCRIPTION',
        payload: val
    };
}

export function setEditLocationContactInfo(val) {
    return {
        type: 'SET_EDIT_LOCATION_CONTACT_INFO',
        payload: val
    };
}

export function setEditLocationSocialMedia(val) {
    return {
        type: 'SET_EDIT_LOCATION_SOCIAL_MEDIA',
        payload: val
    };
}

export function setEditLocationAdditionalInfo(val) {
    return {
        type: 'SET_EDIT_LOCATION_ADDITIONAL_INFO',
        payload: val
    };
}

export function setEditLocationWeb(val) {
    return {
        type: 'SET_EDIT_LOCATION_WEB',
        payload: val
    };
}

export function setEditLocationHours(val) {
    return {
        type: 'SET_EDIT_LOCATION_HOURS',
        payload: val
    };
}

export function setEditLocationFeatured(val) {
    return {
        type: 'SET_EDIT_LOCATION_FEATURED',
        payload: val
    };
}

export function setEditLocationTypes(id) {
    return {
        type: 'SET_EDIT_LOCATION_TYPES',
        payload: id
    };
}

export function setEditHourSet(set) {
    return {
        type: 'SET_EDIT_HOUR_SET',
        payload: set
    };
}

export function setEditTimes(day, openClose, value) {
    return {
        type: 'SET_EDIT_TIMES',
        payload: { [day]: { [openClose]: value } }
    };
}

export function setEditCloseAllDay(day) {
    return {
        type: 'SET_EDIT_CLOSE_ALL_DAY',
        payload: day
    };
}

export function setEditOpenAllDay(day) {
    return {
        type: 'SET_EDIT_OPEN_ALL_DAY',
        payload: day
    };
}

export function setMapLastLocationPosition(payload) {
    return {
        type: 'SET_MAP_LAST_LOCATION_POSITION',
        payload
    };
}
