import React from 'react';
import Textarea from 'react-textarea-autosize';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import Avatar from '../UI/Avatar';
import { addPost, uploadPicture } from '../../redux/actions/all';
import { saveActivityScreenDetails } from '../../redux/actions/activities';
import ApiError from '../../helpers/ApiError';
import { camera, cameraMultiRoll, cameraBlob } from '../../helpers/Camera';
import Config from '../../helpers/Config';
import CameraModal from '../../layout/CameraModal';
import GalleryLightbox from '../../layout/gallery/GalleryLightbox';

class LeaveComment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: '',
            submitting: false,
            images: [],
            previews: [],
            pictureModal: false
        };
    }

    filesChanged = files => {
        const tempImageUploaded = [...this.state.images];
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            const file = files[i];
            reader.onloadend = () => {
                const newImage = {
                    image_smallurl: reader.result,
                    image_largeurl: reader.result
                };
                tempImageUploaded.push(newImage);
                this.setState(prevState => ({
                    previews: [file, ...prevState.previews]
                }));
            };
            reader.readAsDataURL(file);
        }
        this.setState({
            images: tempImageUploaded
        });
    }

    toggleModal = () => {
        this.setState({ pictureModal: !this.state.pictureModal });
    }

    changeImage = () => {
        if (Config.isMobileApp) {
            this.toggleModal();
        } else if (!this.props.credentials.token) {
            ApiError('You need to log in or sign up.');
        } else {
            const selectfile = document.getElementById('file');
            selectfile.click();
        }
    }

    phoneCamera = () => {
        camera(this.phoneSuccess);
    }

    phoneUpload = () => {
        cameraMultiRoll(this.phoneSuccess);
    }

    phoneSuccess = file => {
        const newimage = {
            image_smallurl: file,
            image_largeurl: file
        };
        this.setState(prevState => ({ images: [newimage, ...prevState.images] }));
        cameraBlob(file, this.preview);
    }

    preview = imgBlob => {
        this.setState(prevState => ({ previews: [imgBlob, ...prevState.previews] }));
    }

    submitHandler = () => {
        if (!this.props.credentials.token) {
            ApiError('You need to log in or sign up.');
        }
        if (this.state.comment === '' && this.props.credentials.token) {
            ApiError('Please write something before submitting');
            return;
        }
        if (this.state.submitting === false) {
            this.setState({ submitting: true });
            const data = {
                post_body: this.state.comment,
                post_visibility: 'public',
                entity_id: this.props.entityId
            };
            this.props.dispatch(addPost(this.props.credentials.token, data))
                .then(res => {
                    this.setState({
                        comment: '',
                        submitting: false
                    });
                    if (this.state.previews.length > 0 && this.props.credentials.token) {
                        toast.info('Uploading Images');
                        for (let i = 0; i < this.state.previews.length; i++) {
                            this.props.dispatch(uploadPicture(this.props.credentials.token, res.value.data.post_id, this.state.previews[i]));
                        }
                    }
                    toast.success('Posted Successfully');
                    if (this.props.callback) {
                        this.props.callback(res.value.data);
                    }
                    // we return the state of the new post with images, this wat the activity list item will have the necessary information to properly display the post with images
                    if (this.props.stateSetter) {
                        this.props.stateSetter(prevState => ({
                            comments: [{ ...res.value.data, images: this.state.images }, ...prevState.comments],
                            commentCounter: prevState.commentCounter + 1
                        }));
                    }
                    this.setState({ images: [], previews: [] });
                    this.props.dispatch(saveActivityScreenDetails({}));
                })
                .catch(err => {
                    this.setState({ submitting: false });
                    ApiError((err?.response?.data?.[0]?.message || err.response.data.message) || 'There was an error');
                });
        }
    }

    commentHandler = e => {
        this.setState({ comment: e.target.value });
    }

    clearImages = () => {
        this.setState({ images: [], previews: [] });
    }

    render() {
        let button = (<div className="spacerSmall" />);

        const charCount = this.state.comment.length;
        const maxChar = 80;
        const size = maxChar / charCount;
        let fontSize = size.toFixed(1) * 10;
        if (fontSize > 14) {
            fontSize = 14;
        } else if (fontSize < 10) {
            fontSize = 10;
        }

        if (this.props.actionButtons) {
            if (fontSize > 20) {
                fontSize = 20;
            } else if (fontSize < 10) {
                fontSize = 10;
            }
            button = (
                <React.Fragment>
                    <input
                        className="hidden"
                        id="file"
                        type="file"
                        onChange={e => this.filesChanged(e.target.files)}
                        accept="image/*"
                        required
                        multiple
                    />
                    <div className="row alignItems actionButtons fontRed">
                        <div className="flexButtons flexStart" onClick={() => this.changeImage()}>
                            <i className="icn icon-gallery " /> Add Pictures
                        </div>
                        <div onClick={this.submitHandler} className="flexButtons flexEnd">
                            <i className="icn icon-no-messages" /> Post
                        </div>
                    </div>
                </React.Fragment>
            );
        } else if (this.state.comment !== '') {
            button = (
                <React.Fragment>
                    <div onClick={this.submitHandler} className="inlineButtons greenBorder backgroundGreen fontWhite">
                        Submit
                    </div>
                    <div className="spacerSmall" />
                </React.Fragment>
            );
        }

        return (
            <div className="wrapper leave-comment">
                {this.state.pictureModal && (
                    <CameraModal
                        toggleModal={this.toggleModal}
                        callbackCamera={this.phoneCamera}
                        callbackUpload={this.phoneUpload}
                    />
                )}
                <div className="row alignItemsTop">
                    <div className="avatar round-avatar small user colored-icon">
                        <Avatar
                            src={this.props.commentAs?.images?.[0] && this.props.commentAs.images[0].image_smallurl}
                            alt={this.props.commentAs?.user_firstname}
                            type="user"
                        />
                    </div>
                    <div className="text-area-comment-holder">
                        {!!this.state.images && this.state.images.length > 0 && (
                            <div onClick={this.clearImages} className="right fontRed">
                                Clear Pictures <i className="icn icon-sign-out" />
                            </div>
                        )}
                        {!!this.state.images && this.state.images.length > 0 && (
                            <div className="gallery whiteOpaque">
                                <GalleryLightbox
                                    images={this.state.images}
                                    count={this.state.images.length}
                                    size={this.state.images.length < 3 ? 'normal' : 'small'}
                                    fillers={false}
                                />
                            </div>
                        )}
                        <Textarea
                            onChange={this.commentHandler}
                            className={'niceTextarea smallmargin x' + fontSize}
                            placeholder={this.props.placeholder || 'Write a comment...'}
                            value={this.state.comment || ''}
                        />
                        {button}
                    </div>
                </div>
            </div>
        );
    }
}

LeaveComment.propTypes = {
    dispatch: PropTypes.func.isRequired,
    stateSetter: PropTypes.func,
    callback: PropTypes.func,
    entityId: PropTypes.number.isRequired,
    commentAs: PropTypes.instanceOf(Object).isRequired
};

export default LeaveComment;
