import React from 'react';
import { Link } from 'react-router-dom';

export const MainSearchNavigation = ({ onChange, searchValue, onClear }) => (
    <div className="mainSearchNavigation row">
        <Link to="/searchlist"><i className="icn icon-list-search" /></Link>
        <div className="searchMap">
            <input
                onChange={e => onChange(e)}
                value={searchValue}
                name="searchLocation"
                type="text"
                placeholder="Search..."
            />
            {!!searchValue && (
                <div className="deleteButton" onClick={onClear}>
                    <i className="icn icon-sign-out" />
                </div>
            )}
        </div>
        <Link to="/categories"><i className="icn icon-filters" /></Link>
    </div>
);
