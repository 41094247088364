import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

import { getUserProfile } from '../../redux/actions/users';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import Spinner from '../../layout/UI/Spinner';
import NotFound from '../../layout/NotFound';
import { Pull2Refresh } from '../../helpers/Pull2Refresh';
import ApiError from '../../helpers/ApiError';
import { OwnProfile } from './OwnProfile';

class OwnUserProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loading: true };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.start();
        Pull2Refresh(this.start);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    start = () => {
        this.props.dispatch(getUserProfile(this.props.credentials.token, this.props.match.params.id))
            .then(() => this.setState({ loading: false }))
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loading: false });
            });
    }

    render() {
        return (
            <div className="mainAppContainer">
                {String(this.props.credentials.user_id) !== String(this.props.match.params.id) && !this.state.loading && (
                    <MainNavigation {...this.props} title={this.props.userProfile.user_id ? this.props.userProfile.user_firstname + ' ' + this.props.userProfile.user_lastname : 'Not Found'} leftButton="back" />
                )}
                {String(this.props.credentials.user_id) === String(this.props.match.params.id) && !this.state.loading && (
                    <MainNavigation {...this.props} title={this.props.userData.user_firstname + ' ' + this.props.userData.user_lastname} leftButton="back" />
                )}
                <div className="bodyContent pull2refresh">
                    {!!this.state.loading && (<Spinner />)}
                    {!!this.props.userProfile && !this.state.loading && !this.props.userProfile.user_id && (
                        <NotFound type="user" className="backgroundRed" />
                    )}
                    {this.props.userProfile && !this.state.loading && !!this.props.userProfile.user_id && (
                        <React.Fragment>
                            {/* THIS SECTION IS FOR PEOPLE WHO ARE LOOKING AT OTHER PEOPLES PROFILES */}
                            {String(this.props.credentials.user_id) !== String(this.props.match.params.id) && (
                                <Redirect to={'/user/profile/' + this.props.match.params.id} />
                            )}
                            {/* THIS SECTION IS FOR PEOPLE WHO ARE LOOKING AT THEIR OWN PROFILES */}
                            {String(this.props.credentials.user_id) === String(this.props.match.params.id) && (
                                <OwnProfile
                                    dispatch={this.props.dispatch}
                                    credentials={this.props.credentials}
                                    images={this.props.userProfile.images.image_id}
                                    user={this.props.userProfile}
                                    dogs={this.props.userProfile.dogs}
                                    userData={this.props.userData}
                                    history={this.props.history}
                                />
                            )}
                        </React.Fragment>
                    )}
                </div>
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        userProfile: state.users.userProfile
    };
}

export default connect(mapStateToProps)(OwnUserProfile);
