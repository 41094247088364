import { toast } from 'react-toastify';

import ApiError from '../../helpers/ApiError';

export default function reducer(
    state = {
        searchLostDogs: '',
        breeds: [],
        dogCreate: {
            name: '',
            breeds: [],
            error: false,
            success: false
        },
        reportMissing: { dogs: [] },
        dogProfile: {}
    },
    action
) {
    switch (action.type) {
        case 'persist/REHYDRATE': {
            if (action.payload.dogs) {
                return {
                    ...state,
                    searchLostDogs: action.payload.dogs.searchLostDogs || '',
                    breeds: action.payload.dogs.breeds || [],
                    dogCreate: action.payload.dogs.dogCreate || { name: '', breeds: [], error: false, success: false },
                    reportMissing: action.payload.dogs.reportMissing || { dogs: [] },
                    dogProfile: action.payload.dogs.dogProfile || {}
                };
            }
            return { ...state };
        }

        case 'SET_CREATE_DOG_NAME':
            return { ...state, dogCreate: { ...state.dogCreate, name: action.payload } };

        case 'SET_CREATE_BREEDS_SELECTED': {
            let newBreeds;

            if (!!state.dogCreate.breeds && state.dogCreate.breeds.indexOf(action.payload) !== -1) {
                newBreeds = state.dogCreate.breeds.filter(item => item !== action.payload);
            } else if (!!state.dogCreate && !!state.dogCreate.breeds) {
                newBreeds = [...state.dogCreate.breeds, action.payload];
            } else {
                newBreeds = [action.payload];
            }
            return { ...state, dogCreate: { ...state.dogCreate, breeds: newBreeds } };
        }
        case 'SET_SEARCH_LOST_DOGS': {
            return { ...state, searchLostDogs: action.payload };
        }

        case 'SET_CREATE_DOG_PICTURE_FILE':
            return { ...state, dogCreate: { ...state.dogCreate, pictureFile: action.payload } };

        case 'CLEAR_DOG_PICTURE_FILE':
            return { ...state, dogCreate: { ...state.dogCreate, picture: '', pictureFile: '' } };

        case 'SET_CREATE_DOG_PICTURE':
            return { ...state, dogCreate: { ...state.dogCreate, picture: action.payload } };

        case 'SET_CREATE_DOG_SEX':
            return { ...state, dogCreate: { ...state.dogCreate, sex: action.payload } };

        case 'SET_CREATE_DOG_FIXED':
            return { ...state, dogCreate: { ...state.dogCreate, fixed: action.payload } };

        case 'SET_CREATE_DOG_DOB':
            return { ...state, dogCreate: { ...state.dogCreate, dob: action.payload } };

        case 'SET_CREATE_DOG_PLAYDATES':
            return { ...state, dogCreate: { ...state.dogCreate, playdates: action.payload } };

        case 'SET_CREATE_DOG_DESCRIPTION':
            return { ...state, dogCreate: { ...state.dogCreate, description: action.payload } };

        case 'SET_CREATE_DOG_WEIGHT':
            return { ...state, dogCreate: { ...state.dogCreate, weight: action.payload } };

        case 'SET_CREATE_DOG_SOCIAL_MEDIA':
            return { ...state, dogCreate: { ...state.dogCreate, socialmedia: action.payload } };

        case 'GET_DOG_PROFILE_PENDING':
            return { ...state, dogProfile: { ...state.dogProfile, loading: true } };

        case 'GET_DOG_PROFILE_FULFILLED':
            return { ...state, dogProfile: { ...action.payload.data } };

        case 'SET_DOG_PROFILE':
            return { ...state, dogProfile: { ...action.payload } };

        case 'SET_MISSING_DOG_SELECTED': {
            let selectedDogs;
            if (!state.reportMissing || !state.reportMissing.selectedDogs || !state.reportMissing.selectedDogs.length) {
                selectedDogs = [];
                selectedDogs.push(action.payload);
            } else if (!!state.reportMissing && !!state.reportMissing.selectedDogs && !!state.reportMissing.selectedDogs.length && state.reportMissing.selectedDogs.length > 0 && state.reportMissing.selectedDogs.indexOf(action.payload) !== -1) {
                selectedDogs = state.reportMissing.selectedDogs.filter(item => item !== action.payload);
            } else {
                selectedDogs = [...state.reportMissing.selectedDogs];
                selectedDogs.push(action.payload);
            }
            return { ...state, reportMissing: { ...state.reportMissing, selectedDogs } };
        }

        case 'SET_MISSING_DOG_REWARD':
            return { ...state, reportMissing: { ...state.reportMissing, reward: action.payload } };

        case 'SET_MISSING_DOG_CITY':
            return { ...state, reportMissing: { ...state.reportMissing, city: action.payload } };

        case 'SET_MISSING_DOG_STATE':
            return { ...state, reportMissing: { ...state.reportMissing, state: action.payload } };

        case 'SET_MISSING_DOG_COUNTRY':
            return { ...state, reportMissing: { ...state.reportMissing, country: action.payload } };

        case 'SET_MISSING_DOG_POSTAL':
            return { ...state, reportMissing: { ...state.reportMissing, postal: action.payload } };

        case 'SET_MISSING_DOG_STREET1':
            return { ...state, reportMissing: { ...state.reportMissing, street1: action.payload } };

        case 'SET_MISSING_DOG_STREET2':
            return { ...state, reportMissing: { ...state.reportMissing, street2: action.payload } };

        case 'SET_MISSING_DOG_GEOPOINT':
            return { ...state, reportMissing: { ...state.reportMissing, geopoint: action.payload } };

        case 'SET_MISSING_DOG_ADDITIONAL':
            return { ...state, reportMissing: { ...state.reportMissing, additional: action.payload } };

        case 'SET_MISSING_DOG_DATE':
            return { ...state, reportMissing: { ...state.reportMissing, date: action.payload } };

        case 'SET_MISSING_DOG_TIME':
            return { ...state, reportMissing: { ...state.reportMissing, time: action.payload } };

        case 'SET_MISSING_DOG_CONTACT_NAME':
            return { ...state, reportMissing: { ...state.reportMissing, contactName: action.payload } };

        case 'SET_MISSING_DOG_CONTACT_PHONE':
            return { ...state, reportMissing: { ...state.reportMissing, contactPhone: action.payload } };

        case 'SET_MISSING_DOG_CONTACT_EMAIL':
            return { ...state, reportMissing: { ...state.reportMissing, contactEmail: action.payload } };

        case 'SET_MISSING_DOG_CONTACT_BY_PHONE':
            return { ...state, reportMissing: { ...state.reportMissing, contactByPhone: (!state.reportMissing.contactByPhone) } };

        case 'SET_MISSING_DOG_CONTACT_BY_EMAIL':
            return { ...state, reportMissing: { ...state.reportMissing, contactByEmail: (!state.reportMissing.contactByEmail) } };

        case 'SET_MISSING_DOG_CONTACT_BY_TEXT':
            return { ...state, reportMissing: { ...state.reportMissing, contactByText: (!state.reportMissing.contactByText) } };

        case 'GET_DOG_BREEDS_FULFILLED':
            return { ...state, breeds: action.payload.data };

        case 'CREATE_DOG_FULFILLED':
            return { ...state, dogCreate: { ...state.dogCreate, success: true, error: false, new_dog_id: action.payload.data.dog_id } };

        case 'CREATE_DOG_REJECTED':
            return { ...state, dogCreate: { ...state.dogCreate, success: false, error: true } };

        case 'DELETE_DOG_FULFILLED': {
            toast.success('Dog has beed deleted');
            return { ...state };
        }

        case 'DELETE_DOG_REJECTED': {
            ApiError('Dog could not be deleted');
            return { ...state };
        }

        case 'UPDATE_DOG_FULFILLED': {
            return { ...state };
        }

        case 'UPDATE_MISSING_DOG_FULFILLED': {
            return { ...state, dogProfile: { ...state.dogProfile, dogaddress: action.payload.data } };
        }

        case 'CLEAR_DOG_CREATE':
            return { ...state, dogCreate: { error: false, success: false }, reportMissing: {}, breeds: [] };

        case 'CLEAR_DOG_MISSING':
            return { ...state, reportMissing: { dogs: [] } };

        case 'CLEAR_DOG_PROFILE':
            return { ...state, dogProfile: {} };

        case 'USER_LOGOUT':
            return { ...state, dogCreate: { error: false, success: false }, reportMissing: {}, breeds: [], dogProfile: {} };

        default:
            return { ...state };
    }
}
