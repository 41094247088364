import React from 'react';

import Config from '../../helpers/Config';

export class AdBanner extends React.Component {
    render() {
        let adLink = null;
        if (Config.isMobileApp === false) {
            if (Config.DeviceType === 'Android') {
                adLink = 'https://play.google.com/store/apps/details?id=com.hottomali.OffLeashZone&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';
            }
            if (Config.DeviceType === 'Ios') {
                adLink = 'https://itunes.apple.com/app/id1049134590';
            }
        }

        return (
            <li className="adBanner row" onClick={() => window.open(adLink, '_blank')}>
                <img alt="Pooch Logo" src={Config.fileDirectory + 'assets/images/' + Config.logos.twoLines + '@2x.png'} srcSet={Config.fileDirectory + 'assets/images/' + Config.logos.twoLines + '@1x.png 1x,' + Config.fileDirectory + 'assets/images/' + Config.logos.twoLines + '@2x.png 2x'} />
                <div className="adBannerCaption">Download the<br />Pooch App</div>
            </li>
        );
    }
}

export default AdBanner;
