import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';

import { chat_clearUser } from '../../redux/actions/users';
import Columns from '../../hoc/Columns';
import WhiteTitle from '../../hoc/WhiteTitle';
import UserList from '../../layout/UserList';
import { InviteFriends } from '../../layout/InviteFriends';
import FriendRequestsComponent from '../Friends/FriendRequestsComponent';
import FindFriendsComponent from '../Friends/FindFriendsComponent';
import sb from '../../helpers/SendBird';

export class ChatConfirm extends React.Component {
    componentDidMount() {
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    rightButtonOnClick = e => {
        e.preventDefault();
        const userIds = [];
        for (const i in this.props.selectedUsers) {
            userIds.push(this.props.selectedUsers[i].id);
        }
        const roomName = this.userList();
        const that = this;
        sb.connect(
            this.props.credentials.user_id,
            this.props.credentials.token,
            () => {
                sb.GroupChannel.createChannelWithUserIds(userIds, true, roomName, null, null, (createdChannel, error) => {
                    if (error) {
                        toast.error('Sorry, there was an issue creating this chat, please try again.');
                        return;
                    }
                    that.props.dispatch(chat_clearUser());
                    that.props.history.push('/chat/channel/' + createdChannel.url);
                });
            }
        );
    }

    onClick = e => {
        e.preventDefault();
    }

    userList() {
        const usersListNames = this.props.selectedUsers.map(user => user.name);
        return usersListNames.join(', ');
    }

    render() {
        const c1 = (<React.Fragment />);

        const c2 = (
            <React.Fragment>
                <div className="mainAppContainer locationListSearch">
                    <div className="bodyContent wrapper noPaddingTop">
                        <div className="maxWidth">
                            <WhiteTitle>Selected Participants</WhiteTitle>
                            <UserList users={this.props.selectedUsers} markSelected={true} onClick={this.onClick} />
                            <div className="spacerSmall" />
                            <div className="wideButtons greenBorder backgroundGreen fontWhite" onClick={this.rightButtonOnClick}>
                                {'Confirm >'}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );

        const c3 = (
            <React.Fragment>
                <InviteFriends wrapperClass="noWrapper" />
                <FriendRequestsComponent limit={3} infiniteScroll={false} viewMoreButton={true} />
                <WhiteTitle>Suggested Friends</WhiteTitle>
                <FindFriendsComponent perPage={3} infiniteScroll={false} />
                <Link to="/findfriends" className="square-button greenBG fontWhite h6">View all</Link>
            </React.Fragment>
        );

        return (
            <div className="mainAppContainer confirm-chat">
                <div className="bodyContent">
                    <div className="three-column-layout two-column-list">
                        <Columns
                            c1={c1}
                            c2={c2}
                            c3={c3}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        selectedUsers: state.users.chat.selectedUsers
    };
}

export default connect(mapStateToProps)(ChatConfirm);
