import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ClickOutside from 'react-click-outside';
import ReactGA from 'react-ga';

import { setEmail, login, fblogin } from '../../redux/actions/users';
import { getActivityCounter } from '../../redux/actions/activities';
import sb from '../../helpers/SendBird';
import { guard } from '../../helpers/Functions';
import { getDeviceToken } from '../../helpers/PushNotifications';
import Config from '../../helpers/Config';
import ApiError from '../../helpers/ApiError';
import BasicAlert from '../../layout/UI/BasicAlert';
import FacebookLoginButton from '../../FacebookLoginButton';

class ModalMobileLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPasswordVisible: false,
            userPassword: '',
            loggingIn: false
        };
    }

    fbLogin = e => {
        e.preventDefault();
        this.setState({ loggingIn: true });
        const that = this;
        const fbLoginSuccess = () => {
            if (window.facebookConnectPlugin) {
                window.facebookConnectPlugin.getAccessToken(token => {
                    that.responseFacebook({ accessToken: token });
                });
            }
        };
        const loginPlugin = () => {
            if (window.facebookConnectPlugin) {
                window.facebookConnectPlugin.login(['public_profile', 'email'], fbLoginSuccess,
                    error => {
                        console.log(error);
                        this.setState({ loggingIn: false });
                        BasicAlert('Sorry there is an issue with the credentials provided');
                    }, { auth_type: 'reauthenticate' });
            }
        };
        if (window.facebookConnectPlugin) {
            window.facebookConnectPlugin.getLoginStatus(response => {
                if (!!response && !!response.authResponse && !!response.authResponse.userID) {
                    window.facebookConnectPlugin.api(
                        '/' + response.authResponse.userID + '/permissions?method=delete',
                        ['public_profile', 'email'],
                        () => {
                            loginPlugin();
                        }, () => {
                            loginPlugin();
                        }
                    );
                } else {
                    loginPlugin();
                }
            }, () => {
                loginPlugin();
            });
        } else {
            loginPlugin();
        }
    }

    responseFacebook = response => {
        this.setState({ loggingIn: true });
        if ((response && response.email && response.id) || (response.accessToken)) {
            const that = this;
            this.props.dispatch(fblogin(response.accessToken))
                .then(response => {
                    console.log(response);
                    toast.success('Welcome Back!');
                    sb.connect(
                        that.props.credentials.user_id,
                        that.props.credentials.token,
                        () => {
                            sb.updateCurrentUserInfo(
                                that.props.userData.user_firstname + ' ' + that.props.userData.user_lastname,
                                guard(that.props.userData, '.images.0.image_smallurl') || '',
                                () => { }
                            );
                        }
                    );
                    getDeviceToken(that.props.dispatch, that.props.credentials);
                    that.closeModal();
                    that.props.dispatch(getActivityCounter(that.props.credentials.token));
                    ReactGA.event({
                        category: 'UserRegistration',
                        action: 'FB Login Success'
                    });
                })
                .catch(err => {
                    this.setState({ loggingIn: false });
                    ApiError(err);
                });
        } else {
            this.setState({ loggingIn: false });
        }
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    verifyData = e => {
        e.preventDefault();
        if (!this.props.userData.user_email) {
            toast('Please write your Email!');
        } else if (!/\S+@\S+\.\S+/.test(this.props.userData.user_email)) {
            toast('Please verify your Email!');
        } else if (!this.state.userPassword) {
            toast('Please enter your password!');
        } else {
            // const that = this;
            this.props.dispatch(login(this.props.userData.user_email, this.state.userPassword))
                .then(() => {
                    toast.success('Welcome Back!');
                    /* sb.connect(
                        that.props.credentials.user_id,
                        that.props.credentials.token,
                        () => {
                            sb.updateCurrentUserInfo(
                                that.props.userData.user_firstname + ' ' + that.props.userData.user_lastname,
                                guard(that.props.userData, '.images.0.image_smallurl') || '',
                                () => { }
                            );
                        }
                    );
                    // that.closeModal();
                    ReactGA.event({
                        category: 'UserRegistration',
                        action: 'Email Login Success'
                    }); */
                })
                .catch(err => {
                    ApiError(err?.response?.data?.[0]?.message || err.message);
                    this.setState({ userPassword: '' });
                });
        }
    }

    closeModal = () => {
        const modal = document.getElementById('CustomModalMobileLogin');
        if (modal) modal.classList.add('hidden');
    }

    render() {
        return (
            <div id="CustomModalMobileLogin" className="hidden">
                <div className="ModalCustomOverlay">
                    <ClickOutside onClickOutside={this.closeModal}>
                        <div className="ModalMobileLogin">
                            <div className="container">
                                <i className="icn icon-cross fontWhite" onClick={this.closeModal} />
                                <section className="modalTopSection">
                                    <h2>Log In.</h2>
                                    <Link className="modalRegister fontWhite" to="/register">
                                        Not a Member? Sign up
                                    </Link>
                                </section>
                                <form onSubmit={this.verifyData}>
                                    <div className="innerLabelInput">
                                        <span className="blocking-span">
                                            <input
                                                className="inputText"
                                                id="userEmail"
                                                name="userEmail"
                                                type="email"
                                                value={this.props.userData?.user_email || ''}
                                                onChange={e => this.props.dispatch(setEmail(e.target.value))}
                                                required
                                            />
                                            <span className="floating-label">Email address</span>
                                        </span>
                                    </div>
                                    <div className="innerLabelInput">
                                        <span className="blocking-span">
                                            <input
                                                className="inputText"
                                                id="userPassword"
                                                name="userPassword"
                                                type={this.state.isPasswordVisible ? 'text' : 'password'}
                                                value={this.state.userPassword || ''}
                                                onChange={this.handleChange}
                                                required
                                            />
                                            <span className="floating-label">Password</span>
                                            <div
                                                className="showPassword"
                                                onClick={() => this.setState({ isPasswordVisible: !this.state.isPasswordVisible })}
                                            >
                                                {this.state.isPasswordVisible ? 'Hide' : 'Show'}
                                            </div>
                                        </span>
                                    </div>
                                    <Link className="modalForgotPassword fontWhite" to="/forgot">
                                        Forgot Password?
                                    </Link>
                                    <input type="submit" value="Login &gt;" className="wideButtons greenBG fontWhite" />
                                </form>
                                <div className="separator"> OR </div>
                                <section className="modalBottomSection">
                                    {this.state.loggingIn && (
                                        <div className="wideButtons fb-button">
                                            <i className="icn icon-facebook1" /> Logging In...
                                        </div>
                                    )}
                                    {!this.state.loggingIn && Config.isMobileApp && (
                                        <Link
                                            to="/loginemail"
                                            onClick={e => this.fbLogin(e)}
                                            className="wideButtons fb-button"
                                        >
                                            <i className="icn icon-facebook1" /> Login with Facebook
                                        </Link>
                                    )}
                                    {!this.state.loggingIn && !Config.isMobileApp && (
                                        <FacebookLoginButton
                                            callback={this.responseFacebook}
                                        />
                                    )}
                                </section>
                            </div>
                        </div>
                    </ClickOutside>
                </div>
            </div>
        );
    }
}

export default ModalMobileLogin;
