import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';

import { getLocation, checkFavorite, removeFavorite } from '../../redux/actions/locations';
import { deletePicture, uploadPicture, setPicture, getReviews } from '../../redux/actions/all';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import UserList from '../../layout/UserList';
import LocationMap from '../../layout/locations/LocationMap';
import { Stars } from '../../layout/UI/Stars';
import DynamicInputs from '../../layout/UI/DynamicInputs';
import GalleryLightbox from '../../layout/gallery/GalleryLightbox';
import Spinner from '../../layout/UI/Spinner';
import CameraModal from '../../layout/CameraModal';
import NotFound from '../../layout/NotFound';
import ApiError from '../../helpers/ApiError';
import { timetable, urlFriendly } from '../../helpers/Functions';
import { Pull2Refresh } from '../../helpers/Pull2Refresh';
import Config from '../../helpers/Config';
import { camera, cameraMultiRoll, cameraBlob } from '../../helpers/Camera';
import { UserReview } from './UserReview';

class LocationProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            location: (this.props.locationData && this.props.locationData.location) || null,
            gallery: (this.props.locationData && this.props.locationData.location && this.props.locationData.location.images) || [],
            loading: this.props.loading !== false,
            reviews: null,
            pictureModal: false,
            myLocationsToAdmin: [],
            currentId: (this.props.currentId) || false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        Pull2Refresh(this.start);
        if (!this.state.currentId) {
            this.start();
        } else {
            this.getReview(this.props.credentials.token, this.props.currentId);
        }
    }

    start = () => {
        if (this.props.userData && this.props.userData.adminlocations) {
            const adminLocations = this.props.userData.adminlocations.map(location => String(location.location_id));
            this.setState({ myLocationsToAdmin: adminLocations });
        }
        this.props.dispatch(getLocation(this.props.credentials.token, this.props.match.params.id))
            .then(response => {
                this.setState({ location: response.value.data, loading: false, gallery: response.value.data.images });
                this.getReview(this.props.credentials.token, this.props.match.params.id);
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loading: false });
            });
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    getReview = (token, id) => {
        this.props.dispatch(getReviews(token, id))
            .then(res => {
                this.setState({
                    reviews: res.value.data
                });
            });
    }

    mapReviews = reviews => reviews.map((review, index) => (<UserReview key={index} user={review} />))

    checkFavorite = e => {
        e.preventDefault();
        const favorite = document.getElementsByClassName('checkfavorite');
        favorite[0].classList.toggle('hidden');
        const remove = document.getElementsByClassName('removefavorite');
        remove[0].classList.toggle('hidden');
        this.props.dispatch(checkFavorite(this.props.credentials.token, this.state.location.location_id));
    }

    removeFavorite = e => {
        e.preventDefault();
        const favorite = document.getElementsByClassName('checkfavorite');
        favorite[0].classList.toggle('hidden');
        const remove = document.getElementsByClassName('removefavorite');
        remove[0].classList.toggle('hidden');
        this.props.dispatch(removeFavorite(this.props.credentials.token, this.state.location.location_id));
    }

    filesChanged = files => {
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            const file = files[i];

            reader.onloadend = () => {
                this.props.dispatch(uploadPicture(this.props.credentials.token, this.state.location.location_id, file, false))
                    .then(response => this.updateThumbs(response));
            };
            reader.readAsDataURL(file);
        }
    }

    toggleModal = () => {
        this.setState({ pictureModal: !this.state.pictureModal });
    }

    changeImage = () => {
        if (Config.isMobileApp) {
            this.toggleModal();
        } else {
            const selectfile = document.getElementById('file');
            selectfile.click();
        }
    }

    phoneCamera = () => {
        camera(this.phoneSuccess);
    }

    phoneUpload = () => {
        cameraMultiRoll(this.phoneSuccess);
    }

    phoneSuccess = file => {
        cameraBlob(file, this.uploadToProfile);
    }

    uploadToProfile = imgBlob => {
        this.props.dispatch(uploadPicture(this.props.credentials.token, this.state.location.location_id, imgBlob, false))
            .then(response => this.updateThumbs(response));
    }

    updateThumbs = response => {
        this.setState(prevState => ({
            pictureModal: false,
            gallery: [response.value.data, ...prevState.gallery]
        }));
        if (!!navigator && !!navigator.camera && !!navigator.camera.cleanup) {
            navigator.camera.cleanup();
        }
    }

    handleClickRemoveImage = id => {
        this.props.dispatch(deletePicture(this.props.credentials.token, id))
            .then(res => {
                console.log(res);
                this.setState((prevState, props) => {
                    console.log(props);
                    const newGallery = prevState.gallery.filter(image => image.image_id !== id);
                    return {
                        gallery: newGallery
                    };
                });
                toast.success('Deleted Image Successfully');
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    handleClickSetImage = id => {
        this.props.dispatch(setPicture(this.props.credentials.token, id))
            .then(() => {
                // this.setState({ redirect: true });
                toast.success('Updated Profile Successfully');
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    render() {
        const contactinfo = [];

        if (!!this.state.location && !!this.state.location.location_contactinfo) {
            const contactInfo = this.state.location.location_contactinfo;
            Object.keys(contactInfo).forEach(key => {
                const contactName = key.charAt(0).toUpperCase() + key.slice(1);
                const contactValue = contactInfo[key];
                if (contactName === 'Phone' && !!contactValue) {
                    contactinfo.push(
                        <div key={contactName}>
                            Telephone<br /><a className="fontGreen" href={'tel:' + contactValue}>{contactValue}</a>
                        </div>
                    );
                } else if (contactName === 'Email' && !!contactValue) {
                    contactinfo.push(
                        <div key={contactName}>
                            Email<br /><a className="fontGreen" href={'mailto:' + contactValue}>{contactValue}</a>
                        </div>
                    );
                } else if (contactName === 'TollFree' && !!contactValue) {
                    contactinfo.push(
                        <div key={contactName}>
                            Toll-free<br /><a className="fontGreen" href={'tel:' + contactValue}>{contactValue}</a>
                        </div>
                    );
                } else if (!!contactName && !!contactValue) {
                    contactinfo.push(
                        <div key={contactName}>
                            {contactName}<br /><div className="fontGreen">{contactValue}</div>
                        </div>
                    );
                }
            });
        }

        const socialmedia = [];

        if (!!this.state.location && !!this.state.location.location_socialmedia) {
            const media = this.state.location.location_socialmedia;
            Object.keys(media).forEach(key => {
                const socialName = key.charAt(0).toUpperCase() + key.slice(1);
                const socialURL = media[key];
                // the only way to show facebook link will be if facebook has a correct link
                if (socialName === 'Facebook' && (socialURL.indexOf('http') !== -1)) {
                    socialmedia.push(
                        <div key={socialName}>
                            {socialName}<br /><a className="fontGreen" href={socialURL} target="_blank" rel="noopener noreferrer">{socialURL}</a>
                        </div>
                    );
                } else if (socialName === 'Instagram' && (socialURL.indexOf('http') !== -1)) {
                    // Instagram can have 2 way of showing: if it has all the url or if it has the username
                    socialmedia.push(
                        <div key={socialName}>
                            {socialName}<br /><a className="fontGreen" href={socialURL} target="_blank" rel="noopener noreferrer">{socialURL}</a>
                        </div>
                    );
                } else if (socialName === 'Instagram') {
                    socialmedia.push(
                        <div key={socialName}>
                            {socialName}<br /><a className="fontGreen" href={'https://instagram.com/' + socialURL} target="_blank" rel="noopener noreferrer">{socialURL}</a>
                        </div>
                    );
                } else if (socialName === 'Twitter' && (socialURL.indexOf('http') !== -1)) {
                    socialmedia.push(
                        <div key={socialName}>
                            {socialName}<br /><a className="fontGreen" href={socialURL} target="_blank" rel="noopener noreferrer">{socialURL}</a>
                        </div>
                    );
                } else if (socialName === 'Twitter') {
                    socialmedia.push(
                        <div key={socialName}>
                            {socialName}<br /><a className="fontGreen" href={'https://twitter.com/' + socialURL} target="_blank" rel="noopener noreferrer">{socialURL}</a>
                        </div>
                    );
                } else if (socialURL.indexOf('http') !== -1) {
                    socialmedia.push(
                        <div key={socialName}>
                            {socialName}<br /><a className="fontGreen" href={socialURL} target="_blank" rel="noopener noreferrer">{socialURL}</a>
                        </div>
                    );
                }
            });
        }

        const categories = [];

        if (this.state.location && this.state.location.locationtypes) {
            categories.push(<div key="categories">Categories</div>);
            this.state.location.locationtypes.map(loc => categories.push(<div key={loc.locationtype_name} className="fontGreen">{loc.locationtype_name}</div>));
        }

        /*
        we want to show all the information available for this particular location
        however we only want to show the information available and permited for each input

        for example, the category "hotels" has specific inputs
        if this location is exclusively "hotels" then it will only show "hotels" information
        in other words, if this "hotel" has more information irrelevant to "hotels" then it will exclude this information
        */
        const additionalInfo = [];
        if (!!this.state.location && this.state.location.location_additionalinfo) {
            const preselectedCategories = this.state.location.locationtypes.map(loc => loc.locationtype_name);

            additionalInfo.push(
                <div key="additional">
                    <div>Additional Info</div>
                </div>
            );

            for (const key in DynamicInputs) {
                let showInput = false;
                for (const cat in preselectedCategories) {
                    if (DynamicInputs[key].categories.includes(preselectedCategories[cat])) {
                        showInput = true;
                        break;
                    }
                }

                if (showInput && !!this.state.location && !!this.state.location.location_additionalinfo && !!this.state.location.location_additionalinfo[key]) {
                    let label = '';
                    if (DynamicInputs[key].type === 'input') {
                        label = ': ' + this.state.location.location_additionalinfo[key];
                    }
                    additionalInfo.push(
                        <div key={key}>
                            <div className="fontGreen">{DynamicInputs[key].label}{label}</div>
                        </div>
                    );
                }
            }
        }

        let adminButton = null;
        if ((this.state.location && this.state.location.location_name)
            && this.props.userData && this.props.userData.user_role
            && (this.props.userData.user_role === 'superadmin'
                || (this.props.userData.user_role === 'admin' && this.state.myLocationsToAdmin && this.state.myLocationsToAdmin.includes(String(this.props.match.params.id))))) {
            adminButton = (
                <Link to={'/managelocation/' + this.state.location.location_id + '/' + urlFriendly(this.state.location.location_name)} className="wideButtons redBorder redBG fontWhite">
                    Edit
                </Link>
            );
        }

        let claimBusinessButton = null;
        if (this.state.location && this.state.location.adminlocations && this.state.location.adminlocations.length === 0) {
            claimBusinessButton = (
                <Link to={'/claimlocation/' + this.state.location.location_id + '/' + urlFriendly(this.state.location.location_name)} className="wideButtons blueBorder blueBG fontWhite">
                    Claim this location
                </Link>
            );
        }

        let leader;
        if (this.state.location && this.state.location.topusers && this.state.location.topusers.length > 0) {
            leader = (
                <div>
                    <h4 className="fontGreen">Pack Leader</h4>
                    <UserList
                        users={[this.state.location.topusers[0]]}
                        dispatch={this.props.dispatch}
                        credentials={this.props.credentials}
                        addFriend
                    />
                </div>
            );
        } else if (this.state.location && this.state.location.topusers && this.state.location.topusers.length === 0) {
            leader = (
                <Link className="wideButtons greenBorder greenBG fontWhite" to={'/location/checkin/' + this.state.location.location_id + '/' + urlFriendly(this.state.location.location_name)}>
                    Check in to become Pack Leader
                </Link>
            );
        }

        return (
            <div className="mainAppContainer">
                {this.state.pictureModal && (
                    <CameraModal toggleModal={this.toggleModal} callbackCamera={this.phoneCamera} callbackUpload={this.phoneUpload} />
                )}
                {!!this.state.location && !this.state.loading && (
                    <MainNavigation {...this.props} title={this.state.location.location_name} leftButton="back" rightButton="share" shareTitle={'Check out this location: ' + this.state.location.location_name} />
                )}
                {!this.state.location && !this.state.loading && (
                    <MainNavigation {...this.props} title="Not Found" leftButton="back" />
                )}
                <div className="bodyContent pull2refresh">
                    {!!this.state.loading && (
                        <Spinner />
                    )}
                    {!this.state.location && !this.state.loading && (
                        <NotFound type="locations" class="backgroundRed" />
                    )}
                    {this.state.location && this.state.location.address && this.state.location.address.address_latitude && this.state.location.address.address_longitude && (
                        <div className="profileMap">
                            <LocationMap geoPoint={{ lat: this.state.location.address.address_latitude, lng: this.state.location.address.address_longitude }} />
                        </div>
                    )}
                    {!!this.state.location && (
                        <div>
                            <div className="locationAddressWrapper">
                                <div className="locationAddress maxWidth">
                                    <h4>{this.state.location.location_name}</h4>
                                    {this.state.location.address && this.state.location.address.address_street && (
                                        <span>{this.state.location.address.address_street}</span>
                                    )}
                                    {this.state.location.address && this.state.location.address.address_street2 && (
                                        <span>, {this.state.location.address.address_street2}</span>
                                    )}
                                    {this.state.location.address && this.state.location.address.address_city && (
                                        <span><br />{this.state.location.address.address_city}</span>
                                    )}
                                    {this.state.location.address && this.state.location.address.address_state && (
                                        <span>, {this.state.location.address.address_state}</span>
                                    )}
                                    {this.state.location.address && this.state.location.address.address_country && (
                                        <span><br />{this.state.location.address.address_country}</span>
                                    )}
                                    {this.state.location.address && this.state.location.address.address_zipcode && (
                                        <span>, {this.state.location.address.address_zipcode}</span>
                                    )}
                                    <br />
                                    <Stars stars={this.state.location.Reviewaverage} />
                                    <span className="fontGold">({this.state.location.Reviewcounter})</span>
                                </div>
                            </div>
                            <div className="locationProfile maxWidth">
                                {adminButton}
                                {claimBusinessButton}
                                <div className="locationInfo">
                                    {this.state.location.location_description && (
                                        <div>
                                            {this.state.location.location_description}
                                        </div>
                                    )}
                                    {this.state.location.location_website && (
                                        <div>
                                            Website<br /><a className="fontGreen" href={(this.state.location.location_website.indexOf('://') === -1) ? 'http://' + this.state.location.location_website : this.state.location.location_website} target="_blank" rel="noopener noreferrer">{this.state.location.location_website}</a>
                                        </div>
                                    )}
                                    {contactinfo}
                                    {socialmedia}
                                    {categories}
                                    {leader}
                                    {additionalInfo}
                                    {this.state.location.location_schedule
                                        && timetable(this.state.location.location_schedule)}
                                    {this.state.location && this.state.location.address && (
                                        <a target="_blank" rel="noopener noreferrer" href={'https://www.google.com/maps/dir/Current+Location/' + (this.state.location.address.address_street || '') + ' ' + (this.state.location.address.address_street2 || '') + ' ' + (this.state.location.address.address_city || '') + ' ' + (this.state.location.address.address_state || '') + ' ' + (this.state.location.address.address_country || '') + ' ' + (this.state.location.address.address_zipcode || '')} className="wideButtons greenBorder whiteBG fontGreen">Directions</a>
                                    )}
                                </div>
                                <div className="row buttonsRow">
                                    <div className="checkIn">
                                        <Link to={'/location/checkin/' + this.state.location.location_id + '/' + urlFriendly(this.state.location.location_name)}>
                                            <i className="icn icon-check-in fontDarkGray" />
                                            <span className="fontBlack"> Check In</span>
                                        </Link>
                                    </div>
                                    <div className="favorites">
                                        <Link to="#" onClick={e => this.removeFavorite(e)} className={'removefavorite ' + (!this.state.location.IsFavorite && 'hidden')}>
                                            <i className="icn icon-heart-fill fontRed" />
                                            <span className="fontBlack"> Favorite</span>
                                        </Link>
                                        <Link to="#" onClick={e => this.checkFavorite(e)} className={'checkfavorite ' + (!!this.state.location.IsFavorite && 'hidden')}>
                                            <i className="icn icon-heart-outline fontRed" />
                                            <span className="fontBlack"> Favorite</span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="galleryHolder">
                                    <GalleryLightbox
                                        dispatch={this.props.dispatch}
                                        images={this.state.gallery}
                                        userData={this.props.userData}
                                        count={2}
                                        size="normal"
                                        handleClickSetImage={this.handleClickSetImage}
                                        handleClickRemoveImage={this.handleClickRemoveImage}
                                    />
                                    {this.state.gallery.length > 2 && (
                                        <div className="holderEnclosed">
                                            <Link to={'/location/gallery/' + this.state.location.location_id + '/' + urlFriendly(this.state.location.location_name)}>+ {this.state.gallery.length - 2} More </Link>
                                        </div>
                                    )}
                                </div>
                                <div className="profileGalleryButtons row">
                                    <div>
                                        <input className="hidden" id="file" type="file" onChange={e => this.filesChanged(e.target.files)} accept="image/*" required multiple />
                                        <div onClick={() => { this.changeImage(); }} className="fontGreen">
                                            <i className="icn icon-upload-photo" />
                                            Add Photos
                                        </div>
                                    </div>
                                    <div>
                                        <Link to={'/location/gallery/' + this.state.location.location_id + '/' + urlFriendly(this.state.location.location_name)} className="fontGreen">
                                            <i className="icn icon-gallery" />
                                            View Gallery
                                        </Link>
                                    </div>
                                </div>
                                <div className="addReview">
                                    Add Review
                                    <Link to={'/location/review/' + this.state.location.location_id + '/' + urlFriendly(this.state.location.location_name)}>
                                        <Stars stars={0} big={true} />
                                    </Link>
                                </div>
                            </div>
                            <div className="reviewList">
                                <div className="maxWidth">
                                    <ul>
                                        {this.state.reviews && this.mapReviews(this.state.reviews)}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <SecondaryNavigation selected="search" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(LocationProfile);
