import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Config from '../helpers/Config';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { release_date: '' };
    }

    componentDidMount() {
        fetch(window.location.origin + '/release.json')
            .then(res => res.json())
            .then(settings => {
                console.log('Latest release info:', settings);
                const release_date = new Date(settings.release_date).toLocaleString();
                this.setState({
                    release_date
                });
            });
    }

    render() {
        let contactLink = `mailto:${process.env.REACT_APP_EMAIL_SUPPORT}?subject=To the team at Pooch`;
        if (this.props.credentials && this.props.credentials.user_id) {
            contactLink += ', My UID: ' + this.props.credentials.user_id;
        }

        let footer = null;
        if (this.props.location && this.props.location.pathname && this.props.location.pathname === '/') {
            footer = null;
        } else {
            footer = (
                <footer className="mainFooter">
                    <div className="footerContainer wrapper maxWidth">
                        <div className="containerLeft">
                            <div className="footerLogo">
                                <Link to="/">
                                    <img
                                        src={Config.fileDirectory + 'assets/images/' + Config.logos.twoLines + '@2x.png'}
                                        srcSet={Config.fileDirectory + 'assets/images/' + Config.logos.twoLines + '@1x.png 1x,' + Config.fileDirectory + 'assets/images/' + Config.logos.twoLines + '@2x.png 2x'}
                                        alt="Footer Logo"
                                    />
                                </Link>
                            </div>
                            <div className="footerCaption">
                                <p>A dog friendly network where dog owners can find and connect with other dog owners and dog friendly businesses.</p>
                            </div>
                        </div>
                        <div className="containerRight">
                            <div className="footerMenu">
                                <ul className="menuLinks">
                                    <li><Link to="/privacy">Privacy</Link></li>
                                    <li><Link to="/terms">Terms</Link></li>
                                    <li><a href={contactLink}>Contact Us</a></li>
                                    {/*
                                    <li><Link to={'/about'}>About Pooch</Link></li>
                                    <li><Link to={'/faqs'}>FAQs</Link></li>
                                    <li><Link to={'/press'}>Press</Link></li>
                                    <li><Link to={'/careers'}>Careers</Link></li>
                                    */}
                                </ul>
                            </div>
                            <div className="footerDownloadApp">
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://itunes.apple.com/app/id1049134590"
                                >
                                    <img
                                        className="iosImg"
                                        alt="Download from Apple AppStore"
                                        src={Config.fileDirectory + 'assets/images/logo-app_store.png'}
                                    />
                                </a>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://play.google.com/store/apps/details?id=com.hottomali.OffLeashZone&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                                >
                                    <img
                                        className="androidImg"
                                        alt="Get it on Google Play"
                                        src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="footerBottom">
                        <div className="footerContainer wrapper maxWidth">
                            <div className="footerSocialMedia">
                                <a target="_blank" rel="noopener noreferrer" href="https://instagram.com/poochnetwork">
                                    <i className="icn icon-instagram fontLightGray" />
                                </a>
                                <a target="_blank" rel="noopener noreferrer" href="https://facebook.com/poochnetwork">
                                    <i className="icn icon-facebook fontLightGray" />
                                </a>
                                <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/poochnetwork">
                                    <i className="icn icon-twitter fontLightGray" />
                                </a>
                            </div>
                            <div className="copywrite">
                                <p>
                                    &copy;2018 Pooch. All Rights Reserved.
                                    <br />
                                    {this.state.release_date && (
                                        <React.Fragment>
                                            Last updated: {this.state.release_date}
                                        </React.Fragment>
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </footer>
            );
        }
        return (
            <React.Fragment>
                {footer}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        credentials: state.users.credentials
    };
}

export default withRouter(connect(mapStateToProps)(Footer));
