import ApiError from './ApiError';

export const camera = (callback, direction = 0) => {
    // on ios it crashes when trying to save photo to photoalbum
    // on android we need to declare correctOrientation so the images do not flip
    let options = {};

    if (window.device && window.device.platform && window.device.platform === 'Android') {
        options = {
            quality: 80,
            destinationType: 1,
            sourceType: 1,
            correctOrientation: true,
            targetWidth: 1000,
            targetHeight: 1000,
            saveToPhotoAlbum: false,
            cameraDirection: direction
        };
    } else {
        options = {
            quality: 80,
            destinationType: 1,
            sourceType: 1,
            correctOrientation: false,
            targetWidth: 1000,
            targetHeight: 1000,
            saveToPhotoAlbum: false,
            cameraDirection: direction
        };
    }

    window.navigator.camera.getPicture(callback, cameraError, options);
};

export const cameraRoll = callback => {
    window.navigator.camera.getPicture(callback, cameraError, {
        quality: 80,
        destinationType: 1,
        sourceType: 0,
        correctOrientation: true,
        targetWidth: 1000,
        targetHeight: 1000
    });
};

export const cameraMultiRoll = callback => {
    window.imagePicker.getPictures(
        results => {
            if (results !== 'OK') {
                for (let i = 0; i < results.length; i++) {
                    callback(results[i]);
                }
            }
        }, data => {
            cameraError(data);
        }, { quality: 80, width: 1000, height: 1000 }
    );
};

export const cameraBlob = (file, callback) => {
    window.resolveLocalFileSystemURL(file, fileEntry => {
        fileEntry.file(file => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const imgBlob = new Blob([this.result], { type: 'image/jpeg' });
                navigator.camera.cleanup();
                callback(imgBlob);
            };
            reader.readAsArrayBuffer(file);
        });
    });
};

const cameraError = () => {
    ApiError('There was an error uploading, please try again!');
};
