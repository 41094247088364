import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';

import { deleteDog, updateMissingDog, clearDogPictureFile } from '../../redux/actions/dogs';
import { uploadPicture, deletePicture, setPicture } from '../../redux/actions/all';
import GalleryLightbox from '../../layout/gallery/GalleryLightbox';
import Avatar from '../../layout/UI/Avatar';
import CameraModal from '../../layout/CameraModal';
import BasicConfirm from '../../layout/UI/BasicConfirm';
import ApiError from '../../helpers/ApiError';
import { listDogBreeds, guard } from '../../helpers/Functions';
import Config from '../../helpers/Config';
import { camera, cameraRoll, cameraMultiRoll, cameraBlob } from '../../helpers/Camera';

export default class OwnDogProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
            gallery: [],
            pictureModal: false,
            pictureModal2: false
        };
    }

    componentDidMount() {
        this.setState({
            image: guard(this.props.dogProfile, '.images.0.image_mediumurl') || null,
            gallery: this.props.dogProfile.images
        });
    }

    filesChanged = files => {
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            const file = files[i];
            reader.onloadend = () => {
                this.props.dispatch(uploadPicture(this.props.credentials.token, this.props.dogProfile.dog_id, file, true))
                    .then(response => this.updateThumbs(response, true));
            };
            reader.readAsDataURL(file);
        }
    }

    galleryfiles = files => {
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            const file = files[i];
            reader.onloadend = () => {
                this.props.dispatch(uploadPicture(this.props.credentials.token, this.props.dogProfile.dog_id, file, false))
                    .then(response => this.updateThumbs(response));
            };
            reader.readAsDataURL(file);
        }
    }

    listOwners = owners => owners.map((owner, index) => {
        let comma = '';
        if (index < owners.length - 1) {
            comma = ', ';
        }
        return (
            <span key={index}>
                <Link to={'/user/profile/' + owner.user_id} className="fontGreen">
                    {owner.user_firstname + ' ' + owner.user_lastname}
                </Link>
                {comma}
            </span>
        );
    })

    deleteDog = e => {
        e.preventDefault();
        BasicConfirm(
            'Are you sure?',
            'Do you want to permanently remove ' + this.props.dogProfile.dog_name + ' from the Pooch network?',
            'Yes, Remove.',
            this.handleClickRemove
        );
    }

    handleClickRemove = () => {
        this.props.dispatch(deleteDog(this.props.credentials.token, this.props.dogProfile.dog_id))
            .then(() => {
                this.props.history.push('/user/profile/' + this.props.credentials.user_id);
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    foundDog = e => {
        e.preventDefault();
        BasicConfirm(
            'Did you find ' + this.props.dogProfile.dog_name + '?',
            false,
            'Yes, Mark as Found.',
            this.handleClickFound
        );
    }

    handleClickFound = () => {
        const data = { dogaddress_found: moment().format('YYYY-MM-DD') };
        this.props.dispatch(updateMissingDog(this.props.credentials.token, this.props.dogProfile.dogaddress.dogaddress_id, data))
            .then(() => {
                toast.success('Profile Updated! Glad to hear you found your pup!');
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    // Removes image from GalleryLightBox
    handleClickRemoveImage = id => {
        this.props.dispatch(deletePicture(this.props.credentials.token, id))
            .then(res => {
                console.log(res);
                this.setState(prevState => {
                    const newGallery = prevState.gallery.filter(image => image.image_id !== id);
                    return {
                        gallery: newGallery
                    };
                }, () => {
                    this.setState({ image: this.state.gallery && this.state.gallery[0] && this.state.gallery[0].image_largeurl ? this.state.gallery[0].image_largeurl : null });
                });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    handleClickSetImage = id => {
        this.props.dispatch(setPicture(this.props.credentials.token, id))
            .then(res => {
                let newFeaturedImage = {};
                // we push the new featured image to the top of the list, on the first position
                this.setState(prevState => {
                    const newGallery = prevState.gallery.filter(image => {
                        if (image.image_id === id) newFeaturedImage = image;
                        return image.image_id !== id;
                    });
                    newGallery.sort((a, b) => b.image_id - a.image_id);
                    return {
                        gallery: [newFeaturedImage, ...newGallery],
                        image: res.value.data.image_largeurl
                    };
                });
                toast.success('Profile Image Set Successfully!');
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    toggleModal = () => {
        this.setState({ pictureModal: !this.state.pictureModal });
    }

    toggleModal2 = () => {
        this.setState({ pictureModal2: !this.state.pictureModal2 });
    }

    changeImage = () => {
        if (Config.isMobileApp) {
            this.toggleModal();
        } else {
            const selectfile = document.getElementById('file');
            selectfile.click();
        }
    }

    addToGallery = e => {
        e.preventDefault();
        if (Config.isMobileApp) {
            this.toggleModal2();
        } else {
            const selectfile = document.getElementById('galleryfile');
            selectfile.click();
        }
    }

    profileCamera = () => {
        camera(this.profileSuccess);
    }

    profileUpload = () => {
        cameraRoll(this.profileSuccess);
    }

    profileSuccess = file => {
        cameraBlob(file, this.uploadToProfile);
    }

    uploadToProfile = imgBlob => {
        this.props.dispatch(uploadPicture(this.props.credentials.token, this.props.dogProfile.dog_id, imgBlob, true))
            .then(response => this.updateThumbs(response, true));
    }

    galleryCamera = () => {
        camera(this.gallerySuccess);
    }

    galleryUpload = () => {
        cameraMultiRoll(this.gallerySuccess);
    }

    gallerySuccess = file => {
        cameraBlob(file, this.uploadToGallery);
    }

    uploadToGallery = imgBlob => {
        this.props.dispatch(uploadPicture(this.props.credentials.token, this.props.dogProfile.dog_id, imgBlob, false))
            .then(response => this.updateThumbs(response));
    }

    updateThumbs = (response, featured) => {
        if (featured) {
            this.setState({ image: response.value.data.image_mediumurl });
        }
        this.setState(prevState => ({
            pictureModal: false,
            pictureModal2: false,
            gallery: [response.value.data, ...prevState.gallery]
        }));
        if (!!navigator && !!navigator.camera && !!navigator.camera.cleanup) {
            navigator.camera.cleanup();
        }
    }

    removeImage = () => {
        this.props.dogProfile.dispatch(clearDogPictureFile());
        this.setState(() => ({
            image: null
        }));
    }

    render() {
        const dog_is_lost = !!this.props.dogProfile && !!this.props.dogProfile.dogaddress && !this.props.dogProfile.dogaddress.dogaddress_found;
        const dog_passed_away = !!this.props.dogProfile.dog_deceased;
        return (
            <div>
                {this.state.pictureModal && (
                    <CameraModal toggleModal={this.toggleModal} callbackCamera={this.profileCamera} callbackUpload={this.profileUpload} />
                )}
                {this.state.pictureModal2 && (
                    <CameraModal toggleModal={this.toggleModal2} callbackCamera={this.galleryCamera} callbackUpload={this.galleryUpload} />
                )}
                <div className={'profilePictureWrapper dog' + (dog_is_lost ? ' lost' : '') + (dog_passed_away ? ' passed' : '')}>
                    <div className="profile-avatar">
                        <input className="hidden" name="file" id="file" type="file" onChange={e => this.filesChanged(e.target.files)} accept="image/*" required />
                        <div className="fontWhite wrapper alignCenter">
                            <div className="round-avatar huge dog">
                                <Avatar
                                    src={this.state.image}
                                    alt={this.props.dogProfile.dog_name}
                                    onClick={this.changeImage}
                                    type="dog"
                                />
                            </div>
                            {/* this.state.image &&
                                <div onClick={()=>{this.removeImage()}}  className="removeImage">Remove  Picture</div>
                            */}
                            <div onClick={() => { this.changeImage(); }} className="changeImage spacerVertical">Change Profile Picture</div>
                        </div>
                    </div>
                </div>
                {/*
                    <div className="tabSubmenu row">
                        <Link to="/user/dogadd">Add another dog</Link>
                        <Link to="/friends"></Link>
                        <Link to="/activities">Activity</Link>
                    </div>
                */}
                <div className="profileDetails maxWidth noPadding">
                    {dog_is_lost && (
                        <React.Fragment>
                            <div className="spacerSmall" />
                            <div className="wrapper">
                                <Link onClick={e => this.foundDog(e)} to="#" className="wideButtons redBorder redBG fontWhite">
                                    Mark Dog as Found
                                </Link>
                            </div>
                        </React.Fragment>
                    )}
                    <div>
                        <div className="boxContainer">
                            <div className="boxTitle row">
                                <div className="title"><h4>Pictures</h4>
                                </div>
                            </div>
                            <div className="edit-dog image-options">
                                <Link to="#" onClick={e => { this.addToGallery(e); }} className="fontGreen">
                                    <i className="icn icon-upload-photo" /> Add Pictures
                                </Link>
                                <input name="galleryfile" className="hidden" id="galleryfile" type="file" onChange={e => this.galleryfiles(e.target.files)} accept="image/*" required multiple />
                                {this.state.gallery.length > 0 && (
                                    <Link to={'/dog/' + this.props.dogProfile.dog_id + '/gallery'} className="fontGreen"> <i className="icn icon-gallery" /> View Gallery </Link>
                                )}
                            </div>
                        </div>
                        {this.state.gallery.length > 0 && (
                            <div className="galleryHolder">
                                <GalleryLightbox
                                    dispatch={this.props.dispatch}
                                    credentials={this.props.credentials}
                                    dogProfile={this.props.dogProfile}
                                    userData={this.props.userData}
                                    images={this.state.gallery}
                                    count={2}
                                    size="normal"
                                    handleClickSetImage={this.handleClickSetImage}
                                    handleClickRemoveImage={this.handleClickRemoveImage}
                                />
                                {this.state.gallery.length > 2 && (
                                    (
                                        <div className="holderEnclosed">
                                            <Link to={'/dog/' + this.props.dogProfile.dog_id + '/gallery'}>+ {this.state.gallery.length - 2} More</Link>
                                        </div>
                                    )
                                )}
                            </div>
                        )}
                    </div>
                    <div className="boxContainer">
                        <div className="boxTitle row">
                            <div className="title">Details</div>
                        </div>
                    </div>
                    <div className="wrapper maxWidth">
                        <ul className="listDivided rightIcon">
                            <li>
                                <Link to={'/dog/' + this.props.dogProfile.dog_id + '/edit/details'} className="fontBlack">
                                    <div>
                                        <label className="fontBlack h6">Pooch ID</label><br />
                                        {(this.props.dogProfile.parententity && this.props.dogProfile.parententity.entity_poochid && 'pooch.pet/' + this.props.dogProfile.parententity.entity_poochid) || 'Create Pooch ID'}
                                    </div>
                                    <i className="icn icon-right-arrow fontGreen" />
                                </Link>
                            </li>
                            <li>
                                <Link to={'/dog/' + this.props.dogProfile.dog_id + '/edit/name'} className="fontBlack">
                                    <div>
                                        <label className="fontBlack h6">Name</label><br />
                                        {this.props.dogProfile.dog_name}
                                    </div>
                                    <i className="icn icon-right-arrow fontGreen" />
                                </Link>
                            </li>
                            <div className="details">
                                <div className="imageSection">
                                    <li>
                                        <Link to={'/dog/' + this.props.dogProfile.dog_id + '/edit/description'} className="fontBlack">
                                            <div>
                                                <label className="fontBlack h6">About {this.props.dogProfile.dog_name}</label><br />
                                                {this.props.dogProfile.dog_description || 'No description yet'}
                                            </div>
                                            <i className="icn icon-right-arrow fontGreen" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/dog/' + this.props.dogProfile.dog_id + '/edit/breed'} className="fontBlack">
                                            <div>
                                                <label className="fontBlack h6">Breed</label><br />
                                                {this.props.dogProfile && this.props.dogProfile.breeds && this.props.dogProfile.breeds.length && listDogBreeds(this.props.dogProfile.breeds)}
                                            </div>
                                            <i className="icn icon-right-arrow fontGreen" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/dog/' + this.props.dogProfile.dog_id + '/edit/details'} className="fontBlack">
                                            <div>
                                                <label className="fontBlack h6">Sex</label><br />
                                                {this.props.dogProfile.dog_sex.charAt(0).toUpperCase() + this.props.dogProfile.dog_sex.slice(1)}
                                            </div>
                                            <i className="icn icon-right-arrow fontGreen" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/dog/' + this.props.dogProfile.dog_id + '/edit/details'} className="fontBlack">
                                            <div>
                                                <label className="fontBlack h6">Weight</label><br />
                                                {parseFloat(this.props.dogProfile.dog_weight).toFixed(2)} lbs
                                            </div>
                                            <i className="icn icon-right-arrow fontGreen" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/dog/' + this.props.dogProfile.dog_id + '/edit/details'} className="fontBlack">
                                            <div>
                                                <label className="fontBlack h6">Fixed</label><br />
                                                {this.props.dogProfile.dog_fixed ? 'Fixed' : 'Not Fixed'}
                                            </div>
                                            <i className="icn icon-right-arrow fontGreen" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/dog/' + this.props.dogProfile.dog_id + '/edit/details'} className="fontBlack">
                                            <div>
                                                <label className="fontBlack h6">Available for events</label><br />
                                                {this.props.dogProfile.dog_playdates ? '' : 'Not'} Available for events
                                            </div>
                                            <i className="icn icon-right-arrow fontGreen" />
                                        </Link>
                                    </li>
                                </div>
                            </div>
                            <li>
                                <Link to={'/dog/' + this.props.dogProfile.dog_id + '/edit/details'} className="fontBlack">
                                    <div>
                                        <label className="fontBlack h6">Birthday</label><br />
                                        {this.props.dogProfile.dog_birthdate || 'Not selected'}
                                    </div>
                                    <i className="icn icon-right-arrow fontGreen" />
                                </Link>
                            </li>
                            {/*
                            <li>
                                <Link to={"/dog/"+this.props.dogProfile.dog_id+"/edit/details"} className="fontBlack">
                                    <div>
                                        <label className="fontBlack h6">Dog Passed Away</label><br/>
                                        {this.props.dogProfile.dog_deceased||'Not passed away'}
                                    </div>
                                    <i className="icn icon-right-arrow fontGreen" />
                                </Link>
                            </li>
                            */}
                            <li>
                                <Link to="/user/dogs" className="fontBlack" onClick={e => this.deleteDog(e)}>
                                    <div>
                                        <label className="fontBlack h6">Remove</label><br />
                                        Delete Dog
                                    </div>
                                    <i className="icn icon-right-arrow fontGreen" />
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="dogSocialMediaProfile">
                        <div>
                            <div className="boxContainer">
                                <div className="boxTitle row">
                                    <div className="title">Social Media</div>
                                </div>
                            </div>
                            <div className="profileDetails">
                                <Link to={'/dog/' + this.props.dogProfile.dog_id + '/edit/details'} className="fontBlack">
                                    <ul>
                                        <li className="socialMediaListItem row">
                                            <i className="icn icon-facebook fontGreen" />
                                            <span>
                                                <p>{this.props.dogProfile.dog_socialmedia && this.props.dogProfile.dog_socialmedia.facebook && this.props.dogProfile.dog_socialmedia.facebook}</p>
                                                <i className="icn icon-right-arrow fontGreen" />
                                            </span>
                                        </li>
                                        <li className="socialMediaListItem row">
                                            <i className="icn icon-twitter fontGreen" />
                                            <span>
                                                <p>{this.props.dogProfile.dog_socialmedia && this.props.dogProfile.dog_socialmedia.twitter && this.props.dogProfile.dog_socialmedia.twitter}</p>
                                                <i className="icn icon-right-arrow fontGreen" />
                                            </span>
                                        </li>
                                        <li className="socialMediaListItem row">
                                            <i className="icn icon-instagram fontGreen" />
                                            <span>
                                                <p>{this.props.dogProfile.dog_socialmedia && this.props.dogProfile.dog_socialmedia.instagram && this.props.dogProfile.dog_socialmedia.instagram}</p>
                                                <i className="icn icon-right-arrow fontGreen" />
                                            </span>
                                        </li>
                                    </ul>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper maxWidth">
                        <Link to={'/dog/' + this.props.dogProfile.dog_id} className="rightRoundButton greenBorder whiteBG fontGreen">View Profile</Link>
                        {!dog_is_lost && (
                            <Link to="/reportmissing" className="rightRoundButton redBorder redBG fontWhite">
                                Report Lost Dog
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
