import React from 'react';
import ReactGA from 'react-ga';

import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import ExploreList from './ExploreList';

export default class FilterCategory extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    render() {
        return (
            <div className="mainAppContainer textList">
                <MainNavigation title="Search Dog Parks/Services" leftButton="back" />
                <ExploreList {...this.props} fullCategories={true} />
                <SecondaryNavigation selected="search" />
            </div>
        );
    }
}
