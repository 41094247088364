import React from 'react';
import { Link } from 'react-router-dom';

const PlaydateJoinButtons = ({ joinedStatus, changeStatus }) => (
    <div className="row alignItems">
        {joinedStatus === 'declined'
            ? (
                <Link to="#decline" onClick={e => changeStatus(e)} className="flexButtons fontGreen">
                    Declined
                </Link>
            )
            : (
                <Link to="#decline" onClick={e => changeStatus(e, 'declined')} className="flexButtons fontRed">
                    Decline
                </Link>
            )}
        {joinedStatus === 'maybe'
            ? (
                <Link to="#maybe" onClick={e => changeStatus(e)} className="flexButtons fontGreen">
                    Maybe
                </Link>
            )
            : (
                <Link to="#maybe" onClick={e => changeStatus(e, 'maybe')} className="flexButtons fontRed">
                    Maybe
                </Link>
            )}
        {joinedStatus === 'joined'
            ? (
                <Link to="#accept" onClick={e => changeStatus(e)} className="flexButtons fontGreen">
                    Accepted
                </Link>
            )
            : (
                <Link to="#accept" onClick={e => changeStatus(e, 'joined')} className="flexButtons fontRed">
                    Accept
                </Link>
            )}
    </div>
);

export default PlaydateJoinButtons;
