import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import ApiError from '../../helpers/ApiError';
import { getDogProfile, clearDogProfile } from '../../redux/actions/dogs';
import Spinner from '../../layout/UI/Spinner';
import NotFound from '../../layout/NotFound';
import OtherDogProfile from './OtherDogProfile';

export class DogProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: this.props.loading !== false,
            currentId: (this.props.match && this.props.match.params && this.props.match.params.id) || false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.state.currentId) {
            if (window && window.location && window.location.pathname) {
                ReactGA.pageview(window.location.pathname + window.location.search);
            }
            this.props.dispatch(clearDogProfile());
            this.start();
        }
    }

    start = () => {
        this.props.dispatch(getDogProfile(this.props.credentials.token, this.props.match.params.id))
            .then(() => {
                this.setState({ loading: false });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loading: false });
            });
    }

    render() {
        return (
            <div className="mainAppContainer">
                <div className="bodyContent dog-profiles">
                    {(!this.props.dogProfile || !this.props.dogProfile.dog_name) && !this.state.loading && (
                        <NotFound type="dog" class="backgroundRed" />
                    )}
                    {this.props.dogProfile && this.props.dogProfile.dog_name && !this.state.loading && (
                        <OtherDogProfile {...this.props} />
                    )}
                    {!!this.state.loading && (<Spinner />)}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        dogProfile: state.dogs.dogProfile
    };
}

export default connect(mapStateToProps)(DogProfile);
