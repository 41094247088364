import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import { userDelete, sendRequest, updateRequest, blockRequest, updateUserRole } from '../../redux/actions/users';
import { guard } from '../../helpers/Functions';
import ApiError from '../../helpers/ApiError';
import sb from '../../helpers/SendBird';
import BasicConfirm from '../../layout/UI/BasicConfirm';
import AdvancedConfirm from '../../layout/UI/AdvancedConfirm';
import Spinner from '../../layout/UI/Spinner';

const ROLE_SUPER_ADMIN = 'superadmin';
// const ROLE_ADMIN = 'admin'; // Used to administrate an specific location, this it's handled from "User Administrator"
const ROLE_REGULAR = 'regular';

/**
 * Component with the buttons that will appear in the ProfileHeader which will allow some actions with the displayed profile
 *
 * @param {Object} user a JSON Object with the information of the user to show
 * @param {Object} userData a JSON Object with the information of the logged user
 * @param {Object} credentials a JSON Object with the credentials of the logged user
 * @param {function} dispatch function used to hit end-points
 * @param {bool} ownProfile true if it's the profile of the logged user
 */
class UserProfileButtons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
            manageAccount: false,
            loading: false
        };
    }

    friendRequest = (e, id) => {
        e.preventDefault();
        this.props.dispatch(sendRequest(this.props.credentials.token, id))
            .then(res => {
                console.log(res);
                toast.info('Friend Request Sent');
            }).catch(err => {
                ApiError(err);
            });
    }

    cancelFriendShip = (e, id) => {
        e.preventDefault();
        BasicConfirm(
            'Are you sure?',
            'Do you want to cancel your friend request?',
            'Yes, Cancel request!',
            this.handleClickCancel.bind(this, id)
        );
    }

    handleClickCancel = id => {
        this.props.dispatch(updateRequest(this.props.credentials.token, id, { friendship_status: 'cancelled' }));
    }

    unfriendRequest = (e, name, id) => {
        e.preventDefault();
        BasicConfirm(
            'Are you sure?',
            'Do you want to unfriend ' + name + '?',
            'Yes, Unfriend.',
            this.handleClickUnfriend.bind(this, id)
        );
    }

    handleClickUnfriend = id => {
        this.props.dispatch(updateRequest(this.props.credentials.token, id, { friendship_status: 'unfriended' }));
    }

    blockRequest = (e, name, id) => {
        e.preventDefault();
        BasicConfirm(
            'Are you sure?',
            'Do you want to unfriend and block ' + name + '?',
            'Yes, Block.',
            this.handleClickBlock.bind(this, id)
        );
    }

    deleteAccount = (token, userId) => {
        const headerText = 'Are you sure you want to close this account?';
        const bodyText = 'Once it\'s closed, the user won\'t be able to use this Pooch account, and all their account data and uploaded pictures will be removed. It can\'t be reopened, and we can\'t restore it. Please type "REMOVE ACCOUNT" below to confirm.';
        this.setState({ loading: true });
        AdvancedConfirm(
            headerText,
            parse(bodyText),
            'REMOVE ACCOUNT',
            'Remove it',
            () => {
                console.log('Deleting account:', userId);
                this.props.dispatch(userDelete(token, userId))
                    .then(() => {
                        this.setState({ loading: false });
                        toast.success('Account deleted successfully.');
                        this.props.history.push('/manageusers');
                    })
                    .catch(err => {
                        this.setState({ loading: false });
                        console.log('deleteAccount:err:', err);
                        ApiError(err?.response?.data?.[0]?.message || err.message);
                    });
            }
        );
    };

    updateRole = (token, userId, role) => {
        const data = { user_role: role, user_firstname: 'Yuusuke' };
        this.setState({ loading: true });
        this.props.dispatch(updateUserRole(token, userId, data))
            .then(() => {
                this.setState({ loading: false });
                toast.success('User role updated successfully.');
            })
            .catch(err => {
                this.setState({ loading: false });
                console.log('updateRole:err:', err);
                ApiError(err?.response?.data?.[0]?.message || err.message);
            });
    }

    handleClickBlock = id => {
        this.props.dispatch(blockRequest(this.props.credentials.token, id, { friendship_status: 'blocked' }));
    }

    onMouseLeaveHandler = () => {
        this.setState({
            hover: false
        });
    }

    onMouseEnterHandler = () => {
        this.setState({
            hover: true
        });
    }

    toggleModal = () => {
        if (this.props.credentials?.token) {
            this.setState(prevState => ({ manageAccount: !prevState.manageAccount }));
        } else {
            ApiError('You need to log in or sign up.');
        }
    }

    acceptRequest(e, id) {
        e.preventDefault();
        this.props.dispatch(updateRequest(this.props.credentials.token, id, { friendship_status: 'accepted' }));
    }

    unblockRequest(e, id) {
        e.preventDefault();
        this.props.dispatch(updateRequest(this.props.credentials.token, id, { friendship_status: 'cancelled' }));
        this.setState({ hover: false });
    }

    chatWithUser(e, userId) {
        e.preventDefault();
        const roomName = this.props.user.user_firstname;
        const that = this;
        if (this.props.credentials.token) {
            sb.connect(
                this.props.credentials.user_id,
                this.props.credentials.token,
                () => {
                    sb.GroupChannel.createChannelWithUserIds([userId], true, roomName, null, null, (createdChannel, error) => {
                        if (error) {
                            toast.error('Sorry, there was an issue creating this chat, please try again.');
                            return;
                        }
                        that.props.history.push('/chat/channel/' + createdChannel.url);
                    });
                }
            );
        } else {
            ApiError('You need to log in or sign up.');
        }
    }

    render() {
        let friendshipButton = null;
        let messageButton = null;
        let blockButton = null;
        let editButton = null;
        let adminButton = null;

        if (this.props.ownProfile && this.props.credentials.token) {
            editButton = (
                <Link
                    className="fontGreen"
                    to={'/user/profile/' + this.props.user.user_id + '/edit'}
                >
                    Edit Your Profile
                </Link>
            );
        } else {
            // if this user sent a friend request and is still pending
            if (
                !!guard(this.props.credentials, '.user_id')
                && this.props.user && this.props.user.myfriendship && this.props.user.myfriendship.sender_id
                && this.props.user.myfriendship.sender_id === this.props.credentials.user_id
                && this.props.user.myfriendship.friendship_status === 'pending'
            ) {
                friendshipButton = (
                    <Link to="#" className="request-sent" onClick={e => this.cancelFriendShip(e, this.props.user.myfriendship.friendship_id)}>
                        Request Sent
                    </Link>
                );
            } else if (
                // if this user received a friend request and is still pending
                !!guard(this.props.credentials, '.user_id')
                && this.props.user && this.props.user.myfriendship && this.props.user.myfriendship.receiver_id
                && this.props.user.myfriendship.receiver_id === this.props.credentials.user_id
                && this.props.user.myfriendship.friendship_status === 'pending'
            ) {
                friendshipButton = (
                    <Link to="#" className="accept-request" onClick={e => this.acceptRequest(e, this.props.user.myfriendship.friendship_id)}>
                        Accept Request
                    </Link>
                );
            } else if (
                (
                    !!guard(this.props.credentials, '.user_id')
                    && this.props.user && this.props.user.myfriendship && this.props.user.myfriendship.friendship_status
                    && (
                        this.props.user.myfriendship.friendship_status === 'unfriended'
                        || this.props.user.myfriendship.friendship_status === 'declined'
                        || this.props.user.myfriendship.friendship_status === 'cancelled'
                    )
                )
                || (this.props.user && !this.props.user.myfriendship)
            ) {
                // if relationship between users is open for friendship, havent had a relationship, have unfriended, declined or cancelled, but not BLOCKED
                friendshipButton = (
                    <Link to="#" className="friendship-status fontGreen" onClick={e => this.friendRequest(e, this.props.user.user_id)}>
                        Add Friend
                    </Link>
                );
            } else if (
                // if users have a
                !!guard(this.props.credentials, '.user_id')
                && this.props.user && this.props.user.myfriendship && this.props.user.myfriendship.friendship_status
                && this.props.user.myfriendship.friendship_status === 'accepted'
            ) {
                friendshipButton = (
                    <Link to="#" className="friendship-status fontGreen" onClick={e => this.unfriendRequest(e, this.props.user.user_firstname, this.props.user.myfriendship.friendship_id)}>
                        Friend
                    </Link>
                );
            }

            if (
                (
                    !!guard(this.props.credentials, '.user_id')
                    && this.props.user && this.props.user.myfriendship && this.props.user.myfriendship.friendship_status
                    && this.props.user.myfriendship.friendship_status !== 'blocked'
                )
                || !this.props.user.myfriendship
            ) {
                messageButton = (
                    <Link to="#" className="message-user fontGreen" onClick={e => this.chatWithUser(e, this.props.user.user_id)}>
                        Message
                    </Link>
                );
            }

            if (
                !!guard(this.props.credentials, '.user_id')
                && this.props.user && this.props.user.myfriendship && this.props.user.myfriendship.friendship_status
                && this.props.user.myfriendship.friendship_status === 'blocked'
            ) {
                blockButton = (
                    <Link
                        to="#"
                        className="unblock-user fontBlue"
                        onClick={e => this.unblockRequest(e, this.props.user.myfriendship.friendship_id)}
                        onMouseEnter={this.onMouseEnterHandler}
                        onMouseLeave={this.onMouseLeaveHandler}
                    >
                        {!this.state.hover && (
                            <p className="hover-icon"><i className="icn icon-cross" />User Blocked</p>
                        )}
                        {this.state.hover && (
                            <p>Unblock</p>
                        )}
                    </Link>
                );
            } else if (
                (
                    !!guard(this.props.credentials, '.user_id')
                    && this.props.user && this.props.user.myfriendship && this.props.user.myfriendship.friendship_status
                    && this.props.user.myfriendship.friendship_status !== 'blocked'
                )
                || !this.props.user.myfriendship
            ) {
                blockButton = (
                    <Link
                        to="#"
                        className="block-user fontGreen"
                        onClick={e => this.blockRequest(e, this.props.user.user_firstname, this.props.user.user_id)}
                        onMouseEnter={this.onMouseEnterHandler}
                        onMouseLeave={this.onMouseLeaveHandler}
                    >
                        {this.state.hover && (
                            <p className="hover-icon">
                                <i className="icn icon-cross block-user-icon" /> Block
                            </p>
                        )}
                        {this.state.active && (
                            <i className="icn icon-cross block-user-icon" />
                        )}
                        {!this.state.hover && (
                            <p>Block</p>
                        )}
                    </Link>
                );
            }
        }

        if (this.props.userData?.user_role === 'superadmin') {
            adminButton = (
                <Link
                    to="#"
                    className="fontGreen"
                    onClick={e => {
                        e.preventDefault();
                        this.setState({ manageAccount: true });
                    }}
                >
                    Manage
                </Link>
            );
        }

        return (
            <React.Fragment>
                <div className="tabSubmenu">
                    <div className="maxWidth noPadding row">
                        <div className="inlineMultiButtons row maxWidth">
                            {editButton}
                            {friendshipButton}
                            {messageButton}
                            {blockButton}
                            {adminButton}
                        </div>
                    </div>
                </div>
                {this.state.manageAccount && (
                    <ReactModal
                        isOpen={this.state.manageAccount}
                        contentLabel="onRequestClose"
                        onRequestClose={this.toggleModal}
                        className="Modal ModalAdmin"
                        overlayClassName="Overlay"
                        ariaHideApp={false}
                    >
                        <div className="adminSection">
                            <h2 className="fontWhite">
                                Update User role
                            </h2>
                            <div className="wrapper maxWidth flex-grid">
                                <button
                                    className={'wideButtons transparentBorder fontGreen ' + (this.props.user.user_role === ROLE_REGULAR ? 'whiteOpaque' : 'whiteBG')}
                                    type="button"
                                    onClick={() => this.updateRole(this.props.credentials.token, this.props.user.user_id, ROLE_REGULAR)}
                                    disabled={this.props.user.user_role === ROLE_REGULAR || this.state.loading}
                                >
                                    Regular
                                </button>
                                <button
                                    className={'wideButtons transparentBorder fontGreen ' + (this.props.user.user_role === ROLE_SUPER_ADMIN ? 'whiteOpaque' : 'whiteBG')}
                                    type="button"
                                    onClick={() => this.updateRole(this.props.credentials.token, this.props.user.user_id, ROLE_SUPER_ADMIN)}
                                    disabled={this.props.user.user_role === ROLE_SUPER_ADMIN || this.state.loading}
                                >
                                    Super Admin
                                </button>
                            </div>
                        </div>
                        <div className="adminSection">
                            <h2 className="fontWhite">
                                Remove this User
                            </h2>
                            <button
                                className="wideButtons transparentBorder redBG fontWhite"
                                type="button"
                                onClick={() => this.deleteAccount(this.props.credentials.token, this.props.user.user_id)}
                                disabled={this.state.loading}
                            >
                                Remove
                            </button>
                        </div>
                        {this.state.loading && (<Spinner />)}
                    </ReactModal>
                )}
            </React.Fragment>
        );
    }
}

UserProfileButtons.propTypes = {
    user: PropTypes.instanceOf(Object).isRequired,
    userData: PropTypes.instanceOf(Object).isRequired,
    credentials: PropTypes.instanceOf(Object).isRequired,
    dispatch: PropTypes.func.isRequired,
    ownProfile: PropTypes.bool.isRequired
};

export default withRouter(UserProfileButtons);
