import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Avatar from '../../layout/UI/Avatar';
import GalleryLightbox from '../../layout/gallery/GalleryLightbox';
import { Stars } from '../../layout/UI/Stars';
import { guard } from '../../helpers/Functions';

export class UserReview extends React.Component {
    render() {
        const userName = (this.props.user.user.user_firstname || '') + ' ' + (this.props.user.user.user_lastname || '');
        const publishedDate = (this.props.user.review_createdat);

        return (
            <li className="userReviewWrapper">
                <div className="userReview" itemProp="review" itemScope itemType="http://schema.org/Review">
                    <Link to={'/user/profile/' + this.props.user.user_id} className="round-avatar">
                        <Avatar
                            src={guard(this.props.user, '.user.images.0.image_smallurl')}
                            alt={userName}
                        />
                    </Link>
                    <Link to={'/user/profile/' + this.props.user.user_id} className="info">
                        {this.props.user.user && (
                            <h4 className="fontGreen" itemProp="author">{userName}</h4>
                        )}
                        {this.props.user && this.props.user.user && this.props.user.user.lastaddress && (this.props.user.user.lastaddress.address_city || this.props.user.user.lastaddress.address_country) && (
                            <div className="fontGray h7" itemProp="name">
                                {this.props.user.user.lastaddress.address_city || ''} {this.props.user.user.lastaddress.address_country || ''}
                            </div>
                        )}
                        <span className="fontDarkGray" itemProp="description">
                            {!!this.props.user && !!this.props.user.review_comment && this.props.user.review_comment.split('\n').map((item, key) => <span key={key}>{item}<br /></span>)}
                        </span>
                        {this.props.user.reviews && (
                            <div className="userActivity row">
                                <div>{this.props.user.reviews || 0} Reviews</div>
                                <div>{this.props.user.pictures || 0} Photos</div>
                                <div>{this.props.user.checkins || 0} Check Ins</div>
                            </div>
                        )}
                    </Link>
                    <div>
                        <div className="fontDarkGray label h6" itemProp="datePublished">
                            {moment(publishedDate).format('MMMM Do, YYYY')}
                        </div>
                        <div itemProp="reviewRating" itemScope itemType="http://schema.org/Rating">
                            <Stars stars={this.props.user.review_rating} /> {this.props.user.review_rating}
                        </div>
                    </div>
                </div>
                {!!this.props.user.images && !!this.props.user.images.length && (
                    <GalleryLightbox images={this.props.user.images} count={4} size="small" fillers={false} />
                )}
            </li>
        );
    }
}
