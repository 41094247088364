import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { setEmail, forgotPassword } from '../../redux/actions/users';
import ApiError from '../../helpers/ApiError';
import TopNav from './TopNav';

class LoginForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pause: false
        };
    }

    componentDidMount() {
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    verifyData = e => {
        e.preventDefault();
        const that = this;
        if (this.state.pause) return;
        if (!this.props.userData.user_email) {
            ApiError('Please write your Email!');
        } else {
            this.setState({ pause: true });
            this.props.dispatch(forgotPassword(this.props.userData.user_email))
                .then(() => {
                    that.setState({ pause: false });
                    that.props.history.push('/reset');
                })
                .catch(err => {
                    ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                    that.setState({ pause: false });
                });
        }
    }

    render() {
        return (
            <div className="modal display-block">
                <div className="mainAppContainer LoginScreens Login">
                    <TopNav backLink="/loginemail" />
                    <div className="LoginWrapper wrapper">
                        <h1>Password Recovery</h1>
                    </div>
                    <form onSubmit={this.verifyData}>
                        <div className="LoginWrapper wrapper maxWidth">
                            <div className="innerLabelInput">
                                <span className="blocking-span">
                                    <input
                                        className="inputText"
                                        id="userEmail"
                                        name="userEmail"
                                        type="email"
                                        value={this.props.userData.user_email || ''}
                                        onChange={e => this.props.dispatch(setEmail(e.target.value))}
                                        required
                                    />
                                    <span className="floating-label">Email address</span>
                                </span>
                            </div>
                            <input type="submit" value="Reset My Password &gt;" className="rightRoundButton whiteBorder whiteOpaque fontWhite" />
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(LoginForgotPassword);
