import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import ActivityNavigation from '../../layout/navigation/ActivityNavigation';
import { InviteFriends } from '../../layout/InviteFriends';
import ActivityDispatcher from '../../layout/ActivityDispatcher';

export class Notifications extends React.Component {
    componentDidMount() {
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    render() {
        return (
            <div className="mainAppContainer">
                <MainNavigation title="Notifications" />
                <ActivityNavigation.ActivityNavigationMobile selected="notifications" />
                <div className="bodyContent pull2refresh maxWidth">
                    <InviteFriends />
                    <ActivityDispatcher
                        credentials={this.props.credentials}
                        dispatch={this.props.dispatch}
                        type="notification"
                        disableImages={true}
                        disableActions={true}
                    />
                </div>
                <SecondaryNavigation selected="" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(Notifications);
