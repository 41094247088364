import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Config from './components/helpers/Config';

export default class PrivateRoute extends React.Component {
    render() {
        return (
            <Route
                exact
                path={this.props.path}
                component={props => {
                    // return !!this.props.credentials.token ? <Header><this.props.component {...props}/></Header>:<Redirect to="/"/>
                    if (!Config.isMobileApp) {
                        return <this.props.component {...props} />;
                    }
                    return this.props.credentials.token ? <this.props.component {...props} /> : <Redirect to="/" />;
                }}
            />
        );
    }
}
