import React from 'react';

import additionalInputs from './DynamicInputs';

const dynamicInputs = (selectedCategories, checkmarkCallback, inputCallback, stateAdditionalInfo) => {
    const showInputs = [];
    for (const key in additionalInputs) {
        let addInput = false;
        for (const cat in additionalInputs[key].categories) {
            if (selectedCategories.includes(additionalInputs[key].categories[cat])) {
                addInput = true;
                break;
            }
        }

        if (addInput) {
            if (additionalInputs[key].type === 'checkmark') {
                showInputs.push(
                    <div key={key} className="row separator" id={key} onClick={checkmarkCallback}>
                        <div className="leftLabel h6" id={key}>{additionalInputs[key].label}</div>
                        <label className="checkmarkContainer inline">
                            <input
                                type="checkbox"
                                id={key}
                                name={key}
                                checked={(!!stateAdditionalInfo && !!stateAdditionalInfo[key])}
                                onChange={() => { }}
                            />
                            <span className="checkmark small" />
                        </label>
                    </div>
                );
            }

            if (additionalInputs[key].type === 'input') {
                showInputs.push(
                    <div key={key} className="innerLabelInput">
                        <span className="blocking-span">
                            <input
                                className="inputText"
                                name={key}
                                type="text"
                                onChange={inputCallback}
                                value={(stateAdditionalInfo && stateAdditionalInfo[key]) || ''}
                            />
                            <span className="floating-label">{additionalInputs[key].label}</span>
                        </span>
                    </div>
                );
            }
        }
    }

    return showInputs;
};

export default dynamicInputs;
