import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import {
    setCreateLocationCity,
    setCreateLocationState,
    setCreateLocationCountry,
    setCreateLocationPostal,
    setCreateLocationStreet1,
    setCreateLocationStreet2
} from '../../redux/actions/locations';
import ApiError from '../../helpers/ApiError';
import { getGeoLocation } from '../../helpers/GeoLocation';
import { guard } from '../../helpers/Functions';
import CountriesStates from '../../helpers/CountriesStates';
import Columns from '../../hoc/Columns';

class AddLocationAddress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listOfCountries: [],
            listOfStates: [],
            countrySelectedID: -1,
            stateSelectedID: -1
        };
    }

    getGeoLocation = getGeoLocation.bind(this)

    componentDidMount() {
        if (window?.location?.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        window.scrollTo(0, 0);
        this.countriesDropdownList();
        this.searchDropdownLocations(
            guard(this.props.createLocation, '.address.address_country'),
            guard(this.props.createLocation, '.address.address_state')
        );
    }

    verifyData = e => {
        e.preventDefault();
        if (!!guard(this.props.createLocation, '.address.address_city') && !!guard(this.props.createLocation, '.address.address_country') && !!guard(this.props.createLocation, '.address.address_state') && !!guard(this.props.createLocation, '.address.address_street')) {
            this.props.history.push('/location/add/details' + this.props.history.location.search);
        } else {
            ApiError('Please write your Address, Street 1, City, Country and State/Province');
        }
    }

    getLocationData = e => {
        e.preventDefault();
        getGeoLocation(this.props, this.showLocation, this.props.credentials.token, true, 1500);
    }

    showLocation = () => {
        if (guard(this.props.userLocation, '.address_street')) {
            this.props.dispatch(setCreateLocationStreet1(this.props.userLocation.address_street));
        }
        if (guard(this.props.userLocation, '.address_street2')) {
            this.props.dispatch(setCreateLocationStreet2(this.props.userLocation.address_street2));
        }
        if (guard(this.props.userLocation, '.address_city')) {
            this.props.dispatch(setCreateLocationCity(this.props.userLocation.address_city));
        }
        if (guard(this.props.userLocation, '.address_state')) {
            this.props.dispatch(setCreateLocationState(this.props.userLocation.address_state));
        }
        if (guard(this.props.userLocation, '.address_country')) {
            this.props.dispatch(setCreateLocationCountry(this.props.userLocation.address_country));
        }
        if (guard(this.props.userLocation, '.address_zipcode')) {
            this.props.dispatch(setCreateLocationPostal(this.props.userLocation.address_zipcode));
        }
        this.searchDropdownLocations(guard(this.props.userLocation, '.address_country'), guard(this.props.userLocation, '.address_state'));
    }

    /**
     * Check the `CountriesStates` list and compare against the `country` received to update the state of the application with the country
     * and after that update show the right list of state/provinces according to the country selected updating the state of the application with the state selected
     *
     * @param {string} country
     * @param {string} state
     */
    searchDropdownLocations = (country, state) => {
        for (const i in CountriesStates) {
            if (CountriesStates[i].country === country) {
                this.setState({ countrySelectedID: i });
                this.stateProvince(i);
                for (const j in CountriesStates[i].states) {
                    if (CountriesStates[i].states[j] === state || (CountriesStates[i].short && CountriesStates[i].short[j] === state)) {
                        this.setState({ stateSelectedID: j });
                        break;
                    }
                }
                break;
            }
        }
    }

    countriesDropdownList = () => {
        const countries = CountriesStates.map((location, index) => (
            <option key={index} value={index}>
                {location.country}
            </option>
        ));
        this.setState({ listOfCountries: countries });
    }

    stateProvince = selected => {
        if (selected > -1) {
            const states = CountriesStates[selected].states.map((location, index) => (
                <option key={index} value={index}>
                    {location}
                </option>
            ));
            this.setState({ listOfStates: states });
        }
    }

    changeState = selState => {
        if (selState > -1) {
            if (CountriesStates[this.state.countrySelectedID].short && CountriesStates[this.state.countrySelectedID].short[selState]) {
                this.props.dispatch(setCreateLocationState(CountriesStates[this.state.countrySelectedID].short[selState]));
            } else {
                this.props.dispatch(setCreateLocationState(CountriesStates[this.state.countrySelectedID].states[selState]));
            }
            this.setState({ stateSelectedID: selState });
        } else {
            this.props.dispatch(setCreateLocationState(false));
            this.setState({ stateSelectedID: -1 });
        }
    }

    changeCountry = selCountry => {
        if (selCountry > -1) {
            this.props.dispatch(setCreateLocationCountry(CountriesStates[selCountry].country));
            this.props.dispatch(setCreateLocationState(false));
            this.setState({ countrySelectedID: selCountry, stateSelectedID: -1, listOfStates: [] }, () => {
                this.stateProvince(selCountry);
            });
        } else {
            this.props.dispatch(setCreateLocationCountry(false));
            this.props.dispatch(setCreateLocationState(false));
            this.setState({ listOfStates: [], countrySelectedID: -1, stateSelectedID: -1 }, () => {
                this.stateProvince(selCountry);
            });
        }
    }

    render() {
        const addressForm = (
            <React.Fragment>
                <div className="LoginWrapper wrapper maxWidth">
                    <h1 className="section">
                        Please provide the Address of this Location
                    </h1>
                </div>
                <form onSubmit={this.verifyData} className="wideForm">
                    <Link
                        to="#"
                        className="wideButtons whiteBorder whiteOpaque fontWhite"
                        onClick={event => this.getLocationData(event)}
                    >
                        Use Current Location &gt;
                    </Link>
                    <div className="wideButtons transparent fontWhite">
                        Or write your address
                    </div>
                    <div className="innerLabelInput">
                        <span className="blocking-span">
                            <input
                                className="inputText"
                                name="street1"
                                type="text"
                                onChange={e => this.props.dispatch(setCreateLocationStreet1(e.target.value))}
                                required
                                value={guard(this.props.createLocation, '.address.address_street') || ''}
                            />
                            <span className="floating-label">Street Address 1</span>
                        </span>
                    </div>
                    <div className="innerLabelInput">
                        <span className="blocking-span">
                            <input
                                className="inputText"
                                name="street2"
                                type="text"
                                onChange={e => this.props.dispatch(setCreateLocationStreet2(e.target.value))}
                                value={guard(this.props.createLocation, '.address.address_street2') || ''}
                            />
                            <span className="floating-label">Street Address 2</span>
                        </span>
                    </div>
                    <div className="innerLabelInput">
                        <span className="blocking-span">
                            <input
                                className="inputText"
                                name="city"
                                type="text"
                                onChange={e => this.props.dispatch(setCreateLocationCity(e.target.value))}
                                required
                                value={guard(this.props.createLocation, '.address.address_city') || ''}
                            />
                            <span className="floating-label">City</span>
                        </span>
                    </div>
                    <div className="innerLabelInput">
                        <span className="h6 fontWhite">Country</span>
                        <select
                            required
                            value={this.state.countrySelectedID}
                            className="blankMultipleChoice fontWhite"
                            onChange={e => this.changeCountry(e.target.value)}
                        >
                            <option value={-1}>Select a Country</option>
                            {this.state.listOfCountries.length > 0 && this.state.listOfCountries}
                        </select>
                    </div>
                    {!!this.state.listOfStates && this.state.listOfStates.length > 0 && (
                        <div className="innerLabelInput">
                            <span className="h6 fontWhite">State/Province</span>
                            <select
                                required
                                value={this.state.stateSelectedID}
                                className="blankMultipleChoice fontWhite"
                                onChange={e => this.changeState(e.target.value)}
                            >
                                <option value={-1}>Select a State/Province</option>
                                {this.state.listOfStates}
                            </select>
                        </div>
                    )}
                    <div className="innerLabelInput">
                        <span className="blocking-span">
                            <input
                                className="inputText"
                                name="postal"
                                type="text"
                                onChange={e => this.props.dispatch(setCreateLocationPostal(e.target.value))}
                                value={guard(this.props.createLocation, '.address.address_zipcode') || ''}
                            />
                            <span className="floating-label">Postal Code</span>
                        </span>
                    </div>
                    <div className="spacerSmall" />
                    <input
                        type="submit"
                        value="Next &gt;"
                        to={'/location/add/details' + this.props.history.location.search}
                        className="rightRoundButton whiteBorder whiteOpaque fontWhite"
                    />
                </form>
            </React.Fragment>
        );

        return (
            <div className="mainAppContainer LoginScreens">
                <div className="bodyContent backgroundGreen">
                    <div className="three-column-layout">
                        <Columns
                            c1={(<React.Fragment />)}
                            c2={addressForm}
                            c3={(<React.Fragment />)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        createLocation: state.locations.createLocation,
        userLocation: state.users.user.user_location
    };
}

export default connect(mapStateToProps)(AddLocationAddress);
