import React from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';

import HelmetTags from '../../helmetTags';

export default class Privacy extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    render() {
        const pageTitle = 'Privacy Policy';
        const pageDescription = 'Pooch Privacy Policy';
        return (
            <React.Fragment>
                <HelmetTags title={pageTitle} description={pageDescription} />
                <div className="mainAppContainer LoginScreens">
                    <div className="LoginWrapper wrapper aboutus-sections">
                        <h1>Pooch Privacy Policy</h1>
                        <div className="privacy">
                            <h3>Privacy</h3>
                            <div className="content">
                                Pooch is dedicated to preserving your right to online privacy. If you have any questions about how we collect or use the data you share with us, please read below. You can contact us as though Contact Us link or email us at: <a href={`mailto:${process.env.REACT_APP_EMAIL_SUPPORT}`}>{process.env.REACT_APP_EMAIL_SUPPORT}</a>
                                <br />
                                <br />
                                This policy describes information that we process to support our Facebook log in integration.
                            </div>
                            <h3>WHAT THE PRIVACY POLICY IS USED FOR</h3>
                            <div className="content">
                                This privacy policy applies to personal data that we collect from you as a user of this app. The policy provides information on what personal data we collect, why we collect the data, how it is used and the lawful basis on which your personal data is processed and secured, and what your rights are under the applicable data protection and privacy laws, including the General Data Protection Regulation (“GDPR").
                            </div>
                            <h3>WHAT WE COLLECT</h3>
                            <div className="content">
                                Upon sign up with our app, we may collect non-personally identifiable information such as the pages you visit, accounts, the duration and frequency of your visit, the content that is created by you for your posts and creating events, application information provided by you for example metadata(primarily for SEO purposes).
                                If you subscribe to our newsletters we may collect personally identifiable information such as email address, name, and contact information. We do this only upon receiving your consent by clicking the submit button. You may turn this off at any given moment in the settings option of the app.
                            </div>
                            <h3>HOW WE COLLECT</h3>
                            <div className="content">
                                We use cookies, to collect and store non-personally identifiable information. You can delete this cookie at will in your browser's preferences, or set your browser to deny cookies in the settings section provided by the web browser of your choice.
                                <br />
                                <br />
                                We may also collect information from you as a standard part of connecting your computer to our server.
                            </div>
                            <h3>WHAT WE DO WITH YOUR SHARED DATA</h3>
                            <div className="content">
                                By subscribing our newsletter you may be contacted by us via email or phone. We use the third party vendors, including Google.
                                If we do disclose any information about the use of our site to third-parties who are not affiliates, we will provide only anonymized data. We will never sell, exchange, trade, nor disclose your personally identifiable information, unless compelled to by law.
                                <br />
                                <br />
                                We may use the shared data such as liking an image or group of images, page views, duration, chat content and chat history for tracking the performance of the different features provided by the Pooch app. In other words, we use the data provided for analyzing product performance, analytics, and the appeal of other business related services provided by the app.
                                <br />
                                <br />
                                All data is transferred, stored, and accessed securely.
                            </div>
                            <h3>ACCOUNT REMOVAL</h3>
                            <div className="content">
                                To delete your account please go to your profile page, then go to edit your profile and finally scroll down to the button "Remove" and follow the instructions provided after clicked on it.
                                <br />
                                <br />
                                Removal of your Pooch account will also remove any collected data from our system (see Accounts above for more on removal).
                            </div>
                            <h3>SECURITY</h3>
                            <div className="content">
                                You will be given the ability to create a unique username and password which becomes your log-in credential and allows you to access certain restricted parts of our app. You are responsible for any activity you post and thus comply that you must take every possible measure to keep those credentials secure and protected. You must not share or give away your password or log-in details to anyone else.
                                <br />
                                <br />
                                As a result, the transition of data over the internet or public communications networks can never be completely secure. We at Pooch are responsible in taking the appropriate technical and organizational security measures to protect our users personal data is submitted to us against unauthorized/unlawful access or loss, destruction or damage.
                                <br />
                                <br />
                                Unfortunately, due to the nature of web applications and online services, we cannot 100% guarantee the security of personal data that you provide to us online.
                            </div>
                            <h3>LINKS TO OTHER SITES</h3>
                            <div className="content">
                                This app contains links to other websites that promote other businesses within the app that Pooch has no control over. We are not responsible for the privacy policies of these sites and ask that you read their privacy policies that they provide thoroughly.
                                This Privacy Policy applies only to information collected by this app.
                            </div>
                            <h3>OPTING-OUT</h3>
                            <div className="content">
                                If you wish to, you can opt-out of:
                                <ul>
                                    <li>Google Analytics by visiting: <a href="http://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout</a></li>
                                    <li>Receiving emails: <a href="https://pooch.pet/settings">https://pooch.pet/settings</a></li>
                                    <li>Phone calls, and mail from us by emailing: <a href={`mailto:${process.env.REACT_APP_EMAIL_SUPPORT}`}>{process.env.REACT_APP_EMAIL_SUPPORT}</a></li>
                                </ul>
                            </div>
                            <h3>CHANGES TO OUR PRIVACY POLICY</h3>
                            <div className="content">
                                Pooch reserves the right to update this Privacy Policy. We will post amendments here prior to implementing any change.
                                For any further information, comments, questions or concerns, please contact us at <a href={`mailto:${process.env.REACT_APP_EMAIL_SUPPORT}`}>{process.env.REACT_APP_EMAIL_SUPPORT}</a>
                            </div>
                        </div>
                    </div>
                    <div className="LoginWrapper wrapper">
                        <Link to="#" onClick={() => this.props.history.goBack()} className="wideButtons whiteBorder transparent fontWhite">
                            Go Back
                        </Link>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
