import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { logout } from '../../redux/actions/users';

export class Modal extends React.Component {
    onClose = e => {
        if (this.props.onClose) this.props.onClose(e);
    }

    completeLogout = () => {
        this.props.dispatch(logout());
    }

    render() {
        if (!this.props.show) return null;
        const userId = this.props.userData.user_id;
        let userLink;
        if (this.props.userData && this.props.userData.parententity && this.props.userData.parententity.entity_poochid) {
            userLink = '/' + this.props.userData.parententity.entity_poochid;
        } else {
            userLink = '/user/profile/' + this.props.userData.user_id;
        }

        return (
            <div>
                <div className="modalBackdrop" onClick={e => this.onClose(e)} />
                <div className="modalMenu" id="modal">
                    <div className="menuTop">
                        <div className="userMenuImage">
                            <div className="round-avatar header-menu">
                                <Link to={userLink}>
                                    {this.props.userData && (!this.props.userData.images || this.props.userData.images.length === 0) && (
                                        <i className="icn icon-user" onClick={this.showModalMobile} />
                                    )}
                                    {this.props.userData?.images?.[0] && (
                                        <img src={this.props.userData.images[0].image_smallurl} alt="User Profile" onClick={this.showModalMobile} />
                                    )}
                                </Link>
                            </div>
                            <p className="user-name">{this.props.userData.user_firstname}</p>
                        </div>
                        <div className="closeModal">
                            <button type="button" onClick={e => this.onClose(e)}>
                                <i className="icn icon-cross fontRed" />
                            </button>
                        </div>
                    </div>
                    <div className="menuList">
                        <ul className="list">
                            <li>
                                <Link to={'/user/profile/' + userId + '/edit'} className="fontRed">Edit Profile</Link>
                            </li>
                            <li>
                                <Link to="/user/dogs" className="fontRed">My Dogs</Link>
                            </li>
                            <li>
                                <Link to="/chat" className="fontRed">Chat</Link>
                            </li>
                            <li>
                                <Link to="/notifications" className="fontRed">Notifications</Link>
                            </li>
                            <li>
                                <Link to="/friends" className="fontRed">Friends</Link>
                            </li>
                            <li>
                                <Link to="/favorites" className="fontRed">Favorites</Link>
                            </li>
                            <li>
                                <Link to="/location/add" className="fontRed"> Add a Place/Service</Link>
                            </li>
                            <li>
                                <a href={process.env.REACT_APP_SHOP_LINK} target="_blank" rel="noopener noreferrer" className="fontRed">
                                    Shop
                                </a>
                            </li>
                            <li>
                                <Link to="/missingdogs" className="fontRed"> Missing Dogs</Link>
                            </li>
                            <li>
                                <Link to="/reportmissing" className="fontRed"> Report a Lost Dog</Link>
                            </li>
                            {(this.props.userData?.user_role === 'admin' || this.props.userData?.adminlocations?.length > 0 || this.props.userData?.user_role === 'superadmin') && (
                                <li>
                                    <Link to="/managelocation" className="fontRed"> Manage Locations</Link>
                                </li>
                            )}
                            {this.props.userData?.user_role === 'superadmin' && (
                                <React.Fragment>
                                    <li>
                                        <Link to="/manageusers" className="fontRed">Manage Users</Link>
                                    </li>
                                    <li>
                                        <Link to="/manageadministrators" className="fontRed">Manage Administrators</Link>
                                    </li>
                                    <li>
                                        <Link to="/managestats" className="fontRed">Stats</Link>
                                    </li>
                                    <li>
                                        <Link to="/managecontactus" className="fontRed">Manage Contact Forms</Link>
                                    </li>
                                </React.Fragment>
                            )}
                            {!!this.props.credentials?.user_id && (
                                <React.Fragment>
                                    <li>
                                        <Link to="/settings" className="fontRed">Settings</Link>
                                    </li>
                                    <li className="signOutDesktop" onClick={e => this.onClose(e)}>
                                        <Link to="/" onClick={this.completeLogout} className="fontRed">Sign Out</Link>
                                    </li>
                                </React.Fragment>
                            )}
                            <li className="signOutMobile"><Link to="/" onClick={this.completeLogout} className="fontRed">Sign Out</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(Modal);
