import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import sb from '../../helpers/SendBird';

export default class MissingDogInformation extends React.Component {
    chatWithUser = (e, users, lostDog) => {
        e.preventDefault();
        const userIds = [];
        let roomName = this.props.userData.user_firstname;
        for (const i in users) {
            userIds.push(users[i].user_id);
            roomName += ', ' + users[i].user_firstname;
        }
        if (lostDog) {
            roomName = 'Dog Missing: ' + this.props.dogProfile.name;
        }
        const that = this;
        sb.connect(
            this.props.credentials.user_id,
            this.props.credentials.token,
            () => {
                sb.GroupChannel.createChannelWithUserIds(userIds, true, roomName, null, null, (createdChannel, error) => {
                    if (error) {
                        toast.error('Sorry, there was an issue creating this chat, please try again.');
                        return;
                    }
                    that.props.history.push('/chat/channel/' + createdChannel.url);
                });
            }
        );
    }

    render() {
        const dog_is_lost = !!this.props.dogProfile && !!this.props.dogProfile.dogaddress && !this.props.dogProfile.dogaddress.dogaddress_found;

        const lastSeen = [];
        let missingData = '';
        if (dog_is_lost) {
            missingData = this.props.dogProfile.dogaddress;
        }

        return (
            <React.Fragment>
                {dog_is_lost && (
                    <div className="boxContainer">
                        <div className="boxTitle row">
                            <div className="title fontRed">MISSING INFORMATION</div>
                        </div>
                        <div className="dogDescription">
                            <span className="missingDogInfo">
                                {!this.props.isMyDog && (
                                    <Link to="#" onClick={e => this.chatWithUser(e, this.props.dogProfile.users, true)} className="wideButtons redBG fontWhite">Contact Owner!</Link>
                                )}
                                {missingData.dogaddress_reward && (
                                    <div className="dogAddress">
                                        <strong className="fontRed">Reward: </strong> ${missingData.dogaddress_reward}
                                    </div>
                                )}
                                {missingData.dogaddress_datetime && (
                                    <div className="missingDog">
                                        <strong className="fontRed">Date Lost: </strong> {moment(missingData.dogaddress_datetime).format('MMMM Do YYYY, h:mm a')}
                                    </div>
                                )}
                                {missingData.address && (
                                    <div className="dogLastSeen">
                                        <strong className="fontRed">Last Seen: </strong>
                                        {!!missingData.address && !!missingData.address.address_street && lastSeen.push(missingData.address.address_street) && ''}
                                        {!!missingData.address && !!missingData.address.address_street2 && lastSeen.push(missingData.address.address_street2) && ''}
                                        {!!missingData.address && !!missingData.address.address_city && lastSeen.push(missingData.address.address_city) && ''}
                                        {!!missingData.address && !!missingData.address.address_state && lastSeen.push(missingData.address.address_state) && ''}
                                        {!!missingData.address && !!missingData.address.address_country && lastSeen.push(missingData.address.address_country) && ''}
                                        {!!missingData.address && !!missingData.address.address_zipcode && lastSeen.push(missingData.address.address_zipcode) && ''}
                                        {lastSeen.join(' ')}
                                        {lastSeen.splice(0, lastSeen.length) && ''}
                                    </div>
                                )}
                                {missingData.dogaddress_contactinfo && (!!missingData.dogaddress_contactinfo.contact_by_phone || !!missingData.dogaddress_contactinfo.contact_by_text) && (
                                    <div className="">
                                        <strong className="fontRed">Call or Text: </strong> <a className="fontGreen" href={'tel:' + missingData.dogaddress_contactinfo.phone}>{missingData.dogaddress_contactinfo.phone}</a>
                                    </div>
                                )}
                                {missingData.dogaddress_contactinfo && !!missingData.dogaddress_contactinfo.contact_by_email && (
                                    <div>
                                        <strong className="fontRed">Email: </strong> <a className="fontGreen" href={'mailto:' + missingData.dogaddress_contactinfo.email}>{missingData.dogaddress_contactinfo.email}</a>
                                    </div>
                                )}
                                {missingData.dogaddress_description && (
                                    <div>
                                        <strong className="fontRed">Additional Info: </strong>
                                        <br />{missingData.dogaddress_description}
                                    </div>
                                )}
                            </span>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

MissingDogInformation.propTypes = {
    dogProfile: PropTypes.instanceOf(Object),
    userData: PropTypes.instanceOf(Object),
    credentials: PropTypes.instanceOf(Object).isRequired
};
