import React from 'react';
import { connect } from 'react-redux';

import { logout } from '../../redux/actions/users';

export class Logout extends React.Component {
    completeLogout = () => {
        this.props.dispatch(logout());
    }

    render() {
        this.completeLogout();
        return window.location.replace('/');
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(Logout);
