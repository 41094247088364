import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { InviteFriends } from '../../layout/InviteFriends';
import FriendsNavigation from '../../layout/navigation/FriendsNavigation';
import Columns from '../../hoc/Columns';
import FindFriendsComponent from './FindFriendsComponent';
import FriendRequestsComponent from './FriendRequestsComponent';

let delayTimer;

export class Friends extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            searchFor: ''
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    searchNow = () => {
        this.setState(prevState => ({
            searchFor: prevState.searchText
        }));
    }

    handleSearchInput = e => {
        clearTimeout(delayTimer);
        const that = this;
        this.setState({ searchText: e.target.value }, () => {
            delayTimer = setTimeout(() => {
                that.searchNow();
                // Do the ajax stuff
            }, 1500);
        });
    }

    onClear = () => {
        clearTimeout(delayTimer);
        const that = this;
        this.setState({ searchText: '' }, () => {
            delayTimer = setTimeout(() => {
                that.searchNow();
                // Do the ajax stuff
            }, 1500);
        });
    }

    render() {
        const c1 = (
            <div className="simple-navigation">
                <FriendsNavigation
                    showFindFriends
                    selected="findfriends"
                />
            </div>
        );

        const c2 = (
            <FindFriendsComponent
                searchFor={this.state.searchFor}
                perPage={30}
                infiniteScroll={true}
                reusedata={true}
            />
        );

        const c3 = (
            <React.Fragment>
                <InviteFriends wrapperClass="noWrapper" />
                <FriendRequestsComponent limit={3} infiniteScroll={false} viewMoreButton={true} />
            </React.Fragment>
        );

        return (
            <div className="mainAppContainer">
                <div className="bodyContent find-friends">
                    <div className="simpleSearchBar row">
                        <i className="icn icon-search fontGreen" />
                        <input onChange={e => this.handleSearchInput(e)} value={this.state.searchText} placeholder="SEARCH Friends" />
                        {!!this.state.searchText && (
                            <i onClick={this.onClear} className="icn icon-sign-out fontDarkGray" />
                        )}
                    </div>
                    <div className="three-column-layout">
                        <Columns
                            c1={c1}
                            c2={c2}
                            c3={c3}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(Friends);
