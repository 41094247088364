import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import {
    getLocations,
    setSearchLocation,
    setSearchedLocationsList,
    setSearchedLocationsPosition,
    setMapLastLocationPosition
} from '../../redux/actions/locations';
import { MainSearchNavigation } from '../../layout/navigation/MainSearchNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import { LocationListItem } from '../../layout/locations/LocationListItem';
import GoogleMap from '../../layout/locations/GoogleMap';
import AdBanner from '../../layout/locations/AdBanner';
import Config from '../../helpers/Config';
import { orderLocationsByDistance } from '../../helpers/Functions';
import { getGeoLocation } from '../../helpers/GeoLocation';

let delayTimer;

export class MainSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // locations: [],
            templocations: [],
            locationBanners: [],
            hasNewCenter: false,
            recenter: false,
            loading: true,
            newCenter: null,
            loadingText: 'Loading...'
        };
    }

    getGeoLocation = getGeoLocation.bind(this)

    componentWillMount() {
        this.start();
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    start = (getFreshLocation = 120000) => {
        getGeoLocation(this.props, this.getNearByLocations, this.props.credentials.token, true, 6000, getFreshLocation);
    }

    recenter = () => {
        const that = this;
        this.props.dispatch(setMapLastLocationPosition(null));
        this.setState({ loading: true, loadingText: 'Loading...' }, () => {
            that.start(0);
        });
    }

    getNearByLocations = geopoint => {
        let geoLocation = null;
        if (geopoint) {
            geoLocation = this.props.mapLastLocation || geopoint;
            this.props.dispatch(setSearchedLocationsPosition(geopoint));
        }
        if (geoLocation === null) {
            geoLocation = this.props.searchedLocationsPosition || this.props.userData.user_location || this.props.userData.address || null;
        }
        this.setState({ loading: true, loadingText: 'Loading...', recenter: true }, () => {
            this.setState({ recenter: false });
        });
        this.props.dispatch(getLocations(this.props.credentials.token, geoLocation, this.props.selectedSearchFilter, this.props.searchLocation, 1, 100))
            .then(response => {
                this.setState(prevState => ({
                    hasNewCenter: false,
                    templocations: [...prevState.templocations, ...response.value.data]
                }), () => this.removeDuplicatedLocations(geoLocation));
            })
            .catch(() => this.setState({
                loading: false,
                loadingText: 'There was an Error...'
            }));
    }

    centerMoved = geopoint => {
        this.props.dispatch(setMapLastLocationPosition(geopoint));
        this.setState({ hasNewCenter: true, newCenter: geopoint });
    }

    searchNearNewCenter = () => {
        this.setState({ hasNewCenter: false });
        this.getNearByLocations(this.state.newCenter);
    }

    /**
     * Provide a list for render in Map
     * remove Duplicate ID from list
     * and sum float number to longitud when map find location with the same address
     *
     * doesn't return anything, only modified the list received
     */
    removeDuplicatedLocations = center => {
        const filtered = this.state.templocations.filter((loc, index, self) => index === self.findIndex(l => (
            l.location_id === loc.location_id
        )));

        const orderedByDistance = orderLocationsByDistance(filtered, center);
        const limited = orderedByDistance.slice(0, 100);

        const normalLocations = limited.filter(location => location.location_featured === 0);
        const featuredLocations = limited.filter(location => location.location_featured === 1);

        const list = [...featuredLocations, ...normalLocations];
        this.props.dispatch(setSearchedLocationsList(list));
        const delta = 0.00015;
        const indexedAddresses = {};
        list.map(address => {
            const latitude = parseFloat(address.address.address_latitude);
            let longitude = parseFloat(address.address.address_longitude);
            // Increment the longitude slightly if the pair has been
            // encountered before.
            while (indexedAddresses[longitude + ':' + latitude] !== undefined) {
                longitude += delta;
            }
            indexedAddresses[longitude + ':' + latitude] = true;
            // Add to map.
            address.address = {
                address_latitude: latitude,
                address_longitude: longitude
            };
            return null;
        });
        this.setState({ templocations: list }, this.mapLocationBanners);
    }

    mapLocationBanners = () => {
        this.setState({ loading: false, loadingText: false });
        if (!!this.props.searchedLocationsList && this.props.searchedLocationsList.length > 0) {
            const locationBanners = this.props.searchedLocationsList.map((location, index) => (<LocationListItem key={index} {...this.props} location={location} url="location/profile" banner />));
            if (Config.isMobileApp === false) {
                locationBanners.splice(5, 0, (<AdBanner key="adBanner" />));
            }
            this.setState({ locationBanners }, () => {
                if (!Config.isMobileDevice) window.dragscroll.reset();
            });
        } else {
            this.setState({ locationBanners: [], loadingText: 'Nothing Found' });
        }
    }

    handleSearchInput = e => {
        clearTimeout(delayTimer);
        const value = e.target.value;
        this.props.dispatch(setSearchLocation(value));
        const that = this;
        delayTimer = setTimeout(() => {
            that.props.dispatch(setSearchedLocationsList([]));
            that.setState({ templocations: [] });
            that.getNearByLocations();
            // Do the ajax stuff
        }, 1500);
    }

    onClear = () => {
        clearTimeout(delayTimer);
        const value = '';
        this.props.dispatch(setSearchLocation(value));
        const that = this;
        this.setState({ loadingText: 'Loading...' });
        delayTimer = setTimeout(() => {
            that.props.dispatch(setSearchedLocationsList([]));
            that.setState({ templocations: [] });
            that.getNearByLocations();
            // Do the ajax stuff
        }, 1500);
    }

    render() {
        return (
            <div className="mainAppContainer main-map-search">
                <MainSearchNavigation searchValue={this.props.searchLocation} onChange={this.handleSearchInput} onClear={this.onClear} title="Search" />
                <div className="bigMapHolder">
                    <div className="gps-locate" onClick={this.recenter}>
                        <i className="icn icon-report-lost-dog" />
                    </div>
                    {this.state.loadingText && (
                        <div className="searchNearHere">
                            <div className="searchAreaButton h6">{this.state.loadingText}</div>
                        </div>
                    )}
                    {!this.state.loadingText && this.props.selectedSearchFilter !== 'all' && this.props.selectedSearchFilter !== '' && this.state.hasNewCenter === false && (
                        <div className="searchNearHere">
                            <Link to="/categories" className="searchAreaButton h6">Category: {this.props.selectedSearchFilter}</Link>
                        </div>
                    )}
                    {this.state.hasNewCenter === true && (
                        <div className="searchNearHere">
                            <div onClick={this.searchNearNewCenter} className="searchAreaButton h6">Search this area</div>
                        </div>
                    )}
                    <div className="locationBannerHolder">
                        <div className={'locationBanner dragscroll locationListSearch ' + (Config.isMobileDevice ? 'mobile' : '')}>
                            {this.state.locationBanners}
                        </div>
                    </div>
                    <GoogleMap
                        locations={this.props.searchedLocationsList}
                        userData={this.props.userData}
                        centerMoved={this.centerMoved}
                        recenter={this.state.recenter}
                        center={this.props.searchedLocationsPosition}
                        lastPosition={this.props.mapLastLocation}
                        history={this.props.history}
                    />
                    {this.props.searchedLocationsList.length === 0 && !this.state.loading && (
                        <div className="add-location-banner row">
                            <div className="location-banner-caption">
                                <i className="icn icon-location-pin-white" />
                                <Link to="/location/add">Missing location?<br />Click to add Location</Link>
                            </div>
                        </div>
                    )}
                </div>
                <SecondaryNavigation selected="search" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        searchLocation: state.locations.searchLocation,
        searchedLocationsList: state.locations.searchedLocationsList,
        searchedLocationsPosition: state.locations.searchedLocationsPosition,
        selectedSearchFilter: state.searchFilters.selectedFilter,
        mapLastLocation: state.locations.mapLastLocation
    };
}

export default connect(mapStateToProps)(MainSearch);
