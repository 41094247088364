import React from 'react';
import PropTypes from 'prop-types';

const SearchBar = ({ handleSearchInput, searchValue, placeholder, onClear }) => (
    <div className="simpleSearchBar row">
        <i className="icn icon-search fontGreen" />
        <input
            onChange={e => handleSearchInput(e)}
            value={searchValue}
            placeholder={placeholder}
        />
        {!!searchValue && !!onClear && (
            <i onClick={onClear} className="icn icon-sign-out fontDarkGray" />
        )}
    </div>
);

SearchBar.propTypes = {
    handleSearchInput: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    searchValue: PropTypes.string,
    placeholder: PropTypes.string
};

export default SearchBar;
