import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import {
    getLocation,
    setReviewPicture,
    clearReview,
    setReviewStars,
    setReviewDescription,
    saveReview
} from '../../redux/actions/locations';
import { uploadPicture } from '../../redux/actions/all';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import GalleryLightbox from '../../layout/gallery/GalleryLightbox';
import { Stars } from '../../layout/UI/Stars';
import Spinner from '../../layout/UI/Spinner';
import Avatar from '../../layout/UI/Avatar';
import CameraModal from '../../layout/CameraModal';
import NotFound from '../../layout/NotFound';
import ApiError from '../../helpers/ApiError';
import Config from '../../helpers/Config';
import { guard, urlFriendly } from '../../helpers/Functions';
import { camera, cameraMultiRoll, cameraBlob } from '../../helpers/Camera';

class LocationReview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [],
            pictureModal: false,
            loading: true,
            location: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(clearReview());
        this.props.dispatch(getLocation(this.props.credentials.token, this.props.match.params.id))
            .then(response => this.setState({ loading: false, location: response.value.data }))
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loading: false, location: false });
            });
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    filesChanged = files => {
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            const file = files[i];
            reader.onloadend = () => {
                const newimage = {
                    image_smallurl: reader.result,
                    image_largeurl: reader.result
                };
                this.setState({ images: [newimage, ...this.state.images] });
                this.props.dispatch(setReviewPicture(file));
            };
            reader.readAsDataURL(file);
        }
    }

    changeStars = stars => {
        this.props.dispatch(setReviewStars(stars));
    }

    verifyData = e => {
        if (!this.props.review.stars) {
            e.preventDefault();
            ApiError('Please Select your Rating using the Stars');
        } else {
            const data = { review_rating: this.props.review.stars, review_comment: this.props.review.description };
            this.props.dispatch(saveReview(this.props.credentials.token, this.state.location.location_id, data))
                .then(response => {
                    for (const i in this.props.review.images) {
                        this.props.dispatch(uploadPicture(this.props.credentials.token, response.value.data.review_id, this.props.review.images[i], false));
                    }
                    // this.setState({ shareModal: false });
                    this.props.dispatch(clearReview());
                    this.props.history.push('/location/profile/' + this.state.location.location_id + '/' + urlFriendly(this.state.location.location_name));
                });
        }
    }

    toggleModal = () => {
        this.setState({ pictureModal: !this.state.pictureModal });
    }

    changeImage = () => {
        if (Config.isMobileApp) {
            this.toggleModal();
        } else {
            const selectfile = document.getElementById('file');
            selectfile.click();
        }
    }

    phoneCamera = () => {
        camera(this.phoneSuccess);
    }

    phoneUpload = () => {
        cameraMultiRoll(this.phoneSuccess);
    }

    phoneSuccess = file => {
        const newimage = {
            image_smallurl: file,
            image_largeurl: file
        };
        this.setState({ images: [newimage, ...this.state.images] });
        cameraBlob(file, this.uploadToProfile);
    }

    uploadToProfile = imgBlob => {
        this.props.dispatch(setReviewPicture(imgBlob));
    }

    render() {
        return (
            <div className="mainAppContainer">
                <MainNavigation {...this.props} title="Review" leftButton="back" />
                <div className="bodyContent bgColorRed fontWhite">
                    {this.state.pictureModal && (
                        <CameraModal toggleModal={this.toggleModal} callbackCamera={this.phoneCamera} callbackUpload={this.phoneUpload} />
                    )}
                    {this.state.loading && (
                        <Spinner />
                    )}
                    {!this.state.location && !this.state.loading && (
                        <NotFound type="locations" class="backgroundRed" />
                    )}
                    {!this.state.loading && this.state.location && (
                        <div className="locationProfile maxWidth">
                            <div className="row checkInTitle">
                                <Link to={'/location/profile/' + this.state.location.location_id + '/' + urlFriendly(this.state.location.location_name)} className="round-avatar location">
                                    <Avatar
                                        src={guard(this.state.location, '.images.0.image_smallurl')}
                                        alt={this.state.location.location_name}
                                        type="location"
                                    />
                                </Link>
                                <Link to={'/location/profile/' + this.state.location.location_id + '/' + urlFriendly(this.state.location.location_name)}><span className="fontWhite">Review {this.state.location.location_name}</span></Link>
                            </div>
                            <div className="addRating">
                                Rating
                                <Stars stars={this.props.review.stars || 0} big={true} onClick={this.changeStars} />
                            </div>
                            <textarea value={this.props.review.description || ''} onChange={e => this.props.dispatch(setReviewDescription(e.target.value))} className="niceTextarea" placeholder="Add a review (e.g. Hanging out with friends)" />
                            <div className="upload whiteOpaque">
                                <input className="hidden" id="file" type="file" onChange={e => this.filesChanged(e.target.files)} accept="image/*" required multiple />
                                <div onClick={() => { this.changeImage(); }} className="fontWhite">
                                    <i className="icn icon-upload-photo" /> Add Photos
                                </div>
                                <div className="gallery whiteOpaque">
                                    <GalleryLightbox images={this.state.images} count={4} size="normal" fillers={true} />
                                </div>
                            </div>
                            <div className="addRating">
                                <Link onClick={e => this.verifyData(e)} to={'/location/profile/' + this.state.location.location_id + '/' + urlFriendly(this.state.location.location_name)} className="wideButtons whiteBorder whiteOpaque fontWhite">Add New Review</Link>
                            </div>
                        </div>
                    )}
                </div>
                <SecondaryNavigation selected="search" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        review: state.locations.locationReview
    };
}

export default connect(mapStateToProps)(LocationReview);
