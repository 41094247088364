import React from 'react';
import PropTypes from 'prop-types';

let delayTimer;

class NavigationSearchBar extends React.Component {
    handleSearchInput = e => {
        clearTimeout(delayTimer);
        const value = e.target.value;
        this.props.disp(this.props.searchInputHandler(value));
        const that = this;
        delayTimer = setTimeout(() => {
            that.props.callback();
        }, 1500);
    }

    onClear = () => {
        clearTimeout(delayTimer);
        this.props.disp(this.props.searchInputHandler(''));
        this.props.callback();
    }

    render() {
        return (
            <div className="simpleSearchBar row">
                <i className="icn icon-search fontDarkGray" />
                <input
                    className="search-input"
                    onChange={this.handleSearchInput}
                    value={this.props.searchValue}
                    placeholder={this.props.placeHolder}
                />
                {!!this.props.searchValue && (
                    <i onClick={this.onClear} className="icn icon-sign-out fontDarkGray" />
                )}
            </div>
        );
    }
}

NavigationSearchBar.propTypes = {
    callback: PropTypes.func.isRequired,
    disp: PropTypes.func.isRequired, // this is the dipatch function
    searchValue: PropTypes.string,
    searchInputHandler: PropTypes.func.isRequired
};

export default NavigationSearchBar;
