import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import {
    FacebookShareButton,
    TwitterShareButton,
    RedditShareButton,
    EmailShareButton
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import LikeButton from './LikeButton';
import LeaveComment from '../../Comments/LeaveComment';
import CommentList from '../../Comments/CommentList';
import Config from '../../../helpers/Config';
import ApiError from '../../../helpers/ApiError';

class ActionButtons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            likes: (this.props.EntityActionCounters && this.props.EntityActionCounters.like && parseInt(this.props.EntityActionCounters.like, 10)) || 0,
            showComments: false,
            alreadyLiked: !!this.props.actionBySelf,
            shareModal: false,
            commentCounter: this.props.commentCounter,
            // eslint-disable-next-line react/no-unused-state
            replies: [], // this one is being used on child component <CommentList />
            // eslint-disable-next-line react/no-unused-state
            comments: [] // this one is being used on child component <CommentList />
        };
    }

    toggleModal = () => {
        if (this.props.credentials.token) {
            this.setState(prevState => ({ shareModal: !prevState.shareModal }));
        } else {
            ApiError('You need to log in or sign up.');
        }
    }

    copiedUrl = () => {
        this.toggleModal();
        toast.success('Saved to Clipboard');
    }

    updateLikes = likes => {
        this.setState(prevState => ({
            alreadyLiked: !prevState.alreadyLiked,
            likes
        }));
    }

    toggleComments = () => {
        this.setState(prevState => ({ showComments: !prevState.showComments }));
    }

    stateSetter = newState => {
        this.setState(newState);
    }

    render() {
        const url = Config.webUrl + '/activity/' + (this.props.entityId);
        const shareTitle = 'Check this out: ';
        let whoLikedIt = false;
        let commentCounter = 'Be the first to comment';
        if (this.state.alreadyLiked && this.state.likes === 1) {
            whoLikedIt = 'You liked this';
        } else if (this.state.alreadyLiked && this.state.likes === 2) {
            whoLikedIt = 'You and ' + (this.state.likes - 1) + ' person liked this';
        } else if (this.state.alreadyLiked && this.state.likes >= 3) {
            whoLikedIt = 'You and ' + (this.state.likes - 1) + ' people liked this';
        } else if (this.state.likes === 1) {
            whoLikedIt = this.state.likes + ' person liked this';
        } else if (this.state.likes >= 2) {
            whoLikedIt = this.state.likes + ' people liked this';
        } else {
            whoLikedIt = 'Be the first to like';
        }
        if (this.state.commentCounter > 0) {
            commentCounter = this.state.commentCounter + ' comments';
        }

        return (
            <div className="actionButtonsHolder">
                <div className="row post-summary space-between">
                    <div className={'h7 ' + (this.state.alreadyLiked ? 'fontGreen' : 'fontRed')}>
                        <i className="icn icon-like " /> {whoLikedIt}
                    </div>
                    <div className={'h7 ' + (this.state.commentCounter ? 'fontGreen' : 'fontRed')}>
                        <i className="icn icon-no-messages " /> {commentCounter}
                    </div>
                </div>
                <div className="row alignItems actionButtons">
                    <LikeButton
                        dispatch={this.props.dispatch}
                        credentials={this.props.credentials}
                        entityId={this.props.entityId}
                        actionBySelf={this.props.actionBySelf}
                        EntityActionCounters={this.props.EntityActionCounters}
                        updateLikes={this.updateLikes}
                    />
                    <div onClick={this.toggleComments} className="flexButtons fontRed"><i className="icn icon-no-messages " /> Comments</div>
                    <div onClick={this.toggleModal} className="flexButtons fontRed"><i className="icn icon-share" /> Share</div>
                </div>
                {this.state.showComments && (
                    <React.Fragment>
                        <LeaveComment
                            dispatch={this.props.dispatch}
                            stateSetter={this.stateSetter}
                            entityId={this.props.entityId}
                            credentials={this.props.credentials}
                            commentAs={this.props.userData}
                        />
                        <CommentList
                            commentAs={this.props.userData}
                            stateSetter={this.stateSetter}
                            state={this.state}
                            dispatch={this.props.dispatch}
                            credentials={this.props.credentials}
                            entityId={this.props.entityId}
                            mode="post"
                            infiniteScroll={false}
                            acceptReplies={true}
                        />
                    </React.Fragment>
                )}
                {this.state.shareModal && (
                    <ReactModal
                        isOpen={true}
                        contentLabel="onRequestClose Example"
                        onRequestClose={this.toggleModal}
                        className="Modal"
                        overlayClassName="Overlay"
                        ariaHideApp={false}
                    >
                        <h2>Share to:</h2>
                        <CopyToClipboard text={url} onCopy={this.copiedUrl}>
                            <div className="SocialMediaShareButton SocialMediaShareButton--copy">
                                Copy Link
                            </div>
                        </CopyToClipboard>
                        <FacebookShareButton
                            url={url}
                            quote={shareTitle}
                        >
                            Facebook
                        </FacebookShareButton>
                        <TwitterShareButton
                            url={url}
                            title={shareTitle}
                        >
                            Twitter
                        </TwitterShareButton>
                        <RedditShareButton
                            url={url}
                            title={shareTitle}
                            windowWidth={660}
                            windowHeight={460}
                        >
                            Reddit
                        </RedditShareButton>
                        <EmailShareButton
                            url={url}
                            subject={shareTitle}
                            body={shareTitle + ' ' + url}
                        >
                            Email
                        </EmailShareButton>
                        <Link to="#" onClick={this.toggleModal}>
                            Cancel
                        </Link>
                    </ReactModal>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user
    };
}

ActionButtons.propTypes = {
    dispatch: PropTypes.func.isRequired,
    credentials: PropTypes.instanceOf(Object).isRequired,
    entityId: PropTypes.number,
    actionBySelf: PropTypes.instanceOf(Object),
    EntityActionCounters: PropTypes.instanceOf(Object),
    commentCounter: PropTypes.number
};

export default connect(mapStateToProps)(ActionButtons);
