import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import moment from 'moment';

import {
    pe_comment,
    updatePlaydate,
    pe_editing,
    pe_toggleInvitation,
    pe_changeDate,
    pe_changeTime,
    pe_togglePark,
    getPlaydate,
    pe_type,
    pe_title,
    pe_additionalinfo,
    pe_images_files,
    pe_images
} from '../../redux/actions/playdates';
import { sendNotification, uploadPicture } from '../../redux/actions/all';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import Avatar from '../../layout/UI/Avatar';
import CameraModal from '../../layout/CameraModal';
import Spinner from '../../layout/UI/Spinner';
import { camera, cameraBlob, cameraRoll } from '../../helpers/Camera';
import { guard } from '../../helpers/Functions';
import Config from '../../helpers/Config';

class PlaydateEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            invitedUsers: [],
            pictureModal: false,
            image: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(getPlaydate(this.props.credentials.token, this.props.match.params.id))
            .then(response => {
                const pd = response.value.data;
                if (String(this.props.pe.editing) !== String(this.props.match.params.id)) {
                    this.props.dispatch(pe_editing(this.props.match.params.id));
                    this.props.dispatch(pe_changeDate(pd.playdate_datetime));
                    this.props.dispatch(pe_changeTime(pd.playdate_datetime));
                    this.props.dispatch(pe_togglePark({ id: pd.location.location_id, name: pd.location.location_name }));
                    this.props.dispatch(pe_comment(pd.playdate_comment));
                    this.props.dispatch(pe_toggleInvitation(pd.playdate_isactive));
                    this.props.dispatch(pe_type(pd.playdate_type));
                    this.props.dispatch(pe_title(pd.playdate_title));
                    this.props.dispatch(pe_additionalinfo(pd.playdate_additionalinfo));
                }
                if (pd.images && pd.images[0] && pd.images[0].image_smallurl) {
                    this.setState({ image: pd.images[0].image_smallurl });
                }
                this.setState({ loading: false, invitedUsers: pd.users });
            });
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    filesChanged = files => {
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            const file = files[i];
            reader.onloadend = () => {
                this.props.dispatch(pe_images_files(file));
                this.props.dispatch(pe_images(reader.result));
            };
            reader.readAsDataURL(file);
        }
    }

    toggleModal = () => {
        this.setState({ pictureModal: !this.state.pictureModal });
    }

    changeImage = () => {
        if (Config.isMobileApp) {
            this.toggleModal();
        } else {
            const selectfile = document.getElementById('file');
            selectfile.click();
        }
    }

    phoneCamera = () => {
        camera(this.phoneSuccess);
    }

    phoneUpload = () => {
        cameraRoll(this.phoneSuccess);
    }

    phoneSuccess = file => {
        this.props.dispatch(pe_images(file));
        cameraBlob(file, this.uploadToProfile);
    }

    uploadToProfile = imgBlob => {
        this.props.dispatch(pe_images_files(imgBlob));
    }

    clear = () => {
        document.getElementById('file').value = '';
        this.props.dispatch(pe_images());
        this.props.dispatch(pe_images_files());
    }

    saveChanges(e) {
        e.preventDefault();

        let date; let
            time;
        if (!this.props.pe.selectedDate) {
            date = moment().format('YYYY-MM-DD');
        } else {
            date = moment(this.props.pe.selectedDate).format('YYYY-MM-DD');
        }

        if (!this.props.pe.selectedTime) {
            time = moment().format('H:mm:ss');
        } else {
            time = moment(this.props.pe.selectedTime).format('H:mm:ss');
        }

        const data = {
            location_id: this.props.pe.selectedPark.id,
            playdate_datetime: date + ' ' + time,
            playdate_comment: this.props.pe.comment,
            playdate_isprivate: this.props.pe.invitationOnly ? 1 : 0,
            playdate_type: this.props.pe.type,
            playdate_title: this.props.pe.title,
            playdate_additionalinfo: this.props.pe.additionalinfo
        };

        let receivers = this.state.invitedUsers.filter(user => !(user.user_id === String(this.props.credentials.user_id)));
        receivers = this.state.invitedUsers.map(user => ({ user_id: user.user_id }));

        const notificationData = {
            notification_title: 'Event Update',
            notification_body: this.props.userData.user_firstname + ' updated the Event',
            playdate_id: this.props.pe.editing,
            sender_id: this.props.credentials.user_id,
            receivers
        };
        if (this.props.pe && this.props.pe.images_files && this.props.pe.images_files.size) {
            this.props.dispatch(uploadPicture(this.props.credentials.token, this.props.match.params.id, this.props.pe.images_files, true));
        }
        this.props.dispatch(updatePlaydate(this.props.credentials.token, this.props.match.params.id, data))
            .then(response => {
                console.log(response);
                toast.success('Updated Event!');
                this.props.dispatch(sendNotification(this.props.credentials.token, notificationData));
                this.props.history.push('/events/' + this.props.match.params.id);
            });
    }

    render() {
        return (
            <div className="mainAppContainer playdates">
                {this.state.pictureModal && (
                    <CameraModal toggleModal={this.toggleModal} callbackCamera={this.phoneCamera} callbackUpload={this.phoneUpload} />
                )}
                <MainNavigation title="Edit the Event" leftButton="back" />
                {this.state.loading && (<Spinner />)}
                <div className="bodyContent wrapper">
                    {!this.state.loading && (
                        <div className="maxWidth">
                            <h1 className="fontGreen">Select a cover image for your Event!</h1>
                            <div className="profile-avatar">
                                <input className="hidden" id="file" type="file" onChange={e => this.filesChanged(e.target.files)} accept="image/*" required />
                                <div className="round-avatar huge fontGreen">
                                    <Avatar
                                        src={guard(this.props.pe, '.images') || this.state.image}
                                        onClick={this.changeImage}
                                        alt={this.props.pe.title}
                                        className="round-avatar huge changeImage"
                                    />
                                </div>
                                <div className="alignCenter">
                                    <div onClick={this.changeImage} className="changeImage">Add Event cover picture</div>
                                    {guard(this.props.pe, '.images') && (
                                        <div onClick={this.clear} className="changeImage">Clear</div>
                                    )}
                                </div>
                            </div>
                            <Link onClick={e => this.saveChanges(e)} to="/events/manage/event" className="wideButtons greenBorder greenBG fontWhite">Save Changes</Link>
                        </div>
                    )}
                </div>
                <SecondaryNavigation selected="events" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        pe: state.playdates.edit
    };
}

export default connect(mapStateToProps)(PlaydateEdit);
