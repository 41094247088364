import React from 'react';
import { connect } from 'react-redux';

import { contactUsList } from '../../redux/actions/all';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import ContactFormList from '../../layout/ContactFormList';
import Spinner from '../../layout/UI/Spinner';
import NotFound from '../../layout/NotFound';
import { infiniteScroll } from '../../helpers/Pull2Refresh';
import ApiError from '../../helpers/ApiError';

export class AdminContactUsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingMore: false,
            currentPage: 1,
            totalPages: 1, // This it's being used by infiniteScroll->handleScroll->loadMore
            entries: []
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.start();
    }

    start = () => {
        this.props.dispatch(contactUsList(this.props.credentials.token, this.state.currentPage))
            .then(response => {
                this.setState(
                    prevState => ({
                        loading: false,
                        loadingMore: false,
                        totalPages: response.value.headers['x-pagination-page-count'],
                        entries: prevState.entries.concat(response.value.data)
                    }), () => {
                        if (this.state.currentPage < this.state.totalPages) {
                            infiniteScroll(this, this.start);
                        }
                    }
                );
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loading: false, loadingMore: false });
            });
    }

    render() {
        return (
            <div className="mainAppContainer">
                <MainNavigation title="Manage Contact Forms" />
                {(!!this.state.loading) && (
                    <div className="bodyContent noPaddingTop">
                        <Spinner />
                    </div>
                )}
                {!this.state.loading && !this.state.entries.length && (
                    <NotFound type="form" />
                )}
                {!this.state.loading && !!this.state.entries && !!this.state.entries.length && (
                    <div className="bodyContent noPaddingTop">
                        <div className="maxWidth">
                            <ContactFormList list={this.state.entries} />
                        </div>
                    </div>
                )}
                {!!this.state.loadingMore && (
                    <div className="bodyContent noPaddingTop">
                        <Spinner />
                    </div>
                )}
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(AdminContactUsList);
