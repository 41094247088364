import React from 'react';
import { connect } from 'react-redux';
import Textarea from 'react-textarea-autosize';
import ReactGA from 'react-ga';

import { getLocation } from '../../redux/actions/locations';
import { contactUs } from '../../redux/actions/all';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import Spinner from '../../layout/UI/Spinner';
import { urlFriendly } from '../../helpers/Functions';
import Config from '../../helpers/Config';
import ApiError from '../../helpers/ApiError';

class ClaimLocation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sending: false,
            location: null,
            data: {
                user_phone: '',
                user_location: '',
                business_title: '',
                business_email: '',
                business_number: '',
                business_website: '',
                comments: ''
            }
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(getLocation(this.props.credentials.token, this.props.match.params.id))
            .then(response => {
                this.setState({
                    location: response.value.data
                });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    inputHandler = e => {
        this.setState({ data: { ...this.state.data, [e.target.name]: e.target.value } });
    }

    verifyData = e => {
        e.preventDefault();
        if (
            !!this.state.location
            && !!this.props.userData
            && !!this.state.location.location_id
            && !!this.state.location.location_name
            && !!this.props.userData.user_firstname
            && !!this.props.userData.user_email
            && (!!this.props.userData.user_phone || !!this.state.data.user_phone)
            && !!this.state.data.business_title
            && !!this.state.data.business_number
            && !!this.state.data.business_email
            && !!this.state.data.business_website
            && !!this.state.data.user_location
        ) {
            this.setState({ sending: true });

            const data = {
                contactus_type: 'claimBusiness',
                location_id: this.state.location.location_id,
                contactus_additionalinfo: {
                    location_name: this.state.location.location_name,
                    user_profile: Config.fileDirectory + 'user/profile/' + this.props.credentials.user_id,
                    location_url: Config.fileDirectory + 'location/profile/' + this.state.location.location_id + '/' + urlFriendly(this.state.location.location_name),
                    user_phone: this.state.data.user_phone || this.props.userData.user_phone,
                    title: this.state.data.business_title,
                    email: this.state.data.business_email,
                    website: this.state.data.business_website,
                    business_phone: this.state.data.business_number,
                    comments: this.state.data.comments,
                    user_location: this.state.data.user_location

                }
            };

            this.props.dispatch(contactUs(this.props.credentials.token, data))
                .then(() => {
                    this.setState({ sending: 'sent' });
                })
                .catch(err => {
                    ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                    this.setState({ sending: false });
                });
        } else {
            ApiError('Please fill out the entire form');
        }
    }

    render() {
        // gets the selected categories for this specific location
        // it then passes the data to the DynamicForm, with data handlers, this way it will return all the necessary fields specified by each category

        return (
            <div className="mainAppContainer LoginScreens">
                <MainNavigation {...this.props} title="Claim Location" leftButton="back" />
                <div className="bodyContent backgroundGreen">
                    <div className="spacerSmall" />
                    <form onSubmit={this.verifyData}>
                        <div className="wrapper maxWidth">
                            <h1 className="fontWhite">To claim this business please fill out the form</h1>
                        </div>
                        {this.state.location
                            && (
                                <div className="wrapper maxWidth">
                                    <div className="innerLabelInput">
                                        <span className="blocking-span">
                                            <input className="inputText" name="username" type="text" required value={this.props.userData.user_firstname + ' ' + this.props.userData.user_lastname} readOnly />
                                            <span className="floating-label">Your Name</span>
                                        </span>
                                    </div>
                                    <div className="innerLabelInput">
                                        <span className="blocking-span">
                                            <input className="inputText" name="user_location" type="text" required value={this.state.data.user_location} onChange={this.inputHandler} />
                                            <span className="floating-label">Your Location(City, Province/State, Country)</span>
                                        </span>
                                    </div>
                                    <div className="innerLabelInput">
                                        <span className="blocking-span">
                                            <input className="inputText" name="email" type="email" required value={this.props.userData.user_email} readOnly />
                                            <span className="floating-label">Your Email</span>
                                        </span>
                                    </div>
                                    <div className="innerLabelInput">
                                        <span className="blocking-span">
                                            <input className="inputText" name="user_phone" type="text" required value={this.props.userData.user_phone || this.state.data.user_phone} onChange={this.inputHandler} />
                                            <span className="floating-label">Your Phone Number</span>
                                        </span>
                                    </div>
                                    <h4 className="fontWhite">Regarding this location:</h4>
                                    <div className="innerLabelInput">
                                        <span className="blocking-span">
                                            <input className="inputText" name="locationName" type="text" required value={this.state.location.location_name} readOnly />
                                            <span className="floating-label">Location Name</span>
                                        </span>
                                    </div>
                                    <div className="innerLabelInput">
                                        <span className="blocking-span">
                                            <input className="inputText fontWhite" name="business_title" type="text" required value={this.state.data.business_title} onChange={this.inputHandler} />
                                            <span className="floating-label">Your Title (Owner, employee, other)</span>
                                        </span>
                                    </div>
                                    <div className="innerLabelInput">
                                        <span className="blocking-span">
                                            <input className="inputText fontWhite" name="business_number" type="text" required value={this.state.data.business_number} onChange={this.inputHandler} />
                                            <span className="floating-label">Location/Business Phone Number</span>
                                        </span>
                                    </div>
                                    <div className="innerLabelInput">
                                        <span className="blocking-span">
                                            <input className="inputText fontWhite" name="business_email" type="email" required value={this.state.data.business_email} onChange={this.inputHandler} />
                                            <span className="floating-label">Location/Business Email</span>
                                        </span>
                                    </div>
                                    <div className="innerLabelInput">
                                        <span className="blocking-span">
                                            <input className="inputText fontWhite" name="business_website" type="text" required value={this.state.data.business_website} onChange={this.inputHandler} />
                                            <span className="floating-label">Business Website</span>
                                        </span>
                                    </div>
                                    <div className="fontWhite h5">Additional Comments</div>
                                    <Textarea name="comments" onChange={this.inputHandler} className="niceTextarea smallmargin" placeholder="" value={this.state.data.comments} />
                                    <div className="spacerSmall" />
                                    {this.state.sending === false && (
                                        <input type="submit" value="Submit &gt;" to="#" className="rightRoundButton whiteBorder whiteOpaque fontWhite" />
                                    )}
                                    {this.state.sending === true && (
                                        <Spinner />
                                    )}
                                    {this.state.sending === 'sent' && (
                                        <div className="fontWhite">
                                            Your claim has been sent! We will contact you to verify your claim shortly.
                                            <div className="spacerSmall" />
                                        </div>
                                    )}
                                </div>
                            )}
                    </form>
                </div>
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(ClaimLocation);
