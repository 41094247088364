import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactGA from 'react-ga';
import { toast } from 'react-toastify';
import DatePicker from 'rmc-date-picker';

import 'rmc-picker/assets/index.css';
import 'rmc-date-picker/assets/index.css';

import { getDogProfile, updateDog } from '../../redux/actions/dogs';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import { formatMonth, guard } from '../../helpers/Functions';
import ApiError from '../../helpers/ApiError';

class UserDogEditDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dogProfile: null,
            deceased: null
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(getDogProfile(this.props.credentials.token, this.props.match.params.id))
            .then(() => this.setState({
                dogProfile: this.props.dogProfile,
                entity_poochid: ((this.props.dogProfile && this.props.dogProfile.parententity && this.props.dogProfile.parententity.entity_poochid) || '')
            }));
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    handlePoochID = e => {
        const poochId = e.target.value.split('pooch.pet/');
        let text = '';
        if (poochId.length > 1) {
            text = poochId[1].replace(/[^a-z0-9_-]+/gi, '').toLowerCase();
        }
        this.setState({
            // changed: true,
            entity_poochid: text
        });
    }

    verifyData = e => {
        e.preventDefault();
        if (this.state.dogProfile) {
            if (!this.state.dogProfile.dog_sex) {
                ApiError('Please select the sex of the dog');
                return;
            }
            if (this.state.dogProfile.dog_fixed !== 1 && this.state.dogProfile.dog_fixed !== 0) {
                ApiError('Please select if the dog is fixed or not fixed');
                return;
            }
            if (!this.state.dogProfile.dog_birthdate) {
                ApiError('Please select the date of birth of your dog!');
                return;
            }
            const saveObject = {};
            saveObject.dog_sex = this.state.dogProfile.dog_sex;
            saveObject.dog_fixed = this.state.dogProfile.dog_fixed;
            saveObject.dog_birthdate = moment(this.state.dogProfile.dog_birthdate).format('YYYY-MM-DD');
            saveObject.dog_deceased = this.state.dogProfile.dog_deceased ? moment(this.state.dogProfile.dog_deceased).format('YYYY-MM-DD') : null;
            saveObject.dog_playdates = this.state.dogProfile.dog_playdates;
            saveObject.dog_weight = this.state.dogProfile.dog_weight;
            saveObject.dog_socialmedia = this.state.dogProfile.dog_socialmedia;
            saveObject.entity_poochid = this.state.entity_poochid;
            this.props.dispatch(updateDog(this.props.credentials.token, this.props.match.params.id, saveObject))
                .then(() => {
                    toast.success('Updated dog correctly');
                    this.props.history.goBack();
                })
                .catch(err => {
                    ApiError(err);
                });
        }
    }

    onDateChange = date => {
        this.setState({ dogProfile: { ...this.state.dogProfile, dog_birthdate: date } });
    }

    onDeceasedChange = date => {
        this.setState({ dogProfile: { ...this.state.dogProfile, dog_deceased: date } });
    }

    socialMediaTextHandler = (e, type) => {
        const value = e.target.value;
        this.setState({ dogProfile: { ...this.state.dogProfile, dog_socialmedia: { ...this.state.dogProfile.dog_socialmedia, [type]: value } } });
    }

    toggleSex(e) {
        this.setState({ dogProfile: { ...this.state.dogProfile, dog_sex: e.target.innerText.toLowerCase() } });
    }

    toggleFixed(val) {
        this.setState({ dogProfile: { ...this.state.dogProfile, dog_fixed: val } });
    }

    togglePlaydates(val) {
        this.setState({ dogProfile: { ...this.state.dogProfile, dog_playdates: val } });
    }

    changeWeight(e) {
        this.setState({ dogProfile: { ...this.state.dogProfile, dog_weight: e.target.value } });
    }

    addWeight(action, value) {
        let v = 50;
        if (value) {
            v = value;
        }
        if (action === 'add' && v < 250) {
            this.setState({ dogProfile: { ...this.state.dogProfile, dog_weight: parseInt(v, 10) + 1 } });
        } else if (action === 'sub' && v > 1) {
            this.setState({ dogProfile: { ...this.state.dogProfile, dog_weight: parseInt(v, 10) - 1 } });
        }
    }

    toggleDeceased(val) {
        if (val === true) {
            this.setState({ dogProfile: { ...this.state.dogProfile, dog_deceased: moment()._d } });
        } else {
            this.setState({ dogProfile: { ...this.state.dogProfile, dog_deceased: null } });
        }
        this.setState({ deceased: val });
    }

    render() {
        return (
            <div className="mainAppContainer LoginScreens">
                <MainNavigation {...this.props} title="Edit details" leftButton="back" />
                <div className="bodyContent backgroundGreen">
                    <form onSubmit={this.verifyData}>
                        {!!this.state.dogProfile && (
                            <div className="wrapper maxWidth fontWhite">
                                <div className="section">Pooch ID</div>
                                <ul className="dogSocialMediaProfile edit">
                                    <li className="socialMediaListItem row">
                                        <div className="innerLabelInput">
                                            <input onChange={this.handlePoochID} className="inputText" name="entity_poochid" type="text" value={'pooch.pet/' + this.state.entity_poochid} />
                                        </div>
                                    </li>
                                </ul>
                                <h1 className="section">Tell us a little more about {this.state.dogProfile.dog_name}</h1>
                                <div className="section">{this.state.dogProfile.dog_name} is:</div>
                                <div className="row multiChoiceHolder">
                                    <div className={'multiChoice ' + (guard(this.state.dogProfile, '.dog_sex') === 'male' ? 'selected' : '')} onClick={e => this.toggleSex(e)}>Male</div>
                                    <div className={'multiChoice ' + (guard(this.state.dogProfile, '.dog_sex') === 'female' ? 'selected' : '')} onClick={e => this.toggleSex(e)}>Female</div>
                                </div>
                                <div className="section">When was {this.state.dogProfile.dog_name} born?</div>
                                <div className="selectDate fontWhite">
                                    <DatePicker
                                        mode="date"
                                        date={this.state.dogProfile.dog_birthdate ? moment(this.state.dogProfile.dog_birthdate)._d : moment()._d}
                                        formatMonth={formatMonth}
                                        onDateChange={this.onDateChange}
                                        maxDate={moment()._d}
                                    />
                                </div>
                                <div className="section">Is {this.state.dogProfile.dog_name} fixed?</div>
                                <div className="row multiChoiceHolder">
                                    <div className={'multiChoice ' + (guard(this.state.dogProfile, '.dog_fixed') === 1 ? 'selected' : '')} onClick={() => this.toggleFixed(1)}>Fixed</div>
                                    <div className={'multiChoice ' + (guard(this.state.dogProfile, '.dog_fixed') === 0 ? 'selected' : '')} onClick={() => this.toggleFixed(0)}>Not Fixed</div>
                                </div>
                                <div className="section">Is {this.state.dogProfile.dog_name} available for Events?</div>
                                <div className="row multiChoiceHolder">
                                    <div className={'multiChoice ' + (guard(this.state.dogProfile, '.dog_playdates') === 1 ? 'selected' : '')} onClick={() => this.togglePlaydates(1)}>Yes</div>
                                    <div className={'multiChoice ' + (guard(this.state.dogProfile, '.dog_playdates') === 0 ? 'selected' : '')} onClick={() => this.togglePlaydates(0)}>No</div>
                                </div>
                                <div className="section">How much does {this.state.dogProfile.dog_name} weigh?</div>
                                <div className="row">
                                    <input className="slider white" id="slider" type="range" min="1" max="250" step="1" value={guard(this.state.dogProfile, '.dog_weight') || ''} onChange={e => this.changeWeight(e)} />
                                    <div className="sliderValue">
                                        <span className="sub" onClick={() => this.addWeight('sub', this.state.dogProfile.dog_weight)}> - </span> <span>{parseFloat(this.state.dogProfile.dog_weight).toFixed(2)}lbs</span> <span className="sub" onClick={() => this.addWeight('add', this.state.dogProfile.dog_weight)}> + </span>
                                    </div>
                                </div>
                                <div className="section">Social Media
                                    <ul className="dogSocialMediaProfile edit">
                                        <li className="socialMediaListItem row">
                                            <i className="icn icon-facebook fontWhite" />
                                            <div className="innerLabelInput">
                                                <input onChange={e => this.socialMediaTextHandler(e, 'facebook')} className="inputText" name="facebook" type="text" value={(this.state.dogProfile.dog_socialmedia && this.state.dogProfile.dog_socialmedia.facebook) || ''} />
                                            </div>
                                        </li>
                                        <li className="socialMediaListItem row">
                                            <i className="icn icon-twitter fontWhite" />
                                            <div className="innerLabelInput">
                                                <input onChange={e => this.socialMediaTextHandler(e, 'twitter')} className="inputText" name="twitter" type="text" value={(this.state.dogProfile.dog_socialmedia && this.state.dogProfile.dog_socialmedia.twitter) || ''} />
                                            </div>
                                        </li>
                                        <li className="socialMediaListItem row">
                                            <i className="icn icon-instagram fontWhite" />
                                            <div className="innerLabelInput">
                                                <input onChange={e => this.socialMediaTextHandler(e, 'instagram')} className="inputText" name="instagram" type="text" value={(this.state.dogProfile.dog_socialmedia && this.state.dogProfile.dog_socialmedia.instagram) || ''} />
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="section">Create memorial for {this.state.dogProfile.dog_name}?</div>
                                <div className="row multiChoiceHolder">
                                    <div className={'multiChoice ' + ((this.state.dogProfile && !!this.state.dogProfile.dog_deceased) || this.state.deceased === true ? 'selected' : '')} onClick={() => this.toggleDeceased(true)}>Yes</div>
                                    <div className={'multiChoice ' + ((this.state.dogProfile && !this.state.dogProfile.dog_deceased) && this.state.deceased !== true ? 'selected' : '')} onClick={() => this.toggleDeceased(false)}>No</div>
                                </div>
                                {((this.state.dogProfile && !!this.state.dogProfile.dog_deceased) || this.state.deceased === true) && (
                                    <div className="section">When did {this.state.dogProfile.dog_name} passed away?</div>
                                )}
                                {((this.state.dogProfile && !!this.state.dogProfile.dog_deceased) || this.state.deceased === true) && (
                                    <div className="selectDate fontWhite">
                                        <DatePicker
                                            mode="date"
                                            date={this.state.dogProfile.dog_deceased ? moment(this.state.dogProfile.dog_deceased)._d : moment()._d}
                                            formatMonth={formatMonth}
                                            onDateChange={this.onDeceasedChange}
                                            maxDate={moment()._d}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        {!!this.state.dogProfile && (
                            <div className="wrapper maxWidth">
                                <input type="submit" value="Save &gt;" className="rightRoundButton whiteBorder whiteOpaque fontWhite" />
                            </div>
                        )}
                    </form>
                </div>
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        dogProfile: state.dogs.dogProfile
    };
}

export default connect(mapStateToProps)(UserDogEditDetails);
