import React from 'react';
import Lightbox from 'react-image-lightbox';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import 'react-image-lightbox/style.css';

import BasicConfirm from '../../layout/UI/BasicConfirm';
import BlurImageLoader from './BlurImageLoader';
import Config from '../../helpers/Config';

/**
 *
 * @param {string} iconClass
 * @param {function} handleClickRemoveImage
 * @param {function} handleClickSetImage
 * @param {Object} match
 * @param {Object} userData
 * @param {Object} dogProfile
 * @param {Array} images
 * @param {string} size
 * @param {*} count
 * @param {boolean} fillers
 */
class GalleryLightbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLightboxOpen: false,
            photoIndex: 0,
            visibleImageId: 0
        };
    }

    galleryState = (e, imageId) => {
        e.preventDefault();
        this.setState({
            photoIndex: e.target.getAttribute('keyval'),
            visibleImageId: imageId
        }, () => {
            this.setState({ isLightboxOpen: true });
        });
    }

    lightboxGallery = (images, size = 'normal', count = 'all', fillers = false) => {
        const gallery = [];
        if (count === 'all') {
            count = images.length;
        }
        for (let i = 0; i < count; i++) {
            if (images[i]) {
                const imageid = images[i].image_id || i;
                gallery.push(
                    <Link to="#" key={imageid} className={'pictureHolder ' + size} onClick={e => this.galleryState(e, imageid)}>
                        <BlurImageLoader
                            image={images[i].image_largeurl}
                            imageLow={images[i].image_smallurl}
                            alt={'picture ' + imageid}
                            keyval={i}
                        />
                    </Link>
                );
            } else if (fillers) {
                gallery.push(
                    <div key={i} className={'pictureHolder ' + size}>
                        <i className={'icn fontWhite ' + fillers + ' ' + (this.props.iconClass || 'icon-user')} />
                    </div>
                );
            }
        }
        return gallery;
    }

    deleteImage = e => {
        e.preventDefault();
        BasicConfirm(
            'Are you sure?',
            'Do you want to remove Image from the Gallery?',
            'Yes, Remove',
            () => {
                this.setState({ isLightboxOpen: false });
                this.props.handleClickRemoveImage(this.state.visibleImageId);
            }
        );
    }

    setImageAsProfile = e => {
        e.preventDefault();
        BasicConfirm(
            'Are you sure?',
            'Do you want to set Image as Profile?',
            'Confirm',
            () => {
                this.setState({ isLightboxOpen: false });
                this.props.handleClickSetImage(this.state.visibleImageId);
            }
        );
    }

    render() {
        let extraButtons = null;
        let showButtons = false;
        let setImage = null;

        if (this.props.match?.params && this.props.userData && parseInt(this.props.match.params.id, 10) === this.props.userData.user_id) {
            showButtons = true;
        } else if (this.props.dogProfile?.users && this.props.userData) {
            for (let i = 0; i < this.props.dogProfile.users.length; i++) {
                if (this.props.dogProfile.users[i].user_id === this.props.userData.user_id) showButtons = true;
            }
        } else if (this.props.userData?.user_role === 'superadmin') {
            // checks if useradmin adminlocations location id matches the location id
            showButtons = true;
        } else if (this.props.userData?.adminlocations?.[0]?.location?.location_id === parseInt(this.props.match.params.id, 10)) {
            showButtons = true;
        }

        if (showButtons) {
            extraButtons = [(<div className="leftTopCorner" onClick={e => this.deleteImage(e)}>Delete Image</div>)];
            setImage = (<div className="bottomRightCorner" onClick={e => this.setImageAsProfile(e)}>Set as Profile</div>);
            if (this.props.images.length > 1) {
                extraButtons.push(setImage);
            }
        }

        return (
            <div className="pictureGallery">
                {this.props.images
                    && this.lightboxGallery(this.props.images, this.props.size, this.props.count, this.props.fillers)}
                {!!this.state.isLightboxOpen && (
                    <Lightbox
                        toolbarButtons={extraButtons}
                        mainSrc={this.props.images?.[this.state.photoIndex] && this.props.images[this.state.photoIndex].image_largeurl}
                        /* checks if image selected is the last image of the gallery, if it is then set next image is the first image. if not carry on. */
                        nextSrc={this.props.images?.length > 1 && this.props.images?.[this.state.photoIndex] ? this.props.images[(parseInt(this.state.photoIndex, 10) + 1 === this.props.images.length) ? 0 : parseInt(this.state.photoIndex, 10)].image_largeurl : ''}
                        /* check if image selected is the first image of the gallery, if it is then set prev image as the last image. if not carry on. */
                        prevSrc={this.props.images?.length > 1 && this.props.images?.[this.state.photoIndex] ? this.props.images[parseInt(this.state.photoIndex, 10) - 1 < 0 ? this.props.images.length - 1 : parseInt(this.state.photoIndex, 10) - 1].image_largeurl : ''}
                        onCloseRequest={() => this.setState({ isLightboxOpen: false })}
                        onMovePrevRequest={() => this.setState(prevState => {
                            const prevImageIndex = (parseInt(prevState.photoIndex, 10) + this.props.images.length - 1) % this.props.images.length;
                            const newImageId = (this.props.images && this.props.images.length > 0 && this.props.images[prevImageIndex] && this.props.images[prevImageIndex].image_id) ? this.props.images[prevImageIndex].image_id : null;
                            return {
                                photoIndex: prevImageIndex,
                                visibleImageId: newImageId
                            };
                        })}
                        onMoveNextRequest={() => this.setState(prevState => {
                            const nextImageIndex = ((parseInt(prevState.photoIndex, 10) + 1) === this.props.images.length) ? 0 : parseInt(this.state.photoIndex, 10) + 1;
                            const newImageId = (this.props.images && this.props.images.length > 0 && this.props.images[nextImageIndex] && this.props.images[nextImageIndex].image_id) ? this.props.images[nextImageIndex].image_id : null;
                            return {
                                photoIndex: nextImageIndex,
                                visibleImageId: newImageId
                            };
                        })}
                        wrapperClassName={Config.isMobileDevice ? (Config.DeviceType === 'Ios' ? 'mobileLightbox ios' : 'mobileLightbox') : 'websiteLightbox'}
                    />
                )}
            </div>
        );
    }
}

GalleryLightbox.propTypes = {
    iconClass: PropTypes.string,
    handleClickRemoveImage: PropTypes.func,
    handleClickSetImage: PropTypes.func,
    match: PropTypes.instanceOf(Object),
    userData: PropTypes.instanceOf(Object),
    dogProfile: PropTypes.instanceOf(Object),
    images: PropTypes.instanceOf(Array),
    size: PropTypes.string,
    count: PropTypes.node,
    fillers: PropTypes.bool
};

export default withRouter(GalleryLightbox);
