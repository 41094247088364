import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { updateUserHomeAddress, userNewAddress, updateUser } from '../../redux/actions/users';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import ApiError from '../../helpers/ApiError';
import { guard } from '../../helpers/Functions';
import { getGeoLocation } from '../../helpers/GeoLocation';
import CountriesStates from '../../helpers/CountriesStates';

class UserAddress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            savedAddress: null,
            listOfCountries: [],
            listOfStates: [],
            countrySelectedID: -1,
            stateSelectedID: -1
        };
    }

    getGeoLocation = getGeoLocation.bind(this)

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.userData && this.props.userData.address) {
            this.setState({ savedAddress: this.props.userData.address }, () => {
                this.searchDropdownLocations(
                    guard(this.props.userData, '.address.address_country'),
                    guard(this.props.userData, '.address.address_state')
                );
            });
        } else {
            this.searchDropdownLocations(
                guard(this.props.userLocation, '.address_country'),
                guard(this.props.userLocation, '.address_state')
            );
        }
        this.countriesDropdownList();
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    handleChange(event) {
        this.setState({
            savedAddress: {
                ...this.state.savedAddress,
                [event.target.name]: event.target.value
            }
        });
    }

    getLocationData(e) {
        e.preventDefault();
        getGeoLocation(this.props, this.showLocation, this.props.credentials.token, true, 1500);
    }

    saveToApi = () => {
        if (!!this.props.userData && !!this.props.userData.address_id) {
            this.props.dispatch(updateUserHomeAddress(this.props.credentials.token, this.props.userData.address_id, this.state.savedAddress))
                .then(() => {
                    toast.success('Address Saved');
                    this.props.history.goBack();
                })
                .catch(err => {
                    ApiError(err?.response?.data?.[0]?.message || err.message);
                });
        } else {
            this.props.dispatch(userNewAddress(this.props.credentials.token, this.state.savedAddress))
                .then(results => {
                    this.props.dispatch(updateUser(this.props.credentials.token, this.props.credentials.user_id, { address_id: results.value.data.address_id }))
                        .then(() => {
                            toast.success('Address Saved');
                            this.props.history.goBack();
                        })
                        .catch(err => {
                            ApiError(err?.response?.data?.[0]?.message || err.message);
                        });
                })
                .catch(err => {
                    ApiError(err?.response?.data?.[0]?.message || err.message);
                });
        }
    }

    showLocation = () => {
        this.setState({
            savedAddress: this.props.userLocation
        });
        this.searchDropdownLocations(
            guard(this.props.userLocation, '.address_country'),
            guard(this.props.userLocation, '.address_state')
        );
    }

    verifyData(e) {
        e.preventDefault();
        if (!!this.state.savedAddress && !!this.state.savedAddress.address_street && !!this.state.savedAddress.address_city && !!this.state.savedAddress.address_state && !!this.state.savedAddress.address_country) {
            this.saveToApi();
        } else {
            ApiError('Please write your Address, City, (State/Prov), Postal Code and Country');
        }
    }

    /**
     * Check the `CountriesStates` list and compare against the `country` received to update the state of the application with the country
     * and after that update show the right list of state/provinces according to the country selected updating the state of the application with the state selected
     *
     * @param {string} country
     * @param {string} state
     */
    searchDropdownLocations(country, state) {
        if (country) {
            for (const i in CountriesStates) {
                if (CountriesStates[i].country === country) {
                    this.setState({
                        countrySelectedID: i,
                        savedAddress: { ...this.state.savedAddress, address_country: CountriesStates[i].country }
                    });
                    break;
                }
                this.stateProvince(i);
                for (const j in CountriesStates[i].states) {
                    if (CountriesStates[i].states[j] === state || (CountriesStates[i].short && CountriesStates[i].short[j] === state)) {
                        const theState = (CountriesStates[i].short && CountriesStates[i].short[j]) || CountriesStates[i].states[j];
                        this.setState({
                            stateSelectedID: j,
                            savedAddress: { ...this.state.savedAddress, address_state: theState }
                        });
                        break;
                    }
                }
            }
        }
    }

    countriesDropdownList() {
        const countries = CountriesStates.map((location, index) => (<option key={index} value={index}>{location.country}</option>));
        this.setState({ listOfCountries: countries });
    }

    stateProvince(selected) {
        if (selected > -1) {
            const states = CountriesStates[selected].states.map((location, index) => (<option key={index} value={index}>{location}</option>));
            this.setState({ listOfStates: states });
        }
    }

    changeState(selState) {
        let state = false;
        if (selState > -1 && !!CountriesStates[this.state.countrySelectedID]) {
            if (!!CountriesStates[this.state.countrySelectedID].short && !!CountriesStates[this.state.countrySelectedID].short[selState]) {
                state = CountriesStates[this.state.countrySelectedID].short[selState];
            } else if (!!CountriesStates[this.state.countrySelectedID].states && !!CountriesStates[this.state.countrySelectedID].states[selState]) {
                state = CountriesStates[this.state.countrySelectedID].states[selState];
            }
        }
        this.setState({
            stateSelectedID: selState,
            savedAddress: { ...this.state.savedAddress, address_state: state }
        });
    }

    changeCountry(selCountry) {
        let country = false;
        if (selCountry > -1 && !!CountriesStates[selCountry].country) {
            country = CountriesStates[selCountry].country;
        }
        this.setState({
            countrySelectedID: selCountry,
            stateSelectedID: -1,
            listOfStates: [],
            savedAddress: { ...this.state.savedAddress, address_country: country, address_state: false }
        }, () => {
            this.stateProvince(selCountry);
        });
    }

    render() {
        return (
            <div className="mainAppContainer LoginScreens">
                <MainNavigation {...this.props} title="Your Address" leftButton="back" />
                <div className="bodyContent backgroundRed">
                    <div className="LoginWrapper wrapper maxWidth">
                        <h1 className="section">
                            What's your address?
                        </h1>
                    </div>
                    <form onSubmit={event => this.verifyData(event)}>
                        <div className="LoginWrapper wrapper maxWidth">
                            <Link
                                to="#"
                                className="wideButtons whiteBorder whiteOpaque fontWhite"
                                onClick={event => this.getLocationData(event)}
                            >
                                Use Current Location &gt;
                            </Link>
                            <div className="wideButtons transparent fontWhite">
                                Or write your address
                            </div>
                            <div className="innerLabelInput">
                                <span className="blocking-span">
                                    <input
                                        className="inputText"
                                        name="address_street"
                                        type="text"
                                        onChange={e => this.handleChange(e)}
                                        required
                                        value={guard(this.state.savedAddress, '.address_street') || ''}
                                    />
                                    <span className="floating-label">Street Address 1</span>
                                </span>
                            </div>
                            <div className="innerLabelInput">
                                <span className="blocking-span">
                                    <input
                                        className="inputText"
                                        name="address_street2"
                                        type="text"
                                        onChange={e => this.handleChange(e)}
                                        value={guard(this.state.savedAddress, '.address_street2') || ''}
                                    />
                                    <span className="floating-label">Street Address 2</span>
                                </span>
                            </div>
                            <div className="innerLabelInput">
                                <span className="blocking-span">
                                    <input
                                        className="inputText"
                                        name="address_city"
                                        type="text"
                                        onChange={e => this.handleChange(e)}
                                        required
                                        value={guard(this.state.savedAddress, '.address_city') || ''}
                                    />
                                    <span className="floating-label">City</span>
                                </span>
                            </div>
                            <div className="innerLabelInput">
                                <span className="h6 fontWhite">Country</span>
                                <select
                                    value={this.state.countrySelectedID}
                                    className="blankMultipleChoice fontWhite"
                                    onChange={e => this.changeCountry(e.target.value)}
                                >
                                    <option value={-1}>Select a Country</option>
                                    {this.state.listOfCountries.length > 0 && (
                                        this.state.listOfCountries
                                    )}
                                </select>
                            </div>
                            {!!this.state.listOfStates && this.state.listOfStates.length > 0 && (
                                <div className="innerLabelInput">
                                    <span className="h6 fontWhite">State/Province</span>
                                    <select
                                        value={this.state.stateSelectedID}
                                        className="blankMultipleChoice fontWhite"
                                        onChange={e => this.changeState(e.target.value)}
                                    >
                                        <option value={-1}>Select a State/Province</option>
                                        {this.state.listOfStates}
                                    </select>
                                </div>
                            )}
                            <div className="innerLabelInput">
                                <span className="blocking-span">
                                    <input
                                        className="inputText"
                                        name="address_zipcode"
                                        type="text"
                                        onChange={e => this.handleChange(e)}
                                        value={guard(this.state.savedAddress, '.address_zipcode') || ''}
                                    />
                                    <span className="floating-label">Postal Code</span>
                                </span>
                            </div>
                            <input
                                type="submit"
                                value="Done &gt;"
                                className="rightRoundButton whiteBorder whiteOpaque fontWhite"
                            />
                        </div>
                    </form>
                </div>
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        userLocation: state.users.user.user_location
    };
}

export default connect(mapStateToProps)(UserAddress);
