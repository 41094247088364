import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';

import GalleryLightbox from '../../layout/gallery/GalleryLightbox';
import Avatar from '../../layout/UI/Avatar';
import SocialMediaIcons from '../../layout/UI/SocialMediaIcons';
import { guard, listDogBreeds } from '../../helpers/Functions';
import sb from '../../helpers/SendBird';
import ApiError from '../../helpers/ApiError';
import { sendRequest } from '../../redux/actions/users';
import { deletePicture } from '../../redux/actions/all';
import WhiteTitle from '../../hoc/WhiteTitle';

export default class OtherDogProfile extends React.Component {
    friendRequest = (e, users) => {
        e.preventDefault();
        for (const i in users) {
            this.props.dispatch(sendRequest(this.props.credentials.token, users[i].user_id));
        }
    }

    chatWithUser = (e, users, lostDog) => {
        e.preventDefault();
        const userIds = [];
        let roomName = this.props.userData.user_firstname;

        for (const i in users) {
            userIds.push(users[i].user_id);
            roomName += ', ' + users[i].user_firstname;
        }

        if (lostDog) {
            roomName = 'Dog Missing: ' + this.props.dogProfile.name;
        }

        const that = this;
        sb.connect(
            this.props.credentials.user_id,
            this.props.credentials.token,
            () => {
                sb.GroupChannel.createChannelWithUserIds(userIds, true, roomName, null, null, (createdChannel, error) => {
                    if (error) {
                        toast.error('Sorry, there was an issue creating this chat, please try again.');
                        return;
                    }
                    that.props.history.push('/chat/channel/' + createdChannel.url);
                });
            }
        );
    }

    handleClickRemoveImage = id => {
        this.props.dispatch(deletePicture(this.props.credentials.token, id))
            .then(() => {
                this.setState(prevState => {
                    const newGallery = prevState.gallery.filter(image => image.image_id !== id);
                    return {
                        gallery: newGallery
                    };
                }, () => {
                    this.setState({
                        // image: this.state.gallery && this.state.gallery[0] && this.state.gallery[0].image_largeurl ? this.state.gallery[0].image_largeurl : null
                    });
                });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    listOwners(owners) {
        return owners.map((owner, index) => {
            const image = (
                <Link to={'/user/profile/' + owner.user_id} className="round-avatar colored-icon">
                    <Avatar
                        src={guard(owner, '.images.0.image_mediumurl')}
                        alt={this.props.dogProfile.name}
                        type="user"
                    />
                </Link>
            );
            let lastSeen = null;
            if (owners && owners[index] && owners[index].lastaddress && owners[index].lastaddress.address_city && owners[index].lastaddress.address_country) {
                lastSeen = (<div className="lastSeen">Last Seen: {owners[index].lastaddress.address_city}, {owners[index].lastaddress.address_country}</div>);
            }
            let friendShipStatus = '';
            if (owners[index].myfriendship && owners[index].myfriendship.friendship_status === 'accepted') {
                friendShipStatus = (<div className="flexSmallestButtons borderGreen greenBG fontWhite"><p>Friends</p></div>);
            } else if (owners[index].myfriendship && owners[index].myfriendship.friendship_status === 'pending') {
                friendShipStatus = (<div className="flexSmallestButtons borderBlue blueBG fontWhite"><p>Pending</p></div>);
            } else {
                friendShipStatus = (<div className="filler" />);
            }
            return (<div className="user row" key={owner.user_id}>{image}<span className="dogOwnerLink info"><Link to={'/user/profile/' + owner.user_id} className="ownerInfo fontGreen">{owner.user_firstname + ' ' + owner.user_lastname}{lastSeen}</Link></span>{friendShipStatus}</div>);
        });
    }

    render() {
        let friendshipButton = null;
        let follow = null;
        let messageButton = null;
        let editButton = null;

        const dog_is_lost = !!this.props.dogProfile && !!this.props.dogProfile.dogaddress && !this.props.dogProfile.dogaddress.dogaddress_found;
        const dog_passed_away = !!this.props.dogProfile.dog_deceased;
        const lastSeen = [];
        let missingData = '';
        if (dog_is_lost) {
            missingData = this.props.dogProfile.dogaddress;
        }

        if (this.props.isMyDog) {
            editButton = (
                <Link className="fontGreen" to={'/dog/' + this.props.dogProfile.dog_id + '/edit'}>
                    Edit My Dog
                </Link>
            );
        } else {
            friendshipButton = (
                <Link to="#" className="fontBlue" onClick={e => this.friendRequest(e, this.props.dogProfile.users)}>
                    <i className="icn icon-couple" />Befriend Owner(s)
                </Link>
            );
            follow = null;/* <Link className="fontBlue" to="#">Follow Me</Link> */
            messageButton = <Link to="#" className="fontBlue" onClick={e => this.chatWithUser(e, this.props.dogProfile.users, false)}><i className="icn icon-send-message-plane" />Message Owner(s)</Link>;
        }

        return (
            <div>
                <div className={'profilePictureWrapper dog' + (dog_is_lost ? ' lost' : '') + (dog_passed_away ? ' passed' : '')}>
                    <div className="row maxWidth wrapper alignItems">
                        <div className="round-avatar huge dog">
                            <Avatar
                                src={guard(this.props.dogProfile, '.images.0.image_mediumurl')}
                                alt={this.props.dogProfile.name}
                                className="changeImage"
                                type="dog"
                            />
                        </div>
                        <div className="details dog">
                            <div className="dogData">
                                <div className="dogTitle">
                                    {this.props.dogProfile.dog_name}
                                </div>
                            </div>
                            <div className="dogStatus">
                                {dog_passed_away ? ' (Passed away)' : ''}
                                {(dog_is_lost && !dog_passed_away) ? <div className="fontWhite">(MISSING)</div> : ''}
                                <span>{(!dog_is_lost && !dog_passed_away && this.props.dogProfile.dog_playdates) ? '(Available for events)' : ''}</span>
                            </div>
                            <div className="dogBreed">
                                <strong>Breed(s): </strong> <span>{this.props.dogProfile && this.props.dogProfile.breeds && this.props.dogProfile.breeds.length && listDogBreeds(this.props.dogProfile.breeds)}</span>
                            </div>
                            <div className="dateOfBirth">
                                {this.props.dogProfile.dog_birthdate && (
                                    <div>
                                        <strong>D.O.B: </strong><span>{this.props.dogProfile.dog_birthdate}</span>
                                    </div>
                                )}
                            </div>
                            <div className="dogGender">
                                {this.props.dogProfile.dog_sex && (
                                    <div>
                                        <strong>Sex: </strong><span>{this.props.dogProfile.dog_sex.charAt(0).toUpperCase() + this.props.dogProfile.dog_sex.slice(1)}</span>
                                    </div>
                                )}
                            </div>
                            <div className="dogWeight">
                                {this.props.dogProfile.dog_weight && (
                                    <div>
                                        <strong>Weight: </strong><span>{parseFloat(this.props.dogProfile.dog_weight).toFixed(2)} lbs</span>
                                    </div>
                                )}
                            </div>
                            <div className="dogFixed">
                                {(this.props.dogProfile.dog_fixed === 0 || this.props.dogProfile.dog_fixed === 1) && (
                                    <div>
                                        <strong>Fixed: </strong><span>{this.props.dogProfile.dog_fixed ? 'Fixed' : 'Not Fixed'}</span>
                                    </div>
                                )}
                            </div>
                            {this.props.dogProfile.dog_socialmedia && (
                                <div className="social-media">
                                    <SocialMediaIcons
                                        socialmedia={this.props.dogProfile.dog_socialmedia}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="tabSubmenu otherUserDog row">
                    {editButton}
                    {friendshipButton}
                    {messageButton}
                    {follow}
                </div>
                <div className="profileDetails maxWidth noPadding">
                    {dog_is_lost && (
                        <div className="boxContainer">
                            <div className="boxTitle row">
                                <div className="title fontRed">MISSING INFORMATION</div>
                            </div>
                            <div className="dogDescription">
                                <span className="missingDogInfo">
                                    {!this.props.isMyDog && (
                                        <Link to="#" onClick={e => this.chatWithUser(e, this.props.dogProfile.users, true)} className="wideButtons redBG fontWhite">Contact Owner!</Link>
                                    )}
                                    {missingData.dogaddress_reward && (
                                        <div className="dogAddress">
                                            <strong className="fontRed">Reward: </strong> ${missingData.dogaddress_reward}
                                        </div>
                                    )}
                                    {missingData.dogaddress_datetime && (
                                        <div className="missingDog">
                                            <strong className="fontRed">Date Lost: </strong> {moment(missingData.dogaddress_datetime).format('MMMM Do YYYY, h:mm a')}
                                        </div>
                                    )}
                                    {missingData.address && (
                                        <div className="dogLastSeen">
                                            <strong className="fontRed">Last Seen: </strong>
                                            {!!missingData.address && !!missingData.address.address_street && lastSeen.push(missingData.address.address_street) && ''}
                                            {!!missingData.address && !!missingData.address.address_street2 && lastSeen.push(missingData.address.address_street2) && ''}
                                            {!!missingData.address && !!missingData.address.address_city && lastSeen.push(missingData.address.address_city) && ''}
                                            {!!missingData.address && !!missingData.address.address_state && lastSeen.push(missingData.address.address_state) && ''}
                                            {!!missingData.address && !!missingData.address.address_country && lastSeen.push(missingData.address.address_country) && ''}
                                            {!!missingData.address && !!missingData.address.address_zipcode && lastSeen.push(missingData.address.address_zipcode) && ''}
                                            {lastSeen.join(' ')}
                                            {lastSeen.splice(0, lastSeen.length) && ''}
                                        </div>
                                    )}
                                    {(!!missingData.dogaddress_contactinfo.contact_by_phone || !!missingData.dogaddress_contactinfo.contact_by_text) && (
                                        <div className="">
                                            <strong className="fontRed">Call or Text: </strong> <a className="fontGreen" href={'tel:' + missingData.dogaddress_contactinfo.phone}>{missingData.dogaddress_contactinfo.phone}</a>
                                        </div>
                                    )}
                                    {!!missingData.dogaddress_contactinfo.contact_by_email && (
                                        <div>
                                            <strong className="fontRed">Email: </strong> <a className="fontGreen" href={'mailto:' + missingData.dogaddress_contactinfo.email}>{missingData.dogaddress_contactinfo.email}</a>
                                        </div>
                                    )}
                                    {missingData.dogaddress_description && (
                                        <div>
                                            <strong className="fontRed">Additional Info: </strong>
                                            <br />{missingData.dogaddress_description}
                                        </div>
                                    )}
                                </span>
                            </div>
                        </div>
                    )}
                    <div className="boxContainer">
                        <div className="boxTitle row">
                            <div className="title">About {this.props.dogProfile.dog_name} </div>
                        </div>
                        {this.props.dogProfile.dog_description && (
                            <div className="dogDescription">{this.props.dogProfile.dog_description}</div>
                        )}
                        {this.props.dogProfile.dog_description === null && (
                            <div className="dogDescription">No Description yet...</div>
                        )}
                    </div>
                    {this.props.dogProfile.users && this.props.dogProfile.users.length && (
                        <div className="aboutSection">
                            <div className="boxContainer">
                                <div className="boxTitle row">
                                    <div className="title">
                                        Owner(s):
                                    </div>
                                </div>
                            </div>
                            <div className="participantList">
                                <div className="participantListItem">
                                    {this.listOwners(this.props.dogProfile.users)}
                                </div>
                            </div>
                        </div>
                    )}
                    {this.props.dogProfile.images && this.props.dogProfile.images.length > 0 && (
                        <div className="gallerySection">
                            <WhiteTitle>Pictures
                                <div className="viewAll">
                                    <Link to={'/dog/' + this.props.dogProfile.dog_id + '/edit'} className="fontGreen"><i className="icn icon-upload-photo fontGreen" /></Link>
                                    <Link to={'/dog/' + this.props.dogProfile.dog_id + '/gallery'} className="fontGreen"><i className="icn icon-gallery" /></Link>
                                </div>
                            </WhiteTitle>
                        </div>
                    )}
                    {this.props.dogProfile.images && (
                        <div className="galleryHolder">
                            <GalleryLightbox
                                userData={this.props.userData}
                                images={this.props.dogProfile.images}
                                count={2}
                                size="normal"
                            />
                            {this.props.dogProfile.images.length > 2 && (
                                <div className="holderEnclosed">
                                    <Link to={'/dog/' + this.props.dogProfile.dog_id + '/gallery'}>+ {this.props.dogProfile.images.length - 2} More</Link>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
