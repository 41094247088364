import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';

import { connect } from 'react-redux';
import Index from './components/pages/login/Index';
import Register from './components/pages/login/Register';
import RegisterUser from './components/pages/login/RegisterUser';
import RegisterEmail from './components/pages/login/RegisterEmail';
import RegisterPassword from './components/pages/login/RegisterPassword';
import Login from './components/pages/login/Login';
import LoginEmail from './components/pages/login/LoginEmail';
import LoginForgotPassword from './components/pages/login/LoginForgotPassword';
import LoginReset from './components/pages/login/LoginReset';

import MainSearch from './components/pages/search/MainSearch';
import WelcomeExplore from './components/pages/search/WelcomeExplore';
import FilterCategory from './components/pages/search/FilterCategory';
import ListSearch from './components/pages/search/ListSearch';

import AddLocationType from './components/pages/location/AddLocationType';
import AddLocationAddress from './components/pages/location/AddLocationAddress';
import AddLocationDetails from './components/pages/location/AddLocationDetails';
import LocationProfile from './components/pages/location/LocationProfile';
import LocationGallery from './components/pages/location/LocationGallery';
import LocationCheckIn from './components/pages/location/LocationCheckIn';
import LocationReview from './components/pages/location/LocationReview';
// import CheckInList from './components/pages/location/CheckInList'

import Favorites from './components/pages/settings/Favorites';

// import PlaydatesJoin from './components/pages/playdates/PlaydatesJoin'
import PlaydatesHome from './components/pages/playdates/PlaydatesHome';
import PlaydatesProfile from './components/pages/playdates/PlaydatesProfile';
import PlaydatesCreate from './components/pages/playdates/PlaydatesCreate';
import PlaydatesCreateImages from './components/pages/playdates/PlaydatesCreateImages';
import PlaydatesCreateDogSelection from './components/pages/playdates/PlaydatesCreateDogSelection';
import PlaydatesCreateParkSelection from './components/pages/playdates/PlaydatesCreateParkSelection';
import PlaydatesCreateFriendSelection from './components/pages/playdates/PlaydatesCreateFriendSelection';
import PlaydatesHosted from './components/pages/playdates/PlaydatesHosted';
import PlaydatesRateUser from './components/pages/playdates/PlaydatesRateUser';
import PlaydatesEdit from './components/pages/playdates/PlaydatesEdit';
import PlaydatesEditImage from './components/pages/playdates/PlaydatesEditImage';
import PlaydatesEditParkSelection from './components/pages/playdates/PlaydatesEditParkSelection';
import PlaydatesInviteMoreFriends from './components/pages/playdates/PlaydatesInviteMoreFriends';

import Terms from './components/pages/about/Terms';
import Privacy from './components/pages/about/Privacy';

import OwnUserProfile from './components/pages/profiles/OwnUserProfile';
import UserProfile from './components/pages/profiles/UserProfile';
import UserDogAddDescription from './components/pages/profiles/UserDogAddDescription';
import UserDogAddDetails from './components/pages/profiles/UserDogAddDetails';
import UserDogAddPicture from './components/pages/profiles/UserDogAddPicture';
import UserDogAddBreed from './components/pages/profiles/UserDogAddBreed';
import UserDogAdd from './components/pages/profiles/UserDogAdd';

import UserDogEditDescription from './components/pages/profiles/UserDogEditDescription';
import UserDogEditDetails from './components/pages/profiles/UserDogEditDetails';
import UserDogEditName from './components/pages/profiles/UserDogEditName';
import UserDogEditBreed from './components/pages/profiles/UserDogEditBreed';

import UserDogs from './components/pages/profiles/UserDogs';
import UserPark from './components/pages/profiles/UserPark';
import UserAddress from './components/pages/profiles/UserAddress';
import OwnDogEdit from './components/pages/profiles/OwnDogEdit';
import DogProfile from './components/pages/profiles/DogProfile';
import DogGallery from './components/pages/profiles/DogGallery';
import UserGallery from './components/pages/profiles/UserGallery';

import ActivitiesDogs from './components/pages/activities/Dogs';
import Activities from './components/pages/activities/Activities';
import Notifications from './components/pages/activities/Notifications';

import Pack from './components/pages/friends/Pack';
import Friends from './components/pages/friends/Friends';
import FindFriends from './components/pages/friends/FindFriends';
import FriendRequest from './components/pages/friends/FriendRequest';

import Logout from './components/pagesDesktop/Logout/Logout';

import Chat from './components/pages/chat/Chat';
import ChatCreate from './components/pages/chat/ChatCreate';
import ChatConfirm from './components/pages/chat/ChatConfirm';
import ChatRoom from './components/pages/chat/ChatRoom';

import MoreMenu from './components/pages/settings/MoreMenu';
import Settings from './components/pages/settings/Settings';

import MissingDogs from './components/pages/missing/MissingDogs';
import ReportMissingDogs from './components/pages/missing/ReportMissingDogs';
import ReportMissingDogsDetails from './components/pages/missing/ReportMissingDogsDetails';
import ReportMissingDogsSelectDogs from './components/pages/missing/ReportMissingDogsSelectDogs';
import ReportMissingDogsConfirm from './components/pages/missing/ReportMissingDogsConfirm';

import ClaimLocation from './components/pages/admin/ClaimLocation';
import AdminLocationsList from './components/pages/admin/AdminLocationsList';
import AdminLocation from './components/pages/admin/AdminLocation';
import AdminAdministratorsList from './components/pages/admin/AdminAdministratorsList';
import AdminAdministratorsListAdd from './components/pages/admin/AdminAdministratorsListAdd';
import AdminAdministratorsListAddUser from './components/pages/admin/AdminAdministratorsListAddUser';
import AdminAdministratorsListAddLocation from './components/pages/admin/AdminAdministratorsListAddLocation';
import AdminUsersList from './components/pages/admin/AdminUsersList';
import AdminContactUsList from './components/pages/admin/AdminContactUsList';
import AdminContactUsProfile from './components/pages/admin/AdminContactUsProfile';
import AdminStats from './components/pages/admin/AdminStats';

import PrivateRoute from './PrivateRoute';
import NotFound from './components/layout/NotFound';
import Slug from './components/hoc/Slug';
import Config from './components/helpers/Config';

class Mobile extends React.Component {
    render() {
        let leftanimation = 50;
        const classname = 'switch-wrapper';
        if (this.props.location.pathname === '/search') {
            leftanimation = 0;
        }

        return (
            <AnimatedSwitch atEnter={{ left: leftanimation }} atLeave={{}} atActive={{ left: 0 }} className={classname}>
                {/* <Route {...this.props} path="/importer" component={Importer}/> */}
                <Route
                    exact
                    path="/"
                    component={() => (!!this.props.credentials && !!this.props.credentials.token
                        ? <Redirect to="/dogs" />
                        : (
                            Config.isMobileApp
                                ? (<Index />)
                                : (<Redirect to="/dogs" />)
                        ))}
                />

                {/* ABOUT US SECTIONS */}
                <Route {...this.props} path="/terms" component={Terms} />
                <Route {...this.props} path="/privacy" component={Privacy} />

                {/* REGISTER LOGIN BLOCK */}
                <Route exact path="/register" component={Register} />
                <Route exact path="/registeruser" component={RegisterUser} />
                <Route exact path="/registeremail" component={RegisterEmail} />
                <Route exact path="/registerpassword" component={RegisterPassword} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/loginemail" component={LoginEmail} />
                <Route exact path="/forgot" component={LoginForgotPassword} />
                <Route exact path="/reset" component={LoginReset} />

                {/* SEARCH LOCATIONS BLOCK */}
                <PrivateRoute {...this.props} path="/search" component={MainSearch} />
                <PrivateRoute {...this.props} path="/welcome" component={WelcomeExplore} />
                <PrivateRoute {...this.props} path="/categories" component={FilterCategory} />
                <PrivateRoute {...this.props} path="/searchlist" component={ListSearch} />

                {/* LOCATIONS SECTION */}
                <PrivateRoute {...this.props} path="/location/add/details" component={AddLocationDetails} />
                <PrivateRoute {...this.props} path="/location/add/address" component={AddLocationAddress} />
                <PrivateRoute {...this.props} path="/location/add" component={AddLocationType} />

                {/* <PrivateRoute {...this.props} path="/checkin" component={CheckInList}/> */}
                <PrivateRoute {...this.props} path="/location/gallery/:id/:name" component={LocationGallery} />
                <PrivateRoute {...this.props} path="/location/checkin/:id/:name" component={LocationCheckIn} />
                <PrivateRoute {...this.props} path="/location/review/:id/:name" component={LocationReview} />
                <PrivateRoute {...this.props} path="/location/profile/:id/:name" component={LocationProfile} />

                {/* PLAYDATES */}
                <PrivateRoute {...this.props} path="/events/create/event/dogselection" component={PlaydatesCreateDogSelection} />
                <PrivateRoute {...this.props} path="/events/create/event/locationselection" component={PlaydatesCreateParkSelection} />
                <PrivateRoute {...this.props} path="/events/create/event/images" component={PlaydatesCreateImages} />
                <PrivateRoute {...this.props} path="/events/create/event/friends" component={PlaydatesCreateFriendSelection} />
                <PrivateRoute {...this.props} path="/events/create/event" component={PlaydatesCreate} />
                <PrivateRoute {...this.props} path="/events/manage/event/:id/locationselection" component={PlaydatesEditParkSelection} />
                <PrivateRoute {...this.props} path="/events/manage/event/:id/image" component={PlaydatesEditImage} />
                <PrivateRoute {...this.props} path="/events/manage/event/:id" component={PlaydatesEdit} />
                <PrivateRoute {...this.props} path="/events/manage/event" component={PlaydatesHosted} />
                <PrivateRoute {...this.props} path="/events/invite/:id" component={PlaydatesInviteMoreFriends} />
                <PrivateRoute {...this.props} path="/events/rate/:id" component={PlaydatesRateUser} />
                <PrivateRoute {...this.props} path="/events/:id" component={PlaydatesProfile} />
                <PrivateRoute {...this.props} path="/events" component={PlaydatesHome} />

                {/* PROFILES */}
                <PrivateRoute {...this.props} path="/user/park" component={UserPark} />
                <PrivateRoute {...this.props} path="/user/address" component={UserAddress} />
                <PrivateRoute {...this.props} path="/user/dogadd/description" component={UserDogAddDescription} />
                <PrivateRoute {...this.props} path="/user/dogadd/details" component={UserDogAddDetails} />
                <PrivateRoute {...this.props} path="/user/dogadd/picture" component={UserDogAddPicture} />
                <PrivateRoute {...this.props} path="/user/dogadd/breed" component={UserDogAddBreed} />
                <PrivateRoute {...this.props} path="/user/dogadd" component={UserDogAdd} />

                <PrivateRoute {...this.props} path="/user/dogs" component={UserDogs} />
                <PrivateRoute {...this.props} path="/user/profile/:id/edit" component={OwnUserProfile} />
                <PrivateRoute {...this.props} path="/user/profile/:id/gallery" component={UserGallery} />
                <PrivateRoute {...this.props} path="/user/profile/:id" component={UserProfile} />

                <PrivateRoute {...this.props} path="/dog/:id/edit/description" component={UserDogEditDescription} />
                <PrivateRoute {...this.props} path="/dog/:id/edit/details" component={UserDogEditDetails} />
                <PrivateRoute {...this.props} path="/dog/:id/edit/name" component={UserDogEditName} />
                <PrivateRoute {...this.props} path="/dog/:id/edit/breed" component={UserDogEditBreed} />
                <PrivateRoute {...this.props} path="/dog/:id/gallery" component={DogGallery} />
                <PrivateRoute {...this.props} path="/dog/:id/edit" component={OwnDogEdit} />
                <PrivateRoute {...this.props} path="/dog/:id" component={DogProfile} />

                {/* ACTIVITIES */}
                <PrivateRoute {...this.props} path="/activity/:id" component={Activities} />
                <PrivateRoute {...this.props} path="/activities" component={Activities} />
                <PrivateRoute {...this.props} path="/dogs" component={ActivitiesDogs} />
                <PrivateRoute {...this.props} path="/notifications" component={Notifications} />

                {/* CHAT */}
                <PrivateRoute {...this.props} path="/chat/channel/:id" component={ChatRoom} />
                <PrivateRoute {...this.props} path="/chat" component={Chat} />
                <PrivateRoute {...this.props} path="/chatcreate" component={ChatCreate} />
                <PrivateRoute {...this.props} path="/chatconfirm" component={ChatConfirm} />

                {/* FRIENDS */}
                <PrivateRoute {...this.props} path="/pack" component={Pack} />
                <PrivateRoute {...this.props} path="/friends" component={Friends} />
                <PrivateRoute {...this.props} path="/friendrequest" component={FriendRequest} />
                <PrivateRoute {...this.props} path="/findfriends" component={FindFriends} />

                {/* MISSING DOGS */}
                <PrivateRoute {...this.props} path="/reportmissing/confirm" component={ReportMissingDogsConfirm} />
                <PrivateRoute {...this.props} path="/reportmissing/details" component={ReportMissingDogsDetails} />
                <PrivateRoute {...this.props} path="/reportmissing/selectdogs" component={ReportMissingDogsSelectDogs} />
                <PrivateRoute {...this.props} path="/reportmissing" component={ReportMissingDogs} />
                <PrivateRoute {...this.props} path="/missingdogs" component={MissingDogs} />

                {/* LOGOUT */}
                <Route exact path="/logout" component={Logout} />

                {/* MORE */}
                <PrivateRoute {...this.props} path="/more" component={MoreMenu} />
                <PrivateRoute {...this.props} path="/settings" component={Settings} />
                <PrivateRoute {...this.props} path="/favorites" component={Favorites} />

                {/* ADMIN */}
                <PrivateRoute {...this.props} path="/claimlocation/:id/:name" component={ClaimLocation} />
                <PrivateRoute {...this.props} path="/managelocation/:id/:name" component={AdminLocation} />
                <PrivateRoute {...this.props} path="/managelocation" component={AdminLocationsList} />
                <PrivateRoute {...this.props} path="/manageadministrators/add/user" component={AdminAdministratorsListAddUser} />
                <PrivateRoute {...this.props} path="/manageadministrators/add/location" component={AdminAdministratorsListAddLocation} />
                <PrivateRoute {...this.props} path="/manageadministrators/add" component={AdminAdministratorsListAdd} />
                <PrivateRoute {...this.props} path="/manageadministrators" component={AdminAdministratorsList} />
                <PrivateRoute {...this.props} path="/manageusers" component={AdminUsersList} />
                <PrivateRoute {...this.props} path="/managecontactus/:id" component={AdminContactUsProfile} />
                <PrivateRoute {...this.props} path="/managecontactus" component={AdminContactUsList} />
                <PrivateRoute {...this.props} path="/managestats" component={AdminStats} />

                <Route exact path="/404" component={NotFound} status={404} />
                <Route component={Slug} />
            </AnimatedSwitch>
        );
    }
}

function mapStateToProps(state) {
    return {
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(Mobile);
