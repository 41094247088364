import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Avatar from '../layout/UI/Avatar';
import Spinner from '../layout/UI/Spinner';
import { guard, listDogBreeds } from '../helpers/Functions';

class PetList extends React.Component {
    dogRenderList = null;

    dogMap(dogs) {
        const sex = [];
        this.dogRenderList = dogs.map((dog, index) => (
            <div className="participantListItem wrapper noPadding" key={index}>
                <Link to={'/dog/' + dog.dog_id + (this.props.edit ? '/edit' : '') + (this.props.redirect || '')} onClick={e => (this.props.onClick ? this.props.onClick(e, { id: dog.dog_id, name: dog.dog_name }) : '')} className="dog row">
                    <div className={'round-avatar dog dog-list ' + this.props.avatarClass || ''}>
                        <Avatar
                            src={guard(dog, '.images.0.image_smallurl')}
                            alt={dog.dog_name}
                            type="dog"
                        />
                    </div>
                    <div className={'info ' + this.props.color}>
                        <div className={'name ' + (!!dog.dogaddress && !dog.dogaddress.dogaddress_found ? 'fontRed' : (this.props.color || 'fontGreen'))}>{dog.dog_name} {!!dog.dogaddress && !dog.dogaddress.dogaddress_found ? ' (Missing)' : ''} </div>
                        <div className="race">{dog && dog.breeds && dog.breeds.length && listDogBreeds(dog.breeds)}</div>
                        <div className="sex">
                            {!!dog.dog_sex && sex.push(dog.dog_sex.charAt(0).toUpperCase() + dog.dog_sex.slice(1)) && ''}
                            {!!dog.dog_weight && sex.push(parseFloat(dog.dog_weight).toFixed(2) + ' lbs') && ''}
                            {!!dog.dog_fixed && sex.push('Fixed') && ''}
                            {!dog.dog_fixed && sex.push('Not Fixed') && ''}
                            {sex.join(', ')}
                            {sex.splice(0, sex.length) && ''}
                        </div>
                    </div>
                    {/*  Add follow button here */}
                    {this.props.markSelected
                        && (
                            <div className="next fontGreen">
                                <span className="select-dog-circle" />
                                {!!this.props.selectedDogs && !!this.props.selectedDogs.length && (this.props.selectedDogs.filter(e => e === dog.dog_id).length > 0) && (
                                    <i className={'icn icon-check-mark ' + (this.props.color)} />
                                )}
                            </div>
                        )}
                    {this.props.edit && (
                        <div className="smallWideButtons whiteBorder whiteOpaque fontWhite">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Edit&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    )}
                </Link>
            </div>
        ));
        return this.dogRenderList;
    }

    render() {
        return (
            <div className="participantList dog-list">
                {this.props.dogs === null
                    ? (<Spinner />)
                    : (this.props.dogs?.length === 0 && (
                        <p className="fontGrey">
                            You haven't added any dogs to your profile.
                        </p>
                    ))}
                {this.props.dogs && this.dogMap(this.props.dogs)}
            </div>
        );
    }
}

PetList.propTypes = {
    dogs: PropTypes.instanceOf(Array),
    onClick: PropTypes.func,
    edit: PropTypes.bool,
    redirect: PropTypes.string,
    color: PropTypes.string,
    avatarClass: PropTypes.string,
    markSelected: PropTypes.bool,
    selectedDogs: PropTypes.instanceOf(Array)
};

export default PetList;
