import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * Component that show a low resolution image at the  first load, and then it change to a higher resolution
 *
 * @param {string} image the url of the high resolution image that we want to show
 * @param {string} imageLow the url of the low resolution image that we want to show
 * @param {string} alt the alt description of the image
 * @param {int} keyval used for the GalleryLightBox component as an index for the images to show
 */
const BlurImageLoader = ({ imageLow, image, alt, keyval }) => {
    const runOnce = true;
    const [loadState, setLoadState] = useState({
        src: imageLow,
        loaded: false
    });

    useEffect(() => {
        const img = new Image();
        img.onload = () => {
            setLoadState({
                src: img.src,
                loaded: true
            });
        };
        img.src = image;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [runOnce]);

    return (<img src={loadState.src} alt={alt} keyval={keyval} />);
};

BlurImageLoader.propTypes = {
    image: PropTypes.string.isRequired,
    imageLow: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    keyval: PropTypes.number.isRequired
};

export default BlurImageLoader;
