import React from 'react';
import { connect } from 'react-redux';
import { setEmail, forgotPassword } from '../../redux/actions/users';
import ApiError from '../../helpers/ApiError';

class ModalForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pause: false
        };
    }

    verifyData = e => {
        e.preventDefault();
        const that = this;
        if (this.state.pause) return;

        if (!this.props.userData.user_email) {
            ApiError('Please write your Email!');
        } else {
            this.setState({ pause: true });
            this.props.dispatch(forgotPassword(this.props.userData.user_email))
                .then(() => {
                    that.setState({ pause: false });
                    that.props.handleClose();
                    that.props.showModalConfirmPasswordReset();
                })
                .catch(err => {
                    ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                    that.setState({ pause: false });
                });
        }
    }

    render() {
        return (
            <div className="modal display-block">
                <section className="modalForgotPasswordDesktop">
                    <div className="forgotPasswordModalContainer">
                        <section className="modalTopSection">
                            <h1>Password Recovery</h1>
                            <i className="icn icon-left-arrow" onClick={this.props.showModalDesktopLogin} />
                        </section>
                        <form onSubmit={this.verifyData}>
                            <div className="innerLabelInput">
                                <span className="blocking-span">
                                    <input className="inputText" id="userEmail" name="userEmail" type="email" value={this.props.userData.user_email || ''} onChange={e => this.props.dispatch(setEmail(e.target.value))} required />
                                    <span className="floating-label">Email address</span>
                                </span>
                            </div>
                            <input type="submit" value="Send Password Recovery &gt;" className="rightRoundButton whiteBorder whiteOpaque fontWhite" />
                        </form>
                    </div>
                </section>
                <i className="icn icon-cross fontWhite" onClick={this.props.handleClose} />
                <div className="wholeScreen" onClick={this.props.handleClose} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(ModalForgotPassword);
