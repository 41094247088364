import React from 'react';
import { connect } from 'react-redux';
import DatePicker from 'rmc-date-picker';
import moment from 'moment';
import ReactGA from 'react-ga';

import 'rmc-picker/assets/index.css';
import 'rmc-date-picker/assets/index.css';

import {
    setMissingDogReward,
    setMissingDogCity,
    setMissingDogState,
    setMissingDogCountry,
    setMissingDogPostal,
    setMissingDogStreet1,
    setMissingDogStreet2,
    setMissingDogAdditional,
    setMissingDogDate,
    setMissingDogTime
} from '../../redux/actions/dogs';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import ApiError from '../../helpers/ApiError';
import { getGeoLocation } from '../../helpers/GeoLocation';
import { formatMonth, formatDay, guard } from '../../helpers/Functions';
import CountriesStates from '../../helpers/CountriesStates';

class ReportMissingDogsDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listOfCountries: [],
            listOfStates: [],
            countrySelectedID: -1,
            stateSelectedID: -1
        };
    }

    getGeoLocation = getGeoLocation.bind(this)

    componentDidMount() {
        window.scrollTo(0, 0);

        let country = !!this.props.missingDogs && !!this.props.missingDogs.country && this.props.missingDogs.country;
        let state = !!this.props.missingDogs && !!this.props.missingDogs.state && this.props.missingDogs.state;

        if (!!this.props.missingDogs && !this.props.missingDogs.street1 && !!this.props.userData.address && !!this.props.userData.address.address_street) {
            this.props.dispatch(setMissingDogStreet1(this.props.userData.address.address_street));
        }
        if (!!this.props.missingDogs && !this.props.missingDogs.street2 && !!this.props.userData.address && !!this.props.userData.address.address_street2) {
            this.props.dispatch(setMissingDogStreet2(this.props.userData.address.address_street2));
        }
        if (!!this.props.missingDogs && !this.props.missingDogs.city && !!this.props.userData.address && !!this.props.userData.address.address_city) {
            this.props.dispatch(setMissingDogCity(this.props.userData.address.address_city));
        }
        if (!!this.props.missingDogs && !this.props.missingDogs.state && !!this.props.userData.address && !!this.props.userData.address.address_state) {
            state = this.props.userData.address.address_state;
            this.props.dispatch(setMissingDogState(this.props.userData.address.address_state));
        }
        if (!!this.props.missingDogs && !this.props.missingDogs.country && !!this.props.userData.address && !!this.props.userData.address.address_country) {
            country = this.props.userData.address.address_country;
            this.props.dispatch(setMissingDogCountry(this.props.userData.address.address_country));
        }
        if (!!this.props.missingDogs && !this.props.missingDogs.postal && !!this.props.userData.address && !!this.props.userData.address.address_zipcode) {
            this.props.dispatch(setMissingDogPostal(this.props.userData.address.address_zipcode));
        }
        this.countriesDropdownList();
        this.searchDropdownLocations(country, state);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    verifyData = e => {
        e.preventDefault();
        if (!!this.props.missingDogs.street1 && !!this.props.missingDogs.city && !!this.props.missingDogs.state && !!this.props.missingDogs.country) {
            this.props.history.push('/reportmissing/confirm');
        } else {
            ApiError('Please write your Address, Street 1, City, Country and State/Province');
        }
    }

    onDateChange = date => {
        this.props.dispatch(setMissingDogDate(date));
    }

    onTimeChange = hour => {
        this.props.dispatch(setMissingDogTime(hour));
    }

    getLocationData = e => {
        e.preventDefault();
        getGeoLocation(this.props, this.showLocation, this.props.credentials.token, true, 1500);
    }

    showLocation = () => {
        if (guard(this.props.userLocation, '.address_street')) {
            this.props.dispatch(setMissingDogStreet1(this.props.userLocation.address_street));
        }
        if (guard(this.props.userLocation, '.address_street2')) {
            this.props.dispatch(setMissingDogStreet2(this.props.userLocation.address_street2));
        }
        if (guard(this.props.userLocation, '.address_city')) {
            this.props.dispatch(setMissingDogCity(this.props.userLocation.address_city));
        }
        if (guard(this.props.userLocation, '.address_state')) {
            this.props.dispatch(setMissingDogState(this.props.userLocation.address_state));
        }
        if (guard(this.props.userLocation, '.address_country')) {
            this.props.dispatch(setMissingDogCountry(this.props.userLocation.address_country));
        }
        if (guard(this.props.userLocation, '.address_zipcode')) {
            this.props.dispatch(setMissingDogPostal(this.props.userLocation.address_zipcode));
        }
        this.searchDropdownLocations(guard(this.props.userLocation, '.address_country'), guard(this.props.userLocation, '.address_state'));
    }

    /**
     * Check the `CountriesStates` list and compare against the `country` received to update the state of the application with the country
     * and after that update show the right list of state/provinces according to the country selected updating the state of the application with the state selected
     *
     * @param {string} country
     * @param {string} state
     */
    searchDropdownLocations = (country, state) => {
        for (const i in CountriesStates) {
            if (CountriesStates[i].country === country) {
                this.setState({ countrySelectedID: i });
                this.stateProvince(i);
                for (const j in CountriesStates[i].states) {
                    if (CountriesStates[i].states[j] === state || (CountriesStates[i].short && CountriesStates[i].short[j] === state)) {
                        this.setState({ stateSelectedID: j });
                        break;
                    }
                }
                break;
            }
        }
    }

    countriesDropdownList = () => {
        const countries = CountriesStates.map((location, index) => (
            <option key={index} value={index}>
                {location.country}
            </option>
        ));
        this.setState({ listOfCountries: countries });
    }

    stateProvince = selected => {
        if (selected > -1) {
            const states = CountriesStates[selected].states.map((location, index) => (
                <option key={index} value={index}>
                    {location}
                </option>
            ));
            this.setState({ listOfStates: states });
        }
    }

    changeState = selState => {
        if (selState > -1) {
            if (CountriesStates[this.state.countrySelectedID].short && CountriesStates[this.state.countrySelectedID].short[selState]) {
                this.props.dispatch(setMissingDogState(CountriesStates[this.state.countrySelectedID].short[selState]));
            } else {
                this.props.dispatch(setMissingDogState(CountriesStates[this.state.countrySelectedID].states[selState]));
            }
            this.setState({ stateSelectedID: selState });
        } else {
            this.props.dispatch(setMissingDogState(false));
            this.setState({ stateSelectedID: -1 });
        }
    }

    changeCountry = selCountry => {
        if (selCountry > -1) {
            this.props.dispatch(setMissingDogCountry(CountriesStates[selCountry].country));
            this.props.dispatch(setMissingDogState(false));
            this.setState({ countrySelectedID: selCountry, stateSelectedID: -1, listOfStates: [] }, () => {
                this.stateProvince(selCountry);
            });
        } else {
            this.props.dispatch(setMissingDogCountry(false));
            this.props.dispatch(setMissingDogState(false));
            this.setState({ listOfStates: [], countrySelectedID: -1, stateSelectedID: -1 }, () => {
                this.stateProvince(selCountry);
            });
        }
    }

    render() {
        return (
            <div className="mainAppContainer">
                <MainNavigation {...this.props} title="Location Info" leftButton="back" />
                <div className="bodyContent backgroundRed wrapper">
                    <div className="maxWidth">
                        <h1 className="section fontWhite">
                            Please provide some basic details for your missing dog(s).
                        </h1>
                    </div>
                    <div className="maxWidth">
                        <form onSubmit={this.verifyData}>
                            <p className="fontWhite">Reward (Optional)</p>
                            <div className="innerLabelInput">
                                <span className="blocking-span">
                                    <input className="setReward inputText" name="reward" type="text" onChange={e => this.props.dispatch(setMissingDogReward(e.target.value))} value={guard(this.props.missingDogs, '.reward') || ''} />
                                    {this.props.missingDogs.selectedDogs.length === 1 && (
                                        <span className="floating-label">Amount</span>
                                    )}
                                    {this.props.missingDogs.selectedDogs.length === 2 && (
                                        <span className="floating-label">Total Amount(For Both)</span>
                                    )}
                                    {this.props.missingDogs.selectedDogs.length > 2 && (
                                        <span className="floating-label">Total Amount(For All)</span>
                                    )}
                                </span>
                            </div>
                            {this.props.missingDogs.selectedDogs.length === 1 && (
                                <div className="section fontWhite">Date your dog went missing?</div>
                            )}
                            {this.props.missingDogs.selectedDogs.length > 1 && (
                                <div className="section fontWhite">Date your dogs went missing?</div>
                            )}
                            <div className="selectDate fontWhite">
                                <DatePicker
                                    mode="date"
                                    date={(this.props.missingDogs && this.props.missingDogs.date) ? moment(this.props.missingDogs.date)._d : moment()._d}
                                    maxDate={moment()._d}
                                    onDateChange={this.onDateChange}
                                    use12Hours={true}
                                    formatMonth={formatMonth}
                                    formatDay={formatDay}
                                />
                            </div>
                            {this.props.missingDogs.selectedDogs.length === 1 && (
                                <div className="section fontWhite">Time your dog went missing?</div>
                            )}
                            {this.props.missingDogs.selectedDogs.length > 1 && (
                                <div className="section fontWhite">Time your dogs went missing?</div>
                            )}
                            <div className="selectDate fontWhite">
                                <DatePicker
                                    mode="time"
                                    date={(this.props.missingDogs && this.props.missingDogs.time) ? moment(this.props.missingDogs.time)._d : moment()._d}
                                    minuteStep={5}
                                    onDateChange={this.onTimeChange}
                                    use12Hours={true}
                                    formatMonth={formatMonth}
                                />
                            </div>
                            <div className="wideButtons whiteBorder whiteOpaque fontWhite" onClick={event => this.getLocationData(event)}>
                                Use Current Location &gt;
                            </div>
                            <div className="wideButtons transparent fontWhite">
                                Or write the address
                            </div>
                            <div className="innerLabelInput">
                                <span className="blocking-span">
                                    <input
                                        className="inputText"
                                        name="street1"
                                        type="text"
                                        onChange={e => this.props.dispatch(setMissingDogStreet1(e.target.value))}
                                        required
                                        value={guard(this.props.missingDogs, '.street1') || ''}
                                    />
                                    <span className="floating-label">Street Address 1</span>
                                </span>
                            </div>
                            <div className="innerLabelInput">
                                <span className="blocking-span">
                                    <input
                                        className="inputText"
                                        name="street2"
                                        type="text"
                                        onChange={e => this.props.dispatch(setMissingDogStreet2(e.target.value))}
                                        value={guard(this.props.missingDogs, '.street2') || ''}
                                    />
                                    <span className="floating-label">Street Address 2</span>
                                </span>
                            </div>
                            <div className="innerLabelInput">
                                <span className="blocking-span">
                                    <input
                                        className="inputText"
                                        name="city"
                                        type="text"
                                        onChange={e => this.props.dispatch(setMissingDogCity(e.target.value))}
                                        required
                                        value={guard(this.props.missingDogs, '.city') || ''}
                                    />
                                    <span className="floating-label">City</span>
                                </span>
                            </div>
                            <div className="innerLabelInput">
                                <span className="h6 fontWhite">Country</span>
                                <select
                                    value={this.state.countrySelectedID}
                                    className="blankMultipleChoice fontWhite"
                                    onChange={e => this.changeCountry(e.target.value)}
                                >
                                    <option value={-1}>Select a Country</option>
                                    {this.state.listOfCountries.length > 0 && this.state.listOfCountries}
                                </select>
                            </div>
                            {!!this.state.listOfStates && this.state.listOfStates.length > 0 && (
                                <div className="innerLabelInput">
                                    <span className="h6 fontWhite">State/Province</span>
                                    <select
                                        value={this.state.stateSelectedID}
                                        className="blankMultipleChoice fontWhite"
                                        onChange={e => this.changeState(e.target.value)}
                                    >
                                        <option value={-1}>Select a State/Province</option>
                                        {this.state.listOfStates}
                                    </select>
                                </div>
                            )}
                            <div className="innerLabelInput">
                                <span className="blocking-span">
                                    <input
                                        className="inputText"
                                        name="postal"
                                        type="text"
                                        onChange={e => this.props.dispatch(setMissingDogPostal(e.target.value))}
                                        value={guard(this.props.missingDogs, '.postal') || ''}
                                    />
                                    <span className="floating-label">Postal Code</span>
                                </span>
                            </div>
                            <div className="fontWhite h5">Additional Information</div>
                            <textarea
                                onChange={e => this.props.dispatch(setMissingDogAdditional(e.target.value))}
                                className="niceTextarea smallmargin"
                                placeholder="e.g. Doggy was wearing a yellow collar."
                                value={guard(this.props.missingDogs, '.additional') || ''}
                            />
                            <div className="spacerSmall" />
                            <input
                                type="submit"
                                value="Next &gt;"
                                className="rightRoundButton whiteBorder whiteOpaque fontWhite"
                            />
                        </form>
                    </div>
                </div>
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        missingDogs: state.dogs.reportMissing,
        userLocation: state.users.user.user_location
    };
}

export default connect(mapStateToProps)(ReportMissingDogsDetails);
