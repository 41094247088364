import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { setSearchFilters, getSearchFilters } from '../../redux/actions/searchFilters';

class ExploreList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            viewFull: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(getSearchFilters(this.props.credentials.token)).then(() => this.sortList(this.props.fullCategories));
    }

    filterSelection = (e, filter) => {
        e.preventDefault();
        this.props.dispatch(setSearchFilters(filter));
        if (this.props.link) {
            this.props.history.push(this.props.link);
        } else {
            this.props.history.goBack();
        }
    }

    sortList(viewAll) {
        let otherOption = null;
        let color = '';
        const list = this.props.filterList.map((item, index) => {
            let limit = 4;
            let colorClass = '';
            if (viewAll) {
                limit = 1000;
            }
            if (item.locationtype_name === this.props.selectedFilter) {
                colorClass = 'selected';
            }
            if (item.locationtype_name === 'Other' && viewAll) {
                otherOption = (
                    <li key={index} className={colorClass}>
                        <Link onClick={e => (this.filterSelection(e, item.locationtype_name))} to="#" className="fontRed">
                            {item.locationtype_name} <i className="icn icon-right-arrow fontGreen" />
                        </Link>
                    </li>
                );
            } else if (index < limit) {
                return (
                    <li key={index} className={colorClass}>
                        <Link onClick={e => (this.filterSelection(e, item.locationtype_name))} to="#" className="fontRed ">
                            {item.locationtype_name} <i className="icn icon-right-arrow fontGreen" />
                        </Link>
                    </li>
                );
            }
            return null;
        });
        list.push(otherOption);
        if (this.props.selectedFilter === 'all') {
            color = 'selected';
        }
        list.unshift(
            <li key="all" className={color}>
                <Link onClick={e => (this.filterSelection(e, 'all'))} to="#" className="fontRed">
                    All <i className="icn icon-right-arrow fontGreen" />
                </Link>
            </li>
        );
        this.setState({ list });
    }

    render() {
        return (
            <div className="bodyContent">
                <h3 className="fontGreen title">Explore Nearby</h3>
                <ul className="list">
                    {this.state.list}
                    {!this.props.fullCategories && !this.state.viewFull && (
                        <li><Link to="#" onClick={e => { e.preventDefault(); this.setState({ viewFull: true }); this.sortList(true); }}>...More Categories<i className="icn icon-right-arrow fontGreen" /></Link></li>
                    )}
                </ul>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        filterList: state.searchFilters.filterList,
        selectedFilter: state.searchFilters.selectedFilter
    };
}

export default connect(mapStateToProps)(ExploreList);
