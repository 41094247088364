import React from 'react';
import { Link } from 'react-router-dom';
import DatePicker from 'rmc-date-picker';
import Textarea from 'react-textarea-autosize';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactGA from 'react-ga';

import 'rmc-picker/assets/index.css';
import 'rmc-date-picker/assets/index.css';

import {
    pc_comment,
    pc_toggleInvitation,
    pc_changeDate,
    pc_changeTime,
    pc_type,
    pc_title,
    // pc_changeEndDate,
    // pc_changeEndTime,
    pc_additionalinfo
    // pc_images
} from '../../redux/actions/playdates';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import { PlaydateNavigation } from '../../layout/navigation/PlaydateNavigation';
import BasicAlert from '../../layout/UI/BasicAlert';
import { formatMonth, formatDay } from '../../helpers/Functions';

class PlaydateCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            maxDate: moment().add(2, 'years')._d,
            minDate: moment()._d
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    onDateChange = date => {
        this.props.dispatch(pc_changeDate(date));
    }

    onTimeChange = hour => {
        this.props.dispatch(pc_changeTime(hour));
    }

    verify = e => {
        if ((!this.props.pc.selectedDogs || !this.props.pc.selectedDogs.length) && this.props.pc.type === 'playdate') {
            e.preventDefault();
            BasicAlert('Please choose a Dog for your ' + this.props.pc.type);
            return;
        }
        if (!this.props.pc.selectedPark || !this.props.pc.selectedPark.name) {
            e.preventDefault();
            BasicAlert('Please choose a Location for your ' + this.props.pc.type);
        }
    }

    setEventType = e => {
        this.props.dispatch(pc_type(e.target.value));
    }

    setAdditionalInfo = e => {
        const additionalinfo = { ...this.props.pc.additionalinfo, [e.target.name]: e.target.value };
        this.props.dispatch(pc_additionalinfo(additionalinfo));
    }

    render() {
        return (
            <div className="mainAppContainer playdates">
                <MainNavigation title="Create an Event" />
                <PlaydateNavigation selected="create" />
                <div className="bodyContent wrapper">
                    <div className="maxWidth">
                        <h1 className="fontGreen">Create an Event or Playdate!</h1>
                        <div className="innerLabelInput fontDarkGray noMargin">
                            <input placeholder="Event Title..." className="inputText" name="title" type="text" onChange={e => this.props.dispatch(pc_title(e.target.value))} value={(this.props.pc && this.props.pc.title) || ''} />
                        </div>
                        <div className="spacerSmall" />
                        <h3 className="fontGreen">What do you want to create?</h3>
                        <label className="checkmarkContainer h5">This is an Event <br /><span className="h7">Invite other Users and Friends!</span>
                            <input name="event" value="event" type="checkbox" onChange={this.setEventType} checked={this.props.pc.type === 'event' ? 'checked' : ''} />
                            <span className="checkmark" />
                        </label>
                        <label className="checkmarkContainer h5">This is a Playdate <br /><span className="h7">Invite a small number of Users and Friends to a local park!</span>
                            <input name="playdate" value="playdate" type="checkbox" onChange={this.setEventType} checked={this.props.pc.type === 'playdate' ? 'checked' : ''} />
                            <span className="checkmark" />
                        </label>
                        {this.props.pc.type === 'event' && (
                            <React.Fragment>
                                <div className="spacerSmall" />
                                <h4 className="fontGreen">Does the event have an official website?</h4>
                                <div className="innerLabelInput fontDarkGray noMargin">
                                    <input placeholder="(website url)" className="inputText" name="website" type="text" onChange={this.setAdditionalInfo} value={(this.props.pc && this.props.pc.additionalinfo && this.props.pc.additionalinfo.website) || ''} />
                                </div>
                                <div className="spacerSmall" />
                            </React.Fragment>
                        )}
                        <div className="spacerSmall" />
                        <div className="selectDate">
                            <h5>Select the day:</h5>
                            <DatePicker
                                mode="date"
                                date={this.props.pc.selectedDate ? moment(this.props.pc.selectedDate)._d : moment()._d}
                                maxDate={this.state.maxDate}
                                minDate={this.state.minDate}
                                onDateChange={this.onDateChange}
                                use12Hours={true}
                                formatMonth={formatMonth}
                                formatDay={formatDay}
                            />
                        </div>
                        <div className="selectTime">
                            <h5>Select the start time:</h5>
                            <DatePicker
                                mode="time"
                                date={this.props.pc.selectedTime ? moment(this.props.pc.selectedTime)._d : moment()._d}
                                minuteStep={5}
                                onDateChange={this.onTimeChange}
                                use12Hours={true}
                                formatMonth={formatMonth}
                            />
                        </div>
                        <Link to="/events/create/event/dogselection" className="wideButtons blueBorder whiteBG fontBlue">
                            {(!this.props.pc.selectedDogs || !this.props.pc.selectedDogs.length) && (
                                <span>Select your Dog(s)</span>
                            )}
                            {!!this.props.pc.selectedDogs && !!this.props.pc.selectedDogs.length && (
                                <span>{this.props.pc.selectedDogs.length} Dog(s) Selected</span>
                            )}
                        </Link>
                        <Link to="/events/create/event/locationselection" className="wideButtons greenBorder whiteBG fontGreen">
                            {(!this.props.pc.selectedPark || !this.props.pc.selectedPark.name) && (
                                <span>Select the Location</span>
                            )}
                            {!!this.props.pc.selectedPark && !!this.props.pc.selectedPark.name && (
                                <span>{this.props.pc.selectedPark.name}</span>
                            )}
                        </Link>
                        <div className="h5">Description</div>
                        <Textarea onChange={e => (this.props.dispatch(pc_comment(e.target.value)))} className="niceTextarea" placeholder="Tell other users more information about your Event (e.g. we'll meet at the north end of the park by the fountain.)" value={this.props.pc.comment} />
                        <label className="checkmarkContainer h5">This is a private {this.props.pc.type} (Only Invited Users will be Allowed)
                            <input type="checkbox" onChange={() => this.props.dispatch(pc_toggleInvitation())} checked={this.props.pc.invitationOnly ? 'checked' : ''} />
                            <span className="checkmark" />
                        </label>
                        <Link to="/events/create/event/images" onClick={e => this.verify(e)} className="wideButtons greenBorder greenBG fontWhite">Next &gt;</Link>
                    </div>
                </div>
                <SecondaryNavigation selected="events" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        pc: state.playdates.create
    };
}

export default connect(mapStateToProps)(PlaydateCreate);
