import React from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import Config from '../../helpers/Config';

class Index extends React.Component {
    componentDidMount() {
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    render() {
        return (
            <div className="mainAppContainer LoginScreens Welcome">
                <div className="WelcomeMsg">
                    <img
                        alt="Welcome To Pooch"
                        src={Config.fileDirectory + 'assets/images/' + Config.logos.twoLines + '@2x.png'}
                        srcSet={Config.fileDirectory + 'assets/images/' + Config.logos.twoLines + '@1x.png 1x,' + Config.fileDirectory + 'assets/images/' + Config.logos.twoLines + '@2x.png 2x'}
                    />
                    <h1>The dog friendly network</h1>
                </div>
                <div className="WelcomeNavigation">
                    <Link to="/register"><div className="Button">Sign up</div></Link>
                    <Link to="/login"><div className="Button">Login</div></Link>
                </div>
            </div>
        );
    }
}

export default Index;
