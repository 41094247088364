import React from 'react';
import Helmet from 'react-helmet';

export default class HelmetTags extends React.Component {
    title = 'The App that connects your Dog to all things Dog-Friendly - Pooch';

    name = 'Pooch';

    description = 'Ratings and Reviews of Dog Friendly Parks, Pet Stores, Hotels, Shelters, Groomers, Pet Sitters, Vets, Events and More at Pooch';

    keywords = 'Pooch, Pooch App,veterinarian,dog,pet,park,shelter';

    image = 'https://pooch.pet/assets/images/pooch-logo-twoLiner@2x.png';

    favicon = 'https://pooch.pet/favicon.png';

    site = 'https://pooch.pet';

    type = 'website';

    twitterCard = 'summary';

    constructor(props) {
        super(props);
        if (this.props.title && this.props.title.length > 0) {
            if (this.props.title.length < 30) { // length minimum recommended for SEO
                this.title = this.props.title + ' | Find Parks, Stores, Fun, and More | Pooch';
            } else {
                this.title = this.props.title;
            }
        }
        if (this.props.description && this.props.description.length > 0) {
            if (this.props.description.length < 70) { // length minimum recommended for SEO
                this.description = this.props.description + ' | Search local dog businesses in your area | Pooch';
            } else {
                this.description = this.props.description;
            }
        }
        this.keywords = (this.props.keywords && this.props.keywords.length > 0 ? this.props.keywords : this.keywords);
        this.name = (this.props.name && this.props.name.length > 0 ? this.props.name : this.name);
        this.image = (this.props.image && this.props.image.length > 0 ? this.props.image : this.image);
        this.favicon = (this.props.favicon && this.props.favicon.length > 0 ? this.props.favicon : this.favicon);
        this.site = (this.props.site && this.props.site.length > 0 ? this.props.site : this.site);
        this.type = (this.props.type && this.props.type.length > 0 ? this.props.type : this.type);
        this.twitterCard = (this.props.twitterCard && this.props.twitterCard.length > 0 ? this.props.twitterCard : this.twitterCard);
    }

    render() {
        return (
            <Helmet>
                <title>{this.title}</title>
                <meta name="description" content={this.description} />
                <meta name="keywords" data-react-helmet="true" content={this.keywords} />

                <meta itemProp="name" content={this.title} />
                <meta itemProp="description" content={this.description} />
                <meta itemProp="image" content={this.image} />

                <meta name="twitter:card" content={this.twitterCard} />
                <meta name="twitter:site" content={this.site} />
                <meta name="twitter:title" content={this.title} />
                <meta name="twitter:description" content={this.description} />
                <meta name="twitter:image:src" content={this.image} />

                <meta property="og:title" content={this.title} />
                <meta property="og:type" content={this.type} />
                <meta property="og:url" content={this.site} />
                <meta property="og:image" content={this.image} />
                <meta property="og:description" content={this.description} />
                <meta property="og:site_name" content={this.name} />
            </Helmet>
        );
    }
}
