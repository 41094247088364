import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DateTime from 'react-datetime';
import Textarea from 'react-textarea-autosize';
import moment from 'moment';
import ReactGA from 'react-ga';
import { toast } from 'react-toastify';

import {
    pe_comment,
    updatePlaydate,
    pe_editing,
    pe_toggleInvitation,
    pe_changeDate,
    pe_changeTime,
    pe_togglePark,
    pe_toggleDog,
    getPlaydate,
    cancelPlaydate,
    pe_type,
    pe_title,
    pe_additionalinfo,
    pe_images_files,
    pe_images
} from '../../redux/actions/playdates';
import { uploadPicture, sendNotification } from '../../redux/actions/all';
import { getUserProfile } from '../../redux/actions/users';
import { setSearchLocation, getLocations } from '../../redux/actions/locations';
import { PlaydateNavigation } from '../../layout/navigation/PlaydateNavigation';
import NotFound from '../../layout/NotFound';
import { LocationListItem } from '../../layout/locations/LocationListItem';
import Avatar from '../../layout/UI/Avatar';
import PetList from '../../layout/PetList';
import Spinner from '../../layout/UI/Spinner';
import NavigationSearchBar from '../../layout/UI/NavigationSearchBar';
import { getGeoLocation } from '../../helpers/GeoLocation';
import { infiniteScroll } from '../../helpers/Pull2Refresh';
import ApiError from '../../helpers/ApiError';
import { guard } from '../../helpers/Functions';
import WhiteTitle from '../../hoc/WhiteTitle';
import Columns from '../../hoc/Columns';

class PlaydateEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            cancelProcess: false,
            cancelreason: '',
            invitedUsers: [],
            showDogs: false,
            showLocations: false,
            dogs: [],
            pictureModal: false,
            locationList: [],
            currentPage: 1,
            totalPages: 1, // This it's being used by infiniteScroll->handleScroll->loadMore
            image: false
            // playdateTime: ''
            // playdateDate: ''
        };
    }

    getGeoLocation = getGeoLocation.bind(this)

    componentDidMount() {
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        this.props.dispatch(getPlaydate(this.props.credentials.token, this.props.match.params.id))
            .then(response => {
                const pe = response.value.data;
                this.props.dispatch(setSearchLocation(pe.location.location_name));
                if (String(this.props.pe.editing) !== String(this.props.match.params.id)) {
                    for (let i = 0; i < pe.dogs.length; i++) {
                        this.props.dispatch(pe_editing(pe.dogs[i].dog_id));
                        this.props.dispatch(pe_toggleDog(pe.dogs[i].dog_id));
                    }
                    this.props.dispatch(pe_editing(this.props.match.params.id));
                    this.props.dispatch(pe_changeDate(pe.playdate_datetime));
                    this.props.dispatch(pe_changeTime(pe.playdate_datetime));
                    this.props.dispatch(pe_togglePark({ id: pe.location.location_id, name: pe.location.location_name }));
                    this.props.dispatch(pe_comment(pe.playdate_comment));
                    this.props.dispatch(pe_toggleInvitation(pe.playdate_isactive));
                    this.props.dispatch(pe_toggleInvitation(pe.playdate_isprivate));
                    this.props.dispatch(pe_type(pe.playdate_type));
                    this.props.dispatch(pe_title(pe.playdate_title));
                    this.props.dispatch(pe_additionalinfo(pe.playdate_additionalinfo));
                }
                if (pe.images && pe.images[0] && pe.images[0].image_smallurl) {
                    this.setState({ image: pe.images[0].image_smallurl });
                }
                this.setState({ loading: false, invitedUsers: pe.users });
            });
        this.props.dispatch(getUserProfile(this.props.credentials.token, this.props.credentials.user_id))
            .then(response => this.setState({ dogs: response.value.data.dogs }));
        this.start();
    }

    init = () => {
        this.setState({
            locationList: [],
            loading: true,
            loadingMore: false,
            currentPage: 1,
            totalPages: 1
        }, this.start());
    }

    start = () => {
        this.setState({ loading: true });
        getGeoLocation(
            this.props,
            this.getNearByLocations,
            this.props.credentials.token,
            true
        );
    }

    getNearByLocations = () => {
        const filter = 'all';
        this.props.dispatch(getLocations(this.props.credentials.token, this.props.userData.user_location, filter, this.props.searchValue, this.state.currentPage))
            .then(response => {
                this.locationList(response.value.data);
                this.setState({
                    totalPages: response.value.headers['x-pagination-page-count']
                }, () => {
                    if (this.state.currentPage < this.state.totalPages) {
                        infiniteScroll(this, this.getNearByLocations);
                    }
                });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.locationList();
            });
    }

    locationList = moreLocations => {
        this.setState(prevState => ({
            locationList: prevState.locationList.concat(moreLocations || null),
            loading: false,
            loadingMore: false
        }));
    }

    onDateChange = date => {
        this.props.dispatch(pe_changeDate(date));
    }

    onTimeChange = hour => {
        this.props.dispatch(pe_changeTime(hour));
    }

    selectDog = (e, dog) => {
        e.preventDefault();
        this.props.dispatch(pe_toggleDog(dog.id));
    }

    setEventType = e => {
        this.props.dispatch(pe_type(e.target.value));
    }

    setAdditionalInfo = e => {
        const additionalinfo = { ...this.props.pe.additionalinfo, [e.target.name]: e.target.value };
        this.props.dispatch(pe_additionalinfo(additionalinfo));
    }

    filesChanged = files => {
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            const file = files[i];
            reader.onloadend = () => {
                this.props.dispatch(pe_images_files(file));
                this.props.dispatch(pe_images(reader.result));
            };
            reader.readAsDataURL(file);
        }
    }

    clear = () => {
        document.getElementById('file').value = '';
        this.props.dispatch(pe_images());
        this.props.dispatch(pe_images_files());
    }

    toggleModal = () => {
        this.setState({ pictureModal: !this.state.pictureModal });
    }

    changeImage = () => {
        const selectfile = document.getElementById('file');
        selectfile.click();
    }

    /* handleChange = date => {
        this.setState({
            startDate: date
        });
    } */

    showDogs = () => {
        this.setState(prevState => ({
            showDogs: !prevState.showDogs
        }));
    }

    showLocations = () => {
        this.setState(prevState => ({
            showLocations: !prevState.showLocations
        }));
    }

    locationList = moreLocations => {
        this.setState(prevState => ({
            locationList: prevState.locationList.concat(moreLocations || null),
            loading: false,
            loadingMore: false
        }));
    }

    selectPark = (e, park) => {
        e.preventDefault();
        this.props.dispatch(setSearchLocation(park.name));
        this.props.dispatch(pe_togglePark(park));
    }

    uploadToProfile = imgBlob => {
        this.props.dispatch(pe_images_files(imgBlob));
    }

    toggleType = type => {
        this.props.dispatch(pe_type(type));
    }

    cancelPlaydate(e) {
        e.preventDefault();
        const data = {
            playdate_isactive: 0,
            playdate_cancelledreason: this.state.cancelreason
        };
        let receivers = this.state.invitedUsers.filter(user => !(user.user_id === String(this.props.credentials.user_id)));
        receivers = this.state.invitedUsers.map(user => ({ user_id: user.user_id }));
        const notificationData = {
            notification_title: 'Playdate Cancelled',
            notification_body: this.props.userData.user_firstname + ': ' + (this.state.cancelreason || 'Cancelled the Playdate'),
            playdate_id: this.props.pe.editing,
            sender_id: this.props.credentials.user_id,
            receivers
        };
        this.props.dispatch(cancelPlaydate(this.props.credentials.token, this.props.match.params.id, data))
            .then(() => {
                ApiError('Cancelled Playdated');
                this.props.dispatch(sendNotification(this.props.credentials.token, notificationData));
                this.props.history.goBack();
            });
    }

    saveChanges(e) {
        e.preventDefault();
        let date; let
            time;
        if (!this.props.pe.selectedDate) {
            date = moment().format('YYYY-MM-DD');
        } else {
            date = moment(this.props.pe.selectedDate).format('YYYY-MM-DD');
        }
        if (!this.props.pe.selectedTime) {
            time = moment().format('H:mm:ss');
        } else {
            time = moment(this.props.pe.selectedTime).format('H:mm:ss');
        }
        const data = {
            location_id: this.props.pe.selectedPark.id,
            playdate_datetime: date + ' ' + time,
            playdate_comment: this.props.pe.comment,
            playdate_isprivate: this.props.pe.invitationOnly ? 1 : 0,
            playdate_type: this.props.pe.type,
            playdate_title: this.props.pe.title,
            playdate_additionalinfo: this.props.pe.additionalinfo
        };
        let receivers = this.state.invitedUsers.filter(user => !(user.user_id === String(this.props.credentials.user_id)));
        receivers = this.state.invitedUsers.map(user => ({ user_id: user.user_id }));
        const notificationData = {
            notification_title: 'Event Update',
            notification_body: this.props.userData.user_firstname + ' updated the Event',
            playdate_id: this.props.pe.editing,
            sender_id: this.props.credentials.user_id,
            receivers
        };
        if (this.props.pe && this.props.pe.images_files && this.props.pe.images_files.size) {
            this.props.dispatch(uploadPicture(this.props.credentials.token, this.props.match.params.id, this.props.pe.images_files, true));
        }
        this.props.dispatch(updatePlaydate(this.props.credentials.token, this.props.match.params.id, data))
            .then(() => {
                toast.success('Updated Event!');
                this.props.dispatch(sendNotification(this.props.credentials.token, notificationData));
                this.props.history.push('/events/' + this.props.match.params.id);
            });
    }

    render() {
        const today = DateTime.moment();
        const valid = current => current.isAfter(today);
        const minuteIncrements = {
            minutes: {
                step: 5
            }
        };
        let newlocations;
        if (this.state.loading) {
            newlocations = (<Spinner />);
        } else if (this.state.locationList.length > 0) {
            newlocations = this.state.locationList.map(location => (
                <LocationListItem
                    dispatch={this.props.dispatch}
                    credentials={this.props.credentials}
                    key={location.location_id}
                    location={location}
                    url="location/profile"
                    onClick={this.selectPark}
                />
            ));
        } else {
            newlocations = (
                <NotFound
                    type="searchlocations"
                    onClear={() => {
                        this.props.dispatch(setSearchLocation(''));
                        this.init();
                    }}
                    class="backgroundRed"
                />
            );
        }

        const c1 = (
            <div className="simple-navigation">
                <PlaydateNavigation
                    selected="manage"
                />
            </div>
        );

        const c2 = (
            <React.Fragment>
                <div className="playdate-edit-playdate">
                    {this.state.loading && (<Spinner />)}
                    <WhiteTitle>Edit your {this.props.pe.type}!</WhiteTitle>
                    <div className="playdate-data">
                        <div className="spacerSmall" />
                        <h3 className="fontGreen">Please select</h3>
                        <div className="playdate-type">
                            <div className="spacerSmall" />
                            <label
                                onClick={() => this.toggleType('playdate')}
                                className="checkmarkContainer h5"
                            >
                                This is a Playdate
                                <br />
                                <span className="h7">Invite a small number of Users and Friends to a local park!</span>
                                <span className="checkmark">
                                    {this.props.pe.type === 'playdate'
                                        ? (<i className="icn icon-check-mark fontBlue" />)
                                        : (<i className="icn" />)}
                                </span>
                            </label>
                            <label
                                onClick={() => this.toggleType('event')}
                                className="checkmarkContainer h5"
                            >
                                This is an Event
                                <br />
                                <span className="h7">Invite other Users and Friends!</span>
                                <span className="checkmark">
                                    {this.props.pe.type === 'event'
                                        ? (<i className="icn icon-check-mark fontBlue" />)
                                        : (<i className="icn" />)}
                                </span>
                            </label>
                        </div>
                        <div className="edit-playdate-image">
                            <div className="image-box" onClick={this.changeImage}>
                                <Avatar
                                    src={guard(this.props.pe, '.images') || this.state.image}
                                    alt={this.props.pe.title}
                                    type="blank"
                                />
                                {!guard(this.props.pe, '.images') && !this.state.image && (
                                    <div className="select-playdate-image">
                                        <i className="icn icon-upload-photo" />
                                        <p>Click to upload cover picture</p>
                                    </div>
                                )}
                            </div>
                            {guard(this.props.pe, '.images') && this.state.image && (
                                <div className="clear-image">
                                    <i onClick={this.clear} className="icn icon-cross fontRed changeImage" />
                                </div>
                            )}
                            <input
                                className="hidden"
                                id="file"
                                type="file"
                                onChange={e => this.filesChanged(e.target.files)}
                                accept="image/*"
                                required
                            />
                        </div>
                        <div className="playdate-data-top-section">
                            <div className="playdate-title fontDarkGray">
                                <label>Event Name</label>
                                <input
                                    className="inputText"
                                    name="title"
                                    type="text"
                                    onChange={e => this.props.dispatch(pe_title(e.target.value))}
                                    value={(this.props.pe && this.props.pe.title) || ''}
                                />
                            </div>
                            <div className="playdate-time-date">
                                <div className="playdate-date fontDarkGray">
                                    <label>Date</label>
                                    <div className="select-date selectBox">
                                        <DateTime
                                            dateFormat="YYYY-MM-DD"
                                            className="dropdown-calendar"
                                            timeFormat={false}
                                            isValidDate={valid}
                                            value={this.props.pe.selectedDate ? moment(this.props.pe.selectedDate)._d : moment()._d}
                                            onChange={this.onDateChange}
                                        />
                                        <i className="icn icon-calendar fontGreen" />
                                    </div>
                                </div>
                                <div className="playdate-time fontDarkGray">
                                    <label>Time</label>
                                    <div className="select-time selectBox">
                                        <DateTime
                                            className="dropdown-time-select"
                                            dateFormat={false}
                                            value={this.props.pe.selectedTime ? moment(this.props.pe.selectedTime)._d : moment()._d}
                                            timeConstraints={minuteIncrements}
                                            onChange={this.onTimeChange}
                                        />
                                        <i className="icn icon-time fontGreen" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.props.pe.type === 'event' && (
                            <React.Fragment>
                                <div className="spacerSmall" />
                                <div className="playdate-website">
                                    <label>Does the event have an official website?</label>
                                    <input
                                        className="inputText"
                                        name="website"
                                        type="text"
                                        onChange={this.setAdditionalInfo}
                                        value={(this.props.pe && this.props.pe.additionalinfo && this.props.pe.additionalinfo.website) || ''}
                                    />
                                    <div className="spacerSmall" />
                                </div>
                            </React.Fragment>
                        )}
                        <div className="spacerSmall" />
                        <div className="playdates-select-dog-location">
                            <div className="playdates-select-dog">
                                <div className="wideButtons greenBorder whiteBG fontGreen" onClick={this.showDogs}>
                                    {(!this.props.selectedDogs || !this.props.selectedDogs.length) && (
                                        <span className="select-dogs">Select Your Dog(s) <i className="icn icon-right-arrow" /></span>
                                    )}
                                    {!!this.props.selectedDogs && !!this.props.selectedDogs.length && (
                                        <span className="select-dogs">
                                            {this.props.selectedDogs.length} Dog(s) Selected
                                        </span>
                                    )}
                                </div>
                                {this.state.showDogs && (
                                    <div className="playdates-dog-list">
                                        <PetList
                                            dogs={this.state.dogs}
                                            markSelected={true}
                                            selectedDogs={this.props.selectedDogs}
                                            onClick={this.selectDog}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="spacerSmall" />
                            <div className="playdates-select-location">
                                <div className="wideButtons greenBorder whiteBG fontGreen">
                                    <span className="select-location" onClick={this.showLocations}>
                                        <NavigationSearchBar
                                            callback={this.init}
                                            disp={this.props.dispatch}
                                            searchInputHandler={setSearchLocation}
                                            searchValue={this.props.searchValue}
                                            placeHolder="Select Location"
                                        />
                                        {!this.props.searchValue && (<i className="icn icon-right-arrow" />)}
                                    </span>
                                </div>
                                {this.state.showLocations && (
                                    <div className="playdates-location-list" onClick={this.showLocations}>
                                        <div className="add-location-banner row">
                                            <div className="location-banner-caption">
                                                <i className="icn icon-location-pin-white" />
                                                <Link to={'/location/add?redirect=/events/manage/event/' + this.props.pe.editing}>
                                                    Add a Location
                                                </Link>
                                            </div>
                                        </div>
                                        {!this.state.loadingMore && newlocations}
                                        {this.state.loadingMore && (<Spinner />)}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="spacerSmall" />
                        <div className="playdates-desciption">
                            <div className="h5">Description</div>
                            <Textarea
                                onChange={e => (this.props.dispatch(pe_comment(e.target.value)))}
                                className="niceTextarea"
                                value={this.props.pe.comment || ''}
                            />
                            <div className="playdate-private-event">
                                <label
                                    onClick={() => this.props.dispatch(pe_toggleInvitation(!this.props.pe.invitationOnly))}
                                    className="checkmarkContainer h5"
                                >
                                    This is a private {this.props.pe.type}
                                    <br />
                                    <p className="sub-text">Select this if you only want invited users to attend</p>
                                    <span className="checkmark" onClick={this.selectPrivate}>
                                        {this.props.pe.invitationOnly
                                            ? (<i className="icn icon-check-mark fontBlue" />)
                                            : (<i className="icn" />)}
                                    </span>
                                </label>
                            </div>
                        </div>
                        {!this.state.cancelProcess
                            ? (
                                <div className="row alignItems">
                                    <Link
                                        onClick={e => { e.preventDefault(); this.setState({ cancelProcess: true }); }}
                                        to="/events/manage/event"
                                        className="wideButtons redBorder redBG fontWhite"
                                    >
                                        Cancel Event
                                    </Link>
                                    <div className="flexButtons transparentBorder transparentBG fontGreen" />
                                    <Link
                                        onClick={e => this.saveChanges(e)}
                                        to="/events/manage/event"
                                        className="wideButtons greenBorder greenBG fontWhite"
                                    >
                                        Save Changes
                                    </Link>
                                </div>
                            )
                            : (
                                <div>
                                    <Textarea
                                        className="niceTextarea smallmargin"
                                        placeholder="I am cancelling because..."
                                        id="messageArea"
                                        onChange={e => this.setState({ cancelreason: e.target.value })}
                                        value={this.state.cancelreason}
                                    />
                                    <div className="row alignItems">
                                        <Link
                                            to="#"
                                            onClick={e => { e.preventDefault(); this.setState({ cancelProcess: false }); }}
                                            className="wideButtons blueBorder whiteBG fontBlue"
                                        >
                                            Go Back
                                        </Link>
                                        <div className="flexButtons transparentBorder transparentBG fontGreen" />
                                        <Link
                                            to="#decline"
                                            onClick={e => this.cancelPlaydate(e)}
                                            className="wideButtons redBorder redBG fontWhite"
                                        >
                                            Yes, Cancel Event
                                        </Link>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </React.Fragment>
        );

        const c3 = (<React.Fragment />);

        return (
            <div className="mainAppContainer playdates">
                <div className="bodyContent playdates-edit">
                    <div className="three-column-layout">
                        <Columns
                            c1={c1}
                            c2={c2}
                            c3={c3}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        pe: state.playdates.edit,
        userData: state.users.user,
        credentials: state.users.credentials,
        searchValue: state.locations.searchLocation,
        selectedDogs: state.playdates.edit.selectedDogs,
        selectedPark: state.playdates.edit.selectedPark,
        selectedDate: state.playdates.edit.selectedDate,
        selectedTime: state.playdates.edit.selectedTime,
        invitationOnly: state.playdates.edit.invitationOnly
    };
}

export default connect(mapStateToProps)(PlaydateEdit);
