export default function reducer(
    state = {
        counter: {},
        saveActivityScreenDetails: {},
        saveNotificationScreenDetails: {},
        saveDogScreenDetails: {}
    },
    action
) {
    switch (action.type) {
        case 'persist/REHYDRATE': {
            if (action.payload.activities) {
                return {
                    ...state,
                    counter: /* action.payload.activities.counter || */ {},
                    saveActivityScreenDetails: /* action.payload.activities.saveActivityScreenDetails || */ {},
                    saveNotificationScreenDetails: /* action.payload.activities.saveNotificationScreenDetails || */ {},
                    saveDogScreenDetails: {}
                };
            }
            return { ...state };
        }

        case 'SAVE_ACTIVITY_SCREEN_DETAILS': {
            return { ...state, saveActivityScreenDetails: action.payload };
        }

        case 'SAVE_NOTIFICATION_SCREEN_DETAILS': {
            return { ...state, saveNotificationScreenDetails: action.payload };
        }

        case 'SAVE_DOGS_SCREEN_DETAILS': {
            return { ...state, saveDogScreenDetails: action.payload };
        }

        case 'GET_ACTIVITY_COUNTER_FULFILLED': {
            return { ...state, counter: action.payload.data };
        }

        case 'USER_LOGOUT':
            return {
                ...state,
                counter: {},
                saveActivityScreenDetails: {},
                saveNotificationScreenDetails: {},
                saveDogScreenDetails: {}
            };

        default:
            return { ...state };
    }
}
