import { applyMiddleware, createStore, compose } from 'redux';
import { persistStore, autoRehydrate } from 'redux-persist';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';

import reducer from './reducers';

export default function configureStore() {
    let middleware;
    let composeEnhancers;

    if (process.env.REACT_APP_MODE === 'PROD') {
        middleware = applyMiddleware(promise(), thunk);
        composeEnhancers = compose;
    } else {
        if (process.env.REACT_APP_REDUX_LOG === 'true') {
            middleware = applyMiddleware(promise(), thunk, createLogger());
        } else {
            middleware = applyMiddleware(promise(), thunk);
        }
        composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    }

    return new Promise((resolve, reject) => {
        try {
            const store = createStore(
                reducer,
                // preloadedState,
                composeEnhancers(
                    autoRehydrate()
                ),
                middleware
            );
            persistStore(store, {}, () => resolve(store));
        } catch (e) {
            reject(e);
        }
    });
}
