import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { getUserProfile } from '../../redux/actions/users';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import PetList from '../../layout/PetList';
import Spinner from '../../layout/UI/Spinner';
import { Pull2Refresh } from '../../helpers/Pull2Refresh';
import ApiError from '../../helpers/ApiError';

class UserDogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            dogs: []
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.start();
        Pull2Refresh(this.start);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    start = () => {
        this.props.dispatch(getUserProfile(this.props.credentials.token, this.props.credentials.user_id))
            .then(response => this.setState({ loading: false, dogs: response.value.data.dogs }))
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loading: false });
            });
    }

    render() {
        return (
            <div className="mainAppContainer playdates">
                <MainNavigation title="Dogs" />
                <div className="bodyContent wrapper pull2refresh noPadding">
                    <div className="maxWidth">
                        <Link className="wideButtons greenBorder greenBG fontWhite spacerVertical" to="/user/dogadd"><i className="icn icon-more-add" /> Add a Dog!</Link>
                        <div className="boxContainer">
                            <div className="boxTitle row">
                                <div className="title">Dog(s)</div>
                            </div>
                        </div>
                        {this.state.loading && (<Spinner />)}
                        {!!this.state.dogs.length && !this.state.loading && (<PetList dogs={this.state.dogs} />)}
                    </div>
                </div>
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(UserDogs);
