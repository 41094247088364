import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import parse from 'html-react-parser';
import ReactModal from 'react-modal';

import { sendRequest, updateRequest, blockRequest, userDelete, updateUserRole } from '../../redux/actions/users';
import { getActivities } from '../../redux/actions/activities';
import { deletePicture, setPicture } from '../../redux/actions/all';
import PetList from '../../layout/PetList';
import MainNavigation from '../../layout/navigation/MainNavigation';
import Avatar from '../../layout/UI/Avatar';
import BasicConfirm from '../../layout/UI/BasicConfirm';
import GalleryLightbox from '../../layout/gallery/GalleryLightbox';
import { ActivityListItem } from '../../layout/ActivityListItem';
import Spinner from '../../layout/UI/Spinner';
import SocialMediaIcons from '../../layout/UI/SocialMediaIcons';
import UserSummary from '../../layout/UI/UserSummary';
import AdvancedConfirm from '../../layout/UI/AdvancedConfirm';
import ApiError from '../../helpers/ApiError';
import sb from '../../helpers/SendBird';
import { guard, urlFriendly } from '../../helpers/Functions';
import { infiniteScroll } from '../../helpers/Pull2Refresh';
import WhiteTitle from '../../hoc/WhiteTitle';

const ROLE_SUPER_ADMIN = 'superadmin';
// const ROLE_ADMIN = 'admin'; // Used to administrate an specific location, this it's handled from "User Administrator"
const ROLE_REGULAR = 'regular';

/**
 * Component used to show information of the user on the `user` param and allow to make some actions to that user
 *
 * @param {array} dogs array of Objects with the dogs that the user displayed has added
 * @param {Object} user a JSON Object with the information of the user to show
 * @param {Object} userData a JSON Object with the information of the logged user
 * @param {Object} credentials a JSON Object with the credentials of the logged user
 * @param {function} dispatch function used to hit end-points
 * @param {Object} history react-router Object used to get information from the browser history and/or to have access to some functions
 */
export class OtherUserProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activities: [],
            loadingMore: true,
            currentPage: 1,
            totalPages: 1, // This it's being used by infiniteScroll->handleScroll->loadMore
            image: null,
            gallery: []
            // socialMedia: {},
            // bio: false
        };
    }

    componentDidMount() {
        this.getActivities();
        this.setState({
            image: this.props.user?.images?.[0]?.image_mediumurl ? this.props.user.images[0].image_mediumurl : null,
            gallery: this.props.user?.images
            // socialMedia: this.props.user.user_socialmedia,
            // bio: this.props.user.user_aboutme
        });
    }

    friendRequest = (e, id) => {
        e.preventDefault();
        this.props.dispatch(sendRequest(this.props.credentials.token, id)).catch(err => {
            ApiError(err);
        });
    }

    cancelFriendShip = (e, id) => {
        e.preventDefault();
        BasicConfirm(
            'Are you sure?',
            'Do you want to cancel your friend request?',
            'Yes, Cancel request!',
            this.handleClickCancel.bind(this, id)
        );
    }

    handleClickCancel = id => {
        this.props.dispatch(updateRequest(this.props.credentials.token, id, { friendship_status: 'cancelled' }));
    }

    unfriendRequest = (e, name, id) => {
        e.preventDefault();
        BasicConfirm(
            'Are you sure?',
            'Do you want to unfriend ' + name + '?',
            'Yes, Unfriend.',
            this.handleClickUnfriend.bind(this, id)
        );
    }

    handleClickUnfriend = id => {
        this.props.dispatch(updateRequest(this.props.credentials.token, id, { friendship_status: 'unfriended' }));
    }

    blockRequest = (e, name, id) => {
        e.preventDefault();
        if (this.props.credentials.token) {
            BasicConfirm(
                'Are you sure?',
                'Do you want to unfriend and block ' + name + '?',
                'Yes, Block.',
                this.handleClickBlock.bind(this, id)
            );
        }
    }

    handleClickBlock = id => {
        this.props.dispatch(
            blockRequest(this.props.credentials.token, id, { friendship_status: 'blocked' })
        )
            .catch(err => {
                ApiError(err);
            });
    }

    getActivities = () => {
        this.props.dispatch(getActivities(this.props.credentials.token, this.props.user.user_id, this.state.currentPage))
            .then(response => {
                this.activityList(response.value.data);
                this.setState({
                    loadingMore: false,
                    totalPages: response.value.headers['x-pagination-page-count']
                }, () => {
                    if (this.state.currentPage < this.state.totalPages) {
                        infiniteScroll(this, this.getActivities);
                    }
                });
            })
            .catch(() => {
                this.setState({ loadingMore: false });
            });
    }

    // Removes image from GalleryLightBox
    handleClickRemoveImage = id => {
        this.props.dispatch(deletePicture(this.props.credentials.token, id))
            .then(() => {
                this.setState(prevState => {
                    const newGallery = prevState.gallery.filter(image => image.image_id !== id);
                    return {
                        gallery: newGallery
                    };
                }, () => {
                    this.setState({ image: this.state?.gallery?.[0]?.image_largeurl ? this.state.gallery[0].image_largeurl : null });
                });
            })
            .catch(err => {
                ApiError(err?.response?.data?.[0]?.message || err.message);
            });
    }

    toggleModal = () => {
        if (this.props.credentials?.token) {
            this.setState(prevState => ({ manageAccount: !prevState.manageAccount }));
        } else {
            ApiError('You need to log in or sign up.');
        }
    }

    deleteAccount = (token, userId) => {
        const headerText = 'Are you sure you want to close this account?';
        const bodyText = 'Once it\'s closed, the user won\'t be able to use this Pooch account, and all their account data and uploaded pictures will be removed. It can\'t be reopened, and we can\'t restore it. Please type "REMOVE ACCOUNT" below to confirm.';
        AdvancedConfirm(
            headerText,
            parse(bodyText),
            'REMOVE ACCOUNT',
            'Remove it',
            () => {
                console.log('Deleting account:', userId);
                this.setState({ loadingMore: true });
                this.props.dispatch(userDelete(token, userId))
                    .then(() => {
                        toast.success('Account deleted successfully.');
                        this.setState({ loadingMore: false });
                        this.props.history.push('/manageusers');
                    })
                    .catch(err => {
                        console.log('deleteAccount:err:', err);
                        this.setState({ loadingMore: false });
                        ApiError('Something went wrong... Please try again later.');
                    });
            }
        );
    };

    updateRole = (token, userId, role) => {
        const data = { user_role: role, user_firstname: 'Yuusuke' };
        this.setState({ loadingMore: true });
        this.props.dispatch(updateUserRole(token, userId, data))
            .then(() => {
                this.setState({ loadingMore: false });
                toast.success('User role updated successfully.');
            })
            .catch(err => {
                this.setState({ loadingMore: false });
                console.log('updateRole:err:', err);
                ApiError(err?.response?.data?.[0]?.message || err.message);
            });
    }

    handleClickSetImage = id => {
        this.props.dispatch(setPicture(this.props.credentials.token, id))
            .then(res => {
                let newFeaturedImage = {};
                // we push the new featured image to the top of the list, on the first position
                this.setState(prevState => {
                    const newGallery = prevState.gallery.filter(image => {
                        if (image.image_id === id) newFeaturedImage = image;
                        return image.image_id !== id;
                    });
                    newGallery.sort((a, b) => b.image_id - a.image_id);
                    return {
                        gallery: [newFeaturedImage, ...newGallery],
                        image: res.value.data.image_largeurl
                    };
                });
                toast.success('Profile Image Set Successfully!');
            })
            .catch(err => {
                ApiError(err?.response?.data?.[0]?.message || err.message);
            });
    }

    acceptRequest(e, id) {
        e.preventDefault();
        this.props.dispatch(updateRequest(this.props.credentials.token, id, { friendship_status: 'accepted' }));
    }

    unblockRequest(e, id) {
        e.preventDefault();
        this.props.dispatch(updateRequest(this.props.credentials.token, id, { friendship_status: 'cancelled' }));
    }

    chatWithUser(e, userId) {
        e.preventDefault();
        const roomName = this.props.user.user_firstname;
        const that = this;
        if (this.props.credentials.token) {
            sb.connect(
                this.props.credentials.user_id,
                this.props.credentials.token,
                () => {
                    sb.GroupChannel.createChannelWithUserIds([userId], true, roomName, null, null, (createdChannel, error) => {
                        if (error) {
                            toast.error('Sorry, there was an issue creating this chat, please try again.');
                            return;
                        }
                        that.props.history.push('/chat/channel/' + createdChannel.url);
                    });
                }
            );
        } else {
            ApiError('You need to log in or sign up.');
        }
    }

    activityList(data) {
        const activities = data.map((activity, index) => (
            <ActivityListItem dispatch={this.props.dispatch} key={index} activity={activity} credentials={this.props.credentials} />
        ));
        this.setState({ activities: [...this.state.activities, activities] });
    }

    render() {
        let friendshipButton = null;
        let messageButton = null;
        let blockButton = null;
        let editButton = null;
        let adminButton = null;

        if (this.props.ownProfile && this.props.credentials?.token) {
            editButton = (
                <Link className="fontGreen" to={'/user/profile/' + this.props.credentials.user_id + '/edit'}>
                    Edit Your Profile
                </Link>
            );
        } else {
            // if this user sent a friend request and is still pending
            if (
                !!guard(this.props.credentials, '.user_id')
                && this.props.user?.myfriendship?.sender_id
                && this.props.user.myfriendship.sender_id === this.props.credentials.user_id
                && this.props.user.myfriendship.friendship_status === 'pending'
            ) {
                friendshipButton = (
                    <Link to="#" className="fontWhite bgColorBlue" onClick={e => this.cancelFriendShip(e, this.props.user.myfriendship.friendship_id)}>
                        Request Sent
                    </Link>
                );
            } else if (
                // if this user received a friend request and is still pending
                !!guard(this.props.credentials, '.user_id')
                && this.props.user?.myfriendship?.receiver_id
                && this.props.user.myfriendship.receiver_id === this.props.credentials.user_id
                && this.props.user.myfriendship.friendship_status === 'pending'
            ) {
                friendshipButton = (
                    <Link to="#" className="fontBlue" onClick={e => this.acceptRequest(e, this.props.user.myfriendship.friendship_id)}>
                        Accept Request
                    </Link>
                );
            } else if (
                (
                    !!guard(this.props.credentials, '.user_id')
                    && this.props.user?.myfriendship?.friendship_status
                    && (
                        this.props.user.myfriendship.friendship_status === 'unfriended'
                        || this.props.user.myfriendship.friendship_status === 'declined'
                        || this.props.user.myfriendship.friendship_status === 'cancelled'
                    )
                )
                || (this.props.user && !this.props.user.myfriendship)
            ) {
                // if relationship between users is open for friendship, havent had a relationship, have unfriended, declined or cancelled, but not BLOCKED
                friendshipButton = (
                    <Link to="#" className="fontGreen" onClick={e => this.friendRequest(e, this.props.user.user_id)}>
                        Add Friend
                    </Link>
                );
            } else if (
                // if users have a
                !!guard(this.props.credentials, '.user_id')
                && this.props.user && this.props.user.myfriendship && this.props.user.myfriendship.friendship_status
                && this.props.user.myfriendship.friendship_status === 'accepted'
            ) {
                friendshipButton = (
                    <Link
                        to="#"
                        className="fontGreen"
                        onClick={e => this.unfriendRequest(e, this.props.user.user_firstname, this.props.user.myfriendship.friendship_id)}
                    >
                        Friend
                    </Link>
                );
            }

            if (
                (
                    !!guard(this.props.credentials, '.user_id')
                    && this.props.user?.myfriendship?.friendship_status
                    && this.props.user.myfriendship.friendship_status !== 'blocked'
                )
                || !this.props.user.myfriendship
            ) {
                messageButton = (
                    <Link
                        to="#"
                        className="fontGreen"
                        onClick={e => this.chatWithUser(e, this.props.user.user_id)}
                    >
                        Message
                    </Link>
                );
            }

            if (
                !!guard(this.props.credentials, '.user_id')
                && this.props.user?.myfriendship?.friendship_status
                && this.props.user.myfriendship.friendship_status === 'blocked'
            ) {
                blockButton = (
                    <Link
                        to="#"
                        className="fontBlue"
                        onClick={e => this.unblockRequest(e, this.props.user.myfriendship.friendship_id)}
                    >
                        Unblock
                    </Link>
                );
            } else if (
                (
                    !!guard(this.props.credentials, '.user_id')
                    && this.props.user?.myfriendship?.friendship_status
                    && this.props.user.myfriendship.friendship_status !== 'blocked'
                )

                || !this.props.user.myfriendship
            ) {
                blockButton = (
                    <Link
                        to="#"
                        className="fontGreen"
                        onClick={e => this.blockRequest(e, this.props.user.user_firstname, this.props.user.user_id)}
                    >
                        Block
                    </Link>
                );
            }
        }

        if (this.props.userData?.user_role === 'superadmin') {
            adminButton = (
                <Link
                    to="#"
                    className="fontGreen"
                    onClick={e => {
                        e.preventDefault();
                        this.setState({ manageAccount: true });
                    }}
                >
                    Manage
                </Link>
            );
        }

        return (
            <React.Fragment>
                <div>
                    {this.props.user && (
                        <div>
                            <MainNavigation title={this.props.user.user_firstname + ' ' + this.props.user.user_lastname} leftButton="back" />
                            <div className="profilePictureWrapper user">
                                <div className="row maxWidth wrapper">
                                    <div className="round-avatar user huge">
                                        <Avatar
                                            src={this.state.image}
                                            alt={this.props.user.user_firstname + ' ' + this.props.user.user_lastname}
                                            type="user"
                                        />
                                    </div>
                                    <div className="details">
                                        {this.props.user && (
                                            <div>
                                                <div className="userTitle">{this.props.user.user_firstname} {this.props.user.user_lastname}</div>
                                            </div>
                                        )}
                                        {this.props.user?.user_primaryhours && (
                                            <div>
                                                <p className="userProfileData"><b>Available Hours for Events:</b> {this.props.user.user_primaryhours}</p>
                                            </div>
                                        )}
                                        {this.props.user?.primarylocation?.location_name && (
                                            <div>
                                                <p className="userProfileData">
                                                    <b>Favorite Park: </b>
                                                    <Link
                                                        className="fontWhite"
                                                        to={'/location/profile/' + this.props.user.primarylocation.location_id + '/' + urlFriendly(this.props.user.primarylocation.location_name)}
                                                    >
                                                        {this.props.user.primarylocation.location_name}
                                                    </Link>
                                                </p>
                                            </div>
                                        )}
                                        {this.props.user?.lastaddress && (
                                            <div>
                                                <p className="userProfileData">
                                                    <b>Last  Seen:</b> {this.props.user.lastaddress.address_city}, {this.props.user.lastaddress.address_country}
                                                </p>
                                            </div>
                                        )}
                                        {this.props.user?.user_socialmedia && (
                                            <div className="social-media">
                                                <SocialMediaIcons
                                                    socialmedia={this.props.user.user_socialmedia}
                                                />
                                            </div>
                                        )}
                                        {!!this.props.user && !!this.props.user.UserSummary && (
                                            <UserSummary
                                                summary={this.props.user.UserSummary}
                                                color="fontWhite"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="tabSubmenu">
                                <div className="maxWidth noPadding row">
                                    {editButton}
                                    {friendshipButton}
                                    {messageButton}
                                    {blockButton}
                                    {adminButton}
                                </div>
                            </div>
                            <div className="profileDetails maxWidth noPadding">
                                <div className="boxContainer">
                                    <div className="boxTitle row">
                                        <div className="title">
                                            About Me
                                        </div>
                                    </div>
                                </div>
                                <div className="maxWidth fontDarkGray profile-description">
                                    {this.props.user?.user_aboutme && (
                                        <div className="profile-bio">
                                            {this.props.user.user_aboutme.split('\n').map((item, key) => (
                                                <p className="paragraph-bio" key={key}>{item}<br /></p>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className="boxContainer">
                                    <div className="boxTitle row">
                                        <div className="title">
                                            Dog(s)
                                        </div>
                                    </div>
                                </div>
                                <PetList dogs={this.props.dogs} />
                                {this.props?.user?.images?.length > 0 && (
                                    <div className="gallerySection">
                                        <WhiteTitle>
                                            Pictures
                                            <div className="viewAll">
                                                <Link to={'/user/profile/' + this.props.user.user_id + '/edit'} className="fontGreen">
                                                    <i className="icn icon-upload-photo fontGreen" />
                                                </Link>
                                                <Link to={'/user/profile/' + this.props.user.user_id + '/gallery'} className="fontGreen">
                                                    <i className="icn icon-gallery" />
                                                </Link>
                                            </div>
                                        </WhiteTitle>
                                        {this.props.user?.images && (
                                            <div className="galleryHolder">
                                                <GalleryLightbox
                                                    images={this.state.gallery}
                                                    userData={this.props.userData}
                                                    count={2}
                                                    size="normal"
                                                    handleClickRemoveImage={this.handleClickRemoveImage}
                                                    handleClickSetImage={this.handleClickSetImage}
                                                />
                                                {this.props.user.images.length > 2 && (
                                                    <div className="holderEnclosed">
                                                        <Link to={'/user/profile/' + this.props.userData.user_id + '/gallery'}>
                                                            + {this.state.gallery.length - 2} More
                                                        </Link>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div className="boxContainer">
                                    <div className="boxTitle row">
                                        <div className="title">
                                            Activities
                                        </div>
                                    </div>
                                </div>
                                {this.state.activities?.length > 0 && (
                                    <ul className="activitiesListSearch readAll">
                                        {this.state.activities}
                                    </ul>
                                )}
                                {this.state.loadingMore && (<Spinner />)}
                            </div>
                        </div>
                    )}
                </div>
                {this.state.manageAccount && (
                    <ReactModal
                        isOpen={this.state.manageAccount}
                        contentLabel="onRequestClose"
                        onRequestClose={this.toggleModal}
                        className="Modal ModalAdmin"
                        overlayClassName="Overlay"
                        ariaHideApp={false}
                    >
                        <div className="adminSection">
                            <h2 className="fontWhite">
                                Update User role
                            </h2>
                            <div className="wrapper maxWidth flex-grid">
                                <button
                                    className={'wideButtons transparentBorder fontGreen ' + (this.props.user.user_role === ROLE_REGULAR ? 'whiteOpaque' : 'whiteBG')}
                                    type="button"
                                    onClick={() => this.updateRole(this.props.credentials.token, this.props.user.user_id, ROLE_REGULAR)}
                                    disabled={this.props.user.user_role === ROLE_REGULAR || this.state.loadingMore}
                                >
                                    Regular
                                </button>
                                <button
                                    className={'wideButtons transparentBorder fontGreen ' + (this.props.user.user_role === ROLE_SUPER_ADMIN ? 'whiteOpaque' : 'whiteBG')}
                                    type="button"
                                    onClick={() => this.updateRole(this.props.credentials.token, this.props.user.user_id, ROLE_SUPER_ADMIN)}
                                    disabled={this.props.user.user_role === ROLE_SUPER_ADMIN || this.state.loadingMore}
                                >
                                    Super Admin
                                </button>
                            </div>
                        </div>
                        <div className="adminSection">
                            <h2 className="fontWhite">
                                Remove this User
                            </h2>
                            <button
                                className="wideButtons transparentBorder redBG fontWhite"
                                type="button"
                                onClick={() => this.deleteAccount(this.props.credentials.token, this.props.user.user_id)}
                                disabled={this.state.loadingMore}
                            >
                                Remove
                            </button>
                        </div>
                        {this.state.loadingMore && (<Spinner />)}
                    </ReactModal>
                )}
            </React.Fragment>
        );
    }
}

OtherUserProfile.propTypes = {
    dogs: PropTypes.instanceOf(Array),
    user: PropTypes.instanceOf(Object).isRequired,
    userData: PropTypes.instanceOf(Object).isRequired,
    credentials: PropTypes.instanceOf(Object).isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.instanceOf(Object).isRequired
};
