import React from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { fblogin } from '../../redux/actions/users';
import { getActivityCounter } from '../../redux/actions/activities';
import BasicAlert from '../../layout/UI/BasicAlert';
import DownloadAppButton from '../../layout/DownloadAppButton';
import sb from '../../helpers/SendBird';
import Config from '../../helpers/Config';
import { getDeviceToken } from '../../helpers/PushNotifications';
import { guard } from '../../helpers/Functions';
import ApiError from '../../helpers/ApiError';
import TopNav from './TopNav';
import FacebookLoginButton from '../../FacebookLoginButton';

export class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loggingIn: false
        };
    }

    componentDidMount() {
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    fbLogin = e => {
        e.preventDefault();
        this.setState({ loggingIn: true });
        const that = this;
        const fbLoginSuccess = () => {
            window.facebookConnectPlugin.getAccessToken(token => {
                that.responseFacebook({ accessToken: token });
            });
        };
        const loginPlugin = () => {
            window.facebookConnectPlugin.login(['public_profile', 'email'], fbLoginSuccess,
                error => {
                    console.log(error);
                    this.setState({ loggingIn: false });
                    BasicAlert('Sorry there is an issue with the credentials provided');
                }, { auth_type: 'reauthenticate' });
        };
        if (window.facebookConnectPlugin) {
            window.facebookConnectPlugin.getLoginStatus(response => {
                if (!!response && !!response.authResponse && !!response.authResponse.userID) {
                    window.facebookConnectPlugin.api(
                        '/' + response.authResponse.userID + '/permissions?method=delete',
                        ['public_profile',
                            'email'],
                        () => {
                            loginPlugin();
                        }, () => {
                            loginPlugin();
                        }
                    );
                } else {
                    loginPlugin();
                }
            }, () => loginPlugin());
        } else {
            loginPlugin();
        }
    }

    responseFacebook = response => {
        this.setState({ loggingIn: true });
        if ((response && response.email && response.id) || (response.accessToken)) {
            const that = this;
            this.props.dispatch(fblogin(response.accessToken))
                .then(response => {
                    console.log(response);
                    toast.success('Welcome Back!');
                    sb.connect(
                        that.props.credentials.user_id,
                        that.props.credentials.token,
                        () => {
                            sb.updateCurrentUserInfo(
                                that.props.userData.user_firstname + ' ' + that.props.userData.user_lastname,
                                guard(that.props.userData, '.images.0.image_smallurl') || '',
                                () => { }
                            );
                        }
                    );
                    getDeviceToken(that.props.dispatch, that.props.credentials);
                    that.props.dispatch(getActivityCounter(that.props.credentials.token));
                    that.props.history.push('/welcome');
                })
                .catch(err => {
                    ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                });
        } else {
            this.setState({ loggingIn: false });
        }
    }

    render() {
        return (
            <div className="mainAppContainer LoginScreens Login">
                <TopNav backLink="/" />
                <div className="LoginWrapper wrapper">
                    <h1>Login.</h1>
                </div>
                <div className="LoginWrapper wrapper">
                    {this.state.loggingIn && (
                        <div className="wideButtons fb-button">
                            <i className="icn icon-facebook1" /> Logging In...
                        </div>
                    )}
                    {!this.state.loggingIn && Config.isMobileApp && (
                        <Link
                            to="/loginemail"
                            onClick={e => this.fbLogin(e)}
                            className="wideButtons fb-button"
                        >
                            <i className="icn icon-facebook1" /> Login with Facebook
                        </Link>
                    )}
                    {!this.state.loggingIn && !Config.isMobileApp && (
                        <FacebookLoginButton
                            callback={this.responseFacebook}
                        />
                    )}
                    <Link to="/loginemail" className="wideButtons whiteBorder transparent fontWhite">
                        <i className="icn icon-email" /> Login with Email
                    </Link>
                    <Link to="/register" className="wideButtons whiteBorder transparent fontWhite">
                        Don't have an account?
                    </Link>
                    <DownloadAppButton />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(Login);
