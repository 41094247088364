import React from 'react';
import PropTypes from 'prop-types';

import { postEntityActions, deleteEntityActions } from '../../../redux/actions/all';
import ApiError from '../../../helpers/ApiError';

class ActionButtons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            actionId: (this.props.actionBySelf && this.props.actionBySelf.like && this.props.actionBySelf.like.entityactionbyuser_id),
            actionType: (this.props.actionBySelf && this.props.actionBySelf.like && this.props.actionBySelf.like.entityactionbyuser_additionalinfo && this.props.actionBySelf.like.entityactionbyuser_additionalinfo.action),
            likes: (this.props.EntityActionCounters && this.props.EntityActionCounters.like && parseInt(this.props.EntityActionCounters.like, 10)) || 0
        };
    }

    handleLike = () => {
        if (this.state.actionId && this.state.actionType === 'like') {
            this.props.dispatch(deleteEntityActions(this.props.credentials.token, this.state.actionId))
                .then(() => {
                    this.setState(prevState => ({
                        actionId: null,
                        actionType: null,
                        likes: prevState.likes - 1
                    }), this.updateLikes);
                })
                .catch(err => {
                    ApiError(err);
                });
        } else {
            const data = {
                entityactionbyuser_type: 'like',
                entity_id: this.props.entityId,
                entityactionbyuser_additionalinfo: {
                    action: 'like'
                }
            };

            this.props.dispatch(postEntityActions(this.props.credentials.token, data))
                .then(res => {
                    this.setState(prevState => ({
                        actionId: (res.value && res.value.data && res.value.data.entityactionbyuser_id),
                        actionType: (res.value && res.value.data && res.value.data.entityactionbyuser_type),
                        likes: prevState.likes + 1
                    }), this.updateLikes);
                })
                .catch(err => {
                    ApiError(err);
                });
        }
    }

    updateLikes = () => {
        this.props.updateLikes(this.state.likes);
    }

    render() {
        const liked = (this.state.actionId && this.state.actionType === 'like');
        let colorAction = 'fontRed';
        let currentClass = '';

        if (liked) {
            colorAction = 'fontGreen';
            currentClass = 'like-animation';
        }
        if (!liked) {
            currentClass = '';
        }

        return (
            <div onClick={this.handleLike} className={'flexButtons ' + colorAction}>
                <div className={'like ' + currentClass} id="like-button">
                    <i className="icn icon-like " onClick={this.addAnimation} />
                </div>
                {!!liked && 'Liked!'}
                {!liked && 'Like'}
            </div>
        );
    }
}

ActionButtons.propTypes = {
    credentials: PropTypes.instanceOf(Object).isRequired,
    dispatch: PropTypes.func.isRequired,
    entityId: PropTypes.number,
    actionBySelf: PropTypes.instanceOf(Object),
    updateLikes: PropTypes.func.isRequired
};

export default ActionButtons;
