import React from 'react';

const UserSummary = props => {
    const container = !!props.summary;
    return (
        <React.Fragment>
            {container
                && (
                    <div className={'row separate-children ' + (props.color ? props.color : 'fontWhite')}>
                        {!!props.summary.UploadedImagesCounter && (
                            <div title={props.summary.UploadedImagesCounter + ' Uploaded Pictures'}>
                                <i className="icn icon-gallery" /> {props.summary.UploadedImagesCounter}
                            </div>
                        )}
                        {!!props.summary.FriendCounter && (
                            <div title={props.summary.FriendCounter + ' Friends'}>
                                <i className="icn icon-couple" /> {props.summary.FriendCounter}
                            </div>
                        )}
                        {!!props.summary.ReviewCounter && (
                            <div title={props.summary.ReviewCounter + ' Reviews'}>
                                <i className="icn icon-no-messages" /> {props.summary.ReviewCounter}
                            </div>
                        )}
                        {!!props.summary.RatingCounter && (
                            <div title={props.summary.RatingCounter + ' Ratings'}>
                                <i className="icn icon-star-fill" /> {props.summary.RatingCounter}
                            </div>
                        )}
                        {!!props.summary.CheckinCounter && (
                            <div title={props.summary.CheckinCounter + ' Check Ins'}>
                                <i className="icn icon-check-in" /> {props.summary.CheckinCounter}
                            </div>
                        )}
                        {!!props.summary.FavoriteLocationCounter && (
                            <div title={props.summary.FavoriteLocationCounter + ' Favorites'}>
                                <i className="icn icon-heart-fill" /> {props.summary.FavoriteLocationCounter}
                            </div>
                        )}
                    </div>
                )}
        </React.Fragment>
    );
};

export default UserSummary;
