import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import MainNavigation from '../../layout/navigation/MainNavigation';

export class MissingDogs extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    render() {
        return (
            <div className="mainAppContainer">
                <MainNavigation {...this.props} title="Report a Lost Dog" leftButton="back" />
                <div className="bodyContent backgroundRed wrapper">
                    <div className="maxWidth">
                        <h1 className="section fontWhite">Report your lost Dogs</h1>
                        <p className="fontWhite">Send an Alert out to let nearby dog owners know your dog is missing.</p>
                        <p className="fontWhite">Pooch Alerts will immediately send your dog's info and connect you to all Pooch users within a 5.0km radius of the location your dog(s) was last seen.</p>
                    </div>
                    <div className="maxWidth">
                        <Link to="/reportmissing/selectdogs" className="rightRoundButton whiteBorder whiteOpaque fontWhite">
                            Report Missing Dog &gt;
                        </Link>
                    </div>
                </div>
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(MissingDogs);
