import React from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import PropTypes from 'prop-types';

import Config from '../../helpers/Config';

const LocationMap = ({ google, geoPoint }) => (
    <Map
        google={google}
        className="locationMap"
        mapTypeControl={false}
        zoomControl={!Config.isMobileDevice}
        zoom={14}
        initialCenter={geoPoint}
    >
        <Marker
            icon={{
                url: Config.fileDirectory + 'assets/images/gold-map-pin@2x.png'
            }}
        />
    </Map>
);

LocationMap.propTypes = {
    geoPoint: PropTypes.instanceOf(Object).isRequired
};

export default GoogleApiWrapper({ apiKey: Config.GoogleApi })(LocationMap);
