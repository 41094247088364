import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { getDogProfile, clearDogProfile } from '../../redux/actions/dogs';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import Spinner from '../../layout/UI/Spinner';
import NotFound from '../../layout/NotFound';
import ApiError from '../../helpers/ApiError';
import { Pull2Refresh } from '../../helpers/Pull2Refresh';
import OtherDogProfile from './OtherDogProfile';

class DogProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            myDog: false,
            loading: this.props.loading !== false,
            currentId: (this.props.match && this.props.match.params && this.props.match.params.id) || false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.state.currentId) {
            this.props.dispatch(clearDogProfile());
            this.start();
            Pull2Refresh(this.start);
            if (window && window.location && window.location.pathname) {
                ReactGA.pageview(window.location.pathname + window.location.search);
            }
        } else {
            this.myDog();
        }
    }

    start = () => {
        this.props.dispatch(getDogProfile(this.props.credentials.token, this.props.match.params.id))
            .then(() => {
                this.myDog();
                this.setState({ loading: false });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loading: false });
            });
    }

    myDog() {
        let isMyDog = false;
        if (this.props.dogProfile && this.props.dogProfile.users && this.props.dogProfile.users.length) {
            for (const i in this.props.dogProfile.users) {
                if (this.props.credentials.user_id === this.props.dogProfile.users[i].user_id) {
                    isMyDog = true;
                }
            }
        }
        this.setState({ myDog: isMyDog });
    }

    render() {
        const dog_is_lost = !!this.props.dogProfile && !!this.props.dogProfile.dogaddress && !this.props.dogProfile.dogaddress.dogaddress_found;
        return (
            <div className="mainAppContainer">
                {this.props.dogProfile && this.props.dogProfile.dog_name && !this.state.loading && (this.props.location.winScroll || this.props.location.winScroll === 0) && (
                    <MainNavigation {...this.props} title={this.props.dogProfile && this.props.dogProfile.dog_name} leftButton="back-gallery" leftLink="/dogs" />
                )}
                {this.props.dogProfile && this.props.dogProfile.dog_name && !this.state.loading && !this.props.location.winScroll && this.props.location.winScroll !== 0 && (
                    <MainNavigation {...this.props} styleClass={dog_is_lost ? 'fontRed' : ''} title={dog_is_lost ? this.props.dogProfile.dog_name + ' (MISSING)' : this.props.dogProfile.dog_name} leftButton="back" />
                )}
                {(!this.props.dogProfile || !this.props.dogProfile.dog_name) && !this.state.loading && !this.props.location.winScroll && this.props.location.winScroll !== 0 && (
                    <MainNavigation {...this.props} styleClass="" title="Not Found" leftButton="back" />
                )}
                <div className="bodyContent pull2refresh">
                    {(!this.props.dogProfile || !this.props.dogProfile.dog_name) && !this.state.loading && (
                        <NotFound type="dog" class="backgroundRed" />
                    )}
                    {this.props.dogProfile && this.props.dogProfile.dog_name && !this.state.loading && (
                        <OtherDogProfile {...this.props} isMyDog={this.state.myDog} />
                    )}
                    {!!this.state.loading && (
                        <Spinner />
                    )}
                </div>
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        dogProfile: state.dogs.dogProfile
    };
}

export default connect(mapStateToProps)(DogProfile);
