import React from 'react';

const ContactInfo = props => {
    const info = props.contactInfo;
    return (
        <React.Fragment>
            {info['phone'] && (
                <div>
                    <a href={'tel:' + info['phone']}>{info['phone']}</a>
                </div>
            )}
            {info['email'] && (
                <div>
                    <a href={'mailto:' + info['email']}>{info['email']}</a>
                </div>
            )}
            {info['tollFree'] && (
                <div>
                    Toll-Free<br /><a href={'tel:' + info['tollFree']}>{info['tollFree']}</a>
                </div>
            )}
            {info['fax'] && (
                <div>
                    Fax<br />{info['fax']}
                </div>
            )}
        </React.Fragment>
    );
};

export default ContactInfo;
