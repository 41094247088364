import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { toast } from 'react-toastify';

import WhiteTitle from '../../hoc/WhiteTitle';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import UserList from '../../layout/UserList';
import { chat_clearUser } from '../../redux/actions/users';
import sb from '../../helpers/SendBird';

export class ChatConfirm extends React.Component {
    componentDidMount() {
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        console.log(this.props.selectedUsers);
    }

    rightButtonOnClick = e => {
        e.preventDefault();

        const userIds = [];

        for (const i in this.props.selectedUsers) {
            userIds.push(this.props.selectedUsers[i].id);
        }

        const roomName = this.userList();

        const that = this;
        sb.connect(
            this.props.credentials.user_id,
            this.props.credentials.token,
            () => {
                sb.GroupChannel.createChannelWithUserIds(userIds, true, roomName, null, null, (createdChannel, error) => {
                    if (error) {
                        toast.error('Sorry, there was an issue creating this chat, please try again.');
                        return;
                    }
                    that.props.dispatch(chat_clearUser());
                    that.props.history.push('/chat/channel/' + createdChannel.url);
                });
            }
        );
    }

    onClick = e => {
        e.preventDefault();
    }

    userList() {
        const usersListNames = this.props.selectedUsers.map(user => user.name);
        return usersListNames.join(', ');
    }

    render() {
        return (
            <div className="mainAppContainer locationListSearch">
                <MainNavigation title="Confirm Chat" leftButton="back" rightButton="done" rightButtonOnClick={this.rightButtonOnClick} />
                <div className="bodyContent wrapper noPaddingTop">
                    <div className="maxWidth">
                        <div className="spacerSmall" />
                        <WhiteTitle>Selected Participants</WhiteTitle>
                        <UserList users={this.props.selectedUsers} markSelected={true} onClick={this.onClick} />
                    </div>
                </div>
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        selectedUsers: state.users.chat.selectedUsers
    };
}

export default connect(mapStateToProps)(ChatConfirm);
