import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export const MissingDogsNavigation = ({ showTitle, selected }) => (
    <React.Fragment>
        {showTitle && (
            <div className="simple-title">
                <i className="icn icon-eye fontBlue" /> <b className="fontBlack">Missing Dogs</b>
            </div>
        )}
        <div className="tabSubmenu">
            <div className="navigationTabs">
                <div className="maxWidth row">
                    <Link to="/missingdogs" className={(selected === 'missing-dogs' ? 'active' : '')}>
                        Missing Dogs
                    </Link>
                    <Link to="/reportmissing" className={(selected === 'report-missing-dogs' ? 'active' : '')}>
                        Report a Missing Dog
                    </Link>
                </div>
            </div>
        </div>
    </React.Fragment>
);

MissingDogsNavigation.propTypes = {
    selected: PropTypes.string
};
