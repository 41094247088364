import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Textarea from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import qs from 'qs';
import ReactGA from 'react-ga';

import { setCreateDogDescription, createDog, clearDogCreate } from '../../redux/actions/dogs';
import { uploadPicture } from '../../redux/actions/all';
import MainNavigation from '../../layout/navigation/MainNavigation';
import Spinner from '../../layout/UI/Spinner';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import ApiError from '../../helpers/ApiError';

class UserDogAddDescription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingSubmitForm: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    verifyData = e => {
        e.preventDefault();
        this.submit.setAttribute('disabled', 'disabled');
        this.setState({ loadingSubmitForm: true });
        const dataSet = {};
        if (this.props.dogCreate.name) {
            dataSet.dog_name = this.props.dogCreate.name;
        }
        if (this.props.dogCreate.description) {
            dataSet.dog_description = this.props.dogCreate.description;
        }
        if (!!this.props.dogCreate.breeds && !!this.props.dogCreate.breeds.length) {
            dataSet.breeds = [];
            for (const i in this.props.dogCreate.breeds) {
                dataSet.breeds.push({ breed_id: this.props.dogCreate.breeds[i] });
            }
        }
        if (this.props.dogCreate.dob) {
            dataSet.dog_birthdate = moment(this.props.dogCreate.dob).format('YYYY-MM-DD');
        }
        if (this.props.dogCreate.fixed === true || this.props.dogCreate.fixed === false) {
            dataSet.dog_fixed = this.props.dogCreate.fixed ? 1 : 0;
        }
        if (this.props.dogCreate.playdates === true || this.props.dogCreate.playdates === false) {
            dataSet.dog_playdates = this.props.dogCreate.playdates ? 1 : 0;
        }
        if (this.props.dogCreate.sex === 'male' || this.props.dogCreate.sex === 'female') {
            dataSet.dog_sex = this.props.dogCreate.sex;
        }
        if (this.props.dogCreate.weight) {
            dataSet.dog_weight = this.props.dogCreate.weight;
        }
        if (this.props.dogCreate.socialmedia) {
            dataSet.dog_socialmedia = this.props.dogCreate.socialmedia;
        }
        this.props.dispatch(createDog(this.props.credentials.token, dataSet))
            .then(() => {
                if (!this.props.dogCreate.pictureFile) {
                    this.setState({ loadingSubmitForm: false }, () => {
                        this.submit.removeAttribute('disabled');
                    });
                }
                toast.success('Dog Profile Created');
                this.uploadPicture();
            })
            .catch(err => {
                this.setState({ loadingSubmitForm: false }, () => {
                    this.submit.removeAttribute('disabled');
                });
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    uploadPicture = () => {
        let params = false;
        if (this.props.history.location.search) {
            params = qs.parse(this.props.history.location.search.slice(1));
        }
        if (this.props.dogCreate.success && this.props.dogCreate.new_dog_id) {
            const dogId = this.props.dogCreate.new_dog_id;
            if (this.props.dogCreate.pictureFile) {
                toast('Uploading Dog Profile Image');
                this.props.dispatch(uploadPicture(this.props.credentials.token, this.props.dogCreate.new_dog_id, this.props.dogCreate.pictureFile, true))
                    .then(() => {
                        this.props.dispatch(clearDogCreate());
                        this.setState({ loadingSubmitForm: false }, () => {
                            this.submit.removeAttribute('disabled');
                        });
                        if (params && params.redirect) {
                            this.props.history.push(params.redirect);
                        } else {
                            this.props.history.push('/dog/' + dogId);
                        }
                    });
            } else {
                this.props.dispatch(clearDogCreate());
                if (params && params.redirect) {
                    this.props.history.push(params.redirect);
                } else {
                    this.props.history.push('/dog/' + dogId);
                }
            }
        } else {
            ApiError('Make sure all data is correct');
        }
    }

    render() {
        return (
            <div className="mainAppContainer LoginScreens">
                <MainNavigation {...this.props} title={'Describe ' + this.props.dogCreate.name} leftButton="back" />
                <div className="bodyContent backgroundGreen">
                    {this.state.loadingSubmitForm
                        ? (<Spinner />)
                        : (
                            <form onSubmit={this.verifyData}>
                                <div className="LoginWrapper wrapper maxWidth fontWhite">
                                    <h1 className="section">How would you describe {this.props.dogCreate.name}?</h1>
                                    <Textarea value={this.props.dogCreate.description || ''} onChange={e => this.props.dispatch(setCreateDogDescription(e.target.value))} className="niceTextarea" placeholder={'e.g. ' + this.props.dogCreate.name + ' is the fun and playful kind...'} />
                                </div>
                                <div className="LoginWrapper wrapper maxWidth">
                                    <button ref={submit => { this.submit = submit; }} type="submit" className="rightRoundButton whiteBorder whiteOpaque fontWhite">
                                        Create &gt;
                                    </button>
                                </div>
                            </form>
                        )}
                </div>
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        dogCreate: state.dogs.dogCreate
    };
}

export default connect(mapStateToProps)(UserDogAddDescription);
