import Device from './Device';

export default class Config {
    static get DeviceType() {
        return Device.DeviceType;
    }

    static get isMobileApp() {
        return Device.isMobileApp;
    }

    static get isMobileDevice() {
        return Device.isMobileDevice;
    }

    static get GoogleApi() {
        return process.env.REACT_APP_API_GOOGLE;
    }

    static get GoogleAndroidApi() {
        return process.env.REACT_APP_API_GOOGLE_ANDROID;
    }

    static get GoogleIosApi() {
        return process.env.REACT_APP_API_GOOGLE_IOS;
    }

    static get GoogleGeoCodeApi() {
        return process.env.REACT_APP_API_GOOGLE_GEOCODE;
    }

    static get fileDirectory() {
        if (process.env.REACT_APP_MODE === 'DEV') {
            if (this.isMobileApp) {
                return '';
            }
            return '/';
        }
        return `${process.env.REACT_APP_MOBILE_SITE}/`;
    }

    static get baseUrl() {
        if (this.isMobileApp) {
            return '#';
        }
        return process.env.REACT_APP_URL_SITE;
    }

    static get webUrl() {
        return process.env.REACT_APP_URL_SITE;
    }

    static get logos() {
        return {
            twoLines: 'pooch-logo-twoLiner',
            inline: 'pooch-logo',
            letters: 'pooch-logo-letters'
        };
        /* return {
            twoLines: 'POOCH-HALLOWEEN-LOGO-LAUNCH',
            inline: 'POOCH-HALLOWEEN-LOGO',
            letters: 'pooch-logo-letters-halloween'
        } */
    }
}
