import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { updateUserResponse } from '../../redux/actions/playdates';
import Avatar from '../../layout/UI/Avatar';
import PlaydateJoinButtons from '../../layout/UI/PlaydateJoinButtons';
import { guard, eventDateTime } from '../../helpers/Functions';
import ApiError from '../../helpers/ApiError';

export class PlaydatesManageList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // change: false,
            joinedStatus: '',
            playdateuser_id: null
        };
    }

    componentDidMount() {
        // makes sure to get all the participants in one place
        const pd = this.props.playdate;
        const participants = pd.users;
        // const owner = pd.owner
        // const playdateTitle = this.playdateTitle(owner, participants)
        const myPlaydateProfile = participants.filter(user => user.user_id === this.props.credentials.user_id);
        if (myPlaydateProfile && myPlaydateProfile[0]) {
            this.setState({
                joinedStatus: myPlaydateProfile[0].playdateuser_status,
                playdateuser_id: myPlaydateProfile[0].playdateuser_id
            });
        }
        /* this.setState({
            title: playdateTitle
        }) */
    }

    // CREATES A TITLE FOR THE PLAYDATE, THE TITLE IS YOUR NAME PLUS 2 OTHER PEOPLE
    // IF THERE ARE MORE THEN IT WILL ADD "AND MORE"
    /* playdateTitle(owner, participants) {
        var totalParticipants = participants.length + 1//add the owner to the participants count
        var displayLimit = 3 //this display limit including "YOU"
        var users = ['You']
        let displaying = 1 //amount of users being displayed, 1 because you are now being displayed

        //since you are already in the list dont show yourself again
        //we validate to make sure you are not the owner
        //then we add the owners first name to the list
        if(!!owner && !!owner.user_id && owner.user_id !== this.props.credentials.user_id) {
            users.push(owner.user_firstname)
            displaying++
        }

        //since you are already in the list dont show yourself again
        //we add the name of the other participants in the list
        participants.map((user, index)=> {
            if(user.user.user_id !== this.props.credentials.user_id) {
                if(displaying < displayLimit) {
                    users.push(user.user.user_firstname)
                    displaying++
                }
            }
            return null
        })

        //now we show how many other users there are in the playdate
        let hiddenParticipants = totalParticipants-displaying
        if(hiddenParticipants > 0) {
            users.push(' and '+hiddenParticipants +' more')
        }

        return <span>{users.join(', ')}</span>
    }

    changeResponse(e) {
        e.preventDefault()
        this.setState({change: true})
    }
    */

    changeStatus = (e, response) => {
        e.preventDefault();
        if (response) {
            this.props.dispatch(updateUserResponse(this.props.credentials.token, this.state.playdateuser_id, { playdateuser_status: response }))
                .then(() => toast.success('Updated Successfully'))
                .catch(() => ApiError('Sorry, there was an error'));
            this.setState({
                // change: false,
                joinedStatus: response
            });
        }
    }

    render() {
        const pd = this.props.playdate;
        const title = pd.playdate_title || (pd.location && pd.location.location_name) || 'Playdate';
        const image = (pd.images && pd.images[0] && pd.images[0].image_smallurl) || (pd && pd.location && pd.location.images && pd.location.images[0] && pd.location.images[0].image_smallurl);

        return (
            <React.Fragment>
                {pd && pd.location && (
                    <li className="playdateItem">
                        <div className="participant row">
                            <Link to={'/events/' + pd.playdate_id} className="round-avatar location participantAvatar">
                                <Avatar
                                    src={image}
                                    alt={pd.location.location_name}
                                    type="location"
                                />
                            </Link>
                            <div className="participantInfo">
                                <Link to={'/events/' + pd.playdate_id}>
                                    {title}
                                </Link>
                                <div className="h6 fontGray">
                                    {pd.location.address.address_city}, {pd.location.address.address_state}
                                    <br />
                                    {eventDateTime(pd.playdate_datetime)}
                                    {!pd.playdate_isactive ? <span className="fontRed"> (Cancelled)</span> : ''}
                                </div>
                                <div className="owner-info row">
                                    <Link to={'/user/profile/' + pd.owner.user_id}>
                                        <div className="round-avatar user verysmall">
                                            <Avatar
                                                src={guard(pd.owner, '.images.0.image_smallurl')}
                                                alt={pd.owner.user_firstname + ' ' + pd.owner.user_lastname}
                                                type="user"
                                            />
                                        </div>
                                    </Link>
                                    <Link to={'/user/profile/' + pd.owner.user_id}>{pd.owner.user_firstname + ' ' + pd.owner.user_lastname}</Link>
                                    {!!pd.users && pd.users.length > 0 && (
                                        <React.Fragment>
                                            {'\u00A0'}{'& ' + pd.users.length}
                                            {pd.users.length === 1 ? ' other user' : ' other users'}
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        </div>
                        {this.props.type === 'hosted' && (
                            <div className="row alignItems">
                                <Link to={'/events/manage/event/' + pd.playdate_id} className="flexButtons fontRed h6">
                                    Edit
                                </Link>
                            </div>
                        )}
                        {this.props.type === 'myInvitations' && (
                            <PlaydateJoinButtons joinedStatus={this.state.joinedStatus} changeStatus={this.changeStatus} />
                        )}
                    </li>
                )}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(PlaydatesManageList);
