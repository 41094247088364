import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { sendRequest, updateRequest } from '../redux/actions/users';
import Avatar from '../layout/UI/Avatar';
import { guard } from '../helpers/Functions';

class UserListNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sentRequests: [],
            acceptedRequests: [],
            showList: false
        };
    }

    componentDidMount() {
        // SO THIS IS A HUGE NEW FEATURE, IT IS CHANGING REDUX.
        // THIS IS FOR FindFriends.js PARENT COMPONENT
        // IN FindFriends.js THE CURRENT USER CAN ACCEPT OR SEND A FRIEND REQUESTS
        // REDUX IS SAVING THE STATES FOR ACCEPTED OR SENT REQUESTS IN: user.friendRequestsStatus
        // THE IDEA IS TO HAVE ALL THE ACTIONS FROM ALL OTHER SCREENS
        // IN THIS SCREEN WE WILL USE THIS GLOBAL REDUX AND WILL SHOW THE CORRESPONDING STATUS IN THE BUTTONS

        // WE MAKE SURE THAT THE friendRequestsStatus HAS INFORMATION FIRST AND LOOP THRU IT
        if (this.props.friendRequestsStatus?.length > 0) {
            const requestsStatus = [...this.props.friendRequestsStatus];
            for (let i = 0; i < requestsStatus.length; i++) {
                const rs = requestsStatus[i];
                // WE MAKE SURE THAT WE ARE THE ONES SENDING THE REQUEST
                if (rs.sender_id === this.props.credentials.user_id) {
                    // SINCE A USER CAN SEND A REQUEST AND THEN CANCELL IT OR ACCEPT IT
                    // WE HAVE TO REMOVE ALL PREVIOUS INSTANCES FROM THE OTHER STATES, THE REASON FOR FILTER
                    // THIS CONDITIONAL MEANS WE ARE SENDING A FRIEND REQUEST
                    // WE REMOVE ALL THE "ACCEPTED" FRIENDS REQUESTS, AND ONLY LEAVE THE PENDING STATUS
                    if (rs.friendship_status === 'pending') {
                        this.setState(prevState => {
                            const newAcceptedRequests = prevState.acceptedRequests.filter(id => id !== rs.receiver_id);
                            return {
                                sentRequests: [...prevState.sentRequests, rs.receiver_id],
                                acceptedRequests: newAcceptedRequests
                            };
                        });
                    } else if (rs.friendship_status === 'accepted') {
                        // THIS CONDITIONAL MEANS WE ARE ACCEPTING A FRIEND REQUEST
                        // WE REMOVE ALL THE "PENDING" FRIENDS REQUESTS, AND ONLY LEAVE THE ACCEPTED STATUS,
                        // THE REVERSE OF THE PREVIOUS ONE
                        this.setState(prevState => {
                            const newSentRequests = prevState.sentRequests.filter(id => id !== rs.receiver_id);
                            return {
                                sentRequests: newSentRequests,
                                acceptedRequests: [...prevState.acceptedRequests, rs.receiver_id]
                            };
                        });
                    } else {
                        // ANY OTHER STATE THEN REVERSE BUTTON TO "ADD AS FRIEND"
                        this.setState(prevState => {
                            const newAcceptedRequests = prevState.acceptedRequests.filter(id => id !== rs.receiver_id);
                            const newSentRequests = prevState.sentRequests.filter(id => id !== rs.receiver_id);
                            return {
                                sentRequests: newSentRequests,
                                acceptedRequests: newAcceptedRequests
                            };
                        });
                    }
                }
            }
        }
    }

    friendRequest = (e, id) => {
        e.preventDefault();
        this.props.dispatch(sendRequest(this.props.credentials.token, id))
            .then(() => this.setState(
                prevState => ({
                    sentRequests: prevState.sentRequests.concat(id)
                })
            ));
    }

    acceptRequest = (e, id) => {
        e.preventDefault();
        this.props.dispatch(updateRequest(this.props.credentials.token, id, { friendship_status: 'accepted' }))
            .then(() => this.setState(
                prevState => ({
                    acceptedRequests: prevState.acceptedRequests.concat(id)
                })
            ));
    }

    getDogNames = dogs => {
        const dogNames = [];
        for (const i in dogs) {
            dogNames.push(dogs[i].dog_name);
        }
        return dogNames.map((item, index) => (<li key={index}>{item}</li>));
    }

    showList = () => {
        this.setState(prevState => ({
            showList: !prevState.showList
        }));
    }

    userMap(users) {
        return users.map((user, index) => {
            let button = null;
            let friendStatus = null;

            if (this.props.addFriend) {
                if ((!!user && !!user.myfriendship && user.myfriendship.friendship_status === 'pending' && user.myfriendship.sender_id === this.props.credentials.user_id) || !!this.state.sentRequests.includes(user.user_id)) {
                    button = (
                        <div className="flexSmallestButtons grayBorder grayBG fontWhite">
                            <p>Pending</p>
                        </div>
                    );
                    friendStatus = (<div className="lastSeen fontGreen">Friend Request Sent</div>);
                } else if (!!user && !!user.myfriendship && !!user.myfriendship.friendship_id && !!this.state.acceptedRequests.includes(user.myfriendship.friendship_id)) {
                    button = (
                        <div className="flexSmallestButtons greenBorder greenBG fontWhite">
                            <p>Accepted</p>
                        </div>
                    );
                } else if (!!user && !!user.myfriendship && user.myfriendship.friendship_status === 'pending' && user.myfriendship.sender_id !== this.props.credentials.user_id) {
                    button = (
                        <div className="flexSmallestButtons blueBorder blueBG fontWhite" onClick={e => this.acceptRequest(e, user.myfriendship.friendship_id)}>
                            <p>Accept</p>
                        </div>
                    );
                } else {
                    button = (
                        <div onClick={e => this.friendRequest(e, user.user_id)} className="flexSmallestButtons greenBorder whiteBG fontGreen">
                            <p>Add Friend</p>
                        </div>
                    );
                }
            } else if (this.props.markSelected) {
                button = (
                    <div className="next">
                        {!!this.props.selectedUsers && !!this.props.selectedUsers.length && (this.props.selectedUsers.filter(e => String(e.id) === String(user.user_id)).length > 0) && (
                            <i className="icn icon-check-mark fontBlue" />
                        )}
                    </div>
                );
            }

            return (
                <div className="participantListItem" key={index}>
                    <Link
                        to={'/user/profile/' + user.user_id}
                        onClick={e => (this.props.onClick ? this.props.onClick(e, { id: user.user_id, name: user.user_firstname + ' ' + user.user_lastname, user_firstname: user.user_firstname, user_lastname: user.user_lastname, images: user.images }) : '')}
                        className="user row"
                    >
                        <div className="check-option">
                            {button}
                        </div>
                        <div className="round-avatar colored-icon ">
                            <Avatar
                                src={guard(user, '.images.0.image_smallurl')}
                                alt={user.user_firstname + ' ' + user.user_lastname}
                                type="user"
                            />
                        </div>
                        <div className="info row">
                            <div className="full-width">
                                <div className="name fontGreen">{user.user_firstname + ' ' + user.user_lastname}</div>
                                {user.lastaddress && user.lastaddress.address_city && (
                                    <div className="additional-info">
                                        <div className="lastSeen">
                                            Last Seen: {user.lastaddress.address_city} {user.lastaddress.address_country || ''}
                                        </div>
                                        {user.dogs.length > 0 && (
                                            <div className="user-dog-list">
                                                <div className="dog-list"> Dog(s):
                                                    {this.state.showList && (
                                                        <ul className="list">
                                                            {this.getDogNames(user.dogs)}
                                                        </ul>
                                                    )}
                                                    {!this.state.showList && (
                                                        <div className="first-dog">{user.dogs[0].dog_name}</div>
                                                    )}
                                                    {user.dogs.length > 1 && (
                                                        <div className="drop-list" onClick={e => { this.showList(e); }}> + {user.dogs.length - 1} more</div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {friendStatus}
                            </div>
                        </div>
                    </Link>
                </div>
            );
        });
    }

    render() {
        return (
            <div className="participantList">
                {this.props.users && this.props.users !== 'loading' && this.userMap(this.props.users)}
            </div>
        );
    }
}

UserListNew.propTypes = {
    credentials: PropTypes.instanceOf(Object),
    friendRequestsStatus: PropTypes.instanceOf(Array),
    dispatch: PropTypes.func,
    addFriend: PropTypes.bool,
    markSelected: PropTypes.bool,
    selectedUsers: PropTypes.instanceOf(Array),
    users: PropTypes.instanceOf(Array),
    onClick: PropTypes.func
};

export default UserListNew;
