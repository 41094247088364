import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { pc_images, pc_images_files } from '../../redux/actions/playdates';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import { PlaydateNavigation } from '../../layout/navigation/PlaydateNavigation';
import CameraModal from '../../layout/CameraModal';
import Avatar from '../../layout/UI/Avatar';
import { camera, cameraBlob, cameraRoll } from '../../helpers/Camera';
import { guard } from '../../helpers/Functions';
import Config from '../../helpers/Config';

class PlaydateCreateImages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pictureModal: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    filesChanged = files => {
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            const file = files[i];
            reader.onloadend = () => {
                this.props.dispatch(pc_images_files(file));
                this.props.dispatch(pc_images(reader.result));
            };
            reader.readAsDataURL(file);
        }
    }

    toggleModal = () => {
        this.setState({ pictureModal: !this.state.pictureModal });
    }

    changeImage = () => {
        if (Config.isMobileApp) {
            this.toggleModal();
        } else {
            const selectfile = document.getElementById('file');
            selectfile.click();
        }
    }

    phoneCamera = () => {
        camera(this.phoneSuccess);
    }

    phoneUpload = () => {
        cameraRoll(this.phoneSuccess);
    }

    phoneSuccess = file => {
        this.props.dispatch(pc_images(file));
        cameraBlob(file, this.uploadToProfile);
    }

    uploadToProfile = imgBlob => {
        this.props.dispatch(pc_images_files(imgBlob));
    }

    clear = () => {
        document.getElementById('file').value = '';
        this.props.dispatch(pc_images());
        this.props.dispatch(pc_images_files());
    }

    render() {
        return (
            <div className="mainAppContainer playdates">
                {this.state.pictureModal && (
                    <CameraModal toggleModal={this.toggleModal} callbackCamera={this.phoneCamera} callbackUpload={this.phoneUpload} />
                )}
                <MainNavigation title="Select an Image" />
                <PlaydateNavigation selected="create" />
                <div className="bodyContent wrapper">
                    <div className="maxWidth">
                        <h1 className="fontGreen">Upload an image for your {this.props.pc.type}!</h1>
                        <div className="profile-avatar">
                            <input className="hidden" id="file" type="file" onChange={e => this.filesChanged(e.target.files)} accept="image/*" required />
                            <div className="round-avatar huge fontGreen">
                                <Avatar
                                    src={guard(this.props.pc, '.images')}
                                    onClick={this.changeImage}
                                    alt={this.props.pc.title}
                                    className="round-avatar huge changeImage"
                                />
                            </div>
                            <div className="alignCenter">
                                <div onClick={this.changeImage} className="changeImage">Add {this.props.pc.type} cover picture</div>
                                {guard(this.props.pc, '.images') && (
                                    <div onClick={this.clear} className="changeImage">
                                        Clear
                                    </div>
                                )}
                            </div>
                        </div>
                        <Link to="/events/create/event/friends" className="wideButtons greenBorder greenBG fontWhite">Next &gt;</Link>
                    </div>
                </div>
                <SecondaryNavigation selected="events" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        pc: state.playdates.create
    };
}

export default connect(mapStateToProps)(PlaydateCreateImages);
