import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { getPlaydates } from '../../redux/actions/playdates';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import { PlaydateNavigation } from '../../layout/navigation/PlaydateNavigation';
import ApiError from '../../helpers/ApiError';
import Spinner from '../../layout/UI/Spinner';
import NotFound from '../../layout/NotFound';
import PlaydayeManageList from './PlaydatesManageList';

export class PlaydatesHosted extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hosted: [],
            passed: [],
            loadingHosted: true,
            loadingPassed: true,
            pageHosted: 1,
            pagePassed: 1,
            totalPagesHosted: 1,
            totalPagesPassed: 1
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.loadHosted();
        this.loadPassed();
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    loadHosted = () => {
        const filters = 'islive=true&onlymine=true&filter[owner_id][eq]=' + this.props.credentials.user_id + '&per-page=5&page=' + this.state.pageHosted;
        this.props.dispatch(getPlaydates(this.props.credentials.token, false, filters))
            .then(response => {
                this.showHosted(response.value.data);
                this.setState({
                    loadingHosted: false,
                    totalPagesHosted: response.value.headers['x-pagination-page-count']
                });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loadingHosted: false });
            });
    }

    loadMoreHosted = () => {
        this.setState(prevState => ({
            pageHosted: prevState.pageHosted + 1,
            loadingHosted: true
        }), this.loadHosted);
    }

    loadPassed = () => {
        const filters = 'islive=false&onlymine=true&filter[owner_id][eq]=' + this.props.credentials.user_id + '&per-page=5&page=' + this.state.pagePassed;
        this.props.dispatch(getPlaydates(this.props.credentials.token, false, filters))
            .then(response => {
                this.showPassed(response.value.data);
                this.setState({
                    loadingPassed: false,
                    totalPagesPassed: response.value.headers['x-pagination-page-count']
                });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loadingPassed: false });
            });
    }

    loadMorePassed = () => {
        this.setState(prevState => ({
            pagePassed: prevState.pagePassed + 1,
            loadingPassed: true
        }), this.loadPassed);
    }

    showHosted(playdates) {
        if (!!playdates && playdates.length > 0) {
            const myPlaydatesList = playdates.map(playdate => (<PlaydayeManageList key={playdate.playdate_id} playdate={playdate} type="hosted" />));
            this.setState(prevState => ({ hosted: [...prevState.hosted, myPlaydatesList] }));
        }
    }

    showPassed(playdates) {
        if (!!playdates && playdates.length > 0) {
            const myPlaydatesList = playdates.map(playdate => (<PlaydayeManageList key={playdate.playdate_id} playdate={playdate} type="pastPlaydates" />));
            this.setState(prevState => ({ passed: [...prevState.passed, myPlaydatesList] }));
        }
    }

    render() {
        const isLoading = this.state.loadingHosted || this.state.loadingPassed;
        const hasPlaydaytes = this.state.hosted.length > 0 || this.state.passed.length > 0;
        return (
            <div className="mainAppContainer playdates">
                <MainNavigation title="Hosted Events" />
                <PlaydateNavigation selected="manage" />
                <div className="bodyContent pull2refresh playdatesmanage">
                    {isLoading === false && hasPlaydaytes === false && (<NotFound type="playdates" />)}
                    <div className="maxWidth">
                        {this.state.hosted.length > 0 && (
                            <React.Fragment>
                                <div className="boxContainer">
                                    <div className="boxTitle row">
                                        <div className="title">
                                            Your Upcoming Events
                                        </div>
                                    </div>
                                </div>
                                {this.state.hosted}
                                {(this.state.pageHosted < this.state.totalPagesHosted) && (
                                    <div className="wrapper">
                                        <div className="fontGreen alignRight" onClick={this.loadMoreHosted}>View More</div>
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                        {this.state.hosted.length > 0 && this.state.passed.length > 0 && (
                            <div className="spacer" />
                        )}
                        {this.state.loadingHosted && (
                            <Spinner />
                        )}
                        {this.state.passed.length > 0 && (
                            <React.Fragment>
                                <div className="boxContainer">
                                    <div className="boxTitle row">
                                        <div className="title">
                                            Your Past Events
                                        </div>
                                    </div>
                                </div>
                                {this.state.passed}
                                {(this.state.pagePassed < this.state.totalPagesPassed)
                                    && (
                                        <div className="wrapper">
                                            <div className="fontGreen alignRight" onClick={this.loadMorePassed}>View More Past Events</div>
                                        </div>
                                    )}
                            </React.Fragment>
                        )}
                        {this.state.loadingPassed && (
                            <Spinner />
                        )}
                    </div>
                </div>
                <SecondaryNavigation selected="events" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(PlaydatesHosted);
