import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { resetPassword, login, setEmail } from '../../redux/actions/users';
import { getActivityCounter } from '../../redux/actions/activities';
import sb from '../../helpers/SendBird';
import { getDeviceToken } from '../../helpers/PushNotifications';
import { guard } from '../../helpers/Functions';
import ApiError from '../../helpers/ApiError';
import TopNav from './TopNav';

class LoginReset extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userPassword: '',
            userPassword2: '',
            pin: '',
            pause: false
        };
    }

    componentDidMount() {
        const params = new URLSearchParams(window.location.search);
        const pin = params.get('pin'); // bar
        const email = params.get('email'); // bar
        if (email) this.props.dispatch(setEmail(email));
        this.setState({ pin });
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    verifyData = e => {
        e.preventDefault();
        if (this.state.pause) return;
        if (!this.state.userPassword || !this.state.userPassword2 || (this.state.userPassword !== this.state.userPassword2)) {
            toast('Please verify your passwords!');
        } else {
            this.setState({ pause: true });
            const that = this;
            const data = {
                user_email: this.props.userData.user_email,
                user_password: this.state.userPassword,
                user_pin: this.state.pin
            };
            this.props.dispatch(resetPassword(data))
                .then(() => {
                    that.props.dispatch(login(that.props.userData.user_email, that.state.userPassword))
                        .then(response => {
                            console.log(response);
                            toast.success('Welcome Back!');
                            that.props.dispatch(getActivityCounter(that.props.credentials.token));
                            sb.connect(
                                that.props.credentials.user_id,
                                that.props.credentials.token,
                                () => {
                                    sb.updateCurrentUserInfo(
                                        that.props.userData.user_firstname + ' ' + that.props.userData.user_lastname,
                                        guard(that.props.userData, '.images.0.image_smallurl') || '',
                                        () => { }
                                    );
                                }
                            );
                            getDeviceToken(that.props.dispatch, that.props.credentials);
                            that.setState({ pause: false });
                            that.props.history.push('/welcome');
                        });
                })
                .catch(err => {
                    ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                    that.setState({ pause: false });
                });
        }
    }

    render() {
        return (
            <div className="mainAppContainer LoginScreens Login">
                <TopNav backLink="/forgot" />
                <div className="LoginWrapper wrapper">
                    <h1>Reset your password</h1>
                </div>
                <form onSubmit={this.verifyData}>
                    <div className="LoginWrapper wrapper maxWidth">
                        <div className="innerLabelInput">
                            <span className="blocking-span">
                                <input className="inputText" id="email" name="email" value={this.props.userData.user_email || ''} onChange={e => this.props.dispatch(setEmail(e.target.value))} required />
                                <span className="floating-label">Email</span>
                            </span>
                        </div>
                        <div className="innerLabelInput">
                            <span className="blocking-span">
                                <input className="inputText" id="pin" name="pin" value={this.state.pin || ''} onChange={this.handleChange} required />
                                <span className="floating-label">PIN (emailed to you)</span>
                            </span>
                        </div>
                        <div className="innerLabelInput">
                            <span className="blocking-span">
                                <input className="inputText" id="userPassword" name="userPassword" type="password" value={this.state.userPassword || ''} onChange={this.handleChange} required />
                                <span className="floating-label">New Password</span>
                            </span>
                        </div>
                        <div className="innerLabelInput">
                            <span className="blocking-span">
                                <input className="inputText" id="userPassword2" name="userPassword2" type="password" value={this.state.userPassword2 || ''} onChange={this.handleChange} required />
                                <span className="floating-label">Repeat New Password</span>
                            </span>
                        </div>
                        <input type="submit" value="Reset password &gt;" className="rightRoundButton whiteBorder whiteOpaque fontWhite" />
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(LoginReset);
