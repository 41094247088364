import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';

import { loginClick } from '../../helpers/ApiError';

export class MissingDogs extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    goToReportDog() {
        if (this.props.credentials?.token) {
            this.props.history.push('/reportmissing/selectdogs');
        } else {
            loginClick();
        }
    }

    render() {
        return (
            <div className="mainAppContainer">
                <div className="bodyContent backgroundRed wrapper">
                    <div className="maxWidth small">
                        <div className="report-missing-dog-section page-container">
                            <div className="page-column">
                                <div className="page-item">
                                    <h1 className="section fontWhite">Report your lost Dogs</h1>
                                </div>
                                <div className="page-item">
                                    <p className="fontWhite">Send an Alert out to let nearby dog owners know your dog is missing.</p>
                                </div>
                                <div className="page-item">
                                    <p className="fontWhite">Pooch Alerts will immediately send your dog's info and connect you to all Pooch users within a 5.0km radius of the location your dog(s) was last seen.</p>
                                    <Link
                                        to="#"
                                        className="rightRoundButton whiteBorder whiteOpaque fontWhite"
                                        onClick={() => this.goToReportDog()}
                                    >
                                        Report Missing Dog &gt;
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(MissingDogs);
