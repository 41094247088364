import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

import { setSearchLocation, getManagerLocations } from '../../redux/actions/locations';
import { LocationListItem } from '../../layout/locations/LocationListItem';
import Spinner from '../../layout/UI/Spinner';
import NavigationSearchBar from '../../layout/UI/NavigationSearchBar';
import { getGeoLocation } from '../../helpers/GeoLocation';
import { Pull2Refresh, infiniteScroll } from '../../helpers/Pull2Refresh';
import ApiError from '../../helpers/ApiError';
import Columns from '../../hoc/Columns';
import WhiteTitle from '../../hoc/WhiteTitle';

export class AdminLocationsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locationList: [],
            loading: true,
            loadingMore: false,
            currentPage: 1,
            totalPages: 1, // This it's being used by infiniteScroll->handleScroll->loadMore
            searchAPI: true
        };
    }

    getGeoLocation = getGeoLocation.bind(this)

    componentDidMount() {
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        window.scrollTo(0, 0);
        if (this.props.userData.user_role === 'superadmin') {
            this.start();
            Pull2Refresh(this.init);
        } else if (this.props.userData && this.props.userData.adminlocations && this.props.userData.adminlocations.length > 0) {
            const locs = this.props.userData.adminlocations.map(location => location.location);
            this.setState({ locationList: locs, loading: false, searchAPI: false });
        }
    }

    init = () => {
        this.setState({
            locationList: [],
            loading: true,
            loadingMore: false,
            currentPage: 1,
            totalPages: 1
        }, this.start());
    }

    start = () => {
        this.setState({ loading: true });
        getGeoLocation(this.props, this.getNearByLocations, this.props.credentials.token);
    }

    getNearByLocations = () => {
        const filter = 'all';
        this.props.dispatch(getManagerLocations(this.props.credentials.token, this.props.userData.user_location, filter, this.props.searchValue, this.state.currentPage))
            .then(response => {
                this.locationList(response.value.data);
                this.setState({
                    totalPages: response.value.headers['x-pagination-page-count']
                }, () => {
                    if (this.state.currentPage < this.state.totalPages) {
                        infiniteScroll(this, this.getNearByLocations);
                    }
                });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.locationList();
            });
    }

    locationList = moreLocations => {
        this.setState(prevState => {
            if (moreLocations) {
                return {
                    locationList: prevState.locationList.concat(moreLocations),
                    loading: false,
                    loadingMore: false
                };
            }
            return {
                loading: false,
                loadingMore: false
            };
        });
    }

    render() {
        let newlocations = null;

        if (this.state.loading) { // if loading
            newlocations = (<Spinner />);
        } else if (this.state.locationList.length > 0) { // if it has at least 1 location
            newlocations = this.state.locationList.map(location => (
                <LocationListItem
                    dispatch={this.props.dispatch}
                    credentials={this.props.credentials}
                    key={location.location_id}
                    location={location}
                    url="managelocation"
                />
            ));
        } else { // if it doesnt have any locations and you searched for something
            newlocations = (
                <h2>No Location Found</h2>
            );
        }

        const c1 = (<React.Fragment />);

        const c3 = (<React.Fragment />);

        const c2 = (
            <React.Fragment>
                <WhiteTitle>Locations</WhiteTitle>
                {newlocations}
                {this.state.loadingMore ? <Spinner /> : null}
            </React.Fragment>
        );

        return (
            <div className="mainAppContainer">
                {!(!!this.props.credentials.token && (this.props.userData.user_role === 'admin' || this.props.userData.user_role === 'superadmin' || (this.props.userData.adminlocations && this.props.userData.adminlocations.length > 0))) && (
                    <Redirect to="/" />
                )}
                <div className="bodyContent">
                    {this.state.searchAPI && (
                        <NavigationSearchBar
                            callback={this.init}
                            disp={this.props.dispatch}
                            searchInputHandler={setSearchLocation}
                            searchValue={this.props.searchValue}
                        />
                    )}
                    <div className="three-column-layout locationListSearch">
                        <Columns
                            c1={c1}
                            c2={c2}
                            c3={c3}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        searchValue: state.locations.searchLocation,
        selectedSearchFilter: state.searchFilters.selectedFilter
    };
}

export default connect(mapStateToProps)(AdminLocationsList);
