import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import ActivityDispatcher from '../../layout/ActivityDispatcher';
import ActivityNavigation from '../../layout/navigation/ActivityNavigation';
import { InviteFriends } from '../../layout/InviteFriends';
import { loginClick } from '../../helpers/ApiError';
import Columns from '../../hoc/Columns';
import WhiteTitle from '../../hoc/WhiteTitle';
import FriendRequestsComponent from '../Friends/FriendRequestsComponent';
import FindFriendsComponent from '../Friends/FindFriendsComponent';

export class Activities extends React.Component {
    componentDidMount() {
        this.start();
        window.scrollTo(0, 0);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    start = () => {
        if (!this.props.credentials?.token) loginClick();
    }

    render() {
        const c1 = (
            <div className="simple-navigation">
                <ActivityNavigation.ActivityNavigationDesktop />
            </div>
        );

        const c2 = (
            <React.Fragment>
                <WhiteTitle>Notifications</WhiteTitle>
                <ActivityDispatcher
                    credentials={this.props.credentials}
                    dispatch={this.props.dispatch}
                    type="notification"
                    disableImages={true}
                    disableActions={true}
                />
            </React.Fragment>
        );

        const c3 = (
            <React.Fragment>
                <InviteFriends wrapperClass="noWrapper" />
                <FriendRequestsComponent limit={3} infiniteScroll={false} viewMoreButton={true} />
                <WhiteTitle>Suggested Friends</WhiteTitle>
                <FindFriendsComponent perPage={3} infiniteScroll={false} />
                <Link to="/findfriends" className="square-button greenBG fontWhite h6">View all</Link>
            </React.Fragment>
        );

        return (
            <div className="mainAppContainer notification">
                <div className="bodyContent">
                    <div className="three-column-layout two-column-list">
                        <Columns
                            c1={c1}
                            c2={c2}
                            c3={c3}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        credentials: state.users.credentials,
        counter: state.activities.counter
    };
}

export default connect(mapStateToProps)(Activities);
