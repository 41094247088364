import apiConnection from '../../helpers/Api';
import { cleanJson } from '../../helpers/Functions';

export function setFirstName(payload) {
    return {
        type: 'SET_FIRST_NAME',
        payload
    };
}

export function setLastName(payload) {
    return {
        type: 'SET_LAST_NAME',
        payload
    };
}

export function setEmail(payload) {
    return {
        type: 'SET_EMAIL',
        payload
    };
}

export function setUserSocialMedia(payload) {
    return {
        type: 'SET_SOCIAL_MEDIA',
        payload
    };
}

export function register(fname, lname, email, password) {
    return {
        type: 'USER_REGISTER',
        payload: apiConnection.post('users', {
            user_firstname: fname.trim(),
            user_lastname: lname.trim(),
            user_email: email.trim(),
            user_password: password
        })
    };
}

export function fbregister(fbtoken) {
    return {
        type: 'USER_REGISTER',
        payload: apiConnection.post('users/facebook/signup?facebookAccessToken=' + fbtoken)
    };
}

export function login(email, password) {
    return {
        type: 'USER_LOGIN',
        payload: apiConnection.post('users/login?expand=images,address,parententity,adminlocations,adminlocations.location', { user_email: email.trim(), user_password: password })
    };
}

export function fblogin(fbtoken) {
    return {
        type: 'USER_LOGIN',
        payload: apiConnection.post('users/facebook/login?expand=images,address,parententity,adminlocations,adminlocations.location&facebookAccessToken=' + fbtoken)
    };
}

export function saveDeviceToken(token, data) {
    return {
        type: 'SAVE_DEVICE_TOKEN',
        payload: apiConnection.post('devices', cleanJson(data), { headers: { Authorization: token } })
    };
}

export function updateDeviceToken(token, id, data) {
    return {
        type: 'UPDATE_DEVICE_TOKEN',
        payload: apiConnection.put('devices/' + id, cleanJson(data), { headers: { Authorization: token } })
    };
}

export function forgotPassword(payload) {
    return {
        type: 'FORGOT_PASSWORD',
        payload: apiConnection.post('users/password/forgot', { user_email: payload })
    };
}

export function resetPassword(payload) {
    return {
        type: 'FORGOT_PASSWORD',
        payload: apiConnection.post('users/password/reset', payload)
    };
}

export function refreshOwnProfile(token, id) {
    return {
        type: 'REFRESH_OWN_PROFILE',
        payload: apiConnection.get('users/' + id + '?expand=images,parententity,address,adminlocations.location.images', { headers: { Authorization: token } })
    };
}

export function setUserLocation(payload) {
    return {
        type: 'SET_USER_LOCATION',
        payload
    };
}

export function logout() {
    return {
        type: 'USER_LOGOUT'
    };
}

export function updateUser(token, id, data) {
    return {
        type: 'UPDATE_USER',
        payload: apiConnection.put('users/' + id + '?expand=images,address', cleanJson(data), { headers: { Authorization: token } })
    };
}

export function updateUserRole(token, id, data) {
    return {
        type: 'UPDATE_USER_ROLE',
        payload: apiConnection.put('users/' + id, cleanJson(data), { headers: { Authorization: token } })
    };
}

export function getUserProfile(token, id) {
    return {
        type: 'GET_USER',
        payload: apiConnection.get('users/' + id + '?expand=myfriendship,parententity,lastaddress,images,address,dogs,dogs.images,dogs.breeds,dogs.dogaddress,primarylocation', { headers: { Authorization: token } })
    };
}

export function updateUserHomeAddress(token, id, data) {
    return {
        type: 'UPDATE_HOME_ADDRESS',
        payload: apiConnection.put('addresses/' + id, cleanJson(data), { headers: { Authorization: token } })
    };
}

export function userNewAddress(token, data) {
    return {
        type: 'CREATE_HOME_ADDRESS',
        payload: apiConnection.post('users/lastaddress', cleanJson(data), { headers: { Authorization: token } })
    };
}

export function findFriends(token, geopoint, filter, search, page = 1, perPage = 30) {
    let query = '?expand=myfriendship,dogs,lastaddress,address,images,' + filter + '&per-page=' + perPage + '&page=' + page;
    if (geopoint) {
        query += '&location=' + geopoint.address_latitude + ',' + geopoint.address_longitude;
    }
    if (search) {
        query += '&filter[or][][user_fullname][like]=' + search.trim();
        query += '&filter[or][][address_city][like]=' + search.trim();
        query += '&filter[or][][address_state][like]=' + search.trim();
        query += '&filter[or][][address_country][like]=' + search.trim();
    }
    return {
        type: 'GET_FIND_FRIENDS',
        payload: apiConnection.get('users' + query, { headers: { Authorization: token } })
    };
}

export function addLocationAdmin(token, data) {
    return {
        type: 'ADD_LOCATION_ADMIN',
        payload: apiConnection.post('adminlocations', cleanJson(data), { headers: { Authorization: token } })
    };
}

export function setLocationAdministratorUser(payload) {
    return {
        type: 'SET_LOCATION_ADMINISTRATOR_USER',
        payload
    };
}

export function setLocationAdministratorLocation(payload) {
    return {
        type: 'SET_LOCATION_ADMINISTRATOR_LOCATION',
        payload
    };
}

export function getLocationAdministrators(token, search, page = 1) {
    let query = '?expand=location,user,user.images&per-page=30&page=' + page;
    if (search) {
        query += '&filter[or][][user_fullname][like]=' + search;
        query += '&filter[or][][location_name][like]=' + search;
    }
    return {
        type: 'GET_LOCATION_ADMINISTRATORS',
        payload: apiConnection.get('adminlocations' + query, { headers: { Authorization: token } })
    };
}

export function getUsers(token, search, page = 1) {
    let query = '?expand=images&per-page=30&page=' + page;
    if (search) {
        query += '&filter[or][][user_fullname][like]=' + search;
    }
    return {
        type: 'GET_USERS',
        payload: apiConnection.get('users' + query, { headers: { Authorization: token } })
    };
}

export function deleteLocationAdministrators(token, id) {
    return {
        type: 'DELETE_LOCATION_ADMINISTRATORS',
        payload: apiConnection.delete('adminlocations/' + id, { headers: { Authorization: token } })
    };
}

export function sendRequest(token, id) {
    return {
        type: 'SEND_FRIEND_REQUEST',
        payload: apiConnection.post('users/friendship/' + id, {}, { headers: { Authorization: token } })
    };
}

export function updateRequest(token, id, data) {
    return {
        type: 'UPDATE_FRIEND_STATE',
        payload: apiConnection.put('users/friendship/' + id, cleanJson(data), { headers: { Authorization: token } })
    };
}

export function blockRequest(token, id) {
    return {
        type: 'UPDATE_FRIEND_STATE',
        payload: apiConnection.post('users/block/' + id, {}, { headers: { Authorization: token } })
    };
}

export function chat_toggleUser(payload) {
    return {
        type: 'CHAT_TOGGLE_USER',
        payload
    };
}

export function chat_clearUser() {
    return {
        type: 'CHAT_CLEAR_USER'
    };
}

export function findFriendsSavedStatus(payload) {
    return {
        type: 'FIND_FRIENDS_SAVED_STATUS',
        payload
    };
}

export function setUsersMainImage(payload) {
    return {
        type: 'SET_USERS_MAIN_IMAGE',
        payload
    };
}

export function setUserBio(payload) {
    return {
        type: 'SET_USER_BIO',
        payload
    };
}

export function userDelete(token, user_id) {
    return {
        type: 'USER_DELETE',
        payload: apiConnection.delete('users/' + user_id, { headers: { Authorization: token } })
    };
}

export function getDeviceTokenStatus(payload) {
    return {
        type: 'GET_DEVICE_TOKEN_STATUS',
        payload
    };
}
