import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import { findFriends, findFriendsSavedStatus } from '../../redux/actions/users';
import UserList from '../../layout/UserList';
import Spinner from '../../layout/UI/Spinner';
import ApiError from '../../helpers/ApiError';
import { getGeoLocation } from '../../helpers/GeoLocation';
import { infiniteScroll } from '../../helpers/Pull2Refresh';

export class FindFriendsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            searchText: '',
            friends: [],
            currentPage: 1,
            loadingMore: false,
            totalPages: 1, // This it's being used by infiniteScroll->handleScroll->loadMore
            createdAt: false,
            scrolled: 0
        };
    }

    getGeoLocation = getGeoLocation.bind(this);

    componentDidMount() {
        if (this.props.reusedata && this.props.findFriendsSavedStatus && this.props.findFriendsSavedStatus.createdAt) {
            this.setState({ ...this.props.findFriendsSavedStatus }, () => {
                window.scrollTo(0, this.state.scrolled);
                if (this.props.infiniteScroll === true) {
                    infiniteScroll(this, this.search);
                }
            });
        } else {
            window.scrollTo(0, 0);
            this.start();
        }
    }

    // Replace this function in a future refactory https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
    UNSAFE_componentWillReceiveProps(props) {
        if ((!!props.searchFor || props.searchFor === '') && props.searchFor !== this.state.searchText) {
            this.newSearch(props.searchFor);
        }
    }

    componentWillUnmount() {
        if (this.props.reusedata) {
            const newState = { ...this.state, scrolled: window.scrollY };
            this.props.dispatch(findFriendsSavedStatus(newState));
        }
    }

    // THE WHOLE IDEA FOR FIND FRIENDS SAVED STATUS IS THE FOLLOWING:
    // A USER CAN ENTER THE FIND FRIENDS PAGE
    // THE IDEA IS TO SAVE THE STATE OF THIS PAGE
    // IF YOU CLICK ON A USERS PROFILE AND THEN GO BACK IT WILL SHOW YOU THE SAME SCREEN WHERE...
    // YOU LEFT OFF. SO IF YOU CLICKED ON A USER ON THE 10TH PAGE YOU WILL MAINTAIN THE POSITION OF THE 10TH PAGE
    // THIS WAS DONE BECAUSE EVERYTIME YOU CLICKED ON A USER AND WENT BACK IT HAD TO RELOAD EVERYTHING AGAIN
    //
    // IT IS FAIRLY SIMPLE
    // WE JUST SAVE THE WHOLE SCREEN LOCAL STATE, AND REUSE IT WHEN THE COMPONENTDIDMOUNT
    // THE USER CAN LATER REFRESH THE PAGE, OR PULL TO REFRESH TO GET A BRAND NEW LIST
    // THIS IS DONE IN REDUX AND USING THE CREATEDAT PROPERTY
    // this.props.dispatch(findFriendsSavedStatus(this.state))

    start = () => {
        this.setState({ loading: true, friends: [], createdAt: false, totalPages: 1, currentPage: 1 });
        this.getGeoLocation(this.props, this.search, this.props.credentials.token);
    };

    newSearch = text => {
        this.setState({
            searchText: text,
            loading: true,
            friends: [],
            createdAt: false,
            totalPages: 1,
            currentPage: 1
        }, this.search);
    };

    search = () => {
        const filter = '&filter[friendship_status][neq]=accepted';
        this.props.dispatch(findFriends(this.props.credentials.token, this.props.userData.user_location, filter, this.state.searchText, this.state.currentPage, this.props.perPage))
            .then(response => {
                this.setState(
                    prevState => ({
                        loading: false,
                        totalPages: response.value.headers['x-pagination-page-count'],
                        loadingMore: false,
                        friends: prevState.friends.concat(response.value.data),
                        createdAt: moment()._d
                    }), () => {
                        // THIS IS WHERE WE SAVE THE WHOLE SCREEN STATE
                        // AS YOU CAN GUESS IT SAVES ON EVERY NEW PAGE LOADED
                        // IT IS SAVING THE WHOLE USER LIST, THE CURRENT PAGE, THE TOTAL PAGES, AND THE SEARCH TEXT, ETC
                        if (this.props.reusedata) {
                            this.props.dispatch(findFriendsSavedStatus(this.state));
                        }
                        if (this.props.infiniteScroll === true && this.state.currentPage < this.state.totalPages) {
                            infiniteScroll(this, this.search);
                        }
                    }
                );
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loading: false, loadingMore: false });
            });
    };

    render() {
        return (
            <React.Fragment>
                {(!!this.state.loading) && (<Spinner />)}
                {!!this.state.friends && (
                    <UserList
                        users={this.state.friends}
                        dispatch={this.props.dispatch}
                        credentials={this.props.credentials}
                        friendRequestsStatus={this.props.friendRequestsStatus}
                        addFriend={true}
                    />
                )}
                {!!this.state.loadingMore && (<Spinner />)}
            </React.Fragment>
        );
    }
}

FindFriendsComponent.propTypes = {
    perPage: PropTypes.number.isRequired,
    infiniteScroll: PropTypes.bool.isRequired,
    searchFor: PropTypes.string,
    reusedata: PropTypes.bool
};

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        findFriendsSavedStatus: state.users.findFriendsSavedStatus,
        friendRequestsStatus: state.users.friendRequestsStatus
    };
}

export default connect(mapStateToProps)(FindFriendsComponent);
