import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DatePicker from 'rmc-date-picker';
import moment from 'moment';
import ReactGA from 'react-ga';

import 'rmc-picker/assets/index.css';
import 'rmc-date-picker/assets/index.css';

import {
    setCreateDogSex,
    setCreateDogDOB,
    setCreateDogFixed,
    setCreateDogPlaydates,
    setCreateDogWeight,
    setCreateDogSocialMedia
} from '../../redux/actions/dogs';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import ApiError from '../../helpers/ApiError';
import { formatMonth } from '../../helpers/Functions';

class UserDogAddDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            socialMedia: {}
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (!this.props.dogCreate.sex) {
            this.props.dispatch(setCreateDogSex('male'));
        }
        if (this.props.dogCreate.fixed !== true && this.props.dogCreate.fixed !== false) {
            this.props.dispatch(setCreateDogFixed(true));
        }
        if (!this.props.dogCreate.dob) {
            this.props.dispatch(setCreateDogDOB(moment()._d));
        }
        if (this.props.dogCreate.playdates !== true && this.props.dogCreate.playdates !== false) {
            this.props.dispatch(setCreateDogPlaydates(true));
        }
        if (!this.props.dogCreate.weight) {
            this.props.dispatch(setCreateDogWeight(50));
        }
        if (!this.props.dogCreate.dog_socialmedia) { // or social_media
            this.props.dispatch(setCreateDogSocialMedia(this.state.socialMedia));
        }
        this.setState({
            socialMedia: (this.props.dogCreate && this.props.dogCreate.dog_socialmedia) || {}
        });
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    onDateChange = date => {
        this.props.dispatch(setCreateDogDOB(date));
    }

    socialMediaHandler = event => {
        this.setState({
            socialMedia: { ...this.state.socialMedia, [event.target.name]: event.target.value }
        }, () => {
            this.props.dispatch(setCreateDogSocialMedia(this.state.socialMedia));
        });
    }

    verifyData(e) {
        if (this.props.dogCreate) {
            if (!this.props.dogCreate.sex) {
                e.preventDefault();
                ApiError('Please select the sex of the dog');
            }
        } else {
            e.preventDefault();
        }
    }

    toggleSex(e) {
        this.props.dispatch(setCreateDogSex(e.target.innerText.toLowerCase()));
    }

    toggleFixed(val) {
        this.props.dispatch(setCreateDogFixed(val));
    }

    togglePlaydates(val) {
        this.props.dispatch(setCreateDogPlaydates(val));
    }

    changeWeight(e) {
        this.props.dispatch(setCreateDogWeight(e.target.value));
    }

    addWeight(action, value) {
        let v = 50;
        if (value) {
            v = value;
        }
        if (action === 'add' && v < 250) {
            this.props.dispatch(setCreateDogWeight(parseInt(v, 10) + 1));
        } else if (action === 'sub' && v > 1) {
            this.props.dispatch(setCreateDogWeight(v - 1));
        }
    }

    render() {
        return (
            <div className="mainAppContainer LoginScreens">
                <MainNavigation {...this.props} title={'Some details about ' + this.props.dogCreate.name} leftButton="back" />
                <div className="bodyContent backgroundGreen">
                    <div className="LoginWrapper wrapper maxWidth fontWhite">
                        <h1 className="section">Tell us a little more about {this.props.dogCreate.name}</h1>
                        <div className="section">{this.props.dogCreate.name} is:</div>
                        <div className="row multiChoiceHolder">
                            <div className={'multiChoice ' + (this.props.dogCreate && this.props.dogCreate.sex && this.props.dogCreate.sex === 'male' ? 'selected' : '')} onClick={e => this.toggleSex(e)}>Male</div>
                            <div className={'multiChoice ' + (this.props.dogCreate && this.props.dogCreate.sex && this.props.dogCreate.sex === 'female' ? 'selected' : '')} onClick={e => this.toggleSex(e)}>Female</div>
                        </div>
                        <div className="section">When was {this.props.dogCreate.name} born?</div>
                        <div className="selectDate fontWhite">
                            <DatePicker
                                mode="date"
                                date={this.props.dogCreate.dob ? moment(this.props.dogCreate.dob)._d : moment()._d}
                                formatMonth={formatMonth}
                                onDateChange={this.onDateChange}
                                maxDate={moment()._d}
                            />
                        </div>
                        <div className="section">Is {this.props.dogCreate.name} fixed?</div>
                        <div className="row multiChoiceHolder">
                            <div className={'multiChoice ' + (this.props.dogCreate && this.props.dogCreate.fixed === true ? 'selected' : '')} onClick={() => this.toggleFixed(true)}>Fixed</div>
                            <div className={'multiChoice ' + (this.props.dogCreate && this.props.dogCreate.fixed === false ? 'selected' : '')} onClick={() => this.toggleFixed(false)}>Not Fixed</div>
                        </div>
                        <div className="section">Is {this.props.dogCreate.name} available for Events?</div>
                        <div className="row multiChoiceHolder">
                            <div className={'multiChoice ' + (this.props.dogCreate && this.props.dogCreate.playdates === true ? 'selected' : '')} onClick={() => this.togglePlaydates(true)}>Yes</div>
                            <div className={'multiChoice ' + (this.props.dogCreate && this.props.dogCreate.playdates === false ? 'selected' : '')} onClick={() => this.togglePlaydates(false)}>No</div>
                        </div>
                        <div className="section">How much does {this.props.dogCreate.name} weigh?</div>
                        <div className="row">
                            <input className="slider white" id="slider" type="range" min="1" max="250" step="1" value={this.props.dogCreate.weight || ''} onChange={e => this.changeWeight(e)} />
                            <div className="sliderValue">
                                <span className="sub" onClick={() => this.addWeight('sub', this.props.dogCreate.weight)}> - </span> <span>{parseFloat(this.props.dogCreate.weight).toFixed(2)}lbs</span> <span className="sub" onClick={() => this.addWeight('add', this.props.dogCreate.weight)}> + </span>
                            </div>
                        </div>
                        <div className="section">Add Social Media for {this.props.dogCreate.name}</div>
                        <div className="section">
                            <div className="innerLabelInput">
                                <span className="blocking-span">
                                    <input className="inputText" name="facebook" type="text" onChange={this.socialMediaHandler} value={(this.state.socialMedia && this.state.socialMedia.facebook) || ''} />
                                    <span className="floating-label">Facebook</span>
                                </span>
                            </div>
                            <div className="innerLabelInput">
                                <span className="blocking-span">
                                    <input className="inputText" name="twitter" type="text" onChange={this.socialMediaHandler} value={(this.state.socialMedia && this.state.socialMedia.twitter) || ''} />
                                    <span className="floating-label">Twitter</span>
                                </span>
                            </div>
                            <div className="innerLabelInput">
                                <span className="blocking-span">
                                    <input className="inputText" name="instagram" type="text" onChange={this.socialMediaHandler} value={(this.state.socialMedia && this.state.socialMedia.instagram) || ''} />
                                    <span className="floating-label">Instagram</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="LoginWrapper wrapper maxWidth">
                        <Link to={'/user/dogadd/description' + this.props.history.location.search} className="rightRoundButton whiteBorder whiteOpaque fontWhite" onClick={e => this.verifyData(e)}>
                            Next &gt;
                        </Link>
                    </div>
                </div>
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        dogCreate: state.dogs.dogCreate
    };
}

export default connect(mapStateToProps)(UserDogAddDetails);
