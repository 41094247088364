import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';

import { getLocation } from '../../redux/actions/locations';
import { deletePicture, setPicture } from '../../redux/actions/all';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import GalleryLightbox from '../../layout/gallery/GalleryLightbox';
import NotFound from '../../layout/NotFound';
import ApiError from '../../helpers/ApiError';

class LocationGallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            location: null,
            loading: true,
            // image: null,
            gallery: []
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.props.dispatch(getLocation(this.props.credentials.token, this.props.match.params.id))
            .then(response => {
                this.setState({
                    location: response.value.data,
                    loading: false,
                    gallery: response.value.data.images
                    // image:  response.value.data.images[0].image_mediumurl
                });
            })
            .catch(() => this.setState({ loading: false }));
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    handleClickRemoveImage = id => {
        this.props.dispatch(deletePicture(this.props.credentials.token, id))
            .then(res => {
                console.log(res);
                this.setState(prevState => {
                    const newGallery = prevState.gallery.filter(image => image.image_id !== id);
                    return {
                        gallery: newGallery
                    };
                });
                toast.success('Deleted Image Successfully');
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    handleClickSetImage = id => {
        this.props.dispatch(setPicture(this.props.credentials.token, id))
            .then(() => {
                // this.setState({ redirect: true });
                toast.success('Updated Profile Successfully');
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    render() {
        let gallery = null;
        let notFound = null;

        if (this.state.location && this.state.location.images && this.state.location.images.length > 0 && !this.state.loading) {
            gallery = (
                <GalleryLightbox
                    images={this.state.gallery}
                    userData={this.props.userData}
                    count="all"
                    size="normal"
                    handleClickSetImage={this.handleClickSetImage}
                    handleClickRemoveImage={this.handleClickRemoveImage}
                />
            );
        } else if (!this.state.loading) {
            notFound = (<NotFound type="emptyGallery" />);
        }

        return (
            <div className="mainAppContainer">
                <MainNavigation {...this.props} title={this.state.location && this.state.location.location_name} leftButton="back" />
                <div className="bodyContent">
                    {gallery && (
                        <div className="locationProfile locationGallery maxWidth">
                            {gallery}
                        </div>
                    )}
                    {notFound}
                </div>
                <SecondaryNavigation selected="search" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(LocationGallery);
