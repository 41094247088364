import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { findFriends, updateRequest } from '../../redux/actions/users';
import UserListRequest from '../../layout/UserListRequest';
import Spinner from '../../layout/UI/Spinner';
import { infiniteScroll } from '../../helpers/Pull2Refresh';

export class FriendRequestComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            reloading: false,
            friendsList: [],
            currentPage: 1,
            loadingMore: false,
            totalPages: 1, // This it's being used by infiniteScroll->handleScroll->loadMore
            listOfAnsweredUsers: []
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.find();
    }

    find = () => {
        this.setState({ reloading: true });
        const filter = '&filter[friendship_status][eq]=pending&filter[receiver_id][eq]=' + this.props.credentials.user_id;
        if (this.props.credentials?.token) {
            this.props.dispatch(findFriends(this.props.credentials.token, this.props.userData.user_location, filter, null, this.state.currentPage, this.props.limit || null))
                .then(response => {
                    this.filter(response.value.data);
                    this.setState({ loading: false, reloading: false, totalPages: response.value.headers['x-pagination-page-count'], loadingMore: false }, () => {
                        if (this.props.infiniteScroll === true && this.state.currentPage < this.state.totalPages) {
                            infiniteScroll(this, this.find);
                        }
                    });
                })
                .catch(() => {
                    // ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message)||err.message)
                    this.setState({ loading: false, reloading: false, loadingMore: false });
                });
        }
    }

    acceptRequest = (e, id) => {
        e.preventDefault();
        this.props.dispatch(updateRequest(this.props.credentials.token, id, { friendship_status: 'accepted' }))
            .then(() => {
                this.setState(prevState => ({ listOfAnsweredUsers: prevState.listOfAnsweredUsers.concat(id) }));
            });
    }

    declineRequest = (e, id) => {
        e.preventDefault();
        this.props.dispatch(updateRequest(this.props.credentials.token, id, { friendship_status: 'declined' }))
            .then(() => {
                this.setState(prevState => ({ listOfAnsweredUsers: prevState.listOfAnsweredUsers.concat(id) }));
            });
    }

    filter = data => {
        const filteredUsers = data.filter(item => (!!item.myfriendship && item.myfriendship.receiver_id === this.props.credentials.user_id));
        this.setState(prevState => ({
            friendsList: prevState.friendsList.concat(filteredUsers),
            loading: false,
            reloading: false
        }));
    }

    render() {
        return (
            <React.Fragment>
                {!!this.props.credentials?.token && (!!this.state.loading || !!this.state.reloading) && !this.state.loadingMore && (
                    <Spinner />
                )}
                {this.state.friendsList.length > 0 && (
                    <React.Fragment>
                        <UserListRequest
                            {...this.props}
                            users={this.state.friendsList}
                            onClickAccept={this.acceptRequest}
                            onClickDecline={this.declineRequest}
                            listOfAnsweredUsers={this.state.listOfAnsweredUsers}
                        />
                        {this.props.viewMoreButton === true && this.state.currentPage < this.state.totalPages && (
                            <Link to="/friendrequest" className="square-button greenBG fontWhite h6">
                                View all
                            </Link>
                        )}
                        <div className="spacerSmall" />
                    </React.Fragment>
                )}
                {!!this.state.loadingMore && (
                    <Spinner />
                )}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(FriendRequestComponent);
