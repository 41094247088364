import React from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import ChatList from '../../layout/ChatList';
import Spinner from '../../layout/UI/Spinner';
import NotFound from '../../layout/NotFound';
import ActivityNavigation from '../../layout/navigation/ActivityNavigation';
import sb from '../../helpers/SendBird';
import { loginClick } from '../../helpers/ApiError';
import Columns from '../../hoc/Columns';
import WhiteTitle from '../../hoc/WhiteTitle';
import ChatRoom from './ChatRoom';
import HelmetTags from '../../helmetTags';

export class Chat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            channelList: [],
            loading: true,
            reloading: false,
            chaturl: false
        };
    }

    componentDidMount() {
        this.start();
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        if (this.props.match && this.props.match.params && this.props.match.params.id) {
            this.setState({ chaturl: this.props.match.params.id });
        }
    }

    getGroupChat() {
        const that = this;
        const channelListQuery = sb.GroupChannel.createMyGroupChannelListQuery();
        channelListQuery.includeEmpty = false;
        channelListQuery.limit = 100; // pagination limit could be set up to 100
        if (channelListQuery.hasNext) {
            channelListQuery.next((channelList, error) => {
                if (error) {
                    that.setState({ loading: false, reloading: false });
                    return;
                }
                that.setState({ channelList, loading: false, reloading: false });
            });
        }
    }

    openChatBox = (e, url) => {
        e.preventDefault();
        this.setState({ chaturl: false }, () => {
            this.setState({ chaturl: url });
        });
    }

    start = () => {
        const that = this;
        this.setState({ reloading: true });
        if (this.props.credentials && this.props.credentials.user_id) {
            sb.connect(
                this.props.credentials.user_id,
                this.props.credentials.token,
                () => {
                    that.getGroupChat();
                }
            );
        } else {
            loginClick();
        }
    }

    render() {
        const pageTitle = 'Pooch Chatroom ';
        const pageDescription = 'Chat with your friends!';

        const c1 = (
            <div className="simple-navigation">
                <ActivityNavigation.ActivityNavigationDesktop />
            </div>
        );

        const c2 = (
            <React.Fragment>
                {this.state.chaturl && (
                    <ChatRoom chaturl={this.state.chaturl} />
                )}
                {!this.state.chaturl && (
                    <div className="select-chat">
                        <WhiteTitle>
                            {this.state.channelList.length > 0 && (
                                <span>Select one of your recent conversations</span>
                            )}
                            {this.state.channelList.length === 0 && (
                                <span>Create a conversation</span>
                            )}
                        </WhiteTitle>
                    </div>
                )}
            </React.Fragment>
        );

        const c3 = (
            <React.Fragment>
                <div className="chat-title">
                    <WhiteTitle>Chat</WhiteTitle>
                    <Link to="/chatcreate" className="create-chat">
                        <i className="icn icon-compose-msg" />
                    </Link>
                </div>
                {(!!this.state.loading || !!this.state.reloading) && (<Spinner />)}
                {!this.state.loading && !this.state.channelList.length && (<NotFound type="chat" />)}
                {!this.state.loading && !!this.state.channelList.length && (
                    <ChatList
                        {...this.props}
                        openChatBox={this.openChatBox}
                        chatList={this.state.channelList}
                    />
                )}
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <HelmetTags title={pageTitle} description={pageDescription} />
                <div className="mainAppContainer chat-desktop">
                    <div className="bodyContent">
                        <div className="three-column-layout two-column-list">
                            <Columns
                                c1={c1}
                                c2={c2}
                                c3={c3}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(Chat);
