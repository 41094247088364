import React from 'react';
import FacebookLogin from 'react-facebook-login';
import PropTypes from 'prop-types';

import Config from './helpers/Config';

/**
 * Custom component that includes all the used prop for the <FacebookLogin /> to be centralized in just one file
 * This is only used a login feature for Browser log in, not App
 *
 * @param {function} callback The function to trigger after Facebook return the user information
 * @param {string} textButton The text to be shown in the button
 * @param {string} className The class (styles) to be used in the button
 * @param {string} redirectUri The route that will be rederected to after facebook complete the login,
 *                             Do not forget that all routes used here must be added in the Facebook Dashboard whitelist
 */
const FacebookLoginButton = ({
    callback,
    textButton = 'Login with Facebook',
    className = 'wideButtons fb-button',
    redirectUri = window.location.href
}) => (
    <FacebookLogin
        version="5.0" // Used the minimum suggested version
        textButton={textButton}
        appId={process.env.REACT_APP_API_FACEBOOK_APP_ID}
        autoLoad={false}
        fields="name,email"
        cookie={false}
        callback={callback}
        cssClass={className}
        icon="icn icon-facebook1"
        authType="reauthenticate"
        redirectUri={redirectUri}
        isMobile={false} // Some mobile browsers (like safari) block the pop-up used for this feature, so to avoid use a pop-up and use another tab for this, it must be false for mobile
        disableMobileRedirect={Config.isMobileDevice} // This is used in conjunction with the above one, if the `isMobile` is false, then this must be true
    />
);
FacebookLoginButton.propTypes = {
    callback: PropTypes.func.isRequired,
    textButton: PropTypes.string,
    className: PropTypes.string,
    redirectUri: PropTypes.string
};

export default FacebookLoginButton;
