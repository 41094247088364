import React from 'react';
import { toast } from 'react-toastify';

import { saveDeviceToken, getDeviceTokenStatus } from '../redux/actions/users';
import BasicConfirm from '../layout/UI/BasicConfirm';

export function checkPermissions(dispatch) {
    if (window.FirebasePlugin) {
        window.FirebasePlugin.hasPermission(data => {
            dispatch(getDeviceTokenStatus(data.isEnabled));
        });
    }
}

export function getDeviceToken(dispatch, credentials) {
    if (window.FirebasePlugin) {
        window.FirebasePlugin.hasPermission(data => {
            if (!data.isEnabled) {
                BasicConfirm(
                    'Notifications',
                    'Receive Notifications when someone invites you or sends you a message',
                    'Yes, Allow',
                    window.FirebasePlugin.grantPermission
                );
            }
        });

        window.FirebasePlugin.getToken(deviceToken => {
            let deviceDescription = null;

            if (window.device && window.device.platform) {
                deviceDescription = { deviceOS: window.device.platform, deviceType: window.device.model };
            }

            const data = {
                device_token: deviceToken,
                user_id: credentials.user_id,
                device_status: 'logged in',
                device_description: deviceDescription
            };
            dispatch(saveDeviceToken(credentials.token, data));
        }, error => {
            console.error('getTokenError', error);
            return false;
        });
    }
    return false;
}

if (window.FirebasePlugin) {
    window.FirebasePlugin.onNotificationOpen(notification => {
        const title = (notification.aps && notification.aps.alert && notification.aps.alert.title) || notification.title;
        const body = (notification.aps && notification.aps.alert && notification.aps.alert.body) || notification.body;

        let url = '#';

        if (notification.playdate_id) {
            url = '#/events/' + notification.playdate_id;
        } else if (notification.dog_id) {
            url = '#/dog/' + notification.dog_id;
        } else if (notification.activity_id) {
            url = '#/activity/' + notification.activity_id;
        } else if (notification.chat_id) {
            url = '#/chat/channel/' + notification.chat_id;
        } else if (notification.sender_id) {
            url = '#/user/profile/' + notification.sender_id;
        }

        if (notification.tap) {
            window.location.replace(url);
        } else {
            toast.info(
                <div className="notificationToast" onClick={() => window.location.replace(url)}>
                    <div className="notificationTitle">{title}</div>
                    <div className="notificationBody">{body}</div>
                </div>
            );
        }
    }, error => {
        console.error('onNotificationOpen', error);
    });
}
