import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { pc_toggleDog } from '../../redux/actions/playdates';
import { getUserProfile } from '../../redux/actions/users';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import PetList from '../../layout/PetList';

class PlaydatesCreateDogSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dogs: []
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(getUserProfile(this.props.credentials.token, this.props.credentials.user_id))
            .then(response => this.setState({ dogs: response.value.data.dogs }));
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    selectDog = (e, dog) => {
        e.preventDefault();
        this.props.dispatch(pc_toggleDog(dog.id));
    }

    finishDogSelection = e => {
        e.preventDefault();
        this.props.history.push('/events/create/event');
    }

    render() {
        return (
            <div className="mainAppContainer playdates">
                <MainNavigation title="Select your Dogs" leftButton="back" rightButton="done" rightButtonOnClick={this.finishDogSelection} />
                <div className="bodyContent wrapper">
                    <div className="maxWidth">
                        <Link className="wideButtons greenBorder greenBG fontWhite spacerVertical" to="/user/dogadd?redirect=/events/create/event/dogselection">
                            <i className="icn icon-more-add" /> Add a Dog!
                        </Link>
                        <PetList dogs={this.state.dogs} markSelected={true} selectedDogs={this.props.selectedDogs} onClick={this.selectDog} />
                    </div>
                </div>
                <SecondaryNavigation selected="events" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        selectedDogs: state.playdates.create.selectedDogs,
        selectedPark: state.playdates.create.selectedPark,
        selectedDate: state.playdates.create.selectedDate,
        selectedTime: state.playdates.create.selectedTime,
        invitationOnly: state.playdates.create.invitationOnly,
        comment: state.playdates.create.comment
    };
}

export default connect(mapStateToProps)(PlaydatesCreateDogSelection);
