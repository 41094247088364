import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { getLocations, setSearchLocation } from '../../redux/actions/locations';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import { LocationListItem } from '../../layout/locations/LocationListItem';
import Spinner from '../../layout/UI/Spinner';
import NavigationSearchBar from '../../layout/UI/NavigationSearchBar';
import NotFound from '../../layout/NotFound';
import { getGeoLocation } from '../../helpers/GeoLocation';
import { Pull2Refresh, infiniteScroll } from '../../helpers/Pull2Refresh';
import ApiError from '../../helpers/ApiError';

export class ListSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locationList: [],
            loading: true,
            loadingMore: false,
            currentPage: 1,
            totalPages: 1 // This it's being used by infiniteScroll->handleScroll->loadMore
        };
    }

    getGeoLocation = getGeoLocation.bind(this)

    componentWillMount() {
        this.start();
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        Pull2Refresh(this.init);
    }

    init = () => {
        this.setState({
            locationList: [],
            loading: true,
            loadingMore: false,
            currentPage: 1,
            totalPages: 1
        }, this.start());
    }

    start = () => {
        this.setState({ loading: true });
        getGeoLocation(this.props, this.getNearByLocations, this.props.credentials.token, true);
    }

    getNearByLocations = () => {
        const filter = this.props.selectedSearchFilter;
        this.props.dispatch(getLocations(this.props.credentials.token, this.props.userData.user_location, filter, this.props.searchValue, this.state.currentPage))
            .then(response => {
                this.locationList(response.value.data);
                this.setState({
                    totalPages: response.value.headers['x-pagination-page-count']
                }, () => {
                    if (this.state.currentPage < this.state.totalPages) {
                        infiniteScroll(this, this.getNearByLocations);
                    }
                });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.locationList();
            });
    }

    locationList = moreLocations => {
        this.setState(prevState => {
            if (moreLocations) {
                return {
                    locationList: prevState.locationList.concat(moreLocations),
                    loading: false,
                    loadingMore: false
                };
            }
            return {
                loading: false,
                loadingMore: false
            };
        });
    }

    render() {
        let newlocations = null;
        if (this.state.loading) { // if loading
            newlocations = (<Spinner />);
        } else if (this.state.locationList.length > 0) { // if it has at least 1 location
            let list = [...this.state.locationList];
            if (this.state.currentPage === 1) {
                const normalLocations = this.state.locationList.filter(location => location.location_featured === 0);
                const featuredLocations = this.state.locationList.filter(location => location.location_featured === 1);
                list = [...featuredLocations, ...normalLocations];
            }
            newlocations = list.map(location => (
                <LocationListItem
                    {...this.props}
                    key={location.location_id}
                    location={location}
                    url="location/profile"
                />
            ));
        } else if (this.props.searchValue) { // if it doesnt have any locations and you searched for something
            newlocations = (
                <NotFound
                    type="searchlocations"
                    onClear={() => { this.props.dispatch(setSearchLocation('')); this.init(); }}
                    class="backgroundRed"
                />
            );
        } else {
            // ideally if there are no locations, but you didn\'t search for anything means that the filter is empty
            newlocations = (<NotFound type="filteredlocations" class="backgroundRed" />);
        }

        return (
            <div className="mainAppContainer">
                <MainNavigation title="List Search" leftButton="map" leftLink="/search" rightButton="filter" rightLink="/categories" />
                <div className="bodyContent locationListSearch pull2refresh">
                    <NavigationSearchBar
                        callback={this.init}
                        disp={this.props.dispatch}
                        searchInputHandler={setSearchLocation}
                        searchValue={this.props.searchValue}
                    />
                    {newlocations}
                    {this.state.loadingMore && (<Spinner />)}
                </div>
                <SecondaryNavigation selected="search" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        searchValue: state.locations.searchLocation,
        selectedSearchFilter: state.searchFilters.selectedFilter
    };
}

export default connect(mapStateToProps)(ListSearch);
