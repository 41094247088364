import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Textarea from 'react-textarea-autosize';

import { contactUsProfile, updateContactUsProfile } from '../../redux/actions/all';
import { addLocationAdmin, getLocationAdministrators, deleteLocationAdministrators } from '../../redux/actions/users';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import Spinner from '../../layout/UI/Spinner';
import NotFound from '../../layout/NotFound';
import BasicConfirm from '../../layout/UI/BasicConfirm';
import Avatar from '../../layout/UI/Avatar';
import { guard, urlFriendly } from '../../helpers/Functions';
import ApiError from '../../helpers/ApiError';

export class AdminContactUsProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            info: {}
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(contactUsProfile(this.props.credentials.token, this.props.match.params.id))
            .then(res => {
                this.setState({
                    loading: false,
                    info: res.value.data
                });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loading: false });
            });
    }

    handleApproveLocationAdmin = () => {
        BasicConfirm(
            'Are you sure?',
            'You want to grant admin access to this user?',
            'Yes, Confirmed!',
            this.saveApproveLocationAdmin
        );
    }

    saveApproveLocationAdmin = () => {
        this.props.dispatch(addLocationAdmin(this.props.credentials.token, { user_id: this.state.info.user_id, location_id: this.state.info.location_id }))
            .then(() => this.updateLocationAdmin('approved'))
            .catch(err => ApiError((err && err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || 'There was an error'));
    }

    showInfo = info => {
        const arr = [];
        for (const i in info) {
            let desc = info[i];
            if (desc.indexOf('http') > -1) {
                desc = <a className="fontGreen" href={desc}>{desc}</a>;
            }
            arr.push(
                <div key={i} className="table row">
                    <div>{i.charAt(0).toUpperCase() + i.slice(1)}</div>
                    <div className="fontGreen">{desc}</div>
                </div>
            );
        }
        return arr;
    }

    handleDenyLocationAdmin = e => {
        e.preventDefault();
        BasicConfirm(
            'Are you sure?',
            'Do you want to deny ' + this.state.info.user.user_firstname + ' ' + this.state.info.user.user_lastname + "'s request to claim business?",
            'Yes, Deny it!',
            this.deleteUserFromAdmin
        );
    }

    deleteUserFromAdmin = () => {
        this.props.dispatch(getLocationAdministrators(this.props.credentials.token, this.state.info.user.user_firstname + ' ' + this.state.info.user.user_lastname))
            .then(res => {
                let listOfLocations = res.value.data;
                listOfLocations = listOfLocations.filter(loc => loc.location_id === this.state.info.location_id);
                if (listOfLocations.length > 0) {
                    this.props.dispatch(deleteLocationAdministrators(this.props.credentials.token, listOfLocations[0].adminlocation_id))
                        .then(() => { this.updateLocationAdmin('rejected'); })
                        .catch(err => ApiError((err && err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || 'There was an error'));
                } else {
                    this.updateLocationAdmin('rejected');
                }
            });
        // .catch(err => { });
    }

    updateLocationAdmin = claimBusinessStatus => {
        let info = {};
        if (this.state.info && this.state.info.contactus_additionalinfo) {
            info = { ...this.state.info, contactus_additionalinfo: { ...this.state.info.contactus_additionalinfo, response_status: claimBusinessStatus } };
        } else if (this.state.info) {
            info = { ...this.state.info, contactus_additionalinfo: { response_status: claimBusinessStatus } };
        }
        this.setState({ info }, this.save);
    }

    commentChange = e => {
        const comment = e.target.value;
        let info = {};
        if (this.state.info && this.state.info.contactus_additionalinfo) {
            info = { ...this.state.info, contactus_additionalinfo: { ...this.state.info.contactus_additionalinfo, response_comment: comment } };
        } else if (this.state.info) {
            info = { ...this.state.info, contactus_additionalinfo: { response_comment: comment } };
        }
        this.setState({ info });
    }

    save = () => {
        this.props.dispatch(updateContactUsProfile(this.props.credentials.token, this.props.match.params.id, this.state.info))
            .then(() => {
                toast.success('Form Updated Correctly');
            })
            .catch(err => ApiError((err && err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || 'There was an error'));
    }

    render() {
        return (
            <div className="mainAppContainer">
                <MainNavigation title="Manage Contact Form" leftButton="back" />
                {this.state.loading && (
                    <div className="bodyContent wrapper noPaddingTop">
                        <Spinner />
                    </div>
                )}
                {!this.state.loading && !this.state.info.contactus_type && (
                    <NotFound type="form" />
                )}
                {!this.state.loading && !!this.state.info.contactus_type && !!this.state.info.user && this.state.info.contactus_additionalinfo
                    && (
                        <div className="bodyContent noPaddingTop">
                            <div className="wrapper maxWidth">
                                <div className="participantList manage-admin">
                                    <div className="participantListItem padding">
                                        <div className="user row">
                                            <Link to={'/user/profile/' + this.state.info.user_id}>
                                                <div className="round-avatar user colored-icon">
                                                    <Avatar
                                                        src={guard(this.state.info, '.user.images.0.image_smallurl')}
                                                        alt={this.state.info.user.user_firstname + ' ' + this.state.info.user.user_lastname}
                                                        type="user"
                                                    />
                                                </div>
                                            </Link>
                                            <div className="info">
                                                <div className="name">
                                                    <Link className="fontGreen" to={'/user/profile/' + this.state.info.user_id}>{this.state.info.user.user_firstname + ' ' + this.state.info.user.user_lastname}</Link><br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="spacerSmall" />
                                {this.state.info && this.state.info.contactus_type && (
                                    <h3>
                                        {
                                            this.state.info.contactus_type.replace(/([A-Z])/g, ' $1')
                                                // uppercase the first character
                                                .replace(/^./, str => str.toUpperCase())
                                        }
                                        {this.state.info.contactus_additionalinfo.response_status && (
                                            <span className={this.state.info.contactus_additionalinfo.response_status === 'rejected' ? 'fontRed' : 'fontGreen'}> ({this.state.info.contactus_additionalinfo.response_status})</span>
                                        )}
                                    </h3>
                                )}
                                <div className="spacerSmall" />
                                <div className="contactus-form-data">
                                    <div className="column">
                                        {this.state.info.contactus_additionalinfo.email && (
                                            <div className="form-data row">
                                                <div>Email:</div>
                                                <div>
                                                    <a className="claim-business-email" href={'mailto:' + this.state.info.contactus_additionalinfo.email} target="_top">{this.state.info.contactus_additionalinfo.email}</a>
                                                </div>
                                            </div>
                                        )}
                                        <div className="form-data row">
                                            <div>Location:</div>
                                            <div>
                                                <Link className="claim-business-location" to={'/location/profile/' + this.state.info.location_id + '/' + urlFriendly(this.state.info.location.location_name)}>
                                                    {this.state.info.location.location_name + '' + (this.state.info.location.address.address_city ? ', ' + this.state.info.location.address.address_city : '') + (this.state.info.location.address.address_country ? ', ' + this.state.info.location.address.address_country : '')}
                                                </Link>
                                            </div>
                                        </div>
                                        {this.state.info.contactus_additionalinfo && this.state.info.contactus_additionalinfo.title && (
                                            <div className="form-data row">
                                                <div>Title:</div>
                                                <div className="claim-business-title">{this.state.info.contactus_additionalinfo.title}</div>
                                            </div>
                                        )}
                                        {this.state.info.contactus_additionalinfo.website && (
                                            <div className="form-data row">
                                                <div>Website:</div>
                                                <div>
                                                    <a className="claim-business-website" target="_blank" href={'http://' + this.state.info.contactus_additionalinfo.website} rel="noopener noreferrer">
                                                        {this.state.info.contactus_additionalinfo.website}
                                                    </a>
                                                </div>
                                            </div>
                                        )}
                                        {this.state.info.contactus_additionalinfo.business_phone && (
                                            <div className="form-data row">
                                                <div>Business Phone:</div>
                                                <div>
                                                    <a className="claim-business-phone" href={'tel:' + this.state.info.contactus_additionalinfo.business_phone}>{this.state.info.contactus_additionalinfo.business_phone}</a>
                                                </div>
                                            </div>
                                        )}
                                        {this.state.info.contactus_additionalinfo.comments && (
                                            <div className="form-data row">
                                                <div>Comments:</div>
                                                <div className="claim-business-comments">
                                                    {this.state.info.contactus_additionalinfo.comments && this.state.info.contactus_additionalinfo.comments.split('\n').map((item, key) => <div key={key}>{item}</div>)}
                                                </div>
                                            </div>
                                        )}
                                        <div className="form-data row">
                                            <div>User Profile:</div>
                                            <div>
                                                <Link to={'/user/profile/' + this.state.info.user_id} className="claim-business-user-profile">{this.state.info.user.user_firstname + ' ' + this.state.info.user.user_lastname}</Link>
                                            </div>
                                        </div>
                                        {this.state.info.contactus_additionalinfo.user_phone && (
                                            <div className="form-data row">
                                                <div>User Phone:</div>
                                                <div>
                                                    <a className="claim-business-user-phone" href={'tel:' + this.state.info.contactus_additionalinfo.user_phone}>{this.state.info.contactus_additionalinfo.user_phone}</a>
                                                </div>
                                            </div>
                                        )}
                                        {this.state.info.contactus_additionalinfo.user_location && (
                                            <div className="form-data row">
                                                <div>User Location:</div>
                                                <div className="claim-business-user-city">{this.state.info.contactus_additionalinfo.user_location}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {this.state.info && this.state.info.contactus_type && this.state.info.contactus_type === 'claimBusiness' && (
                                    <div>
                                        <div className="spacerSmall" />
                                        <h5>Admin Comment</h5>
                                        <Textarea onChange={this.commentChange} className="niceTextarea smallmargin" placeholder="Write a comment or reason for granting or rejecting request" value={(this.state.info && this.state.info.contactus_additionalinfo && this.state.info.contactus_additionalinfo.response_comment) || ''} />
                                        <div className="spacerSmall" />
                                        <div onClick={this.save} className="rightRoundButton greenBorder backgroundGreen fontWhite">
                                            Save Comment
                                        </div>
                                        <div className="spacerSmall" />
                                        <div className="grant-access">
                                            <div onClick={this.handleApproveLocationAdmin} className="rightRoundButton greenBorder backgroundGreen fontWhite">
                                                Grant Admin Access
                                            </div>
                                            <div onClick={this.handleDenyLocationAdmin} className="rightRoundButton redBorder backgroundRed fontWhite">
                                                Deny Admin Access
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(AdminContactUsProfile);
