import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import Avatar from '../layout/UI/Avatar';
import { guard } from '../helpers/Functions';

class ContactFormList extends React.Component {
    itemMap(list) {
        return list.map((item, index) => {
            let responseStatus = (item.contactus_additionalinfo && item.contactus_additionalinfo.response_status) || null;
            if (responseStatus === 'rejected') {
                responseStatus = (<span className="fontRed">({responseStatus})</span>);
            } else if (responseStatus) {
                responseStatus = (<span className="fontGreen">({responseStatus})</span>);
            }

            return (
                <div className="participantListItem padding" key={index}>
                    <div className="user row">
                        <Link to={'/user/profile/' + item.user_id}>
                            <div className="round-avatar user colored-icon">
                                <Avatar
                                    src={guard(item, '.user.images.0.image_smallurl')}
                                    alt={item.user.user_firstname + ' ' + item.user.user_lastname}
                                    type="user"
                                />
                            </div>
                        </Link>
                        <div className="info">
                            <div className="name">
                                <Link className="fontGreen" to={'/user/profile/' + item.user_id}>{item.user.user_firstname + ' ' + item.user.user_lastname}</Link><br />
                                Date: {moment.tz(item.contactus_createdat, 'Etc/GMT').format('MMM DD YYYY')} ({moment.tz(item.contactus_createdat, 'Etc/GMT').fromNow()}) <br />
                                Subject: <Link className="fontGreen" to={'/managecontactus/' + item.contactus_id}>{item.contactus_type.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())} {responseStatus}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    render() {
        return (
            <div className="participantList">
                {this.props.list && this.itemMap(this.props.list)}
            </div>
        );
    }
}

export default ContactFormList;
