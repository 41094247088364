import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class ActivityNavigationMobile extends React.Component {
    render() {
        return (
            <React.Fragment>
                {this.props.showTitle
                    && (
                        <div className="simple-title">
                            <i className="icn icon-activity fontBlue" /> <b className="fontBlack">Feed</b>
                        </div>
                    )}
                <div className="tabSubmenu">
                    <div className="navigationTabs">
                        <div className="maxWidth row">
                            <div className={`navigationIcon ${this.props.selected === 'notifications' ? 'active' : ''}`}>
                                <Link to="/notifications"><i className="icn icon-world" /></Link>
                            </div>
                            <Link to="/dogs" className={(this.props.selected === 'dogs' ? 'active' : '')}>
                                Dogs
                            </Link>
                            <Link to="/activities" className={(this.props.selected === 'activities' ? 'active' : '')}>
                                Friends
                                {!!this.props.counter.NewActivities && this.props.counter.NewActivities !== '0'
                                    && (
                                        <div className="badge-holder-main">
                                            <div className="badge-holder">
                                                <div className="badge">
                                                    {this.props.counter.NewActivities}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </Link>
                            <div className={`navigationIcon ${this.props.selected === 'chat' ? 'active' : ''}`}>
                                <Link to="/chat"><i className="icn icon-no-messages" /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

class ActivityNavigationDesktop extends React.Component {
    render() {
        return (
            <React.Fragment>
                {this.props.showTitle
                    && (
                        <div className="simple-title">
                            <i className="icn icon-activity fontBlue" /> <b className="fontBlack">Feed</b>
                        </div>
                    )}
                <div className="tabSubmenu">
                    <div className="navigationTabs">
                        <div className="maxWidth row">
                            <Link to="/dogs" className={(this.props.selected === 'dogs' ? 'active' : '')}>
                                Dogs
                            </Link>
                            <Link to="/activities" className={(this.props.selected === 'activities' ? 'active' : '')}>
                                Friends
                                {!!this.props.counter.NewActivities && this.props.counter.NewActivities !== '0'
                                    && (
                                        <div className="badge-holder-main">
                                            <div className="badge-holder">
                                                <div className="badge">
                                                    {this.props.counter.NewActivities}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </Link>
                            <Link to="/chat" className={(window.location.pathname === '/chat' ? 'active' : '')}>
                                Chat
                            </Link>
                            <Link to="/notifications" className={(window.location.pathname === '/notifications' ? 'active' : '')}>
                                Notifications
                                {!!this.props.counter.NewNotifications && this.props.counter.NewNotifications !== '0'
                                    && (
                                        <div className="badge-holder-main">
                                            <div className="badge-holder">
                                                <div className="badge">
                                                    {this.props.counter.NewNotifications}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </Link>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        counter: state.activities.counter
    };
}

export default {
    ActivityNavigationDesktop: connect(mapStateToProps)(ActivityNavigationDesktop),
    ActivityNavigationMobile: connect(mapStateToProps)(ActivityNavigationMobile)
};

export { ActivityNavigationMobile, ActivityNavigationDesktop };
