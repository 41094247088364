import apiConnection from '../../helpers/Api';
import { cleanJson } from '../../helpers/Functions';

export function setCreateDogName(payload) {
    return {
        type: 'SET_CREATE_DOG_NAME',
        payload
    };
}

export function setCreateBreedsSelected(breed_id) {
    return {
        type: 'SET_CREATE_BREEDS_SELECTED',
        payload: breed_id
    };
}

export function setCreateDogPictureFile(payload) {
    return {
        type: 'SET_CREATE_DOG_PICTURE_FILE',
        payload
    };
}

export function clearDogPictureFile() {
    return {
        type: 'CLEAR_DOG_PICTURE_FILE'
    };
}

export function clearDogCreate() {
    return { type: 'CLEAR_DOG_CREATE' };
}

export function clearMissingDogs() {
    return { type: 'CLEAR_DOG_MISSING' };
}

export function setCreateDogPicture(payload) {
    return {
        type: 'SET_CREATE_DOG_PICTURE',
        payload
    };
}

export function setCreateDogSex(payload) {
    return {
        type: 'SET_CREATE_DOG_SEX',
        payload
    };
}

export function setCreateDogFixed(payload) {
    return {
        type: 'SET_CREATE_DOG_FIXED',
        payload
    };
}

export function setCreateDogPlaydates(payload) {
    return {
        type: 'SET_CREATE_DOG_PLAYDATES',
        payload
    };
}

export function setCreateDogWeight(payload) {
    return {
        type: 'SET_CREATE_DOG_WEIGHT',
        payload
    };
}

export function setCreateDogDescription(payload) {
    return {
        type: 'SET_CREATE_DOG_DESCRIPTION',
        payload
    };
}

export function setCreateDogDOB(payload) {
    return {
        type: 'SET_CREATE_DOG_DOB',
        payload
    };
}

export function setCreateDogSocialMedia(val) {
    return {
        type: 'SET_CREATE_DOG_SOCIAL_MEDIA',
        payload: val
    };
}

export function setMissingDogReward(val) {
    return {
        type: 'SET_MISSING_DOG_REWARD',
        payload: val
    };
}

export function setMissingDogCity(val) {
    return {
        type: 'SET_MISSING_DOG_CITY',
        payload: val
    };
}

export function setMissingDogState(val) {
    return {
        type: 'SET_MISSING_DOG_STATE',
        payload: val
    };
}

export function setMissingDogCountry(val) {
    return {
        type: 'SET_MISSING_DOG_COUNTRY',
        payload: val
    };
}

export function setMissingDogPostal(val) {
    return {
        type: 'SET_MISSING_DOG_POSTAL',
        payload: val
    };
}

export function setMissingDogStreet1(val) {
    return {
        type: 'SET_MISSING_DOG_STREET1',
        payload: val
    };
}

export function setMissingDogStreet2(val) {
    return {
        type: 'SET_MISSING_DOG_STREET2',
        payload: val
    };
}

export function setMissingDogGeopoint(val) {
    return {
        type: 'SET_MISSING_DOG_GEOPOINT',
        payload: val
    };
}

export function setMissingDogAdditional(val) {
    return {
        type: 'SET_MISSING_DOG_ADDITIONAL',
        payload: val
    };
}

export function setMissingDogDate(val) {
    return {
        type: 'SET_MISSING_DOG_DATE',
        payload: val
    };
}

export function setMissingDogTime(val) {
    return {
        type: 'SET_MISSING_DOG_TIME',
        payload: val
    };
}

export function setMissingDogContactName(val) {
    return {
        type: 'SET_MISSING_DOG_CONTACT_NAME',
        payload: val
    };
}

export function setMissingDogContactEmail(val) {
    return {
        type: 'SET_MISSING_DOG_CONTACT_EMAIL',
        payload: val
    };
}

export function setMissingDogContactPhone(val) {
    return {
        type: 'SET_MISSING_DOG_CONTACT_PHONE',
        payload: val
    };
}

export function setMissingDogContactByPhone(val) {
    return {
        type: 'SET_MISSING_DOG_CONTACT_BY_PHONE',
        payload: val
    };
}

export function setMissingDogContactByEmail(val) {
    return {
        type: 'SET_MISSING_DOG_CONTACT_BY_EMAIL',
        payload: val
    };
}

export function setMissingDogContactByText(val) {
    return {
        type: 'SET_MISSING_DOG_CONTACT_BY_TEXT',
        payload: val
    };
}

export function setMissingDogSelected(val) {
    return {
        type: 'SET_MISSING_DOG_SELECTED',
        payload: val
    };
}

export function reportMissingDog(token, id, data) {
    return {
        type: 'REPORT_LOST_DOG',
        payload: apiConnection.post('dogs/lost/' + id, cleanJson(data), { headers: { Authorization: token } })
    };
}

export function getDogBreeds(token) {
    return {
        type: 'GET_DOG_BREEDS',
        payload: apiConnection.get('breeds?sort=breed_name&per-page=0', { headers: { Authorization: token } })
    };
}

export function createDog(token, data) {
    return {
        type: 'CREATE_DOG',
        payload: apiConnection.post('dogs', cleanJson(data), { headers: { Authorization: token } })
    };
}

export function clearDogProfile() {
    return {
        type: 'CLEAR_DOG_PROFILE'
    };
}

export function getDogProfile(token, id) {
    return {
        type: 'GET_DOG_PROFILE',
        payload: apiConnection.get('dogs/' + id + '?expand=images,parententity,breeds,users,dogaddress,dogaddress.address,users.images,users.lastaddress,users.myfriendship', { headers: { Authorization: token } })
    };
}

export function setDogProfile(payload) {
    return {
        type: 'SET_DOG_PROFILE',
        payload
    };
}

export function deleteDog(token, id) {
    return {
        type: 'DELETE_DOG',
        payload: apiConnection.delete('dogs/' + id, { headers: { Authorization: token } })
    };
}

export function updateMissingDog(token, id, data) {
    return {
        type: 'UPDATE_MISSING_DOG',
        payload: apiConnection.put('dogs/lost/' + id, cleanJson(data), { headers: { Authorization: token } })
    };
}

export function updateDog(token, id, data) {
    return {
        type: 'UPDATE_DOG',
        payload: apiConnection.put('dogs/' + id, cleanJson(data), { headers: { Authorization: token } })
    };
}

export function getLostDogs(token, geopoint, page = 1, search) {
    let query = '?expand=dog,dog.breeds,dog.images,dog.users,address&per-page=30&page=' + page;

    if (geopoint) {
        query += '&location=' + geopoint.address_latitude + ',' + geopoint.address_longitude;
    }

    if (search) {
        query += '&filter[or][][dog_name][like]=' + search.trim();
        query += '&filter[or][][address_city][like]=' + search.trim();
        query += '&filter[or][][user_fullname][like]=' + search.trim();
    }

    return {
        type: 'GET_DOGS_LOST',
        payload: apiConnection.get('dogs/lost' + query, { headers: { Authorization: token } })
    };
}

export function setSearchLostDogs(search) {
    return {
        type: 'SET_SEARCH_LOST_DOGS',
        payload: search
    };
}

export function removeDogPicture() {
    return {
        type: 'DELETE_DOG_PICTURE'
        // payload: search
    };
}
