import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { setCreateDogPicture, setCreateDogPictureFile, clearDogPictureFile } from '../../redux/actions/dogs';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import CameraModal from '../../layout/CameraModal';
import Avatar from '../../layout/UI/Avatar';
import Config from '../../helpers/Config';
import { camera, cameraRoll, cameraBlob } from '../../helpers/Camera';
import { guard } from '../../helpers/Functions';

class UserDogAddPicture extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pictureModal: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    filesChanged = files => {
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            const file = files[i];
            reader.onloadend = () => {
                this.props.dispatch(setCreateDogPictureFile(file));
                this.props.dispatch(setCreateDogPicture(reader.result));
            };
            reader.readAsDataURL(file);
        }
    }

    toggleModal = () => {
        this.setState({ pictureModal: !this.state.pictureModal });
    }

    changeImage = () => {
        if (Config.isMobileApp) {
            this.toggleModal();
        } else {
            const selectfile = document.getElementById('file');
            selectfile.click();
        }
    }

    removeImage = () => {
        this.props.dispatch(clearDogPictureFile());
    }

    phoneCamera = () => {
        camera(this.phoneSuccess);
    }

    phoneUpload = () => {
        cameraRoll(this.phoneSuccess);
    }

    phoneSuccess = file => {
        this.props.dispatch(setCreateDogPicture(file));
        cameraBlob(file, this.uploadToProfile);
    }

    uploadToProfile = imgBlob => {
        this.props.dispatch(setCreateDogPictureFile(imgBlob));
    }

    render() {
        return (
            <div className="mainAppContainer LoginScreens">
                {this.state.pictureModal && (
                    <CameraModal toggleModal={this.toggleModal} callbackCamera={this.phoneCamera} callbackUpload={this.phoneUpload} />
                )}
                <MainNavigation {...this.props} title="Add a Picture" leftButton="back" />
                <div className="bodyContent backgroundGreen">
                    <div className="LoginWrapper wrapper maxWidth">
                        <h1 className="section">Add a profile picture for {this.props.dogCreate.name}</h1>
                        <div className="profilePictureWrapper edit add-dog alignCenter">
                            <div className="profile-avatar">
                                <input className="hidden" id="file" type="file" onChange={e => this.filesChanged(e.target.files)} accept="image/*" required />
                                <div className="fontWhite" id="profileImage">
                                    <div className="round-avatar huge">
                                        <Avatar
                                            src={guard(this.props.dogCreate, '.picture')}
                                            onClick={this.changeImage}
                                            alt={this.props.dogCreate.name}
                                            className="changeImage"
                                        />
                                    </div>
                                    <div className="image-options alignItems">(
                                        {this.props.dogCreate.picture && this.props.dogCreate.pictureFile && (
                                            <div onClick={() => { this.removeImage(); }} className="changeImage spacerVertical">Remove Image</div>
                                        )}
                                        {!this.props.dogCreate.picture && !this.props.dogCreate.pictureFile && (
                                            <div onClick={() => { this.changeImage(); }} className="changeImage spacerVertical">Add Image</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="LoginWrapper wrapper maxWidth">
                        <Link to={'/user/dogadd/details' + this.props.history.location.search} className="rightRoundButton whiteBorder whiteOpaque fontWhite">
                            Next &gt;
                        </Link>
                    </div>
                </div>
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        dogCreate: state.dogs.dogCreate
    };
}

export default connect(mapStateToProps)(UserDogAddPicture);
