import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { findFriends, updateRequest } from '../../redux/actions/users';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import FriendsNavigation from '../../layout/navigation/FriendsNavigation';
import UserListRequest from '../../layout/UserListRequest';
import Spinner from '../../layout/UI/Spinner';
import { InviteFriends } from '../../layout/InviteFriends';
import { Pull2Refresh, infiniteScroll } from '../../helpers/Pull2Refresh';
import Cards from './Cards';
import ApiError from '../../helpers/ApiError';

export class FriendRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            reloading: false,
            friendsList: [],
            currentPage: 1,
            loadingMore: false,
            totalPages: 1, // This it's being used by infiniteScroll->handleScroll->loadMore
            listOfAnsweredUsers: []
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.find();
        Pull2Refresh(this.find);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    find = () => {
        this.setState({ reloading: true });
        const filter = '&filter[friendship_status][eq]=pending&filter[receiver_id][eq]=' + this.props.credentials.user_id;
        this.props.dispatch(findFriends(this.props.credentials.token, this.props.userData.user_location, filter, null, this.state.currentPage))
            .then(response => {
                this.filter(response.value.data);
                this.setState({
                    loading: false,
                    reloading: false,
                    totalPages: response.value.headers['x-pagination-page-count'],
                    loadingMore: false
                }, () => {
                    if (this.state.currentPage < this.state.totalPages) {
                        infiniteScroll(this, this.find);
                    }
                });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loading: false, reloading: false, loadingMore: false });
            });
    }

    acceptRequest = (e, id) => {
        e.preventDefault();
        this.props.dispatch(updateRequest(this.props.credentials.token, id, { friendship_status: 'accepted' }))
            .then(() => {
                this.setState(prevState => ({ listOfAnsweredUsers: prevState.listOfAnsweredUsers.concat(id) }));
            });
    }

    declineRequest = (e, id) => {
        e.preventDefault();
        this.props.dispatch(updateRequest(this.props.credentials.token, id, { friendship_status: 'declined' }))
            .then(() => {
                this.setState(prevState => ({ listOfAnsweredUsers: prevState.listOfAnsweredUsers.concat(id) }));
            });
    }

    filter = data => {
        const filteredUsers = data.filter(item => (!!item.myfriendship && item.myfriendship.receiver_id === this.props.credentials.user_id));
        this.setState(prevState => ({
            friendsList: prevState.friendsList.concat(filteredUsers),
            loading: false,
            reloading: false
        }));
    }

    render() {
        return (
            <div className="mainAppContainer">
                <MainNavigation title="Friends Requests" />
                <FriendsNavigation selected="friendrequest" />
                <div className="bodyContent pull2refresh noPaddingTop ">
                    {(!!this.state.loading || !!this.state.reloading) && !this.state.loadingMore && (
                        <Spinner />
                    )}
                    {!this.state.loading && (!this.state.friendsList || !this.state.friendsList.length) && (
                        <InviteFriends />
                    )}
                    {this.state.friendsList.length > 0 && (
                        <React.Fragment>
                            <div className="maxWidth wrapper noPadding">
                                <div className="boxContainer">
                                    <div className="boxTitle row">
                                        <div className="title">
                                            Friend Requests
                                        </div>
                                    </div>
                                </div>
                                <UserListRequest {...this.props} users={this.state.friendsList} onClickAccept={this.acceptRequest} onClickDecline={this.declineRequest} listOfAnsweredUsers={this.state.listOfAnsweredUsers} />
                            </div>
                            <div className="paddingTop" />
                        </React.Fragment>
                    )}
                    {!!this.state.loadingMore && (
                        <Spinner />
                    )}
                    <Cards dispatch={this.props.dispatch} credentials={this.props.credentials} userData={this.props.userData} />
                </div>
                <SecondaryNavigation selected="friends" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(FriendRequest);
