import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { logout } from '../redux/actions/users';
import Config from '../helpers/Config';
import sb from '../helpers/SendBird';
import Modal from './Modal/Modal';
import ModalDesktopLogin from './Modal/ModalDesktopLogin';
import ModalDesktopRegister from './Modal/ModalDesktopRegister';
import ModalForgotPassword from './Modal/ModalForgotPassword';
import ModalPasswordReset from './Modal/ModalPasswordReset';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            active: false,
            login: false,
            register: false,
            pause: false,
            forgotPassword: false,
            confirmPassword: false
        };
    }

    /* componentDidMount() {
        if ((!this.props.userData || !this.props.userData.user_id)) {
            //after 10 seconds ask the user to register
            //setTimeout(this.initialRegister, 10000)
        }
    } */

    initialRegister = () => {
        if (!this.state.register && !this.state.login && !this.state.forgotPassword && !this.state.confirmPassword) {
            this.showModalDesktopRegister();
        }
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    showSideMenu = () => {
        this.setState({
            ...this.state,
            show: !this.state.show,
            active: !this.state.active
        });
        window.setTimeout(() => {
            const element = document.querySelector('#modal');
            const className = element.className;
            element.className = className.concat(' active');
        }, 10);
        document.body.classList.add('activeModal');
        document.getElementById('userImage').classList.add('userModal');
        document.getElementById('headerNavOptions').classList.add('activeModal');
    }

    preventClose = () => {
        this.setState({
            ...this.state,
            show: !this.state.show,
            active: !this.state.active
        });
    }

    closeModalMobile = () => {
        this.setState({
            ...this.state,
            show: !this.state.show,
            active: !this.state.active
        });
        document.body.classList.remove('activeModal');
        if (document.getElementById('userImage') && document.getElementById('userImage').classList) {
            document.getElementById('userImage').classList.remove('userModal');
        }
        if (document.getElementById('headerNavOptions') && document.getElementById('headerNavOptions').classList) {
            document.getElementById('headerNavOptions').classList.remove('activeModal');
        }
    }

    showModalDesktopLogin = () => {
        this.setState({
            login: true,
            register: false,
            forgotPassword: false
        });
        document.body.classList.add('activeModal');
    }

    hideModalDesktopLogin = () => {
        this.setState({ login: false });
        document.body.classList.remove('activeModal');
    }

    showModalDesktopRegister = () => {
        this.setState({
            register: true,
            login: false
        });
        document.body.classList.add('activeModal');
    }

    hideModalDesktopRegister = () => {
        this.setState({ register: false });
        document.body.classList.remove('activeModal');
    }

    showModalForgotPassword = () => {
        this.setState({
            forgotPassword: true,
            login: false,
            confirmPassword: false
        });
        document.body.classList.add('activeModal');
    }

    hideModalForgotPassword = () => {
        this.setState({ forgotPassword: false });
        document.body.classList.remove('activeModal');
    }

    showModalConfirmPasswordReset = () => {
        this.setState({ confirmPassword: true });
        document.body.classList.add('activeModal');
    }

    hideModalConfirmPasswordReset = () => {
        this.setState({ confirmPassword: false });
        document.body.classList.remove('activeModal');
    }

    logout = e => {
        e.preventDefault();
        sb.disconnect(() => { });
        const that = this;
        if (window && window.FB && window.FB.logout) window.FB.logout();
        if (!!window && !!window.facebookConnectPlugin) {
            window.facebookConnectPlugin.getLoginStatus(response => {
                if (!!response && !!response.authResponse && !!response.authResponse.userID) {
                    window.facebookConnectPlugin.api(
                        '/' + response.authResponse.userID + '/permissions?method=delete',
                        ['public_profile', 'email'],
                        () => {
                            window.facebookConnectPlugin.logout(
                                () => that.completeLogout(),
                                () => that.completeLogout()
                            );
                        },
                        () => {
                            that.completeLogout();
                        }
                    );
                } else {
                    that.completeLogout();
                }
            }, () => {
                that.completeLogout();
            });
        } else {
            that.completeLogout();
        }
    }

    completeLogout = () => {
        ReactGA.event({
            category: 'UserRegistration',
            action: 'logout'
        });
        this.props.dispatch(logout());
    }

    render() {
        const path = this.props.location && this.props.location.pathname;
        const userId = this.props.userData.user_id;

        let searchActive = '';
        let friendsActive = '';
        let playdatesActive = '';
        let feedActive = '';

        if (path === '/' || path === '/search' || path.indexOf('/location/') > -1) {
            searchActive = 'selected';
        }

        if (path === '/friends' || path === '/findfriends' || path === '/friendrequest') {
            friendsActive = 'selected';
        }

        if (path === '/events' || path.indexOf('/events/') > -1) {
            playdatesActive = 'selected';
        }

        if (path === '/activities' || path === '/dogs' || path === '/chat' || path === '/notifications') {
            feedActive = 'selected';
        }

        // scroll header
        window.addEventListener('scroll', () => {
            const nav = document.querySelector('.mainHeader');
            if (window.pageYOffset > 200) {
                nav.classList.add('shrink');
            } else {
                nav.classList.remove('shrink');
            }
        });

        return (
            <React.Fragment>
                <header className={'mainHeader row wrapper maxWidth ' + (!this.state.active ? '' : 'activeModal')}>
                    <div className="headerLogo">
                        <Link to="/">
                            <img
                                className="logoImg"
                                src={Config.fileDirectory + 'assets/images/' + Config.logos.inline + '@2x.png'}
                                srcSet={Config.fileDirectory + 'assets/images/' + Config.logos.inline + '@1x.png 1x,' + Config.fileDirectory + 'assets/images/' + Config.logos.inline + '@2x.png 2x'}
                                alt="App Logo"
                            />
                        </Link>
                    </div>
                    <div className="headerNavMenu" id="headerNavOptions">
                        <div className="navigation">
                            <Link to="/dogs" className={feedActive}>Feed</Link>
                            <Link to="/search" className={searchActive}>Search</Link>
                            <Link to="/events" className={playdatesActive}>Events</Link>
                            <Link to="/friends" className={friendsActive}>Friends</Link>
                            <a href={process.env.REACT_APP_SHOP_LINK} target="_blank" rel="noopener noreferrer">
                                Shop
                            </a>
                        </div>
                    </div>
                    {!this.props.userData && Config.isMobileDevice && (
                        <div className="loginMobile">
                            <Link className="login" to="/login">Log In</Link>
                        </div>
                    )}
                    {!!this.props.userData && !!userId && (
                        <div className="userProfileImage" id="userImage" onClick={e => this.showSideMenu(e)}>
                            <div className="header-menu-select round-avatar">
                                {this.props.userData && (!this.props.userData.images || this.props.userData.images.length === 0) && (
                                    <i className="icn icon-user user" />
                                )}
                                {this.props.userData?.images?.[0] && (
                                    <img src={(this.props.userData.images[0].image_smallurl)} alt="User Profile" />
                                )}
                            </div>
                            <div className="user-name fontWhite">
                                {this.props.userData.user_firstname}
                                <i className="icn icon-arrow_drop_down fontWhite" />
                            </div>
                        </div>
                    )}
                    {!userId && !Config.isMobileDevice && (
                        <div className="loginDesktop">
                            <div className="login" id="login-header-button" onClick={this.showModalDesktopLogin}>
                                Log In
                            </div>
                            <div className="join whiteBorder smallWideButtons" onClick={this.showModalDesktopRegister}>
                                Join
                            </div>
                        </div>
                    )}
                    <Modal
                        onClose={this.closeModalMobile}
                        preventClose={this.preventClose}
                        show={this.state.show}
                    />
                    {this.state.login && (
                        <ModalDesktopLogin
                            handleClose={this.hideModalDesktopLogin}
                            userPassword={this.state.userPassword}
                            handleChange={this.handleChange}
                            userData={this.props.userData}
                            dispatch={this.props.dispatch}
                            showModalDesktopRegister={this.showModalDesktopRegister}
                            showModalForgotPassword={this.showModalForgotPassword}
                            credentials={this.props.credentials}
                        />
                    )}
                    {this.state.register && (
                        <ModalDesktopRegister
                            handleClose={this.hideModalDesktopRegister}
                            userPassword={this.state.userPassword}
                            handleChange={this.handleChange}
                            userData={this.props.userData}
                            dispatch={this.props.dispatch}
                            showModalDesktopLogin={this.showModalDesktopLogin}
                        />
                    )}
                    {this.state.forgotPassword && (
                        <ModalForgotPassword
                            handleClose={this.hideModalForgotPassword}
                            userData={this.props.userData}
                            dispatch={this.props.dispatch}
                            showModalDesktopLogin={this.showModalDesktopLogin}
                            showModalConfirmPasswordReset={this.showModalConfirmPasswordReset}
                        />
                    )}
                    {this.state.confirmPassword && (
                        <ModalPasswordReset
                            handleClose={this.hideModalConfirmPasswordReset}
                            userData={this.props.userData}
                            dispatch={this.props.dispatch}
                            showModalForgotPassword={this.showModalForgotPassword}
                        />
                    )}
                </header>
                {this.props.children}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        counter: state.activities.counter
    };
}

export default withRouter(connect(mapStateToProps)(Header));
