import React from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

class ChatBubbles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDate: false
        };
    }

    render() {
        return (
            <div className="msgBubbleWrapper">
                <div
                    className={'msgBubble ' + this.props.userSentClass}
                    onClick={() => this.setState({ showDate: !this.state.showDate })}
                >
                    {this.props.message.message.split('\n').map((item, index) => <div key={index}>{item}</div>)}
                </div>
                <div className={'userName ' + this.props.userSentClass}>
                    {this.props.message._sender.nickname} {this.state.showDate && <span>- {moment(this.props.message.createdAt).fromNow()}</span>}
                </div>
                <div className={'profilePicture ' + this.props.userSentClass}>
                    <Link to={'/user/profile/' + this.props.message._sender.userId}>
                        {!(this.props.message._sender.profileUrl.includes('sendbird'))
                            ? (<img src={this.props.message._sender.profileUrl} alt={this.props.message._sender.nickname} />)
                            : (<i className="icn icon-user" />)}
                    </Link>
                </div>
                <div className="clear" />
            </div>
        );
    }
}

export default ChatBubbles;
