import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Avatar from './UI/Avatar';
import { guard } from '../helpers/Functions';

/**
 * Component that will render a list of all users passed to the `list`
 *
 * @param {array} list array of Objects with the user data
 */
class AdministratorsUsersList extends React.Component {
    itemMap(list) {
        return list.map((user, index) => (
            <div className="participantListItem" key={index}>
                <div className="user row">
                    <Link to={'/user/profile/' + user.user_id}>
                        <div className="round-avatar colored-icon">
                            <Avatar
                                src={guard(user, '.images.0.image_smallurl')}
                                alt={user.user_firstname + ' ' + user.user_lastname}
                                type="user"
                            />
                        </div>
                    </Link>
                    <div className="info">
                        <div className="name">
                            <Link to={'/user/profile/' + user.user_id}>{user.user_firstname + ' ' + user.user_lastname}</Link>
                        </div>
                    </div>
                </div>
            </div>
        ));
    }

    render() {
        return (
            <div className="participantList manage-admin">
                {this.props.list && this.itemMap(this.props.list)}
            </div>
        );
    }
}

AdministratorsUsersList.propTypes = {
    list: PropTypes.instanceOf(Array).isRequired
};

export default AdministratorsUsersList;
