import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { getSlug } from '../redux/actions/all';
import NotFound from '../layout/NotFound';
import Config from '../helpers/Config';

import { setDogProfile } from '../redux/actions/dogs';

import MobileUserProfile from '../pages/profiles/UserProfile';
import DesktopUserProfile from '../pagesDesktop/Profiles/UserProfile';
import MobileDogProfile from '../pages/profiles/DogProfile';
import DesktopDogProfile from '../pagesDesktop/Profiles/DogProfile';
import MobileLocationProfile from '../pages/location/LocationProfile';
import DesktopLocationProfile from '../pagesDesktop/Locations/LocationProfile';

const UserProfile = Config.isMobileDevice ? MobileUserProfile : DesktopUserProfile;
const DogProfile = Config.isMobileDevice ? MobileDogProfile : DesktopDogProfile;
const LocationProfile = Config.isMobileDevice ? MobileLocationProfile : DesktopLocationProfile;

export class Slug extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            type: '',
            data: {},
            entity_id: null
        };
    }

    componentDidMount() {
        this.start();
    }

    start = () => {
        window.scrollTo(0, 0);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }

        this.props.dispatch(getSlug(this.props.credentials.token, this.props.location.pathname.substr(1)))
            .then(res => {
                this.setState({
                    loading: false,
                    entity_id: res.value.data.entity_id,
                    type: res.value.data.entity_type,
                    data: res.value.data
                });
            })
            .catch(() => {
                this.setState({
                    loading: false
                });
            });
    }

    render() {
        let page = <div className="mainAppContainer"><div className="bodyContent user-profiles" /></div>;

        if (!this.state.loading) {
            if (this.state.type === 'user') {
                page = (
                    <UserProfile
                        dispatch={this.props.dispatch}
                        credentials={this.props.credentials}
                        userSlugData={this.state.data.user}
                        history={this.props.history}
                        currentId={this.state.entity_id}
                        loading={false}
                    />
                );
            } else if (this.state.type === 'dog') {
                this.props.dispatch(setDogProfile(this.state.data.dog));
                page = (
                    <DogProfile
                        dispatch={this.props.dispatch}
                        credentials={this.props.credentials}
                        history={this.props.history}
                        currentId={this.state.entity_id}
                        loading={false}
                    />
                );
            } else if (this.state.type === 'location') {
                page = (
                    <LocationProfile
                        dispatch={this.props.dispatch}
                        credentials={this.props.credentials}
                        locationData={this.state.data}
                        history={this.props.history}
                        currentId={this.state.entity_id}
                        loading={false}
                    />
                );
            } else {
                page = <NotFound />;
            }
        }

        return (
            page
        );
    }
}

function mapStateToProps(state) {
    return {
        credentials: state.users.credentials,
        userData: state.users.user
    };
}
export default connect(mapStateToProps)(Slug);
