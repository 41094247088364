import React from 'react';
import { Link } from 'react-router-dom';

import Avatar from '../layout/UI/Avatar';
import { guard } from '../helpers/Functions';

export default class UserListRequest extends React.Component {
    getDogNames(dogs) {
        const dogNames = [];
        for (const i in dogs) {
            dogNames.push(dogs[i].dog_name);
        }
        return (<span className="fontGreen">{dogNames.join(', ')}</span>);
    }

    userMap(users) {
        const list = users.filter(user => !(this.props.listOfAnsweredUsers.indexOf(user.myfriendship.friendship_id) > -1));

        return list.map((user, index) => (
            <div className="participantListItem padding" key={index}>
                {!!user.myfriendship && user.myfriendship.receiver_id === this.props.credentials.user_id
                    && (
                        <div className="user row">
                            <Link to={'/user/profile/' + user.user_id}>
                                <div className="round-avatar user colored-icon">
                                    <Avatar
                                        src={guard(user, '.images.0.image_smallurl')}
                                        alt={user.user_firstname + ' ' + user.user_lastname}
                                        type="user"
                                    />
                                </div>
                            </Link>
                            <div className="info">
                                <Link to={'/user/profile/' + user.user_id}><div className="name fontGreen">{user.user_firstname + ' ' + user.user_lastname}</div></Link>
                                {user.address && user.address.address_city && (
                                    <div className="lastSeen">Last Seen: {user.address.address_city} {user.address.address_country || ''} </div>
                                )}
                                <div className="row alignItems">
                                    <div onClick={e => this.props.onClickAccept(e, user.myfriendship.friendship_id)} className="flexSmallestButtons whiteBorder greenBG fontWhite"><p>Accept</p></div>
                                    <div onClick={e => this.props.onClickDecline(e, user.myfriendship.friendship_id)} className="flexSmallestButtons redBorder redBG fontWhite"><p>Delete</p></div>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        ));
    }

    render() {
        return (
            <div className="participantList">
                {this.props.users && this.props.users !== 'loading' && this.userMap(this.props.users)}
            </div>
        );
    }
}
