import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import ActivityDispatcher from '../../layout/ActivityDispatcher';
import ActivityNavigation from '../../layout/navigation/ActivityNavigation';
import { InviteFriends } from '../../layout/InviteFriends';
import FriendRequestsComponent from '../Friends/FriendRequestsComponent';
import FindFriendsComponent from '../Friends/FindFriendsComponent';
import Columns from '../../hoc/Columns';
import WhiteTitle from '../../hoc/WhiteTitle';
import HelmetTags from '../../helmetTags';

export class Activities extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentId: this.props.match.params.id
        };
    }

    componentDidMount() {
        this.start();
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.match.params.id !== this.state.currentId) {
            this.setState({
                currentId: nextProps.match.params.id
                // loading: true
            }, () => {
                this.start();
            });
        }
        return true;
    }

    start = () => {
        window.scrollTo(0, 0);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    render() {
        const header = 'Dog Activities';
        const pageTitle = header;
        const pageDescription = 'Latest Activity of friends and favorite locations in your area';

        const c1 = (
            <div className="simple-navigation">
                <ActivityNavigation.ActivityNavigationDesktop
                    selected="dogs"
                />
            </div>
        );

        const c2 = (
            <ActivityDispatcher
                credentials={this.props.credentials}
                dispatch={this.props.dispatch}
                type="dogs"
                userData={this.props.userData}
                disableImages={false}
                allowPosts={false}
            />
        );

        const c3 = (
            <React.Fragment>
                <InviteFriends wrapperClass="noWrapper" />
                <FriendRequestsComponent limit={3} infiniteScroll={false} viewMoreButton={true} />
                <WhiteTitle>Suggested Friends</WhiteTitle>
                <FindFriendsComponent perPage={3} infiniteScroll={false} />
                <Link to="/findfriends" className="square-button greenBG fontWhite h6">View all</Link>
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <HelmetTags title={pageTitle} description={pageDescription} />
                <div className="mainAppContainer activities">
                    <div className="bodyContent">
                        <div className="three-column-layout two-column-list">
                            <Columns
                                c1={c1}
                                c2={c2}
                                c3={c3}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        counter: state.activities.counter
    };
}

export default connect(mapStateToProps)(Activities);
