import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';
import Textarea from 'react-textarea-autosize';

import { getDogProfile, updateDog } from '../../redux/actions/dogs';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import ApiError from '../../helpers/ApiError';

class UserDogEditDescription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            description: ''
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(getDogProfile(this.props.credentials.token, this.props.match.params.id)).then(() => this.myDog());
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    verifyData = e => {
        e.preventDefault();
        this.props.dispatch(updateDog(this.props.credentials.token, this.props.match.params.id, { dog_description: this.state.description }))
            .then(() => {
                toast.success('Updated description correctly');
                this.props.history.goBack();
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    myDog() {
        this.setState({
            description: this.props.dogProfile.dog_description
        });
    }

    changeDescription(value) {
        this.setState({ description: value });
    }

    render() {
        return (
            <div className="mainAppContainer LoginScreens">
                <MainNavigation {...this.props} title={'Describe ' + this.props.dogProfile.dog_name} leftButton="back" />
                <div className="bodyContent backgroundGreen">
                    <form onSubmit={this.verifyData}>
                        <div className="LoginWrapper wrapper maxWidth fontWhite">
                            <h1 className="section">How would you describe {this.props.dogProfile.dog_name}?</h1>
                            <Textarea value={this.state.description || ''} onChange={e => this.changeDescription(e.target.value)} className="niceTextarea" placeholder={'e.g. ' + this.props.dogProfile.dog_name + ' is the fun and playful kind...'} />
                        </div>
                        <div className="LoginWrapper wrapper maxWidth">
                            <input type="submit" value="Save &gt;" className="rightRoundButton whiteBorder whiteOpaque fontWhite" />
                        </div>
                    </form>
                </div>
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        dogProfile: state.dogs.dogProfile
    };
}

export default connect(mapStateToProps)(UserDogEditDescription);
