import React from 'react';
import { Link } from 'react-router-dom';

import UserListRequest from '../UserListRequest';

const FriendRequestBox = props => (
    <div className="boxContainer">
        <div className="boxTitle row">
            <div className="title">
                Friend Requests
            </div>
            {props.totalPagesRequests > 1
                && (
                    <div className="link">
                        <Link to="/friendrequest">View All &gt;</Link>
                    </div>
                )}
        </div>
        <div className="boxContent">
            <div className="maxWidth wrapper">
                <UserListRequest
                    {...props}
                    users={props.friendRequests}
                    onClickAccept={props.acceptRequest}
                    onClickDecline={props.declineRequest}
                    listOfAnsweredUsers={props.listOfAnsweredUsers}
                />
            </div>
        </div>
    </div>
);

export default FriendRequestBox;
