import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import 'react-confirm-alert/src/react-confirm-alert.css';

/**
 * Modal with a dialog question that must be used as a confirmation for a dangerous action,
 * the user must write the required text to confirm the action
 *
 * @param {string} title text used as a title in the dialog box
 * @param {string} subtitle text used in the body of the dialog box
 * @param {string} confirmation text used as a confirmation button in the dialog box
 * @param {string} confirmation_text text that the user must write to confirm the action
 * @param {function} clicked  function to be trigger once the confirmation button it's pressed
 */
const AdvancedConfirm = (title = 'Are you sure?', subtitle, confirmation_text, confirmation = 'Yes', clicked) => {
    let confirmText = '';

    const setConfirmText = e => {
        confirmText = e.target.value;
    };

    confirmAlert({
        customUI: ({ onClose }) => (
            <div className="custom-confirm-ui large-text">
                <h1>{title}</h1>
                {!!subtitle && (<p className="basic-confirm-subtitle">{subtitle}</p>)}
                <div className="row">
                    <input
                        className="inputText"
                        type="text"
                        name="confirmation"
                        placeholder={confirmation_text}
                        onChange={e => setConfirmText(e)}
                    />
                </div>
                <div className="row">
                    <button
                        className="flexButtons greenBorder whiteBG fontGreen h6"
                        type="button"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        className="flexButtons whiteBorder whiteBG fontRed h6"
                        type="button"
                        onClick={() => {
                            if (confirmText !== confirmation_text) {
                                toast.error('Sorry, the text doesn\'t match with the required one, please try again.');
                            } else {
                                clicked();
                                onClose();
                            }
                        }}
                    >
                        {confirmation}
                    </button>
                </div>
            </div>
        )
    });
};

AdvancedConfirm.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    confirmation: PropTypes.string.isRequired,
    confirmation_text: PropTypes.string.isRequired,
    clicked: PropTypes.func.isRequired
};

export default AdvancedConfirm;
