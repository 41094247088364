import React from 'react';

import SettingsNotifications from '../../layout/SettingsNotifications';

const Settings = () => (
    <div className="mainAppContainer LoginScreens">
        <div className="bodyContent backgroundGreen">
            <div className="LoginWrapper wrapper maxWidth fontWhite">
                <SettingsNotifications />
            </div>
        </div>
    </div>
);

export default Settings;
