export default function reducer(
    state = {
        selectedFilter: 'all',
        filterList: []
    },
    action
) {
    switch (action.type) {
        case 'persist/REHYDRATE': {
            if (action.payload.searchFilters) {
                return {
                    ...state,
                    selectedFilter: action.payload.searchFilters.selectedFilter || 'all',
                    filterList: action.payload.searchFilters.filterList || []
                };
            }
            return { ...state };
        }
        case 'SET_SEARCH_FILTERS': {
            return { ...state, selectedFilter: action.payload };
        }
        case 'SEARCH_FILTERS_LIST_FULFILLED': {
            return { ...state, filterList: action.payload.data };
        }
        case 'USER_LOGOUT': {
            return { ...state, selectedFilter: '', filterList: [] };
        }
        default: {
            return { ...state };
        }
    }
}
