import { combineReducers } from 'redux';

import users from './users';
import searchFilters from './searchFilters';
import playdates from './playdates';
import locations from './locations';
import dogs from './dogs';
import activities from './activities';
import all from './all';

export default combineReducers({
    users,
    searchFilters,
    playdates,
    locations,
    dogs,
    activities,
    all
});
