import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { checkFavorite, removeFavorite } from '../../redux/actions/locations';
import { Stars } from '../UI/Stars';
import Avatar from '../UI/Avatar';
import { guard, calculateDistance, urlFriendly } from '../../helpers/Functions';
import ApiError from '../../helpers/ApiError';

export class LocationListItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFavorite: false
        };
    }

    componentDidMount() {
        this.setState({ isFavorite: (this.props.location && this.props.location.IsFavorite) });
    }

    toggleFavorite(e) {
        e.preventDefault();
        if (this.state.isFavorite) {
            this.props.dispatch(removeFavorite(this.props.credentials.token, this.props.location.location_id))
                .then(() => {
                    this.setState({ isFavorite: false });
                })
                .catch(err => {
                    ApiError(err);
                });
        } else {
            this.props.dispatch(checkFavorite(this.props.credentials.token, this.props.location.location_id))
                .then(() => {
                    this.setState({ isFavorite: true });
                })
                .catch(err => {
                    ApiError(err);
                });
        }
    }

    render() {
        const url = this.props.url;
        let details = null;
        let calculatedDistance = '';
        const className = (this.state.isFavorite) ? 'icon-heart-fill' : 'icon-heart-outline';

        if (this.props.userData
            && this.props.userData.user_location
            && this.props.userData.user_location.address_latitude
            && this.props.userData.user_location.address_longitude
            && this.props.location
            && this.props.location.address
            && this.props.location.address.address_latitude
            && this.props.location.address.address_longitude
        ) {
            calculatedDistance = calculateDistance(this.props.userData.user_location.address_latitude, this.props.userData.user_location.address_longitude, this.props.location.address.address_latitude, this.props.location.address.address_longitude);
            calculatedDistance = parseFloat(calculatedDistance).toFixed(2) + ' km';
        }

        let address = null;
        const city = this.props.location.address && this.props.location.address.address_city;
        const street = this.props.location.address && this.props.location.address.address_street;

        if (city && street) {
            address = (
                <div className="h7 fontDarkGray">
                    {street}, {city}
                </div>
            );
        } else if (street) {
            address = (
                <div className="h7 fontDarkGray">
                    {street}
                </div>
            );
        } else if (city) {
            address = (
                <div className="h7 fontDarkGray">
                    {city}
                </div>
            );
        }

        if (!this.props.banner && this.props.location && this.props.location.location_id) {
            details = (
                <Link className="info" to={'/' + url + '/' + this.props.location.location_id + '/' + urlFriendly(this.props.location.location_name)} onClick={e => (this.props.onClick ? this.props.onClick(e, { id: this.props.location.location_id, name: this.props.location.location_name }) : '')}>
                    <div className="h4">
                        <div className="location-name fontGreen">
                            {this.props.location.location_name}
                        </div>
                    </div>
                    {address}
                    <Stars stars={this.props.location.Reviewaverage} />
                    <span className="fontGold">({this.props.location.Reviewcounter})</span>
                </Link>
            );
        } else if (this.props.location && this.props.location.location_id) {
            details = (
                <div className="info">
                    <div className="h4">
                        <Link className="fontGreen" to={'/' + url + '/' + this.props.location.location_id + '/' + urlFriendly(this.props.location.location_name)} onClick={e => (this.props.onClick ? this.props.onClick(e, { id: this.props.location.location_id, name: this.props.location.location_name }) : '')}>
                            {this.props.location.location_name}
                        </Link>
                    </div>
                    {address}
                    <Stars stars={this.props.location.Reviewaverage} />
                    <span className="fontGold">({this.props.location.Reviewcounter})</span>
                </div>
            );
        }

        return (
            <li className="locationItemBasic row">
                {this.props.location && this.props.location.location_id
                    && (
                        <React.Fragment>
                            <Link to={'/' + url + '/' + this.props.location.location_id + '/' + urlFriendly(this.props.location.location_name)} className="round-avatar location" onClick={e => (this.props.onClick ? this.props.onClick(e, { id: this.props.location.location_id, name: this.props.location.location_name }) : '')}>
                                <Avatar
                                    type="location"
                                    src={guard(this.props.location, '.images.0.image_smallurl')}
                                    alt={this.props.location.location_name}
                                />
                            </Link>

                            {details}

                            <div className="additionalInfo">
                                <div className="distance">
                                    {!!this.props.location.location_featured
                                        && <div className="h7 fontGold">Featured</div>}
                                    {calculatedDistance}
                                </div>
                                <Link to="#" onClick={e => this.toggleFavorite(e)} className={'icn fontRed ' + className} />
                            </div>
                        </React.Fragment>
                    )}
            </li>
        );
    }
}

LocationListItem.propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.instanceOf(Object).isRequired,
    url: PropTypes.string.isRequired,
    credentials: PropTypes.instanceOf(Object),
    onClick: PropTypes.func,
    banner: PropTypes.bool
};
