import React from 'react';
import { Link } from 'react-router-dom';

import Avatar from '../layout/UI/Avatar';
import { guard, urlFriendly } from '../helpers/Functions';

class AdministratorsList extends React.Component {
    itemMap(list) {
        return list.map((item, index) => (
            <div className="participantListItem" key={index}>
                <div className="user row">
                    <Link to={'/user/profile/' + item.user_id}>
                        <div className="round-avatar user colored-icon">
                            <Avatar
                                src={guard(item, '.user.images.0.image_smallurl')}
                                alt={item.user.user_firstname + ' ' + item.user.user_lastname}
                                type="user"
                            />
                        </div>
                    </Link>
                    <div className="info">
                        <div className="name">
                            <Link to={'/user/profile/' + item.user.user_id}>{item.user.user_firstname + ' ' + item.user.user_lastname}</Link>
                            <br />
                            Admin: <Link className="fontGreen" to={'/location/profile/' + item.location.location_id + '/' + urlFriendly(item.location.location_name)}>{item.location.location_name}</Link>
                        </div>
                    </div>
                    <div onClick={() => this.props.onClick(item.adminlocation_id, item.location.location_name, item.user.user_firstname + ' ' + item.user.user_lastname)}>
                        <i className="icn icon-sign-out fontRed" />
                    </div>
                </div>
            </div>
        ));
    }

    render() {
        return (
            <div className="participantList manage-admin">
                {this.props.list && this.itemMap(this.props.list)}
            </div>
        );
    }
}

export default AdministratorsList;
