import { toast } from 'react-toastify';

import ApiError from '../../helpers/ApiError';

export default function reducer(
    state = {
        searchLocation: '',
        createLocation: { locationtypes: [] },
        editLocation: { locationtypes: [] },
        locationReview: { images: [] },
        searchedLocationsList: [],
        searchedLocationsPosition: null,
        mapLastLocation: null
    },
    action
) {
    switch (action.type) {
        case 'persist/REHYDRATE': {
            if (action.payload.locations) {
                return {
                    ...state,
                    searchLocation: /* action.payload.locations.searchLocation  || */ '',
                    createLocation: action.payload.locations.createLocation || { locationtypes: [] },
                    editLocation: action.payload.locations.editLocation || { locationtypes: [] },
                    locationReview: action.payload.locations.locationReview || { images: [] },
                    searchedLocationsList: /* action.payload.locations.searchedLocationsList || */[],
                    searchedLocationsPosition: /* action.payload.locations.searchedLocationsPosition || */ null
                };
            }
            return { ...state };
        }

        case 'GET_FAVORITES_PENDING': {
            return { ...state, locationsList: { loading: true } };
        }

        case 'SET_SEARCHED_LOCATIONS_LIST': {
            return { ...state, searchedLocationsList: action.payload };
        }

        case 'SET_SEARCHED_LOCATIONS_POSITION': {
            return { ...state, searchedLocationsPosition: action.payload };
        }

        case 'GET_FAVORITES_FULFILLED': {
            return { ...state, locationsList: action.payload.data };
        }

        case 'SET_SEARCH_LOCATION': {
            return { ...state, searchLocation: action.payload };
        }

        case 'ADD_LOCATION_FULFILLED': {
            return { ...state, createLocation: null };
        }

        case 'ADD_LOCATION_REJECTED': {
            if (!!action.payload.response && !!action.payload.response.data && action.payload.response.data.length > 0) {
                ApiError(action.payload.response.data[0].message);
            }
            return { ...state };
        }

        case 'SET_REVIEW_PICTURE': {
            return { ...state, locationReview: { ...state.locationReview, images: [...state.locationReview.images, action.payload] } };
        }

        case 'CLEAR_REVIEW_PICTURE_FILE':
            return { ...state, locationReview: { ...state.locationReview, images: [] } };

        case 'SET_REVIEW_DESCRIPTION': {
            return { ...state, locationReview: { ...state.locationReview, description: action.payload } };
        }

        case 'SET_REVIEW_STARS': {
            return { ...state, locationReview: { ...state.locationReview, stars: action.payload } };
        }

        case 'CLEAR_REVIEW': {
            return { ...state, locationReview: { images: [] } };
        }

        case 'CHECKIN_FULFILLED': {
            return { ...state };
        }

        case 'SET_CREATE_LOCATION_NAME': {
            return { ...state, createLocation: { ...state.createLocation, location_name: action.payload } };
        }

        case 'SET_CREATE_LOCATION_CITY': {
            return { ...state, createLocation: { ...state.createLocation, address: { ...state.createLocation.address, address_city: action.payload } } };
        }

        case 'SET_CREATE_LOCATION_STATE': {
            return { ...state, createLocation: { ...state.createLocation, address: { ...state.createLocation.address, address_state: action.payload } } };
        }

        case 'SET_CREATE_LOCATION_COUNTRY': {
            return { ...state, createLocation: { ...state.createLocation, address: { ...state.createLocation.address, address_country: action.payload } } };
        }

        case 'SET_CREATE_LOCATION_POSTAL': {
            return { ...state, createLocation: { ...state.createLocation, address: { ...state.createLocation.address, address_zipcode: action.payload } } };
        }

        case 'SET_CREATE_LOCATION_STREET1': {
            return { ...state, createLocation: { ...state.createLocation, address: { ...state.createLocation.address, address_street: action.payload } } };
        }

        case 'SET_CREATE_LOCATION_STREET2': {
            return { ...state, createLocation: { ...state.createLocation, address: { ...state.createLocation.address, address_street2: action.payload } } };
        }

        case 'SET_CREATE_LOCATION_GEOPOINT': {
            return { ...state, createLocation: { ...state.createLocation, address: { ...state.createLocation.address, address_latitude: action.payload.lat, address_longitude: action.payload.lng } } };
        }

        case 'SET_CREATE_LOCATION_DESCRIPTION': {
            return { ...state, createLocation: { ...state.createLocation, location_description: action.payload } };
        }

        case 'SET_CREATE_LOCATION_CONTACT_INFO': {
            return { ...state, createLocation: { ...state.createLocation, location_contactinfo: action.payload } };
        }

        case 'SET_CREATE_LOCATION_SOCIAL_MEDIA': {
            return { ...state, createLocation: { ...state.createLocation, location_socialmedia: action.payload } };
        }

        case 'SET_CREATE_LOCATION_ADDITIONAL_INFO': {
            return { ...state, createLocation: { ...state.createLocation, location_additionalinfo: action.payload } };
        }

        case 'SET_CREATE_LOCATION_WEB': {
            return { ...state, createLocation: { ...state.createLocation, location_website: action.payload } };
        }

        case 'SET_CREATE_LOCATION_HOURS': {
            return { ...state, createLocation: { ...state.createLocation, hours: action.payload } };
        }

        case 'SET_CREATE_LOCATION_TYPES': {
            let newIds;
            if (!!state.createLocation && !!state.createLocation.locationtypes && state.createLocation.locationtypes.some(o => o['locationtype_id'] === action.payload.id)) {
                newIds = state.createLocation.locationtypes.filter(item => item.locationtype_id !== action.payload.id);
            } else if (state.createLocation && state.createLocation.locationtypes) {
                newIds = [...state.createLocation.locationtypes, { locationtype_id: action.payload.id, locationtype_name: action.payload.name }];
            } else {
                newIds = [{ locationtype_id: action.payload.id, locationtype_name: action.payload.name }];
            }
            return { ...state, createLocation: { ...state.createLocation, locationtypes: newIds, location_additionalinfo: {} } };
        }

        case 'SET_CREATE_HOUR_SET': {
            return { ...state, createLocation: { ...state.createLocation, hourSet: action.payload } };
        }

        case 'SET_CREATE_TIMES': {
            const day = Object.keys(action.payload)[0];
            const openClose = Object.keys(action.payload[day])[0];

            if (state.createLocation.location_schedule && state.createLocation.location_schedule[day]) {
                return { ...state, createLocation: { ...state.createLocation, location_schedule: { ...state.createLocation.location_schedule, [day]: { ...state.createLocation.location_schedule[day], [openClose]: action.payload[day][openClose], allday: false, closed: false } } } };
            }
            return { ...state, createLocation: { ...state.createLocation, location_schedule: { ...state.createLocation.location_schedule, [day]: { [openClose]: action.payload[day][openClose], allday: false, closed: false } } } };
        }

        case 'SET_CREATE_CLOSE_ALL_DAY': {
            const closeday = action.payload;
            if (state.createLocation.location_schedule && state.createLocation.location_schedule[closeday]) {
                return { ...state, createLocation: { ...state.createLocation, location_schedule: { ...state.createLocation.location_schedule, [closeday]: { ...state.createLocation.location_schedule[closeday], closed: !state.createLocation.location_schedule[closeday].closed, allday: false, open: '', close: '' } } } };
            }
            return { ...state, createLocation: { ...state.createLocation, location_schedule: { ...state.createLocation.location_schedule, [closeday]: { closed: true, open: '', close: '' } } } };
        }

        case 'SET_CREATE_OPEN_ALL_DAY': {
            const openday = action.payload;
            if (state.createLocation.location_schedule && state.createLocation.location_schedule[openday]) {
                return { ...state, createLocation: { ...state.createLocation, location_schedule: { ...state.createLocation.location_schedule, [openday]: { ...state.createLocation.location_schedule[openday], allday: !state.createLocation.location_schedule[openday].allday, closed: false, open: '24h', close: '' } } } };
            }
            return { ...state, createLocation: { ...state.createLocation, location_schedule: { ...state.createLocation.location_schedule, [openday]: { allday: true, open: '24h', close: '' } } } };
        }

        case 'GET_EDIT_LOCATION_FULFILLED': {
            return { ...state, editLocation: { ...action.payload.data, location_schedule: action.payload.data.location_schedule } };
        }

        case 'SET_EDIT_LOCATION_NAME': {
            return { ...state, editLocation: { ...state.editLocation, location_name: action.payload } };
        }

        case 'SET_EDIT_LOCATION_CITY': {
            return { ...state, editLocation: { ...state.editLocation, address: { ...state.editLocation.address, address_city: action.payload } } };
        }

        case 'SET_EDIT_LOCATION_STATE': {
            return { ...state, editLocation: { ...state.editLocation, address: { ...state.editLocation.address, address_state: action.payload } } };
        }

        case 'SET_EDIT_LOCATION_COUNTRY': {
            return { ...state, editLocation: { ...state.editLocation, address: { ...state.editLocation.address, address_country: action.payload } } };
        }

        case 'SET_EDIT_LOCATION_POSTAL': {
            return { ...state, editLocation: { ...state.editLocation, address: { ...state.editLocation.address, address_zipcode: action.payload } } };
        }

        case 'SET_EDIT_LOCATION_STREET1': {
            return { ...state, editLocation: { ...state.editLocation, address: { ...state.editLocation.address, address_street: action.payload } } };
        }

        case 'SET_EDIT_LOCATION_STREET2': {
            return { ...state, editLocation: { ...state.editLocation, address: { ...state.editLocation.address, address_street2: action.payload } } };
        }

        case 'SET_EDIT_LOCATION_GEOPOINT': {
            return { ...state, editLocation: { ...state.editLocation, address: { ...state.editLocation.address, address_latitude: action.payload.lat, address_longitude: action.payload.lng } } };
        }

        case 'SET_EDIT_LOCATION_DESCRIPTION': {
            return { ...state, editLocation: { ...state.editLocation, location_description: action.payload } };
        }

        case 'SET_EDIT_LOCATION_CONTACT_INFO': {
            return { ...state, editLocation: { ...state.editLocation, location_contactinfo: action.payload } };
        }

        case 'SET_EDIT_LOCATION_SOCIAL_MEDIA': {
            return { ...state, editLocation: { ...state.editLocation, location_socialmedia: action.payload } };
        }

        case 'SET_EDIT_LOCATION_ADDITIONAL_INFO': {
            return { ...state, editLocation: { ...state.editLocation, location_additionalinfo: action.payload } };
        }

        case 'SET_EDIT_LOCATION_WEB': {
            return { ...state, editLocation: { ...state.editLocation, location_website: action.payload } };
        }

        case 'SET_EDIT_LOCATION_HOURS': {
            return { ...state, editLocation: { ...state.editLocation, hours: action.payload } };
        }

        case 'SET_EDIT_LOCATION_FEATURED': {
            return { ...state, editLocation: { ...state.editLocation, location_featured: (!state.editLocation.location_featured) } };
        }

        case 'SET_EDIT_LOCATION_TYPES': {
            let newIds2;
            if (!!state.editLocation && !!state.editLocation.locationtypes && state.editLocation.locationtypes.some(o => o['locationtype_id'] === action.payload.id)) {
                newIds2 = state.editLocation.locationtypes.filter(item => item.locationtype_id !== action.payload.id);
            } else if (state.editLocation && state.editLocation.locationtypes) {
                newIds2 = [...state.editLocation.locationtypes, { locationtype_id: action.payload.id, locationtype_name: action.payload.name }];
            } else {
                newIds2 = [{ locationtype_id: action.payload.id, locationtype_name: action.payload.name }];
            }
            return { ...state, editLocation: { ...state.editLocation, locationtypes: newIds2 } };
        }

        case 'SET_EDIT_HOUR_SET': {
            return { ...state, editLocation: { ...state.editLocation, hourSet: action.payload } };
        }

        case 'SET_EDIT_TIMES': {
            const day2 = Object.keys(action.payload)[0];
            const openClose2 = Object.keys(action.payload[day2])[0];

            if (state.editLocation.location_schedule && state.editLocation.location_schedule[day2]) {
                return { ...state, editLocation: { ...state.editLocation, location_schedule: { ...state.editLocation.location_schedule, [day2]: { ...state.editLocation.location_schedule[day2], [openClose2]: action.payload[day2][openClose2], allday: false, closed: false } } } };
            }
            return { ...state, editLocation: { ...state.editLocation, location_schedule: { ...state.editLocation.location_schedule, [day2]: { [openClose2]: action.payload[day2][openClose2], allday: false, closed: false } } } };
        }

        case 'SET_EDIT_CLOSE_ALL_DAY': {
            const closeday2 = action.payload;
            if (state.editLocation.location_schedule && state.editLocation.location_schedule[closeday2]) {
                return { ...state, editLocation: { ...state.editLocation, location_schedule: { ...state.editLocation.location_schedule, [closeday2]: { ...state.editLocation.location_schedule[closeday2], closed: !state.editLocation.location_schedule[closeday2].closed, allday: false, open: '', close: '' } } } };
            }
            return { ...state, editLocation: { ...state.editLocation, location_schedule: { ...state.editLocation.location_schedule, [closeday2]: { closed: true, open: '', close: '' } } } };
        }

        case 'SET_EDIT_OPEN_ALL_DAY': {
            const openday2 = action.payload;
            if (state.editLocation.location_schedule && state.editLocation.location_schedule[openday2]) {
                return { ...state, editLocation: { ...state.editLocation, location_schedule: { ...state.editLocation.location_schedule, [openday2]: { ...state.editLocation.location_schedule[openday2], allday: !state.editLocation.location_schedule[openday2].allday, closed: false, open: '24h', close: '' } } } };
            }
            return { ...state, editLocation: { ...state.editLocation, location_schedule: { ...state.editLocation.location_schedule, [openday2]: { allday: true, open: '24h', close: '' } } } };
        }

        case 'SAVE_EDIT_LOCATION_FULFILLED':
            toast.success('Saved');
            return { ...state };

        case 'SAVE_EDIT_LOCATION_REJECTED':
            ApiError('There is some missing data, please verify all the data and try again.');
            return { ...state };

        case 'DELETE_LOCATION_FULFILLED':
            toast.success('Location has beed deleted');
            return { ...state };

        case 'DELETE_LOCATION_REJECTED':
            ApiError('There seems to be an issue with the location or your credentials');
            return { ...state };

        case 'USER_LOGOUT':
            return {
                ...state,
                createLocation: { locationtypes: [] },
                editLocation: { locationtypes: [] },
                locationReview: { images: [] },
                searchedLocationsList: [],
                searchedLocationsPosition: null
            };

        case 'SET_MAP_LAST_LOCATION_POSITION': {
            return {
                ...state,
                mapLastLocation: (action.payload?.address_latitude && action.payload?.address_longitude) ? {
                    address_latitude: action.payload.address_latitude,
                    address_longitude: action.payload.address_longitude
                } : null
            };
        }

        default:
            return { ...state };
    }
}
