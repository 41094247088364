import React from 'react';
import { Link } from 'react-router-dom';

import Avatar from '../UI/Avatar';
import { guard, formatDateTime } from '../../helpers/Functions';

const CommentBox = ({ user, creator, date, comment }) => (
    <div className="wrapper backgroundWhite comment">
        <div className="row alignItemsTop">
            <Link className="avatar round-avatar user small colored-icon" to={'/user/profile/' + user.user_id}>
                <Avatar
                    src={guard(user, '.images.0.image_smallurl')}
                    alt={user.user_firstname + ' ' + user.user_lastname}
                    type="user"
                />
            </Link>
            <div>
                {creator && (
                    <div className="h7 fontBlack">
                        Posted by
                    </div>
                )}
                <Link className="fontGreen" to={'/user/profile/' + user.user_id}>
                    {user.user_firstname + ' ' + user.user_lastname}
                    {date && (<span className="h6 fontGray"> - {formatDateTime(date)}</span>)}
                </Link>
                {comment && (
                    <div className="fontDarkGray">
                        <div className="comment-text">
                            {comment.split('\n').map((item, index) => (
                                <div key={index}>{item}</div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    </div>
);

export default CommentBox;
