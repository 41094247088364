import React from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Textarea from 'react-textarea-autosize';
import parse from 'html-react-parser';

import { userDelete, logout, updateUser, refreshOwnProfile, setUserSocialMedia, setUserBio } from '../../redux/actions/users';
import { uploadPicture, deletePicture, setPicture } from '../../redux/actions/all';
import ApiError from '../../helpers/ApiError';
import sb from '../../helpers/SendBird';
import Config from '../../helpers/Config';
import { camera, cameraRoll, cameraMultiRoll, cameraBlob } from '../../helpers/Camera';
import { guard } from '../../helpers/Functions';
import Avatar from '../../layout/UI/Avatar';
import CameraModal from '../../layout/CameraModal';
import GalleryLightbox from '../../layout/gallery/GalleryLightbox';
import WhiteTitle from '../../hoc/WhiteTitle';
import AdvancedConfirm from '../../layout/UI/AdvancedConfirm';
import Spinner from '../../layout/UI/Spinner';

export class OwnProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            image: null,
            dogs: null,
            fname: false,
            lname: false,
            email: false,
            phone: false,
            bio: false,
            socialMedia: {},
            playdateTime: false,
            changed: false,
            gallery: [],
            pictureModal: false,
            pictureModal2: false,
            entity_poochid: '',
            loadingDeleteAccount: false
        };
        this.props.dispatch(refreshOwnProfile(this.props.credentials.token, this.props.credentials.user_id));
    }

    componentDidMount() {
        this.setState({
            user: this.props.user,
            dogs: this.props.dogs,
            image: guard(this.props.user, '.images.0.image_mediumurl') || null,
            gallery: this.props.user.images,
            socialMedia: this.props.user.user_socialmedia,
            bio: this.props.user.user_aboutme,
            entity_poochid: ((this.props.user && this.props.user.parententity && this.props.user.parententity.entity_poochid) || '')
        });
    }

    handlePoochID = e => {
        const poochId = e.target.value.split('pooch.pet/');
        let text = '';
        if (poochId.length > 1) {
            text = poochId[1].replace(/[^a-z0-9_-]+/gi, '').toLowerCase();
        }
        this.setState({
            changed: true,
            entity_poochid: text
        });
    }

    filesChanged = files => {
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            const file = files[i];
            reader.onloadend = () => {
                this.props.dispatch(uploadPicture(this.props.credentials.token, this.props.user.user_id, file, true))
                    .then(response => {
                        this.props.dispatch(refreshOwnProfile(this.props.credentials.token, this.props.credentials.user_id));
                        this.updateThumbs(response, true);
                    });
            };
            reader.readAsDataURL(file);
        }
    }

    galleryfiles = files => {
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            const file = files[i];
            reader.onloadend = () => {
                this.props.dispatch(uploadPicture(this.props.credentials.token, this.props.user.user_id, file, false))
                    .then(response => this.updateThumbs(response));
            };
            reader.readAsDataURL(file);
        }
    }

    getDogNames = dogs => {
        const dogNames = [];
        for (const i in dogs) {
            dogNames.push(dogs[i].dog_name);
        }
        return (<span>{dogNames.join(', ')}</span>);
    }

    handleChange = event => {
        this.setState({
            changed: true,
            [event.target.name]: event.target.value
        });
    }

    updateSendBird = () => {
        const that = this;
        sb.connect(
            that.props.credentials.user_id,
            that.props.credentials.token,
            () => {
                sb.updateCurrentUserInfo(
                    that.props.userData.user_firstname + ' ' + that.props.userData.user_lastname,
                    guard(that.props.userData, '.images.0.image_smallurl') || '',
                    () => {

                    }
                );
            }
        );
    }

    saveChanges = e => {
        e.preventDefault();
        const that = this;
        const changes = {};
        if (this.state.fname) {
            changes.user_firstname = this.state.fname;
        }
        if (this.state.lname !== false) {
            changes.user_lastname = this.state.lname;
        }
        if (this.state.phone !== false) {
            changes.user_phone = this.state.phone;
        }
        if (this.state.email !== false) {
            if (!/\S+@\S+\.\S+/.test(this.state.email)) {
                ApiError('Please verify your Email!');
                return;
            }
            changes.user_email = this.state.email;
        }
        if (this.state.socialMedia !== false) {
            changes.user_socialmedia = this.state.socialMedia;
        }
        if (this.state.bio !== false) {
            changes.user_aboutme = this.state.bio;
        }
        if (this.state.playdateTime !== false) {
            changes.user_primaryhours = this.state.playdateTime;
        }

        changes.entity_poochid = this.state.entity_poochid;

        if (Object.keys(changes).length && this.state.changed) {
            this.props.dispatch(updateUser(this.props.credentials.token, this.props.credentials.user_id, changes))
                .then(response => {
                    console.log(response);
                    toast.success('Saved!');
                    that.updateSendBird();
                    that.setState({
                        changed: false
                    });
                })
                .catch(err => {
                    ApiError(err);
                });
        } else {
            toast.info('No changes to save.');
        }
    }

    toggleModal = () => {
        this.setState({
            pictureModal: !this.state.pictureModal,
            changed: true
        });
    }

    toggleModal2 = () => {
        this.setState({
            pictureModal2: !this.state.pictureModal2,
            changed: true
        });
    }

    changeImage = () => {
        if (Config.isMobileApp) {
            this.toggleModal();
        } else {
            const selectfile = document.getElementById('file');
            selectfile.click();
        }
    }

    // Removes image from GalleryLightBox
    handleClickRemoveImage = id => {
        this.props.dispatch(deletePicture(this.props.credentials.token, id))
            .then(() => {
                this.setState(prevState => {
                    const newGallery = prevState.gallery.filter(image => image.image_id !== id);
                    return {
                        gallery: newGallery
                    };
                }, () => {
                    this.setState({ image: this.state.gallery && this.state.gallery[0] && this.state.gallery[0].image_largeurl ? this.state.gallery[0].image_largeurl : null });
                });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    handleClickSetImage = id => {
        this.props.dispatch(setPicture(this.props.credentials.token, id))
            .then(res => {
                let newFeaturedImage = {};
                // we push the new featured image to the top of the list, on the first position
                this.setState(prevState => {
                    const newGallery = prevState.gallery.filter(image => {
                        if (image.image_id === id) newFeaturedImage = image;
                        return image.image_id !== id;
                    });
                    newGallery.sort((a, b) => b.image_id - a.image_id);
                    return {
                        gallery: [newFeaturedImage, ...newGallery],
                        image: res.value.data.image_largeurl
                    };
                });
                toast.success('Profile Image Set Successfully!');
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
            });
    }

    addToGallery = e => {
        e.preventDefault();
        if (Config.isMobileApp) {
            this.toggleModal2();
        } else {
            const selectfile = document.getElementById('galleryfile');
            selectfile.click();
        }
    }

    profileCamera = () => {
        camera(this.profileSuccess, 1);
    }

    profileUpload = () => {
        cameraRoll(this.profileSuccess);
    }

    profileSuccess = file => {
        this.setState({ image: file, pictureModal: false });
        toast('Uploading Picture');
        cameraBlob(file, this.uploadToProfile);
    }

    uploadToProfile = imgBlob => {
        this.props.dispatch(uploadPicture(this.props.credentials.token, this.props.user.user_id, imgBlob, true))
            .then(response => {
                this.updateSendBird();
                this.props.dispatch(refreshOwnProfile(this.props.credentials.token, this.props.credentials.user_id));
                this.updateThumbs(response);
            })
            .catch(err => ApiError(err && err.message ? err.message : 'There was an error uploading'));
    }

    galleryCamera = () => {
        camera(this.gallerySuccess);
    }

    galleryUpload = () => {
        cameraMultiRoll(this.gallerySuccess);
    }

    gallerySuccess = file => {
        cameraBlob(file, this.uploadToGallery);
    }

    uploadToGallery = imgBlob => {
        this.props.dispatch(uploadPicture(this.props.credentials.token, this.props.credentials.user_id, imgBlob, false))
            .then(response => this.updateThumbs(response));
    }

    updateThumbs = (response, featured) => {
        if (featured) {
            this.setState({ image: response.value.data.image_mediumurl });
        }
        this.setState(prevState => ({
            pictureModal: false,
            pictureModal2: false,
            gallery: [response.value.data, ...prevState.gallery]
        }));
        if (!!navigator && !!navigator.camera && !!navigator.camera.cleanup) {
            navigator.camera.cleanup();
        }
    }

    socialMediaHandler = event => {
        this.setState({
            changed: true,
            socialMedia: { ...this.state.socialMedia, [event.target.name]: event.target.value }
        }, () => {
            this.props.dispatch(setUserSocialMedia(this.state.socialMedia));
        });
    }

    userBio = e => {
        this.setState({
            changed: true,
            [e.target.name]: e.target.value
        }, () => {
            this.props.dispatch(setUserBio(this.state.bio));
        });
    }

    deleteAccount = (token, userId) => {
        const headerText = 'Are you sure you want to close your account?';
        const bodyText = 'If there\'s anything we can help you with, please <a href="' + process.env.REACT_APP_EMAIL_SUPPORT + '">contact us</a>. Once it\'s closed, you won\'t be able to use this Pooch account, and all your account data and uploaded pictures will be removed. It can\'t be reopened, and we can\'t restore it. Please type "REMOVE ACCOUNT" below to confirm.';
        AdvancedConfirm(
            headerText,
            parse(bodyText),
            'REMOVE ACCOUNT',
            'Remove it',
            () => {
                console.log('Deleting account:', userId);
                this.setState({ loadingDeleteAccount: true });
                this.props.dispatch(userDelete(token, userId))
                    .then(() => {
                        toast.success('Account deleted successfully.');
                        this.props.dispatch(logout());
                        this.setState({ loadingDeleteAccount: false });
                        this.props.history.push('/');
                    })
                    .catch(err => {
                        console.log('deleteAccount:err:', err);
                        this.setState({ loadingDeleteAccount: false });
                        ApiError('Something went wrong... Please try again later.');
                    });
            }
        );
    };

    render() {
        return (
            <div>
                {this.state.pictureModal && (
                    <CameraModal toggleModal={this.toggleModal} callbackCamera={this.profileCamera} callbackUpload={this.profileUpload} />
                )}
                {this.state.pictureModal2 && (
                    <CameraModal toggleModal={this.toggleModal2} callbackCamera={this.galleryCamera} callbackUpload={this.galleryUpload} />
                )}
                {this.state.user && (
                    <div>
                        <div className="profilePictureWrapper user edit">
                            <div className="fontWhite wrapper alignCenter">
                                <input className="hidden" id="file" type="file" onChange={e => this.filesChanged(e.target.files)} accept="image/*" required />
                                <div className="round-avatar huge user fontWhite">
                                    <Avatar
                                        src={this.state.image}
                                        alt={this.state.user.user_firstname + ' ' + this.state.user.user_lastname}
                                        onClick={this.changeImage}
                                        className="changeImage"
                                        type="user"
                                    />
                                </div>
                                <div className="fontWhite alignCenter">
                                    <div onClick={() => { this.changeImage(); }} className="changeImage spacerVertical">Change Profile Picture</div>
                                </div>
                            </div>
                        </div>
                        <div className="tabSubmenu">
                            <div className="maxWidth row">
                                <Link className="fontGreen" to="/user/dogadd">Add Dog</Link>
                                <Link className="fontGreen" to="/friends">Friends</Link>
                                <Link className="fontGreen" to="/activities">Activity</Link>
                            </div>
                        </div>
                        <div className="profileDetails maxWidth noPadding">
                            <div className="imageSection">
                                <div className="boxContainer">
                                    <div className="boxTitle row">
                                        <div className="title">
                                            <h4>Pictures</h4>
                                        </div>
                                    </div>
                                    <div className="edit-user image-options">
                                        <input name="galleryfile" className="hidden" id="galleryfile" type="file" onChange={e => this.galleryfiles(e.target.files)} accept="image/*" required multiple />
                                        {this.state.gallery.length > 0 && (
                                            <Link to={'/user/profile/' + this.props.user.user_id + '/gallery'} className="fontGreen"> <i className="icn icon-gallery" /> View Gallery </Link>
                                        )}
                                        <Link to="#" onClick={e => { this.addToGallery(e); }} className="fontGreen">
                                            <i className="icn icon-upload-photo" /> Add Pictures
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {this.state.gallery.length > 0 && (
                                <div className="galleryHolder">
                                    <GalleryLightbox
                                        dispatch={this.props.dispatch}
                                        credentials={this.props.credentials}
                                        userData={this.props.userData}
                                        images={this.state.gallery}
                                        count={2}
                                        size="normal"
                                        handleClickRemoveImage={this.handleClickRemoveImage}
                                        handleClickSetImage={this.handleClickSetImage}
                                    />
                                    {this.state.gallery.length > 2 && (
                                        <div className="holderEnclosed"><Link to={'/user/profile/' + this.props.userData.user_id + '/gallery'}>+ {this.state.gallery.length - 2} More</Link></div>
                                    )}
                                </div>
                            )}
                            <form onSubmit={this.saveChanges}>
                                <div className="spacerSmall" />
                                <WhiteTitle>Pooch ID</WhiteTitle>
                                <div className="profileDetails maxWidth">
                                    <div className="userSocialMedia">
                                        <ul>
                                            <li className="socialMediaListItem">
                                                <div>
                                                    <label className="fontBlack h6">Create your unique Pooch Link</label><br />
                                                    <input className="transparentInput" name="entity_poochid" value={'pooch.pet/' + this.state.entity_poochid} onChange={this.handlePoochID} />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <WhiteTitle>About Me</WhiteTitle>
                                <div className="profileDetails maxWidth">
                                    <Textarea
                                        onChange={e => this.userBio(e)}
                                        className="about-me-textarea"
                                        placeholder="Write a few sentences about yourself!"
                                        name="bio"
                                        value={this.state.bio || ''}
                                    />
                                </div>
                                <WhiteTitle>Details</WhiteTitle>
                                <div className="profileDetails maxWidth">
                                    <ul className="listDivided rightIcon">
                                        <li>
                                            <Link to="/user/dogs" className="fontBlack">
                                                <div>
                                                    <label className="fontBlack h6">Dogs</label><br />
                                                    {this.getDogNames(this.state.dogs)}
                                                </div>
                                                <i className="icn icon-right-arrow fontGreen" />
                                            </Link>
                                        </li>
                                        <li>
                                            <div>
                                                <label className="fontBlack h6">First Name</label><br />
                                                <input className="transparentInput" name="fname" value={this.state.fname === false ? this.state.user.user_firstname || '' : this.state.fname} onChange={e => this.handleChange(e)} />
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <label className="fontBlack h6">Last Name</label><br />
                                                <input className="transparentInput" name="lname" value={this.state.lname === false ? this.state.user.user_lastname || '' : this.state.lname} onChange={e => this.handleChange(e)} />
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <label className="fontBlack h6">Email</label><br />
                                                <input className="transparentInput" type="email" name="email" value={this.state.email === false ? this.state.user.user_email || '' : this.state.email} onChange={e => this.handleChange(e)} />
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <label className="fontBlack h6">Phone Number</label><br />
                                                <input className="transparentInput" type="tel" name="phone" value={this.state.phone === false ? this.state.user.user_phone || '' : this.state.phone} onChange={e => this.handleChange(e)} />
                                            </div>
                                        </li>
                                        <li>
                                            <Link to="/user/address" className="fontBlack">
                                                <div>
                                                    <label className="fontBlack h6">Address</label><br />
                                                    {this.props.userData && this.props.userData.address_id && this.props.userData.user_location
                                                        ? (
                                                            <span>
                                                                {this.props.userData.user_location.address_street}
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                {this.props.userData && this.props.userData.address && this.props.userData.address.address_street}
                                                            </span>
                                                        )}
                                                </div>
                                                <i className="icn icon-right-arrow fontGreen" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/user/park" className="fontBlack">
                                                <div>
                                                    <label className="fontBlack h6">Favorite Park</label><br />
                                                    {(!!this.state.user && !!this.state.user.primarylocation && this.state.user.primarylocation.location_name) || 'Select a Location'}
                                                </div>
                                                <i className="icn icon-right-arrow fontGreen" />
                                            </Link>
                                        </li>
                                        <li>
                                            <div>
                                                <label className="fontBlack h6">When are you available for Events?</label><br />
                                                <input className="transparentInput" name="playdateTime" value={this.state.playdateTime === false ? this.state.user.user_primaryhours || '' : this.state.playdateTime} onChange={e => this.handleChange(e)} placeholder="e.g. Weekdays after 6pm" />
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="spacerSmall" />
                                <WhiteTitle>Social Media</WhiteTitle>
                                <div className="profileDetails ">
                                    <div className="userSocialMedia">
                                        <ul>
                                            <li className="socialMediaListItem row">
                                                <i className="icn icon-facebook fontGreen" />
                                                <div>
                                                    <input
                                                        className="transparentInput"
                                                        type="text"
                                                        name="facebook"
                                                        placeholder="Facebook"
                                                        onChange={this.socialMediaHandler}
                                                        value={(this.state.socialMedia && this.state.socialMedia.facebook === undefined) ? (this.props.user && this.props.user.user_socialmedia && this.props.user.user_socialmedia.facebook) || '' : (this.state.socialMedia && this.state.socialMedia.facebook) || ''}
                                                    />
                                                </div>
                                            </li>
                                            <li className="socialMediaListItem row">
                                                <i className="icn icon-twitter fontGreen" />
                                                <div>
                                                    <input
                                                        className="transparentInput"
                                                        type="text"
                                                        name="twitter"
                                                        placeholder="Twitter"
                                                        onChange={this.socialMediaHandler}
                                                        value={(this.state.socialMedia && this.state.socialMedia.twitter === undefined) ? (this.props.user && this.props.user.user_socialmedia && this.props.user.user_socialmedia.twitter) || '' : (this.state.socialMedia && this.state.socialMedia.twitter) || ''}
                                                    />
                                                </div>
                                            </li>
                                            <li className="socialMediaListItem row">
                                                <i className="icn icon-instagram fontGreen" />
                                                <div>
                                                    <input
                                                        className="transparentInput"
                                                        type="text"
                                                        name="instagram"
                                                        placeholder="Instagram"
                                                        onChange={this.socialMediaHandler}
                                                        value={(this.state.socialMedia && this.state.socialMedia.instagram === undefined) ? (this.props.user && this.props.user.user_socialmedia && this.props.user.user_socialmedia.instagram) || '' : (this.state.socialMedia && this.state.socialMedia.instagram) || ''}
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {this.state.loadingDeleteAccount
                                    ? (<Spinner />)
                                    : (
                                        <React.Fragment>
                                            <input
                                                type="button"
                                                value="Remove"
                                                className="leftRoundButton redBorder redBG fontWhite"
                                                onClick={() => this.deleteAccount(this.props.credentials?.token, this.props.userData?.user_id)}
                                            />
                                            <input
                                                type="submit"
                                                value="Save Changes"
                                                className="rightRoundButton greenBorder greenBG fontWhite"
                                            />
                                            <Link to={'/user/profile/' + this.props.userData.user_id} className="rightRoundButton greenBorder whiteBG fontGreen">
                                                View Profile
                                            </Link>
                                        </React.Fragment>
                                    )}
                            </form>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

OwnProfile.propTypes = {
    dogs: PropTypes.instanceOf(Array),
    user: PropTypes.instanceOf(Object).isRequired,
    userData: PropTypes.instanceOf(Object),
    credentials: PropTypes.instanceOf(Object).isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.instanceOf(Object).isRequired
};
