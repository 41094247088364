import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import { getFavorites } from '../../redux/actions/locations';
import { LocationListItem } from '../../layout/locations/LocationListItem';
import Spinner from '../../layout/UI/Spinner';
import { InviteFriends } from '../../layout/InviteFriends';
import LocationsNearBy from '../../layout/LocationsNearBy';
import { infiniteScroll } from '../../helpers/Pull2Refresh';
import ApiError from '../../helpers/ApiError';
import WhiteTitle from '../../hoc/WhiteTitle';
import Columns from '../../hoc/Columns';
import FriendRequestsComponent from '../../pagesDesktop/Friends/FriendRequestsComponent';
import FindFriendsComponent from '../../pagesDesktop/Friends/FindFriendsComponent';

export class Favorites extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locationList: [],
            loading: true,
            loadingMore: false,
            currentPage: 1,
            totalPages: 1 // This it's being used by infiniteScroll->handleScroll->loadMore
            // location: null
        };
    }

    componentDidMount() {
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        this.start();
    }

    init = () => {
        this.setState({
            locationList: [],
            loading: true,
            loadingMore: false,
            currentPage: 1,
            totalPages: 1
        }, this.start());
    }

    start = () => {
        this.props.dispatch(getFavorites(this.props.credentials.token, this.state.currentPage))
            .then(response => {
                this.locationList(response.value.data);
                this.setState({
                    totalPages: response.value.headers['x-pagination-page-count']
                }, () => {
                    if (this.state.currentPage < this.state.totalPages) {
                        infiniteScroll(this, this.start);
                    }
                });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.locationList();
            });
    }

    locationList = moreLocations => {
        this.setState(prevState => {
            if (moreLocations) {
                return {
                    locationList: prevState.locationList.concat(moreLocations),
                    loading: false,
                    loadingMore: false
                };
            }
            return {
                loading: false,
                loadingMore: false
            };
        });
    }

    render() {
        let newlocations = null;
        if (this.state.loading) { // if loading
            newlocations = (<Spinner />);
        } else if (this.state.locationList.length > 0) { // if it has at least 1 location
            newlocations = this.state.locationList.map(location => (
                <LocationListItem
                    dispatch={this.props.dispatch}
                    credentials={this.props.credentials}
                    key={location.location_id}
                    location={location}
                    url="location/profile"
                />
            ));
        } else { // ideally if there are no locations, but you didn't search for anything means that the filter is empty
            newlocations = (<h2>Sorry, you dont have any favorite locations yet.</h2>);
        }

        let nearbyDefault = false;

        if (this.props.userData && this.props.userData.user_location && this.props.userData.user_location.address_latitude) {
            nearbyDefault = (this.props.userData && this.props.userData.user_location);
        } else {
            nearbyDefault = (this.props.user && this.props.user.userData.address);
        }

        const c1 = (
            <React.Fragment>
                {this.props.userData && this.props.userData.user_location && this.props.userData.user_location.address_latitude && (
                    <LocationsNearBy
                        geoLocation={nearbyDefault}
                        dispatch={this.props.dispatch}
                        showSpinner={true}
                    />
                )}
            </React.Fragment>
        );

        const c2 = (
            <React.Fragment>
                <WhiteTitle>Your Favorites</WhiteTitle>
                {newlocations}
                {this.state.loadingMore && (<Spinner />)}
            </React.Fragment>
        );

        const c3 = (
            <React.Fragment>
                <InviteFriends wrapperClass="noWrapper" />
                <FriendRequestsComponent limit={3} infiniteScroll={false} viewMoreButton={true} />
                <WhiteTitle>Suggested Friends</WhiteTitle>
                <FindFriendsComponent perPage={3} infiniteScroll={false} />
                <Link to="/findfriends" className="square-button greenBG fontWhite h6">View all</Link>
            </React.Fragment>
        );

        return (
            <div className="mainAppContainer ">
                <div className="bodyContent locationListSearch">
                    <div className="three-column-layout">
                        <Columns
                            c1={c1}
                            c2={c2}
                            c3={c3}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(Favorites);
