import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export const FoundDogsNavigation = ({ showTitle, selected }) => (
    <React.Fragment>
        {showTitle && (
            <div className="simple-title">
                <i className="icn icon-eye fontBlue" /> <b className="fontBlack">Found Dogs</b>
            </div>
        )}
        <div className="tabSubmenu">
            <div className="navigationTabs">
                <div className="maxWidth row">
                    <Link to="/founddogs" className={(selected === 'found-dogs' ? 'active' : '')}>
                        Found Dogs
                    </Link>
                    <Link to="/reportfound" className={(selected === 'report-found-dog' ? 'active' : '')}>
                        Report a Found Dog
                    </Link>
                </div>
            </div>
        </div>
    </React.Fragment>
);

FoundDogsNavigation.propTypes = {
    selected: PropTypes.string,
    showTitle: PropTypes.bool
};
