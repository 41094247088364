import React from 'react';
import ReactGA from 'react-ga';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { setEmail, setFirstName, setLastName, register, fbregister } from '../../redux/actions/users';
import Config from '../../helpers/Config';
import { guard } from '../../helpers/Functions';
import sb from '../../helpers/SendBird';
import { getDeviceToken } from '../../helpers/PushNotifications';
import ApiError from '../../helpers/ApiError';
import BasicAlert from '../../layout/UI/BasicAlert';
import FacebookLoginButton from '../../FacebookLoginButton';

class ModalDesktopRegister extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPasswordVisible: false,
            password: null,
            confirmPassword: null,
            loggingIn: false
        };
    }

    fbLogin = e => {
        e.preventDefault();
        this.setState({ loggingIn: true });
        const that = this;
        const fbLoginSuccess = () => {
            window.facebookConnectPlugin.getAccessToken(token => {
                that.responseFacebook({ accessToken: token });
            });
        };
        window.facebookConnectPlugin.login(['public_profile', 'email'], fbLoginSuccess,
            error => {
                console.log(error);
                this.setState({ loggingIn: false });
                BasicAlert('Sorry there is an issue with the credentials provided');
            }, { auth_type: 'reauthenticate' });
    }

    responseFacebook = response => {
        this.setState({ loggingIn: true });
        if ((response && response.email && response.id) || (response.accessToken)) {
            const that = this;
            this.props.dispatch(fbregister(response.accessToken))
                .then(response => {
                    console.log(response);
                    toast.success('Welcome to Pooch!');
                    sb.connect(
                        that.props.credentials.user_id,
                        that.props.credentials.token,
                        () => {
                            sb.updateCurrentUserInfo(
                                that.props.userData.user_firstname + ' ' + that.props.userData.user_lastname,
                                guard(that.props.userData, '.images.0.image_smallurl') || '',
                                () => { }
                            );
                        }
                    );
                    getDeviceToken(that.props.dispatch, that.props.credentials);
                    ReactGA.event({
                        category: 'UserRegistration',
                        action: 'FB Registration Success'
                    });
                    if (window?.location) window.location.reload();
                })
                .catch(() => {
                    this.setState({ loggingIn: false });
                    ApiError('Email is already in use, try to login instead.');
                });
        } else {
            this.setState({ loggingIn: false });
        }
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    verifyData = e => {
        e.preventDefault();
        if (!this.props.userData.user_firstname || !this.props.userData.user_lastname) {
            ApiError('Please write your First Name and Last Name');
        }
        if (!this.props.userData.user_email) {
            ApiError('Please write your Email!');
        } else if (!/\S+@\S+\.\S+/.test(this.props.userData.user_email)) {
            ApiError('Please verify your Email!');
        }
        if (this.state.password && this.state.password.length >= 8 && this.state.password === this.state.confirmPassword) {
            const that = this;
            this.props.dispatch(register(this.props.userData.user_firstname, this.props.userData.user_lastname, this.props.userData.user_email, this.state.password))
                .then(response => {
                    console.log(response);
                    toast.success('Welcome to Pooch!');
                    sb.connect(
                        that.props.credentials.user_id,
                        that.props.credentials.token,
                        () => {
                            sb.updateCurrentUserInfo(
                                that.props.userData.user_firstname + ' ' + that.props.userData.user_lastname,
                                guard(that.props.userData, '.images.0.image_smallurl') || '',
                                () => { }
                            );
                        }
                    );
                    ReactGA.event({
                        category: 'UserRegistration',
                        action: 'Email Registration Success'
                    });
                    getDeviceToken(that.props.dispatch, that.props.credentials);
                    if (window?.location) window.location.reload();
                })
                .catch(err => {
                    let errorMsg = 'There was an unexpected error';
                    if (!!err && !!err.response && !!err.response.data && !!err.response.data.message) {
                        const messages = JSON.parse(err.response.data.message);
                        if (messages?.user_email?.[0]) errorMsg = messages.user_email[0];
                    }
                    ApiError(errorMsg);
                });
        } else if (this.state.password !== this.state.confirmPassword) {
            ApiError('Passwords do not match!');
        } else {
            ApiError('Please write your password, it must be at least 8 characters long');
        }
    }

    render() {
        return (
            <div className="modal display-block">
                <section className="modalLoginDesktop">
                    <div className="registerModalContainer">
                        <section className="modalTopSection">
                            <h2>Register.</h2>
                        </section>
                        <form className="registerForm" onSubmit={this.verifyData}>
                            <div className="innerLabelInput">
                                <span className="blocking-span">
                                    <input
                                        className="inputText"
                                        id="firstName"
                                        name="firstName"
                                        type="text"
                                        onChange={e => this.props.dispatch(setFirstName(e.target.value))}
                                        required
                                        value={this.props.userData.user_firstname}
                                    />
                                    <span className="floating-label">First Name</span>
                                </span>
                            </div>
                            <div className="innerLabelInput">
                                <span className="blocking-span">
                                    <input
                                        className="inputText"
                                        id="lastName"
                                        name="lastName"
                                        type="text"
                                        onChange={e => this.props.dispatch(setLastName(e.target.value))}
                                        required
                                        value={this.props.userData.user_lastname}
                                    />
                                    <span className="floating-label">Last Name</span>
                                </span>
                            </div>
                            <div className="innerLabelInput">
                                <span className="blocking-span">
                                    <input
                                        className="inputText"
                                        id="email"
                                        name="email"
                                        type="email"
                                        onChange={e => this.props.dispatch(setEmail(e.target.value))}
                                        required
                                        value={this.props.userData.user_email}
                                    />
                                    <span className="floating-label">Email</span>
                                </span>
                            </div>
                            <div className="innerLabelInput">
                                <span className="blocking-span">
                                    {this.state.isPasswordVisible ? (
                                        <input
                                            className="inputText"
                                            id="password"
                                            name="password"
                                            type="text"
                                            minLength={8}
                                            onChange={e => this.handleChange(e)}
                                            required
                                        />
                                    ) : (
                                        <input
                                            className="inputText"
                                            id="password"
                                            name="password"
                                            type="password"
                                            minLength={8}
                                            onChange={e => this.handleChange(e)}
                                            required
                                        />
                                    )}
                                    <span className="floating-label">Password</span>
                                    {this.state.password && this.state.confirmPassword && this.state.password === this.state.confirmPassword && (
                                        <i className="icn icon-check-mark fontWhite" />
                                    )}
                                </span>
                                <div
                                    className="showPassword"
                                    onClick={() => this.setState({ isPasswordVisible: !this.state.isPasswordVisible })}
                                >
                                    {this.state.isPasswordVisible ? 'Hide' : 'Show'}
                                </div>
                            </div>
                            <div className="innerLabelInput">
                                <span className="blocking-span">
                                    {this.state.isPasswordVisible ? (
                                        <input
                                            className="inputText"
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            type="text"
                                            minLength={8}
                                            onChange={e => this.handleChange(e)}
                                            required
                                        />
                                    ) : (
                                        <input
                                            className="inputText"
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            type="password"
                                            minLength={8}
                                            onChange={e => this.handleChange(e)}
                                            required
                                        />
                                    )}
                                    <span className="floating-label">Confirm Password</span>
                                    {this.state.password && this.state.confirmPassword && this.state.password === this.state.confirmPassword && (
                                        <i className="icn icon-check-mark fontWhite" />
                                    )}
                                </span>
                            </div>
                            <div
                                className="rightRoundButton transparentBorder transparent fontWhite"
                                onClick={this.props.showModalDesktopLogin}
                            >
                                Already have an account?
                            </div>
                            <input
                                value="Create Account &gt;"
                                type="submit"
                                className="rightRoundButton whiteBorder whiteOpaque fontWhite"
                            />
                            <div className="separator alignCenter"> OR </div>
                            {this.state.loggingIn && (
                                <div className="wideButtons whiteBorder whiteBG fontGreen">
                                    <i className="icn icon-facebook1" /> Logging In...
                                </div>
                            )}
                            {!this.state.loggingIn && Config.isMobileApp && (
                                <Link
                                    to="/loginemail"
                                    onClick={e => this.fbLogin(e)}
                                    className="wideButtons fb-button"
                                >
                                    <i className="icn icon-facebook1" /> Continue with Facebook
                                </Link>
                            )}
                            {!this.state.loggingIn && !Config.isMobileApp && (
                                <FacebookLoginButton
                                    textButton="Continue with Facebook"
                                    callback={this.responseFacebook}
                                />
                            )}
                        </form>
                    </div>
                </section>
                <i className="icn icon-cross fontWhite" onClick={this.props.handleClose} />
                <div className="wholeScreen" onClick={this.props.handleClose} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(ModalDesktopRegister);
