import React from 'react';

import Config from '../helpers/Config';

class DownloadAppButton extends React.Component {
    render() {
        const button = [];
        if (Config.isMobileApp === false) {
            if (Config.DeviceType === 'Android' || Config.DeviceType === 'Other') {
                button.push(
                    <a
                        key="android"
                        className="wideButtons whiteBorder backgroundWhite fontBlack"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://play.google.com/store/apps/details?id=com.hottomali.OffLeashZone&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    >
                        <i className="icn icon-googleplay" /> Download on Google Play
                    </a>
                );
            }

            if (Config.DeviceType === 'Ios' || Config.DeviceType === 'Other') {
                button.push(
                    <a
                        key="ios"
                        className="wideButtons whiteBorder backgroundWhite fontBlack"
                        href="https://itunes.apple.com/app/id1049134590"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="icn icon-apple" /> Download on the App Store
                    </a>
                );
            }
        }

        return (
            <div className="downloadAppMobile">
                {button}
            </div>
        );
    }
}

export default DownloadAppButton;
