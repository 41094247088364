import React from 'react';

const WhiteTitle = ({ className = '', children }) => (
    <div className={'boxContainer ' + className}>
        <div className="boxTitle row">
            <div className="title">
                {children}
            </div>
        </div>
    </div>
);

export default WhiteTitle;
