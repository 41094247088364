import { toast } from 'react-toastify';
import axios from 'axios';

import { setUserLocation, userNewAddress } from '../redux/actions/users';
import Config from './Config';
import ApiError from './ApiError';

export function getGeoLocation(props, callback, token = '', showError = false, timeLimit = 6000, maxAge = 120000) {
    // console.log('the properties of getGeoLocation ', props, callback, token, showError, timeLimit, maxAge)
    if (navigator.geolocation) {
        // console.log('1 - enter if')
        navigator.geolocation.getCurrentPosition(position => { showPosition(position, props, callback, token); }, error => { showDefaultPosition(error, props, callback, showError); }, { timeout: timeLimit, enableHighAccuracy: true, maximumAge: maxAge });
    } else {
        // console.log('2 - enter else')
        showDefaultPosition(false, props, callback, true);
    }
}

export function showPosition(position, props, callback, token) {
    // console.log('showPosition',position)
    if (token) {
        // console.log('3 - with token')
        reverseGeocode(position)
            .then(results => {
                // console.log('4 - reverse geocode', results)
                props.dispatch(setUserLocation(results));
                props.dispatch(userNewAddress(token, results));
                callback(results);
            })
            .catch(err => {
                console.log('err:', err);
                const loc = { address_latitude: position.coords.latitude, address_longitude: position.coords.longitude };
                props.dispatch(setUserLocation(loc));
                callback(loc);
            });
    } else {
        // console.log('5 - without token')
        const loc = { address_latitude: position.coords.latitude, address_longitude: position.coords.longitude };

        props.dispatch(setUserLocation(loc));
        callback(loc);
    }
}

export function showDefaultPosition(error, props, callback, showError) {
    // console.log('[showDefaultPosition]', error)
    let loc = null;
    if (!!props.userData && !!props.userData.address && props.userData.address !== null && props.userData.address.address_latitude !== null && !!props.userData.address.address_longitude !== null) {
        // console.log('6 - default')
        if (showError) {
            toast.info('Displaying your default Location');
        }
        loc = props.userData.address;
    } else {
        // console.log('7 - else default')
        if (showError && Config.isMobileApp) {
            ApiError('GPS/Location is off, make sure to allow Location Services for Pooch App.');
        } else if (showError && !Config.isMobileApp) {
            ApiError("Your browser does not support Location or it's turned off.");
        }
        props.dispatch(setUserLocation(null));
    }
    callback(loc);
}

export function reverseGeocode(position) {
    // console.log('8 - reverseGeocode', position)
    return new Promise((resolve, reject) => {
        axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + position.coords.latitude + ',' + position.coords.longitude + '&key=' + Config.GoogleGeoCodeApi)
            .then(response => {
                const data = response.data;
                // console.log('new geocoder2',data)
                if (!!data && !!data.results && !!data.results[0] && !!data.results[0].address_components) {
                    // console.log('9 - reverseGeocode')
                    const locationData = { address_latitude: position.coords.latitude, address_longitude: position.coords.longitude };
                    for (const index in data.results[0].address_components) {
                        if ((data.results[0].address_components[index].types.includes('street_number') || data.results[0].address_components[index].types.includes('route')) && !!data.results[0].address_components[index].long_name) {
                            locationData['address_street'] = data.results[0].address_components[index].long_name + ' ';
                        }
                        if (data.results[0].address_components[index].types.includes('locality')) {
                            locationData['address_city'] = data.results[0].address_components[index].long_name;
                        }
                        if (data.results[0].address_components[index].types.includes('administrative_area_level_1')) {
                            locationData['address_state'] = data.results[0].address_components[index].long_name;
                        }
                        if (data.results[0].address_components[index].types.includes('country')) {
                            locationData['address_country'] = data.results[0].address_components[index].long_name;
                        }
                        if (data.results[0].address_components[index].types.includes('postal_code')) {
                            locationData['address_zipcode'] = data.results[0].address_components[index].long_name;
                        }
                    }
                    resolve(locationData);
                } else {
                    // console.log('10 - reverseGeocode')
                    reject();
                }
                // console.log('11 - reverseGeocode')
            })
            .catch(err => reject(err));
        // console.log('12 - promise')
    });
}

/*
export function geocode(address) {
    return new Promise(function(resolve, reject) {
        var geocoder = require('geocoder');
        var newaddress = []

        if(!!address.address_street){
            newaddress.push(address.address_street)
        }
        if(!!address.address_street2){
            newaddress.push(address.address_street2)
        }
        if(!!address.address_city){
            newaddress.push(address.address_city)
        }
        if(!!address.address_state){
            newaddress.push(address.address_state)
        }
        if(!!address.address_country){
            newaddress.push(address.address_country)
        }
        if(!!address.address_zipcode){
            newaddress.push(address.address_zipcode)
        }
        geocoder.geocode(newaddress.join(' '), function ( err, data ) {
            if (!!data && !!data.results && !!data.results[0] && data.results[0].geometry) {
                resolve(data.results[0].geometry)
            }
            else {
                reject(err)
            }
        });
    })
} */
