import React from 'react';
import { connect } from 'react-redux';

import { getStats } from '../../redux/actions/all';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';

class AdminStats extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stats: null
        };
    }

    componentDidMount() {
        this.props.dispatch(getStats(this.props.credentials.token))
            .then(res => {
                this.setState({ stats: res.value });
            });
    }

    render() {
        let message = null;
        if (this.state.stats && this.state.stats.data) {
            message = JSON.stringify(this.state.stats.data, null, 2);
            message = message.split('\n').map((item, index) => <div key={index}>{item}</div>);
        }

        return (
            <div className="mainAppContainer">
                <MainNavigation title="Stats" />
                <div className="bodyContent maxWidth wrapper">
                    <pre>
                        {message}
                    </pre>
                </div>
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(AdminStats);
