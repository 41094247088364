import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import GalleryLightbox from '../../layout/gallery/GalleryLightbox';
import Avatar from '../../layout/UI/Avatar';
import DogProfileHeader from '../../layout/UI/DogProfileHeader';
import DogProfileButtons from '../../layout/UI/DogProfileButtons';
import { guard, listDogBreeds } from '../../helpers/Functions';
import Columns from '../../hoc/Columns';
import WhiteTitle from '../../hoc/WhiteTitle';
import FindFriendsComponent from '../Friends/FindFriendsComponent';
import MissingDogInformation from './MissingDogInformation';
import HelmetTags from '../../helmetTags';

export default class OtherDogProfile extends React.Component {
    listOwners(owners) {
        return owners.map(owner => {
            const image = (
                <div className="round-avatar owner-list">
                    <Avatar
                        src={guard(owner, '.images.0.image_mediumurl')}
                        alt={this.props.dogProfile.name}
                        type="user"
                    />
                </div>
            );
            let lastSeen = null;
            if (owners && owners.lastaddress && owners.lastaddress.address_city && owners.lastaddress.address_country) {
                lastSeen = (<div className="lastSeen">Last Seen: {owner.lastaddress.address_city}, {owner.lastaddress.address_country}</div>);
            }
            let friendShipStatus = '';

            if (owner.myfriendship && owner.myfriendship.friendship_status === 'accepted') {
                friendShipStatus = (<div className="flexSmallestButtons borderGreen greenBG fontWhite"><p>Friends</p></div>);
            } else if (owner.myfriendship && owner.myfriendship.friendship_status === 'pending') {
                friendShipStatus = (<div className="flexSmallestButtons borderBlue blueBG fontWhite"><p>Pending</p></div>);
            } else {
                friendShipStatus = null;
            }

            return (
                <Link className="user row " to={'/user/profile/' + owner.user_id} key={owner.user_id}>
                    {image}
                    <div className="name">
                        <div className="fontGreen">{owner.user_firstname + ' ' + owner.user_lastname}</div>
                        <div>{lastSeen}</div>
                    </div>
                    {friendShipStatus}
                </Link>
            );
        });
    }

    render() {
        let listBreeds = '';
        if (this.props.dogProfile.breeds.length > 0) {
            listBreeds = ' (' + listDogBreeds(this.props.dogProfile.breeds) + ')';
        }
        const pageTitle = this.props.dogProfile && this.props.dogProfile.dog_name;
        let isMyDog = false;
        if (this.props.dogProfile && this.props.dogProfile.users && this.props.dogProfile.users.length) {
            for (const i in this.props.dogProfile.users) {
                if (this.props.credentials.user_id === this.props.dogProfile.users[i].user_id) {
                    isMyDog = true;
                }
            }
        }

        const rightButtons = (
            <DogProfileButtons
                isMyDog={isMyDog}
                dispatch={this.props.dispatch}
                userData={this.props.userData}
                credentials={this.props.credentials}
                dogProfile={this.props.dogProfile}
            />
        );

        const c1 = (
            <React.Fragment>
                {this.props.dogProfile.users && this.props.dogProfile.users.length && (
                    <React.Fragment>
                        <WhiteTitle>Owner(s):</WhiteTitle>
                        <div className="participantList">
                            <div className="participantListItem">
                                {this.listOwners(this.props.dogProfile.users)}
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );

        const c2 = (
            <React.Fragment>
                <HelmetTags
                    title={pageTitle + '\'s' + listBreeds || 'Dog Profile'}
                    description={this.props.dogProfile && this.props.dogProfile.dog_description ? this.props.dogProfile.dog_description : ''}
                />
                <MissingDogInformation
                    history={this.props.history}
                    userData={this.props.userData}
                    credentials={this.props.credentials}
                    isMyDog={isMyDog}
                    dogProfile={this.props.dogProfile}
                />
                <WhiteTitle>About {this.props.dogProfile.dog_name}</WhiteTitle>
                {this.props.dogProfile.dog_description && (
                    <div className="maxWidth fontDarkGray profile-description">
                        <div className="profile-bio">
                            {this.props.dogProfile && this.props.dogProfile.dog_description && this.props.dogProfile.dog_description.split('\n').map((item, key) => <p className="paragraph-bio" key={key}>{item}<br /></p>)}
                            {this.props.dogProfile.dog_description === null
                                && <p className="paragraph-bio">No Description yet...</p>}
                        </div>
                    </div>
                )}
                {this.props.dogProfile.images && this.props.dogProfile.images.length > 0 && (
                    <React.Fragment>
                        <div className="gallerySection">
                            <WhiteTitle>Pictures
                                <div className="viewAll">
                                    <Link to={'/dog/' + this.props.dogProfile.dog_id + '/gallery'} className="fontGreen">View All</Link>
                                </div>
                            </WhiteTitle>
                        </div>
                        <div className="galleryHolder">
                            <GalleryLightbox
                                userData={this.props.userData}
                                images={this.props.dogProfile.images}
                                count={4}
                                size="normal"
                            />
                            {this.props.dogProfile.images.length > 2 && (
                                <div className="holderEnclosed">
                                    <Link to={'/dog/' + this.props.dogProfile.dog_id + '/gallery'}>+ {this.props.dogProfile.images.length - 2} More</Link>
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );

        const c3 = (
            <React.Fragment>
                <WhiteTitle>Suggested Friends</WhiteTitle>
                <FindFriendsComponent perPage={5} infiniteScroll={false} />
                <Link to="/findfriends" className="square-button greenBG fontWhite h6">View all</Link>
            </React.Fragment>
        );

        return (
            <div>
                <DogProfileHeader dogProfile={this.props.dogProfile} right={rightButtons} />
                <div className="three-column-layout">
                    <Columns
                        c1={c1}
                        c2={c2}
                        c3={c3}
                    />
                </div>
            </div>
        );
    }
}

OtherDogProfile.propTypes = {
    dogProfile: PropTypes.instanceOf(Object),
    userData: PropTypes.instanceOf(Object),
    credentials: PropTypes.instanceOf(Object).isRequired,
    dispatch: PropTypes.func.isRequired
};
