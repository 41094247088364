import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactGA from 'react-ga';

import { getLostDogs, setSearchLostDogs } from '../../redux/actions/dogs';
import NotFound from '../../layout/NotFound';
import { MissingDogsNavigation } from '../../layout/navigation/MissingDogsNavigation';
import NavigationSearchBar from '../../layout/UI/NavigationSearchBar';
import Spinner from '../../layout/UI/Spinner';
import Avatar from '../../layout/UI/Avatar';
import ApiError from '../../helpers/ApiError';
import { guard } from '../../helpers/Functions';
import { Pull2Refresh, infiniteScroll } from '../../helpers/Pull2Refresh';
import { getGeoLocation } from '../../helpers/GeoLocation';
import Columns from '../../hoc/Columns';
import HelmetTags from '../../helmetTags';

class MissingDogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            missingDogs: [],
            loading: true,
            reloading: false,
            currentPage: 1,
            // loadingMore: false,
            totalPages: 1, // This it's being used by infiniteScroll->handleScroll->loadMore
            missingDogList: []
        };
    }

    getGeoLocation = getGeoLocation.bind(this)

    componentDidMount() {
        window.scrollTo(0, 0);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        this.start();
        Pull2Refresh(this.start);
    }

    init = () => {
        this.setState({
            missingDogs: [],
            loading: true,
            reloading: false,
            currentPage: 1,
            totalPages: 1,
            missingDogList: []
        }, this.start());
    }

    start = () => {
        this.setState({ reloading: true });
        this.getGeoLocation(this.props, this.showLostDogs, this.props.credentials.token, true);
    }

    showLostDogs = () => {
        if (!this.props.userData.user_location && this.state.currentPage === 1) {
            ApiError('Please turn on your Location to see missing dogs near you.');
        }
        this.props.dispatch(getLostDogs(this.props.credentials.token, (this.props.userData.user_location || null), this.state.currentPage, this.props.searchValue))
            .then(response => {
                this.setState(prevState => ({
                    loading: false,
                    reloading: false,
                    totalPages: response.value.headers['x-pagination-page-count'],
                    // loadingMore: false,
                    missingDogList: prevState.missingDogList.concat(response.value.data)
                }), () => {
                    if (this.state.currentPage < this.state.totalPages) {
                        infiniteScroll(this, this.showLostDogs);
                    }
                    this.missingMap();
                });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loading: false, reloading: false });
            });
    }

    listBreeds(breeds) {
        const listedBreeds = breeds.map(breed => breed.breed_name);
        return listedBreeds.join(', ') + (listedBreeds.length > 1 ? ' mix' : '');
    }

    missingMap() {
        const count = this.state.missingDogs.length;
        const missingDogs = this.state.missingDogList.map((dog, index) => (
            <div key={dog.dog.dog_id} className="missing-dogs-desktop">
                <div className="participantListItem" key={count + index}>
                    <div className="dog row">
                        <div className="round-avatar dog">
                            <Avatar
                                src={guard(dog, '.dog.images.0.image_smallurl')}
                                alt={dog.dog.dog_name}
                                type="dog"
                            />
                        </div>
                        <div className="info">
                            <div className="name missingDogTitle fontRed">{dog.dog.dog_name}</div>
                            {!!dog.dogaddress_reward && (
                                <div>
                                    <strong>Reward:</strong> ${dog.dogaddress_reward}
                                </div>
                            )}
                            <div className="missing-dog">
                                <strong>Breed(s)</strong>{!!dog && !!dog.dog && !!dog.dog.breeds && !!dog.dog.breeds.length && this.listBreeds(dog.dog.breeds)}
                            </div>
                            {!!dog.dogaddress_datetime && (
                                <div>
                                    <strong>Lost: </strong>{moment(dog.dogaddress_datetime).format('MMMM Do YYYY, h:mm a')}
                                </div>
                            )}
                            {!!dog && !!dog.address && (
                                <div>
                                    <strong>Last Seen: </strong>
                                    {guard(dog, '.address.address_street') ? dog.address.address_street + ' ' : null}
                                    {guard(dog, '.address.address_street2') ? dog.address.address_street2 + ' ' : null}
                                    {guard(dog, '.address.address_city') ? dog.address.address_city + ' ' : null}
                                    {guard(dog, '.address.address_state') ? dog.address.address_state + ' ' : null}
                                    {guard(dog, '.address.address_country') ? dog.address.address_country + ' ' : null}
                                    {guard(dog, '.address.address_zipcode') ? dog.address.address_zipcode + ' ' : null}
                                </div>
                            )}
                        </div>
                        <div className="distance">
                            {(!!dog && !!dog.Distance) ? parseFloat(dog.Distance).toFixed(2) + ' km' : null}
                        </div>
                    </div>
                    <div className="more-info-section">
                        <Link to={'/dog/' + dog.dog_id} className="more-info inlineButtons greenBorder whiteBG fontGreen h5">More Info</Link>
                    </div>
                </div>
            </div>
        ));
        this.setState(prevState => ({ missingDogs: prevState.missingDogs.concat(missingDogs) }));
    }

    render() {
        const pageTitle = 'Missing Dogs';
        const pageDescription = 'Help locate missing dogs in your area.';

        const c1 = (
            <React.Fragment>
                <div className="simple-navigation">
                    <MissingDogsNavigation
                        selected="missing-dogs"
                    />
                </div>
            </React.Fragment>
        );

        const c2 = (
            <React.Fragment>
                {!this.state.loading && !this.state.missingDogs.length && (
                    <NotFound class="desktop-notFound" type="lostdogs" />
                )}
                {(!!this.state.loading || !!this.state.reloading) && (<Spinner />)}
                <div className="participantList lostDogsList">
                    {this.state.missingDogs}
                </div>
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <HelmetTags title={pageTitle || ''} description={pageDescription} />
                <div className="mainAppContainer">
                    <NavigationSearchBar
                        callback={this.init}
                        disp={this.props.dispatch}
                        searchInputHandler={setSearchLostDogs}
                        searchValue={this.props.searchValue}
                    />
                    <div className="bodyContent maxWidth noPadding">
                        <div className="three-column-layout two-column-list">
                            <Columns
                                c1={c1}
                                c2={c2}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        searchValue: state.dogs.searchLostDogs
    };
}

export default connect(mapStateToProps)(MissingDogs);
