import React from 'react';
import { connect } from 'react-redux';
import Textarea from 'react-textarea-autosize';

import { sendNotification } from '../../redux/actions/all';
import ChatBubbles from '../../layout/ChatBubbles';
import sb from '../../helpers/SendBird';
import { guard } from '../../helpers/Functions';
import WhiteTitle from '../../hoc/WhiteTitle';

export class ChatRoom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roomName: '',
            writting: '',
            channel: null,
            messages: null,
            typing: false,
            typingUsers: [],
            // channelType: null,
            // channelList: [],
            chaturl: this.props.chaturl
        };
    }

    componentDidMount() {
        const that = this;
        sb.connect(
            this.props.credentials.user_id,
            this.props.credentials.token,
            () => {
                sb.updateCurrentUserInfo(
                    that.props.userData.user_firstname + ' ' + that.props.userData.user_lastname,
                    guard(that.props.userData, '.images.0.image_smallurl') || '',
                    () => { }
                );
                const sbChannel = sb.GroupChannel;
                sbChannel.getChannel(that.state.chaturl, (channel, error) => {
                    if (error) return;
                    if (channel && channel.markAsRead) channel.markAsRead();
                    that.setState({
                        channel
                    }, () => {
                        that.roomName();
                        that.loadOld();
                        that.handleReceiver();
                        that.handleTyping();
                    });
                });
            }
        );
        const messageArea = document.getElementById('messageArea');
        messageArea.addEventListener('keydown', e => {
            if ((e.keyCode === 13 && e.metaKey) || (e.keyCode === 13 && e.ctrlKey)) {
                that.sendMessage(e);
            }
        });
    }

    handleTyping = () => {
        const that = this;
        const ChannelHandler = new sb.ChannelHandler();
        ChannelHandler.onTypingStatusUpdated = channel => {
            that.setState({
                typingUsers: channel.getTypingMembers(),
                typing: channel.isTyping()
            });
        };
        sb.addChannelHandler('HANDLE_TYPING_' + this.state.chaturl, ChannelHandler);
    }

    handleReceiver = () => {
        const that = this;
        const ChannelHandler = new sb.ChannelHandler();
        ChannelHandler.onMessageReceived = (channel, message) => {
            const messages = that.state.messages;
            messages.push(message);
            that.setState({ messages });
            that.scrollDown();
            channel.markAsRead();
        };
        sb.addChannelHandler('MESSAGES_RECEIVED_' + this.state.chaturl, ChannelHandler);
    }

    roomName = () => {
        const allMembers = this.state.channel.members.filter(member => !(member.userId === String(this.props.credentials.user_id)));
        const membersNames = [];
        for (const i in allMembers) {
            if (allMembers.length > 1) {
                membersNames.push(allMembers[i].nickname.split(' ')[0]);
            } else {
                membersNames.push(allMembers[i].nickname);
            }
        }
        this.setState({
            roomName: membersNames.join(', ')
        });
    }

    writting = e => {
        e.preventDefault();
        this.setState({
            writting: e.target.value
        });
        if (!!e.target.value.length && !!this.state.channel) {
            this.state.channel.startTyping();
        }
        if (!e.target.value.length && !!this.state.channel) {
            this.state.channel.endTyping();
        }
    }

    sendMessage = e => {
        e.preventDefault();
        const that = this;

        if (this.state.writting.length > 0) {
            this.state.channel.sendUserMessage(this.state.writting, null, null, (message, error) => {
                if (error) return;
                const messages = that.state.messages;
                messages.push(message);
                that.setState({ messages, writting: '' });
                that.sendPush(message);
                that.scrollDown();
                that.state.channel.endTyping();
            });
        }
    }

    scrollDown = () => {
        const objDiv = document.getElementsByClassName('chat-holder');
        objDiv[0].scrollTop = objDiv[0].scrollHeight;
    }

    sendPush = message => {
        if (message && message.message && this.props.userData.user_firstname && this.state.chaturl && this.props.credentials.user_id) {
            const notification_body = this.props.userData.user_firstname + ' says: ' + message.message;
            const sender_id = this.props.credentials.user_id;
            this.sendNotification(this.state.channel.members, notification_body, this.state.chaturl, sender_id, false);
        }
    }

    sendNotification = (participantList, notification_body, chat_id, sender_id, isOpenChannel) => {
        let receivers = participantList.filter(user => !(user.userId === String(this.props.credentials.user_id)));
        receivers = receivers.map(user => ({ user_id: user.userId }));
        const data = {
            notification_title: 'Chat',
            notification_body,
            chat_id,
            sender_id,
            receivers,
            isOpenChannel
        };
        this.props.dispatch(sendNotification(this.props.credentials.token, data));
    }

    loadOld() {
        const that = this;
        const messageListQuery = this.state.channel.createPreviousMessageListQuery();
        messageListQuery.load(100, true, (messageList, error) => {
            if (error) return;
            that.setState({
                messages: messageList.reverse()
            });
            that.scrollDown();
        });
    }

    displayMessages(messages) {
        if (!!messages && !!messages.length) {
            return messages.map((m, index) => {
                const userSentClass = (m._sender.userId === String(this.props.credentials.user_id)) ? 'userSelf' : 'userOther';
                return (
                    <ChatBubbles key={index} userSentClass={userSentClass} message={m} />
                );
            });
        }
    }

    mapTypingUsers(users) {
        const usersNames = users.map(user => {
            const fname = user.nickname.split(' ');
            return fname[0];
        });

        return usersNames.join(', ');
    }

    render() {
        return (
            <div className="chat-holder">
                <div className="main-chat-title">
                    <WhiteTitle>{this.state.roomName}</WhiteTitle>
                    {/* <Link to={'/chat'} ><i className="icn icon-sign-out fontGray" /></Link> */}
                </div>
                {this.displayMessages(this.state.messages)}
                {!!this.state.typing && (
                    <div className="typingStatus">
                        Typing: {this.mapTypingUsers(this.state.typingUsers)}
                    </div>
                )}
                <div className="row senderText">
                    <div className="messageInput">
                        <Textarea placeholder="Type something..." id="messageArea" onChange={e => this.writting(e)} value={this.state.writting} />
                    </div>
                    <div className="sendButton" onClick={e => { this.sendMessage(e); }}>
                        <i className="icn icon-send-message-plane" />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(ChatRoom);
