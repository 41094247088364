import React from 'react';

const Columns = ({ wrapperClass, c1, c2, c3 }) => (
    <div className={wrapperClass || 'wrapper maxWidth'}>
        <div className="columns-holder">
            {c1 && (
                <div className="column-1">
                    {c1}
                </div>
            )}
            {c2 && (
                <div className="column-2">
                    {c2}
                </div>
            )}
            {c3 && (
                <div className="column-3">
                    {c3}
                </div>
            )}
        </div>
    </div>
);

export default Columns;
