import React from 'react';
import PropTypes from 'prop-types';

const SocialMediaIcons = ({ socialmedia }) => {
    let facebook = null;
    let instagram = null;
    let twitter = null;

    if (socialmedia['facebook'] && (socialmedia['facebook'].indexOf('http') > -1)) {
        // http://facebook.com/handle
        facebook = (
            <a className="h1 fontWhite" href={socialmedia['facebook']} target="_blank" rel="noopener noreferrer">
                <i className="icn icon-facebook" />
            </a>
        );
    } else if (socialmedia['facebook'] && (socialmedia['facebook'].indexOf('facebook.') > -1)) {
        // facebook.com/handle
        facebook = (
            <a className="h1 fontWhite" href={'http://' + socialmedia['facebook']} target="_blank" rel="noopener noreferrer">
                <i className="icn icon-facebook" />
            </a>
        );
        // handle
    } else if (socialmedia['facebook'] && (socialmedia['facebook'].indexOf('http') === -1) && (socialmedia['facebook'].indexOf('facebook.') === -1)) {
        facebook = (
            <a className="h1 fontWhite" href={'http://facebook.com/' + socialmedia['facebook']} target="_blank" rel="noopener noreferrer">
                <i className="icn icon-facebook" />
            </a>
        );
    }
    if (socialmedia['twitter'] && (socialmedia['twitter'].indexOf('http') > -1)) {
        // http://twitter.com/handle
        twitter = (
            <a className="h1 fontWhite" href={socialmedia['twitter']} target="_blank" rel="noopener noreferrer">
                <i className="icn icon-twitter" />
            </a>
        );
    } else if (socialmedia['twitter'] && (socialmedia['twitter'].indexOf('twitter.') > -1)) {
        // twitter.com/handle
        twitter = (
            <a className="h1 fontWhite" href={'http://' + socialmedia['twitter']} target="_blank" rel="noopener noreferrer">
                <i className="icn icon-twitter" />
            </a>
        );
    } else if (socialmedia['twitter'] && (socialmedia['twitter'].indexOf('http') === -1) && (socialmedia['twitter'].indexOf('twitter.') === -1)) {
        // handle
        twitter = (
            <a className="h1 fontWhite" href={'http://twitter.com/' + socialmedia['twitter']} target="_blank" rel="noopener noreferrer">
                <i className="icn icon-twitter" />
            </a>
        );
    }
    if (socialmedia['instagram'] && (socialmedia['instagram'].indexOf('http') > -1)) {
        // http://instagram.com/handle
        instagram = (
            <a className="h1 fontWhite" href={socialmedia['instagram']} target="_blank" rel="noopener noreferrer">
                <i className="icn icon-instagram" />
            </a>
        );
    } else if (socialmedia['instagram'] && (socialmedia['instagram'].indexOf('instagram.') > -1)) {
        // instagram.com/handle
        instagram = (
            <a className="h1 fontWhite" href={'http://' + socialmedia['instagram']} target="_blank" rel="noopener noreferrer">
                <i className="icn icon-instagram" />
            </a>
        );
    } else if (socialmedia['instagram'] && (socialmedia['instagram'].indexOf('http') === -1) && (socialmedia['instagram'].indexOf('instagram.') === -1)) {
        // handle
        instagram = (
            <a className="h1 fontWhite" href={'http://instagram.com/' + socialmedia['instagram']} target="_blank" rel="noopener noreferrer">
                <i className="icn icon-instagram" />
            </a>
        );
    }

    return (
        <React.Fragment>
            {facebook}
            {twitter}
            {instagram}
        </React.Fragment>
    );
};

SocialMediaIcons.propTypes = {
    socialmedia: PropTypes.node
};

export default SocialMediaIcons;
