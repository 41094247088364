import apiConnection from '../../helpers/Api';

export function uploadPicture(token, id, picture, featured = false) {
    const data = new FormData();

    data.append('image_file', picture, picture.name || 'upload');
    data.append('image_featured', featured);

    return {
        type: 'UPLOAD_PICTURE',
        payload: apiConnection.post('images/entity/' + id, data, { headers: { Authorization: token, 'content-type': 'multipart/form-data' } })
    };
}

export function deletePicture(token, id) {
    return {
        type: 'DELETE_PICTURE',
        payload: apiConnection.delete('images/' + id, { headers: { Authorization: token } })
    };
}

export function setPicture(token, id) {
    return {
        type: 'SET_PICTURE',
        payload: apiConnection.put('images/featured/' + id, null, { headers: { Authorization: token } })
    };
}

export function getReviews(token, id, page = 1) {
    const query = '?expand=entity, location, playdate, user.images, user.lastaddress, images, playdateuser&per-page=30&page=' + page;
    return {
        type: 'GET_REVIEW',
        payload: apiConnection.get('reviews/' + id + query, { headers: { Authorization: token, 'content-type': 'multipart/form-data' } })
    };
}

export function sendNotification(token, data) {
    return {
        type: 'SEND_CHAT_NOTIFICATION',
        payload: apiConnection.post('devices/chat', data, { headers: { Authorization: token } })
    };
}

export function contactUs(token, data) {
    return {
        type: 'CONTACT_US_POST',
        payload: apiConnection.post('contactus', data, { headers: { Authorization: token } })
    };
}

export function contactUsList(token, page = 1) {
    const query = '?per-page=30&sort=-contactus_id&expand=user.images,location&page=' + page;
    return {
        type: 'CONTACT_US_LIST',
        payload: apiConnection.get('contactus' + query, { headers: { Authorization: token } })
    };
}

export function contactUsProfile(token, id) {
    return {
        type: 'CONTACT_US_PROFILE',
        payload: apiConnection.get('contactus/' + id + '?expand=user.images,location.address', { headers: { Authorization: token } })
    };
}

export function updateContactUsProfile(token, id, data) {
    return {
        type: 'CONTACT_US_UPDATE',
        payload: apiConnection.put('contactus/' + id, data, { headers: { Authorization: token } })
    };
}

export function postEntityActions(token, data) {
    return {
        type: 'POST_ENTITY_ACTIONS',
        payload: apiConnection.post('entityactions', data, { headers: { Authorization: token } })
    };
}

export function putEntityActions(token, id, data) {
    return {
        type: 'PUT_ENTITY_ACTIONS',
        payload: apiConnection.put('entityactions/' + id, data, { headers: { Authorization: token } })
    };
}

export function deleteEntityActions(token, id) {
    return {
        type: 'DELETE_ENTITY_ACTIONS',
        payload: apiConnection.delete('entityactions/' + id, { headers: { Authorization: token } })
    };
}

export function addPost(token, data) {
    return {
        type: 'ADD_POST',
        payload: apiConnection.post('posts?expand=user.images,activity', data, { headers: { Authorization: token } })
    };
}

export function getPosts(token, id, page = 1, perPage = 30) {
    return {
        type: 'GET_POSTS',
        payload: apiConnection.get('posts/entity/' + id + '?sort=-post_id&expand=user.images, activity, replies.user.images&per-page=' + perPage + '&page=' + page, { headers: { Authorization: token } })
    };
}

export function getStats(token) {
    return {
        type: 'GET_STATS',
        payload: apiConnection.get('stats', { headers: { Authorization: token } })
    };
}

export function getSlug(token, id) {
    let query = '?expand=';
    // USER
    query += 'user.myfriendship,';
    query += 'user.parententity,';
    query += 'user.lastaddress,';
    query += 'user.images,';
    query += 'user.address,';
    query += 'user.dogs.images,';
    query += 'user.dogs.breeds,';
    query += 'user.dogs.dogaddress,';
    query += 'user.primarylocation,';

    // DOG
    query += 'dog.images,';
    query += 'dog.parententity,';
    query += 'dog.breeds,';
    query += 'dog.users,';
    query += 'dog.dogaddress.address,';
    query += 'dog.users.images,';
    query += 'dog.users.lastaddress,';
    query += 'dog.users.myfriendship,';

    // Location
    query += 'location.address,';
    query += 'location.parententity,';
    query += 'location.images,';
    query += 'location.locationtypes,';
    query += 'location.adminlocations.user';

    return {
        type: 'GET_SLUG',
        payload: apiConnection.get('/entities/slug/' + id + query, { headers: { Authorization: token } })
    };
}
