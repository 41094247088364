import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import ActivityNavigation from '../../layout/navigation/ActivityNavigation';
import ChatList from '../../layout/ChatList';
import Spinner from '../../layout/UI/Spinner';
import { InviteFriends } from '../../layout/InviteFriends';
import NotFound from '../../layout/NotFound';
import sb from '../../helpers/SendBird';
import { Pull2Refresh } from '../../helpers/Pull2Refresh';

export class Chat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            channelList: [],
            loading: true,
            reloading: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.start();
        Pull2Refresh(this.start);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    getGroupChat() {
        const that = this;
        const channelListQuery = sb.GroupChannel.createMyGroupChannelListQuery();
        channelListQuery.includeEmpty = false;
        channelListQuery.limit = 100; // pagination limit could be set up to 100
        if (channelListQuery.hasNext) {
            channelListQuery.next((channelList, error) => {
                if (error) {
                    that.setState({ loading: false, reloading: false });
                    return;
                }
                that.setState({ channelList, loading: false, reloading: false });
            });
        }
    }

    start = () => {
        const that = this;
        this.setState({ reloading: true });
        sb.connect(
            this.props.credentials.user_id,
            this.props.credentials.token,
            () => {
                that.getGroupChat();
            }
        );
    }

    render() {
        return (
            <div className="mainAppContainer chatList">
                <MainNavigation title="Chat" rightButton="writeMessage" rightLink="/chatcreate" />
                <ActivityNavigation.ActivityNavigationMobile
                    selected="chat"
                />
                <div className="bodyContent pull2refresh">
                    {!this.state.loading && !!this.state.channelList.length && (
                        <InviteFriends />
                    )}
                    {(!!this.state.loading || !!this.state.reloading) && (
                        <Spinner />
                    )}
                    {!this.state.loading && !this.state.channelList.length && (
                        <NotFound type="chat" />
                    )}
                    {!this.state.loading && !!this.state.channelList.length && (
                        <ChatList {...this.props} chatList={this.state.channelList} />
                    )}
                </div>
                <SecondaryNavigation selected="" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(Chat);
