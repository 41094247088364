import React from 'react';
import { HashRouter, BrowserRouter, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './scss/index.scss';

import Config from './components/helpers/Config';
import Mobile from './AppMobile';
import Desktop from './AppDesktop';

const Router = Config.isMobileApp ? HashRouter : BrowserRouter;

class App extends React.Component {
    extraClasses = newClass => {
        document.documentElement.className += newClass;
    };

    render() {
        if (window.location.origin.includes('https:')) {
            ReactGA.initialize(process.env.REACT_APP_API_GOOGLE_ANALYTICS);
        }
        return (
            <Router>
                {Config.isMobileDevice
                    ? (
                        <div className="mobile-app">
                            <Route path="/" component={Mobile} />
                        </div>
                    )
                    : (
                        <div className="desktop-app">
                            {this.extraClasses(' desktop-html')}
                            <Route path="/" component={Desktop} />
                        </div>
                    )}
                <ToastContainer
                    hideProgressBar={true}
                    position="bottom-center"
                    theme="colored"
                    className="custom-toaster"
                    icon={false}
                />
            </Router>
        );
    }
}

export default App;
