import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import { addLocationAdmin } from '../../redux/actions/users';
import { guard } from '../../helpers/Functions';
import ApiError from '../../helpers/ApiError';

class AdminAdministratorsListAdd extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    saveLocationAdmin(e) {
        e.preventDefault();
        if (!!guard(this.props.administratorUser, 'name') && !!guard(this.props.administratorLocation, 'name')) {
            this.props.dispatch(addLocationAdmin(this.props.credentials.token, { user_id: this.props.administratorUser.id, location_id: this.props.administratorLocation.id }))
                .then(() => {
                    toast.success('Added user to location');
                    this.props.history.goBack();
                })
                .catch(() => ApiError('There was an error, please verify the user and location and try again.'));
        } else {
            ApiError('Please select a user and a location');
        }
    }

    render() {
        return (
            <div className="mainAppContainer playdates">
                <MainNavigation title="User Administration" />
                <div className="bodyContent wrapper">
                    <div className="maxWidth">
                        <div className="spacerSmall" />
                        <div>Select a User that will be the administrator of the Location</div>
                        <div className="spacerSmall" />
                        <Link to="/manageadministrators/add/user" className="wideButtons blueBorder whiteBG fontBlue">
                            {(guard(this.props.administratorUser, '.name'))
                                ? (<span>{this.props.administratorUser.name}</span>)
                                : (<span>Select a User</span>)}
                        </Link>
                        <Link to="/manageadministrators/add/location" className="wideButtons greenBorder whiteBG fontGreen">
                            {guard(this.props.administratorLocation, '.name')
                                ? (<span>{this.props.administratorLocation.name}</span>)
                                : (<span>Select a Location</span>)}
                        </Link>
                        <Link to="#" className="wideButtons greenBorder greenBG fontWhite" onClick={e => this.saveLocationAdmin(e)}>
                            Save
                        </Link>
                    </div>
                </div>
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        administratorUser: state.users.administratorUser,
        credentials: state.users.credentials,
        administratorLocation: state.users.administratorLocation
    };
}

export default connect(mapStateToProps)(AdminAdministratorsListAdd);
