import PullToRefresh from 'pulltorefreshjs';

import Config from '../helpers/Config';

export function Pull2Refresh(callback) {
    if (Config.isMobileApp) {
        PullToRefresh.destroyAll();
        const elements = document.getElementsByClassName('ptr--ptr');
        while (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
        }

        PullToRefresh.init({
            mainElement: '.pull2refresh',
            onRefresh: callback
        });
    }
}

/**
 * Used to listen the `scroll` event to trigger the function passed on `nextFunc`,
 * also this function will call `handleScroll` which will call `loadMore` and just then call the function passed here as `nextFunc`
 *
 * @param {function} that the context of `this` of the React class
 * @param {function} nextFunc the function to be triggered
 */
export function infiniteScroll(that, nextFunc) {
    window.addEventListener('scroll', () => handleScroll(that, nextFunc));
}

/**
 * This check that the current page/window has reached the end of it to trigger the function passed on `nextFunc`
 * also this function will call `loadMore` and just then call the function passed here as `nextFunc`
 *
 * @param {function} that the context of `this` of the React class
 * @param {function} nextFunc the function to be triggered
 */
export function handleScroll(that, nextFunc) {
    if (that !== null) {
        const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight - 200) {
            loadMore(that, nextFunc);
        }
    }
}

/**
 * This will check first that all the contains a state `loadingMore` ... as `false` (which will be changed to `true` later)
 * and then increase the `currentPage` in +1 just before trigger the function passed here as `nextFunc`
 *
 * @param {function} that the context of `this` of the React class
 * @param {function} nextFunc the function to be triggered
 */
export function loadMore(that, nextFunc) {
    if (!that.state.loadingMore) {
        that.setState(prevState => ({ currentPage: prevState.currentPage + 1 }),
            () => {
                if (that.state.currentPage <= that.state.totalPages) {
                    that.setState({ loadingMore: true }, nextFunc());
                }
            });
    }
}
