import React from 'react';
import { connect } from 'react-redux';

import { refreshOwnProfile } from '../../redux/actions/users';
import FoundDogsList from '../../layout/FoundDogsList';
import { FoundDogsNavigation } from '../../layout/navigation/FoundDogsNavigation';
import Columns from '../../hoc/Columns';
import WhiteTitle from '../../hoc/WhiteTitle';

export class ReportFoundDog extends React.Component {
    componentDidMount() {
        this.props.dispatch(refreshOwnProfile(this.props.credentials.token, this.props.credentials.user_id));
        // this.setState({ user: this.props.user, dogs: this.props.dogs });
    }

    render() {
        const c1 = (
            <React.Fragment>
                <div className="simple-navigation">
                    <FoundDogsNavigation
                        showTitle
                        selected="report-found-dog"
                    />
                </div>
            </React.Fragment>
        );

        const c2 = (
            <React.Fragment>
                <WhiteTitle>Report a Found Dog</WhiteTitle>
                <FoundDogsList dogs={this.props.dogs} />
            </React.Fragment>
        );

        const c3 = (
            <React.Fragment />
        );

        return (
            <div className="mainAppContainer">
                <div className="bodyContent">

                    <div className="three-column-layout two-column-list">
                        <Columns
                            c1={c1}
                            c2={c2}
                            c3={c3}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(ReportFoundDog);
