import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { getUserProfile } from '../../redux/actions/users';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import Spinner from '../../layout/UI/Spinner';
import NotFound from '../../layout/NotFound';
import { Pull2Refresh } from '../../helpers/Pull2Refresh';
import ApiError from '../../helpers/ApiError';
import { OtherUserProfile } from './OtherUserProfile';

class UserProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: this.props.loading !== false,
            currentId: (this.props.match && this.props.match.params && this.props.match.params.id) || false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.state.currentId) {
            this.start();
            Pull2Refresh(this.start);
            if (window && window.location && window.location.pathname) {
                ReactGA.pageview(window.location.pathname + window.location.search);
            }
        }
    }

    start = () => {
        this.props.dispatch(getUserProfile(this.props.credentials.token, this.props.match.params.id))
            .then(() => this.setState({ loading: false }))
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loading: false });
            });
    }

    render() {
        const profileInfo = (this.props.userProfile && !!this.props.userProfile.user_id) || (this.props.userSlugData && this.props.userSlugData.user_id);
        return (
            <div className="mainAppContainer">
                { /* String(this.props.credentials.user_id) === String(this.props.match.params.id) && !this.state.loading &&
                    <MainNavigation {...this.props} title={this.props.userData.user_firstname+' '+this.props.userData.user_lastname} leftButton="back" />
                */}
                <div className="bodyContent pull2refresh">
                    {!!this.state.loading && (<Spinner />)}
                    {!this.state.loading && !profileInfo && (
                        <NotFound type="user" class="backgroundRed" />
                    )}
                    {!this.state.loading && !!profileInfo && (
                        <OtherUserProfile
                            dispatch={this.props.dispatch}
                            credentials={this.props.credentials}
                            userData={this.props.userData}
                            user={this.props.userSlugData || this.props.userProfile}
                            dogs={(this.props.userSlugData && this.props.userSlugData.dogs) || this.props.userProfile.dogs}
                            history={this.props.history}
                            ownProfile={
                                (String(this.props.credentials.user_id) === String(this.state.currentId))
                                || (String(this.props.credentials.user_id) === String(this.props.currentId))
                            }
                        />
                    )}
                </div>
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        userProfile: state.users.userProfile
    };
}

export default connect(mapStateToProps)(UserProfile);
