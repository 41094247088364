import apiConnection from '../../helpers/Api';

export function setSearchFilters(payload) {
    return {
        type: 'SET_SEARCH_FILTERS',
        payload
    };
}

export function getSearchFilters(token) {
    return {
        type: 'SEARCH_FILTERS_LIST',
        payload: apiConnection.get('locationtypes?sort=locationtype_name', { headers: { Authorization: token } })
    };
}
