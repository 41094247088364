import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import ActivityNavigation from '../../layout/navigation/ActivityNavigation';
import DownloadAppButton from '../../layout/DownloadAppButton';
import ActivityDispatcher from '../../layout/ActivityDispatcher';

export class Activities extends React.Component {
    componentDidMount() {
        this.start();
    }

    start = () => {
        window.scrollTo(0, 0);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    render() {
        return (
            <div className="mainAppContainer">
                <MainNavigation title="Dog Activities" />
                <ActivityNavigation.ActivityNavigationMobile selected="dogs" />
                <div className="bodyContent pull2refresh maxWidth">
                    <DownloadAppButton />
                    <ActivityDispatcher
                        credentials={this.props.credentials}
                        dispatch={this.props.dispatch}
                        type="dogs"
                        userData={this.props.userData}
                        disableImages={false}
                        allowPosts={false}
                        winScroll={this.props.location.winScroll}
                    />
                </div>
                <SecondaryNavigation selected="activities" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        credentials: state.users.credentials,
        userData: state.users.user
    };
}

export default connect(mapStateToProps)(Activities);
