import React from 'react';
import { connect } from 'react-redux';

import { findFriends, setLocationAdministratorUser } from '../../redux/actions/users';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import UserList from '../../layout/UserList';
import Spinner from '../../layout/UI/Spinner';
import ApiError from '../../helpers/ApiError';
import { Pull2Refresh, infiniteScroll } from '../../helpers/Pull2Refresh';

let delayTimer;

export class AdminAdministratorsListAddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            reloading: false,
            searchText: '',
            loadingMore: false,
            currentPage: 1,
            totalPages: 1, // This it's being used by infiniteScroll->handleScroll->loadMore
            findFriends: []
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.start();
        Pull2Refresh(this.start);
    }

    start = () => {
        this.setState({ reloading: true });
        this.search();
    }

    search = () => {
        this.props.dispatch(findFriends(this.props.credentials.token, this.props.userData.user_location, null, this.state.searchText, this.state.currentPage))
            .then(response => {
                this.setState(
                    prevState => ({
                        loading: false,
                        reloading: false,
                        loadingMore: false,
                        totalPages: response.value.headers['x-pagination-page-count'],
                        findFriends: prevState.findFriends.concat(response.value.data)
                    }), () => {
                        if (this.state.currentPage < this.state.totalPages) {
                            infiniteScroll(this, this.search);
                        }
                    }
                );
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({ loading: false, reloading: false, loadingMore: false });
            });
    }

    handleSearchInput = e => {
        clearTimeout(delayTimer);
        const that = this;
        this.setState({ searchText: e.target.value, loading: true, findFriends: [], currentPage: 1 });
        delayTimer = setTimeout(() => {
            that.search();
            // Do the ajax stuff
        }, 1500);
    }

    onClear = () => {
        clearTimeout(delayTimer);
        const that = this;
        this.setState({ searchText: '', loading: true, findFriends: [], currentPage: 1 });
        delayTimer = setTimeout(() => {
            that.search();
            // Do the ajax stuff
        }, 1500);
    }

    selectUser = (e, data) => {
        e.preventDefault();
        this.props.dispatch(setLocationAdministratorUser(data));
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="mainAppContainer">
                <MainNavigation title="Add User" />
                <div className="simpleSearchBar row">
                    <i className="icn icon-search fontDarkGray" />
                    <input onChange={this.handleSearchInput} value={this.state.searchText} placeholder="Search..." />
                    {!!this.state.searchText && (
                        <i onClick={this.onClear} className="icn icon-sign-out fontDarkGray" />
                    )}
                </div>
                <div className="bodyContent wrapper noPaddingTop pull2refresh">
                    <div className="maxWidth">
                        {(!!this.state.loading || !!this.state.reloading) && (
                            <Spinner />
                        )}
                        {!this.state.loading && !!this.state.findFriends && this.state.findFriends.length > 0 && (
                            <UserList users={this.state.findFriends} onClick={this.selectUser} />
                        )}
                    </div>
                    {!!this.state.loadingMore && (
                        <Spinner />
                    )}
                </div>
                <SecondaryNavigation selected="more" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(AdminAdministratorsListAddUser);
