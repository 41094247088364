import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

import { findFriends } from '../../redux/actions/users';
import UserList from '../../layout/UserList';
import Spinner from '../../layout/UI/Spinner';
import FriendsNavigation from '../../layout/navigation/FriendsNavigation';
import { InviteFriends } from '../../layout/InviteFriends';
import { getGeoLocation } from '../../helpers/GeoLocation';
import { infiniteScroll } from '../../helpers/Pull2Refresh';
import ApiError from '../../helpers/ApiError';
import Columns from '../../hoc/Columns';
import WhiteTitle from '../../hoc/WhiteTitle';
import FindFriendsComponent from './FindFriendsComponent';
import FriendRequestsComponent from './FriendRequestsComponent';
import HelmetTags from '../../helmetTags';

let delayTimer;

export class Friends extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            friends: [],
            searchText: '',
            currentPage: 1,
            // loadingMore: false,
            totalPages: 1, // This it's being used by infiniteScroll->handleScroll->loadMore
            // totalPagesRequests: 0,
            // listOfAnsweredUsers: [],
            others: [],
            loadingOthers: false,
            currentPageOthers: 1,
            totalPagesOthers: 1
        };
    }

    getGeoLocation = getGeoLocation.bind(this)

    componentDidMount() {
        window.scrollTo(0, 0);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        this.start();
    }

    start = () => {
        this.setState({ loading: true, friends: [] });
        this.getGeoLocation(this.props, this.search, this.props.credentials.token);
    }

    search = () => {
        this.findFriends();
        if (this.state.searchText) {
            this.findOthers();
        }
    }

    findFriends = () => {
        const filter = '&filter[friendship_status][eq]=accepted';
        let perPage = 60;
        if (this.state.searchText) perPage = 100;
        this.props.dispatch(findFriends(this.props.credentials.token, this.props.userData.user_location, filter, this.state.searchText, this.state.currentPage, perPage))
            .then(response => {
                this.setState(prevState => ({
                    loading: false,
                    // loadingMore: false,
                    totalPages: response.value.headers['x-pagination-page-count'],
                    friends: prevState.friends.concat(response.value.data)
                }), () => {
                    if (this.state.currentPage < this.state.totalPages) {
                        infiniteScroll(this, this.search);
                    }
                });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({
                    loading: false
                    // loadingMore: false
                });
            });
    }

    findOthers = () => {
        const filter = '&filter[friendship_status][neq]=accepted';
        this.props.dispatch(findFriends(this.props.credentials.token, this.props.userData.user_location, filter, this.state.searchText, this.state.currentPageOthers))
            .then(response => {
                this.setState(prevState => ({
                    loadingOthers: false,
                    totalPagesOthers: response.value.headers['x-pagination-page-count'],
                    others: prevState.others.concat(response.value.data)
                }));
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.setState({
                    loading: false
                    // loadingMore: false
                });
            });
    }

    handleSearchInput = e => {
        clearTimeout(delayTimer);
        const that = this;
        this.setState({ searchText: e.target.value, loading: true, currentPage: 1, friends: [], others: [] }, () => {
            delayTimer = setTimeout(() => {
                that.search();
                // Do the ajax stuff
            }, 1500);
        });
    }

    onClear = () => {
        clearTimeout(delayTimer);
        const that = this;
        this.setState({ searchText: '', loading: true, currentPage: 1, friends: [], others: [] }, () => {
            delayTimer = setTimeout(() => {
                that.search();
                // Do the ajax stuff
            }, 1500);
        });
    }

    loadMoreOthers = () => {
        this.setState(prevState => ({
            currentPageOthers: prevState.currentPageOthers + 1,
            loadingOthers: true
        }), this.findOthers);
    }

    render() {
        const pageTitle = 'Find Friends';
        const pageDescription = 'Find local friends to meet and join playdates, walk dogs, socialize at Pooch';

        const c1 = (
            <div className="simple-navigation">
                <FriendsNavigation
                    showFindFriends
                    selected="friends"
                />
            </div>
        );

        const c2 = (
            <React.Fragment>
                {(!!this.state.loading) && (<Spinner />)}
                {!this.state.loading && this.state.friends.length === 0 && (<Redirect to="/findfriends" />)}
                {this.state.friends.length > 0 && (<UserList users={this.state.friends} />)}
                {this.state.friends.length > 0 && this.state.others.length > 0 && (<div className="spacerSmall" />)}
                {this.state.others.length > 0 && (
                    <React.Fragment>
                        <WhiteTitle>Other Users</WhiteTitle>
                        <UserList users={this.state.others} />
                        {this.state.loadingOthers && (<Spinner />)}
                        {this.state.currentPageOthers < this.state.totalPagesOthers && (
                            <React.Fragment>
                                <div className="spacerSmall" />
                                <div className="fontGreen alignRight spacer" onClick={this.loadMoreOthers}>
                                    Load More Users
                                </div>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );

        const c3 = (
            <React.Fragment>
                <InviteFriends wrapperClass="noWrapper" />
                <FriendRequestsComponent limit={3} infiniteScroll={false} viewMoreButton={true} />
                <WhiteTitle>Suggested Friends</WhiteTitle>
                <FindFriendsComponent perPage={3} infiniteScroll={false} />
                <Link to="/findfriends" className="square-button greenBG fontWhite h6">View all</Link>
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <HelmetTags title={pageTitle} description={pageDescription} />
                <div className="mainAppContainer">
                    <div className="bodyContent">
                        <div className="simpleSearchBar row">
                            <i className="icn icon-search fontGreen" />
                            <input onChange={e => this.handleSearchInput(e)} value={this.state.searchText} placeholder="SEARCH Friends" />
                            {!!this.state.searchText && (
                                <i onClick={this.onClear} className="icn icon-sign-out fontDarkGray" />
                            )}
                        </div>
                        <div className="three-column-layout two-column-list">
                            <Columns
                                c1={c1}
                                c2={c2}
                                c3={c3}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials
    };
}

export default connect(mapStateToProps)(Friends);
