import { toast } from 'react-toastify';
import ReactGA from 'react-ga';

import { isJSON } from '../helpers/Functions';
import Config from '../helpers/Config';

let toastIdError = null;

const ApiError = err => {
    if (typeof err === 'string') {
        showError(err);
    } else if (err && err.response && err.response.data && err.response.data.message) {
        if (typeof err.response.data.message === 'string' && !(isJSON(err.response.data.message))) {
            showError(err.response.data.message);
        } else {
            const json = JSON.parse(err.response.data.message);
            if (json.errors) {
                const errors = json.errors;
                for (const property in errors) {
                    if (errors.hasOwnProperty(property)) {
                        for (const i in errors[property]) {
                            showError(errors[property][i]);
                        }
                    }
                }
            }
        }
    } else if (err.message) {
        showError(err.message);
    }
};

const showError = msg => {
    if (!toast.isActive(toastIdError)) {
        ReactGA.event({
            category: 'Error',
            action: msg
        });
        if (msg === 'Request failed with status code 404') {
            toastIdError = toast.error('404 - Page not found');
        } else {
            const isLoggedIn = window && window.localStorage && window.localStorage['reduxPersist:users'] && JSON.parse(window.localStorage['reduxPersist:users']);
            if ((isLoggedIn && isLoggedIn.credentials && isLoggedIn.credentials.token)) {
                toastIdError = toast.error(msg);
            } else if (
                msg === 'Receiver Id must be an integer.'
                || msg === 'Owner Id must be an integer.'
                || msg === 'Network Error'
                || msg === 'There was an error'
                || msg === 'There was an error, please try again.'
                || msg === 'Sorry, there was an error'
                || msg === 'You need to log in or sign up.'
                || msg === 'Your request was made with invalid credentials.' // Check if this will apply only to no logged users
                || msg === 'Please login'
            ) {
                loginClick();
            } else {
                toastIdError = toast.error(msg);
            }
        }
    }
};

/**
 * This function it's going to open a modal to allow the user log into his account
 */
export const loginClick = () => {
    if (!Config.isMobileApp && !Config.isMobileDevice) {
        const button = document.getElementById('login-header-button');
        if (button) button.click();
    } else {
        const modal = document.getElementById('CustomModalMobileLogin');
        if (modal?.classList.contains('hidden')) {
            modal.classList.remove('hidden');
        }
    }
};

export default ApiError;
