import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { getLocations, setSearchLocation } from '../../redux/actions/locations';
import { pc_togglePark } from '../../redux/actions/playdates';
import MainNavigation from '../../layout/navigation/MainNavigation';
import SecondaryNavigation from '../../layout/navigation/SecondaryNavigation';
import { LocationListItem } from '../../layout/locations/LocationListItem';
import Spinner from '../../layout/UI/Spinner';
import NavigationSearchBar from '../../layout/UI/NavigationSearchBar';
import NotFound from '../../layout/NotFound';
import { getGeoLocation } from '../../helpers/GeoLocation';
import { Pull2Refresh, infiniteScroll } from '../../helpers/Pull2Refresh';
import ApiError from '../../helpers/ApiError';

export class PlaydatesCreateParkSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locationList: [],
            loading: true,
            loadingMore: false,
            currentPage: 1,
            totalPages: 1 // This it's being used by infiniteScroll->handleScroll->loadMore
        };
    }

    getGeoLocation = getGeoLocation.bind(this)

    componentDidMount() {
        window.scrollTo(0, 0);
        this.start();
        Pull2Refresh(this.init);
        if (window && window.location && window.location.pathname) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    init = () => {
        this.setState({
            locationList: [],
            loading: true,
            loadingMore: false,
            currentPage: 1,
            totalPages: 1
        }, this.start());
    }

    start = () => {
        this.setState({ loading: true });
        getGeoLocation(this.props, this.getNearByLocations, this.props.credentials.token, true);
    }

    getNearByLocations = () => {
        const filter = 'all';
        this.props.dispatch(getLocations(this.props.credentials.token, this.props.userData.user_location, filter, this.props.searchValue, this.state.currentPage))
            .then(response => {
                this.locationList(response.value.data);
                this.setState({
                    totalPages: response.value.headers['x-pagination-page-count']
                }, () => {
                    if (this.state.currentPage < this.state.totalPages) {
                        infiniteScroll(this, this.getNearByLocations);
                    }
                });
            })
            .catch(err => {
                ApiError((err.response && err.response.data && err.response.data[0] && err.response.data[0].message) || err.message);
                this.locationList();
            });
    }

    locationList = moreLocations => {
        this.setState(prevState => ({
            locationList: prevState.locationList.concat(moreLocations || null),
            loading: false,
            loadingMore: false
        }));
    }

    selectPark = (e, park) => {
        e.preventDefault();
        this.props.dispatch(pc_togglePark(park));
        this.props.history.push('/events/create/event');
    }

    render() {
        let newlocations = null;

        if (this.state.loading) { // if loading
            newlocations = (<Spinner />);
        } else if (this.state.locationList.length > 0) { // if it has at least 1 location
            newlocations = this.state.locationList.map(location => (
                <LocationListItem
                    dispatch={this.props.dispatch}
                    credentials={this.props.credentials}
                    key={location.location_id}
                    location={location}
                    url="location/profile"
                    onClick={this.selectPark}
                />
            ));
        } else { // if it doesnt have any locations and you searched for something
            newlocations = (
                <NotFound
                    type="searchlocations"
                    onClear={() => {
                        this.props.dispatch(setSearchLocation(''));
                        this.init();
                    }}
                    class="backgroundRed"
                />
            );
        }

        return (
            <div className="mainAppContainer">
                <MainNavigation title="Select a Location" rightButton="addLocation" rightLink="/location/add" rightButtonRedirect="/events/create/event/locationselection" />
                <div className="bodyContent locationListSearch pull2refresh">
                    <NavigationSearchBar
                        callback={this.init}
                        disp={this.props.dispatch}
                        searchInputHandler={setSearchLocation}
                        searchValue={this.props.searchValue}
                    />
                    {newlocations}
                    {this.state.loadingMore && (<Spinner />)}
                </div>
                <SecondaryNavigation selected="events" />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state.users.user,
        credentials: state.users.credentials,
        searchValue: state.locations.searchLocation,
        selectedDogs: state.playdates.create.selectedDogs,
        selectedPark: state.playdates.create.selectedPark,
        selectedDate: state.playdates.create.selectedDate,
        selectedTime: state.playdates.create.selectedTime,
        invitationOnly: state.playdates.create.invitationOnly,
        comment: state.playdates.create.comment
    };
}

export default connect(mapStateToProps)(PlaydatesCreateParkSelection);
